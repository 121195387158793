import React, { memo, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { RemoveIcon, UploadIcon } from '~/assets/icons';
import {
  DEFAULT_ALLOWED_FILE_TYPES,
  DEFAULT_ICON_FILES,
  DEFAULT_SIZE_LIMIT,
} from '~/services/files';
import { Container, ErrorText, FileBox } from './styles';

export const InputUpload = memo(
  ({
    data,
    removeFile,
    onChange,
    acceptedMimeTypes,
    size,
    type,
    required = true,
  }) => {
    const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
      useDropzone();
    const props = getRootProps();

    const [error, setError] = useState('');

    useEffect(() => {
      const acceptedFileTypes = acceptedMimeTypes || DEFAULT_ALLOWED_FILE_TYPES;
      const allowedSize = size || DEFAULT_SIZE_LIMIT;

      if (acceptedFiles && acceptedFiles?.length > 0) {
        if (
          acceptedFiles[acceptedFiles?.length - 1].size > Number(allowedSize)
        ) {
          return setError('Tamanho de arquivo acima do permitido.');
        }
        setError('');

        if (
          acceptedFileTypes.includes(
            acceptedFiles[acceptedFiles?.length - 1].type
          )
        ) {
          onChange(acceptedFiles);
          setError('');
        } else {
          acceptedFiles.pop();
          return setError('Extensão de arquivo não permitida.');
        }
      }
    }, [acceptedFiles]);
    return (
      <div>
        {required ? <p style={{ color: 'red' }}>Obrigatório*</p> : null}
        <Container {...props} className={isDragActive ? 'dragActive' : ''}>
          <div>
            <p className='description'>
              <UploadIcon /> Clique para selecionar arquivo ou arraste e solte
              do seu dispositivo
            </p>
          </div>

          <input name='file' {...getInputProps()} />
        </Container>
        {data &&
          data.map(({ path, type, size, name }) => (
            <FileBox key={path ?? name}>
              <div key={path ?? name} className='file'>
                <div>
                  {DEFAULT_ICON_FILES[type]}{' '}
                  {path?.length > 30 ? `${path.substring(0, 29)}...` : name}
                </div>
                <div className='sizeBox'>
                  <div className='size'>{(size * 0.000001).toFixed(2)} MB</div>
                  <div onClick={(e) => removeFile(path)}>
                    <RemoveIcon />
                  </div>
                </div>
              </div>
            </FileBox>
          ))}
        {acceptedFiles &&
          acceptedFiles?.length === 0 &&
          type &&
          data?.length === 0 && <ErrorText>Obrigatório</ErrorText>}
        {error && <ErrorText>{error}</ErrorText>}
        <p>JPG, PNG ou PDF, arquivo com tamanho máximo de 7mb.</p>
      </div>
    );
  }
);

InputUpload.displayName = 'InputUpload';
