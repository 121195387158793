import React from 'react';
import {
  Main,
  Container,
  Header,
  Content,
  HeaderHolder,
  LogoImage,
  LineHolder,
} from '../../pages/CheckoutPayment/styles';

const CheckoutLayout = ({ children }) => {
  return (
    <Main>
      <Content>
        <Container>{children}</Container>
      </Content>
    </Main>
  );
};

export default CheckoutLayout;
