import React, { useCallback } from 'react';
import { Table } from 'rsuite';

const TableCellOperacao = ({ rowData, dataKey, ...props }) => {
  const getText = useCallback(() => {
    const code = rowData[dataKey];
    if (code === 'ESPECIE') return 'Dinheiro em espécie';
    if (code === 'CARTAO') return 'Cartão de viagem';
    return code;
  }, []);

  return <Table.Cell {...props}>{getText()}</Table.Cell>;
};

export default TableCellOperacao;
