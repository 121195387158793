import styled from 'styled-components';
import { Tooltip } from 'rsuite';
import Button from '../Button/index';

export const StyledCard = styled.div`
  background: #fff;
  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 10px;
  height: 361px;
  flex-direction: column;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 64px 0px 0 32px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 180px;
    margin: 20px auto;
    /* flex-direction: row; */

    padding: 0 15px;
  }
`;

export const TitleCard = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  color: #20315b;

  @media (max-width: 700px) {
    font-size: 20px;
    line-height: 28px;
    /* margin-left: 8px; */
  }
`;

export const SubTitleCard = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #455566;

  @media (max-width: 650px) {
    display: none;
  }
`;

export const BankLength = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: #0fc7ff;
  font-weight: bold;
`;

export const BodyCard = styled.div`
  display: flex;
  padding: 16px 32px 16px;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  button {
    font-weight: bold;
  }

  a {
    text-decoration: none;
    color: #0fc7ff;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    &:hover {
      color: #fff;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    padding: 0;

    #body {
      /* width: 100; */
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 16px;
      gap: 8px;
    }
  }

  @media (min-width: 651px) and (max-width: 768px) {
    #body {
      margin-bottom: 16px;
    }
  }
`;

export const BodyButton = styled.div`
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 32px;
`;

export const BodyContainer = styled.div`
  @media (min-width: 769px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    margin: 16px 0px;
  }
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: flex-start;
  /* width: 100%; */
  /* padding: 24px 16px 16px; */
  max-height: 120px;

  @media (min-width: 769px) {
    width: 100%;
    flex: 1;
    &:has(p) {
      img {
        margin-bottom: 0 !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    padding: 0;
    padding: 8px 16px;
    /* padding-right: 16px; */

    /* &:has(p) {
      img {
        margin-bottom: 0 !important;
      }
  
      p {
        margin-bottom: 60px;
      }
    } */
  }
`;

export const IconImg = styled.img`
  display: none;
  @media (min-width: 769px) {
    display: flex;
  }
`;

export const IconImgMobile = styled.img`
  display: none;

  @media (max-width: 768px) {
    display: flex;

    /* margin-left: 16px; */
    /* margin-bottom: 61px; */
  }
`;

export const BodyQnt = styled.div`
  width: 100%;
  display: flex;
  padding-top: 4px;
`;

export const QntContracts = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 140px;
  min-height: 24px;
  width: 173px;
  height: 40px;

  top: 12px;

  background-color: #ffecec;
  border-radius: 50px;

  @media (max-width: 768px) {
    max-width: 140px;
    max-height: 24px;

    margin-left: 8px;
    margin-top: 4px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;

    margin-left: 8px;

    background-color: #ef3131;

    font-family: IBM Plex Sans;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #f9fafc;

    @media (max-width: 768px) {
      width: 16px;
      height: 16px;

      margin-left: 4px;

      font-size: 11px;
      line-height: 24px;
    }
  }

  span {
    font-family: IBM Plex Sans;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #20315b;

    margin-right: 8px;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 24px;

      margin-right: 8px;
    }
  }
`;

export const CustomButton = styled(Button)`
  width: 232px;
  height: 40px;

  @media (max-width: 768px) {
    width: 100%;
    /* width: 174px; */
    /* height: 32px; */

    border: 1.5px solid #0fc7ff;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    line-height: 20px;
  }
`;

export const InfoStatusComplete = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  /* display: flex; 
  /* align-items: center; */
  /* text-align: right; */

  color: #14cc70;
`;

export const InfoStatusIncomplete = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #ef3131;
`;

export const InfoStatusOptional = styled.p`
  font-family: IBM Plex Sans;
  font-style: italic;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #9ba8b7;
`;

export const TooltipLimit = styled(Tooltip)`
  .rs-tooltip-inner {
    background-color: #132552;
    border-radius: 5px;
    font-family: IBM Plex Sans;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    padding: 8px 8px;
  }

  .rs-tooltip-arrow {
    border-bottom-color: #132552;
  }
`;
