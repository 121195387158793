import styled from 'styled-components';

import { Radio, Modal } from 'rsuite';

import Button from '../Button';

export const CustomModal = styled(Modal)`
  width: 100%;
  max-width: 489px;
  z-index: 50;

  @media (min-width: 801px) {
    padding-top: 140px;
  }

  .error-message {
    color: #ff0000;
  }

  @media (min-width: 768px) {
    .rs-modal-content {
      padding: 16px 40px;
    }
  }
`;

export const ContentModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 801px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const BodyContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const LineTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 8px;
  }
`;

export const Title = styled.h3`
  font-family: IBM Plex Sans;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #20315b;

  @media (min-width: 801px) {
    font-family: IBM Plex Sans;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    /* color: #20315b; */
    color: #132552;
  }
`;

export const TextBody = styled.p`
  font-family: IBM Plex Sans;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  color: #455566;
  margin-top: 16px;

  @media (min-width: 801px) {
    font-family: IBM Plex Sans;
    font-weight: 400;
    font-size: 26px;
    line-height: 34px;
    color: #455566;
    margin-top: 32px;
  }
`;

export const RadioCustom = styled(Radio)`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #455566;

  opacity: 0.8;

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 20px;
  }

  .rs-radio-checker:hover .rs-radio-wrapper .rs-radio-inner::before {
    border-color: #0fc7ff !important;
  }
  .rs-radio-checker:hover .rs-radio-wrapper .rs-radio-inner::after {
    border-color: #0fc7ff !important;
    background: #0fc7ff !important;
    border: 8px solid #0fc7ff !important;
    box-sizing: border-box !important;
  }

  .rs-radio-checked .rs-radio-wrapper .rs-radio-inner::after {
    border-color: #0fc7ff !important;
    background-color: #0fc7ff !important;
    border: 8px solid #0fc7ff !important;
    box-sizing: border-box !important;
  }

  .rs-radio-wrapper .rs-radio-inner::before {
    width: 24px;
    height: 24px;
  }

  .rs-radio-checker {
    padding-top: 12px;
    padding-bottom: 10px;
    padding-left: 52px;
    min-height: 36px;
    line-height: 1;
    position: relative;
  }

  span.rs-radio-wrapper::after {
    width: 24px;
    height: 24px;
  }

  span.rs-radio-inner::after {
    border-color: #0fc7ff;
    background: #0fc7ff !important;
    border: 8px solid #0fc7ff !important;
    box-sizing: border-box !important;
    margin: 4px 0px 0px 4px !important;
  }

  span.rs-radio-inner::before {
    background: none !important;
  }
`;

// export const CustomButtom = styled(Button)`
//   width: 154px;
//   height: 40px;
//   margin-top: 27px;
//   min-width: min-content;
// `;

export const CustomButtomGhost = styled.a`
  border-radius: 500px;
  padding: 6px 8px;

  color: #0fc7ff;
  background: none;
  border: 2px solid #0fc7ff;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;

  &:hover {
    background: #0fc7ff;
    border: 2px solid #0fc7ff;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const CustomButtom = styled.a`
  border-radius: 500px;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 20px;

  color: #fff;
  font-weight: 600;
  background: linear-gradient(
    128.69deg,
    #48d3fe 28.81%,
    #0fc7ff 52.98%,
    #09b2e6 84.96%
  );
  box-shadow: 0px 3px 20px rgba(114, 222, 255, 0.25),
    0px 2px 4px rgba(196, 241, 255, 0.25), 0px 5px 20px rgba(0, 159, 217, 0.2);
  transition: 0.5s all;
  background-size: 200%;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 3px 20px rgba(114, 222, 255, 0.25),
      0px 2px 4px rgba(196, 241, 255, 0.25), 0px 5px 20px rgba(0, 159, 217, 0.2);
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
  }
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  flex-direction: row;

  @media (max-width: 445px) {
    height: 90px;
    flex-direction: column;
  }
`;

export const BoxTitle = styled.p`
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #455566;

  opacity: 0.8;
`;

export const BoxText = styled.p`
  font-family: IBM Plex Sans;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #455566;

  opacity: 0.8;
`;
