import React, { useState } from 'react';
import { Stepper } from '../../../pages/Checkout/styles';
import TravelMoneyCalculatorStep1 from './TravelMoneyCalculatorStep1';
import TravelMoneyCalculatorStep2 from './TravelMoneyCalculatorStep2';

export default function StepperCalculator({ isANewCard }) {
  const [activeStep, setActiveStep] = useState(1);

  const changeActiveStep = (activeStep) => {
    setActiveStep(activeStep);
  };

  return (
    <Stepper>
      <TravelMoneyCalculatorStep1
        activeStep={activeStep}
        onChangeStep={changeActiveStep}
        isANewCard={isANewCard}
      />
      <TravelMoneyCalculatorStep2
        activeStep={activeStep}
        onChangeStep={changeActiveStep}
      />
    </Stepper>
  );
}
