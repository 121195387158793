import { ReactSmartScroller } from 'react-smart-scroller';
import { Col, Grid } from 'rsuite';
import styled from 'styled-components';

export const CustomGrid = styled(Grid)`
  padding-top: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 120px;
    padding-right: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
  }
`;

export const Title = styled.div`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  color: #20315b;
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 32px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 18px;
  }
`;

export const SubTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #20315b;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 16px;
  }
`;

export const AbaText = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: ${(props) => (props.isActive == true ? '#20315b' : '#455566')};
`;

export const AbaDivider = styled.div`
  margin-top: 12px;
  background: ${(props) => (props.isActive == true ? '#0fc7ff' : '#132552')};
  border: 4px solid
    ${(props) => (props.isActive == true ? '#0fc7ff' : '#132552')};
`;

export const CalculatorTitle = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #455566;
  margin-left: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 16px;
  }
`;

export const ContainerButton = styled.div``;

export const Card = styled.div`
  background: ${(props) => (props.selected ? '#E7FAFF' : '#fff')};
  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 10px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 20px 0px 0 0px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid ${(props) => (props.selected ? '#0FC7FF' : '#fff')};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 100%;
    padding: 10px;
  }
`;

export const TitleIcon = styled.span``;

export const ResponsiveContainer = styled(Col)`
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-top: 30px;
  }
`;
