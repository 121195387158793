import React from 'react';
import styled from 'styled-components';
import { RemoveIcon } from '../../../assets/icons';
import { DEFAULT_ICON_FILES } from '../../../services/files';

export const Container = styled.div`
  background: rgba(15, 199, 255, 0.1);
  border-radius: 5px;
  height: 72px;
  max-width: 550px;
  padding: 10px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-size: 12px;
`;

export const FileBox = ({ mFileObj, canDelete, onDelete }) => {
  const file = mFileObj.Files?.length > 0 ? mFileObj.Files[0] : mFileObj;
  return (
    <Container key={mFileObj.DisplayID}>
      <FlexibleContainer>
        {DEFAULT_ICON_FILES[file.Extension]}
      </FlexibleContainer>
      <FlexibleContainer flex={4}>{file.Name}</FlexibleContainer>
      <FlexibleContainer flex={2}>
        {(file.Size * 0.000001).toFixed(2)} MB
      </FlexibleContainer>
      {canDelete && (
        <FlexibleContainer onClick={() => onDelete()}>
          <RemoveIcon />
        </FlexibleContainer>
      )}
    </Container>
  );
};

const FlexibleContainer = (props) => {
  const { flex, onClick } = props;
  const style = {
    ...props.style,
    flex: flex ?? 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return (
    <div style={style} onClick={onClick}>
      {props.children}
    </div>
  );
};
