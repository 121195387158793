import React from 'react';
import { StyledBox, ValueBoxText, Value } from './styles';
import BrazilFlag from '../../assets/icons/flags/br.svg';
import EuaFlag from '../../assets/icons/flags/eua.svg';
import { transformNumberInMoney } from '~/utils/transformNumberInMoney';

const ValueBox = ({ currency, text, value }) => {
  // const flags = {
  //   'BRL': () => {
  //     return BrazilFlag
  //   },
  //   'USD': () => {
  //     return EuaFlag
  //   }
  // }

  return (
    <StyledBox>
      <ValueBoxText>{text}</ValueBoxText>
      <div>
        {/* <Image src={flags[currency]()} alt={currency} /> */}
        <Value>{transformNumberInMoney({ number: value, currency })}</Value>
      </div>
    </StyledBox>
  );
};

export default ValueBox;
