import React from 'react';
import { Modal } from 'rsuite';
import { CloseOutlined } from '@mui/icons-material';

import * as S from './styles';

export default function FATCAModal({ show, onHide }) {
  return (
    <S.SModal
      show={show}
      onHide={onHide}
      size='md'
      style={{ padding: '16px 0 0 0' }}
    >
      <Modal.Header>
        <S.ModalClose onClick={onHide}>
          <CloseOutlined color='#0fc7ff' />
        </S.ModalClose>
      </Modal.Header>

      <Modal.Body>
        <h2>FATCA (Foreign Account Tax Compliance Act)</h2>

        <S.Description style={{ marginBottom: 16 }}>
          A Advanced Corretora de Câmbio LTDA declara ser instituição financeira
          aderente ao FATCA e que cumpre e cumprirá integralmente com as
          obrigações de diligência e reporte decorrentes desta legislação.
        </S.Description>

        <S.Description style={{ fontWeight: 800 }}>
          1. O que é FATCA?
        </S.Description>

        <S.Description style={{ marginBottom: 16 }}>
          Trata-se de uma lei americana sancionada em 18 de março de 2010, cujo
          objetivo resume-se em combater a evasão fiscal por US Persons, os
          quais poderão deter contas/investimentos em Instituições Financeiras
          não situadas nos EUA (“Foreign Financial Institutions” ou “FFI’s”), e
          que não declarem ou não tributem tais contas e/ou investimentos nos
          E.U.A.
        </S.Description>

        <S.Description style={{ fontWeight: 800 }}>
          2. Como se caracteriza U.S. Person?
        </S.Description>

        <S.Description style={{ marginBottom: 16 }}>
          Será caracterizado como U.S. Person aquela pessoa que seja: (i)
          cidadão americano, ou seja, nascido nos EUA ou de seus territórios,
          com pais americanos (cidadania derivada), com cidadania americana
          (naturalização); (ii) residente fiscal nos EUA, ou seja, possua green
          card ou tenha presença substancial (31 dias durante um ano corrente e
          183 dias durante o triênio); e (iii) sócio ou acionista de empresa
          constituída sob as leis dos EUA (partnership doméstica ou sociedade
          doméstica), (iv) Ainda, se uma U.S. Person detiver mais 10% ou mais de
          participação direta ou indireta, societária ou contratual, no lucro ou
          no capital de uma entidade (“US Substantial Owner” ou “NFFEs”), esta
          deverá ser reportada.
        </S.Description>
      </Modal.Body>
    </S.SModal>
  );
}
