import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RadioGroup } from 'rsuite';
import {
  RadioItem,
  StepperColumn,
  StepperContent,
  StepperDescription,
  StepperItem,
  StepperItemNumber,
  StepperTitle,
} from '../../../../pages/Checkout/styles';
import check from '../../../../assets/check.svg';
import {
  resetProductSelected,
  selectProductType,
} from '../../../../store/modules/exchange/actions';

const TravelMoneyCalculatorStep1 = ({
  activeStep,
  onChangeStep,
  isANewCard,
}) => {
  const [checked, setChecked] = useState(false);
  const { productList } = useSelector((state) => state.exchange);
  const dispatch = useDispatch();
  let isPRD = false;
  if (process.env.NODE_ENV === 'production') {
    isPRD = true;
  }

  const onChangeStep1 = async (productType) => {
    dispatch(resetProductSelected());
    dispatch(selectProductType(productType));
    setChecked(!!productType);
    onChangeStep(2);
  };

  useEffect(() => {
    onChangeStep1('ESPECIE');
  }, [productList]);

  return (
    <StepperItem active={activeStep === 1} checked={checked}>
      <StepperItemNumber>
        {activeStep > 1 ? <img src={check} /> : '1'}
      </StepperItemNumber>
      <StepperColumn>
        <StepperTitle>Como você vai receber o valor</StepperTitle>
        {/* <StepperTitle>Como você quer receber o valor?</StepperTitle> */}
        <StepperDescription>
          Obs: pode haver uma pequena variação no valor final
        </StepperDescription>
        <StepperContent>
          <RadioGroup
            defaultValue='ESPECIE'
            onChange={(value) => onChangeStep1(value)}
          >
            {/* {!isANewCard && ( */}
            <RadioItem checked value='ESPECIE' disabled={!productList.length}>
              Dinheiro em espécie
            </RadioItem>
            {/* )} */}
            {/* <RadioItem value={'CARTAO'} disabled={!productList.length}>
              Cartão de Viagem
            </RadioItem> */}
            {/* {!process.env.NODE_ENV === 'production' ? (<RadioItem value={'CARTAO'} disabled={!productList.length}>
              Cartão de Viagem
            </RadioItem>):  <div />} */}
          </RadioGroup>
        </StepperContent>
      </StepperColumn>
    </StepperItem>
  );
};

export default TravelMoneyCalculatorStep1;
