import styled from 'styled-components';

export const StyledBox = styled.div`
  background: #ffffff;
  border: 1px solid #eff2f7;
  box-shadow: 0px 38px 56px rgba(154, 154, 167, 0.07),
    0px 11.4559px 16.8824px rgba(154, 154, 167, 0.0456112),
    0px 4.75819px 7.01207px rgba(154, 154, 167, 0.035),
    0px 1.72094px 2.53613px rgba(154, 154, 167, 0.0243888);
  border-radius: 10px;

  margin-bottom: 24px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`;

export const Category = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: #455566;
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #20315b;
  padding: 16px 0;
  display: block;
`;

export const EditButton = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0fc7ff;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const Content = styled.div`
  border-top: 1px solid #e0e6ed;
  padding-top: 16px;

  & span {
    display: block;
  }
`;

export const InternalContainer = styled.div`
  padding: 24px 32px;
`;

export const CollapseButton = styled.div`
  background: #e7faff;
  color: #09b2e6;
  text-align: center;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px 0;

  cursor: pointer;
`;

export const List = styled.ul`
  width: 100%;
  margin-bottom: 0;
`;
export const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #455566;
  padding: 6px 0;
  margin-bottom: 12px;

  & span {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    svg {
      margin-right: 14px;
    }
  }

  & strong {
    font-weight: bold;
  }

  ${(props) =>
    props.big &&
    `

    & span {
      color: #9BA8B7;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
    }

    & strong {
      color: #20315B;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
  `}
`;

export const Order = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const TotalOperation = styled.div`
  border-top: 1px solid #e0e6ed;
  padding-top: 24px;
  margin-top: 56px;
`;

export const FlagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;

  span {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

    color: #20315b;
  }

  strong {
    padding-left: 8px;
  }
`;
