import styled from 'styled-components';
import { Modal } from 'rsuite';

export const Container = styled.div`
  display: flex;
  width: 100%;
  background: #f9fafc;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1rem;
  }
  span {
    color: red;
  }
`;

export const ButtonBase = styled.div`
  margin-bottom: 2rem;
  background: #eff2f7;
  border-radius: 50px;
  max-width: 384px;
  width: 100%;
  border: none;
`;

export const Buttons = styled.div`
  margin: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  max-width: 340px;
  height: 40px;
  background: ${(props) =>
    props.notToggle
      ? 'transparent'
      : 'linear-gradient(138.72deg, #48D3FE 28.81%, #0FC7FF 52.98%, #09B2E6 84.96%)'};
  color: ${(props) => (props.notToggle ? '#9BA8B7' : '#fff')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
`;

export const FlexButton = styled.div`
  display: flex;
`;

export const All = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
`;

export const Title = styled.div`
  margin-bottom: 20px;
  color: #20315b;
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 32px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 400px;

  img {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .input {
    margin: 5px 0 15px 0;
    height: 50px;
    width: 100%;
    max-width: 384px;
    border-radius: 5px;
    border: 2.73067px solid #d3dce6;
    padding-left: 10px;
    background: transparent;
  }

  button {
    margin-top: 20px;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    max-width: 384px;
    height: 48px;
    color: #fff;
    background: linear-gradient(
      138.72deg,
      #48d3fe 28.81%,
      #0fc7ff 52.98%,
      #09b2e6 84.96%
    );
    box-shadow: 0px 3px 20px rgba(114, 222, 255, 0.25),
      0px 2px 4px rgba(196, 241, 255, 0.25), 0px 5px 20px rgba(0, 159, 217, 0.2);
    border-radius: 30px;
    border: none;
  }
`;

export const Fields = styled.div`
  width: 100%;
  max-width: 380px;
  font-family: IBM Plex Sans;
  font-size: 14px;
  line-height: 20px;
  color: #455566;
`;

export const Banner = styled.div`
  width: 100%;
  display: flex;

  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

export const Password = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    margin-right: 17px;
    color: #09b2e6;
  }
`;

export const Rodape = styled.div`
  margin: 8px 0 0px;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #009fd9;
    text-decoration: none;
  }
`;
