import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  background: #20315b;
  padding: 25px 0;
`;
export const Infos = styled.div``;
export const Title = styled.div``;
export const Wrapper = styled.div``;

export const BackButton = styled.div`
  display: flex;
  color: #0fc7ff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;

  p {
    margin-left: 3px;
  }

  a {
    text-decoration: none;
    color: #0fc7ff;
  }
`;
