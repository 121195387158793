export function getPartners(id, token) {
  return {
    type: '@partnersList/GET_PARTNERS',
    payload: { id, token },
  };
}

export function savePartners(partners) {
  return {
    type: '@partnersList/SAVE_PARTNERS',
    payload: { partners },
  };
}
