/* eslint-disable no-underscore-dangle */
import { api } from '../../../services/api';
import { removeMask } from '../../../utils/removeMask';

export const savePartnerFiles = async (userId, files, partnerId) => {
  if (files.length > 0) {
    const newFile = new FormData();

    files.map((item) => newFile.append('file', item));

    newFile.append('id', userId);
    newFile.append('className', 'Outro Documento');
    newFile.append('type', 'Representante Legal');
    newFile.append('property', '');
    newFile.append('partnerId', partnerId);

    await api.post(`/fileManager`, newFile, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
};

export const saveFirstStep = async (token, data, userId, id) => {
  api.defaults.headers.Authorization = `Bearer ${token}`;

  const dto = {
    isPF: data.isPF,
    email: data.email != '' ? data.email : null,
    user_id: userId,
    isAttorney: data.isAttorney,
  };

  if (data.isPF === true) {
    dto.documentPF = removeMask(data.document);
    dto.name = data.name;
    dto.lastName = data.lastName;
    dto.dateBirth = data.dateBirth;
    dto.cityBirth = data.cityBirth;
    dto.stateBirth = data.stateBirth;
    dto.countryBirth = data.countryBirth;
    dto.motherName = data.motherName;
    dto.fatherName = data.fatherName;
  } else {
    dto.documentPJ = removeMask(data.document);
    dto.companyName = data.companyName;
    dto.openDate = data.issuingExpeditionDate;
    dto.issuingExpeditionDate = data.issuingExpeditionDate;
  }

  if (id) {
    const partner = await api.put(`/register/partners/step-1/${id}`, dto);
    return partner.data._id;
  }
  const partner = await api.post('/register/partners/step-1', dto);
  return partner.data._id;
};

export const saveSecondStep = async (token, data, id) => {
  api.defaults.headers.Authorization = `Bearer ${token}`;

  const dto = {
    documentType: data.documentType,
    documentNumber: data.documentNumber,
    issuingExpeditionDate:
      data.issuingExpeditionDate != '' ? data.issuingExpeditionDate : null,
    issuingState: data.issuingState,
    issuingAgency: data.issuingAgency,
    issuingCountry: data.issuingCountry,
  };

  await api.put(`/register/partners/step-2/${id}`, dto);
};

export const saveThirdStep = async (token, data, id) => {
  api.defaults.headers.Authorization = `Bearer ${token}`;

  const dto = {
    addressZipcode: data.addressZipcode.replace('-', '').replace('.', ''),
    addressStreet: data.addressStreet,
    addressNumber: data.addressNumber,
    addressComplement: data.addressComplement,
    addressNeighborhood: data.addressNeighborhood,
    addressState: data.addressState,
    addressCity: data.addressCity,
    addressCountry: data.addressCountry,
    addressTypeId: data.addressTypeId,
    internationalAddress: data.internationalAddress,
  };
  await api.put(`/register/partners/step-3/${id}`, dto);
};

export const saveFourthStep = async (token, data, id) => {
  api.defaults.headers.Authorization = `Bearer ${token}`;

  const dto = {
    role: data.role,
    quota: isNaN(data.quota) ? 0 : Number(data.quota),
    incomePartner: data.income,
    patrimonyPartner: data.patrimony,
    buy_financial: data.buy_financial,
    sell_financial: data.sell_financial,
    import: data.import,
    export: data.export,
    pep: data.pep,
    reside_abroad: data.reside_abroad,
  };

  if (!data.income) delete dto.incomePartner;

  if (!data.patrimony) delete dto.patrimonyPartner;

  await api.put(`/register/partners/step-4/${id}`, dto);
};
