import { CheckboxGroup, RadioGroup } from 'rsuite';
import styled from 'styled-components';
import { StepperContent } from '~/pages/Checkout/styles';

export const Container = styled.div`
  /* display: grid;
  grid-template-columns: 2fr 3fr 2fr; */

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  ul {
    width: 450px;
    margin-right: 64px;
  }

  ${StepperContent} {
    display: flex;
    flex-direction: column;
    flex: 1;

    &::after {
      top: 4px;
      left: -33px !important;
    }
  }
`;

export const RadioGroupContainer = styled(RadioGroup)`
  display: flex;

  .rs-radio + .rs-radio {
    margin-left: 16px;
  }
`;

export const CheckboxGroupContainer = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
`;

export const SidebarOrder = styled.div`
  width: 400px;
`;

export const StepperColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  margin-left: 16px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 80%;
  }
`;

export const ReceiverRadioWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardboxWrapper = styled.div`
  display: flex;
  width: 325px;
  justify-content: space-between;
`;
