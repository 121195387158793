import React, { Fragment } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import NewChat from '~/pages/NewChat';

export const HistoryView = styled.div`
  position: relative;
  width: 100%;
  @media (min-width: 768px) {
    height: calc(100vh - 80px);
    width: 50%;
    padding: 32px 0 0 0;
  }
`;

export const Chat = styled.div`
  margin: 20px 0;
  display: inline-block;
  width: 100%;
`;

export const StyledChatWidget = styled(Chat)``;

export const StyledChatAgent = styled(Chat)`
  display: flex;
`;

export const ChatAgentFigure = styled.figure`
  margin-right: 24px;

  & img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`;

export const ChatAgentPhoto = styled.img``;

export const Baloon = styled.span`
  box-shadow: 0px 15px 25px rgba(0, 159, 217, 0.1),
    0px 2px 7px rgba(9, 178, 230, 0.2), 0px 30px 30px rgba(9, 178, 230, 0.05);
  border-radius: 0px 10px 10px 10px;
  padding: 16px 18px;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  max-width: 400px;
  width: fit-content;
  display: block;
`;

export const BaloonWidget = styled(Baloon)`
  background: #ffffff;
  border: 1px solid rgba(112, 124, 151, 0.25);
  color: #455566;
  box-shadow: 15px 15px 35px rgba(112, 124, 151, 0.05),
    10px 10px 25px rgba(112, 124, 151, 0.05);
  margin-left: auto;
`;

export const BaloonAgent = styled(Baloon)`
  background: #0fc7ff;
`;

export const StyledChatTime = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin: 30px 0;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background: rgba(112, 124, 151, 0.15);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    left: 0;
  }
`;

export const ChatTimeLapsed = styled.span`
  color: rgba(69, 85, 102, 0.5);
  text-align: center;
  background: #fff;
  padding: 0 16px;
  z-index: 2;
  position: relative;
`;

export const StyledUnselectedHistory = styled.div`
  text-align: center;
  min-height: calc(100vh - 150px);
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 30px;

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const UnselectedTitle = styled.h2`
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  color: rgba(32, 49, 91, 0.3);
  padding-top: 32px;
  width: 100%;
  max-width: 400px;
`;

export const ChatWrapper = styled.div`
  background: #fff;
  overflow: auto;
  padding-bottom: 86px;

  @media (min-width: 768px) {
    max-height: calc(100vh - 112px);
    padding: 0 16px 16px;
  }
`;

export const ChatAgent = ({ photo, message }) => {
  return (
    <StyledChatAgent>
      <ChatAgentFigure>
        <ChatAgentPhoto src={photo} />
      </ChatAgentFigure>
      <BaloonAgent>{message}</BaloonAgent>
    </StyledChatAgent>
  );
};

export const ChatWidget = ({ message }) => {
  return (
    <StyledChatWidget>
      <BaloonWidget>{message}</BaloonWidget>
    </StyledChatWidget>
  );
};

export const ChatTime = ({ time }) => {
  return (
    <StyledChatTime>
      <ChatTimeLapsed>{time}</ChatTimeLapsed>
    </StyledChatTime>
  );
};

export const ChatAfterTime = ({ data, lessTime = false }) => (
  <>
    <ChatTime
      time={
        lessTime
          ? `Há ${dayjs(data.sendAt).format('mm')} minutos atrás`
          : dayjs(data.sendAt).format('DD/MM/YYYY - HH:mm')
      }
    />
    {data.senderType === 'agent' ? (
      <ChatAgent photo={data.sender.photo} message={data.text} key={data.id} />
    ) : (
      <ChatWidget message={data.text} key={data.id} />
    )}
  </>
);

const NewChatView = styled.div`
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    height: calc(100vh - 80px);
    width: 767px;
    max-width: 100%;
  }
`;

export const IframeSrc = styled.iframe`
  width: 100%;
  min-height: calc(100vh - 80px);
  border: 0 none;
`;

export const PageHolder = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
  border: 0 none;
`;

export const Iframe = (props) => {
  const { src, id, ...rest } = props;
  return (
    <NewChatView>
      <IframeSrc id={id} src={src} {...rest} />
    </NewChatView>
  );
};

export const NewChatIframe = ({ src, id }) => {
  return (
    <NewChatView>
      <PageHolder>
        <NewChat />
      </PageHolder>
    </NewChatView>
  );
};
