import { string } from 'prop-types';
import { toast } from 'react-toastify';
import { takeEvery, call, put, all, select } from 'redux-saga/effects';
import { api, exchangeAPI } from '~/services/api';
import cpfMask from '../../../mask/cpf';
import { calculateIof } from '../../../utils/calculateIOF';
import {
  addProducts,
  addCurrencyTax,
  createTicket,
  disableLoadingOnExchange,
  enableLoadingOnExchange,
  updateTicket,
  setDeliveryAddressList,
  setDeliveryAddress,
  updateUserLimits,
  setCheckoutStep,
} from './actions';

export function* getProducts() {
  put(enableLoadingOnExchange());
  try {
    const { data } = yield call(api.get, '/exchange/products');

    yield put(addProducts(data));
  } catch {
    toast.error(
      'Falha ao obter cotações de câmbio. Tente novamente em alguns instantes.'
    );
  } finally {
    put(disableLoadingOnExchange());
  }
}

export function* getProductTax({ payload }) {
  put(enableLoadingOnExchange());
  try {
    const baseUsdResp = yield call(
      api.get,
      `/exchange/taxes?codeAgent=DIGITAL&codeProduct=USD`
    );
    const { currency } = payload;

    let currencyData = baseUsdResp.data;

    if (currency !== 'USD') {
      const { data } = yield call(
        api.get,
        `/exchange/taxes?codeAgent=DIGITAL&codeProduct=${currency}`
      );
      currencyData = data || {};
    }

    yield put(addCurrencyTax(currency, currencyData, baseUsdResp.data));
  } catch {
    toast.error(
      'Falha ao obter cotação de câmbio. Tente novamente em alguns instantes.'
    );
  } finally {
    put(disableLoadingOnExchange());
  }
}

export function* generateTickets({ payload }) {
  const getUser = (state) => state.user;
  const getExchange = (state) => state.exchange;
  put(disableLoadingOnExchange());

  const user = yield select(getUser);
  const exchange = yield select(getExchange);

  try {
    put(enableLoadingOnExchange());
    const response = yield call(api.post, `/exchange/exchange_tickets`, {
      code_agent: 'DIGITAL',
      delivery_local: 'DELIVERY',
      delivery_date: exchange.selectedProduct.deliveryDate,
      code_client: user.profile?.gnCambioId,
      code_product: exchange.selectedProduct.currencyTo,
      client_address_delivery_id: exchange.address.id,
      product_type: exchange.selectedProduct.productType,
      expense_value: 0,
      tax_id: exchange.selectedProduct.taxId,
      foreign_currency_value:
        exchange.selectedProduct.currencyFromValue /
        exchange.selectedProduct.currencyToTax.toFixed(6),
      nacional_currency_value: exchange.selectedProduct.currencyFromValue,
      tax_value: exchange.selectedProduct.currencyToTax,
      iof: calculateIof(
        exchange.selectedProduct.currencyFromValue,
        exchange.selectedProduct.iof || exchange.defaultIof
      ).toFixed(2),
      exchange_type: payload.payload.exchange_type,
      receiver: exchange.receiver,
    });
    yield put(createTicket(response));
    yield put(setCheckoutStep(3));
  } catch {
    yield put(setCheckoutStep(2));
    toast.error(
      'Falha ao gerar ticket de pagamento. Por favor, entre em contato com o suporte.'
    );
  } finally {
    yield put(disableLoadingOnExchange());
  }
}

export function* generateTicketToCard({ payload }) {
  put(enableLoadingOnExchange());
  const getUser = (state) => state.user;
  const getExchange = (state) => state.exchange;

  const user = yield select(getUser);
  const exchange = yield select(getExchange);

  const data = {
    code_product: exchange.selectedProduct.productId,
    product_type: 'CARTAO',
    foreign_currency_value: Number(
      exchange.selectedProduct.currencyToValue
    ).toFixed(2),
    nacional_currency_value: Number(
      exchange.selectedProduct.currencyFromValue
    ).toFixed(2),
    tax_value: exchange.selectedProduct.currencyToTax,
    tax_id: exchange.selectedProduct.taxId,
    delivery_date: exchange.selectedProduct.deliveryDate,
    iof: calculateIof(
      exchange.selectedProduct.currencyFromValue,
      exchange.selectedProduct.iof || exchange.defaultIof
    ).toFixed(2),
    code_client: user.profile?.gnCambioId,
    client_address_delivery_id: exchange.address.id,
    exchange_card: {
      ...payload.payload.exchangecard,
      code_client: user.profile?.gnCambioId,
      document: cpfMask(user.profile.document),
      email: user.profile.email,
      code_currency: exchange.selectedProduct.productId,
      titular_name: `${user.profile?.name} ${user.profile?.lastName}`,
      rg: user.profile?.identificationDocument,
      card_number: '',
      mother_name: user.profile?.motherName,
      traveler: exchange.user.name,
      genre_traveler: exchange.user.gender || 'M',
      birth_date: new Date(exchange.user.birthDate),
      travel_date: new Date(),
    },
    exchange_type: payload.payload.exchange_type,
    receiver: exchange.receiver,
    ...payload.payload,
  };

  try {
    const response = yield call(api.post, `/exchange/exchange_tickets`, data);
    yield put(createTicket(response));
    yield put(setCheckoutStep(3));
  } catch {
    yield put(setCheckoutStep(2));
    toast.error(
      'Falha ao gerar ticket de pagamento. Por favor, entre em contato com o suporte.'
    );
  } finally {
    put(disableLoadingOnExchange());
  }
}

export function* confirmPayment({ payload }) {
  put(disableLoadingOnExchange());
  try {
    if (payload.payload.paymentMethod === 'CARTAO') {
      payload.payload.paymentMethod = 'CRT_DIG';
    }

    if (payload.payload.paymentMethod === 'PIX') {
      payload.payload.paymentMethod = 'PIX_DIG';
    }

    if (payload.payload.paymentMethod === 'TED') {
      payload.payload.paymentMethod = 'TED_DIG';
    }

    if (payload.payload.paymentMethod === 'BOLETO') {
      payload.payload.paymentMethod = 'BLT_DIG';
    }

    const response = yield call(
      api.post,
      `/exchange/exchange_tickets/confirm`,
      {
        ...payload.payload,
      }
    );

    put(enableLoadingOnExchange());
    yield put(updateTicket({ ...response, new: true }));
  } catch (error) {
    yield put(disableLoadingOnExchange());

    if (error.response.status == 400) {
      const errorMessage = error.response.data.message.includes(
        'security_code_length'
      )
        ? 'Código de segurança inválido'
        : error.response.data.message;
      toast.error(errorMessage);
    } else {
      toast.error(
        'Falha ao gerar ticket de pagamento. Por favor, entre em contato com o suporte.'
      );
    }
  }
}

export function* getDeliveryAddress({ payload }) {
  const getUser = (state) => state.user;
  const getToken = (state) => state.auth;
  const user = yield select(getUser);
  const token = yield select(getToken);

  try {
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token.token}`;
      const response = yield call(
        api.get,
        `/delivery_address/${user.profile._id}`
      );
      yield put(setDeliveryAddressList(response.data));
    }
  } catch {
    toast.error('Falha ao adicionar endereço.');
  }
}

export function* generateDeliveryAddress({ payload }) {
  const getUser = (state) => state.user;
  const getToken = (state) => state.auth;
  const user = yield select(getUser);
  const token = yield select(getToken);

  const { id, ...addressData } = payload;

  const mountedPayload = {
    user_id: user.profile._id,
    ...addressData,
  };

  try {
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token.token}`;
      let response;

      if (id) {
        yield call(api.put, `/delivery_address/${id}`, mountedPayload);

        yield put(setDeliveryAddress(payload));
      } else {
        response = yield call(api.post, `/delivery_address`, mountedPayload);
        yield put(
          setDeliveryAddress({ ...response.data, id: response.data.address_id })
        );
      }
    }
  } catch (e) {
    toast.error('Falha ao adicionar endereço.');
  }
}

export function* getUserLimits() {
  put(enableLoadingOnExchange());
  const getUser = (state) => state.user;
  const user = yield select(getUser);

  const codeClient = user.profile.gnCambioId;

  if (codeClient) {
    try {
      const response = yield call(
        api.get,
        `/exchange/limits?codeClient=${codeClient}`
      );
      yield put(updateUserLimits(response));
    } catch {
      toast.error(
        'Falha ao consultar limite de transações. Por favor, entre em contato com o suporte.'
      );
    } finally {
      put(disableLoadingOnExchange());
    }
  }
}

export default all([
  takeEvery('@exchange/GET_PRODUCTS', getProducts),
  takeEvery('@exchange/GET_TAX', getProductTax),
  takeEvery('@exchange/GENERATE_TICKET', generateTickets),
  takeEvery('@exchange/PAYMENT_ORDER', confirmPayment),
  takeEvery('@exchange/GET_DELIVERY_ADDRESS_LIST', getDeliveryAddress),
  takeEvery('@exchange/ADD_ADDRESS', generateDeliveryAddress),
  takeEvery('@exchange/GENERATE_TICKET_FOR_CARD', generateTicketToCard),
  takeEvery('@exchange/GET_USER_LIMITS', getUserLimits),
]);
