import PropTypes from 'prop-types';
import React from 'react';
import { ControlLabel, FormGroup, Input } from 'rsuite';
import styled from 'styled-components';

export const CustomFormGroup = styled(FormGroup)`
  margin: 0 0 24px 0;
`;

const CustomInput = styled(Input)`
  margin: 5px 0 5px 0;
  height: 42px;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #d3dce6;
  padding-left: 10px;
`;

export default class FormTextField extends React.Component {
  render() {
    return (
      <>
        {this.props.labelText && (
          <ControlLabel>{this.props.labelText}</ControlLabel>
        )}
        <CustomFormGroup controlId={this.props.fieldName}>
          <CustomInput
            name={this.props.fieldName}
            id={this.props.fieldName}
            type={this.props.inputType ?? 'text'}
            maxLength={this.props.maxLength}
            max={
              this.props.inputType === 'date'
                ? this.props.maxDate
                : this.props.max
            }
            min={this.props.min}
            step={this.props.step}
            onChange={this.props.handleOnChange}
            onBlur={this.props.handleOnBlur}
            value={this.props.value}
            style={
              this.props.style ?? {
                width: '100%',
                borderColor:
                  this.props.touched && this.props.error ? '#f00' : '#ccc',
              }
            }
            disabled={this.props.disabled ?? false}
          />
          {this.props.error && this.props.touched && (
            <div>
              <span style={{ color: '#EF3131' }}>{this.props.error}</span>
            </div>
          )}
        </CustomFormGroup>
      </>
    );
  }
}
FormTextField.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.bool,
  labelText: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  maxDate: PropTypes.string,
  style: PropTypes.object,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.string.isRequired,
  handleOnBlur: PropTypes.func,
  handleOnChange: PropTypes.func,
};
