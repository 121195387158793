import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { Formik } from 'formik';
import { XCircle } from 'react-feather';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Checkbox, Form, Modal, Row } from 'rsuite';
import * as Yup from 'yup';
import { CircleFlag as Flag } from 'react-circle-flags';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import contractApi from '~/services/contractApi';
import IconError from '../../assets/iconsContract/erro.svg';
import IconSuccess from '../../assets/iconsContract/iconSuccess.svg';
import Button from '../Button';
import EmptyContainer from '../EmptyContainer';
import ModalSignature from '../ModalsContract/ModalSignature';

import { api } from '~/services/api';

import {
  Btn,
  BtnCancel,
  BtnContent,
  BtnContentModal,
  ContentModal,
  ContentModalContainer,
  ContentModalHeader,
  ContentModalHighlight,
  ContentModalHighlightContent,
  ContentModalTerms,
  ContentModalText,
  ContentModalTitle,
  HeaderCell,
  InputSign,
  LoaderSign,
  ModalCustom,
  Status,
  SubTitleModal,
  SubTitleResultModal,
  Table,
  TitleLoader,
  TitleModal,
  ModalFooter,
  PaginationHolder,
  PaginationButton,
  PaginationLabel,
} from './styles';
import Loading from '../Loading';

const { Column, Cell } = Table;

const ContractTable = ({
  dados,
  codClient,
  cpf,
  isLoading,
  setContractLoading,
  documentClient,
  setContractsList,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [show, setShow] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [token, setToken] = useState('');
  const [modalStep, setModalStep] = useState(0);
  const [isAgreeTerm, setAgreeTerm] = useState(false);
  const [page, setPage] = useState(1);

  const { profile } = useSelector((state) => state.user);

  const contractSign = Yup.object().shape({
    key: Yup.string().required('Obrigatório'),
    token: Yup.string().required('Obrigatório'),
    contracts: Yup.array().required('Obrigatório'),
    codClient: Yup.string().required('Obrigatório'),
    cpf: Yup.string().required('Obrigatório'),
  });

  const getDados = () => {
    const data = dados;
    return data;
  };

  const agreeBacen = () => {
    setModalStep(1);
  };

  const [loading, setLoading] = React.useState(false);

  async function getContracts() {
    try {
      const { data } = await api.get(
        `/contracts/to_sign?code_client=${documentClient}&cpf=${cpf
          .replaceAll('.', '')
          .replace('-', '')}`
      );

      setContractsList(data);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  const signatureContract = async () => {
    try {
      setLoading(true);

      const codCli = codClient;
      const clientCpf = cpf;

      const { data } = await contractApi.get(
        `/contracts/auth_sign?cpf=${clientCpf
          .replaceAll('.', '')
          .replace('-', '')}&code_client=${codCli}&email=${profile?.email}`
      );

      setToken(data.authorization_token);

      setModalStep(2);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSignContract = async (data) => {
    try {
      const cod = data.codClient;
      const codCli = `${cod}`;
      data.codClient = codCli;

      setModalStep(3);
      await contractApi.post('/contracts/sign', {
        key: data.key,
        token: data.token,
        contracts: data.contracts,
        codClient: data.codClient,
        cpf: data.cpf,
      });

      setModalStep(4);
      window.location.reload();
    } catch (e) {
      setModalStep(5);
    }
  };

  const handleModal = () => {
    setShow(true);
  };

  const handleModalClose = () => {
    setShow(false);
    setModalStep(0);
    setAgreeTerm(false);
    setContracts([]);
    setDisabled(true);
    setCheckAll(false);
  };

  const handleCheck = (value, checked) => {
    const keys = checked
      ? [...contracts, value]
      : contracts.filter((item) => item !== value);
    setContracts(keys);

    if (keys.length > 0) setDisabled(false);
    else setDisabled(true);
  };

  const handleCheckAll = (_value, checked) => {
    if (checked) {
      setCheckAll(true);
      setContracts(dados);
      setDisabled(false);
    } else {
      setContracts([]);
      setDisabled(true);
      setCheckAll(false);
    }
  };

  const CheckCell = ({ rowData, onChange, contracts, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <Checkbox
          onChange={onChange}
          value={rowData}
          checked={contracts.some(
            (item) => item.id_contract_in_bacen === rowData.id_contract_in_bacen
          )}
        />
      </Cell>
    );
  };

  const [checkAll, setCheckAll] = React.useState(false);

  useEffect(() => {
    if (documentClient && page) {
      getContracts();
    }
  }, [documentClient, page]);

  return (
    <>
      {!isLoading && !getDados().length && (
        <EmptyContainer hasData={codClient} />
      )}
      {!isLoading && getDados().length && (
        <div>
          <Row>
            <Table
              virtualized
              headerHeight={70}
              rowHeight={56}
              height={480}
              id='table'
              data={getDados()}
              loading={isLoading}
            >
              {profile?.document.length === 11 && (
                <Column width={70} verticalAlign='middle'>
                  <HeaderCell>
                    <Checkbox
                      checked={checkAll}
                      onChange={handleCheckAll}
                      style={{ bottom: '8px' }}
                    />
                  </HeaderCell>
                  <CheckCell
                    dataKey='id'
                    contracts={contracts}
                    onChange={handleCheck}
                  />
                </Column>
              )}

              <Column width={70} align='center' verticalAlign='middle'>
                <HeaderCell>#</HeaderCell>
                <Cell dataKey='id'>
                  {(_rowData, order) => (
                    <span className='spanColor'>{order + 1}</span>
                  )}
                </Cell>
              </Column>

              <Column width={240} verticalAlign='middle'>
                <HeaderCell>Tipo de operação</HeaderCell>
                <Cell dataKey='op'>
                  {(rowData) => rowData.type_contract_name}
                </Cell>
              </Column>

              <Column width={240} verticalAlign='middle'>
                <HeaderCell>Moeda e valor</HeaderCell>
                <Cell dataKey='valor'>
                  {(rowData) => {
                    return (
                      <span>
                        {rowData.currency === 'EUR' ? (
                          <img
                            src='/icons/uniao-europeia.png'
                            className='euro'
                            style={{ height: '27px', marginLeft: '-7px' }}
                          />
                        ) : (
                          <Flag
                            countryCode={rowData.currency
                              .substr(0, 2)
                              .toLowerCase()}
                            height={25}
                          />
                        )}{' '}
                        {rowData.currency}{' '}
                        {rowData.value_foreign_currency.toLocaleString(
                          'pt-BR',
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </span>
                    );
                  }}
                </Cell>
              </Column>

              <Column width={240} verticalAlign='middle'>
                <HeaderCell>Data do contrato</HeaderCell>
                <Cell dataKey='data'>
                  {(rowData) => {
                    const data = format(
                      new Date(parseISO(rowData.contract_date)),
                      'dd/MM/yyyy'
                    );
                    return data;
                  }}
                </Cell>
              </Column>

              <Column width={240} verticalAlign='middle'>
                <HeaderCell>Nº do contrato</HeaderCell>
                <Cell dataKey='contrato'>
                  {(rowData) => rowData.id_contract_in_bacen}
                </Cell>
              </Column>

              <Column width={240} verticalAlign='middle'>
                <HeaderCell>Status do contrato</HeaderCell>
                <Cell>
                  <Status>AGUARDANDO ASSINATURA</Status>
                </Cell>
              </Column>

              <Column width={240} verticalAlign='middle'>
                <HeaderCell />
                <Cell>
                  {(rowData) => {
                    async function handlePdf() {
                      setContractLoading(true);
                      const a = document.createElement('a');
                      document.body.appendChild(a);
                      a.style = 'display: none';
                      const token = rowData.token_download;

                      await contractApi
                        .get(`/contracts/download?token_download=${token}`, {
                          responseType: 'blob',
                        })
                        .then((res) => {
                          const blob = new Blob([res.data], {
                            type: 'octet/stream',
                          });
                          const url = window.URL.createObjectURL(blob);

                          a.href = url;
                          a.download = 'contrato.pdf';
                          a.click();
                          setContractLoading(false);
                        })
                        .catch((_err) => {
                          setContractLoading(false);
                          toast.error(
                            'Não foi possível realizar o download agora. Por favor, tente novamente mais tarde.'
                          );
                        });
                    }
                    return (
                      <Button
                        style={{ bottom: '4px', width: '150px' }}
                        ghost
                        onClick={handlePdf}
                      >
                        Download PDF
                      </Button>
                    );
                  }}
                </Cell>
              </Column>
            </Table>
            <PaginationHolder>
              {profile?.document.length === 11 && (
                <BtnContent>
                  <Button md onClick={handleModal} disabled={disabled}>
                    Assinar contratos
                  </Button>
                </BtnContent>
              )}
              <div>
                <PaginationButton
                  disabled={page === 1}
                  onClick={() => {
                    setPage(page - 1);
                    setLoading(true);
                  }}
                >
                  <FiChevronLeft size={25} color={page !== 1 && '#0fc7ff'} />
                </PaginationButton>
                <PaginationLabel>{page}</PaginationLabel>
                <PaginationButton
                  disabled={dados.length < 50}
                  onClick={() => {
                    setPage(page + 1);
                    setLoading(true);
                  }}
                >
                  <FiChevronRight
                    size={25}
                    color={dados.length === 50 && '#0fc7ff'}
                  />
                </PaginationButton>
              </div>
            </PaginationHolder>
          </Row>
        </div>
      )}

      {isLoading && <Loading text='Aguarde...' />}

      <ModalCustom
        show={show}
        onHide={handleModalClose}
        size='md'
        style={{ padding: '16px 0 0 0' }}
      >
        <Modal.Body>
          {modalStep === 0 && (
            <>
              <ContentModalTerms>
                <ContentModalHeader>
                  {/* <img src={BacenLogo} alt='BACEN' /> */}
                  <h4 style={{ color: '#20315B' }}>Termos de aceite</h4>
                  <XCircle
                    color='#0FC7FF'
                    size={33}
                    style={{ cursor: 'pointer' }}
                    onClick={handleModalClose}
                  />
                </ContentModalHeader>
                <ContentModalContainer>
                  <ContentModalTitle>
                    CIRCULAR Nº 3.829, DE 9 DE MARÇO DE 2017
                  </ContentModalTitle>
                  <ContentModalText>
                    Altera a Circular nº 3.691, de 16 de dezembro de 2013, a fim
                    de permitir a utilização de assinaturas eletrônicas em
                    contratos de câmbio em qualquer formato admitido pelas
                    partes como válido e aceito pela pessoa a quem for oposto o
                    documento.
                    <br />
                    <br />
                    A Diretoria Colegiada do Banco Central do Brasil, em sessão
                    realizada em 8 de março de 2017, com base nos arts. 23 e 24
                    da Lei nº 4.131, de 3 de setembro de 1962, nos arts. 9º, 10,
                    inciso VII, e 11, inciso III, da Lei nº 4.595, de 31 de
                    dezembro de 1964, e tendo em vista o § 2º do art. 10 da
                    Medida Provisória nº 2.200-2, de 24 de agosto de 2001,
                    <br />
                    <br />
                    <strong>R E S O L V E :</strong>
                    <br />
                    Art. 1º Os arts. 42, 43 e 138 da Circular nº 3.691, de 16 de
                    dezembro de 2013, passam a vigorar com as seguintes
                    alterações:
                    <br />
                    <br />
                    “Art. 42.
                    ..........................................................................................................
                    <br />
                    <br />
                    I - é permitido o uso de assinatura eletrônica;
                    <br />
                    <br />
                    .........................................................................................................................
                    <br />
                    <br />
                    § 1º Considera-se assinatura eletrônica, para fins do
                    disposto no inciso I do caput, as seguintes formas de
                    identificação inequívoca do signatário:
                    <br />
                    <br />
                    I - certificados digitais emitidos no âmbito da
                    Infraestrutura de Chaves Públicas (ICP-Brasil); ou
                    <br />
                    <br />
                    II - outros meios de comprovação de autoria e integridade de
                    documentos de forma eletrônica, inclusive os que utilizem
                    certificados não emitidos pela ICP-Brasil, desde que
                    admitidos pelas partes como válidos, na forma da legislação
                    em vigor.
                    <br />
                    <br />
                    § 2º No caso de utilização de assinatura eletrônica, é de
                    exclusiva responsabilidade da instituição autorizada a
                    operar no mercado de câmbio assegurar o cumprimento da
                    legislação em vigor, garantindo a autenticidade e a
                    integridade do documento eletrônico, bem como das
                    respectivas assinaturas eletrônicas, incluindo-se a alçada
                    dos demais signatários.” (NR)
                    <br />
                    <br />
                    “Art. 43. No caso de uso de assinatura eletrônica no
                    contrato de câmbio, a instituição autorizada a operar no
                    mercado de câmbio, negociadora da moeda estrangeira, deve:
                    <br />
                    <br />
                    .........................................................................................................................
                    <br />
                    <br />
                    II - estar apta a tornar disponível, de forma imediata, ao
                    Banco Central do Brasil, pelo prazo de cinco anos, contados
                    do término do exercício em que Circular nº 3.829, de 9 de
                    março de 2017 Página 2 de 2 ocorra a contratação ou, se
                    houver, a liquidação, o cancelamento ou a baixa, a impressão
                    do contrato de câmbio e dele fazer constar a expressão
                    “contrato de câmbio assinado eletronicamente”;
                    <br />
                    <br />
                    III - manter pelo prazo de cinco anos o documento eletrônico
                    com as informações do contrato de câmbio e as respectivas
                    assinaturas eletrônicas, bem como a comprovação de que o
                    mecanismo empregado para assinatura eletrônica corresponde
                    àqueles previstos no § 1º do art. 42.
                    <br />
                    <br />
                    Parágrafo único. No caso de contrato de câmbio assinado por
                    meio de utilização de certificados digitais emitidos no
                    âmbito da ICP-Brasil, é admitida, até 31 de dezembro de
                    2017, a utilização da expressão “contrato de câmbio assinado
                    digitalmente” para fins de atendimento do inciso II.” (NR)
                    <br />
                    <br />
                    “Art. 138. Ressalvadas as disposições específicas previstas
                    na legislação em vigor, os documentos vinculados a operações
                    no mercado de câmbio devem ser mantidos em arquivo da
                    instituição autorizada a operar no mercado de câmbio, em
                    meio físico ou eletrônico, pelo prazo de cinco anos contados
                    do término do exercício em que ocorra a contratação ou, se
                    houver, a liquidação, o cancelamento ou a baixa, observado
                    que, quando solicitado, devem ser disponibilizados de forma
                    imediata e sem ônus para o Banco Central do Brasil.” (NR)
                    <br />
                    <br />
                    Art. 2º Esta Circular entra em vigor na data de sua
                    publicação.
                    <br />
                    <br />
                    Art. 3º Ficam revogados o inciso I do art. 43, os incisos I
                    e II do art. 138 e o parágrafo único do art. 139 da Circular
                    nº 3.691, de 16 de dezembro de 2013.
                    <br />
                    <br />
                    <strong>Otávio Ribeiro Damaso</strong>
                    <br />
                    Diretor de Regulação
                    <br />
                    <br />
                    <strong>Anthero de Moraes Meirelles</strong>
                    <br />
                    Diretor de Fiscalização
                    <br />
                    <br />
                    <strong>Luiz Edson Feltrim</strong>
                    <br />
                    Diretor de Administração
                    <br />
                    <br />
                  </ContentModalText>
                </ContentModalContainer>
                <ContentModalHighlight>
                  <ContentModalHighlightContent>
                    Esse texto não substitui o publicado no DOU de 13/3/2017,
                    Seção 1, P. 15/16, e no Sisbacen.
                  </ContentModalHighlightContent>
                </ContentModalHighlight>
                {/* <CheckboxHolder>
                  <Checkbox
                    onChange={(value) => setAgreeTerm(value)}
                    value={!isAgreeTerm}
                  >
                    Eu estou de acordo com os termos do Banco Central
                  </Checkbox>
                </CheckboxHolder> */}
              </ContentModalTerms>
              {/* <BtnContentModal>
                <Button lg onClick={agreeBacen} disabled={!isAgreeTerm}>
                  Continuar
                </Button>

                <BtnCancel appearance='link' onClick={handleModalClose}>
                  Cancelar
                </BtnCancel>
              </BtnContentModal> */}
            </>
          )}
          {modalStep === 1 && (
            <>
              <ContentModal>
                <ModalSignature
                  dados={contracts}
                  cancelHandler={handleModalClose}
                />
              </ContentModal>
              <BtnContentModal>
                <BtnCancel md onClick={handleModalClose}>
                  Cancelar
                </BtnCancel>{' '}
                <Btn loading={loading} onClick={signatureContract}>
                  Confirmar
                </Btn>
              </BtnContentModal>
            </>
          )}
          {modalStep === 2 && (
            <ContentModal>
              {/* <img src={IconKey} alt='Ícone código de segurança' /> */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  position: 'relative',
                }}
              >
                <TitleModal>Insira o código de segurança</TitleModal>
              </div>
              <SubTitleModal>
                Enviamos em seu email um token de 6 dígitos numéricos para
                validar sua assinatura
              </SubTitleModal>
              <Formik
                initialValues={{
                  key: '',
                  token,
                  contracts: contracts.map((data) => data.operation_number),
                  codClient,
                  cpf,
                }}
                validationSchema={contractSign}
                onSubmit={(values, action) => {}}
              >
                {({
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  isValid,
                  touched,
                  handleSubmit,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <InputSign
                        className='input'
                        onChange={(value, event) => handleChange(event)}
                        onBlur={handleBlur}
                        name='key'
                        id='key'
                        maxLength={6}
                        value={values.key}
                        type='text'
                        placeholder='0 . 0 . 0 . 0 . 0 . 0'
                      />

                      <BtnContentModal>
                        <BtnCancel onClick={handleModalClose}>
                          Cancelar
                        </BtnCancel>
                        <Btn
                          type='submit'
                          disabled={String(values.key).length < 6}
                          onClick={() => handleSignContract(values)}
                        >
                          Confirmar
                        </Btn>
                      </BtnContentModal>
                    </Form>
                  );
                }}
              </Formik>
            </ContentModal>
          )}
          {modalStep === 3 && (
            <ContentModal>
              <LoaderSign size='lg' />
              <TitleLoader>Assinando...</TitleLoader>
            </ContentModal>
          )}
          {modalStep === 4 && (
            <ContentModal>
              <img src={IconSuccess} alt='' />
              {/* <TitleModal>Tudo certo!</TitleModal> */}
              <SubTitleResultModal>
                Contratos assinados com sucesso
              </SubTitleResultModal>
              <BtnContentModal>
                <Btn onClick={handleModalClose}>OK</Btn>
              </BtnContentModal>
            </ContentModal>
          )}
          {modalStep === 5 && (
            <ContentModal>
              <img src={IconError} alt='' />
              <TitleModal>Ops... algo deu errado</TitleModal>
              <SubTitleResultModal>
                O contrato não foi assinado
              </SubTitleResultModal>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  width: '100%',
                }}
              >
                <BtnCancel onClick={handleModalClose}>Cancelar</BtnCancel>{' '}
                <Btn onClick={() => setModalStep(2)}>Tentar novamente</Btn>
              </div>
            </ContentModal>
          )}
        </Modal.Body>
        {modalStep === 0 && (
          <ModalFooter>
            <div>
              <Checkbox
                onChange={(value) => setAgreeTerm(value)}
                value={!isAgreeTerm}
              >
                Eu estou de acordo com os termos do Banco Central
              </Checkbox>
            </div>
            <div style={{ display: 'flex' }}>
              <BtnCancel onClick={handleModalClose}>Cancelar</BtnCancel>{' '}
              <Button onClick={agreeBacen} disabled={!isAgreeTerm}>
                Continuar
              </Button>
            </div>
          </ModalFooter>
        )}
      </ModalCustom>
    </>
  );
};

export default ContractTable;
