import React from 'react';
import { Tag } from 'rsuite';
import styled, { withTheme } from 'styled-components';

const StyledTag = styled(Tag)`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  padding: 8.5px 16px;

  ${(props) =>
    props.small &&
    `
        padding: 4.5px 16px;
    `}

  ${(props) =>
    props.warning &&
    `
        background: ${props.theme.palette.action.light.warning};
        color: ${props.theme.palette.action.standard.warning};
    `}

    ${(props) =>
    props.danger &&
    `
        background: ${props.theme.palette.action.light.danger};
        color: ${props.theme.palette.action.standard.danger}
    `}

    ${(props) =>
    props.success &&
    `
        background: ${props.theme.palette.action.light.success};
        color: ${props.theme.palette.action.standard.success}
    `}
`;
export default withTheme((props) => (
  <StyledTag {...props}>{props.children}</StyledTag>
));
