import { Input, Modal } from 'rsuite';
import styled from 'styled-components';
import Button from '../../components/Button';

export const StyledInput = styled(Input)`
  margin: 5px 0 15px 0;
  height: 50px;
  width: 384px;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #d3dce6;
  padding-left: 10px;
`;

export const CustomModal = styled(Modal)`
  width: 100vw;
  height: 100vh;
  z-index: 50;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100vh;
  }

  @media (min-width: 800px) {
    width: 60%;
  }
`;

export const StyledTitle = styled.h2`
  color: #20315b;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin: 25px 0;
`;

export const StyledButton = styled(Button)`
  width: 141px;
  height: 48px;
  margin-top: 35px;
  background: linear-gradient(
    98.1deg,
    #48d3fe 28.81%,
    #0fc7ff 52.98%,
    #09b2e6 84.96%
  );

  @media screen and (max-width: 600px) {
    width: 95%;
  }
`;

export const DisplayGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;

  @media (max-width: 900px) {
  }
`;

export const FormGrid = styled.div`
  width: 100%;
  margin-left: 50px;

  a {
    color: #09b2e6;
    font-size: 12px;
  }

  .active {
    color: #455566;
    text-decoration: none;
  }

  .breadcrumb {
    margin: 20px 0;
  }

  @media (max-width: 900px) {
    padding: 0 16px 90px;
    margin: 0;
  }
`;

export const BackButton = styled.div`
  display: flex;
  color: #0fc7ff;
  font-weight: bold;
  font-size: 16px;
  margin-left: 15px;
  cursor: pointer;

  p {
    margin-left: 3px;
  }

  a {
    text-decoration: none;
    color: #0fc7ff;
  }
`;

export const FlexPhone = styled.div`
  display: flex;
  padding-top: 10px;
`;

export const DesktopDelete = styled.div`
  text-decoration-line: underline;
  color: #0fc7ff;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const MobileDelete = styled.div`
  text-decoration-line: underline;
  color: #0fc7ff;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const GeralColumn = styled.div``;

export const PasswordColumn = styled.div`
  .passwordText {
    width: 272px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #455566;
    margin-bottom: 33px;
  }
`;

export const AllForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  width: 100%;

  @media (max-width: 1145px) and (min-width: 300px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const AvatarColumn = styled.div`
  width: 192px;
  height: 192px;
  margin: 28px 28px;
  border-radius: 50%;
  background: #132552;
`;

export const Avatar = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarInitials = styled.span`
  font-size: 90px;
  color: #fff;
  font-weight: 100;
  text-transform: uppercase;
`;
