import React from 'react';
import styled from 'styled-components';
import { Input, SelectPicker, Tooltip } from 'rsuite';
import ProfessionIcon from '~/assets/profissao.svg';
import ProfessionMobileIcon from '~/assets/profissao_mobile.svg';

export const ProfessionIconImg = () => (
  <img src={ProfessionIcon} alt='Ícone de profissão' />
);
export const ProfessionMobileIconImg = () => (
  <img src={ProfessionMobileIcon} alt='Ícone de profissão' width='30' />
);

export const StyledInput = styled(Input)`
  margin: 0px 0 15px 0;
  height: 42px;
  width: 340px;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #d3dce6;
  padding-left: 10px;
`;

export const StyledSelect = styled(SelectPicker)`
  margin: 0px 0 15px 0;
  height: 42px;
  width: 340px;
  max-width: 100%;
`;

export const Title = styled.div`
  margin-bottom: 25px;
  color: #20315b;
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: block;
  width: 100%;

  @media (min-width: 768px) {
    width: initial;
    display: initial;
  }
`;

export const All = styled.div`
  display: grid;

  @media (max-width: 768px) {
    .breadcrumb {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const Fields = styled.div`
  font-family: IBM Plex Sans;
  font-size: 14px;
  line-height: 20px;
  color: #455566;

  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const BackButton = styled.div`
  display: flex;
  color: #0fc7ff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;

  p {
    margin-left: 3px;
  }

  a {
    text-decoration: none;
    color: #0fc7ff;
  }
`;

export const DisplayGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 35px;
  width: 100%;

  @media (min-width: 768px) {
    margin-left: 50px;
    margin-bottom: 35px;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 90px;
  }

  .rs-input {
    margin: 0px 0 15px 0;
    height: 42px;
    width: 340px;
    max-width: 100%;
    border-radius: 5px;
    border: 1px solid #d3dce6;
    padding-left: 10px;
  }

  .alignButton {
    margin: 0 25px;

    @media (max-width: 600px) {
      display: flex;
      justify-content: center;
    }

    @media (max-width: 1130px) {
      margin: 0 0 30px;
    }
  }

  button {
    margin: 20px 0px;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    @media screen and (max-width: 600px) {
      position: relative;
    }

    width: 141px;
    height: 48px;
    color: #fff;
    background: linear-gradient(
      138.72deg,
      #48d3fe 28.81%,
      #0fc7ff 52.98%,
      #09b2e6 84.96%
    );
    box-shadow: 0px 3px 20px rgba(114, 222, 255, 0.25),
      0px 2px 4px rgba(196, 241, 255, 0.25), 0px 5px 20px rgba(0, 159, 217, 0.2);
    border-radius: 30px;
    border: none;
  }

  @media (min-width: 768px) {
    margin-left: 50px;
  }

  @media (max-width: 1130px) {
    display: grid;
    grid-template-columns: 1fr;
    flex-direction: column;
  }
`;

export const FormGrid = styled.div`
  width: 90%;
  margin: 25px;

  a {
    color: #09b2e6;
    font-size: 12px;
  }

  .active {
    color: #455566;
    text-decoration: none;
  }
`;

export const DocumentFields = styled(Fields)`
  max-width: 300px;

  @media (min-width: 768px) {
    margin: 115px 0 0;
  }

  @media screen and (max-width: 1130px) {
    /* margin: 30px 50px; */
    padding: 0 25px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }

  ${(props) => props.withoutPadding && ` padding: 0 !important; `}
`;

export const InfoStatusComplete = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  display: flex;
  align-items: center;
  text-align: right;

  margin-top: 16px;

  color: #14cc70;
`;

export const InfoStatusIncomplete = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  display: flex;
  align-items: center;
  text-align: right;

  margin-top: 16px;

  color: #ef3131;
`;

export const InfoStatusOptional = styled.p`
  font-family: IBM Plex Sans;
  font-style: italic;
  font-size: 24px;
  line-height: 32px;

  display: flex;
  align-items: center;
  text-align: right;

  margin-top: 16px;

  color: #9ba8b7;
`;

export const TooltipLimit = styled(Tooltip)`
  .rs-tooltip-inner {
    background-color: #132552;
    border-radius: 5px;
    font-family: IBM Plex Sans;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    padding: 8px 8px;
  }

  .rs-tooltip-arrow {
    border-bottom-color: #132552;
  }
`;
