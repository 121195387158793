import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Progress, Whisper } from 'rsuite';

import { Info } from 'react-feather';
import {
  StyledCard,
  TitleCard,
  SubTitleCard,
  BodyCard,
  Icon,
  IconImg,
  IconImgMobile,
  CustomButton,
  InfoStatusOptional,
  InfoStatusComplete,
  InfoStatusIncomplete,
  BodyContainer,
  BankLength,
  TooltipLimit,
  BodyButton,
} from './styles';

const { Line } = Progress;

const Card = (props) => {
  const history = useHistory();
  const navigateTo = () => history.push(props.navigateTo);
  const { user } = useSelector((state) => state);

  const {
    icon,
    iconMobile,
    title,
    subtitle,
    percent,
    bankList,
    partnersPercentage,
    partners = [],
  } = props;

  const {
    importProducts,
    exportProducts,
    receiveAbroad,
    sendAbroad,
    travelMoney,
    amount,
  } = user.profile;

  const tooltip = (
    <TooltipLimit>
      Exigido somente para limites operacionais acima de R$ 120.000
    </TooltipLimit>
  );

  return (
    <StyledCard>
      <BodyCard>
        <Icon>
          <IconImg src={icon} alt='Ícone' />
          <IconImgMobile src={iconMobile} alt='Ícone' />
        </Icon>
        <div id='body'>
          <BodyContainer>
            <TitleCard> {title} </TitleCard>
            <SubTitleCard> {subtitle} </SubTitleCard>
          </BodyContainer>

          {![
            'Banco',
            'Representantes',
            'Meus contratos',
            'Fechamento',
            'Financeiro',
            'Profissão',
          ].includes(title) &&
            (percent === 100 ? (
              <InfoStatusComplete>Concluído</InfoStatusComplete>
            ) : (
              <InfoStatusIncomplete>Incompleto</InfoStatusIncomplete>
            ))}

          {['Banco'].includes(title) && bankList.banks.length > 0 && (
            <BankLength>
              {bankList.banks.length}
              {bankList.banks.length <= 1
                ? ' Conta cadastrada'
                : ' Contas cadastradas'}
            </BankLength>
          )}

          {['Representantes'].includes(title) &&
            (partnersPercentage === 100 &&
            !partners.some((item) => item.isPartner && !item.dynamicsId) ? (
              <InfoStatusComplete>Concluído</InfoStatusComplete>
            ) : (
              <InfoStatusIncomplete>Incompleto</InfoStatusIncomplete>
            ))}

          {['Financeiro', 'Profissão'].includes(title) &&
            (percent === 100 ? (
              <InfoStatusComplete>Concluído</InfoStatusComplete>
            ) : (
              <InfoStatusOptional>
                Opcional
                <Whisper
                  placement='bottom'
                  controlId='control-id-hover'
                  trigger='hover'
                  speaker={tooltip}
                >
                  <Info
                    size={24}
                    color='#09B2E6'
                    style={{ marginLeft: '8px' }}
                  />
                </Whisper>
              </InfoStatusOptional>
            ))}

          {['Fechamento'].includes(title) &&
            ((importProducts === true ||
              exportProducts === true ||
              receiveAbroad === true ||
              sendAbroad === true ||
              travelMoney === true) &&
            amount ? (
              <InfoStatusComplete>Concluído</InfoStatusComplete>
            ) : (
              <InfoStatusOptional>
                Opcional
                <Whisper
                  placement='bottom'
                  controlId='control-id-hover'
                  trigger='hover'
                  speaker={tooltip}
                >
                  <Info
                    size={24}
                    color='#09B2E6'
                    style={{ marginLeft: '8px' }}
                  />
                </Whisper>
              </InfoStatusOptional>
            ))}
        </div>
      </BodyCard>
      <BodyButton>
        <CustomButton ghost onClick={navigateTo}>
          {!['Banco', 'Fechamento'].includes(title) &&
            (percent === 100 ? 'Editar' : 'Preencher')}
          {['Banco', 'Fechamento'].includes(title) && 'Preencher'}
        </CustomButton>
      </BodyButton>
    </StyledCard>
  );
};

export default Card;
