import { Form, Formik } from 'formik';
import { FormGroup, SelectPicker } from 'rsuite';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import {
  CardHolder,
  CardNumber,
  CardOwner,
  CardRepresentative,
  CardValidate,
  Content,
  Description,
  ElementsHolder,
  FieldName,
  StyledCard,
  Title,
  TransactionData,
} from './styles';

import { StyledInput, StyledMaskedInput } from '../styles/form.js';

import { transformNumberInMoney } from '~/utils/transformNumberInMoney';

import { updateCardInfo } from '~/store/modules/exchange/actions';
import { calcutateCheckoutValues } from '../../utils/calculateCheckoutValues';
import CardItem from './card.svg';

const creditCardMask = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const PaymentCard = () => {
  const { user, exchange } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { totalValue } = calcutateCheckoutValues(exchange);

  const creditCardSchema = Yup.object().shape({
    cardNumber: Yup.string()
      .min(19, 'Número do Cartao inválido')
      .required('Obrigatório'),
    name: Yup.string().required('Obrigatório'),
    validDate: Yup.array().required('Obrigatório'),
    securityCode: Yup.string()
      .min(3, 'Código de segurança inválido')
      .required('Obrigatório'),
    installments: Yup.string().required('Obrigatório'),
  });

  const installmentOptions = () => {
    const installments = [];

    for (let i = 1; i <= 4; i++) {
      installments.push({
        label: `${i}x de ${transformNumberInMoney({
          currency: 'BRL',
          number: totalValue / i,
        })}`,
        value: i,
      });
    }

    return installments;
  };

  function handleSecurityCodeMask(securityCodeInputValue) {
    const numbers = securityCodeInputValue.match(/^\d+$/);

    if (numbers) {
      return numbers.join('').length > 3
        ? [/\d/, /\d/, /\d/, /\d/]
        : [/\d/, /\d/, /\d/];
    }

    return [/\d/, /\d/, /\d/];
  }

  function handleValidDateMask(validDateInputValue) {
    if (validDateInputValue.length) {
      return validDateInputValue[0] === '0'
        ? [/[0]/, /[1-9]/, '/', /[2-9]/, /[2-9]/]
        : [/[1]/, /[0-2]/, '/', /[2-9]/, /[2-9]/];
    }

    return [/[0-9]/, /[0-2]/, '/', /[2-9]/, /[2-9]/];
  }

  return (
    <StyledCard id='card-data'>
      <Title>Adicione os dados do cartão</Title>

      <Description>
        O cartão deve ser vinculado ao mesmo CPF {user.profile.document}
      </Description>

      <Content>
        <Formik
          initialValues={{
            cardNumber: '',
            name: '',
            validDate: '',
            securityCode: '',
            installments: '',
          }}
          validationSchema={creditCardSchema}
          onChange={(values) => {}}
          onSubmit={(values, action) => {}}
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            isValid,
            touched,
            handleSubmit,
            setFieldValue,
          }) => {
            const handleSelectChange = (value, event) => {
              setFieldValue('installments', value);
            };

            const changeCardData = ({ type, value }) => {
              dispatch(updateCardInfo({ type, value }));
            };

            return (
              <ElementsHolder>
                <TransactionData>
                  <Form onSubmit={handleSubmit}>
                    <FormGroup>
                      <FieldName>Número do cartão</FieldName>
                      <MaskedInput
                        mask={creditCardMask}
                        name='cardNumber'
                        placeholder='XXXX XXXX XXXX XXXX'
                        onChange={(e) => {
                          handleChange(e);
                          changeCardData({
                            type: 'cardNumber',
                            value: e.target.value,
                          });
                        }}
                        value={values.cardNumber}
                        id='cardNumber'
                        render={(ref, props) => (
                          <StyledMaskedInput
                            type='text'
                            className='rs-input'
                            ref={ref}
                            {...props}
                          />
                        )}
                      />
                    </FormGroup>

                    <FormGroup>
                      <FieldName>Nome impresso no cartão</FieldName>
                      <StyledInput
                        name='name'
                        placeholder='Nome impresso no cartão'
                        onChange={(value, event) => {
                          handleChange(event);
                          changeCardData({ type: 'name', value });
                        }}
                        value={values.name}
                        type='text'
                      />
                    </FormGroup>

                    <div style={{ display: 'flex' }}>
                      <FormGroup style={{ width: '50%' }}>
                        <FieldName>Validade</FieldName>
                        <MaskedInput
                          mask={handleValidDateMask}
                          name='validDate'
                          placeholder='MM/AA'
                          onChange={(value) => {
                            handleChange(value);
                            changeCardData({
                              type: 'validDate',
                              value: value.target.value,
                            });
                          }}
                          value={values.validDate}
                          id='validDate'
                          render={(ref, props) => (
                            <StyledMaskedInput
                              type='text'
                              className='rs-input'
                              ref={ref}
                              {...props}
                            />
                          )}
                        />
                      </FormGroup>

                      <FormGroup style={{ width: '50%', marginLeft: 10 }}>
                        <FieldName>Cód. de Segurança</FieldName>
                        <MaskedInput
                          mask={handleSecurityCodeMask}
                          name='securityCode'
                          placeholder='XXX'
                          onChange={(e) => {
                            handleChange(e);
                            changeCardData({
                              type: 'securityCode',
                              value: e.target.value,
                            });
                          }}
                          value={values.securityCode}
                          id='securityCode'
                          render={(ref, props) => (
                            <StyledMaskedInput
                              type='text'
                              className='rs-input'
                              ref={ref}
                              {...props}
                            />
                          )}
                        />
                      </FormGroup>
                    </div>

                    <FormGroup>
                      <FieldName>Número de Parcelas</FieldName>
                      <SelectPicker
                        size='lg'
                        locale={{ searchPlaceholder: 'Pesquisar' }}
                        placeholder='Defina o número de parcelas'
                        onChange={(value, event) => {
                          handleSelectChange(value);
                          changeCardData({
                            type: 'installments',
                            value,
                          });
                        }}
                        data={installmentOptions()}
                        value={values.installments}
                        style={{ width: '380px' }}
                      />
                    </FormGroup>
                  </Form>
                </TransactionData>

                <CardRepresentative>
                  <CardHolder>
                    <CardNumber>
                      {values.cardNumber.replace(/_/g, ' ')}
                    </CardNumber>
                    <CardOwner>{values.name}</CardOwner>
                    <CardValidate>
                      {values.validDate.replace(/_/g, ' ')}
                    </CardValidate>
                    <img src={CardItem} />
                  </CardHolder>
                </CardRepresentative>
              </ElementsHolder>
            );
          }}
        </Formik>
      </Content>
    </StyledCard>
  );
};

export default PaymentCard;
