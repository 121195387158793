import React, { memo, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ControlLabel, FormGroup } from 'rsuite';
import ProgressLine from 'rsuite/lib/Progress/ProgressLine';
import { UploadIcon } from '../../assets/icons';
import {
  DEFAULT_ALLOWED_FILE_TYPES,
  DEFAULT_SIZE_LIMIT,
  UploadFiles as uploadFiles,
} from '../../services/files';
import FilesList from '../FilesList';
import { FileBox } from '../FilesList/FileBox';
import { ErrorText } from '../InputUpload/styles';
import { Spacer } from '../Spacer';
import { DocumentFields, TitleFile } from '../styles/form';
import { Container, DraggableContainerContent, Input } from './styles';

const UploadFiles = ({
  className,
  type,
  required,
  labelText,
  titleText,
  acceptedMimeTypes,
  size,
  files,
  onChange,
  isDragActive,
  listOldFiles,
  setFilesList,
}) => {
  const [error, setError] = useState(null);

  const fileMaxSize = (size || DEFAULT_SIZE_LIMIT) / 1000000;

  const onDragDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      changeFileList(e.dataTransfer.files);
    }
  };

  const onInputChange = (event) => {
    if (event.target.files && event.target.files[0])
      changeFileList(event.target.files);
  };

  const validateFile = (file) => {
    const acceptedFileTypes = acceptedMimeTypes || DEFAULT_ALLOWED_FILE_TYPES;
    const allowedSize = size || DEFAULT_SIZE_LIMIT;

    if (file) {
      if (file.size > Number(allowedSize)) {
        setError('Tamanho de arquivo acima do permitido.');
      } else if (!acceptedFileTypes.includes(file.type)) {
        setError('Extensão de arquivo não permitida.');
      } else {
        setError(null);
        return true;
      }
    }
    return false;
  };

  const changeFileList = (fileList) => {
    if (![...fileList].every((f) => validateFile(f))) {
      return;
    }
    files.push(...fileList);
    onChange(files);
  };

  const onDeleteNewFile = (file) => {
    const list = [...files];
    const index = list.findIndex(
      (e) => e.name === file.name && e.size === file.size
    );
    if (index >= 0) {
      list.splice(index, 1);
      onChange(list);
    }
  };

  return (
    <>
      <DocumentFields>
        <TitleFile>{titleText}</TitleFile>
        <ControlLabel>{labelText}</ControlLabel>
        <FormGroup>
          <div style={{ marginTop: 5 }}>
            <div>
              {required ? <p style={{ color: 'red' }}>Obrigatório*</p> : null}
              <Container
                draggable
                onDrop={onDragDrop}
                className={isDragActive ? 'dragActive' : ''}
              >
                <DraggableContainerContent>
                  <UploadIcon />
                  <p className='description'>
                    Clique para selecionar arquivo ou arraste e solte do seu
                    dispositivo
                  </p>
                </DraggableContainerContent>
                <Input
                  name='file'
                  type='file'
                  multiple
                  onChange={onInputChange}
                />
              </Container>
              {error && <ErrorText>{error}</ErrorText>}
              <p>
                JPG, PNG ou PDF, arquivo com tamanho máximo de {fileMaxSize} mb.
              </p>
            </div>
          </div>
        </FormGroup>

        {files &&
          files.map((file, idx) => {
            return (
              <FileBox
                key={idx}
                mFileObj={{
                  DisplayID: file.name,
                  Name: file.name,
                  Extension: file.type,
                  Size: file.size,
                }}
                canDelete
                onDelete={() => onDeleteNewFile(file)}
              />
            );
          })}

        {listOldFiles && (
          <>
            <Spacer bottom={10} />
            <FilesList setFilesList={setFilesList} nameClass={className} />
          </>
        )}

        {/* {(isUploading || uploadPercent === 100) && (
            <ProgressLine percent={uploadPercent.toFixed(0)} />
          )} */}
      </DocumentFields>
    </>
  );
};

export default UploadFiles;
