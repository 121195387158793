import React from 'react';
import { Circle, CheckCircle } from 'react-feather';
import { DetailsRange } from './styles';

export default function Range({ data }) {
  const { status_id } = data;

  const getIconByStatus = (step, margin) => {
    // if cancelled
    if (status_id === 4)
      return (
        <Circle color='#E5E9F2' size={24} style={{ marginLeft: margin }} />
      );

    if (status_id >= step)
      return (
        <CheckCircle color='#14CC70' size={24} style={{ marginLeft: margin }} />
      );

    return <Circle color='#E5E9F2' size={24} style={{ marginLeft: margin }} />;
  };

  const getTextColorByStatus = (step) => {
    // if cancelled
    if (status_id === 4) return '#9BA8B7';
    if (status_id >= step) return '#000';
    return '#9BA8B7';
  };

  const getValueByStatus = () => {
    const ticketStatus = {
      1: '10',
      2: '40',
      3: '72',
      4: '0',
      5: '100',
    };

    return ticketStatus[status_id];
  };

  return (
    <div>
      <div>
        <DetailsRange min='1' max='100' value={getValueByStatus()} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>
            {getIconByStatus(1, 25)}
            <br />
            <span style={{ color: getTextColorByStatus(1), fontWeight: 500 }}>
              {status_id > 1 ? (
                <>
                  Pedido
                  <br />
                  confirmado
                </>
              ) : (
                <>
                  Pedido em
                  <br />
                  análise
                </>
              )}
            </span>
          </span>
          <span>
            {getIconByStatus(2, 25)}
            <br />
            <span style={{ color: getTextColorByStatus(2), fontWeight: 500 }}>
              {status_id !== 4 && status_id > 2 ? (
                <>
                  Pagamento <br /> identificado
                </>
              ) : (
                <>
                  Aguardando <br /> pagamento
                </>
              )}
            </span>
          </span>
          <span>
            {getIconByStatus(3, 25)}
            <br />
            <span style={{ color: getTextColorByStatus(3), fontWeight: 500 }}>
              Preparação
              <br />
              de moedas
            </span>
          </span>
          <span>
            {getIconByStatus(4, 12)}
            <br />
            <span style={{ color: getTextColorByStatus(4), fontWeight: 500 }}>
              Pedido <br />
              enviado
            </span>
          </span>
          {/* <span>
            {getIconByStatus(5, 12)}
            <br />
            <span style={{ color: getTextColorByStatus(5), fontWeight: 500 }}>
              {product_type === 'CARTAO' ? (
                <>
                  Cartão <br />
                  recebido
                </>
              ) : (
                <>
                  Moedas <br />
                  recebidas
                </>
              )}
            </span>
          </span> */}
        </div>
      </div>
    </div>
  );
}
