import React from 'react';
import { useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { InputGroup } from 'rsuite';
import { createNumberMask } from 'text-mask-addons';
import { CircleFlag as Flag } from 'react-circle-flags';
import {
  FlexibleBox,
  Select,
  SelectHolder,
  StyledMaskedInputCurrency,
} from '../styles';

const CurrencyTo = ({ onSelected, data, onValueChange, maskedValue }) => {
  const { selectedProduct } = useSelector((state) => state.exchange);
  const selectedProductId = selectedProduct?.productId;

  const maskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 10, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
    // requireDecimal: true,
    // alwaysShowDecimal: true,
  };

  const currencyMask = createNumberMask({ ...maskOptions });

  return (
    <>
      <FlexibleBox>
        <SelectHolder id='currencyTo' className='currencyToGroup'>
          <ProductFlags code={selectedProduct.currencyTo} />
          <Select onChange={onSelected}>
            {data.map(({ groupDescription, productId }) => (
              <option
                key={productId}
                value={productId}
                selected={productId === selectedProductId}
              >
                {(groupDescription === 'USD' && 'Dólar americano') ||
                  (groupDescription === 'ILS' && 'Shekel israelense') ||
                  (groupDescription === 'GBP' && 'Libra esterlina') ||
                  (groupDescription === 'EUR' && 'Euro') ||
                  (groupDescription === 'DKK' && 'Coroa dinamarquesa') ||
                  (groupDescription === 'CAD' && 'Dólar canadense') ||
                  (groupDescription === 'NZD' && 'Dólar neozelandês') ||
                  (groupDescription === 'AUD' && 'Dólar australiano') ||
                  (groupDescription === 'CHF' && 'Franco suíço') ||
                  (groupDescription === 'CLP' && 'Peso chileno') ||
                  (groupDescription === 'JPY' && 'Iene') ||
                  (groupDescription === 'MXN' && 'Peso mexicano') ||
                  (groupDescription === 'SEK' && 'Coroa sueca') ||
                  (groupDescription === 'ARS' && 'Peso argentino') ||
                  (groupDescription === 'ZAR' && 'Rand sul-africano') ||
                  (groupDescription === 'CNY' && 'Yuan chinês') ||
                  (groupDescription === 'UYU' && 'Peso uruguaio') ||
                  (groupDescription === 'NOK' && 'Coroa noroeguesa')}
              </option>
            ))}
          </Select>
        </SelectHolder>
        <InputGroup>
          <InputGroup.Addon>
            {selectedProduct.currencyTo === 'USD'
              ? '$'
              : selectedProduct.currencyTo}
          </InputGroup.Addon>
          <MaskedInput
            mask={currencyMask}
            name='valueTo'
            id='valueTo'
            onChange={(event) => {
              const { value } = event.target;
              onValueChange(value);
            }}
            value={maskedValue !== '0,00' ? maskedValue : ''}
            placeholder='0,00'
            disabled={!selectedProduct.currencyToTax}
            render={(ref, props) => (
              <StyledMaskedInputCurrency
                type='text'
                className='rs-input'
                ref={ref}
                {...props}
              />
            )}
          />
        </InputGroup>
      </FlexibleBox>
    </>
  );
};

const ProductFlags = (props) => {
  const { code } = props;
  if (code == 'USD')
    return (
      <Flag
        countryCode='us'
        height={25}
        title='Dólar americano'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'ILS')
    return (
      <Flag
        countryCode='il'
        height={25}
        title='Shekel israelense'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'GBP')
    return (
      <Flag
        countryCode='gb'
        height={25}
        title='Libra esterlina'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'EUR')
    return (
      <img
        src='/icons/uniao-europeia.png'
        className='euro'
        title='Euro'
        style={{ height: 25, width: 50 }}
      />
    );
  if (code == 'DKK')
    return (
      <Flag
        countryCode='dk'
        height={25}
        title='Coroa dinamarquesa'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'CAD')
    return (
      <Flag
        countryCode='ca'
        height={25}
        title='Dólar canadense'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'NZD' || code == 'BPNZD')
    return (
      <Flag
        countryCode='nz'
        height={25}
        title='Dólar neozelandês'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'AUD')
    return (
      <Flag
        countryCode='au'
        height={25}
        title='Dólar australiano'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'CHF')
    return (
      <Flag
        countryCode='ch'
        height={25}
        title='Franco suíço'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'CLP')
    return (
      <Flag
        countryCode='cl'
        height={25}
        title='Peso chileno'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'JPY')
    return (
      <Flag
        countryCode='jp'
        height={25}
        title='Iene'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'MXN')
    return (
      <Flag
        countryCode='mx'
        height={25}
        title='Peso mexicano'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'SEK')
    return (
      <Flag
        countryCode='se'
        height={25}
        title='Coroa sueca'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'ARS')
    return (
      <Flag
        countryCode='ar'
        height={25}
        title='Peso argentino'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'ZAR')
    return (
      <Flag
        countryCode='za'
        height={25}
        title='Rand sul-africano'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'CNY')
    return (
      <Flag
        countryCode='cn'
        height={25}
        title='Yuan chinês'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'UYU')
    return (
      <Flag
        countryCode='uy'
        height={25}
        title='Peso uruguaio'
        style={{ marginLeft: '8px' }}
      />
    );
  if (code == 'NOK')
    return (
      <Flag
        countryCode='no'
        height={25}
        title='Coroa noroeguesa'
        style={{ marginLeft: '8px' }}
      />
    );
  return (
    <Flag
      countryCode=''
      height={25}
      title='Moeda não encontrada'
      style={{ marginLeft: '8px' }}
    />
  );
};

export default CurrencyTo;
