import React, { useState, useEffect } from 'react';
import { Progress } from 'rsuite';

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ChoosePaymentType from '~/components/ChoosePaymentType';
import BoletoPayment from '~/components/BoletoPayment';
import PixPayment from '~/components/PixPayment';
import TedPayment from '~/components/TedPayment';
import { confirmPayment } from '~/store/modules/exchange/sagas';

const CheckoutPayment = ({ percent }) => {
  const [step, setStep] = useState('CHOOSE_PAYMENT');
  const [paymentData, setPaymentData] = useState({});
  const [percentStep, setPercentStep] = useState(80);
  const { Line } = Progress;
  const dispatch = useDispatch();
  const history = useHistory();

  const { exchange } = useSelector((state) => state);

  const firstPaymentStep = async (e) => {
    if (e.status === 'COMPLETED_DATA') {
      setPaymentData(e.payment);

      if (e.payment.value === 'BOLETO') {
        setPercentStep(100);
        setStep('PAY_NOW_BOLETO');
      }

      if (e.payment.value === 'PIX') {
        setPercentStep(100);
        setStep('PAY_NOW_PIX');
      }

      if (e.payment.value === 'TED') {
        setPercentStep(100);
        setStep('PAY_NOW_TED');
      }

      if (e.payment.value === 'CARTAO') {
        setPercentStep(100);

        // dispatch(confirmPayment())
      }
    }
  };

  useEffect(() => {
    if (exchange.ticket.Sucesso) {
      setTimeout(() => {
        history.push('/checkout/success');
      }, 2000);
    }
  }, [exchange.ticket]);

  const chooseStep = () => {
    if (step === 'CHOOSE_PAYMENT') {
      return (
        <ChoosePaymentType
          onFinish={(e) => firstPaymentStep(e)}
          percent={percent}
        />
      );
    }

    if (step === 'PAY_NOW_BOLETO') {
      return <BoletoPayment />;
    }

    if (step === 'PAY_NOW_PIX') {
      return <PixPayment />;
    }

    if (step === 'PAY_NOW_TED') {
      return <TedPayment />;
    }
  };

  return <div>{chooseStep()}</div>;
};

export default CheckoutPayment;
