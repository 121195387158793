import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { FiMoreHorizontal } from 'react-icons/fi';
import { signOut } from '~/store/modules/auth/actions';

import { services, options } from './data';

import * as S from './styles';

export default function MobileMenu() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [activeMenu, setActiveMenu] = useState('');
  const [shouldExpand, setShouldExpand] = useState(false);

  function checkIfIsActiveMenu(link) {
    return !!(link === pathname);
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  const goToHelp = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <S.Navbar id='MobileNavbar' expand={shouldExpand}>
      <S.NavbarContentGroup>
        <S.NavbarContentHolder>
          {shouldExpand && (
            <S.ExpandContainer>
              <span>Todas as opções disponíveis</span>

              <S.NavbarOptionsWrapper>
                {options.map(
                  ({ id, name, link, component: Component, url }, index) => (
                    <S.NavbarOptionsContainer
                      key={`nav-options-${id}-${index}`}
                      onClick={
                        id.includes('Logout') ? () => handleSignOut() : () => {}
                      }
                    >
                      {id === 'Tutorial' || id === 'HelpCenter' ? (
                        <Link to={link} onClick={() => goToHelp(url)}>
                          <S.NavbarOptions>
                            <Component />
                          </S.NavbarOptions>
                          <S.NavbarItemName id={id}>{name}</S.NavbarItemName>
                        </Link>
                      ) : (
                        <Link to={link}>
                          <S.NavbarOptions>
                            <Component />
                          </S.NavbarOptions>
                          <S.NavbarItemName id={id}>{name}</S.NavbarItemName>
                        </Link>
                      )}
                    </S.NavbarOptionsContainer>
                  )
                )}
              </S.NavbarOptionsWrapper>

              <hr />
            </S.ExpandContainer>
          )}

          <S.NavbarList>
            {services.map(({ id, name, link, component: Component }) => (
              <S.NavbarListItem
                isActive={checkIfIsActiveMenu(link)}
                key={`nav-list-${id}`}
              >
                <Link to={link}>
                  <Component />
                  <S.NavbarItemName id={id}>{name}</S.NavbarItemName>
                </Link>
              </S.NavbarListItem>
            ))}

            <S.NavbarListItem>
              <S.NavbarListMore
                onClick={() => setShouldExpand((current) => !current)}
              >
                <FiMoreHorizontal size={24} color='#C0CCDA' />
                <S.NavbarItemName id='ChatItem'>
                  {shouldExpand ? 'Menos' : 'Mais'}
                </S.NavbarItemName>
              </S.NavbarListMore>
            </S.NavbarListItem>
          </S.NavbarList>
        </S.NavbarContentHolder>
      </S.NavbarContentGroup>
    </S.Navbar>
  );
}
