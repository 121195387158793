import React from 'react';
import { CircleFlag as Flag } from 'react-circle-flags';
import { Container } from '../pages/ExchangeTicketList/TableCellValue/styles';

export const ProductFlags = ({ code }) => {
  if (!code || code === 'VTM' || code === 'vtm')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <img
          src='/icons/advanced.png'
          className='advanced'
          title='Multimoedas'
        />
      </Container>
    );
  if (code === 'USD' || code === 'BPUSD')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='us' height={25} title='Dólar americano' />
      </Container>
    );
  if (code === 'ILS')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='il' height={25} title='Shekel israelense' />
      </Container>
    );
  if (code === 'GBP' || code === 'BPGBP')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='gb' height={25} title='Libra esterlina' />
      </Container>
    );
  if (code === 'EUR' || code === 'BPEUR')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        {/* <Flag countryCode='eu' height={25} /> */}
        <img src='/icons/uniao-europeia.png' className='euro' title='Euro' />
      </Container>
    );
  if (code === 'DKK')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='dk' height={25} title='Coroa dinamarquesa' />
      </Container>
    );
  if (code === 'CAD' || code === 'BPCAD')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='ca' height={25} title='Dólar canadense' />
      </Container>
    );
  if (code === 'NZD' || code === 'BPNZD')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='nz' height={25} title='Dólar neozelandês' />
      </Container>
    );
  if (code === 'AUD' || code === 'BPAUD')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='au' height={25} title='Dólar australiano' />
      </Container>
    );
  if (code === 'CHF')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='ch' height={25} title='Franco suíço' />
      </Container>
    );
  if (code === 'CLP')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='cl' height={25} title='Peso chileno' />
      </Container>
    );
  if (code === 'JPY')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='jp' height={25} title='Iene' />
      </Container>
    );
  if (code === 'MXN')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='mx' height={25} title='Peso mexicano' />
      </Container>
    );
  if (code === 'SEK')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='se' height={25} title='Coroa sueca' />
      </Container>
    );
  if (code === 'ARS')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='ar' height={25} title='Peso argentino' />
      </Container>
    );
  if (code === 'ZAR')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='za' height={25} title='Rand sul-africano' />
      </Container>
    );
  if (code === 'CNY')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='cn' height={25} title='Yuan chinês' />
      </Container>
    );
  if (code === 'UYU')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='uy' height={25} title='Peso uruguaio' />
      </Container>
    );
  if (code === 'NOK')
    return (
      <Container>
        <Flag countryCode='br' height={25} className='brasil' title='Real' />
        <Flag countryCode='no' height={25} title='Coroa noroeguesa' />
      </Container>
    );
  return (
    <Container>
      <Flag countryCode='br' height={25} className='brasil' title='Real' />
      <img src='/icons/advanced.png' className='advanced' title='' />
    </Container>
  );
};
