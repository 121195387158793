import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Breadcrumb,
  Col,
  ControlLabel,
  Form,
  FormGroup,
  RadioGroup,
  Row,
  SelectPicker,
} from 'rsuite';
import * as Yup from 'yup';
import { MobileHeaderData } from '~/components/HeaderData';
import LoggedLayout, { LayoutContent } from '~/components/LoggedLayout';
import {
  Sidebar,
  SidebarContent,
  SidebarSubtitle,
  SidebarTitle,
} from '~/components/Sidebar';
import cpfMask from '~/mask/cpf';
import { api } from '~/services/api';
import { removeBank } from '~/store/modules/bank/actions';
import { getBanks } from '~/store/modules/banklist/actions';
import testCpf from '~/utils/cpfTest';
import {
  BackButton,
  SquareButton,
  DisplayGrid,
  Fields,
  FormGrid,
  RadioItem,
  StyledButton,
  StyledInput,
  Title,
} from '../../components/styles/form.js';
import BankImage from './icons/bank.svg';
import BankImageMobile from './icons/banks_mobile.svg';
import ErrorModal from '~/components/ErrorModal/index.js';
import { FooterLabel } from './style.js';

const BankPage = () => {
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorData, setErrorData] = useState({});

  const bankInfo = useSelector((state) => state.bank);
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);

  const joint = {
    yes: true,
    no: false,
  };

  const {
    accountType,
    agencyNumber,
    jointAccount,
    accountNumber,
    agencyDigit,
    accountDigit,
    secondHolderCpf,
    secondHolderName,
    bankId,
    dynamicsId,
  } = bankInfo.bank ? bankInfo.bank : {};

  const FieldsSchema = Yup.object().shape({
    bank: Yup.string().required('Obrigatório').nullable(),
    accountType: Yup.string().required('Obrigatório'),
    agency: Yup.string()
      .required('Obrigatório')
      .min(1, 'Tamanho mínimo de 4 dígitos'),
    agencyDigit: Yup.string().optional().max(1, 'Insira apenas 1 dígito'),
    jointAccount: Yup.boolean(),
    accountNumber: Yup.string().required('Obrigatório'),
    accountDigit: Yup.string().optional().max(1, 'Insira apenas 1 dígito'),
    cpfCorrentista: Yup.string().test('cpf', 'CPF Inválido', function (value) {
      return this.parent.jointAccount ? testCpf(value) : true;
    }),
  });

  useEffect(() => {
    const actualData = [];
    api.defaults.headers.Authorization = `Bearer ${auth.token}`;

    api.get('/lists/banks').then(({ data }) => {
      data.map((item) => {
        actualData.push({
          label: `${item.code} - ${item.name}`,
          value: item.id,
        });
      });

      setBanks(actualData);
    });
  }, []);

  const dispatch = useDispatch();

  function CreateBank(data) {
    return api.post(`/bank_data`, {
      bankId: data.bank,
      accountType: data.accountType.toString(),
      agencyNumber: data.agency,
      agencyDigit: data.agencyDigit,
      accountNumber: data.accountNumber.toString(),
      accountDigit: data.accountDigit,
      jointAccount: data.jointAccount,
      secondHolderCpf: data.cpfCorrentista,
      secondHolderName: data.nameCorrentista,
      // accountAbroad: false, (?)
    });
  }

  function UpdateBank(data) {
    return api
      .put(`/bank_data/${bankInfo.bank._id}`, {
        bankId: data.bank,
        accountType: data.accountType.toString(),
        agencyNumber: data.agency.toString(),
        agencyDigit: data.agencyDigit,
        accountNumber: data.accountNumber.toString(),
        accountDigit: data.accountDigit,
        jointAccount: data.jointAccount,
        secondHolderCpf: data.cpfCorrentista,
        secondHolderName: data.nameCorrentista,
        // accountAbroad: false, (?)
      })
      .then(() => dispatch(getBanks(user.profile._id, auth.token)));
  }

  const onlyDigits = (val) =>
    val?.toLowerCase() !== 'x' ? val.replace(/\D|x/g, '') : val;

  return (
    <LoggedLayout>
      <Sidebar>
        <SidebarContent>
          <img src={BankImage} alt='' />
          <SidebarTitle internal>Dados Bancários</SidebarTitle>
          <SidebarSubtitle internal>
            Tipo de conta, banco, conta corrente e etc
          </SidebarSubtitle>
        </SidebarContent>
      </Sidebar>

      <MobileHeaderData
        title='Dados Bancários'
        usePercentage={false}
        icon={BankImageMobile}
      />

      <LayoutContent>
        <ErrorModal
          showModal={showModal}
          onChange={() => setShowModal(false)}
          {...errorData}
        />

        <Formik
          initialValues={{
            bank: bankId || '',
            accountType: accountType || '',
            agency: agencyNumber || '',
            jointAccount: jointAccount || false,
            accountNumber: accountNumber || '',
            agencyDigit: agencyDigit || '',
            accountDigit: accountDigit || '',
            cpfCorrentista: secondHolderCpf || '',
            nameCorrentista: secondHolderName || '',
            dynamicsId: dynamicsId || '',
          }}
          validationSchema={FieldsSchema}
          onSubmit={async (values, action) => {
            setLoading(true);
            if (bankInfo?.bank?._id) {
              UpdateBank({ ...values })
                .then(() => {
                  action.setSubmitting(true);
                  toast.success('Informações bancárias editadas com sucesso.');
                  dispatch(removeBank());
                  dispatch(getBanks(user.profile._id, auth.token));
                  return window.location.replace('/update-bank');
                })
                .catch((error) => {
                  action.setSubmitting(false);
                  if (error.response.status === 400) {
                    setShowModal(true);
                    setErrorData({
                      title: 'Erro ao processar dados',
                      description: error.response?.data?.message[0],
                    });

                    // toast.error(error.response?.data?.message[0])
                  } else {
                    setShowModal(true);
                    setErrorData({
                      title: 'Erro ao processar dados',
                      description:
                        'Tivemos um problema no envio das informações, revise o preenchimento dos campos obrigatórios ou tente novamente.',
                    });

                    // toast.error('Algo deu errado. Por favor, tente novamente.');
                  }
                })
                .finally(() => {
                  setLoading(false);
                });
            } else {
              CreateBank(values)
                .then(() => {
                  action.setSubmitting(true);
                  toast.success('Informações bancárias salvas com sucesso.');
                  dispatch(removeBank());
                  dispatch(getBanks(user.profile._id, auth.token));
                  return window.location.replace('/update-bank');
                })
                .catch((error) => {
                  action.setSubmitting(false);

                  if (error.response.status === 400) {
                    setShowModal(true);
                    setErrorData({
                      title: 'Erro ao processar dados',
                      description: error.response?.data?.message[0],
                    });
                  } else {
                    setShowModal(true);
                    setErrorData({
                      title: 'Erro ao processar dados',
                      description:
                        'Tivemos um problema no envio das informações, revise o preenchimento dos campos obrigatórios ou tente novamente.',
                    });

                    // toast.error('Algo deu errado. Por favor, tente novamente.');
                  }
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          }}
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            isValid,
            touched,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            const handleSelectChange = (value) => {
              setFieldValue('bank', value);
            };

            const RadioChange = (value) => {
              setFieldValue('accountType', value);
            };

            return (
              <Form onSubmit={handleSubmit}>
                <DisplayGrid>
                  <FormGrid>
                    <Breadcrumb>
                      <Link className='step' to='/home'>
                        Home
                      </Link>
                      <Link className='step' to='/update-data'>
                        Meus dados
                      </Link>
                      <Link className='step' to='/update-bank'>
                        Dados bancários
                      </Link>
                      <Link className='active' to='/login'>
                        Conta
                      </Link>
                    </Breadcrumb>

                    <BackButton className='back-button'>
                      <ArrowLeft />
                      <Link to='/update-bank'>
                        <p>Voltar</p>
                      </Link>
                    </BackButton>
                    <Fields className='fieldsName'>
                      <Title>Dados bancários</Title>
                      <Row>
                        <Col xs={24}>
                          <ControlLabel>Tipo de conta *</ControlLabel>
                          <FormGroup controlId='accountType'>
                            <RadioGroup
                              inline
                              name='accountType'
                              value={values.accountType}
                              onChange={(value) => {
                                RadioChange(value);
                              }}
                            >
                              <RadioItem
                                type
                                value='conta-corrente'
                                style={{ minWidth: '180px' }}
                              >
                                Conta corrente
                              </RadioItem>
                              <RadioItem
                                type
                                value='conta-poupanca'
                                style={{ minWidth: '180px' }}
                              >
                                Poupança
                              </RadioItem>
                            </RadioGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24}>
                          <FormGroup>
                            <ControlLabel>Selecione o banco *</ControlLabel>
                            <SelectPicker
                              placeholder='Escolha seu banco'
                              size='lg'
                              locale={{ searchPlaceholder: 'Pesquisar' }}
                              value={values.bank}
                              onChange={(value) => handleSelectChange(value)}
                              data={banks}
                              style={{
                                marginBottom: 20,
                                maxWidth: '370px',
                                width: '100%',
                              }}
                            />

                            {errors.bank && touched.bank && (
                              <span className='input-error'>{errors.bank}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} lg={12}>
                          <FormGroup>
                            <ControlLabel>Agência *</ControlLabel>
                            <StyledInput
                              onChange={(val, _) =>
                                setFieldValue('agency', onlyDigits(val), true)
                              }
                              onBlur={handleBlur}
                              name='agency'
                              id='agency'
                              value={values.agency}
                              type='text'
                              required
                            />

                            {errors.agency && touched.agency && (
                              <span className='input-error'>
                                {errors.agency}
                              </span>
                            )}
                          </FormGroup>
                        </Col>

                        <Col xs={24} lg={7}>
                          <FormGroup>
                            <ControlLabel>Dígito</ControlLabel>
                            <StyledInput
                              onChange={(value) =>
                                setFieldValue(
                                  'agencyDigit',
                                  onlyDigits(value),
                                  true
                                )
                              }
                              onBlur={handleBlur}
                              name='agencyDigit'
                              id='agencyDigit'
                              value={values.agencyDigit}
                              type='text'
                              maxLength={1}
                            />

                            {errors.agencyDigit && touched.agencyDigit && (
                              <span className='input-error'>
                                {errors.agencyDigit}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24} lg={12}>
                          <FormGroup>
                            <ControlLabel>Conta corrente *</ControlLabel>
                            <StyledInput
                              onChange={(value) =>
                                setFieldValue(
                                  'accountNumber',
                                  onlyDigits(value),
                                  true
                                )
                              }
                              onBlur={handleBlur}
                              name='accountNumber'
                              id='accountNumber'
                              value={values.accountNumber}
                              type='text'
                              required
                            />

                            {errors.accountNumber && touched.accountNumber && (
                              <span className='input-error'>
                                {errors.accountNumber}
                              </span>
                            )}
                          </FormGroup>
                        </Col>

                        <Col xs={24} lg={7}>
                          <FormGroup>
                            <ControlLabel>Dígito</ControlLabel>
                            <StyledInput
                              onChange={(value) =>
                                setFieldValue(
                                  'accountDigit',
                                  onlyDigits(value),
                                  true
                                )
                              }
                              onBlur={handleBlur}
                              name='accountDigit'
                              id='accountDigit'
                              value={values.accountDigit}
                              maxLength={1}
                              type='text'
                            />

                            {errors.accountDigit && touched.accountDigit && (
                              <span className='input-error'>
                                {errors.accountDigit}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      {user.profile.document.length !== 14 && (
                        <Row>
                          <Col xs={24}>
                            <FormGroup>
                              <ControlLabel>
                                Esta é uma conta conjunta? *
                              </ControlLabel>
                              <SquareButton
                                onClick={() =>
                                  setFieldValue('jointAccount', joint.yes)
                                }
                                value={joint.yes}
                                label='Sim'
                                name='joint'
                                checked={values.jointAccount === joint.yes}
                                style={{ width: '175px' }}
                              />
                              <SquareButton
                                onClick={() =>
                                  setFieldValue('jointAccount', joint.no)
                                }
                                value={joint.no}
                                label='Não'
                                name='joint'
                                checked={values.jointAccount === joint.no}
                                style={{ width: '175px' }}
                              />
                              {errors.joint && touched.joint && (
                                <span className='input-error'>
                                  {errors.joint}
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      )}

                      {values.jointAccount && (
                        <>
                          <Row>
                            <Col xs={24} lg={19}>
                              <FormGroup>
                                <ControlLabel>
                                  Nome do 2º correntista *
                                </ControlLabel>
                                <StyledInput
                                  onChange={(value, event) =>
                                    handleChange(event)
                                  }
                                  onBlur={handleBlur}
                                  name='nameCorrentista'
                                  id='nameCorrentista'
                                  value={values.nameCorrentista}
                                  type='text'
                                />

                                {errors.nameCorrentista &&
                                  touched.nameCorrentista && (
                                    <span className='input-error'>
                                      {errors.nameCorrentista}
                                    </span>
                                  )}
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={24} lg={19}>
                              <FormGroup>
                                <ControlLabel>
                                  CPF do 2º correntista *
                                </ControlLabel>
                                <StyledInput
                                  onChange={(value, event) =>
                                    handleChange(event)
                                  }
                                  onBlur={handleBlur}
                                  name='cpfCorrentista'
                                  id='cpfCorrentista'
                                  value={cpfMask(values.cpfCorrentista)}
                                  type='text'
                                  maxLength={14}
                                />

                                {errors.cpfCorrentista &&
                                  touched.cpfCorrentista && (
                                    <span className='input-error'>
                                      {errors.cpfCorrentista}
                                    </span>
                                  )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      )}

                      <StyledButton
                        type='submit'
                        disabled={
                          isSubmitting ||
                          !isValid ||
                          loading ||
                          values.jointAccount === ''
                        }
                      >
                        {loading ? 'Carregando' : 'Salvar'}
                      </StyledButton>
                      <FooterLabel>
                        * Campos obrigatórios para salvar formulário e seguir
                        com o cadastro.
                      </FooterLabel>
                    </Fields>
                  </FormGrid>
                </DisplayGrid>
              </Form>
            );
          }}
        </Formik>
      </LayoutContent>
    </LoggedLayout>
  );
};

export default BankPage;
