import React from 'react';
import styled from 'styled-components';
import { FormGroup } from 'rsuite';
import IdentificationIcon from '~/assets/identificacao.svg';
import IdentificationMobileIcon from '~/assets/identificacao_mobile.svg';

export const IdentificationIconImg = () => (
  <img src={IdentificationIcon} alt='Ícone de identificação' />
);
export const IdentificationMobileIconImg = () => (
  <img src={IdentificationMobileIcon} />
);

export const MobileHeaderIcon = styled(IdentificationIconImg)`
  margin-bottom: 30px;
  margin-right: 20px;
  display: block;
`;

export const MobileHeader = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    h1 {
      margin-left: 20px;
      font-size: 32px;
    }
  }
`;
export const Title = styled.div`
  margin-bottom: 25px;
  color: #20315b;
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
`;

export const All = styled.div`
  display: grid;

  @media (max-width: 768px) {
    .breadcrumb {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const InternalTwoColumns = styled.div`
  display: grid;
  @media screen and (max-width: 800px) {
    display: block !important;
  }
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const Content = styled.div`
  margin: 25px 0 110px;
  padding: 0 30px;

  @media (min-width: 768px) {
    margin: 25px 0 0 0;
    padding-left: 97px;
  }

  img {
    width: 250px;
    margin-left: -20px;
  }

  .input {
    margin: 5px 0 15px 0;
    height: 50px;
    width: 384px;
    border-radius: 5px;
    border: 1px solid #d3dce6;
    padding-left: 10px;
  }

  .rs-form-group {
    display: flex;
    flex-direction: column;
  }

  a {
    color: #09b2e6;
    font-size: 12px;
  }

  .active {
    color: #455566;
    text-decoration: none;
  }

  button {
    margin-top: 15px;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    position: absolute;
    width: 141px;
    height: 48px;
    color: #fff;
    background: linear-gradient(
      138.72deg,
      #48d3fe 28.81%,
      #0fc7ff 52.98%,
      #09b2e6 84.96%
    );
    box-shadow: 0px 3px 20px rgba(114, 222, 255, 0.25),
      0px 2px 4px rgba(196, 241, 255, 0.25), 0px 5px 20px rgba(0, 159, 217, 0.2);
    border-radius: 30px;
    border: none;
  }
`;

export const Fields = styled.div`
  font-family: IBM Plex Sans;
  font-size: 14px;
  line-height: 20px;
  color: #455566;
`;

export const Sidebar = styled.section`
  @media screen and (max-width: 600px) {
    display: none;
  }
  background: linear-gradient(321.22deg, #20315b 17.08%, #2d447f 95.75%);
  position: relative;
  display: block;
  height: calc(100vh - 73px);
  color: #f9fafc;
  width: 100%;
  padding-left: 100px;
  max-width: 480px;
`;

export const SidebarContent = styled.div`
  padding: 44px;
`;

export const SidebarTitle = styled.h2`
  color: #f9fafc;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  margin-top: 15px;
`;

export const SidebarSubtitle = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #f9fafc;
  max-width: 235px;
  padding-top: 16px;
  display: block;
`;

export const SidebarLinks = styled.div``;

export const SidebarLinksTitle = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #eff2f7;
`;

export const RG = styled(FormGroup)`
  input {
    /* max-width: 328px; */
    margin: 5px 0 4px 0;
    height: 48px;
    width: 340px;
    max-width: 100%;
    border-radius: 5px;
    border: 1px solid #d3dce6;
    padding-left: 10px;
  }
`;

export const RGUFeOrgExped = styled(FormGroup)`
  display: flex;
  flex-direction: row !important;

  section {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
  }
  input {
    max-width: 127px;
    height: 48px;
    margin: 5px 0 4px 0;
    border-radius: 5px;
    border: 1px solid #d3dce6;
    padding-left: 10px;
  }
`;

export const RGDataExpedicao = styled(FormGroup)`
  input {
    width: 239px;
    max-width: 100%;
    height: 48px;
    margin: 5px 0 4px 0;
    border-radius: 5px;
    border: 1px solid #d3dce6;
    padding-left: 10px;
  }
`;

export const CPF = styled(FormGroup)`
  input {
    width: 239px;
    max-width: 100%;
    height: 48px;
    margin: 5px 0 0 0;
    border-radius: 5px;
    border: 1px solid #d3dce6;
    padding-left: 10px;
  }
`;

export const DocumentFields = styled(Fields)`
  padding: 30px 0 0;

  @media (min-width: 768px) {
    padding: 30px 30px 30px 57px;
  }

  ${(props) => props.withoutPadding && ` padding: 0 !important; `}
`;
export const FileUploadBox = styled.div`
  max-width: 400px;
  height: 225px;
  background: #ffffff;
  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  div {
    margin: 33px;
    border: 1.5px dashed #0fc7ff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h1 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      width: 205px;
      color: #09b2e6;
      text-align: center;
      margin-top: 15px;
    }
  }
  input {
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    position: absolute;
  }

  ${(props) =>
    props.small &&
    `
    height: 178px;

    div { 
      margin: 0;
      padding: 22px;
    }

  `}
`;

export const BackButton = styled.div`
  display: flex;
  color: #0fc7ff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;

  p {
    margin-left: 3px;
  }

  a {
    text-decoration: none;
    color: #0fc7ff;
  }
`;

export const ErrorBox = styled.div`
  width: 100%;
  max-width: 700px;
  height: 64px;
  background: #ffecec;
  color: #ef3131;
  border-radius: 5px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  font-family: IBM Plex Sans;
`;

export const SuccessBox = styled.div`
  width: 100%;
  max-width: 700px;
  height: 64px;
  background: #dcffe6;
  color: #14cc70;
  border-radius: 5px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  font-family: IBM Plex Sans;
`;

export const FooterLabel = styled.p`
  color: #455566;
  font-size: 14px;
  line-height: 20px;
  font-family: IBM Plex Sans;
`;

export const InfoStatusComplete = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  display: flex;
  align-items: center;
  text-align: right;

  margin-top: 16px;

  color: #14cc70;
`;

export const InfoStatusIncomplete = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  display: flex;
  align-items: center;
  text-align: right;

  margin-top: 16px;

  color: #ef3131;
`;
