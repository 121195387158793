import React, { useEffect, useState } from 'react';
import { ArrowLeft, PlusCircle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb } from 'rsuite';
import { MobileHeaderData } from '~/components/HeaderData';
import LoggedLayout from '~/components/LoggedLayout';
import {
  Sidebar,
  SidebarContent,
  SidebarSubtitle,
  SidebarTitle,
} from '~/components/Sidebar';
import { api } from '~/services/api';
import { removeBank } from '~/store/modules/bank/actions';
import { getBanks } from '~/store/modules/banklist/actions';
import BankCard from './BankCard';
import BankImage from './icons/bank.svg';
import BankImageMobile from './icons/banks_mobile.svg';
import {
  BackButton,
  BankListHolder,
  DisplayCards,
  GridCards,
  NewBankCard,
} from './style';

const Banks = (props) => {
  const [banksList, setBanksList] = useState([]);
  const [isFetched, setFetchData] = useState(false);
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const { banks } = useSelector((state) => state.bankList);
  const id = user.profile._id;
  const history = useHistory();

  const addNewBank = () => {
    dispatch(removeBank());
    history.push('/update-bank/new');
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBanks(user.profile._id, auth.token));
    api.defaults.headers.Authorization = `Bearer ${auth.token}`;
    api.get('/lists/banks').then(({ data }) => setBanksList(data));
  }, []);

  return (
    <LoggedLayout>
      <Sidebar>
        <SidebarContent>
          <img src={BankImage} alt='' />
          <SidebarTitle internal>Dados Bancários</SidebarTitle>
          <SidebarSubtitle internal>
            Tipo de conta, banco, conta corrente e etc
          </SidebarSubtitle>
        </SidebarContent>
      </Sidebar>

      <>
        <MobileHeaderData
          title='Dados Bancários'
          usePercentage={false}
          icon={BankImageMobile}
        />
        <BankListHolder>
          <Breadcrumb className='breadcrumb' style={{ paddingLeft: 0 }}>
            <Link className='step' to='/home'>
              Home
            </Link>
            <Link className='step' to='/update-data'>
              Meus dados
            </Link>
            <Link className='active' to='/login'>
              Banco
            </Link>
          </Breadcrumb>

          <BackButton className='back-button'>
            <ArrowLeft />
            <Link to='/update-data'>
              <p>Voltar</p>
            </Link>
          </BackButton>

          <DisplayCards>
            <GridCards>
              {banksList.length > 0 &&
                banks.map((item, idx) => (
                  <BankCard
                    key={item._id}
                    data={item}
                    idx={idx}
                    banks={banksList}
                    userId={user.profile._id}
                  />
                ))}
              <NewBankCard onClick={() => addNewBank()}>
                <PlusCircle color='#0FC7FF' size={33} />
                <h1>Adicionar nova conta</h1>
              </NewBankCard>
            </GridCards>
          </DisplayCards>
        </BankListHolder>
      </>
    </LoggedLayout>
  );
};

export default Banks;
