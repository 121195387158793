export function getBanks(id, auth) {
  return {
    type: '@bankList/GET_BANKS',
    payload: { id, auth },
  };
}

export function saveBanks(banks) {
  return {
    type: '@bankList/SAVE_BANKS',
    payload: { banks },
  };
}
