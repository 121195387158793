import dayjs from 'dayjs';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { toast } from 'react-toastify';
import {
  Breadcrumb,
  Col,
  ControlLabel,
  Form,
  FormGroup,
  Progress,
  Row,
} from 'rsuite';
import * as Yup from 'yup';
import { InputUpload } from '~/components/InputUpload';
import LoggedLayout, { LayoutContent } from '~/components/LoggedLayout';
import FilesList from '~/components/FilesList';
import {
  Sidebar,
  SidebarContent,
  SidebarSubtitle,
  SidebarTitle,
} from '~/components/Sidebar';
import {
  StyledButton,
  DocumentFields,
  ErrorBox,
  SuccessBox,
  TitleFile,
} from '~/components/styles/form';
import cnpjMask from '~/mask/cnpj';
import { api } from '~/services/api';
import {
  DEFAULT_ALLOWED_FILE_TYPES,
  DEFAULT_SIZE_LIMIT,
} from '~/services/files';
import { loadingCompleted, setLoading } from '~/store/modules/auth/actions';
import { updateUser } from '~/store/modules/user/actions';
import { calculatePercentPJGeneralData } from '~/utils/calculate-percent';
import {
  All,
  BackButton,
  StyledInput,
  StyledSelect,
  StyledTitle,
  FooterLabel,
  InfoStatusComplete,
  InfoStatusIncomplete,
} from './style';
import history from '../../services/history';
import FormSelectField from '../../components/Form/form-select-field';
import UploadFiles from '../../components/UploadFiles';
import * as fileUploaderService from '~/services/files';

const Home = () => {
  const { Line } = Progress;
  const [percentage, setPercentage] = useState(0);
  const [activities, setActivities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [uploadProgressFileList, setUploadProgressFileList] = useState([]);
  const [filesList, setFilesList] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [errorData, setErrorData] = useState({});

  const { user, auth } = useSelector((state) => state);

  const { loading } = auth;

  const dispatch = useDispatch();

  async function getActivities() {
    api.defaults.headers.Authorization = `Bearer ${auth.token}`;
    const response = await api.get('/lists/activities');
    return setActivities(response.data);
  }

  useEffect(() => {
    getActivities();
  }, []);

  const {
    // leadid,
    companyFantasyName,
    companyName,
    email,
    // bz_ddd,
    phoneNumber,
    companyAdvisor,
    // address1_telephone2,
    document,
    companyOpenedDate,
    companyEconomicActivity,
    phoneAreaCode,
    originCountry,
    isRelationship,
  } = user.profile;

  const documentId = cnpjMask(document);
  const documentClassName = 'Documento de Constituição';
  const documentType = 'Tipo Documento Constituição';
  const documentProperty = 'Outros';

  useEffect(() => {
    const actualData = [];
    api.defaults.headers.Authorization = `Bearer ${auth.token}`;
    api.get('/lists/countries').then(({ data }) => {
      data.map((item) =>
        actualData.push({ label: item.country, value: item.id })
      );

      setCountries(actualData.reverse());
    });
  }, [auth.token]);

  useEffect(() => {
    window.amplitude.getInstance().logEvent('access Company Data page');
  }, []);

  useEffect(() => {
    setPercentage(calculatePercentPJGeneralData(user.profile));
  }, [
    companyFantasyName,
    companyName,
    email,
    phoneNumber,
    document,
    companyOpenedDate,
    companyEconomicActivity,
    originCountry,
  ]);

  // function onFileUpload(values, token) {
  //   const newFile = new FormData();
  //   if (values.length > 0) {
  //     values.map((item) => newFile.append('file', item));

  //     const escapedDocument = user.profile.document ? user.profile.document.replace(/\D/g, '') : '';

  //     newFile.append('id', escapedDocument);
  //     newFile.append('className', 'Documento de Constituição');
  //     newFile.append('type', 'Tipo Documento Constituição');
  //     newFile.append('property', 'Outros');

  //     api.defaults.headers.Authorization = `Bearer ${token}`;

  //     api
  //       .post(`/fileManager`, newFile, {
  //         headers: { 'Content-Type': 'multipart/form-data' },
  //       })
  //       .then((item) => {
  //         toast.success('Arquivo enviado com sucesso.');
  //       })
  //       .catch((item) => {
  //         setShowModal(true);
  //         setErrorData({
  //           title: 'Erro ao processar dados',
  //           description: 'Tivemos um problema no envio do seu arquivo, tente efetuar o upload novamente.',
  //         });

  //         // toast.error(
  //         //   'Houve um problema ao enviar as informações do documento.'
  //         // );
  //       });
  //   }
  // }

  // function onFormSubmit(values, action) {
  //   const {
  //     companyFantasyName,
  //     companyName,
  //     email,
  //     cnpj,
  //     telphone,
  //     companyOpenedDate,
  //     companyEconomicActivity,
  //     originCountry,
  //     companyAdvisor,
  //     document,
  //     // files,
  //   } = values;
  //   const id = user.profile._id;
  //   const token = auth.token;

  //   try {
  //     action.setSubmitting(true);
  //     dispatch(setLoading());
  //     const dddNumber = telphone.substr(0, 2);
  //     const newPhoneNumber = telphone.substr(2);

  //     // if (files) onFileUpload(files, token);
  //     api
  //       .put(`/users/${id}/enterprise-general`, {
  //         companyFantasyName: companyFantasyName,
  //         companyName: companyName,
  //         email: email,
  //         companyOpenedDate: companyOpenedDate,
  //         companyEconomicActivity: companyEconomicActivity,
  //         originCountry: originCountry,
  //         companyAdvisor: companyAdvisor,
  //         phone: telphone,
  //         phoneAreaCode: dddNumber,
  //         phoneNumber: newPhoneNumber,
  //       })
  //       .then((item) => {
  //         dispatch(
  //           updateUser({
  //             user: item.data,
  //           })
  //         );
  //         dispatch(loadingCompleted());

  //         toast.success('Alterações realizadas com sucesso.');
  //         history.replace('/update-data');
  //       })
  //       .catch((item) => {
  //         setShowModal(true);
  //         setErrorData({
  //           title: 'Erro ao processar dados',
  //           description: 'Tivemos um problema no envio das informações, revise o preenchimento dos campos obrigatórios ou tente novamente.',
  //         });

  //         // toast.error('Houve um problema ao enviar as informações.');
  //         dispatch(loadingCompleted());
  //         action.setSubmitting(false);
  //       });
  //   } catch (err) {
  //     // toast.error('Houve um problema ao enviar as informações.');
  //     setShowModal(true);
  //     setErrorData({
  //       title: 'Erro ao processar dados',
  //       description: 'Tivemos um problema no envio das informações, revise o preenchimento dos campos obrigatórios ou tente novamente.',
  //     });

  //     dispatch(loadingCompleted());
  //     action.setSubmitting(false);
  //   }
  // }

  const escapedPhoneNumber = phoneNumber ? phoneNumber.replace(/\D/g, '') : '';

  const FieldsSchema = Yup.object().shape({
    companyFantasyName: Yup.string()
      .min(3, 'Tamanho mínimo de 3 caractéres')
      .required('Obrigatório'),
    companyName: Yup.string()
      .min(3, 'Tamanho mínimo de 3 caractéres')
      .required('Obrigatório'),
    email: Yup.string().email().required('Obrigatório'),
    cnpj: Yup.string()
      .min(18, 'Tamanho mínimo de 18 caractéres')
      .required('Obrigatório'),
    telphone: Yup.string()
      .max(11, 'Tamanho máximo de 11 caracteres')
      .required('Obrigatório'),
    companyEconomicActivity: Yup.string().required('Obrigatório').nullable(),
    originCountry: Yup.string().required('Obrigatório').nullable(),
    companyOpenedDate: Yup.date()
      .transform((value) => {
        return value ? dayjs(value).toDate() : value;
      })
      .required('Obrigatório')
      .test('data_criação', 'A data não pode ser futura', function (value) {
        if (dayjs().diff(dayjs(value), 'day') < 0) {
          return false;
        }
        return true;
      })
      .typeError('Data inválida'),
    // files: isRelationship === true && filesList.length > 0
    //   ? Yup.mixed()
    //     .test(
    //       'fileSize',
    //       'Arquivo muito grande',
    //       (files) =>
    //         files && files.length > 0 && files[0].size <= DEFAULT_SIZE_LIMIT
    //     )
    //     .test(
    //       'fileFormat',
    //       'Formato não suportado',
    //       (files) =>
    //         files &&
    //         files.length > 0 &&
    //         DEFAULT_ALLOWED_FILE_TYPES.includes(files[0].type)
    //     )
    //   : Yup.mixed()
    //     // .required('Obrigatório')
    //     .test(
    //       'fileSize',
    //       'Arquivo muito grande',
    //       (files) =>
    //         files && files.length > 0 && files[0].size <= DEFAULT_SIZE_LIMIT
    //     )
    //     .test(
    //       'fileFormat',
    //       'Formato não suportado',
    //       (files) =>
    //         files &&
    //         files.length > 0 &&
    //         DEFAULT_ALLOWED_FILE_TYPES.includes(files[0].type)
    //     ),
  });

  return (
    <LoggedLayout
      isUploading={fileIsUploading}
      uploadProgressFileList={uploadProgressFileList}
    >
      <Sidebar>
        <SidebarContent>
          <ReactSVG src='/icons/pj.svg' />
          <SidebarTitle internal>Geral</SidebarTitle>
          <SidebarSubtitle internal>
            Informações de CNPJ, Razão Social, atividade e etc.
          </SidebarSubtitle>

          {percentage === 100 ? (
            <InfoStatusComplete>Concluído</InfoStatusComplete>
          ) : (
            <InfoStatusIncomplete>Incompleto</InfoStatusIncomplete>
          )}
        </SidebarContent>
      </Sidebar>
      <LayoutContent>
        <Formik
          initialValues={{
            companyFantasyName: companyFantasyName || '',
            companyName: companyName || '',
            email: email || '',
            cnpj: document ? cnpjMask(document) : '',
            telphone: phoneNumber
              ? `${phoneAreaCode}${escapedPhoneNumber}`
              : '',
            companyOpenedDate: companyOpenedDate || '',
            companyEconomicActivity: companyEconomicActivity || '',
            originCountry:
              originCountry ?? '010cac00-4a4a-e911-a966-000d3ac1b38b',
            companyAdvisor: companyAdvisor || '',
            // files: '',
          }}
          validationSchema={FieldsSchema}
          onSubmit={async (values, action) => {
            const {
              companyFantasyName,
              companyName,
              email,
              cnpj,
              telphone,
              companyOpenedDate,
              companyEconomicActivity,
              originCountry,
              companyAdvisor,
              document,
              // files,
            } = values;
            const id = user.profile._id;
            const { token } = auth;

            try {
              action.setSubmitting(true);
              dispatch(setLoading());
              api.defaults.headers.Authorization = `Bearer ${token}`;
              const dddNumber = telphone.substr(0, 2);
              const newPhoneNumber = telphone.substr(2);

              // if (files) onFileUpload(files, token);
              if (filesToUpload.length > 0) {
                setFileIsUploading(true);
                let uploadList = filesToUpload.map((f) => {
                  return {
                    id: f.name,
                    progress: 0,
                  };
                });
                setUploadProgressFileList([...uploadList]);
                await Promise.all(
                  filesToUpload.map((item) =>
                    fileUploaderService
                      .UploadFiles(
                        item,
                        documentId,
                        documentClassName,
                        documentType,
                        documentProperty,
                        (progress) => {
                          uploadList = uploadList.map((up) => {
                            return {
                              id: up.id,
                              progress:
                                up.id == item.name ? progress : up.progress,
                            };
                          });
                          setUploadProgressFileList([...uploadList]);
                        }
                      )
                      .then(() => {
                        uploadList = uploadList.map((up) => {
                          return {
                            id: up.id,
                            progress: up.id == item.name ? 100 : up.progress,
                          };
                        });
                        setUploadProgressFileList([...uploadList]);
                      })
                  )
                );
                setFileIsUploading(false);
              }

              api
                .put(`/register/users/enterprise-general`, {
                  companyFantasyName,
                  companyName,
                  email,
                  companyOpenedDate,
                  companyEconomicActivity,
                  originCountry,
                  companyAdvisor,
                  phone: telphone,
                  phoneAreaCode: dddNumber,
                  phoneNumber: newPhoneNumber,
                })
                .then((item) => {
                  dispatch(
                    updateUser({
                      user: item.data,
                    })
                  );
                  dispatch(loadingCompleted());

                  toast.success('Alterações realizadas com sucesso.');
                  history.replace('/update-data');
                })
                .catch((item) => {
                  setShowModal(true);
                  setErrorData({
                    title: 'Erro ao processar dados',
                    description:
                      'Tivemos um problema no envio das informações, revise o preenchimento dos campos obrigatórios ou tente novamente.',
                  });

                  // toast.error('Houve um problema ao enviar as informações.');
                  dispatch(loadingCompleted());
                  action.setSubmitting(false);
                });
            } catch (err) {
              // toast.error('Houve um problema ao enviar as informações.');
              setShowModal(true);
              setErrorData({
                title: 'Erro ao processar dados',
                description:
                  'Tivemos um problema no envio das informações, revise o preenchimento dos campos obrigatórios ou tente novamente.',
              });

              dispatch(loadingCompleted());
              action.setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            isValid,
            touched,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            const FileChange = (value, event) => {
              setFieldValue('files', [...values.files, ...value]);
            };

            const handleSelectOriginCountry = (value) => {
              setFieldValue('originCountry', value);
            };

            const handleSelectChange = (value, event) => {
              setFieldValue('companyEconomicActivity', value ?? '');
            };

            const FileRemove = (value, event) => {
              const filter = values.files.filter((item) => {
                return item.path !== value;
              });

              setFieldValue('files', filter);
            };

            return (
              <Form onSubmit={handleSubmit}>
                <All>
                  <Breadcrumb>
                    <a className='step' href='/home'>
                      Home
                    </a>
                    <a className='step' href='/update-data'>
                      Meus dados
                    </a>
                    <a className='active'>Geral</a>
                  </Breadcrumb>

                  <BackButton>
                    <ArrowLeft />
                    <Link to='/update-data'>
                      <p>Voltar</p>
                    </Link>
                  </BackButton>
                  <Row>
                    <Col xs={24} lg={16}>
                      <StyledTitle>Nome e Contato</StyledTitle>
                      <Row>
                        <Col xs={24} lg={12}>
                          <FormGroup>
                            <ControlLabel>Nome fantasia *</ControlLabel>
                            <StyledInput
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='companyFantasyName'
                              id='companyFantasyName'
                              value={values.companyFantasyName}
                              type='companyFantasyName'
                              required
                            />
                            {errors.companyFantasyName &&
                              touched.companyFantasyName && (
                                <span className='input-error'>
                                  {errors.companyFantasyName}
                                </span>
                              )}
                          </FormGroup>
                        </Col>
                        <Col xs={24} lg={12}>
                          <FormGroup>
                            <ControlLabel>Razão Social *</ControlLabel>
                            <StyledInput
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='companyName'
                              id='companyName'
                              value={values.companyName}
                              disabled
                              required
                            />
                            {errors.companyName && touched.companyName && (
                              <span className='input-error'>
                                {errors.companyName}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24} lg={12}>
                          <FormGroup>
                            <ControlLabel>Email Comercial *</ControlLabel>
                            <StyledInput
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='email'
                              id='email'
                              value={values.email}
                              type='email'
                            />

                            {errors.email && touched.email && (
                              <span className='input-error'>
                                {errors.email}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xs={24} lg={12}>
                          <FormGroup>
                            <ControlLabel>Telefone *</ControlLabel>
                            <StyledInput
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='telphone'
                              id='telphone'
                              value={values.telphone}
                              type='text'
                            />

                            {errors.telphone && touched.telphone && (
                              <span className='input-error'>
                                {errors.telphone}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <StyledTitle>Dados</StyledTitle>
                      <Row>
                        <Col xs={24} lg={12}>
                          <FormGroup>
                            <ControlLabel>CNPJ</ControlLabel>
                            <StyledInput
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='cnpj'
                              id='cnpj'
                              disabled={document != undefined}
                              value={cnpjMask(values.cnpj)}
                              type='text'
                            />
                            {errors.cnpj && touched.cnpj && (
                              <span className='input-error'>{errors.cnpj}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xs={24} lg={12}>
                          <FormGroup>
                            <ControlLabel>Data de Abertura *</ControlLabel>
                            <StyledInput
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='companyOpenedDate'
                              id='companyOpenedDate'
                              required
                              value={values.companyOpenedDate}
                              type='date'
                            />
                            {errors.companyOpenedDate &&
                              touched.companyOpenedDate && (
                                <span className='input-error'>
                                  {errors.companyOpenedDate}
                                </span>
                              )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24} lg={12}>
                          <FormGroup>
                            <ControlLabel>Atividade Econômica *</ControlLabel>
                            <StyledSelect
                              size='lg'
                              placeholder='Atividades'
                              onChange={(value) => handleSelectChange(value)}
                              value={values?.companyEconomicActivity ?? ''}
                              locale={{ searchPlaceholder: 'Pesquisar' }}
                              required
                              data={activities.map((activity) => ({
                                label: `${activity.code} - ${activity.occupation}`,
                                value: activity.id,
                              }))}
                            />
                            {errors.companyEconomicActivity &&
                              touched.companyEconomicActivity && (
                                <span className='input-error'>
                                  {errors.companyEconomicActivity}
                                </span>
                              )}
                          </FormGroup>
                        </Col>
                        <Col xs={24} lg={12}>
                          <FormGroup>
                            <ControlLabel>Pais de origem *</ControlLabel>
                            <StyledSelect
                              size='lg'
                              placeholder={
                                countries?.length
                                  ? 'Escolha seu país...'
                                  : 'Carregando...'
                              }
                              onChange={(value) =>
                                handleSelectOriginCountry(value)
                              }
                              value={values?.originCountry ?? ''}
                              locale={{ searchPlaceholder: 'Pesquisar' }}
                              required
                              data={countries}
                            />
                            {errors.originCountry && touched.originCountry && (
                              <span className='input-error'>
                                {errors.originCountry}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24} lg={24}>
                          <FormGroup>
                            <ControlLabel>
                              Você teve auxílio de algum assessor Advanced?
                            </ControlLabel>
                            <StyledInput
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='companyAdvisor'
                              id='companyAdvisor'
                              value={values.companyAdvisor}
                              type='text'
                              style={{ marginBottom: '8px', width: '100%' }}
                            />
                            <ControlLabel>(opcional)</ControlLabel>

                            {errors.companyAdvisor &&
                              touched.companyAdvisor && (
                                <span className='input-error'>
                                  {errors.companyAdvisor}
                                </span>
                              )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={24} lg={8}>
                      <UploadFiles
                        className={documentClassName}
                        type={documentType}
                        titleText='Envie documentos de identificação, como o contrato social'
                        required={isRelationship !== true}
                        files={filesToUpload}
                        onChange={(files) => {
                          setFilesToUpload([...files]);
                          setFieldValue('files', files);
                        }}
                        listOldFiles
                        setFilesList={setFilesList}
                      />
                      {/* <DocumentFields withoutPadding>
                        <TitleFile>Envie documentos de identificação, como o contrato social</TitleFile>
                        <FormGroup>
                          <InputUpload
                            id='files'
                            name='files'
                            data={values.files}
                            onChange={FileChange}
                            removeFile={FileRemove}
                            acceptedMimeTypes={DEFAULT_ALLOWED_FILE_TYPES}
                            size={DEFAULT_SIZE_LIMIT}
                            required={
                              isRelationship === true && filesList.length > 0
                                ? false
                                : true
                            }
                          />
                          {successMsg && (
                            <SuccessBox>
                              Arquivos enviados com sucesso!
                            </SuccessBox>
                          )}
                          {errorMsg && (
                            <ErrorBox>
                              Houve um erro no envio dos arquivos. Tente anexar
                              novamente
                            </ErrorBox>
                          )}
                        </FormGroup>
                        <FilesList setFilesList={setFilesList} nameClass='Documento de Constituição' />
                      </DocumentFields> */}
                    </Col>
                  </Row>

                  <StyledButton
                    type='submit'
                    // disabled={!isValid || loading || !values.files.length}
                    disabled={
                      isRelationship === true
                        ? !isValid || loading
                        : !isValid || loading || !filesToUpload.length
                    }
                  >
                    Salvar
                  </StyledButton>

                  <FooterLabel>
                    * Campos obrigatórios para salvar formulário e seguir com o
                    cadastro.
                  </FooterLabel>
                </All>
              </Form>
            );
          }}
        </Formik>
      </LayoutContent>
    </LoggedLayout>
  );
};

export default Home;
