import { createGlobalStyle, withTheme } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  *,
  *::after,
  *::before {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
  }

    /* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #AFBAC7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2E4378;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #132552;
}

#root {
  height: 100%;
}
  body {
    font-family: ${(props) => props.theme.fontFamily};
    background: #fff;
  }

  h1, h2, h3, h4, h5 {
    font-style: normal;
    font-weight: 500;
  }
  h1 {
    font-size: 50px;
    line-height: 62px;
  }
  h2 {
    font-size: 40px;
    line-height: 50px;
  }
  h3 {
    font-size: 32px;
    line-height: 40px;
  }
  h4 {
    font-size: 25px;
    line-height: 34px;
  }
  h5 {
    font-size: 20px;
    line-height: 28px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }

  ul {
    list-style: none;
  }

  // Placeholder shown doesn't work
  .rs-input:not([value=""]), .rs-picker-has-value .rs-btn {
    border-color: #9BA8B7;
  }

  .blurred {
    filter: blur(2px);
    pointer-events: none;
  }
`;

export default withTheme(GlobalStyle);
