import storage from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';

export default (reducers) => {
  const persitedReducer = persistReducer(
    {
      key: '@PanelAdvanced',
      storage,
      whitelist: [
        'auth',
        'user',
        'bank',
        'partner',
        'bankList',
        'partnersList',
        'chat',
      ],
    },
    reducers
  );

  return persitedReducer;
};
