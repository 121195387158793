import React from 'react';
import { List, Title, SubTitle } from './styles';
import Tag from '../Tag';

const WelcomeList = (props) => {
  return (
    <List>
      <Title className={props.tag && 'list-tagged'} color={props.color}>
        <img src={props.icon} className='icon' alt='Ícone de Check' />
        <span className='list-title'>{props.title}</span>
        {props.tag && (
          <Tag danger style={{ marginLeft: '10px' }}>
            {props.tag}
          </Tag>
        )}
      </Title>

      <SubTitle>{props.subtitle}</SubTitle>
    </List>
  );
};

export default WelcomeList;
