import produce from 'immer';

const INITIAL_STATE = {
  partners: [],
};

export default function partnersList(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@partnersList/SAVE_PARTNERS': {
        draft.partners = action.payload.partners;
        break;
      }

      default:
        break;
    }
  });
}
