import styled from 'styled-components';

import { Radio, Modal } from 'rsuite';

import Button from '../Button';

export const CustomModal = styled(Modal)`
  width: 100%;
  height: 95%;
  z-index: 50;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  @media (min-width: 800px) {
    width: 600px;

    ${(props) =>
      props.size &&
      props.size === 'sm' &&
      `
      width: 503px;
    `}
  }

  .error-message {
    color: #ff0000;
  }

  @media (min-width: 768px) {
    .rs-modal-content {
      padding: 24px 40px;
    }
  }
`;

export const ContentModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0 56px 0 58px; */
  }
`;

export const BodyContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  color: #20315b;
  margin-top: 32px;

  @media (min-width: 800px) {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #20315b;
    margin-top: 32px;
  }
`;

export const TextBody = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #455566;
  margin-top: 16px;

  @media (min-width: 800px) {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #455566;
    margin-top: 16px;
  }
`;

export const RadioCustom = styled(Radio)`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #455566;

  opacity: 0.8;

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 20px;
  }

  .rs-radio-checker:hover .rs-radio-wrapper .rs-radio-inner::before {
    border-color: #0fc7ff !important;
  }
  .rs-radio-checker:hover .rs-radio-wrapper .rs-radio-inner::after {
    border-color: #0fc7ff !important;
    background: #0fc7ff !important;
    border: 8px solid #0fc7ff !important;
    box-sizing: border-box !important;
  }

  .rs-radio-checked .rs-radio-wrapper .rs-radio-inner::after {
    border-color: #0fc7ff !important;
    background-color: #0fc7ff !important;
    border: 8px solid #0fc7ff !important;
    box-sizing: border-box !important;
  }

  .rs-radio-wrapper .rs-radio-inner::before {
    width: 24px;
    height: 24px;
  }

  .rs-radio-checker {
    padding-top: 12px;
    padding-bottom: 10px;
    padding-left: 52px;
    min-height: 36px;
    line-height: 1;
    position: relative;
  }

  span.rs-radio-wrapper::after {
    width: 24px;
    height: 24px;
  }

  span.rs-radio-inner::after {
    border-color: #0fc7ff;
    background: #0fc7ff !important;
    border: 8px solid #0fc7ff !important;
    box-sizing: border-box !important;
    margin: 4px 0px 0px 4px !important;
  }

  span.rs-radio-inner::before {
    background: none !important;
  }
`;

export const CustomButtom = styled(Button)`
  width: 154px;
  height: 40px;
  margin-top: 27px;
  min-width: min-content;
`;

export const Box = styled.div`
  width: 445px;
  height: 132px;
  background: #eff2f7;
  border-radius: 4px;
  margin-top: 16px;
  flex-direction: column;
  padding: 20px 32px;
`;

export const BoxTitle = styled.p`
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #455566;

  opacity: 0.8;
`;

export const BoxText = styled.p`
  font-family: IBM Plex Sans;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #455566;

  opacity: 0.8;
`;
