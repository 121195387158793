import React, { useEffect, useState } from 'react';
import { Col, Row } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSmartScroller } from 'react-smart-scroller';
import LoggedLayout from '../../components/LoggedLayout';
import {
  AbaDivider,
  AbaText,
  CalculatorTitle,
  Card,
  CustomGrid,
  HorizontalScroll,
  ResponsiveContainer,
  SubTitle,
  Title,
} from './styles';
import { ReactComponent as IconDoc } from './icons/iconDoc.svg';
import { ReactComponent as ReceiveMoneyFromAbroadIcon } from './icons/receiveMoneyFromAbroadIcon.svg';
import { ReactComponent as SendMoneyToYourAccountAbroadIcon } from './icons/sendMoneyToYourAccountAbroadIcon.svg';
import { ReactComponent as SmallCommitmentsIcon } from './icons/smallCommitmentsIcon.svg';
import { ReactComponent as DirectInvestimentIcon } from './icons/directInvestimentIcon.svg';
import { ReactComponent as CashIcon } from './icons/cashIcon.svg';
import { ReactComponent as CashOnPrepaidCardIcon } from './icons/cashOnPrepaidCardIcon.svg';
import SelectOptionCard from './SelectOptionCard';
import ExchangeMoneyCalculator from '../../components/ExchangeMoneyCalculator';
import Button from '../../components/Button';
import ExchangeMoneyCalculatorTaxInformation from '../../components/ExchangeMoneyCalculator/TaxInformation';
import { selectProductType } from '../../store/modules/exchange/actions';
import { Spacer } from '../../components/Spacer';

const SelectOption = () => {
  const dispatch = useDispatch();

  const { selectedProduct } = useSelector((state) => state.exchange);

  const [selectedOption, setSelectedOption] = useState(null);
  const [errors, setErrors] = useState([]);
  const [mobileButton, setMobileButton] = useState('sendMoneyAbroad');
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const onSelectedOption = (id) => {
    if (selectedOption != id) {
      setSelectedOption(id);
      if (id === 'cash') dispatch(selectProductType('ESPECIE'));
      else if (id === 'cashOnPrepaidCard')
        dispatch(selectProductType('CARTAO'));
      else dispatch(selectProductType(null));
    } else {
      setSelectedOption(null);
    }
  };

  const isValidToContinue = () =>
    selectedOption != null &&
    errors.length === 0 &&
    selectedProduct.currencyToValue > 0;

  const sentMoneyAbroadCards = [
    <SelectOptionCard
      id='receiveMoneyFromAbroad'
      labelText='Receba dinheiro do exterior'
      tooltipText='Receba transferências internacionais, seja pagamento de serviços prestados, salário, retorno de investimentos e mais
em sua conta no exterior'
      selected={selectedOption}
      onClick={onSelectedOption}
    >
      <ReceiveMoneyFromAbroadIcon />
    </SelectOptionCard>,

    <SelectOptionCard
      id='sendMoneyToYourAccountAbroad'
      labelText='Enviar dinheiro pra sua conta no exterior'
      selected={selectedOption}
      onClick={onSelectedOption}
    >
      <SendMoneyToYourAccountAbroadIcon />
    </SelectOptionCard>,

    <SelectOptionCard
      id='smallCommitments'
      labelText='Pequenos compromissos'
      selected={selectedOption}
      onClick={onSelectedOption}
    >
      <SmallCommitmentsIcon />
    </SelectOptionCard>,

    <SelectOptionCard
      id='directInvestments'
      isComingSoon
      selected={selectedOption}
      labelText='Investimento direto'
      onClick={onSelectedOption}
      disabled
    >
      <DirectInvestimentIcon />
    </SelectOptionCard>,
  ];

  const moneyToTravelCards = [
    <SelectOptionCard
      id='cash'
      labelText='Dinheiro em espécie'
      selected={selectedOption}
      onClick={onSelectedOption}
    >
      <CashIcon />
    </SelectOptionCard>,

    <SelectOptionCard
      id='cashOnPrepaidCard'
      labelText='Dinheiro no cartão pré-pago'
      selected={selectedOption}
      onClick={onSelectedOption}
    >
      <CashOnPrepaidCardIcon />
    </SelectOptionCard>,
  ];

  return (
    <LoggedLayout>
      <CustomGrid fluid>
        <Row>
          <Col xs={3} md={1}>
            <IconDoc />
          </Col>
          <Col xs={21} md={23}>
            <Title>Escolha a melhor opção pra você</Title>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <SubTitle>
              Veja quais as opções de câmbio da Advanced é a mais adequada para
              você
            </SubTitle>
          </Col>
        </Row>
        <Row style={{ height: '30px' }} />
        <Row>
          {/* Desktop */}
          <Col xsHidden smHidden md={16}>
            <AbaText isActive>Enviar dinheiro para o exterior</AbaText>
            <AbaDivider isActive />
            <Row>
              {sentMoneyAbroadCards.map((e, idx) => (
                <Col key={idx} md={24 / sentMoneyAbroadCards.length}>
                  {e}
                </Col>
              ))}
            </Row>
          </Col>
          <Col xsHidden smHidden md={8}>
            <AbaText>Dinheiro para viajar</AbaText>
            <AbaDivider />
            <Row>
              {moneyToTravelCards.map((e, idx) => (
                <Col key={idx} md={24 / moneyToTravelCards.length}>
                  {e}
                </Col>
              ))}
            </Row>
          </Col>
          {/* Mobile */}
          <Col xs={13} mdHidden lgHidden>
            <Button
              size='xs'
              active={mobileButton !== 'sendMoneyAbroad'}
              onClick={() => setMobileButton('sendMoneyAbroad')}
            >
              Enviar dinheiro ao exterior
            </Button>
          </Col>
          <Col xs={11} mdHidden lgHidden>
            <Button
              size='xs'
              active={mobileButton !== 'moneyToTravel'}
              onClick={() => setMobileButton('moneyToTravel')}
            >
              Dinheiro para viajar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={24} mdHidden lgHidden>
            <ReactSmartScroller
              style={{
                width: windowSize.innerWidth - 10,
                marginBottom: 20,
                marginTop: 20,
              }}
              spacing={15}
              draggable
            >
              {mobileButton === 'sendMoneyAbroad' && sentMoneyAbroadCards}
              {mobileButton === 'moneyToTravel' && moneyToTravelCards}
            </ReactSmartScroller>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Card>
              <Row>
                <Col xs={24}>
                  <CalculatorTitle>
                    Por favor, informe qual é o valor que você deseja realizar
                    nessa transação:
                  </CalculatorTitle>
                </Col>
              </Row>
              <Row style={{ height: 30 }} />
              <Row style={{ width: '100%' }}>
                {/* Mobile */}
                <Col xs={24} smHidden mdHidden lgHidden>
                  <ExchangeMoneyCalculator
                    onCalculate={(err) => {
                      setErrors(err);
                    }}
                  />
                </Col>
                {/* Desktop */}
                <Col xsHidden sm={18} md={20}>
                  <ExchangeMoneyCalculator
                    inline
                    onCalculate={(err) => {
                      setErrors(err);
                    }}
                  />
                </Col>

                <ResponsiveContainer xs={24} sm={6} md={4}>
                  <Button
                    style={{ width: '100%', minWidth: '200px', height: '48px' }}
                    onClick={() => false}
                    disabled={!isValidToContinue()}
                  >
                    Simular
                  </Button>
                </ResponsiveContainer>
              </Row>
              <Row style={{ height: 30 }} />
              <Row>
                <Col xsHidden smHidden md={24}>
                  <ExchangeMoneyCalculatorTaxInformation inline />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row style={{ height: '200px' }} />
      </CustomGrid>
    </LoggedLayout>
  );
};

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default SelectOption;
