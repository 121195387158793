import axios from 'axios';

export const vuoriApi = axios.create({
  baseURL: process.env.REACT_APP_URL_VUORI_API,
});

export const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
});

export const exchangeAPI = axios.create({
  baseURL: process.env.REACT_APP_EXCHANGE_URL,
});

export const authAPI = axios.create({
  baseURL: process.env.REACT_APP_URL_AUTH_API,
});
export default api;
