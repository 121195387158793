import styled from 'styled-components';
import { Nav, Content, SelectPicker } from 'rsuite';
import { ReactSVG } from 'react-svg';

export const DisplayContent = styled.div`
  .active {
    color: #455566;
    text-decoration: none;
  }

  @media (min-width: 768px) {
    margin-top: 40px;
    margin-left: 0;
  }
`;

export const BackButton = styled.div`
  display: flex;
  color: #0fc7ff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  p {
    margin-left: 8px;
  }

  a {
    text-decoration: none;
    color: #0fc7ff;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const HeaderContent = styled.div`
  grid-column: 1/-1;
  margin-top: 5px;
`;

export const SelectDocument = styled.div`
  display: flex;

  > span {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 26px;
    margin-right: 8px;

    @media (max-width: 375px) {
      margin-left: 0px;
    }
  }
`;

export const SelectPickerCustom = styled(SelectPicker)`
  width: 230px;

  @media (max-width: 345px) {
    width: 210px;
  }
`;

export const TabsContent = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 80px auto;
  padding: 15px;
  width: 100%;
`;

export const Tabs = styled.div`
  display: flex;
`;

export const TabsBody = styled.div`
  grid-column: 1 / -1;
  margin-top: 32px;

  @media (max-width: 800px) {
    margin-top: 6rem;
  }
`;

export const StyledNav = styled(Nav)`
  grid-column: 1 / -1;
  padding-top: 15px;
  height: 30px;

  .rs-nav-item-content {
    font-family: IBM Plex Sans;
    font-size: 1rem;
    padding: 8px 12px 18px 0;
    font-weight: 500;
    line-height: 28px;
  }

  @media (min-width: 801px) {
    .rs-nav-item-content {
      font-size: 1.25rem;
    }
  }

  .rs-nav-item-active {
    & .rs-nav-item-content {
      font-weight: bold;
      color: #09b2e6 !important;

      &:hover {
        color: #09b2e6;
      }

      &::before {
        background-color: #09b2e6 !important;
        height: 3px !important;
      }
    }
  }

  @media screen and (max-width: 800px) and (min-width: 426px) {
    .rs-nav-item-content {
      font-family: IBM Plex Sans;
      font-size: 20px;
      padding: 8px 12px 18px 0;
      font-weight: 500;
      line-height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rs-nav-item-active {
      & .rs-nav-item-content {
        font-weight: bold;
        color: #09b2e6 !important;

        &:hover {
          color: #09b2e6;
        }

        &::before {
          background-color: #09b2e6 !important;
          height: 3px !important;
        }
      }
    }
  }

  @media (max-width: 529px) {
    margin-bottom: 3rem;
  }

  @media (max-width: 425px) {
    .rs-nav-item-content {
      font-family: IBM Plex Sans;
      font-size: 1rem;
      padding: 8px 12px 18px 0;
      font-weight: 500;
      /* line-height: 28px; */
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rs-nav-item-active {
      & .rs-nav-item-content {
        font-weight: bold;
        color: #09b2e6 !important;

        &:hover {
          color: #09b2e6;
        }

        &::before {
          background-color: #09b2e6 !important;
          height: 3px !important;
        }
      }
    }
  }
`;

export const CustomContent = styled(Content)`
  @media (min-width: 769px) {
    margin: 40px 40px 40px 140px;
  }

  @media screen and (max-width: 800px) and (min-width: 426px) {
    margin: 96px 20px 0 20px;

    .rs-nav.rs-nav-horizontal.rs-nav-justified > ul {
      flex-wrap: wrap;
    }
  }

  @media (max-width: 425px) {
    margin: 20px;

    .rs-nav.rs-nav-horizontal.rs-nav-justified > ul {
      flex-wrap: wrap;
    }
  }
`;

export const ReactSVGCustomDiv = styled(ReactSVG)`
  div {
    display: flex;
    justify-content: center;
  }
`;

export const Title = styled.h2`
  color: #20315b;
  font-size: 32px;
`;

export const SubTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #455566;
`;

export const Spacer = styled.div`
  padding-top: 20px;
`;
