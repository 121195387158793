import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin-left: 32px;
  margin-right: 120px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  .rs-picker-select,
  input {
    width: 383px;
  }

  .rs-form-group {
    display: flex;
    flex-direction: column;

    & + .rs-form-group {
      margin-top: 24px;
    }
  }
`;
