import React, { useEffect, useState } from 'react';
import { Progress } from 'rsuite';
import dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';

import { Clock } from 'react-feather';
// import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateRemainingTime,
  setExpiredTime,
} from '~/store/modules/exchange/actions';
import { StyledBox, TimerHolder, Info, Timer } from './styles';

dayjs.extend(duration);

const TimerPayment = ({ time = { min: 0, sec: 0 }, onEnd, text }) => {
  const [percentLine, setPercentLine] = useState(100);
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [endTime, setEndTime] = useState(dayjs().add(10, 'm'));
  const [lineColor, setLineColor] = useState('#0FC7FF');
  const [timeLeft, setTimeLeft] = useState(
    Math.abs(currentTime.diff(endTime, 'seconds', false))
  );

  const dispatch = useDispatch();

  const { exchange } = useSelector((state) => state);

  const { Line } = Progress;

  const countTimer = () => {
    const timer = setInterval(() => {
      const diff = dayjs(new Date()).diff(endTime, 'seconds', false);
      const currentTimeLeft = Math.abs(diff >= 0 ? 0 : diff);

      if (currentTimeLeft === 0) {
        setTimeLeft(0);
        setPercentLine(0);
        clearInterval(timer);
      } else {
        setTimeLeft(currentTimeLeft);
        setPercentLine((currentTimeLeft / timeLeft) * 100);
      }
    }, 1000);
  };

  useEffect(() => {
    const endTimeScoped = exchange.timerInitialized
      ? exchange.timeToValidate
      : dayjs().add(5, 'm');
    if (exchange.timeToValidate) {
      countTimer();
    } else {
      setEndTime(endTimeScoped);
      countTimer();
    }
  }, []);

  useEffect(() => {
    dispatch(updateRemainingTime(timeLeft));

    if (!timeLeft) {
      dispatch(setExpiredTime());
    }
  }, [timeLeft]);

  useEffect(() => {
    if (percentLine <= 10) {
      setLineColor('#D92C2C');
    }
  }, [percentLine]);

  return (
    <StyledBox>
      <TimerHolder>
        <Clock size={15} />
        <Line percent={percentLine} strokeColor={lineColor} />
      </TimerHolder>

      <Info>
        <span>{text}</span>
        <Timer style={{ color: lineColor }}>
          {new Date(exchange.timeToValidate * 1000).toISOString().slice(14, 19)}
        </Timer>
      </Info>
    </StyledBox>
  );
};

export default TimerPayment;
