import * as dayjs from 'dayjs';
import React from 'react';

const NextBusinessDayButton = ({ date, onChange, isSelected }) => {
  if (!dayjs(date).isValid()) throw new Error('Invalid date');

  return (
    <button
      onClick={() => {
        if (onChange) onChange(date);
        else throw new Error('onChange function is required');
      }}
      style={{
        border: isSelected ? '1px solid #0FC7FF' : '1px solid #000000',
        borderRadius: 12,
        padding: 10,
        marginRight: 10,
        backgroundColor: isSelected ? '#0FC7FF' : 'transparent',
        color: isSelected ? '#E7FAFF' : '#000000',
      }}
    >
      {dayjs(date).format('DD/MM')}
    </button>
  );
};

export default NextBusinessDayButton;
