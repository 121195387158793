import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Breadcrumb,
  ControlLabel,
  Form,
  FormGroup,
  Progress,
  SelectPicker,
} from 'rsuite';
import * as Yup from 'yup';
import FilesList from '~/components/FilesList';
import { MobileHeaderData } from '~/components/HeaderData';
import { InputUpload } from '~/components/InputUpload';
import LoggedLayout, { LayoutContent } from '~/components/LoggedLayout';
import {
  Sidebar,
  SidebarContent,
  SidebarSubtitle,
  SidebarTitle,
} from '~/components/Sidebar';
import cepMask from '~/mask/zipcode';
import { api } from '~/services/api';
import {
  DEFAULT_ALLOWED_FILE_TYPES,
  DEFAULT_SIZE_LIMIT,
} from '~/services/files';
import zipcode from '~/services/zipcode';
import { loadingCompleted } from '~/store/modules/auth/actions';
import { updateUser } from '~/store/modules/user/actions';
import { calculatePercentAddress } from '~/utils/calculate-percent';
import {
  BackButton,
  Container,
  DisplayGrid,
  DocumentFields,
  FormGrid,
  StyledButton,
  StyledInput,
  StyledTitle,
  Title,
} from '../../components/styles/form.js';
import UploadFiles from '../../components/UploadFiles/index.js';
import cpfMask from '../../mask/cpf.js';
import cnpjMask from '../../mask/cnpj.js';
import Address from './icons/address.svg';
import * as fileUploaderService from '~/services/files';
import history from '../../services/history';
import ErrorModal from '~/components/ErrorModal/index.js';
import {
  CheckItem,
  FooterLabel,
  InfoStatusComplete,
  InfoStatusIncomplete,
} from './style.js';
import AutoCompleteAddress from '~/components/AutoCompleteAddress/index.js';

const AddressPage = () => {
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [uploadProgressFileList, setUploadProgressFileList] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [errorData, setErrorData] = useState({});

  const { user, auth } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const {
    addressZipcode,
    addressStreet,
    addressNumber,
    addressComplement,
    addressNeighborhood,
    addressCity,
    addressState,
    addressCountry,
    addressTypeId,
    document,
    internationalAddress,
    isRelationship,
  } = user.profile;

  const documentId =
    user.profile.document.length > 11
      ? cnpjMask(user.profile.document)
      : cpfMask(user.profile.document);
  const documentClassName = 'Comprovante de Endereço';
  const documentType = 'Tipo Comprovante Endereço';
  const documentProperty = 'Outros';

  const stateOptions = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Distrito Federal', value: 'DF' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
  ];

  useEffect(() => {
    const actualData = [];
    api.defaults.headers.Authorization = `Bearer ${auth.token}`;
    api.get('/lists/countries').then(({ data }) => {
      data.map((item) => {
        actualData.push({ label: item.country, value: item.id });
      });

      setCountries(actualData.reverse());
    });
  }, []);

  useEffect(() => {
    window.amplitude.getInstance().logEvent('access Address page');
  }, []);

  useEffect(() => {
    setPercentage(calculatePercentAddress(user.profile));
  }, [
    addressZipcode,
    addressStreet,
    addressNumber,
    addressNeighborhood,
    addressCity,
    addressState,
    addressCountry,
  ]);

  const FieldsSchema = Yup.object().shape({
    internationalAddress: Yup.boolean(),
    cep: Yup.string()
      .required('Obrigatório')
      .when('internationalAddress', (internationalAddress, schema) => {
        if (internationalAddress === false)
          return schema.min(8, 'Tamanho mínimo de 8 números');
      }),
    street: Yup.string().required('Obrigatório'),
    number: Yup.string().required('Obrigatório'),
    neighborhood: Yup.string().required('Obrigatório'),
    city: Yup.string().required('Obrigatório'),
    state: Yup.string().required('Obrigatório'),
    country: Yup.string().required('Obrigatório').nullable(),
    files: Yup.mixed(),
    addressTypeId: Yup.string().required('Obrigatório'),
  });

  async function UpdateAddress(data) {
    return api.put(`/register/users/address`, {
      addressZipcode: data.cep.replace('-', '').replace('.', ''),
      addressStreet: data.street,
      addressNumber: data.number,
      addressComplement: data.complement,
      addressNeighborhood: data.neighborhood,
      addressCity: data.city,
      addressState: data.state,
      addressCountry: data.country,
      internationalAddress: data.internationalAddress,
      addressTypeId: data.addressTypeId,
      // isRelationship: user.profile.relationship,
    });
  }

  const submitAddress = async (values, action) => {
    setLoading(true);

    if (filesToUpload.length > 0) {
      setFileIsUploading(true);
      let uploadList = filesToUpload.map((f) => {
        return {
          id: f.name,
          progress: 0,
        };
      });

      try {
        await Promise.all(
          filesToUpload.map((item) =>
            fileUploaderService
              .UploadFiles(
                item,
                documentId,
                documentClassName,
                documentType,
                documentProperty,
                (progress) => {
                  uploadList = uploadList.map((up) => {
                    return {
                      id: up.id,
                      progress: up.id == item.name ? progress : up.progress,
                    };
                  });
                  setUploadProgressFileList([...uploadList]);
                }
              )
              .then(() => {
                uploadList = uploadList.map((up) => {
                  return {
                    id: up.id,
                    progress: up.id == item.name ? 100 : up.progress,
                  };
                });
                setUploadProgressFileList([...uploadList]);
              })
          )
        );
        setFileIsUploading(false);
      } catch (error) {
        setShowModal(true);
        setErrorData({
          title: 'Erro ao enviar arquivo',
          description:
            'Tivemos um problema no envio do seu arquivo, tente efetuar o upload novamente.',
        });
      }
    }

    Promise.all([UpdateAddress(values)])
      .then((items) => {
        dispatch(loadingCompleted());
        action.setSubmitting(true);
        dispatch(
          updateUser({
            user: items[0].data,
          })
        );
        setLoading(false);
        toast.success('Alterações realizadas com sucesso.');

        window.amplitude.getInstance().logEvent('Updated address data');
        history.replace('/update-data');
      })
      .catch((item) => {
        setShowModal(true);
        setErrorData({
          title: 'Erro ao processar dados',
          description:
            'Tivemos um problema no envio das informações, revise o preenchimento dos campos obrigatórios ou tente novamente.',
        });

        // toast.error('Houve um problema ao enviar as informações.');
        dispatch(loadingCompleted());

        setLoading(false);
        action.setSubmitting(false);

        window.amplitude.getInstance().logEvent('Error in update client', item);
      });
  };

  return (
    <LoggedLayout
      isUploading={fileIsUploading}
      uploadProgressFileList={uploadProgressFileList}
    >
      <Container className='responsive-container'>
        <Sidebar>
          <SidebarContent>
            <img src={Address} alt='Ícone de endereço' />
            <SidebarTitle internal>Endereço</SidebarTitle>
            <SidebarSubtitle internal>
              Informação completa de endereço com comprovante
            </SidebarSubtitle>

            {percentage === 100 ? (
              <InfoStatusComplete>Concluído</InfoStatusComplete>
            ) : (
              <InfoStatusIncomplete>Incompleto</InfoStatusIncomplete>
            )}
          </SidebarContent>
        </Sidebar>

        <MobileHeaderData
          title='Endereço'
          percentage={percentage}
          icon={Address}
        />

        <LayoutContent>
          <ErrorModal
            showModal={showModal}
            onChange={() => setShowModal(false)}
            {...errorData}
          />

          <Formik
            initialValues={{
              internationalAddress: internationalAddress || false,
              cep: addressZipcode || '',
              street: addressStreet || '',
              number: addressNumber || '',
              complement: addressComplement || '',
              neighborhood: addressNeighborhood || '',
              city: addressCity || '',
              state: addressState || '',
              country: addressCountry || 'e01d95fd-fdc9-e711-8107-e0071b6e3d91',
              files: '',
              addressTypeId:
                addressTypeId || 'f9d46faf-bb21-ee11-9966-002248de6f9b',
            }}
            validationSchema={FieldsSchema}
            onSubmit={(values, action) => submitAddress(values, action)}
          >
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              isValid,
              touched,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              const handleSelectChange = (value, event) => {
                setFieldValue('country', value);
              };

              const FileChange = (value, event) => {
                setFieldValue('file', [...values.file, ...value]);
              };

              const FileRemove = (value, event) => {
                const filter = values.file.filter((item) => {
                  return item.path !== value;
                });

                setFieldValue('file', filter);
              };

              const handleZip = async (zip, event) => {
                setFieldValue('cep', zip);

                if (
                  zip.length === 10 &&
                  values.internationalAddress === false
                ) {
                  const newzipCode = zip.replace('.', '').replace('-', '');

                  await zipcode
                    .get(`${newzipCode}/json`)
                    .then(
                      ({
                        data: {
                          logradouro,
                          localidade,
                          uf,
                          complemento,
                          bairro,
                        },
                      }) => {
                        const BRAZIL_CODE =
                          'e01d95fd-fdc9-e711-8107-e0071b6e3d91';
                        const addressValues = [
                          { id: 'state', value: uf },
                          { id: 'city', value: localidade },
                          { id: 'neighborhood', value: bairro },
                          { id: 'street', value: logradouro },
                          { id: 'number', value: '' },
                          { id: 'complement', value: complemento },
                          { id: 'country', value: BRAZIL_CODE },
                        ];

                        addressValues.map(({ id, value }) => {
                          if (id === 'country') {
                            return handleSelectChange(value);
                          }
                          return setFieldValue(id, value);
                        });
                      }
                    );
                }
              };

              const handleSelectStateChange = (value, event) => {
                setFieldValue('state', value);
              };

              const handleSelectAddresTypeChange = (value, event) => {
                setFieldValue('addressTypeId', value);
              };

              return (
                <Form onSubmit={handleSubmit}>
                  <DisplayGrid>
                    <FormGrid>
                      <Breadcrumb>
                        <Link to='/home'>Home</Link>
                        <Link to='/update-data'>Meus dados</Link>
                        <Link className='active'>Endereço</Link>
                      </Breadcrumb>

                      <BackButton className='back-button'>
                        <ArrowLeft />
                        <Link to='/update-data'>
                          <p>Voltar</p>
                        </Link>
                      </BackButton>
                      <StyledTitle>
                        {document.length > 11
                          ? 'Endereço da empresa'
                          : 'Seu endereço'}
                      </StyledTitle>
                      {isValid}
                      <FormGroup>
                        <CheckItem
                          checked={values.internationalAddress}
                          onChange={() =>
                            setFieldValue(
                              'internationalAddress',
                              !values.internationalAddress
                            )
                          }
                        >
                          Endereço internacional
                        </CheckItem>
                        <ControlLabel>CEP *</ControlLabel>
                        <StyledInput
                          onChange={(value, event) => handleZip(value, event)}
                          onBlur={handleBlur}
                          name='cep'
                          id='cep'
                          value={
                            values.internationalAddress === false
                              ? cepMask(values.cep)
                              : values.cep
                          }
                          type='cep'
                        />

                        <AutoCompleteAddress 
                          onChange={handleSelectAddresTypeChange}
                          values={values}
                        />

                        {errors.cep && touched.cep && (
                          <span className='input-error'>{errors.cep}</span>
                        )}
                        <ControlLabel>Endereço *</ControlLabel>
                        <StyledInput
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          name='street'
                          id='street'
                          value={values.street}
                          type='street'
                        />

                        {errors.street && touched.street && (
                          <span className='input-error'>{errors.street}</span>
                        )}
                        <ControlLabel>Nº *</ControlLabel>
                        <StyledInput
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          name='number'
                          id='number'
                          value={values.number}
                          type='text'
                          maxLength={7}
                        />

                        {errors.number && touched.number && (
                          <span className='input-error'>{errors.number}</span>
                        )}
                        <ControlLabel>Complemento</ControlLabel>
                        <StyledInput
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          name='complement'
                          id='complement'
                          value={values.complement}
                          type='text'
                        />

                        {errors.complement && touched.complement && (
                          <span className='input-error'>
                            {errors.complement}
                          </span>
                        )}
                        <ControlLabel>Bairro *</ControlLabel>
                        <StyledInput
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          name='neighborhood'
                          id='neighborhood'
                          value={values.neighborhood}
                          type='neighborhood'
                        />

                        {errors.neighborhood && touched.neighborhood && (
                          <span className='input-error'>
                            {errors.neighborhood}
                          </span>
                        )}
                        <ControlLabel>Cidade *</ControlLabel>
                        <StyledInput
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          name='city'
                          id='city'
                          value={values.city}
                          type='text'
                        />

                        {errors.city && touched.city && (
                          <span className='input-error'>{errors.city}</span>
                        )}

                        {values.internationalAddress === false ? (
                          <FormGroup>
                            <ControlLabel>Estado (UF) *</ControlLabel>
                            <SelectPicker
                              id='state'
                              name='state'
                              size='lg'
                              locale={{ searchPlaceholder: 'Pesquisar' }}
                              placeholder='Escolha o estado'
                              onChange={(value, event) =>
                                handleSelectStateChange(value)
                              }
                              onBlur={handleBlur}
                              data={stateOptions}
                              value={values.state}
                              style={{
                                width: '100%',
                                marginTop: '5px',
                                maxWidth: '466px ',
                              }}
                              placement='topStart'
                              maxHeight={200}
                            />
                            {errors.state && touched.state && (
                              <span className='input-error'>
                                {errors.state}
                              </span>
                            )}
                          </FormGroup>
                        ) : (
                          <FormGroup>
                            <ControlLabel>Estado (UF) *</ControlLabel>
                            <StyledInput
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='state'
                              id='state'
                              value={values.state}
                              type='text'
                            />
                            {errors.state && touched.state && (
                              <span className='input-error'>
                                {errors.state}
                              </span>
                            )}
                          </FormGroup>
                        )}

                        <FormGroup>
                          <ControlLabel>País de Origem *</ControlLabel>
                          <SelectPicker
                            id='country'
                            name='country'
                            size='lg'
                            placeholder='Escolha seu país...'
                            locale={{ searchPlaceholder: 'Pesquisar' }}
                            onChange={(value, event) =>
                              handleSelectChange(value)
                            }
                            onBlur={handleBlur}
                            data={countries}
                            value={values.country}
                            style={{
                              width: '100%',
                              marginTop: '5px',
                              maxWidth: '466px ',
                            }}
                            placement='topStart'
                            maxHeight={200}
                          />
                          {errors.country && touched.country && (
                            <span className='input-error'>
                              {errors.country}
                            </span>
                          )}
                        </FormGroup>
                      </FormGroup>
                    </FormGrid>

                    {user.profile.document.length !== 14 ? (
                      <UploadFiles
                        id='files'
                        name='files'
                        className={documentClassName}
                        type={documentType}
                        labelText='Comprovante deve ter sido emitido nos últimos 90 dias.'
                        titleText='Comprovante de endereço/conta de concessionária'
                        required={isRelationship !== true}
                        files={filesToUpload}
                        onChange={(files) => {
                          setFilesToUpload([...files]);
                          setFieldValue('files', files);
                        }}
                        listOldFiles
                        setFilesList={setFilesList}
                      />
                    ) : (
                      <div />
                    )}

                    <FormGroup>
                      <StyledButton
                        type='submit'
                        disabled={
                          document.length !== 14
                            ? isRelationship === true
                              ? isSubmitting || !isValid || loading
                              : isSubmitting ||
                                !isValid ||
                                loading ||
                                !filesToUpload.length
                            : isSubmitting || !isValid || loading
                        }
                      >
                        {loading ? 'Carregando...' : 'Salvar'}
                      </StyledButton>

                      <FooterLabel>
                        * Campos obrigatórios para salvar formulário e seguir
                        com o cadastro.
                      </FooterLabel>
                    </FormGroup>
                  </DisplayGrid>
                </Form>
              );
            }}
          </Formik>
        </LayoutContent>
      </Container>
    </LoggedLayout>
  );
};

export default AddressPage;
