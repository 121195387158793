import styled from 'styled-components';
import { Checkbox, Radio } from 'rsuite';
import { Link as ReactLink } from 'react-router-dom';

export const Title = styled.h2`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 40px;
  color: #20315b;
  padding: 28px 0;

  @media (min-width: 768px) {
    font-size: 32px;
    padding: 0 28px 56px;
  }
`;

export const Section = styled.section`
  width: 100%;
  height: 100%;
`;

export const Bar = styled.div`
  padding: 16px 0;
  left: 0px;
  top: 0px;
  width: 100%;
  min-height: 72px;

  background: #ffffff;

  border: 1px solid #e0e6ed;
`;

export const Container = styled.div`
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

export const Content = styled.div`
  padding-bottom: 30px;
`;

export const Link = styled(ReactLink)`
  font-family: IBM Plex Sans;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #0fc7ff;
  text-decoration: none;
  transition: 0.5s all;
`;

export const Stepper = styled.ul``;

export const StepperItemNumber = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: transparent;
  border: 1px solid #9ba8b7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9ba8b7;
  position: relative;
  transition: 0.5s all;

  &::after {
    ${(props) =>
      props.quantityItems ? `content: "${props.quantityItems}";` : ``}
    width: 21px;
    height: 21px;

    font-family: IBM Plex Sans;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #f9fafc;
    background: #20315b;
    border: 1px solid #ffffff;
    border-radius: 50%;
    position: absolute;

    line-height: 1.7;

    right: -10px;
    bottom: -3px;
  }
`;

export const StepperColumn = styled.div`
  margin-left: 32px;
  margin-top: 3px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 80%;
  }
`;

export const StepperContent = styled.div`
  transition: 0.5s all;
  height: max-content;
  margin: 10px 0;
  position: relative;

  .rs-control-label {
    margin: 8px 0;
    display: inline-block;
  }
`;

export const StepperItem = styled.li`
  display: flex;
  margin-bottom: 24px;
  position: relative;

  ${(props) =>
    props.active &&
    `
    margin-bottom: 0;
    ${StepperItemNumber} {
      background: #0FC7FF;
      border-color: #0FC7FF;
      color: #fff;
      font-weight: 600;
    }
  `}

  ${StepperContent} {
    &::after {
      content: '';
      background: #0fc7ff;
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: -50px;
      z-index: 1;
    }
  }

  ${(props) =>
    props.checked &&
    `
    ${StepperItemNumber} {
      background: transparent;
      border: 1px solid #0FC7FF;
      color: #0FC7FF;
      cursor: pointer;
    }
  `}
`;

export const ConfirmButton = styled.span`
  color: #0fc7ff;
  font-family: IBM Plex Sans;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0;
  margin-top: 10px;
  display: inline-block;
  transition: 0.5s all;
  padding: 10px 15px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  background: rgb(15 199 255 / 5%);

  &:hover {
    background: rgb(15 199 255 / 15%);
  }

  ${(props) =>
    props.disabled &&
    `
    color: #a8a8a8;
    background: rgba(64,64,64, 0.05);
    cursor: initial;
    pointer-events: none;
  `}

  ${(props) =>
    props.error &&
    `
    color: #ff5f0f;
    background: rgba(255,15,15, 0.05);
    cursor: initial;
    pointer-events: none;
  `}
`;

export const StepperTitle = styled.span`
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #34404d;
`;

export const StepperDescription = styled(StepperTitle)`
  color: #9ba8b7;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: block;
`;

export const StyledLogo = styled.img`
  height: 70px;
`;

export const CheckItem = styled(Checkbox)`
  margin-top: 10px;
  color: #455566;
  font-size: 16px;
  background: #f9fafc;
  border-radius: 5px;
  margin-bottom: 20px;
  height: 50px;
  padding: 7px 16px 7px 3px;
  border: 1px solid #d3dce6;
`;

export const RadioItem = styled(Radio)`
  margin-top: 10px;
  color: #455566;
  font-size: 16px;
  background: transparent;
  border-radius: 5px;
  margin-bottom: 20px;
  height: 50px;
  padding: 7px 16px 7px 3px;
  border: 1px solid #d3dce6;
`;

export const Select = styled.select`
  width: 142px;
  height: 48px;
  border: 1px solid #d3dce6;
  border-radius: 5px;
  appearance: none;
  -webkit-appearance: none;
  font-size: 16px;
  color: #20315b;
  padding-left: 16px;

  &::after {
    content: '>';
  }
`;

export const SelectHolder = styled.div`
  width: 142px;
  margin-right: 16px;
  position: relative;

  &::after {
    content: '>';
    position: absolute;
    top: 10px;
    left: 120px;
    font-size: 20px;
    color: #9ba8b7;
    transform: rotate(90deg);
    pointer-events: none;
  }
`;

export const DataHolder = styled.div`
  display: flex;
`;

export const InfoList = styled.ul`
  padding-top: 18px;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    width: 1px;
    height: 100%;
    background: #eff2f7;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(23px);
    z-index: 0;
  }
`;

export const InfoItem = styled.li`
  color: #34404d;
  margin-bottom: 18px;
  margin-left: 14px;
  position: relative;
  z-index: 2;

  & svg,
  & span {
    vertical-align: middle;
  }

  & span {
    margin-left: 14px;
  }
`;

export const BoxTimer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 0 32px 0;

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;

    padding: 32px 0;
  }
`;
