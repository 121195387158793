import produce from 'immer';

const INITIAL_STATE = {
  profile: null,
  showUserbar: true,
  showMenu: true,
  skip: false,
  address: [],
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.profile = action.payload.user;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.profile = null;
        window.localStorage.clear();
        break;
      }

      case '@user/SKIP_INTRODUTION': {
        draft.skip = action.payload;
        break;
      }

      case '@user/TOGGLE_USERBAR': {
        draft.showUserbar = true;
        break;
      }

      case '@user/TOGGLE_MENU': {
        draft.showMenu = !state.showMenu;
        break;
      }

      case '@user/ADD_INTRODUTION': {
        draft.skip = false;
        break;
      }

      case '@user/UPDATE_ADDRESS': {
        draft.address = action.payload;
        break;
      }

      case '@user/SET_HUGGY_ID': {
        draft.profile.huggy_contact_id = action.payload;
        break;
      }

      default:
    }
  });
}
