export const transformNumberInMoney = ({ number = 0, currency = 'BRL' }) => {
  if (!number) {
    return;
  }
  const numberString = number.toString();
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency,
  }).format(numberString);
};
