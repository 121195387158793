import styled from 'styled-components';
import { Avatar } from 'rsuite';

export const StyledCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 1.72094px 2.53613px rgba(154, 154, 167, 0.0243888),
    0px 4.75819px 7.01207px rgba(154, 154, 167, 0.035),
    0px 11.4559px 16.8824px rgba(154, 154, 167, 0.0456112),
    0px 38px 56px rgba(154, 154, 167, 0.07);
  border-radius: 10px;
  padding: 24px;
  max-width: 100%;
  margin: 16px 0;
  cursor: pointer;
  border: 2px solid ${(props) => (props.isSelected ? `#0FC7FF` : 'transparent')};
`;

export const CardFooterItemWrapper = styled.div``;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding-bottom: 16px; */
`;

export const CardHeaderGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardUserImage = styled(Avatar)`
  width: 48px;
  height: 48px;
  vertical-align: middle;
`;

export const CardUserName = styled.span`
  padding-left: 16px;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #20315b;
  vertical-align: middle;
`;

export const CardContent = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 24px;

  .responsiveIconHolder {
    display: none;
  }

  @media (min-width: 768px) {
    .responsiveIconHolder {
      padding: 32px 0 16px 64px;
    }
  }
`;

export const CardFooter = styled.div`
  border-top: 1px solid #e0e6ed;
  padding-top: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.withoutDash &&
    `
    border: none;
  `}
`;

export const Arrow = styled.span`
  display: block;
  position relative;
  margin: -13px;

  &:after {
    content: '';
    display: inline-block;
    width: 14px;
    height: 3px;
    background: #0FC7FF;
    transform: rotate(45deg);
    position: absolute;
    top: -4px;
    border-radius: 4px;

  }
  &:before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 3px;
    background: #0FC7FF;
    transform: rotate(-45deg);
    position: absolute;
    top: 4px;
    border-radius: 4px; 
  }
`;

export const CardFooterItem = styled.div`
  & svg {
    width: 20px;
    height: 20px;
    stroke: #9ba8b7;
    vertical-align: middle;
  }
  &:last-child {
    margin-top: 10px;
  }
`;

export const CardFooterContent = styled.span`
  color: #9ba8b7;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-left: 10px;
  vertical-align: middle;
`;
