import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 550px;
  min-height: 120px;

  position: relative;
  line-height: 30px;
  text-align: center;

  background-color: rgba(15, 199, 255, 0.1);
  background-color: #fafafa;
  color: #09b2e6;

  border: 1px dashed #0fc7ff;
  border-radius: 5px;

  &.dragActive {
    background-color: rgba(15, 199, 255, 0.1);
    border: 1.5px dashed #0fc7ff;
    box-sizing: border-box;
    border-radius: 5px;
  }

  &.file-error {
    background-color: #ffebec;
    border: 1px dashed #f44336;
  }
`;

export const Input = styled.input`
  opacity: 0;

  /* IE 8 */
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

export const DraggableContainerContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 120px;
  font-size: 18px;
  flex-direction: row;
  align-items: center;
  padding: 20px;

  svg {
    vertical-align: middle;
    margin-right: 8px;
  }
`;
