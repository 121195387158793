import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  /* max-width: 700px; */
  background-color: rgba(15, 199, 255, 0.1);
  /* max-width: 550px; */
  background-color: #fafafa;
  border: 1px dashed #0fc7ff;
  border-radius: 5px;
  min-height: 120px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: #09b2e6;
  font-family: 'IBM Plex Sans';

  svg {
    vertical-align: middle;
    margin-right: 8px;
  }

  &.dragActive {
    background-color: rgba(15, 199, 255, 0.1);
    border: 1.5px dashed #0fc7ff;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .description {
    font-size: 18px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  &.file-error {
    background-color: #ffebec;
    border: 1px dashed #f44336;
  }
`;

export const FileBox = styled.div`
  background: rgba(15, 199, 255, 0.1);
  border-radius: 5px;
  height: 72px;
  display: flex;
  flex-direction: row;
  /* max-width: 550px; */
  padding: 25px;
  margin-top: 10px;

  .file {
    font-family: IBM Plex Sans;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    svg {
      color: #09b2e6;
      cursor: pointer;
      vertical-align: middle;
      margin-left: 10px;
    }
    .sizeBox {
      div {
        display: inline-block;
      }
    }
  }

  svg {
    vertical-align: middle;
    margin-right: 10px;
  }
`;

export const ErrorText = styled.p`
  color: #f44336;
`;
