import { format } from 'date-fns';
import { api } from '~/services/api';

export async function postPartner(values) {
  const data = {
    file: values.file,
    name: values.name,
    email: values.email,
    user_id: values.user.profile._id,
    lastName: values.lastName,
    dateBirth: format(values.dateBirth, 'YYYY-MM-DD'),
    cityBirth: values.cityBirth,
    stateBirth: values.stateBirth,
    countryBirth: values.countryBirth,
    documentNumber: values.documentNumber,
    document: values.document,
    role: values.role,
    quota: Number(values.quota),
    isPF: values.isPF,
    issuingAgency: values.issuingAgency,
    issuingState: values.issuingState,
    issuingExpeditionDate: values.issuingExpeditionDate,
    addressZipcode: values.addressZipcode,
    addressStreet: values.addressStreet,
    addressNumber: values.addressNumber,
    addressComplement: values.addressComplement,
    addressNeighborhood: values.addressNeighborhood,
    addressCity: values.addressCity,
    addressState: values.addressState,
    addressCountry: values.addressCountry,
    motherName: values.motherName,
    fatherName: 'nao identificado',
    companyName: values.companyName,
    signTogether: true,
    signProcuration: true,
    isPartner: true,
    identifiedFather: false,
    financeBuy: values.buy_financial,
    financeSell: values.sell_financial,
    mayImport: values.import,
    mayExport: values.export,
    isPPE: values.ppe,
    resideAbroad: values.reside_abroad,
  };

  if (data.file.length > 0) {
    const newFile = new FormData();

    data.file.map((item) => newFile.append('file', item));

    newFile.append('id', data.leadId);
    newFile.append('className', 'Outro Documento');
    newFile.append('type', 'Representante Legal');
    newFile.append('property', '');

    await api.post(`/fileManager`, newFile, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  await api.post(`/partners`, data);
}

export async function updatePartner(values) {
  const data = {
    _id: values._id,
    file: values.file,
    name: values.name,
    email: values.email,
    user_id: values.user.profile._id,
    lastName: values.lastName,
    dateBirth: values.dateBirth,
    cityBirth: values.cityBirth,
    stateBirth: values.stateBirth,
    countryBirth: values.countryBirth,
    documentNumber: values.documentNumber,
    document: values.document,
    role: values.role,
    quota: Number(values.quota),
    isPF: values.isPF,
    issuingAgency: values.issuingAgency,
    issuingState: values.issuingState,
    issuingExpeditionDate: values.issuingExpeditionDate,
    addressZipcode: values.addressZipcode,
    addressStreet: values.addressStreet,
    addressNumber: values.addressNumber,
    addressComplement: values.addressComplement,
    addressNeighborhood: values.addressNeighborhood,
    addressCity: values.addressCity,
    addressState: values.addressState,
    addressCountry: values.addressCountry,
    motherName: values.motherName,
    fatherName: 'nao identificado',
    companyName: values.companyName,
    signTogether: true,
    signProcuration: true,
    isPartner: true,
    identifiedFather: false,
    financeBuy: values.buy_financial,
    financeSell: values.sell_financial,
    mayImport: values.import,
    mayExport: values.export,
    isPPE: values.ppe,
    resideAbroad: values.reside_abroad,
  };

  if (data.file.length > 0) {
    const newFile = new FormData();

    data.file.map((item) => newFile.append('file', item));

    newFile.append('id', data.leadId);
    newFile.append('className', 'Outro Documento');
    newFile.append('type', 'Representante Legal');
    newFile.append('property', '');

    await api.post(`/fileManager`, newFile, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  await api.put(`/partners/${data._id}`, data);
}

export async function getPartners(userId) {
  return api.get(`/register/partners`);
}

export async function retrievePartners(userId, data) {
  await api.post(`/register/partners/retrieve-users`, data);
}
