import MaskedInput from 'react-text-mask';
import { InputGroup } from 'rsuite';
import React from 'react';
import { useSelector } from 'react-redux';
import { createNumberMask } from 'text-mask-addons';
import { CircleFlag as Flag } from 'react-circle-flags';
import {
  ErrorMessage,
  FlexibleBox,
  Select,
  SelectHolder,
  StyledMaskedInputCurrency,
} from '../styles';

const CurrencyBy = ({ data, onValueChange, maskedValue, errors }) => {
  const { selectedProduct } = useSelector((state) => state.exchange);
  const maskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 10, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
    // requireDecimal: true,
    // alwaysShowDecimal: true,
  };

  const currencyMask = createNumberMask({ ...maskOptions });

  return (
    <FlexibleBox>
      <SelectHolder id='currencyBy' className='currencyByGroup'>
        <Flag
          style={{ marginLeft: '8px' }}
          countryCode='br'
          height={25}
          className='brasil'
          title='Real'
        />
        <Select>
          {data?.map(({ label, value }) => (
            <option key={value} value={value}>
              {label === 'BRL' && 'Real'}
            </option>
          ))}
        </Select>
      </SelectHolder>
      <InputGroup>
        <InputGroup.Addon>R$</InputGroup.Addon>
        <MaskedInput
          mask={currencyMask}
          name='valueBy'
          id='valueBy'
          onChange={(event) => {
            const { value } = event.target;
            onValueChange(value);
          }}
          value={maskedValue !== '0,00' ? maskedValue : ''}
          placeholder='0,00'
          disabled={!selectedProduct.currencyToTax}
          render={(ref, props) => (
            <>
              <StyledMaskedInputCurrency
                type='text'
                className='rs-input'
                ref={ref}
                {...props}
              />
            </>
          )}
        />
      </InputGroup>
    </FlexibleBox>
  );
};

export default CurrencyBy;
