import styled from 'styled-components';

export const TaxList = styled.ul`
  padding-top: 18px;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    width: 1px;
    height: 100%;
    background: #eff2f7;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(23px);
    z-index: 0;
  }
`;

export const TaxInfo = styled.li`
  color: #34404d;
  margin-bottom: 18px;
  margin-left: 14px;
  position: relative;
  z-index: 2;

  & svg,
  & span {
    vertical-align: middle;
  }

  & span {
    margin-left: 14px;
  }
`;

export const InlineBox = styled.div`
  display: flex;
  flex-direction: row;
`;
