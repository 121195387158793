import React from 'react';
import { Col, Grid, Nav, Row } from 'rsuite';
import Loading from '~/components/Loading';
import LoggedLayout from '../../components/LoggedLayout';
import {
  CustomContent,
  CustomNav,
  ReactSVGCustomDiv,
  Spacer,
  Title,
} from './styles';
import TableOfExchangeTickets from './TableOfExchangeTickets';
import Shippings from './TableOfExchangeTickets/shippings';

export default class ExchangeTicketList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      eventKey: 'travelMoney',
    };
  }

  componentDidMount() {
    this.setState((state) => ({
      loading: state.auth,
    }));
  }

  onSelect = (eventKey) => this.setState({ eventKey });

  render() {
    const { eventKey, loading } = this.state;

    return (
      <LoggedLayout>
        <CustomContent>
          <Grid fluid>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col>
                <ReactSVGCustomDiv src='/icons/pedidos-azul.svg' />
              </Col>
              <Col>
                <Title>Meus Pedidos</Title>
              </Col>
            </Row>
            <Row>
              <Col>
                <p
                  style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#455566',
                  }}
                >
                  Tenha acesso a todas as suas solicitações e operações
                  realizadas na Advanced
                </p>
              </Col>
            </Row>
            <Spacer />
            <Row>
              <Col>
                <CustomNav
                  appearance='subtle'
                  justified
                  activeKey={this.state.eventKey}
                  onSelect={this.onSelect}
                >
                  <Nav.Item eventKey='travelMoney'>
                    Dinheiro para viagem
                  </Nav.Item>
                  <Nav.Item eventKey='shipping'>Suas remessas</Nav.Item>
                </CustomNav>
              </Col>
            </Row>
            <Spacer />
            <Row>
              <Col>
                {eventKey === 'travelMoney' && <TableOfExchangeTickets />}
                {eventKey === 'shipping' && <Shippings />}
              </Col>
            </Row>
          </Grid>
        </CustomContent>
        {loading && <Loading text='Aguarde...' />}
      </LoggedLayout>
    );
  }
}
