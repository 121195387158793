import styled from 'styled-components';
import { CardWrapper } from '~/pages/Home/CardContent/styles';

export const Container = styled.div`
  font-family: IBM Plex Sans;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

export const Wrapper = styled.div`
  margin: 30px 0 0 30px;
  margin-left: 0;
  width: fit-content;

  @media (max-width: 43rem) {
    margin: 0;
    margin-bottom: 30px;

    ${CardWrapper} {
      max-width: 100%;
    }
  }

  > div {
    > div {
      margin-top: 0;
      padding-top: 0;
    }

    button.MuiButtonBase-root {
      border-radius: 500px;
      color: #0fc7ff;
      border: 2px solid #0fc7ff;

      &:hover {
        background: #0fc7ff;
        color: #fff;
      }
    }
  }
`;

export const IncompleteMessageContainer = styled.div`
  position: absolute;
  height: 220px;
  border: 2px solid #455566;
  z-index: 999;
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 10%;
  right: 10%;
`;

export const RegisterLinkContainer = styled.div`
  a {
    display: flex;
    align-items: center;
    color: #09b2e6;
    font-weight: 700;
    font-size: 16px;
  }

  svg {
    margin-left: 12px;
  }
`;
