import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-left: 32px;
  margin-right: 120px;

  width: 735px;
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogoList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${LogoList} {
    margin-top: 24px;
  }
`;
