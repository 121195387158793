import styled from 'styled-components';
import { Table as RTable, Button, Input, Loader, Modal } from 'rsuite';

const { HeaderCell: RHeaderCell } = RTable;

export const HeaderCell = styled(RHeaderCell)`
  color: #455566;
`;

export const Table = styled(RTable)`
  .spanColor {
    color: #20315b;
  }

  .rs-table-cell {
    border-color: #ff0000 !important;
  }

  .rs-table-cell-content {
    color: #20315b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .rs-table-cell-header {
    .rs-table-cell-content {
      font-family: IBM Plex Sans;
      font-weight: normal;
      padding-top: 32px;
      font-size: 14px;
      line-height: 16px;
      color: #455566;
    }
  }

  .rs-table-row {
    border-bottom-color: #d3dce6;
  }
`;

export const Status = styled.span`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4.5px 16px;
  width: 194px;
  height: 24px;
  background: #fffae2;
  border-radius: 5px;

  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #ffb82e;
`;

export const BtnContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-right: 2.5rem;
  /* margin-top: 16px; */
  /* padding: 0 8px 0 0; */

  @media (max-width: 425px) {
    align-items: center;
    justify-content: center;

    margin-top: 16px;
  }
`;

export const BtnContentModalPrinc = styled.div`
  position: fixed;
  bottom: 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 570px;
  height: 10vh;
`;

export const BtnContentModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const Btn = styled(Button)`
  min-width: 111px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 10px;
  gap: 10px;
  border-radius: 500px;
  border: 1px solid #0fc7ff;
  color: #ffffff;
  background: linear-gradient(
    98.1deg,
    #48d3fe 28.81%,
    #0fc7ff 52.98%,
    #09b2e6 84.96%
  );
  box-shadow: 0px 5px 20px rgba(0, 159, 217, 0.2),
    0px 2px 4px rgba(196, 241, 255, 0.25),
    0px 3px 20px rgba(114, 222, 255, 0.25);
`;

export const BtnCancel = styled(Button)`
  width: 111px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 10px;
  gap: 10px;
  border-radius: 500px;
  border: 1px solid #0fc7ff;
  color: #0fc7ff;
  background: #ffffff;
`;

export const ModalCustom = styled(Modal)`
  @media (max-width: 425px) {
    width: 95vw;
  }
`;

export const ContentModal = styled.div`
  min-width: 356px;
  min-height: 315px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;

  @media (max-width: 425px) {
    width: 95vw;
  }
`;

export const ContentModalTerms = styled(ContentModal)`
  width: 530px;
  margin: 0 auto;
`;

export const ContentModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ContentModalContainer = styled.div`
  border: 1px solid #e5e9f2;
  border-radius: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 25px;
  padding-top: 10px;
`;

export const ContentModalTitle = styled.h2`
  color: #132552;
  font-size: 25px;
  line-height: 34px;
  font-weight: 700;
  margin: 32px 0;
`;

export const ContentModalText = styled.span``;

export const ContentModalHighlight = styled.div`
  background: #eff2f7;
  padding: 8px 16px;
  width: 100%;
`;

export const ContentModalHighlightContent = styled.span`
  color: #34404d;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  max-width: 369px;
  margin: 0 auto;
  display: block;
  text-align: center;
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
`;

export const TitleModal = styled.h3`
  padding-top: 20px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #20315b;
`;

export const SubTitleModal = styled.p`
  width: 338px;
  padding-top: 16px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
`;

export const SubTitleResultModal = styled.h5`
  margin-top: 8px;
  margin-bottom: 24px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #20315b;
`;

export const InputSign = styled(Input)`
  width: 373px;
  height: 64px;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  color: #34404d;
  margin-bottom: 24px;
`;

export const TitleLoader = styled.h4`
  margin-top: 32px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #20315b;
`;

export const LoaderSign = styled(Loader)`
  margin-bottom: 24px;

  .rs-loader-spin::after {
    border-width: 4px;
    border-style: solid;
    -webkit-animation: loaderSpin 0.6s infinite linear;
    animation: loaderSpin 0.6s infinite linear;
    border-color: #0fc7ff transparent transparent;
  }
`;

export const CheckboxHolder = styled.div`
  margin: 16px 0 32px;
`;

export const PaginationHolder = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;

  div {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      flex-direction: row;
    }
  }
`;

export const PaginationButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
`;

export const PaginationLabel = styled.span`
  font-size: 16px;
  padding: 0 8px;
  color: #09b2e6;
`;
