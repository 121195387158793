import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import zipcode from '~/services/zipcode';
import { api } from '~/services/api';
import { updateAddress, setHuggyId } from './actions';

async function getAddressByCep(zip) {
  return await zipcode
    .get(`${zip}/json`)
    .then(({ data: { logradouro, localidade, uf, complemento, bairro } }) => {
      const BRAZIL_CODE = '010cac00-4a4a-e911-a966-000d3ac1b38b';
      const values = [
        { id: 'state', value: uf },
        { id: 'city', value: localidade },
        { id: 'neighborhood', value: bairro },
        { id: 'street', value: logradouro },
        { id: 'complement', value: complemento },
        { id: 'country', value: BRAZIL_CODE },
      ];

      return values;
    });
}

export function* getAddress({ payload }) {
  try {
    const response = yield call(getAddressByCep, payload);
    yield put(updateAddress(response));
  } catch (err) {
    toast.error(
      'Não podemos preencher automaticamente seu CEP. Verifique se o CEP inserido está correto.'
    );
  }
}

export function* setNewHuggyId({ payload }) {
  try {
    const getToken = (state) => state.auth;
    const getUser = (state) => state.user;
    const token = yield select(getToken);
    const user = yield select(getUser);

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token.token}`;
      const response = yield call(api.put, `/register/users/huggy`, {
        huggyContactId: payload,
      });
      yield put(setHuggyId(response.data.huggyContactId));
    }
  } catch (err) {
    toast.error('Houve um problema para atualizar Huggy ID.');
  }
}

export default all([
  takeLatest('@user/GET_ADDRESS', getAddress),
  takeLatest('@user/SET_NEW_HUGGY_ID', setNewHuggyId),
]);
