import styled from 'styled-components';
import { Button } from 'rsuite';

export const StyledCard = styled.div`
  background: #fff;
  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 10px;
  width: 296px;
  min-height: 372px;
  display: flex;
  padding: 24px;
  margin: 8px 8px;
  flex-direction: column;

  button {
    font-weight: bold;
    width: 230px;
    min-width: 100%;
  }

  @media (max-width: 700px) {
    width: 100%;
    max-width: 100%;
    padding: 24px;
    min-height: 340px;
    display: flex;
    margin: 16px 0;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
      0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  }
`;

export const Items = styled.div`
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #20315b;

  hr {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const DisplayItems = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardNumber = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #9ba8b7;
`;

export const TitleCard = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #20315b;

  @media (max-width: 700px) {
  }
`;

export const BodyCard = styled.div`
  @media (max-width: 700px) {
    width: 100%;
  }

  a {
    text-decoration: none;
    color: #0fc7ff;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    &:hover {
      color: #fff;
    }
  }
`;

export const BtnExcluir = styled(Button)`
  color: #20315b;
  text-decoration: underline;
  margin-top: 9px;
`;
