/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { Close } from '@rsuite/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Wrapper } from './styles';
import { updateOperationType } from '~/store/modules/exchange/actions';

const SideModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const {
    selectedProduct: { productId, productType, currencyToValue },
    operationType,
  } = useSelector((state) => state.exchange);
  const { _id: userId } = useSelector((state) => state.user.profile);

  function getOperationTypeURL() {
    if (operationType === 'sendMoneyToTravel') {
      return `https://turismo.advancedcorretora.com.br?productId=${productId}&productType=${productType}&value=${currencyToValue}`;
    }

    if (operationType === 'sendMoneyToYourAccountAbroad') {
      return `https://cambio.advancedcorretora.com.br/commercial?clientId=${userId}`;
    }

    if (operationType === 'receiveMoneyFromAbroad') {
      return `https://cambio.advancedcorretora.com.br/receive?clientId=${userId}`;
    }
  }

  const iframeUrl = getOperationTypeURL();

  function onClose() {
    setOpen(false);
    dispatch(updateOperationType(''));
  }

  return (
    <Container open={open}>
      <Wrapper>
        <div>
          <button id='toggleButton' onClick={onClose}>
            <Close />
          </button>
        </div>
        <iframe src={iframeUrl} title='Simulador de Cambio' />
      </Wrapper>
    </Container>
  );
};

export default SideModal;
