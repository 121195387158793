import React from 'react';
import { Link } from 'react-router-dom';

import { ReactSVG } from 'react-svg';
import { CircleFlag as Flag } from 'react-circle-flags';

import { Row } from 'rsuite';

import { format, parseISO } from 'date-fns';

import { FiInfo } from 'react-icons/fi';
import * as S from './styles';
import { ProductFlags } from '~/utils/flags';

export default function Available({ cards, isLoading }) {
  return (
    <Row>
      <S.Table
        virtualized
        headerHeight={70}
        rowHeight={56}
        height={610}
        id='table'
        data={cards}
        loading={isLoading}
      >
        <S.Table.Column width={150} verticalAlign='middle'>
          <S.HeaderCell>Bandeira</S.HeaderCell>

          <S.Table.Cell>
            {(_rowData) => (
              <Link
                title='Recarregar'
                to={
                  _rowData?.cardNumber
                    ? `/my-cards/recharge/${_rowData?.cardNumber}`
                    : ''
                }
              >
                <S.ContractWrapper>
                  <S.ContractTitle>Cartão Visa</S.ContractTitle>
                  <ReactSVG src='/icons/my-cards.svg' />
                </S.ContractWrapper>
              </Link>
            )}
          </S.Table.Cell>
        </S.Table.Column>
        <S.Table.Column width={150} align='center' verticalAlign='middle'>
          <S.HeaderCell>
            Id do cartão{' '}
            <FiInfo
              size={18}
              color='#455566'
              title='Encontra-se no verso do cartão'
            />
          </S.HeaderCell>

          <S.Table.Cell dataKey='id'>
            {(_rowData) => (
              <Link
                title='Recarregar'
                to={
                  _rowData?.cardNumber
                    ? `/my-cards/recharge/${_rowData?.cardNumber}`
                    : ''
                }
              >
                {_rowData?.cardNumber
                  ? `***${_rowData.cardNumber?.slice(-3)}`
                  : 'Recarregar'}
              </Link>
            )}
          </S.Table.Cell>
        </S.Table.Column>
        <S.Table.Column width={150} verticalAlign='middle'>
          <S.HeaderCell>Moeda</S.HeaderCell>

          <S.Table.Cell dataKey='valor'>
            {(_rowData) => (
              <S.CurrencyWrapper>
                <S.FlagWrapper>
                  <ProductFlags
                    code={_rowData.currency
                      .replace('BP', '')
                      .substring(0, 2)
                      .toLowerCase()}
                  />
                </S.FlagWrapper>
              </S.CurrencyWrapper>
            )}
          </S.Table.Cell>
        </S.Table.Column>

        <S.Table.Column width={150} verticalAlign='middle'>
          <S.HeaderCell>Validade</S.HeaderCell>

          <S.Table.Cell dataKey='data'>
            {(_rowData) => _rowData.expiress}
          </S.Table.Cell>
        </S.Table.Column>

        <S.Table.Column width={150} verticalAlign='middle'>
          <S.HeaderCell>Status do cartão</S.HeaderCell>

          <S.Table.Cell>
            <S.Status>ATIVO</S.Status>
          </S.Table.Cell>
        </S.Table.Column>
      </S.Table>
    </Row>
  );
}
