import styled from 'styled-components';
import MessagePayment from '../MessagePayment';

export const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 32px;
  padding-top: 32px;
  padding-bottom: 32px;
  @media (min-width: 769px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
    padding-bottom: 32px;
  }
`;
export const Content = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;

  height: max-content;
  padding: 24px;
  margin-bottom: 16px;

  @media (min-width: 769px) {
    margin-bottom: initial;
    padding: 32px 48px;
  }

  ${MessagePayment} {
    max-width: 100%;
  }
`;

export const AddAddress = styled.div`
  background: #e7faff;
  border: 1px dashed #0fc7ff;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2d447f;
  padding: 30px 0;
  margin-bottom: 1rem;
  cursor: pointer;

  ${(props) =>
    props.completed &&
    `
      background: #DFFCE7;
      border-width: 0px;
      color: #34404D;
      padding: 24px;
      text-align: left;
      font-weight: 400;
   `}
`;
export const ChangeButton = styled.div`
  font-family: 'IBM Plex Sans';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0fc7ff;
  display: flex;
  align-items: center;
  cursor: pointer;

  & svg {
    margin-right: 9px;
  }
`;

export const ReceiverContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReceiverCheckboxWrapper = styled.div`
  display: flex;
  width: 300px;
  justify-content: space-between;
`;

export const LoaderCustom = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  z-index: 100;
`;

export const Loader = styled.div`
  border: 6px solid #eff2f7;
  border-radius: 50%;
  border-top-color: #09b2e6;
  height: 50px;
  width: 50px;
  animation: loaderSpin 0.6s infinite linear;
`;
