import styled from 'styled-components';

export const DocsList = styled.ul`
  list-style: outside;

  li {
    margin: 10px 0 10px 20px;
  }
`;

export const Title = styled.div`
  margin-bottom: 20px;
  color: #20315b;
  font-family: IBM Plex Sans;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
`;
