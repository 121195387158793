import React from 'react';
import { Button } from 'rsuite';
import styled, { withTheme } from 'styled-components';

const StyledButton = styled(Button)`
  display: block;
  border-radius: 500px;
  color: #fff;
  box-sizing: border-box;
  font-weight: 600;
  font-size: ${(props) =>
    (props.lg && `18px`) ||
    (props.md && `16px`) ||
    (props.sm && `15px`) ||
    (props.xs && `14px`)};
  line-height: 20px;
  min-width: ${(props) =>
    (props.lg && `385px`) ||
    (props.md && `245px`) ||
    (props.sm && `125px`) ||
    (props.xs && `80px`)};

  padding: ${(props) =>
    (props.lg && `14px 48px`) ||
    (props.md && `10px 40px`) ||
    (props.sm && `6px 24px`) ||
    (props.xs && `4px 16px`)};

  background: linear-gradient(
    128.69deg,
    #48d3fe 28.81%,
    #0fc7ff 52.98%,
    #09b2e6 84.96%
  );
  max-width: 100%;
  box-shadow: 0px 3px 20px rgba(114, 222, 255, 0.25),
    0px 2px 4px rgba(196, 241, 255, 0.25), 0px 5px 20px rgba(0, 159, 217, 0.2);

  transition: 0.5s all;
  background-size: 200%;

  &:hover {
    box-shadow: 0px 3px 20px rgba(114, 222, 255, 0.25),
      0px 2px 4px rgba(196, 241, 255, 0.25), 0px 5px 20px rgba(0, 159, 217, 0.2);
    color: #fff;
    font-weight: 600;
    font-size: ${(props) =>
      (props.lg && `16px`) ||
      (props.md && `15px`) ||
      (props.sm && `14px`) ||
      (props.xs && `13px`)};
    line-height: 20px;
  }

  ${(props) =>
    props.ghost &&
    `
    color: ${props.theme.palette.brand.lightblue.light};
    background: none;
    border: 2px solid ${props.theme.palette.brand.lightblue.light};
    box-shadow: none;

    &:hover {
      background: ${props.theme.palette.brand.lightblue.light};
      border: 2px solid ${props.theme.palette.brand.lightblue.light};
      color: #fff;
    }
  `}

  &:disabled {
    ${(props) => `
      background: ${props.theme.palette.greyscale.light.lighter};
      box-shadow: none;
      border: none;
      color: #fff;

      &:hover {
        background: ${props.theme.palette.greyscale.light.regular};
        border: 0;
        box-shadow: none;
        color: #fff;
      }

    `}
  }
`;

export default withTheme((props) => (
  <StyledButton {...props}>{props.children}</StyledButton>
));
