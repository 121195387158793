import styled from 'styled-components';
import { IdentificationIconImg } from '~/pages/MyData/Identification/styles';

export const Sidebar = styled.aside`
  @media screen and (max-width: 1174px) {
    display: none;
  }

  /* @media screen and (max-width: 768px) {
    display: none;
  } */

  background: linear-gradient(321.22deg, #20315b 17.08%, #2d447f 95.75%);
  position: relative;
  display: block;
  color: #f9fafc;
  width: 100%;
  padding-left: 100px;
  max-width: 450px;
  height: calc(100vh - 74px);
`;

export const SidebarContent = styled.div`
  padding: 44px;
`;

export const SidebarTitle = styled.h2`
  color: #f9fafc;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  margin-top: 15px;
`;

export const SidebarSubtitle = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #f9fafc;
  max-width: 235px;
  padding-top: 16px;
  display: block;
`;

export const SidebarLinks = styled.div`
  margin-top: 77px;
`;

export const SidebarLinksTitle = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #eff2f7;

  display: block;
`;

export const SidebarIcon = styled(IdentificationIconImg)`
  margin-bottom: 30px;
`;
