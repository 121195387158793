import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  background: #00000050;
  height: 100vh;
  width: 100vw;
  z-index: 900;

  margin-left: -130px;

  transition: transform 0.2s ease-in-out;
  transform: ${({ open }) => (open ? '' : 'translateX(150%)')};
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  max-width: 475px;

  background: #fff;

  padding: 1rem;

  div {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;

    overflow-x: none !important;
  }
`;
