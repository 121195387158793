import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { Form, FormGroup, ControlLabel, Input } from 'rsuite';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ArrowLeft } from 'react-feather';
import MaskedInput from 'react-text-mask';
import {
  Container,
  Content,
  All,
  Banner,
  Title,
  SubTitle,
  BackButton,
} from './styles';
import Logo from '~/assets/logo.svg';
import ImagemLateral from '~/assets/recover.svg';
import Button from '~/components/Button';
import { authAPI as api } from '~/services/api';
import { StyledMaskedInput } from '~/components/styles/form.js';

function RecoverPassword() {
  const history = useHistory();

  const cpfCnpjMask = (value) => {
    const escapedValue = value.replace(/\D/g, '');

    if (escapedValue.length <= 11)
      return [
        /[0-9]/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ];
    return [
      /[0-9]/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
  };

  const handleRecover = async (data) => {
    try {
      const escapedValue = data.document.replace(/\D/g, '');

      await api.post('/auth/forgot_pass', {
        document: escapedValue,
      });

      history.push('/home');
      toast.success('E-mail enviado com sucesso!');
    } catch (e) {
      const { message, statusCode } = e.response.data;

      if (message.includes('User Not Found') && statusCode === 400) {
        toast.error('Documento não encontrado, realize o cadastro!');
      } else {
        toast.error('Falha ao enviar seu documento!');
      }
    }
  };

  const RegisterSchema = Yup.object().shape({
    document: Yup.string('Digite um documento válido').required('Obrigatório'),
  });

  return (
    <Container>
      <All>
        <Formik
          initialValues={{
            document: '',
          }}
          validationSchema={RegisterSchema}
          onSubmit={(values, action) => {}}
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            isSubmitting,
          }) => {
            const rulesToDisableSubmit = () => {
              const document = values.document.length;
              let disable = true;

              if (isSubmitting) {
                disable = true;
              }

              if (document === 14) {
                disable = false;
              }

              if (document === 18) {
                disable = false;
              }

              return disable;
            };
            return (
              <Form onSubmit={handleSubmit}>
                <Content>
                  <BackButton>
                    <ArrowLeft />
                    <Link to='/login'>
                      <p id='BackButton'>Voltar</p>
                    </Link>
                  </BackButton>

                  <img src={Logo} alt='Logo' />
                  <Title>Recuperar senha</Title>
                  <SubTitle>
                    Para recuperar seu acesso, preencha o campo com seu
                    documento cadastrado em sua conta no painel de clientes.
                  </SubTitle>

                  <FormGroup>
                    <ControlLabel>Documento</ControlLabel>
                    <Field
                      name='document'
                      id='document'
                      value={values.document}
                      render={({ field }) => (
                        <MaskedInput
                          {...field}
                          mask={cpfCnpjMask}
                          guide={false}
                          placeholder='CPF ou CNPJ'
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          render={(ref, props) => (
                            <StyledMaskedInput
                              type='text'
                              className='rs-input'
                              ref={ref}
                              {...props}
                            />
                          )}
                        />
                      )}
                    />

                    {errors.document && touched.document && (
                      <span>{errors.document}</span>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Button
                      type='submit'
                      disabled={rulesToDisableSubmit()}
                      onClick={() => handleRecover(values)}
                    >
                      Recuperar acesso
                    </Button>
                  </FormGroup>
                </Content>
              </Form>
            );
          }}
        </Formik>
      </All>
      <Banner>
        <img src={ImagemLateral} alt='Imagem lateral' />
      </Banner>
    </Container>
  );
}

export default RecoverPassword;
