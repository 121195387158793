import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Form,
  FormGroup,
  ControlLabel,
  Input,
  Checkbox,
  InputGroup,
} from 'rsuite';
import * as Yup from 'yup';
import { ArrowRight, Eye, EyeOff } from 'react-feather';
import {
  Container,
  Content,
  All,
  Banner,
  Title,
  ButtonBase,
  FlexButton,
  Buttons,
  Password,
  Rodape,
  Fields,
} from './styles';
import Loading from '~/components/Loading';
import Logo from '~/assets/Advanced-Simbolo.svg';
import ImagemLateral from '~/assets/login.svg';
import Button from '~/components/Button';
import cnpjMask from '~/mask/cnpj';
import cpfMask from '~/mask/cpf';
import {
  signInRequest,
  setResetError,
  loadingCompleted,
} from '~/store/modules/auth/actions';
import ErrorModal from '~/components/ErrorModal';

function Login(props) {
  const dispatch = useDispatch();
  const { loading, error, errorData } = useSelector((state) => state.auth);

  const [showPass, setShowPass] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  dispatch(loadingCompleted());

  const [isPF, setIsPF] = useState(true);
  let isPRD = false;
  if (process.env.NODE_ENV === 'production') {
    isPRD = true;
  }

  const validateLengthDocument = (document) => {
    if (document) {
      if (isPF) {
        return document.length === 11 || document.length === 14;
      }
      return document.length === 18 || document.length === 14;
    }
  };

  const LoginSchema = Yup.object().shape({
    document: Yup.string()
      .required('Obrigatório')
      .test('len', 'Tamanho inválido', (val) => validateLengthDocument(val)),
    password: Yup.string().min(6, 'Senha muito curta.').required('Obrigatório'),
  });

  const setPF = () => setIsPF(true);

  const setPJ = () => setIsPF(false);

  useEffect(() => {
    window.amplitude.getInstance().logEvent('login access');
  }, []);

  useEffect(() => {
    setShowModal(error);
    setModalData(errorData);
    const params = new URLSearchParams(window.location.search);

    setIsPF(params.get('clientType') !== 'pj');
  }, [error]);

  function handleShowPass(e) {
    e.preventDefault();
    setShowPass(!showPass);
  }

  return (
    <Container id='loginForm'>
      <All>
        <Formik
          initialValues={{
            document: '',
            password: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={(values, action) => {
            try {
              dispatch(
                signInRequest(
                  values.document
                    .replaceAll('.', '')
                    .replace('-', '')
                    .replace('/', ''),
                  values.password
                )
              );
              action.setSubmitting(true);
            } catch (err) {
              action.setSubmitting(false);
              window.amplitude.getInstance().logEvent('login error');
            }
          }}
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            isValid,
            touched,
            handleSubmit,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Content>
                  <img src={Logo} alt='Logo' />
                  <Title>Faça seu login na área de clientes</Title>

                  <ButtonBase>
                    <FlexButton>
                      <Buttons id='PfButton' notToggle={!isPF} onClick={setPF}>
                        Pessoa Física
                      </Buttons>
                      <Buttons id='PjButton' notToggle={isPF} onClick={setPJ}>
                        Pessoa Jurídica
                      </Buttons>
                    </FlexButton>
                  </ButtonBase>

                  <Fields className='fieldsName'>
                    <FormGroup
                      id='documentGroup'
                      className='documentGroupClass'
                    >
                      <ControlLabel>
                        {isPF ? 'Seu CPF' : 'Seu CNPJ'}
                      </ControlLabel>
                      <Input
                        className='input'
                        onChange={(value, event) => handleChange(event)}
                        onInput={(value, event) => handleChange(value)}
                        onBlur={handleBlur}
                        name='document'
                        id='document'
                        maxLength={isPF ? 14 : 18}
                        value={
                          isPF
                            ? cpfMask(values.document)
                            : cnpjMask(values.document)
                        }
                        type='text'
                      />

                      {errors.document && touched.document && (
                        <span>{errors.document}</span>
                      )}
                    </FormGroup>

                    <FormGroup
                      id='passwordGroup'
                      className='passwordGroupClass'
                    >
                      <ControlLabel>Sua senha</ControlLabel>
                      <InputGroup
                        style={{ margin: 0, width: '100%', maxHeight: '50px' }}
                      >
                        <Input
                          className='input'
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          name='password'
                          id='password'
                          value={values.password}
                          type={showPass ? 'text' : 'password'}
                          style={{ margin: 0 }}
                        />
                        <InputGroup.Button
                          type='button'
                          onClick={handleShowPass}
                        >
                          {showPass ? <Eye /> : <EyeOff />}
                        </InputGroup.Button>
                      </InputGroup>

                      {errors.password && touched.password && (
                        <span>{errors.password}</span>
                      )}
                    </FormGroup>

                    <Password>
                      <Checkbox> Lembrar senha</Checkbox>
                      <a href='/recover-password' id='forgotPassword'>
                        Esqueci a senha
                      </a>
                    </Password>

                    <FormGroup>
                      {loading ? (
                        <Button disabled>Carregando</Button>
                      ) : (
                        <Button type='submit'>Entrar</Button>
                      )}
                    </FormGroup>

                    <Rodape>
                      <p> Ainda não possui um login?</p>
                      <Link
                        to={{
                          pathname: '/signup',
                          search: props.location.search,
                        }}
                        id='registerNow'
                      >
                        Cadastrar agora
                        <ArrowRight />
                      </Link>
                    </Rodape>
                  </Fields>
                </Content>
              </Form>
            );
          }}
        </Formik>
      </All>

      <ErrorModal
        showModal={showModal}
        {...modalData}
        onChange={() => {
          setShowModal(false);
          dispatch(setResetError());
        }}
      />

      <Banner>
        <img src={ImagemLateral} alt='Imagem lateral' />
      </Banner>

      {loading && <Loading text='Aguarde...' />}
    </Container>
  );
}

export default Login;
