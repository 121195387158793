import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, Row } from 'rsuite';
import * as Yup from 'yup';
import InfoBar from '~/components/InfoBar';
import CheckIcon from '~/assets/simple-check.svg';
import { Fields } from '~/components/styles/form';
import WizardHeader from '~/components/WizardHeader';
import cepMask from '~/mask/zipcode';
import { api } from '~/services/api';
import history from '~/services/history';
import zipcode from '~/services/zipcode';
import FormSelectField from '../../../../components/Form/form-select-field';
import FormTextField from '../../../../components/Form/form-text-field';
import LoggedLayout from '../../../../components/LoggedLayout';
import RouteLeavingGuard from '../../../../routes/route-leaving-guard';
import { saveThirdStep } from '../partner-service';
import {
  Container,
  DoneStep,
  FormContainer,
  Step,
  StyledButton,
  Title,
  CheckItem,
  FooterLabel,
} from './styles';
import AutoCompleteAddress from '~/components/AutoCompleteAddress';

const AddressStep = (props) => {
  const { state } = props.location;
  const formik = React.useRef();
  const [countries, setCountries] = useState([]);
  const { auth } = useSelector((state) => state);

  useEffect(() => {
    const actualData = [];
    api.defaults.headers.Authorization = `Bearer ${auth.token}`;
    api.get('/lists/countries').then(({ data }) => {
      data.map((item) => {
        actualData.push({ label: item.country, value: item.id });
      });

      setCountries(actualData.reverse());
    });
  }, []);

  const saveStep = async (data) => {
    try {
      await saveThirdStep(auth.token, data, state._id);
      toast.success('Dados salvos com sucesso');
    } catch (ex) {
      toast.error('Algo deu errado. Por favor, tente novamente.');
      throw ex;
    }
  };

  const handleNextForm = async (data) => {
    const newData = { ...state, ...data };
    await saveStep(newData);
    history.push('/update-data/partners/new/4', newData);
  };

  const handleBackForm = () => {
    if (state.isPF === true) {
      history.push('/update-data/partners/new/2', state);
    } else {
      history.push('/update-data/partners/new/1', state);
    }
  };

  return (
    <RouteLeavingGuard
      shouldBlockNavigation={() =>
        formik?.current?.dirty == true || formik?.current.touched === true
      }
      onSaveAndExit={() => saveStep(formik.current.values)}
      onCanSave={() => formik.current?.dirty && formik.current?.isValid}
    >
      <LoggedLayout>
        <Container>
          <WizardHeader showLogo={false}>
            <div style={{ marginRight: 20 }}>
              <DoneStep>
                <img src={CheckIcon} alt='Check' />
              </DoneStep>
              <span className='step-title'>Info. pessoais</span>
            </div>
            {state?.isPF && (
              <div style={{ marginRight: 20 }}>
                <DoneStep>
                  <img src={CheckIcon} alt='Check' />
                </DoneStep>
                <span>Documentação</span>
              </div>
            )}

            <div style={{ marginRight: 20 }}>
              <Step active>{state.isPF ? 3 : 2}</Step>
              <span>Endereço</span>
            </div>
            <div style={{ marginRight: 20 }}>
              <Step>{state.isPF ? 4 : 3}</Step>
              <span>Relação com a empresa</span>
            </div>
          </WizardHeader>
          <InfoBar
            title={`Adicionando novo ${
              state.isAttorney ? 'procurador' : 'sócio'
            }`}
          />
          <FormContainer>
            <Title>
              <span>Etapa {state.isPF ? 3 : 2}</span>
              <h3>Endereço</h3>
            </Title>
            <Formik
              innerRef={formik}
              validationSchema={Yup.object().shape({
                internationalAddress: Yup.boolean(),
                addressZipcode: Yup.string()
                  .required('Obrigatório')
                  .when(
                    'internationalAddress',
                    (internationalAddress, schema) => {
                      if (internationalAddress === false)
                        return schema.min(8, 'Tamanho mínimo de 8 números');
                    }
                  ),
                addressStreet: Yup.string().required('Obrigatório'),
                addressNumber: Yup.string().required('Obrigatório'),
                addressComplement: Yup.string().optional(),
                addressNeighborhood: Yup.string().required('Obrigatório'),
                addressState: Yup.string().required('Obrigatório'),
                addressCity: Yup.string().required('Obrigatório'),
                addressCountry: Yup.string().required('Obrigatório').nullable(),
                addressTypeId: Yup.string().required('Obrigatório'),
              })}
              validateOnBlur
              validateOnChange
              validateOnMount
              initialValues={{
                internationalAddress: state?.internationalAddress ?? false,
                addressZipcode: state?.addressZipcode ?? '',
                addressStreet: state?.addressStreet ?? '',
                addressNumber: state?.addressNumber ?? '',
                addressComplement: state?.addressComplement ?? '',
                addressNeighborhood: state?.addressNeighborhood ?? '',
                addressState: state?.addressState ?? '',
                addressCity: state?.addressCity ?? '',
                addressCountry:
                  state?.addressCountry ??
                  '010cac00-4a4a-e911-a966-000d3ac1b38b',
                addressTypeId:
                  state?.addressTypeId || 'f9d46faf-bb21-ee11-9966-002248de6f9b',
              }}
              onSubmit={async (values) => {
                await handleNextForm(values);
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                errors,
                touched,
                isSubmitting,
                isValid,
              }) => {
                const handleSelectChange = (value, event) => {
                  setFieldValue('addressCountry', value);
                };

                const handleSelectAddresTypeChange = (value, event) => {
                  setFieldValue('addressTypeId', value);
                };

                const handleZip = async (value) => {
                  setFieldValue('addressZipcode', value);

                  if (
                    value.length === 10 &&
                    values.internationalAddress === false
                  ) {
                    const newzipCode = value.replace('.', '').replace('-', '');

                    await zipcode
                      .get(`${newzipCode}/json`)
                      .then(
                        ({
                          data: {
                            logradouro,
                            localidade,
                            uf,
                            complemento,
                            bairro,
                          },
                        }) => {
                          const BRAZIL_CODE =
                            '010cac00-4a4a-e911-a966-000d3ac1b38b';
                          const addressValues = [
                            { id: 'addressState', value: uf },
                            { id: 'addressCity', value: localidade },
                            { id: 'addressNeighborhood', value: bairro },
                            { id: 'addressStreet', value: logradouro },
                            { id: 'addressNumber', value: '' },
                            { id: 'addressComplement', value: complemento },
                            { id: 'addressCountry', value: BRAZIL_CODE },
                          ];

                          addressValues.map(({ id, value }) => {
                            if (id === 'addressCountry') {
                              return handleSelectChange(value);
                            }
                            return setFieldValue(id, value);
                          });
                        }
                      );
                  }
                };

                return (
                  <Form>
                    <Fields className='fieldsName'>
                      <Row>
                        <Col xs={24} lg={24}>
                          <CheckItem
                            checked={values.internationalAddress}
                            onChange={() =>
                              setFieldValue(
                                'internationalAddress',
                                !values.internationalAddress
                              )
                            }
                            style={{ border: 'none' }}
                          >
                            Endereço internacional
                          </CheckItem>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} lg={24}>
                          <FormTextField
                            labelText='CEP *'
                            fieldName='addressZipcode'
                            error={errors.addressZipcode}
                            touched={touched.addressZipcode}
                            handleOnChange={(value, event) =>
                              handleZip(value, event)
                            }
                            handleOnBlur={handleBlur}
                            value={
                              values.internationalAddress === false
                                ? cepMask(values.addressZipcode)
                                : values.addressZipcode
                            }
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: '24px' }}>
                        <Col xs={24} lg={24}>
                          <AutoCompleteAddress
                            onChange={handleSelectAddresTypeChange}
                            values={values}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24} lg={18}>
                          <FormTextField
                            labelText='Endereço *'
                            fieldName='addressStreet'
                            error={errors.addressStreet}
                            touched={touched.addressStreet}
                            handleOnChange={(_, event) => handleChange(event)}
                            handleOnBlur={handleBlur}
                            value={values.addressStreet}
                          />
                        </Col>
                        <Col xs={24} lg={6}>
                          <FormTextField
                            labelText='Nº *'
                            fieldName='addressNumber'
                            error={errors.addressNumber}
                            touched={touched.addressNumber}
                            handleOnChange={(_, event) => handleChange(event)}
                            handleOnBlur={handleBlur}
                            value={values.addressNumber}
                            maxLength={7}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} lg={12}>
                          <FormTextField
                            labelText='Complemento'
                            fieldName='addressComplement'
                            error={errors.addressComplement}
                            touched={touched.addressComplement}
                            handleOnChange={(_, event) => handleChange(event)}
                            handleOnBlur={handleBlur}
                            value={values.addressComplement}
                          />
                        </Col>
                        <Col xs={24} lg={12}>
                          <FormTextField
                            labelText='Bairro *'
                            fieldName='addressNeighborhood'
                            error={errors.addressNeighborhood}
                            touched={touched.addressNeighborhood}
                            handleOnChange={(_, event) => handleChange(event)}
                            handleOnBlur={handleBlur}
                            value={values.addressNeighborhood}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} lg={16}>
                          <FormTextField
                            labelText='Cidade *'
                            fieldName='addressCity'
                            error={errors.addressCity}
                            touched={touched.addressCity}
                            handleOnChange={(_, event) => handleChange(event)}
                            handleOnBlur={handleBlur}
                            value={values.addressCity}
                          />
                        </Col>
                        <Col xs={24} lg={8}>
                          <FormTextField
                            labelText='Estado (UF) *'
                            fieldName='addressState'
                            error={errors.addressState}
                            touched={touched.addressState}
                            handleOnChange={(_, event) => handleChange(event)}
                            handleOnBlur={handleBlur}
                            value={values.addressState}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24}>
                          <FormSelectField
                            labelText='País *'
                            fieldName='addressCountry'
                            error={errors.addressCountry}
                            touched={touched.addressCountry}
                            handleOnChange={(val) => handleSelectChange(val)}
                            data={countries}
                            placeholder={
                              countries.length
                                ? 'Escolha seu país...'
                                : 'Carregando...'
                            }
                            value={values.addressCountry}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} lg={12}>
                          <StyledButton
                            ghost='true'
                            onClick={() => handleBackForm()}
                          >
                            Voltar
                          </StyledButton>
                        </Col>
                        <Col xs={24} lg={12}>
                          <StyledButton
                            type='submit'
                            disabled={isSubmitting || !isValid}
                          >
                            Próxima Etapa
                          </StyledButton>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} lg={24}>
                          <FooterLabel>
                            * Campos obrigatórios para salvar formulário e
                            seguir com o cadastro.
                          </FooterLabel>
                        </Col>
                      </Row>
                    </Fields>
                  </Form>
                );
              }}
            </Formik>
          </FormContainer>
        </Container>
      </LoggedLayout>
    </RouteLeavingGuard>
  );
};

export default AddressStep;
