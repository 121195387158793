import React, { useEffect, useState } from 'react';
import { ControlLabel, FormGroup, SelectPicker } from 'rsuite';
import { useSelector } from 'react-redux';
import { getPublicPlacesOptions } from '~/services/publicPlaces';

export default function AutoCompleteAddress({ onChange, values }) {
  const [addressItems, setAddressItems] = useState([]);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    (async () => {
      const response = await getPublicPlacesOptions(token);
      setAddressItems(response);
    })();
  }, []);

  return (
    <FormGroup>
      <ControlLabel>Logradouro *</ControlLabel>

      <SelectPicker
        id='addressTypeId'
        name='addressTypeId'
        size='lg'
        locale={{ searchPlaceholder: 'Pesquisar' }}
        placeholder='Escolha o logradouro'
        onChange={(value, event) => onChange(value, event)}
        data={addressItems}
        value={values.addressTypeId}
        style={{
          width: '100%',
          marginTop: '5px',
          maxWidth: '466px ',
        }}
        placement='topStart'
        maxHeight={200}
      />
    </FormGroup>
  );
}
