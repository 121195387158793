import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Whisper } from 'rsuite';
import { toggleUserbar } from '~/store/modules/user/actions';
import InfoCircleSvg from './icons/infoCircle.svg';
import { checkQuantityItemsFilled } from '~/services/calculate';
import {
  BodyTooltip,
  ContentStatus,
  DisplayStatus,
  StatusTitle,
} from './styles';

export const RegisterStatus = () => {
  const user = useSelector((state) => state.user);
  const { banks } = useSelector((state) => state.bankList);
  const { partners } = useSelector((state) => state.partnersList);
  const [percentagePartners, setPercentagePartners] = useState(0);
  // TODO alterar label e ícone Geral PJ

  const [percentageAllPF, setPercentageAllPF] = useState(0);
  const [percentageAllPJ, setPercentageAllPJ] = useState(0);

  const {
    addressCity, // PF e PJ
    addressCountry, // PF e PJ
    addressNeighborhood, // PF e PJ
    addressNumber, // PF e PJ
    addressState, // PF e PJ
    addressStreet, // PF e PJ
    addressZipcode, // PF e PJ
    civilState,
    gender,
    cityBirth,
    companyName,
    companyFantasyName,
    companyEconomicActivity,
    companyWhereWorkName,
    originCountry,
    countryBirth,
    dateBirth,
    document, // PF e PJ
    email, // PF e PJ
    identificationDocumentType,
    identificationDocument,
    issuingAgency,
    issuingExpeditionDate,
    issuingState,
    lastName, // PF e PJ
    motherName,
    name, // PF e PJ
    companyOpenedDate,
    phoneAreaCode, // PF e PJ
    phoneNumber, // PF e PJ
    stateBirth,
    optInLimit,
    isRelationship,
  } = user.profile;

  const calcPercentage = async () => {
    return await new Promise((resolve, reject) => {
      try {
        if (user.profile) {
          const PF = checkQuantityItemsFilled([
            !!document, // ok
            !!name, // ok
            !!lastName, // ok
            !!identificationDocumentType, // ok
            !!identificationDocument, // ok
            !!issuingAgency, // ok
            !!issuingState, // ok
            !!issuingExpeditionDate, // ok
            !!civilState, // ok
            !!gender, // ok
            !!cityBirth, // ok
            !!stateBirth, // ok
            !!motherName, // ok
            !!countryBirth, // ok
            !!dateBirth, // ok
            !!addressZipcode, // ok
            !!addressStreet, // ok
            !!addressNumber, // ok
            !!addressNeighborhood, // ok
            !!addressState, // ok
            !!addressCity, // ok
            !!addressCountry, // ok
          ]);

          const PJ = checkQuantityItemsFilled([
            !!companyName,
            !!companyFantasyName,
            !!email,
            !!phoneAreaCode,
            !!phoneNumber,
            !!document,
            !!companyOpenedDate,
            !!companyEconomicActivity,
            !!originCountry,
            !!addressZipcode,
            !!addressStreet,
            !!addressNumber,
            !!addressNeighborhood,
            !!addressState,
            !!addressCity,
            !!addressCountry,
          ]);

          setPercentageAllPF(PF);
          setPercentageAllPJ(PJ);
        }
        resolve();
      } catch (error) {
        reject();
      }
    });
  };

  useEffect(() => {
    async function valid() {
      if (user.profile) {
        await calcPercentage();
      }
    }
    valid();

    let percentageAccumulate = 0;

    partners.map((item) => {
      if (item.quota) {
        percentageAccumulate += item.quota;
      }
    });

    setPercentagePartners(percentageAccumulate);
  }, [
    user.profile,
    percentageAllPF,
    percentageAllPJ,
    partners,
    banks,
    percentagePartners,
    document,
    optInLimit,
    isRelationship,
  ]);

  // useEffect(() => {
  //   let percentageAccumulate = 0;

  //   partners.map((item) => {
  //     if (item.quota) {
  //       percentageAccumulate += item.quota;
  //     }
  //   });

  //   setPercentagePartners(percentageAccumulate);
  // }, [percentagePartners]);

  const tooltipIncomplet = (
    <BodyTooltip>
      <b>Cadastro Incompleto:</b> Os dados obrigatórios para solicitar operações
      de câmbio, ainda não foram preenchidos.
    </BodyTooltip>
  );
  const tooltipComplet = (
    <BodyTooltip>
      <b>Cadastro Concluído:</b> Todos os dados do cadastro foram preenchidos.
      Fale com um de nossos especialistas para solicitar serviços.
    </BodyTooltip>
  );
  const tooltipWait = (
    <BodyTooltip>
      <b>Em análise:</b> Seus dados estão sendo analisados pelo nosso time de
      especialistas. Em, no máximo, 2 dias úteis o seu cadastro estará completo
      e entraremos em contato para avisá-lo.
    </BodyTooltip>
  );

  const tooltipProcess = (
    <BodyTooltip>
      <b>Processando:</b> Nossos servidores estão processando seu cadastro, em
      alguns minutos tudo estará pronto!
    </BodyTooltip>
  );

  const statusPF = () => {
    if (percentageAllPF < 100 || banks.length <= 0) {
      return (
        <DisplayStatus incomplete>
          <p>STATUS:</p>
          <StatusTitle incomplete>Incompleto</StatusTitle>
          <Whisper
            placement='rightStart'
            trigger='hover'
            speaker={tooltipIncomplet}
          >
            <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
          </Whisper>
        </DisplayStatus>
      );
    }
    if (
      percentageAllPF === 100 &&
      banks.length > 0 &&
      user.profile.optInLimit &&
      user.profile.isRelationship === false
    ) {
      return (
        <DisplayStatus wait>
          <p>STATUS:</p>
          <StatusTitle wait>Em análise</StatusTitle>
          <Whisper placement='rightStart' trigger='hover' speaker={tooltipWait}>
            <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
          </Whisper>
        </DisplayStatus>
      );
    }

    if (
      percentageAllPF === 100 &&
      banks.length > 0 &&
      user.profile.optInLimit &&
      user.profile.isRelationship === true
    ) {
      return (
        <DisplayStatus complete>
          <p>STATUS:</p>
          <StatusTitle complete>Concluído</StatusTitle>
          <Whisper
            placement='rightStart'
            trigger='hover'
            speaker={tooltipComplet}
          >
            <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
          </Whisper>
        </DisplayStatus>
      );
    }
    //  return (
    //   <DisplayStatus complete>
    //     <p>STATUS:</p>
    //     <StatusTitle complete>Concluído</StatusTitle>
    //     <Whisper
    //       placement='rightStart'
    //       trigger='hover'
    //       speaker={tooltipComplet}
    //     >
    //       <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
    //     </Whisper>
    //   </DisplayStatus>
    // );

    // if (percentageAllPF < 100 && banks.length === 0) {
    //   return (
    //     <DisplayStatus incomplete>
    //       <p>STATUS:</p>
    //       <StatusTitle incomplete>Incompleto</StatusTitle>
    //       <Whisper
    //         placement='rightStart'
    //         trigger='hover'
    //         speaker={tooltipIncomplet}
    //       >
    //         <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
    //       </Whisper>
    //     </DisplayStatus>
    //   );
    // } else if (
    //   percentageAllPF === 100 &&
    //   banks.length > 0 &&
    //   user.profile.optInLimit === true &&
    //   user.profile.isRelationship === false
    // ) {
    //   return (
    //     <DisplayStatus process>
    //       <p>STATUS:</p>
    //       <StatusTitle process>Processando</StatusTitle>
    //       <Whisper
    //         placement='rightStart'
    //         trigger='hover'
    //         speaker={tooltipProcess}
    //       >
    //         <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
    //       </Whisper>
    //     </DisplayStatus>
    //   );
    // } else if (
    //   percentageAllPF === 100 &&
    //   banks.length > 0 &&
    //   user.profile.optInLimit === false &&
    //   user.profile.isRelationship === false
    // ) {
    //   return (
    //     <DisplayStatus wait>
    //       <p>STATUS:</p>
    //       <StatusTitle wait>Em análise</StatusTitle>
    //       <Whisper placement='rightStart' trigger='hover' speaker={tooltipWait}>
    //         <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
    //       </Whisper>
    //     </DisplayStatus>
    //   );
    // }
    // return (
    //   <DisplayStatus complete>
    //     <p>STATUS:</p>
    //     <StatusTitle complete>Concluído</StatusTitle>
    //     <Whisper
    //       placement='rightStart'
    //       trigger='hover'
    //       speaker={tooltipComplet}
    //     >
    //       <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
    //     </Whisper>
    //   </DisplayStatus>
    // );
  };

  const statusPJ = () => {
    if (
      percentageAllPJ < 100 ||
      banks?.length === 0 ||
      partners?.length === 0 ||
      percentagePartners <= 0 ||
      partners.some((item) => item.isPartner && !item.dynamicsId)
      // user.profile.isRelationship
    ) {
      return (
        <DisplayStatus incomplete>
          <p>STATUS:</p>
          <StatusTitle incomplete>Incompleto</StatusTitle>
          <Whisper
            placement='rightStart'
            trigger='hover'
            speaker={tooltipIncomplet}
          >
            <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
          </Whisper>
        </DisplayStatus>
      );
    }

    if (
      percentageAllPJ === 100 &&
      banks.length > 0 &&
      partners?.length > 0 &&
      !partners.some((item) => item.isPartner && !item.dynamicsId) &&
      percentagePartners === 100 &&
      user?.profile?.optInLimit &&
      user.profile.isRelationship === false
    ) {
      return (
        <DisplayStatus wait>
          <p>STATUS:</p>
          <StatusTitle wait>Em análise</StatusTitle>
          <Whisper placement='rightStart' trigger='hover' speaker={tooltipWait}>
            <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
          </Whisper>
        </DisplayStatus>
      );
    }

    return (
      <DisplayStatus complete>
        <p>STATUS:</p>
        <StatusTitle complete>Concluído</StatusTitle>
        <Whisper
          placement='rightStart'
          trigger='hover'
          speaker={tooltipComplet}
        >
          <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
        </Whisper>
      </DisplayStatus>
    );
  };

  // const statusPJ = () => {
  //   if (
  //     percentageAllPJ < 100 &&
  //     banks?.length === 0 &&
  //     partners?.length === 0 &&
  //     percentagePartners <= 0
  //   ) {
  //     return (
  //       <DisplayStatus incomplete>
  //         <p>STATUS:</p>
  //         <StatusTitle incomplete>Incompleto</StatusTitle>
  //         <Whisper
  //           placement='rightStart'
  //           trigger='hover'
  //           speaker={tooltipIncomplet}
  //         >
  //           <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
  //         </Whisper>
  //       </DisplayStatus>
  //     );
  //   }
  //   if (
  //     percentageAllPJ === 100 &&
  //     banks.length > 0 &&
  //     partners?.length > 0 &&
  //     !partners.some((item) => item.isPartner && !item.dynamicsId) &&
  //     percentagePartners === 100 &&
  //     user?.profile?.optInLimit === true &&
  //     user.profile.isRelationship === false
  //   ) {
  //     return (
  //       <DisplayStatus process>
  //         <p>STATUS:</p>
  //         <StatusTitle process>Processando</StatusTitle>
  //         <Whisper
  //           placement='rightStart'
  //           trigger='hover'
  //           speaker={tooltipProcess}
  //         >
  //           <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
  //         </Whisper>
  //       </DisplayStatus>
  //     );
  //   }
  //   if (
  //     percentageAllPJ === 100 &&
  //     banks?.length > 0 &&
  //     partners?.length > 0 &&
  //     !partners.some((item) => item.isPartner && !item.dynamicsId) &&
  //     percentagePartners === 100 &&
  //     user?.profile?.optInLimit === false &&
  //     user.profile.isRelationship === false
  //   ) {
  //     return (
  //       <DisplayStatus wait>
  //         <p>STATUS:</p>
  //         <StatusTitle wait>Em análise</StatusTitle>
  //         <Whisper placement='rightStart' trigger='hover' speaker={tooltipWait}>
  //           <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
  //         </Whisper>
  //       </DisplayStatus>
  //     );
  //   }

  //   if (
  //     partners.some((item) => item.isPartner && !item.dynamicsId) &&
  //     user.profile.isRelationship
  //   ) {
  //     return (
  //       <DisplayStatus incomplete>
  //         <p>STATUS:</p>
  //         <StatusTitle incomplete>Incompleto</StatusTitle>
  //         <Whisper
  //           placement='rightStart'
  //           trigger='hover'
  //           speaker={tooltipIncomplet}
  //         >
  //           <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
  //         </Whisper>
  //       </DisplayStatus>
  //     );
  //   }
  //   return (
  //     <DisplayStatus complete>
  //       <p>STATUS:</p>
  //       <StatusTitle complete>Concluído</StatusTitle>
  //       <Whisper
  //         placement='rightStart'
  //         trigger='hover'
  //         speaker={tooltipComplet}
  //       >
  //         <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
  //       </Whisper>
  //     </DisplayStatus>
  //   );
  // };

  return (
    <ContentStatus>
      {/* {document.length === 11 ? statusPF() : statusPJ()} */}
      {document.length === 11 ? (
        // Validação status PF
        percentageAllPF < 100 || banks.length === 0 ? (
          <DisplayStatus incomplete>
            <p>STATUS:</p>
            <StatusTitle incomplete>Incompleto</StatusTitle>
            <Whisper
              placement='rightStart'
              trigger='hover'
              speaker={tooltipIncomplet}
            >
              <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
            </Whisper>
          </DisplayStatus>
        ) : percentageAllPF === 100 &&
          banks.length > 0 &&
          // user.profile.optInLimit &&
          user.profile.isRelationship === false ? (
          <DisplayStatus wait>
            <p>STATUS:</p>
            <StatusTitle wait>Em análise</StatusTitle>
            <Whisper
              placement='rightStart'
              trigger='hover'
              speaker={tooltipWait}
            >
              <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
            </Whisper>
          </DisplayStatus>
        ) : (
          <DisplayStatus complete>
            <p>STATUS:</p>
            <StatusTitle complete>Concluído</StatusTitle>
            <Whisper
              placement='rightStart'
              trigger='hover'
              speaker={tooltipComplet}
            >
              <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
            </Whisper>
          </DisplayStatus>
        )
      ) : // Validação status PJ
      percentageAllPJ < 100 ||
        banks?.length === 0 ||
        partners?.length === 0 ||
        percentagePartners < 100 ||
        partners.some((item) => item.isPartner && !item.dynamicsId) ? (
        // user.profile.isRelationship
        <DisplayStatus incomplete>
          <p>STATUS:</p>
          <StatusTitle incomplete>Incompleto</StatusTitle>
          <Whisper
            placement='rightStart'
            trigger='hover'
            speaker={tooltipIncomplet}
          >
            <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
          </Whisper>
        </DisplayStatus>
      ) : percentageAllPJ === 100 &&
        banks.length > 0 &&
        partners?.length > 0 &&
        !partners.some((item) => item.isPartner && !item.dynamicsId) &&
        percentagePartners === 100 &&
        // user?.profile?.optInLimit &&
        user.profile.isRelationship === false ? (
        <DisplayStatus wait>
          <p>STATUS:</p>
          <StatusTitle wait>Em análise</StatusTitle>
          <Whisper placement='rightStart' trigger='hover' speaker={tooltipWait}>
            <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
          </Whisper>
        </DisplayStatus>
      ) : (
        <DisplayStatus complete>
          <p>STATUS:</p>
          <StatusTitle complete>Concluído</StatusTitle>
          <Whisper
            placement='rightStart'
            trigger='hover'
            speaker={tooltipComplet}
          >
            <img src={InfoCircleSvg} style={{ marginRight: '16px' }} />
          </Whisper>
        </DisplayStatus>
      )}
    </ContentStatus>
  );
};

export default RegisterStatus;
