import React from 'react';
import { Modal } from 'rsuite';
import { CloseOutlined } from '@mui/icons-material';

import * as S from './styles';

export default function LGPDModal({ show, onHide }) {
  return (
    <S.SModal
      show={show}
      onHide={onHide}
      size='md'
      style={{ padding: '16px 0 0 0' }}
    >
      <Modal.Header>
        <S.ModalClose onClick={onHide}>
          <CloseOutlined color='#0fc7ff' />
        </S.ModalClose>
      </Modal.Header>

      <Modal.Body>
        <h2>Autorizações legais e LGPD</h2>

        <S.Description style={{ marginBottom: 16 }}>
          Responsabilizo-me pela exatidão das informações prestadas à vista dos
          documentos anexados, e outros comprobatórios dos demais elementos de
          informações apresentadas, sob pena de aplicação do disposto no artigo
          64 da Lei 8.383, de 30/12/91.
        </S.Description>

        <S.Contrast>
          <S.Description style={{ marginBottom: 16 }}>
            Caso se faça necessária a apresentação de documentação adicional,
            através de exigência dos órgãos públicos fiscalizadores e
            reguladores, obrigo-me a apresentá-los dentro do prazo solicitado.
            Declaro também ter pleno conhecimento da{' '}
            <a
              href='https://advanced-apps-images.s3.amazonaws.com/documentos/Pol%C3%ADtica+de+Preven%C3%A7%C3%A3o+%C3%A0+Lavagem+de+Dinheiro+V3.pdf'
              target='_blank'
              referrerPolicy='no-referrer'
              rel='noreferrer'
            >
              “Política de Prevenção à Lavagem de Dinheiro e ao Financiamento ao
              Terrorismo”
            </a>{' '}
            bem como dos procedimentos relativos ao combate à lavagem de
            Dinheiro e financiamento ao terrorismo adotados pela Advanced
            Corretora de Câmbio LTDA.
          </S.Description>

          <S.Description style={{ marginBottom: 16 }}>
            Em conformidade com a Lei Federal nº 13.709/2018 (Lei Geral de
            Proteção de Dados Pessoais), Lei Federal nº 12.965/2014 (Marco Civil
            da Internet) e Lei Federal nº 8.078/1990 (Código de Defesa do
            Consumidor) comunicamos que seus dados serão armazenados para
            cumprimento do contrato de prestação de serviço de câmbio e serviços
            relacionados. Informamos também que os dados são compartilhados com
            terceiros (empresas de gestão e tecnologia que cuidam de nossos
            sistemas e bancos parceiros responsáveis pelos pagamentos e
            recebimentos internacionais). Seus dados poderão ficar armazenados
            por até 20 anos a contar da data da última operação realizada
            conosco devido a obrigações junto ao Banco Central e Receita Federal
            do Brasil. No caso de cadastro de menores de idade, estes deverão
            ser realizados pelo responsável legal. Para maiores informações de
            privacidade acesse nossa{' '}
            <a
              href='https://advancedcorretora.com.br/privacy'
              target='_blank'
              referrerPolicy='no-referrer'
              rel='noreferrer'
            >
              Política de Privacidade
            </a>
            .
          </S.Description>
        </S.Contrast>
      </Modal.Body>
    </S.SModal>
  );
}
