import styled from 'styled-components';
import { Modal } from 'rsuite';

// Modal de Bloqueio
export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  width: 100%;

  svg {
    margin: 0 0 0 auto;
    cursor: pointer;
  }
`;

export const DetailsModal = styled(Modal)`
  max-width: 800px;
  width: 100%;
  height: 100%;
  max-height: 90vh !important;
  overflow: auto;
  z-index: 50;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    color: #132552;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1rem;

    margin: 2rem 0 0;

    display: flex;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  div {
    input {
      margin-right: 0.5rem;
    }
  }

  img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* @media (min-width: 801px) {
    width: 560px;

    ${(props) =>
    props.size &&
    props.size === 'sm' &&
    `
      width: 503px;
    `}
  } */

  .error-message {
    color: #ff0000;
  }

  @media (min-width: 768px) {
    .rs-modal-content {
      padding: 2rem 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  @media (max-width: 768px) {
    .rs-modal-content {
      padding: 2rem 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
`;

export const TitleModalWarning = styled.h1`
  color: #132552;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.5rem;

  display: flex;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const CustomArticle = styled.article`
  font-size: 14px;
  line-height: 20px;
  margin: 0 auto 1rem;
  padding: 1rem;
  width: 100%;
  max-width: 900px;
  background: #fff;
  border-radius: 8px;

  /* max-height: 50vh;
  overflow-y: auto; */

  h2 {
    text-align: center;
    margin: 1.5rem 0;
    background: #fff;
  }

  ul {
    margin: 1.5rem 0;
    background: #fff;
  }

  p,
  li {
    margin: 1rem 0.5rem;
    list-style: lower-latin;
    background: #fff;
    font-size: 14px;
  }

  hr {
    margin: 5rem auto 1rem;
    border: 1px solid #222;
    width: 300px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
  }

  p,
  a,
  b,
  strong {
    background: #fff;
  }
`;
