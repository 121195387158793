import React from 'react';
import styled from 'styled-components';
import { Checkbox, FormGroup, Input, Radio, SelectPicker } from 'rsuite';
import IdentificationIcon from '../../assets/identificacao.svg';
import IdentificationMobileIcon from '../../assets/identificacao_mobile.svg';
import Button from '~/components/Button';
import {
  SquareButtonContainer,
  SquareRadioButton,
} from '~/pages/UpdatePersonalData/style';

export const IdentificationIconImg = () => (
  <img src={IdentificationIcon} alt='Ícone de identificação' />
);
export const IdentificationMobileIconImg = () => (
  <img src={IdentificationMobileIcon} />
);

export const Container = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: 769px) {
    height: calc(100vh - 75px);
  }

  @media screen and (max-width: 768px) and (min-width: 426px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
  }
`;

export const StyledMaskedInput = styled.input`
  margin: 5px 0 24px 0;
  height: 42px;
  width: 466px;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #d3dce6;
  padding-left: 10px;
`;

export const StyledMaskedInputCurrency = styled.input`
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #d3dce6;
  padding-left: 10px;
`;

export const StyledInput = styled(Input)`
  margin: 5px 0 24px 0;
  height: 42px;
  width: 466px;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #d3dce6;
  padding-left: 10px;
`;

export const StyledSelect = styled(SelectPicker)`
  margin: 5px 0 24px 0;
  height: 42px;
  width: 247px;
  max-width: 100%;

  span.rs-picker-toggle-clean {
    color: #1675e0;
  }

  span.rs-picker-toggle-caret::before {
    color: #1675e0;
  }
`;

export const DocsList = styled.ul`
  list-style: outside;

  li {
    margin: 10px 0 10px 20px;
  }
`;

export const StyledTitle = styled.h2`
  color: #20315b;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin: 20px 0;
`;

export const StyledButton = styled(Button)`
  width: 141px;
  height: 48px;
  margin-top: 35px;
  background: linear-gradient(
    98.1deg,
    #48d3fe 28.81%,
    #0fc7ff 52.98%,
    #09b2e6 84.96%
  );
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;

  :disabled {
    background: #d3dce6 !important;
  }
`;

export const All = styled.div`
  padding: 30px;
  // overflow-y: auto;
  // overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 0 30px 50px;
  }

  // @media (min-width: 768px) {
  //   width: calc(100vw - 520px);
  //   height: calc(100vh - 115px);
  // }

  /* .step {
    color: #09b2e6;
    font-size: 12px;
  } */

  /* .active {
    color: #455566;
    text-decoration: none;
    font-size: 12px;
  } */
`;

export const Fields = styled.div`
  font-family: IBM Plex Sans;
  font-size: 14px;
  line-height: 20px;
  color: #455566;
`;

export const DocumentFields = styled(Fields)`
  max-width: 320px;
  margin: 30px 0;

  @media (min-width: 768px) {
    margin: 90px 0;
  }

  @media screen and (max-width: 600px) {
    margin-top: 30px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }

  ${(props) => props.withoutPadding && ` padding: 0 !important; `}
`;

export const Title = styled.div`
  margin-bottom: 20px;
  color: #20315b;
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  text-align: left;

  @media (min-width: 768px) {
    text-align: initial;
  }

  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;
export const TitleFile = styled.div`
  margin-bottom: 24px;
  color: #20315b;
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: left;

  @media (min-width: 768px) {
    text-align: initial;
  }

  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;

export const FileUploadBox = styled.div`
  max-width: 400px;
  height: 225px;
  background: #ffffff;
  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 600px) {
    max-width: 300px;
    margin: 0 auto;
  }

  div {
    margin: 33px;
    border: 1.5px dashed #0fc7ff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h1 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      width: 205px;
      color: #09b2e6;
      text-align: center;
      margin-top: 15px;
    }
  }
  input {
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    position: absolute;
  }

  ${(props) =>
    props.small &&
    `
    height: 178px;

    div { 
      margin: 0;
      padding: 22px;
    }

  `}
`;

export const DisplayGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 35px;
  width: 100%;

  @media (min-width: 768px) {
    margin-left: 50px;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 90px;
  }
`;

export const FormGrid = styled.div`
  width: 100%;

  a {
    color: #09b2e6;
    font-size: 12px;
  }

  .active {
    color: #455566;
    text-decoration: none;
  }
`;

export const BackButton = styled.div`
  display: flex;
  color: #0fc7ff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  p {
    margin-left: 3px;
  }

  a {
    text-decoration: none;
    color: #0fc7ff;
  }
`;

export const ErrorBox = styled.div`
  width: 100%;
  max-width: 700px;
  height: 64px;
  background: #ffecec;
  color: #ef3131;
  border-radius: 5px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  font-family: IBM Plex Sans;
`;

export const SuccessBox = styled.div`
  width: 100%;
  max-width: 700px;
  height: 64px;
  background: #dcffe6;
  color: #14cc70;
  border-radius: 5px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  font-family: IBM Plex Sans;
`;

const StyledRadio = styled.input`
  display: none;

  &:checked ~ label {
    background: #009fd9;
    color: #fff;
    border-color: #009fd9;
    opacity: 1;
  }
`;

export const SquareButton = ({
  value,
  label,
  name,
  onClick,
  checked,
  style,
}) => {
  return (
    <SquareButtonContainer onClick={onClick}>
      <StyledRadio
        type='radio'
        id={value}
        checked={checked}
        name={name}
        value={value}
      />
      <SquareRadioButton style={style} htmlFor={value}>
        {label}
      </SquareRadioButton>
    </SquareButtonContainer>
  );
};

export const RadioItem = styled(Radio)`
  margin-top: 10px;
  color: #455566;
  font-size: 16px;
  background: #f9fafc;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 167px;
  height: 50px;
  padding: 7px 8px;
  border: 1px solid #d3dce6;
`;

export const CheckItem = styled(Checkbox)`
  margin-top: 10px;
  color: #455566;
  font-size: 16px;
  background: #f9fafc;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 250px;
  height: 50px;
  padding: 7px 8px;
  border: 1px solid #d3dce6;
`;
