import React from 'react';
import { useSelector } from 'react-redux';
import {
  StepperColumn,
  StepperContent,
  StepperItem,
  StepperItemNumber,
  StepperTitle,
} from '../../../../pages/Checkout/styles';
import check from '../../../../assets/check.svg';
import ExchangeMoneyCalculator from '../../../ExchangeMoneyCalculator';

const TravelMoneyCalculatorStep2 = ({ activeStep }) => {
  const { isLoading } = useSelector((state) => state.exchange);

  return (
    <StepperItem active={activeStep === 2}>
      <StepperItemNumber>
        {activeStep > 2 ? <img src={check} /> : '2'}
      </StepperItemNumber>
      <StepperColumn>
        <StepperTitle>Quanto você precisa para sua viagem?</StepperTitle>
        <StepperContent
          className={activeStep !== 2 || isLoading ? 'blurred' : ''}
        >
          <ExchangeMoneyCalculator />
        </StepperContent>
      </StepperColumn>
    </StepperItem>
  );
};

export default TravelMoneyCalculatorStep2;
