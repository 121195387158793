import styled from 'styled-components';

export const MobileHeader = styled.div`
  background: #20315b;
  width: 100%;
  /* background: #eff2f7; */
  padding: 25px 0;

  @media (min-width: 769px) {
    display: none;
  }

  .mobile-data {
    display: flex;
    flex-direction: column;
    padding: 0 32px;
  }

  .mobile-data__content {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .mobile-data__infos {
    width: 100%;
    margin-left: 20px;
  }

  .mobile-data__title {
    font-weight: 500;
    font-size: 30px;
    color: #f9fafc;
    /* color: #20315b; */

    @media (min-width: 769px) {
      font-size: 35px;
    }
  }

  .mobile-data__percent-title {
    font-weight: bold;
    font-size: 18px;
    color: #0fc7ff;
  }

  .mobile-data__percent-bar-total {
    .rs-progress-line {
      padding: 0;
    }
  }

  .mobile-data__percent-bar {
    width: 100%;
    height: 8px;
    background: #0fc7ff;
    border-radius: 50px;
    margin-left: 20px;
  }
`;
