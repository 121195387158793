import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, Tooltip, List, Loader } from 'rsuite';
import ButtonCustom from '~/components/Button';
import { CardWrapper } from './CardContent/styles';

export const Btn = styled(ButtonCustom)`
  @media (max-width: 800px) {
    margin-right: 16px;
  }

  @media (max-width: 375px) {
    margin: 0.5rem 0 0.5rem 0;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const BtnChat = styled(ButtonCustom)`
  @media (max-width: 375px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 1.5rem;
  }
`;

export const BtnContract = styled(ButtonCustom)`
  width: 263px;
  height: 40px;
  /* margin: 20px 24px 28px 24px; */

  @media (min-width: 768px) {
    /* margin: 24px 0 0 24px; */
    width: 226px;
    height: 40px;
  }
`;

export const Sidebar = styled.section`
  background: linear-gradient(321.22deg, #20315b 17.08%, #2d447f 95.75%);
  display: block;
  height: auto;
  color: #f9fafc;
  width: 100%;
  padding-left: 100px;
  max-width: 460px;
  overflow: auto;

  @media (max-width: 1439px) {
    display: none;
  }

  @media (min-width: 768px) {
    min-height: calc(100vh - 73px);
  }
`;

export const SidebarContent = styled.div`
  padding-top: 44px;
  padding-left: 44px;
`;

export const SidebarTitle = styled.h2`
  color: #fde186;
  font-weight: 500;
  font-size: 45px;
  line-height: 62px;
  margin-bottom: 70px;
`;

export const SidebarSubtitle = styled.span`
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  color: #f9fafc;
  padding-top: 16px;
  margin-bottom: 60px;
  display: block;
`;

export const SidebarLinks = styled.div`
  margin-top: 20px;
`;

export const SidebarLinksTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #eff2f7;
  margin-bottom: 20px;
  max-width: 200px;

  display: block;
`;

export const DisplayCards = styled.div`
  display: flex;
  /* justify-content: center; */
  /* overflow: auto; */
  width: 100%;
  padding-bottom: 90px;

  @media (max-width: 425px) {
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
  }

  @media screen and (min-width: 801px) and (max-width: 968px) {
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
  }

  @media screen and (min-width: 426px) and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
  }

  @media screen and (min-width: 801px) {
    padding-left: 130px;
  }

  /* @media screen and (min-width: 801px) and (max-width: 1100px) {
    padding-left: 130px;
  }

  @media screen and (min-width: 1101px) and (max-width: 1535px) {
    margin-left: 120px;
  }

  @media screen and (min-width: 1536px) {
    margin-left: 64px;
  } */
`;

export const GridCardsContainer = styled.div`
  display: flex;

  @media screen and (max-width: 1286px) and (min-width: 801px) {
    flex-direction: column;

    > ${CardWrapper} {
      max-height: 230px;
    }
  }
`;

export const GridCardsInitial = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  .last-card {
    grid-area: 2 / 2 / 3 / 3;
  }

  @media (min-width: ${(props) => props.width}) {
    grid-template-columns: repeat(2, 2fr);
  }

  /* @media (min-width: 1390px) {
    grid-template-columns: repeat(2, 2fr);
  } */

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
  }
`;

export const GridCards = styled.div`
  display: flex-start;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  .last-card {
    grid-area: 2 / 2 / 3 / 3;
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
  }
`;

export const CardSectionTitle = styled.h1`
  font-size: 24px !important;

  @media screen and (max-width: 1500px) {
    font-size: 20px !important;
  }
`;

export const ServicesContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  section {
    /* padding-bottom: 30px; */
    /* img {
      @media (max-width: 650px) {
        padding: 0 0 8px 24px;
      }
    } */
    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      color: #20315b;

      @media screen and (max-width: 1500px) {
        h1 {
          font-size: 20px;
        }
      }
    }
    p {
      padding: 15px 0;
      font-size: 16px;
      line-height: 24px;
      color: #34404d;
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 0;

    div {
      margin-right: 16px;
    }
  }

  @media (max-width: 650px) {
    padding: 0;
    flex-direction: column;
  }
`;

export const SignupContent = styled.div`
  padding: 0 0 0 8px;
  display: flex;
  justify-content: space-between;
  section {
    h1 {
      font-weight: medium;
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 28px;
    }
  }
`;

export const SupportContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  section {
    /* img {
      @media (max-width: 650px) {
        padding: 0 0 8px 24px;
      }
    } */
    h1 {
      font-weight: 500;
      font-size: 25px;
      line-height: 34px;
      color: #20315b;
      @media (max-width: 650px) {
        font-size: 20px;
        line-height: 28px;
      }
    }
    p {
      padding-top: 15px;
      font-size: 16px;
      line-height: 24px;
      color: #34404d;
      @media (max-width: 650px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  @media (width: 1024px) {
    /* margin-bottom: 40px; */
  }

  @media (max-width: 650px) {
    padding: 0px;
    flex-direction: column;
    /* margin-bottom: 80px; */
  }

  @media (min-width: 801px) {
    div {
      margin-right: 16px;
    }
  }
`;

export const SupportFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */
  width: 100%;
  height: 80px;
  border-radius: 0px 0px 10px 10px;
  /* padding: 0; */

  @media (width: 1024px) {
    /* justify-content: space-between; */
    padding: 6px 24px 12px 24px;
  }

  @media (max-width: 800px) {
    /* justify-content: space-between; */
    /* padding: 6px 24px 12px 24px; */
    padding: 0;
    margin: 0;
  }

  @media (min-width: 801px) {
    padding: 0 40px;
  }

  @media (max-width: 375px) {
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const SupportFooter2Columns = styled.div`
  display: flex;
  flex-direction: col;
  gap: 8px;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */
  width: 100%;
  height: 80px;
  border-radius: 0px 0px 10px 10px;
  /* padding: 0; */

  @media (width: 1024px) {
    /* justify-content: space-between; */
    padding: 6px 24px 12px 24px;
  }

  @media (max-width: 800px) {
    /* justify-content: space-between; */
    /* padding: 6px 24px 12px 24px; */
    padding: 0;
    margin: 0;
  }

  @media (min-width: 801px) {
    padding: 0 40px;
  }

  @media (max-width: 375px) {
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ImageContainer = styled.div`
  @media screen and (max-width: 1536px) {
    margin: auto;
    margin-right: 16px;
  }
`;

export const ResponsiveIcon = styled.img`
  max-height: 80px;
  @media screen and (max-width: 1536px) {
    display: none;
  }
`;

export const WebIcon = styled.img`
  @media screen and (min-width: 1537px) {
    display: none;
  }
`;

export const CustomLi = styled.li`
  padding: 0 0 8px 0;
`;

export const HeaderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const HeaderBlockTitle = styled.h3`
  color: #20315b;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
`;
export const HeaderBlockLink = styled(Link)`
  display: inline-block;
  color: #0fc7ff;

  & svg {
    vertical-align: middle;
  }
`;

export const ContentStatus = styled.div`
  width: 100%;
  min-height: 97px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  @media (width: 1024px) {
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    height: 100%;
    padding-top: 24px;
  }

  @media (max-width: 375px) {
    /* min-height: 72px; */
  }
`;

export const DisplayStatus = styled.div`
  width: 253px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${(props) =>
    (props.incomplete && '#ffecec') ||
    (props.wait && '#FFFAE2') ||
    (props.complete && '#DFFCE7') ||
    (props.process && '#CAEEFF')};
  border-radius: 50px;

  p {
    font-family: IBM Plex Sans;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #455566;
    margin: 0px 0 0 16px;
  }

  @media (max-width: 650px) {
    margin-bottom: 72px;
  }

  @media (max-width: 375px) {
    margin-bottom: 64px;
  }
`;

export const StatusTitle = styled.span`
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: ${(props) =>
    (props.incomplete && '#ef3131') ||
    (props.wait && '#FFB82E') ||
    (props.complete && '#14CC70') ||
    (props.process && '#20315b')};
`;

export const BodyTooltip = styled(Tooltip)`
  width: 346px;
  height: 96px;
  background-color: #009fd9;
  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 4px;

  font-family: IBM Plex Sans;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #ffffff;

  .rs-tooltip-arrow {
    border-right-color: #009fd9 !important;
  }

  .rs-tooltip-inner {
    max-width: 346px !important;
    padding: 16px 21px;
    color: #fff;
    text-align: start;
    background-color: #009fd9;
    border-radius: 4px;
    overflow-wrap: break-word;
  }
`;

export const CustomList = styled(List)`
  @media (width: 1024px) {
    margin-bottom: 40px;
  }

  @media (max-width: 968px) {
    display: none;
  }

  /* @media (max-width: 1286px) {
    display: none;
  } */
`;

export const ButtonList = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  width: 100%;
  max-width: 95%;
  margin: 0 auto;

  &:hover {
    background: #e7faff !important;
  }
`;

export const BodyList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  img {
    width: 48px;
    height: 48px;
  }
`;

export const ListInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const InfoText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoTitle = styled.span`
  text-align: left;
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #34404d;
`;

export const InfoStatusComplete = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #14cc70;
`;

export const InfoStatusIncomplete = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #ef3131;
`;

export const InfoStatusOptional = styled.p`
  font-family: IBM Plex Sans;
  font-style: italic;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #9ba8b7;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #e7faff;
  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 0px 0px 10px 10px;

  @media (max-width: 768px) {
    bottom: 0;
    width: 100%;
    min-width: 140px;
  }

  @media (max-width: 375px) {
    bottom: 0;
    width: 100%;
    max-width: 300px;
  }

  a {
    font-family: IBM Plex Sans;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #09b2e6;
    text-decoration: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 72px;
    width: 100%;
    line-height: 3.5;
  }

  img {
    margin-left: 8px;
  }
`;

export const BankLength = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: #0fc7ff;
  font-weight: bold;
  padding-left: 16px;
  display: flex;
`;

export const CustomLoader = styled(Loader)`
  height: 100%;
  z-index: 1000;

  .rs-loader-spin::before {
    border: 3px solid #0fc7ff;
    /* border: 3px solid rgba(247, 247, 250, 0.8); */
  }
`;
