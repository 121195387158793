import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1rem;
  }

  span {
    color: red;
  }

  .rs-checkbox-checker {
    > label {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .rs-checkbox-wrapper {
    left: 0;
    top: 12px;
  }

  .rs-checkbox-checker {
    padding-left: 24px;
  }
`;

export const PassRecomend = styled.div`
  color: #09b2e6;
  font-size: 0.8rem;
  margin-bottom: 7px;
`;

export const ButtonBase = styled.div`
  margin: 20px 0;
  background: #eff2f7;
  border-radius: 50px;
  height: 50px;
  border: none;
  width: 100%;

  @media (min-width: 1000px) {
    width: 384px;
    margin: 50px 0;
  }
`;

export const Buttons = styled.div`
  margin: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  max-width: 50%;
  height: 40px;
  color: #fff;
  background: ${(props) =>
    props.notToggle
      ? 'transparent'
      : 'linear-gradient(138.72deg, #48D3FE 28.81%, #0FC7FF 52.98%, #09B2E6 84.96%)'};
  color: ${(props) => (props.notToggle ? '#9BA8B7' : '#fff')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;

  @media (min-width: 1000px) {
    width: 340px;
    max-width: initial;
    line-height: 24px;
    font-size: 16px;
  }
`;

export const FlexButton = styled.div`
  display: flex;
`;

export const All = styled.div`
  margin-left: 10%;
  /* overflow: auto; */

  @media (max-width: 1000px) {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const Title = styled.div`
  color: #20315b;
  font-family: IBM Plex Sans;
  font-weight: 500;
  width: 100%;
  font-size: 24px;
  line-height: 40px;

  @media (max-width: 1000px) {
    display: inline-block;
    max-width: 100%;
  }

  @media (min-width: 1000px) {
    margin-bottom: 20px;
    width: 298px;
    font-size: 32px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 384px;

  @media (min-width: 1000px) {
    width: auto;
    max-width: 384px;
    margin-top: 60px;
  }

  img {
    width: 250px;
    margin-left: -20px;
    display: block;
  }

  .input {
    margin: 5px 0 5px 0;
    height: 50px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #d3dce6;
    padding-left: 10px;

    @media (min-width: 1000px) {
      width: 384px;
      max-width: 100%;
    }
  }

  button {
    margin-top: 20px;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    width: 100%;
    height: 48px;
    color: #fff;
    background: linear-gradient(
      138.72deg,
      #48d3fe 28.81%,
      #0fc7ff 52.98%,
      #09b2e6 84.96%
    );
    box-shadow: 0px 3px 20px rgba(114, 222, 255, 0.25),
      0px 2px 4px rgba(196, 241, 255, 0.25), 0px 5px 20px rgba(0, 159, 217, 0.2);
    border-radius: 30px;
    border: none;

    @media (min-width: 1000px) {
      position: absolute;
      width: 384px;
    }
  }
`;

export const ContentFormModal = styled.div`
  width: 100%;
  max-width: 384px;

  @media (min-width: 1000px) {
    width: auto;
    max-width: 384px;
    margin-top: 24px;
  }

  .input {
    margin: 5px 0 5px 0;
    height: 50px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #d3dce6;

    @media (min-width: 1000px) {
      width: 384px;
    }
  }

  button {
    margin-top: 20px;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    width: 100%;
    height: 48px;
    color: #fff;
    background: linear-gradient(
      138.72deg,
      #48d3fe 28.81%,
      #0fc7ff 52.98%,
      #09b2e6 84.96%
    );
    box-shadow: 0px 3px 20px rgba(114, 222, 255, 0.25),
      0px 2px 4px rgba(196, 241, 255, 0.25), 0px 5px 20px rgba(0, 159, 217, 0.2);
    border-radius: 30px;
    border: none;
  }
`;

export const Fields = styled.div`
  font-family: IBM Plex Sans;
  font-size: 14px;
  line-height: 20px;
  color: #455566;

  .number-phone {
    flex-direction: column;

    .rs-form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 0 !important;
    }

    .rs-form-group:nth-child(2) {
      flex: 1;

      input {
        width: 100% !important;
      }
    }

    .rs-form-group input {
      margin-right: 28px;
      /* margin: 0 0 0 12px; */
    }
  }

  @media (max-width: 1000px) {
    .rs-form-group {
      width: 100%;
    }

    .number-phone {
      .rs-form-group:nth-child(1) {
        width: 50px;
        margin-right: 16px;
      }
    }
  }
`;

export const Banner = styled.div`
  top: 0;
  right: 0;
  position: fixed;

  img {
    width: 100%;
    max-width: 50vw;
    height: 100vh;
    object-fit: cover;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Password = styled.div`
  margin: 20px -10px 0;
  display: flex;

  svg {
    cursor: pointer;
  }
`;

export const Rodape = styled.div`
  margin: 20px 0;
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1000px) {
    margin: 90px 0 100px;
  }

  a,
  h1 {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #009fd9;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const Flex = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const Terms = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  > span {
    color: rgb(69, 85, 102);
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 18px;
  }

  > div {
    margin-top: 0;
  }
`;

export const Info = styled.div`
  margin-top: 20px;
  background: #eff2f7;
  /* height: 72px; */
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px 24px;

  p {
    line-height: 20px;
    color: #34404d;
    /* width: 328px; */
    font-size: 14px;

    @media (max-width: 700px) {
      text-align: center;
    }
  }
`;

export const InfoEmail = styled.p`
  margin-top: 8px;
  line-height: 20px;
  color: #ff4d4d;
  /* color: rgb(69, 85, 102); */
  font-size: 14px;
`;

export const CustomModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalTitle = styled.div`
  color: #20315b;
  font-weight: 500;
  width: 377px;
  font-size: 32px;
  line-height: 40px;
`;

export const ModalBody = styled.div`
  font-size: 16px;
  width: 377px;
  line-height: 24px;
  color: #232b33;

  p {
    margin: 20px 0 20px;
  }

  button {
    margin: 20px 0 30px;
  }
`;

export const ModalBodyStatus = styled.div`
  font-size: 16px;
  width: 377px;
  line-height: 24px;
  color: #232b33;

  p {
    margin: 20px 0 20px;
  }

  button {
    margin: 38px 0 30px;
    height: 40px;
  }
`;

export const Icons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: flex-start;

  .attention {
    margin: 30px 80px 10px;
  }
`;

export const IconsStatus = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;

  .attention {
    margin: 30px 90px 24px;
  }
`;

export const BoxRecovery = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 408px;
  min-height: 168px;

  background: #eff2f7;
  border-radius: 8px;
`;

export const LinkContact = styled.a`
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #34404d;
`;
