import styled from 'styled-components';
import { Modal } from 'rsuite';

export const Title = styled.h2`
  color: #20315b;
  font-size: 32px;
`;

export const Text = styled.h1`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  color: #20315b;
`;

export const Spacing = styled.div`
  padding-top: 30px;
`;
export const PaginationHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DetailsModal = styled(Modal)`
  width: 100vw;
  z-index: 50;
  padding: 0px;

  @media (min-width: 800px) {
    width: 800px;

    ${(props) =>
      props.size &&
      props.size === 'sm' &&
      `
      width: 503px;
    `}
  }

  .error-message {
    color: #ff0000;
  }

  @media (min-width: 768px) {
    .rs-modal-content {
      padding: 32px 48px;
    }
  }
`;

export const DetailsPDF = styled.p`
  width: 83px;
  height: 24px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0fc7ff;
  margin-left: 5px;
`;

export const DetailsRange = styled.progress.attrs({ type: 'value' })`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  margin-bottom: 24px;

  &::-webkit-progress-bar {
    border-radius: 50px;
    background-color: #e0e6ed;
  }

  &::-webkit-progress-value {
    border-radius: 50px;
    background-color: #14cc70;
  }
`;

export const DetailsTracking = styled.div`
  width: 701px;
  height: 206px;
  background: #f9fafc;
  border-radius: 4px;
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const DetailsTrackingContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
`;

export const DetailsTrackingContentItem = styled.p`
  flex: 50%;
`;

export const DetailsTrackingCode = styled.div`
  width: 230px;
  height: 32px;
  background: #eff2f7;
  border-radius: 4px;
  text-align: center;
  padding: 4px;
`;

export const DetailsCardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DetailsCardSmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
`;

export const DetailsCard = styled.div`
  width: 407px;
  height: 318px;
  margin-top: 24px;

  ${(props) =>
    props.width &&
    `
      width: ${props.width}px;
    `}

  ${(props) =>
    props.width &&
    `
    height: ${props.height}px;
    `}

  background: #ffffff;

  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 4px;
`;
