import styled, { css } from 'styled-components';

export const NavbarItemName = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #c0ccda !important;
  vertical-align: middle;
  transition: 0.5s all;

  padding-top: 8px;

  @media (min-width: 801px) {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(30px);
    min-width: 180px;
    padding: 0 0 0 16px;
  }
`;

export const Navbar = styled.nav`
  ${({ expand }) => css`
    background: #132552;
    border-radius: 30px 30px 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.5s all;

    max-height: 72px;
    min-width: 100vw;

    overflow-y: scroll;
    overflow-x: hidden;

    /* Works on Firefox */
    scrollbar-width: none;
    scrollbar-color: transparent;

    /* Works on Chrome, Edge, and Safari */
    ::-webkit-scrollbar {
      display: none;
    }

    ${expand &&
    css`
      max-height: 458px;

      @media (max-width: 425px) {
        overflow-y: hidden;
        overflow-x: hidden;
      }

      @media (max-width: 375px) {
        overflow-y: hidden;
        overflow-x: hidden;
        max-height: 440px;
      }
    `}
  `}

  @media (min-width: 801px) {
    top: 0;
    bottom: initial;
    max-width: 97px;
    min-height: 100vh;
    border-radius: 0px 30px 30px 0px;
  }

  .svg-logo {
    transform: translateX(-9px);
  }

  .svg-logo__text {
    opacity: 0;
    transition: 0.5s all;
  }

  &:hover {
    max-width: 240px;
    ${NavbarItemName} {
      opacity: 1;
      visibility: visible;
    }

    .svg-logo__text {
      opacity: 1;
    }
  }
`;

export const NavbarList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (min-width: 801px) {
    a {
      margin: 15px;
      padding: 15px;
    }
  }

  @media (min-width: 801px) {
    flex-direction: column;
  }
`;

export const NavbarListItem = styled.li`
  ${({ isActive }) => css`
    position: relative;
    cursor: pointer;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &:not(:last-child) {
      margin-bottom: 32px;
    }

    @media (max-width: 800px) {
      display: flex;
      flex-direction: row;

      & a {
        text-align: center;
      }
    }

    ${isActive &&
    css`
      svg,
      span {
        color: #0fc7ff !important;
      }
    `}
  `}

  ${(props) =>
    props.logo &&
    `
  
    @media (max-width: 767px) {
      display: none !important;
    }

    @media (min-width: 768px) {
      display: block;
      & > div {
        display: inline-block;
      }
    }
  `}
`;

export const NavbarContentGroup = styled.div`
  ${(props) =>
    props.highlight &&
    `
    display: none;

    @media (min-width: 768px) {
      border-top: 1px solid #2D447F;
      display: inline-block;
    }

    ${NavbarItemName} {
      @media (min-width: 768px) {
        transform: translateY(-50%) translateX(0);
      }
    }
  `}
`;

export const ExpandContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  position: relative;

  & > span {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #c0ccda;

    margin: 16px 0 48px;
  }

  hr {
    position: absolute;
    top: 53%;
    width: 100%;
    border: 1px solid #20315b;
    margin: 32px 0;
  }
`;

export const NavbarContentHolder = styled.div`
  padding: 12px 24px;

  @media (min-width: 801px) {
    padding: 32px 36px;
  }

  @media (max-width: 375px) {
    padding: 8px 16px;
  }
`;

export const NavbarOptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 64px !important;
  grid-gap: 8px;

  position: relative;

  @media (max-width: 375px) {
    grid-gap: 0px;
  }
`;

export const NavbarOptions = styled.div`
  ${({ isActive }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 64px;
    height: 64px;

    background: transparent;

    border: 1px solid #2d447f;
    box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
      0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
    border-radius: 10px;

    svg {
      color: #2e4378;
      width: 42px;
      height: 42px;

      stroke: currentColor;
    }

    ${isActive &&
    css`
      background: #0fc7ff;
      border: 2px solid #0fc7ff;

      svg {
        color: #132552;
      }
    `}

    @media (max-width: 375px) {
      width: 42px;
      height: 42px;

      svg {
        color: #2e4378;
        width: 24px;
        height: 24px;

        stroke: currentColor;
      }
    }
  `}
`;

export const NavbarOptionsContainer = styled.div`
  ${({ isActive }) => css`
    width: auto;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      text-decoration: none;

      span {
        font-size: 10px;
        text-align: center;
        line-height: 14px;
        color: #405da8;

        padding-top: 10px;

        ${isActive &&
        css`
          color: #0fc7ff;
        `}
      }
    }

    &:hover {
      a > div {
        background: #f9fafc;
        border: 2px solid #f9fafc;
      }

      svg {
        color: #132552;
      }

      span {
        color: #f9fafc;
      }
    }

    ${isActive &&
    css`
      ${NavbarOptions} {
        background: #0fc7ff;
        border: 2px solid #0fc7ff;

        svg {
          color: #132552;
        }
      }
    `}
  `}
`;

export const NavbarListMore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
