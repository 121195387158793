import React, { useEffect, useState } from 'react';
import { Modal, SelectPicker, Col, ControlLabel, FormGroup, Row } from 'rsuite';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { CustomButtom, CustomModal } from '~/components/ValidationModal/style';
import { CustomDescription, CustomTitle } from './styles';
import { Fields, StyledInput } from '~/components/styles/form';
import zipcode from '~/services/zipcode';
import cepMask from '~/mask/zipcode';
import { addDeliveryAddress } from '~/store/modules/exchange/actions';

const ModalAddress = ({ isModalVisible, setAddress, onClose }) => {
  const dispatch = useDispatch();
  const { user, auth, exchange } = useSelector((state) => state);

  const [isSelectedAddress, setIsSelectedAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(isModalVisible);
  }, [isModalVisible]);

  useEffect(() => {
    if (exchange.address && Object.values(exchange.address).length) {
      setShowModal(false);
    }
  }, [exchange.address]);

  const FieldsSchema = Yup.object().shape({
    addressZipcode: Yup.string()
      .min(8, 'CEP Inválido.')
      .test('min_length', 'CEP Inválido', (val) => {
        if (val) {
          const replacedValue = val.replace(/[\-\.]/g, '');

          if (replacedValue.length < 8) {
            return false;
          }
        }

        return true;
      })
      .test(
        'range',
        'As entregas de dinheiro em espécie estão limitadas para CEPs de São Paulo e da região metropolitana.',
        (val) => {
          const value =
            val && Number(val.replaceAll('.', '').replaceAll('-', ''));
          if (!exchange.selectedProduct?.productType === 'ESPECIE') {
            return true;
          }
          if (value) {
            if (value >= 1000000 && value <= 9999999) {
              // Regra para validaçao de aceitar somente CEPs de SP
              return true;
            }
            return false;
          }
        }
      ),
    addressStreet: Yup.string().required('Obrigatório'),
    addressNumber: Yup.string().required('Obrigatório'),
    addressComplement: Yup.string(),
    addressNeighborhood: Yup.string().required('Obrigatório'),
    addressState: Yup.string().required('Obrigatório'),
    addressCity: Yup.string().required('Obrigatório'),
  });

  return (
    <CustomModal
      onHide={() => {
        setShowModal(false);
        !!onClose && onClose();
      }}
      size='sm'
      show={showModal}
    >
      <Modal.Header>
        <CustomTitle>Endereço de entrega</CustomTitle>
        {exchange?.deliveryAddressList?.length ? (
          <CustomDescription>
            Escolha um endereço já cadastrado ou adicione um novo.
          </CustomDescription>
        ) : (
          <CustomDescription>
            Cadastre um endereço de entrega.
          </CustomDescription>
        )}
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            addressZipcode: user.profile.address?.addressZipcode ?? '',
            addressStreet: user.profile.address?.addressStreet ?? '',
            addressNumber: user.profile.address?.addressNumber ?? '',
            addressComplement: user.profile.address?.addressComplement ?? '',
            addressNeighborhood:
              user.profile.address?.addressNeighborhood ?? '',
            addressState: user.profile.address?.addressState ?? '',
            addressCity: user.profile.address?.addressCity ?? '',
            addressId: '',
          }}
          onSubmit={(values) => {
            setAddress({
              addressZipcode: values.addressZipcode,
              addressStreet: values.addressStreet,
              addressNumber: values.addressNumber,
              addressComplement: values.addressComplement,
              addressNeighborhood: values.addressNeighborhood,
              addressState: values.addressState,
              addressCity: values.addressCity,
              addressId: values.addressId,
            });

            dispatch(
              addDeliveryAddress({
                zipcode: values.addressZipcode,
                street: values.addressStreet,
                number: values.addressNumber,
                neighborhood: values.addressNeighborhood,
                city: values.addressCity,
                state: values.addressState,
                complement: values.addressComplement,
                id: values.addressId,
              })
            );

            !!onClose && onClose();
          }}
          validationSchema={FieldsSchema}
          validateOnMount
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            touched,
            setFieldValue,
            isSubmitting,
            isValid,
            validateForm,
          }) => {
            const handleZip = async (zip, event) => {
              setIsSelectedAddress(false);
              setFieldValue('addressZipcode', zip);

              const replacedZipCode = zip.replace(/[-.]/g, '');

              if (
                !values.addressId ||
                (values.addressId &&
                  values.addressZipcode !== selectedAddress.zipcode)
              ) {
                if (replacedZipCode.length === 8) {
                  await zipcode
                    .get(`${replacedZipCode}/json`)
                    .then(
                      ({
                        data: {
                          logradouro,
                          localidade,
                          uf,
                          complemento,
                          bairro,
                        },
                      }) => {
                        const addressValues = [
                          { id: 'addressState', value: uf },
                          { id: 'addressCity', value: localidade },
                          { id: 'addressNeighborhood', value: bairro },
                          { id: 'addressStreet', value: logradouro },
                          { id: 'addressComplement', value: complemento },
                        ];

                        addressValues.map(({ id, value }) => {
                          return setFieldValue(id, value);
                        });
                      }
                    );
                }
              }
            };

            const selectAddress = async (data) => {
              if (Object.values(data).length) {
                setSelectedAddress(data);
                setIsSelectedAddress(true);

                const addressValues = [
                  { id: 'addressState', value: data.state },
                  { id: 'addressCity', value: data.city },
                  { id: 'addressNeighborhood', value: data.neighborhood },
                  { id: 'addressStreet', value: data.street },
                  { id: 'addressNumber', value: data.number },
                  { id: 'addressComplement', value: data.complement },
                  { id: 'addressZipcode', value: cepMask(data.zipcode) },
                  { id: 'addressId', value: data.address_id },
                ];

                addressValues.map(({ id, value }) => {
                  return setFieldValue(id, value, true);
                });

                let validate = false;

                setTimeout(async () => {
                  validate = await validateForm();
                }, 1250);

                return validate;
              }
            };

            return (
              <Form>
                {exchange?.deliveryAddressList?.length > 0 && (
                  <div style={{ marginBottom: 20 }}>
                    <SelectPicker
                      size='lg'
                      locale={{ searchPlaceholder: 'Pesquisar' }}
                      placeholder='Selecione seu endereço...'
                      onChange={(value) => {
                        if (Object.values(value).length) {
                          selectAddress(value);
                        }
                      }}
                      data={exchange?.deliveryAddressList.map((item) => ({
                        label: `${item.street} ${
                          item.number ? `${item.number} ` : ''
                        }${item.complement ? `(${item.complement}) ` : ''}- ${
                          item.city
                        }/${item.state} - CEP ${item.zipcode}`,
                        value: item,
                      }))}
                      style={{ width: '380px' }}
                    />
                  </div>
                )}

                <Fields className='fieldsName' style={{ maxWidth: '98%' }}>
                  <Row>
                    <Col xs={24} lg={24}>
                      <ControlLabel>CEP</ControlLabel>
                      <FormGroup controlId='addressZipcode'>
                        <StyledInput
                          name='addressZipcode'
                          id='addressZipcode'
                          type='text'
                          onChange={(value, event) => handleZip(value)}
                          onBlur={handleBlur}
                          value={cepMask(values.addressZipcode)}
                        />
                      </FormGroup>
                      {errors.addressZipcode &&
                        touched.addressZipcode &&
                        values.addressZipcode && (
                          <span className='error-message'>
                            {errors.addressZipcode}
                          </span>
                        )}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} lg={23}>
                      <ControlLabel>Rua</ControlLabel>
                      <FormGroup controlId='addressStreet'>
                        <StyledInput
                          name='addressStreet'
                          id='addressStreet'
                          type='text'
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          value={values.addressStreet}
                        />
                        {errors.addressStreet && touched.addressStreet && (
                          <span className='error-message'>
                            {errors.addressStreet}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} lg={6}>
                      <ControlLabel>Nº</ControlLabel>
                      <FormGroup controlId='addressNumber'>
                        <StyledInput
                          name='addressNumber'
                          id='addressNumber'
                          type='text'
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          value={values.addressNumber}
                        />
                        {errors.addressNumber && touched.addressNumber && (
                          <span className='error-message'>
                            {errors.addressNumber}
                          </span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col xs={24} lg={17}>
                      <ControlLabel>Complemento</ControlLabel>
                      <FormGroup controlId='addressComplement'>
                        <StyledInput
                          name='addressComplement'
                          id='addressComplement'
                          type='text'
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          value={values.addressComplement}
                        />
                        {errors.addressComplement &&
                          touched.addressComplement && (
                            <span className='error-message'>
                              {errors.addressComplement}
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} lg={23}>
                      <ControlLabel>Bairro</ControlLabel>
                      <FormGroup controlId='addressNeighborhood'>
                        <StyledInput
                          name='addressNeighborhood'
                          id='addressNeighborhood'
                          type='text'
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          value={values.addressNeighborhood}
                        />
                        {errors.addressNeighborhood &&
                          touched.addressNeighborhood && (
                            <span className='error-message'>
                              {errors.addressNeighborhood}
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} lg={17}>
                      <ControlLabel>Cidade</ControlLabel>
                      <FormGroup controlId='addressCity'>
                        <StyledInput
                          name='addressCity'
                          id='addressCity'
                          type='text'
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          value={values.addressCity}
                        />
                        {errors.addressCity && touched.addressCity && (
                          <span className='error-message'>
                            {errors.addressCity}
                          </span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col xs={24} lg={6}>
                      <ControlLabel>Estado (UF)</ControlLabel>
                      <FormGroup controlId='addressState'>
                        <StyledInput
                          name='addressState'
                          id='addressState'
                          type='text'
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          value={values.addressState}
                        />
                        {errors.addressState && touched.addressState && (
                          <span className='error-message'>
                            {errors.addressState}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Fields>

                <CustomButtom disabled={!isValid || isSubmitting} type='submit'>
                  {isSelectedAddress ? 'Confirmar' : 'Adicionar endereço'}
                </CustomButtom>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>

      <Modal.Footer />
    </CustomModal>
  );
};

export default ModalAddress;
