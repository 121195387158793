import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import HmacSHA256 from 'crypto-js/hmac-sha256';

const base64Replace = (text) => {
  let encoded = Base64.stringify(text);
  encoded = encoded.replace(/=+$/, '').replace(/\+/g, '-').replace(/\//g, '_');
  return encoded;
};

export const generateUserHash = (
  userIdentifier,
  huggyChatSecretKey = process.env.REACT_APP_HUGGY_CHAT_KEY
) => {
  const header = JSON.stringify({
    typ: 'JWT',
    alg: 'HS256',
  });
  const payload = JSON.stringify({
    jti: userIdentifier,
  });
  const base64Header = base64Replace(Utf8.parse(header));
  const base64Payload = base64Replace(Utf8.parse(payload));
  const part = `${base64Header}.${base64Payload}`;
  const base64Signature = base64Replace(HmacSHA256(part, huggyChatSecretKey));
  return `${part}.${base64Signature}`;
};
