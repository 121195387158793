import styled from 'styled-components';

export const StyledChoosePaymentType = styled.main`
  display: flex;

  padding-top: 32px;
  padding-bottom: 48px;
`;

export const Sidebar = styled.div`
  width: 100%;

  & > div {
    margin-bottom: 16px;
  }
`;

export const OperationsBox = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #c0ccda;
  border-radius: 10px;

  grid-column-start: 1;
  grid-column-end: 3;

  height: max-content;
  padding: 24px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-bottom: initial;
    padding: 32px 48px;
  }
`;

export const BoxTitle = styled.h3`
  color: #20315b;
  font-weight: 500;
  font-size: 29px;
  line-height: 32px;

  @media (min-width: 768px) {
    line-height: 40px;
    font-size: 32px;
  }
`;

export const OptionsTitle = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #34404d;
  padding: 32px 0;
  display: block;

  @media (min-width: 768px) {
    padding: 32px 0 16px;
  }
`;

export const Message = styled.div`
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  max-width: 293px;
`;

export const PaymentTypeList = styled.ul`
  @media (min-width: 768px) {
    padding-bottom: 32px;
  }
`;

export const ButtonList = styled.div`
  display: flex;

  & button {
    width: 100%;
  }

  & button:nth-child(2) {
    margin-left: 16px;
  }
`;

export const MessageList = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    & > div:first-child {
      margin-right: 24px;
    }
  }
`;

export const LogoList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
