import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Col, ControlLabel, FormGroup, Row } from 'rsuite';
import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import { CustomButtom, CustomModal } from '~/components/ValidationModal/style';
import { CustomTitle, CustomForm, Wrapper } from './styles';
import { Fields, StyledInput } from '~/components/styles/form';
import cpfMask from '~/mask/cpf';
import cellphoneMask from '~/mask/cellphone';
import cpfTest from '~/utils/cpfTest';

export default function ModalReceiver({
  isModalVisible,
  onClose,
  onHide,
  isEdit,
}) {
  const [showModal, setShowModal] = useState(false);
  const formik = useRef();
  const { receiver } = useSelector((state) => state.exchange);

  useEffect(() => {
    setShowModal(isModalVisible);
  }, [isModalVisible]);

  const ReceiverSchema = Yup.object().shape({
    fullName: Yup.string().required('Obrigatório'),
    email: Yup.string().email('Digite um email válido'),
    document: Yup.string()
      .required('Obrigatório')
      .length(14, 'Tamanho inválido.')
      .test('value', 'CPF inválido', function (value) {
        if (value?.length === 14) {
          return cpfTest(value);
        }

        return true;
      }),
    phone: Yup.string().length(11, 'Tamanho inválido'),
  });

  return (
    <CustomModal size='sm' onHide={() => onHide()} show={showModal}>
      <Modal.Header>
        <CustomTitle>Quem irá receber?</CustomTitle>
      </Modal.Header>

      <Wrapper>
        <Modal.Body>
          <Formik
            innerRef={formik}
            initialValues={{
              fullName: isEdit === true ? receiver?.name : '',
              document: isEdit === true ? receiver?.document : '',
              email: isEdit === true ? receiver?.email : '',
              phone: isEdit === true ? receiver?.phone : '',
            }}
            validationSchema={ReceiverSchema}
            onSubmit={(values) => {
              onClose(values);
            }}
          >
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              touched,
              setFieldValue,
              isValid,
            }) => {
              return (
                <CustomForm>
                  <Fields className='fieldsName' style={{ maxWidth: '98%' }}>
                    <Row>
                      <Col xs={24} lg={23}>
                        <FormGroup controlId='fullName'>
                          <ControlLabel>Nome completo*</ControlLabel>
                          <StyledInput
                            name='fullName'
                            id='fullName'
                            type='text'
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            value={values.fullName}
                          />

                          {errors.fullName && touched.fullName && (
                            <span>{errors.fullName}</span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24} lg={23}>
                        <ControlLabel>CPF*</ControlLabel>
                        <FormGroup controlId='document'>
                          <StyledInput
                            name='document'
                            id='document'
                            type='text'
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            value={cpfMask(values.document)}
                            maxLength={14}
                          />

                          {errors.document && touched.document && (
                            <span>{errors.document}</span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24} lg={23}>
                        <ControlLabel>E-mail</ControlLabel>
                        <FormGroup controlId='email'>
                          <StyledInput
                            name='email'
                            id='email'
                            type='text'
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            value={values.email}
                          />

                          {errors.email && touched.email && (
                            <span>{errors.email}</span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24} lg={23}>
                        <ControlLabel>Telefone</ControlLabel>
                        <FormGroup controlId='phone'>
                          <StyledInput
                            name='phone'
                            id='phone'
                            type='text'
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            value={cellphoneMask(values.phone)}
                          />

                          {errors.phone && touched.phone && (
                            <span>{errors.phone}</span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <FormGroup>
                        <CustomButtom
                          disabled={
                            !isValid || (!touched.document && !touched.fullName)
                          }
                          onClick={() => {
                            formik.current.submitForm();
                            setShowModal(false);
                          }}
                        >
                          Salvar alterações
                        </CustomButtom>
                      </FormGroup>
                    </Row>
                  </Fields>
                </CustomForm>
              );
            }}
          </Formik>
        </Modal.Body>
      </Wrapper>
    </CustomModal>
  );
}
