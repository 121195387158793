import produce from 'immer';

const INITIAL_STATE = {
  currentChat: {},
  chats: [],
};

export default function chat(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@chat/SET_CHAT': {
        draft.currentChat = action.payload;
        break;
      }

      case '@chat/SET_HISTORY_CHAT': {
        draft.chats = action.payload;
        break;
      }

      default:
    }
  });
}
