import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import bank from './bank/reducer';
import chat from './chat/reducer';
import exchange from './exchange/reducer';
import partner from './partner/reducer';
import bankList from './banklist/reducer';
import partnersList from './partnersList/reducer';
import showPJResiterModal from './showRegisterPJModal/reducer';

export default combineReducers({
  auth,
  user,
  exchange,
  bank,
  partner,
  bankList,
  partnersList,
  chat,
  showPJResiterModal,
});
