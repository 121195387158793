export const publicPlaceOptions = [
  {
    id: 'a4d825b7-cb2f-ee11-bdf4-002248365737',
    code: 'ESTRADA',
    type_name: 'ESTRADA',
  },
  {
    id: '14fff112-cd2f-ee11-bdf4-002248365737',
    code: 'VIA',
    type_name: 'VIA',
  },
  {
    id: '899e9e86-cd2f-ee11-bdf4-002248365737',
    code: 'PASSAGEM',
    type_name: 'PASSAGEM',
  },
  {
    id: 'f9d46faf-bb21-ee11-9966-002248de6f9b',
    code: 'RUA',
    type_name: 'RUA',
  },
  {
    id: '58f1b3bb-bb21-ee11-9966-002248de6f9b',
    code: 'AVENIDA',
    type_name: 'AVENIDA',
  },
  {
    id: 'bef8b282-ce2f-ee11-bdf4-002248365737',
    code: 'CONJUNTO',
    type_name: 'CONJUNTO',
  },
];

export function mapAddressToAutoComplete(addresses) {
  return addresses.map((address) => ({
    label: address.type_name,
    value: address.id,
  }));
}