import styled from 'styled-components';

export const IconHolder = styled.div`
  width: 56px;
  height: 56px;
  background: #e7faff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    width: 24px;
    height: 24px;
    background: #fff;
    border: 2px solid #d3dce6;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const StyledCard = styled.li`
  padding: 16px;
  border: 2px dashed #d3dce6;
  width: 100%;
  border-radius: 5px;

  display: inline-block;
  vertical-align: top;

  cursor: pointer;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin: 0 16px 16px 0;
    max-width: 195px;
  }

  ${({ selected }) =>
    selected &&
    `
    border: 2px solid #0FC7FF;

    ${IconHolder} {
      background: linear-gradient(98.1deg, #48D3FE 28.81%, #0FC7FF 52.98%, #09B2E6 84.96%);

      &::after {
        width: 24px;
        height: 24px;
        background: #0FC7FF;
        border: 2px solid #E7FAFF;
      }
    }
  `}
`;

export const Header = styled.div`
  margin-bottom: 16px;
  position: relative;
`;

export const Infos = styled.div``;

export const Title = styled.h4`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #20315b;
`;

export const Description = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #9ba8b7;
`;
