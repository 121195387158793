import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from '~/services/api';
import { savePartners } from './actions';

export function* getPartners({ payload }) {
  try {
    api.defaults.headers.Authorization = `Bearer ${payload.token}`;
    const response = yield call(api.get, `/register/partners`);

    yield put(savePartners(response.data));
  } catch (err) {
    toast.error('Houve um problema com o login, verifique suas credenciais.');
  }
}

export default all([takeLatest('@partnersList/GET_PARTNERS', getPartners)]);
