import React, { useEffect } from 'react';

function RedirectToOnboarding() {
  useEffect(() => {
    window.location.href = 'https://onboarding.advancedcorretora.com.br/';
  }, []);
  return <div />;
}

export default RedirectToOnboarding;
