import React from 'react';
import { ArrowLeft } from 'react-feather';
import { Link } from 'react-router-dom';
import { Progress } from 'rsuite';
import MobileHeader from '~/components/MobileHeader';
import { BackButton } from './styles';

export const MobileHeaderData = ({
  title,
  percentage = 0,
  backLink = '/update-data',
  icon,
  usePercentage = true,
  children,
}) => {
  const { Line } = Progress;

  return (
    <MobileHeader>
      <div className='mobile-data'>
        <BackButton>
          <ArrowLeft />
          <Link to={backLink}>
            <p>Voltar</p>
          </Link>
        </BackButton>
        <div className='mobile-data__content'>
          <img src={icon} alt='Ícone' />
          <div className='mobile-data__infos'>
            <h1 className='mobile-data__title'>{title}</h1>
            {usePercentage && (
              <div className='mobile-data__percent'>
                <div className='mobile-data__percent-bar-total'>
                  {title !== 'Fechamento' && (
                    <Line
                      percent={percentage > 0 ? percentage.toFixed(2) : 0}
                      showInfo
                      strokeColor='#0FC7FF'
                      style={{ color: '#0FC7FF', fontWeight: 'bold' }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </MobileHeader>
  );
};
