import React from 'react';
import { Switch } from 'react-router-dom';
import Banks from '~/pages/Banks';
import UpdateBank from '~/pages/Banks/UpdateBank';
import Chat from '~/pages/Chat';
import ConfirmData from '~/pages/ConfirmData';
import Checkout from '~/pages/Checkout';
import CheckoutSuccess from '~/pages/CheckoutSuccess';
import Contracts from '~/pages/Contracts/new';
import Home from '~/pages/Home';
import Login from '~/pages/Login';
import MyAccount from '~/pages/MyAccount';
import MyData from '~/pages/MyData';
import Identification from '~/pages/MyData/Identification';
import Partners from '~/pages/MyData/Partners';
import AddressStep from '~/pages/MyData/Partners/Steps/AddressStep';
import CompanyRelationStep from '~/pages/MyData/Partners/Steps/CompanyRelationStep';
import DocumentsDataStep from '~/pages/MyData/Partners/Steps/DocumentsDataStep';
import PersonalDataStep from '~/pages/MyData/Partners/Steps/PersonalDataStep';
import Profession from '~/pages/MyData/Profession';
import Recover from '~/pages/Password/RecoverPassword';
import Reset from '~/pages/Password/ResetPassword';
import CreatePassword from '~/pages/Password/CreatePassword';
import Register from '~/pages/Register';
import UpdateAddress from '~/pages/UpdateAddress';
import UpdateDataPF from '~/pages/UpdatePersonalData/pf';
import UpdateDataPJ from '~/pages/UpdatePersonalData/pj';
import WelcomePage from '~/pages/WelcomePage';
import ExchangeTicketList from '../pages/ExchangeTicketList';
import Route from './Route';
import ExchangeCardsList from '~/pages/ExchangeCardsList';
import ExchangeSolicitationRecharge from '~/pages/ExchangeSolicitationRecharge';
import SelectOption from '../pages/SelectOption';
import Requests from '~/pages/MyData/Requests';
import ClosingEstimate from '~/pages/ClosingEstimate';
import ExchangeCardsNew from '~/pages/ExchangeCardsNew';
import RedirectToOnboarding from '~/pages/RedirectToOnboarding';

let isPRD = false;
if (process.env.NODE_ENV === 'production') {
  isPRD = true;
}

const Routes = () => (
  <Switch>
    <Route exact path='/' component={RedirectToOnboarding} />
    <Route exact path='/login' component={Login} />
    <Route path='/welcome' component={WelcomePage} isPrivate />
    <Route path='/register' component={RedirectToOnboarding} />
    <Route path='/signup' component={Register} />
    <Route path='/account' component={MyAccount} isPrivate />
    <Route path='/confirm-data' component={ConfirmData} isPrivate />
    <Route path='/reset-password' component={Reset} />
    <Route path='/create-password' component={CreatePassword} />
    <Route path='/recover-password' component={Recover} />
    <Route path='/home' component={Home} isPrivate />
    <Route exact path='/checkout' component={Checkout} isPrivate />
    <Route path='/checkout/success' component={CheckoutSuccess} isPrivate />
    <Route path='/chat' component={Chat} isPrivate />
    <Route path='/exchange-tickets' component={ExchangeTicketList} isPrivate />
    <Route exact path='/update-data' component={MyData} isPrivate />
    <Route path='/update-data/pf' component={UpdateDataPF} isPrivate />
    <Route path='/update-data/pj' component={UpdateDataPJ} isPrivate />
    <Route path='/update-address' component={UpdateAddress} isPrivate />
    <Route exact path='/update-bank/new' component={UpdateBank} isPrivate />
    <Route path='/update-bank' component={Banks} isPrivate />
    <Route
      path='/update-data/identification'
      component={Identification}
      isPrivate
    />
    <Route path='/update-data/profession' component={Profession} isPrivate />
    <Route exact path='/update-data/partners' component={Partners} isPrivate />
    <Route
      exact
      path='/update-data/partners/new/1'
      component={PersonalDataStep}
      isPrivate
    />
    <Route
      exact
      path='/update-data/partners/new/2'
      component={DocumentsDataStep}
      isPrivate
    />
    <Route
      exact
      path='/update-data/partners/new/3'
      component={AddressStep}
      isPrivate
    />
    <Route
      exact
      path='/update-data/partners/new/4'
      component={CompanyRelationStep}
      isPrivate
    />
    <Route path='/update-data/finantial' component={Requests} isPrivate />
    <Route path='/closing-estimate' component={ClosingEstimate} isPrivate />
    <Route path='/contract' component={Contracts} isPrivate />
    <Route exact path='/my-cards' component={ExchangeCardsList} isPrivate />
    <Route
      path='/my-cards/:exchangeType/:cardNumber?'
      component={ExchangeCardsNew}
      isPrivate
    />
    <Route
      path='/recharge-card/:cardNumber'
      component={ExchangeSolicitationRecharge}
      isPrivate
    />
    <Route path='/select-option' component={SelectOption} isPrivate />
  </Switch>
);

export default Routes;
