import styled from 'styled-components';

export const List = styled.div`
  margin: 30px auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 30px 119px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    .list-tagged {
      display: flex;

      .list-header {
        width: 70%;
      }

      .list-title {
        font-size: 16px;
      }
    }
  }
`;

export const Title = styled.div`
  align-items: center;
  .icon {
    margin-right: 10px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 32px;
  }

  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: ${(props) => props.color};
`;

export const SubTitle = styled.div`
  margin-left: 43px;
  max-width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #34404d;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 450px;
  }
`;
