import styled from 'styled-components';

export const FlexibleBox = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.inline === true ? 'row' : 'column')};
  max-width: 400px;
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 100%;
`;

export const SelectHolder = styled.div`
  min-width: 190px;
  margin-right: 16px;
  border: 1px solid #d3dce6;
  border-radius: 5px;
  background: #ffffff;
  position: relative;
  display: flex;
  align-items: center;

  &::after {
    content: '>';
    position: absolute;
    top: 10px;
    left: 170px;
    font-size: 20px;
    color: #9ba8b7;
    transform: rotate(90deg);
    pointer-events: none;
  }

  @media screen and (max-width: 768px) and (min-width: 426px) {
    margin-right: 0px;
    margin-bottom: 8px;
    &::after {
      content: '>';
      position: relative;
      top: 0px;
      left: 0px;
    }
  }

  @media (max-width: 425px) {
    min-width: 200px;
    margin-right: 0px;
    margin-bottom: 8px;

    &::after {
      content: '>';
      position: relative;
      top: 0px;
      left: 0px;
      /* left: 270px; */
    }
  }
`;

export const Select = styled.select`
  width: 180px;
  height: 48px;
  border: none;
  border-radius: 5px;
  appearance: none;
  -webkit-appearance: none;
  font-size: 15px;
  color: #20315b;
  padding-left: 8px;
  background-color: transparent;

  &::after {
    content: '>';
  }

  @media screen and (max-width: 768px) and (min-width: 426px) {
    width: 80%;
  }

  @media (max-width: 425px) {
    width: 80%;
  }
`;

export const StyledMaskedInputCurrency = styled.input`
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #d3dce6;
  padding-left: 10px;

  height: 48px;
`;

export const ErrorMessage = styled.p`
  color: red;
  margin-left: 10px;
  margin-top: 10px;
`;

export const LoaderCustom = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 100;
`;

export const Loader = styled.div`
  border: 6px solid #eff2f7;
  border-radius: 50%;
  border-top-color: #09b2e6;
  height: 50px;
  width: 50px;
  animation: loaderSpin 0.6s infinite linear;
`;
