import React from 'react';

import { Progress } from 'rsuite';
import { HeaderHolder, LineHolder } from '../../pages/CheckoutPayment/styles';

const CheckoutStepper = ({ percent }) => {
  const { Line } = Progress;

  return (
    <HeaderHolder>
      <LineHolder>
        <Line
          percent={percent}
          showInfo
          strokeColor='#0FC7FF'
          style={{ color: '#20315B', fontWeight: 'bold' }}
        />
      </LineHolder>
    </HeaderHolder>
  );
};

export default CheckoutStepper;
