import React from 'react';

import { ReactSVG } from 'react-svg';
import { CircleFlag as Flag } from 'react-circle-flags';
import { FiInfo } from 'react-icons/fi';

import { format, parseISO } from 'date-fns';

import * as S from './styles';
import { ProductFlags } from '~/utils/flags';

export default function TCanceled({ cards, isLoading }) {
  return (
    <S.Table
      virtualized
      headerHeight={70}
      rowHeight={56}
      height={610}
      id='table'
      data={cards}
      loading={isLoading}
    >
      <S.Table.Column width={150} verticalAlign='middle'>
        <S.HeaderCell>Bandeira</S.HeaderCell>

        <S.Table.Cell>
          {(_rowData) => (
            <S.ContractWrapper>
              <S.ContractTitle>Cartão Visa</S.ContractTitle>
              <ReactSVG src='/icons/my-cards.svg' />
            </S.ContractWrapper>
          )}
        </S.Table.Cell>
      </S.Table.Column>
      <S.Table.Column width={160} align='center' verticalAlign='middle'>
        <S.HeaderCell>
          Id do cartão{' '}
          <FiInfo
            size={18}
            color='#455566'
            title='Encontra-se no verso do cartão'
          />
        </S.HeaderCell>

        <S.Table.Cell dataKey='id'>
          {(rowData) =>
            rowData?.cardNumber
              ? `***${rowData.cardNumber?.slice(-3)}`
              : 'Recarregar'
          }
        </S.Table.Cell>
      </S.Table.Column>
      <S.Table.Column width={150} verticalAlign='middle'>
        <S.HeaderCell>Moeda</S.HeaderCell>

        <S.Table.Cell dataKey='valor'>
          {(_rowData) => (
            <S.CurrencyWrapper>
              <S.FlagWrapper>
                <ProductFlags
                  code={_rowData.currency
                    .replace('BP', '')
                    .substring(0, 2)
                    .toLowerCase()}
                />
              </S.FlagWrapper>
            </S.CurrencyWrapper>
          )}
        </S.Table.Cell>
      </S.Table.Column>

      <S.Table.Column width={150} verticalAlign='middle'>
        <S.HeaderCell>Validade</S.HeaderCell>

        <S.Table.Cell dataKey='data'>
          {(_rowData) => _rowData.expiress}
        </S.Table.Cell>
      </S.Table.Column>

      <S.Table.Column width={150} verticalAlign='middle'>
        <S.HeaderCell>Status</S.HeaderCell>

        <S.Table.Cell>
          <S.Status>INVÁLIDO</S.Status>
        </S.Table.Cell>
      </S.Table.Column>
    </S.Table>
  );
}
