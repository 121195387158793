import styled from 'styled-components';
import { SidebarContent } from '../../components/Sidebar';

export const Sidebar = styled.section`
  background: linear-gradient(321.22deg, #20315b 17.08%, #2d447f 95.75%);
  position: relative;
  display: block;
  height: calc(100vh - 80px);
  color: #f9fafc;
  width: 100%;
  padding-left: 100px;
  max-width: 480px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const SidebarC = styled(SidebarContent)`
  padding-top: 48px;
  padding-left: 44px;
`;

export const SidebarTitle = styled.h2`
  color: #fde186;
  font-weight: 500;
  font-size: 50px;
  line-height: 62px;
`;

export const SidebarSubtitle = styled.span`
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  color: #f9fafc;
  padding-top: 16px;
  display: block;
`;

export const SidebarLinks = styled.div`
  margin-top: 77px;
`;

export const SidebarLinksTitle = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #eff2f7;
`;

export const DisplayCards = styled.div`
  display: flex;
  justify-content: center;
  overflow: auto;
  width: 100%;

  @media screen and (max-width: 768px) and (min-width: 426px) {
    margin-top: 80px;
  }

  @media (min-width: 769px) {
    height: calc(100vh - 75px);
    padding-right: 20px;
  }
`;

export const GridCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1500px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin: 20px auto;
    width: 100%;
    max-width: 80%;

    justify-content: center;
  }
`;

export const HeaderBar = styled.div`
  background: #eff2f7;
  padding: 20px 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  #label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .headerbar-title {
    font-weight: 500;
    font-size: 25px;
    line-height: 34px;
    color: #20315b;
  }

  img {
    margin-right: 8px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;
