import styled from 'styled-components';

export const Card = styled.div`
  background: ${(props) => (props.selected ? '#E7FAFF' : '#fff')};
  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;

  margin: 20px 0px 0 0px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid ${(props) => (props.selected ? '#0FC7FF' : '#fff')};

  height: 120px;
  width: 120px;
`;

export const ContainerWhisper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelText = styled.div`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #20315b;
  height: 60px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 12px;
    height: 40px;
  }
`;

export const SoonContainer = styled.div`
  padding: 4px 10px;
  gap: 10px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #132552;
  background: #f7d263;
  border-radius: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 12px;
    border-radius: 6px;
    padding: 2px 4px;
  }
`;

export const CardTop = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  align-content: stretch;
  width: 100%;
  height: 30px;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const ContentContainer = styled.div`
  svg {
    width: 40px;
    height: 40px;
  }
`;
