import styled from 'styled-components';

import { Button, Content } from 'rsuite';

export const ContentModal = styled.div`
  min-width: 557px;
  min-height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  /* @media (max-width: 425px) {
    width: 95vw;
  } */
`;

export const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  background: #ffffff;
`;

export const Title = styled.span`
  padding-top: 8px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #20315b;

  max-width: 244px;

  /* @media (max-width: 425px) {
    width: 88vw;
    font-size: 20px;
  } */
`;

export const Container = styled(Content)`
  display: flex;
  align-items: center;
  flex-direction: column;
  /* justify-content: center; */
  padding-top: 24px;
  /* height: 10vh; */
  border: 1px solid #c0ccda;
  border-radius: 12px;
  margin-bottom: 24px;
`;

export const ContractContent = styled.div`
  max-width: 270px;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
`;

export const ContractTitle = styled.h4`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  color: #132552;
  position: relative;

  &::before {
    content: '${(props) => props.index}';
    width: 32px;
    height: 32px;
    position: absolute;
    color: #fff;
    background: #132552;
    border-radius: 50%;
    text-align: center;
    font-size: 16px;
    left: -53px;
  }
`;

export const ContractDesc = styled.span`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #232b33;
  padding-top: 8px;
`;

export const BtnContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 24px;
`;

export const BtnCancel = styled(Button)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #09b2e6;
  padding-top: 24px;
`;

export const BackButton = styled.div`
  display: flex;
  color: #0fc7ff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  p {
    margin-left: 8px;
  }

  a {
    text-decoration: none;
    color: #0fc7ff;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`;
