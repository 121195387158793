import React from 'react';
import styled from 'styled-components';

const Alert = (props) => {
  const AlertContainer = styled.div`
    background-color: ${(props) =>
      (props.level === 'error' && `#FFECEC`) ||
      (props.level === 'success' && `#DFFCE7`)};
    border-radius: 10px;
    width: 456px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    padding: 15px 27px;

    span {
      font-weight: 500;
      font-size: 14px;
    }
  `;

  return (
    <AlertContainer level={props.level}>
      <span>{props.children}</span>
    </AlertContainer>
  );
};

export default Alert;
