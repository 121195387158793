import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '~/components/Button';
import { Content, Title } from '../../pages/Checkout/styles';
import { Section } from '../CheckoutConfirmation/styles';
import { ButtonList, Sidebar } from '../ChoosePaymentType/styles';
import ResumeOrder from '../ResumeOrder';
import { Wrapper } from './styles';
import StepperCalculator from '../SimulateMoneyForTravel/StepperCalculator';
import CheckoutStepper from '../CheckoutStepper';
import { isExchangeValueMinimalToAllowTransaction } from '~/utils/getUsdValueBase';

const CheckoutSimulation = ({
  changeStep,
  remakeSimulation,
  percent,
  isANewCard,
}) => {
  const [isValid, setIsValid] = useState(false);

  const { selectedProduct, isLoading } = useSelector((state) => state.exchange);
  const minUsdValueToValidation = 1000;
  const minUsdValueToValidationCard = 500;
  const maxUsdValueToValidation = 3000;

  useEffect(() => {
    validate();
  }, [selectedProduct]);

  const validate = () => {
    let finalValue;

    const {
      productId,
      productType,
      currencyFromValue,
      currencyToValue,
      currencyUsdBaseToTax,
      virtualStoreMinValue,
    } = selectedProduct;

    let valid = false;

    const maskedValue = Math.ceil(currencyToValue);
    const multiple = maskedValue % (virtualStoreMinValue || 5);

    const allowedTransaction = isExchangeValueMinimalToAllowTransaction(
      minUsdValueToValidation,
      maxUsdValueToValidation,
      currencyFromValue,
      currencyUsdBaseToTax
    );
    const baseValue = +(+currencyFromValue / +currencyUsdBaseToTax).toFixed(2);

    if (productType === 'ESPECIE' && allowedTransaction && multiple === 0) {
      valid = true;
    } else if (
      productType === 'CARTAO' &&
      productId &&
      currencyFromValue > 0 &&
      currencyToValue > 0 &&
      baseValue <= maxUsdValueToValidation &&
      currencyFromValue >= minUsdValueToValidationCard
    ) {
      valid = true;
    }

    if (isValid !== valid) setIsValid(valid);
  };

  return (
    <Section>
      <Wrapper>
        <Title>Dinheiro para Viagem</Title>
        <Content>
          <StepperCalculator isANewCard={isANewCard} />
        </Content>
      </Wrapper>
      <Sidebar>
        <CheckoutStepper percent={percent} />
        <ResumeOrder remakeSimulation={remakeSimulation} />
        <ButtonList>
          <Button sm={undefined} ghost={undefined} disabled>
            {' '}
            Voltar{' '}
          </Button>
          <Button
            sm={undefined}
            disabled={!isValid || isLoading}
            onClick={changeStep}
          >
            {' '}
            {isLoading ? 'Enviando dados...' : 'Próximo passo'}
          </Button>
        </ButtonList>
      </Sidebar>
    </Section>
  );
};

export default CheckoutSimulation;
