import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;

  span {
    color: red;
  }
`;

export const All = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Title = styled.div`
  margin-bottom: 20px;
  color: #20315b;
  /* width: 298px; */
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
`;

export const SubTitle = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  color: #455566;
  /* width: 390px; */
`;

export const Content = styled.div`
  max-width: 400px;
  margin-top: 20%;

  img {
    width: 250px;
    margin-bottom: 60px;
    margin-left: -20px;
  }

  .input {
    margin: 5px 0 5px 0;
    height: 50px;
    width: 384px;
    border-radius: 5px;
    border: 1px solid #d3dce6;
    padding-left: 10px;
  }

  button {
    margin-top: 20px;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    position: absolute;
    width: 384px;
    height: 48px;
    color: #fff;
    background: linear-gradient(
      138.72deg,
      #48d3fe 28.81%,
      #0fc7ff 52.98%,
      #09b2e6 84.96%
    );
    box-shadow: 0px 3px 20px rgba(114, 222, 255, 0.25),
      0px 2px 4px rgba(196, 241, 255, 0.25), 0px 5px 20px rgba(0, 159, 217, 0.2);
    border-radius: 30px;
    border: none;

    @media screen and (max-width: 425px) {
      position: relative;
    }
  }

  @media screen and (max-width: 600px) {
    margin: 40px auto;
  }

  @media screen and (max-width: 320px) {
    max-width: 300px;
  }

  @media (min-width: 321px) and (max-width: 410px) {
    max-width: 320px;
  }
`;

export const Banner = styled.div`
  width: 100%;
  display: block;

  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

export const Rodape = styled.div`
  margin: 100px 0 100px;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #009fd9;
    text-decoration: none;
  }
`;

export const Info = styled.div`
  background: #eff2f7;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  p {
    margin: 10px 20px;
    line-height: 20px;
    color: #34404d;
    font-size: 14px;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 20px;
  }
`;
