import React from 'react';
import { ImageDocumentIcon, DocumentIcon } from '~/assets/icons';
import { api } from '~/services/api';

export const DEFAULT_ALLOWED_FILE_TYPES = [
  'application/pdf',
  'image/jpeg',
  'image/png',
];

export const DEFAULT_SIZE_LIMIT = '7000000'; // 7MB

export const DEFAULT_ICON_FILES = {
  pdf: <DocumentIcon />,
  jpeg: <ImageDocumentIcon />,
  png: <ImageDocumentIcon />,
  'application/pdf': <DocumentIcon />,
  'image/jpeg': <ImageDocumentIcon />,
  'image/png': <ImageDocumentIcon />,
};

export async function UploadFiles(
  file,
  leadid,
  className,
  type,
  property,
  onUploadProgressCb
) {
  const data = new FormData();

  data.append('id', leadid);
  data.append('className', className);
  data.append('type', type);
  data.append('property', property);
  data.append('file', file);

  await api.post(`/fileManager`, data, {
    onUploadProgress: (progressEvent) => {
      if (onUploadProgressCb) {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        onUploadProgressCb(progress);
      }
    },
  });
}
