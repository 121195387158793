import React, { useState } from 'react';
import { Checkbox } from 'rsuite';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Content, Banner, Title, TextArea, Footer } from './styles';
import Button from '~/components/Button';
import List from '~/components/WelcomeList';
import Logo from '../../assets/logoAdvanced.svg';
import ImageBanner from '../../assets/welcome-page.svg';
import Check from '../../assets/check.svg';
import Soon from '../../assets/soon.svg';
import { skipIntrodution } from '~/store/modules/user/actions';

const WelcomeListChecked = [
  {
    icon: Check,
    type: 'check',
    title: 'Adicionar, editar e gerenciar seus documentos',
    color: '#34404D',
    subtitle:
      'O processo de cadastro dos seus dados na Advanced agora está muito mais fácil e totalmente ao seu alcance.',
  },
  {
    icon: Check,
    type: 'check',
    title: 'Falar diretamente com nossos especialistas',
    color: '#34404D',
    subtitle:
      'Nossos clientes agora estão muito mais próximos de nossos especialistas para solicitar serviços ou tirar dúvidas',
  },
  {
    icon: Check,
    type: 'check',
    title: 'Consultar suas operações realizadas',
    color: '#34404D',
    subtitle:
      'Para dar mais visibilidade para nossos clientes, ficou muito mais fácil acompanhar o status das suas operações por aqui',
  },
  {
    icon: Check,
    type: 'soon',
    title: 'Assinar seus contratos de câmbio online',
    color: '#34404D',
    subtitle:
      'Para agilizar o processo de finalização dos seus pedidos, você agora poderá assinar seus contratos de câmbio online',
  },
  {
    icon: Soon,
    type: 'soon',
    title: 'Enviar e receber dinheiro',
    color: '#9BA8B7',
    subtitle: '',
    tag: 'EM BREVE',
  },
  {
    icon: Soon,
    type: 'soon',
    title: 'Solicitar câmbio para viagem',
    color: '#9BA8B7',
    subtitle: '',
    tag: 'EM BREVE',
  },
  {
    icon: Soon,
    type: 'soon',
    title: 'Acessar seu saldo e sacar valores',
    color: '#9BA8B7',
    subtitle: '',
    tag: 'EM BREVE',
  },
];

function WelcomePage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = useState(false);

  // Lógica esconder modal de boas vindas
  const handleSubmit = () => {
    dispatch(skipIntrodution(value));
    history.push('/home');
  };

  const handleValidationPage = (values) => {
    setValue(values);
  };

  return (
    <Container>
      <div className='backgroundBanner' />
      <Content>
        <Banner>
          <img
            src={ImageBanner}
            className='welcome'
            alt='Banner tela de boas vindas'
          />
        </Banner>

        <TextArea>
          <img src={Logo} className='LogoImage' alt='Logo da Advanced' />

          <Title>O que você pode fazer no painel de clientes Advanced</Title>
          {WelcomeListChecked.map((list) => (
            <List
              key={list.title}
              tag={list.tag}
              type={list.type}
              icon={list.icon}
              title={list.title}
              color={list.color}
              subtitle={list.subtitle}
            />
          ))}

          <Footer>
            <Button sm onClick={handleSubmit}>
              Entrar
            </Button>
            <Checkbox
              value={!value}
              checked={value}
              onChange={(values) => handleValidationPage(values)}
            >
              <span>Não mostrar essa mensagem novamente</span>
            </Checkbox>
          </Footer>
        </TextArea>
      </Content>
    </Container>
  );
}

export default WelcomePage;
