import produce from 'immer';

const INITIAL_STATE = {
  partner: null,
};

export default function bank(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@partner/SET_PARTNER': {
        draft.partner = action.payload;
        break;
      }

      case '@partner/PARTNER': {
        draft.partner = null;
        break;
      }

      default:
        break;
    }
  });
}
