import dayjs from 'dayjs';
import React from 'react';
import NextBusinessDayButton from '../NextBusinessDayButton';

const NextBusinessDaysRow = ({ dates, onChange }) => {
  const [selectedDate, setSelectedDate] = React.useState(() => {
    const initial = dates[0];
    if (onChange) onChange(initial);
    return initial;
  });
  if (!dayjs(selectedDate).isValid()) throw new Error('Invalid selectedDate');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        width: '100%',
        marginTop: 10,
      }}
    >
      {dates.map((date) => (
        <NextBusinessDayButton
          date={date}
          onChange={(d) => {
            setSelectedDate(d);
            if (onChange) onChange(d);
          }}
          isSelected={dayjs(selectedDate).isSame(date, 'day')}
        />
      ))}
    </div>
  );
};

export default NextBusinessDaysRow;
