import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ArrowLeft, Info } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { toast } from 'react-toastify';
import {
  Breadcrumb,
  Col,
  ControlLabel,
  Form,
  FormGroup,
  Progress,
  Row,
  Whisper,
} from 'rsuite';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import cnpjMask from '~/mask/cnpj';
import { MobileHeaderData } from '~/components/HeaderData';
import LoggedLayout, { LayoutContent } from '~/components/LoggedLayout';
import {
  Sidebar,
  SidebarContent,
  SidebarSubtitle,
  SidebarTitle,
} from '~/components/Sidebar';
import { api } from '~/services/api';
import { updateUser } from '~/store/modules/user/actions';
import { calculatePercentPJRequest } from '~/utils/calculate-percent';
import {
  All,
  BackButton,
  Container,
  StyledButton,
  StyledMaskedInput,
  StyledTitle,
  Title,
  DocumentFields,
} from '../../../components/styles/form.js';
import {
  BodyUpload,
  FooterLabel,
  InfoStatusComplete,
  InfoStatusOptional,
  TooltipLimit,
} from './styles';
import { InputUpload } from '~/components/InputUpload';
import FilesList from '~/components/FilesList';
import {
  DEFAULT_ALLOWED_FILE_TYPES,
  DEFAULT_SIZE_LIMIT,
} from '~/services/files.js';
import history from '../../../services/history';
import ErrorModal from '~/components/ErrorModal/index.js';
import UploadFiles from '../../../components/UploadFiles';
import * as fileUploaderService from '~/services/files';

const maskOptions = {
  prefix: 'R$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 12, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const Requests = () => {
  const { Line } = Progress;
  const { user, auth } = useSelector((state) => state);
  const [percentage, setPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorData, setErrorData] = useState({});

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [uploadProgressFileList, setUploadProgressFileList] = useState([]);
  const [filesList, setFilesList] = useState([]);

  const { annualPatrimony, annualIncome, isRelationship, document } =
    user.profile;

  const documentId = cnpjMask(document);
  const documentClassName = 'Documento Financeiro';
  const documentType = 'Tipo Documento Financeiro';
  const documentProperty = 'Outros';

  const tooltip = (
    <TooltipLimit>
      Exigido somente para limites operacionais acima de R$ 120.000
    </TooltipLimit>
  );

  const dispatch = useDispatch();

  useEffect(() => {
    window.amplitude.getInstance().logEvent('access Personal Data page');
  }, []);

  const FieldsSchema = Yup.object().shape({
    annualPatrimony: Yup.string().required('Obrigatório'),
    annualIncome: Yup.string().required('Obrigatório'),
  });

  const currencyMask = createNumberMask({ ...maskOptions });

  // Ajustar porcentgem aqui!!!!
  useEffect(() => {
    setPercentage(calculatePercentPJRequest(user.profile));
  }, [annualPatrimony, annualIncome]);

  // function onFileUpload(docs, token) {
  //   const newFile = new FormData();
  //   if (docs.length > 0) {
  //     docs.map((item) => newFile.append('file', item));

  //     const userDocument = user.profile.document
  //       ? user.profile.document.replace(/\D/g, '')
  //       : '';

  //     newFile.append('id', userDocument);
  //     newFile.append('className', 'Documento Financeiro');
  //     newFile.append('type', 'Tipo Documento Financeiro');
  //     newFile.append('property', 'Outro');

  //     api.defaults.headers.Authorization = `Bearer ${token}`

  //     api
  //       .post(`/fileManager`, newFile, {
  //         headers: { 'Content-Type': 'multipart/form-data' },
  //       })
  //       .then((item) => {
  //         toast.success('Arquivo enviado com sucesso.');
  //       })
  //       .catch((item) => {
  //         setShowModal(true);
  //         setErrorData({
  //           title: 'Erro ao enviar arquivo',
  //           description: 'Tivemos um problema no envio do seu arquivo, tente efetuar o upload novamente.',
  //         });

  //         // toast.error('Houve um problema ao enviar as informações do documento.');
  //       });
  //   }

  // }

  return (
    <LoggedLayout
      isUploading={fileIsUploading}
      uploadProgressFileList={uploadProgressFileList}
    >
      <Container className='responsive-container'>
        <Sidebar>
          <SidebarContent>
            <ReactSVG src='/icons/finantial.svg' />
            <SidebarTitle internal>Financeiro</SidebarTitle>
            <SidebarSubtitle internal>
              Servicos, faturamento, património e etc
            </SidebarSubtitle>
            {percentage === 100 ? (
              <InfoStatusComplete>Concluído</InfoStatusComplete>
            ) : (
              <InfoStatusOptional>
                Opcional
                <Whisper
                  placement='bottom'
                  controlId='control-id-hover'
                  trigger='hover'
                  speaker={tooltip}
                >
                  <Info
                    size={24}
                    color='#09B2E6'
                    style={{ marginLeft: '8px' }}
                  />
                </Whisper>
              </InfoStatusOptional>
            )}
          </SidebarContent>
        </Sidebar>

        <MobileHeaderData
          title='Financeiro'
          percentage={percentage}
          icon='/icons/finantial.svg'
        />

        <LayoutContent>
          <ErrorModal
            showModal={showModal}
            onChange={() => setShowModal(false)}
            {...errorData}
          />

          <Formik
            initialValues={{
              annualPatrimony: annualPatrimony
                ? annualPatrimony.toString().replace('.', ',')
                : '',
              annualIncome: annualIncome
                ? annualIncome.toString().replace('.', ',')
                : '',
            }}
            validationSchema={FieldsSchema}
            onSubmit={async (values, action) => {
              const { file } = values;
              const id = user.profile._id;
              const { token } = auth;

              try {
                action.setSubmitting(true);
                setLoading(true);
                api.defaults.headers.Authorization = `Bearer ${token}`;

                // if (file) onFileUpload(file, token);
                if (filesToUpload.length > 0) {
                  setFileIsUploading(true);
                  let uploadList = filesToUpload.map((f) => {
                    return {
                      id: f.name,
                      progress: 0,
                    };
                  });
                  setUploadProgressFileList([...uploadList]);
                  await Promise.all(
                    filesToUpload.map((item) =>
                      fileUploaderService
                        .UploadFiles(
                          item,
                          documentId,
                          documentClassName,
                          documentType,
                          documentProperty,
                          (progress) => {
                            uploadList = uploadList.map((up) => {
                              return {
                                id: up.id,
                                progress:
                                  up.id == item.name ? progress : up.progress,
                              };
                            });
                            setUploadProgressFileList([...uploadList]);
                          }
                        )
                        .then(() => {
                          uploadList = uploadList.map((up) => {
                            return {
                              id: up.id,
                              progress: up.id == item.name ? 100 : up.progress,
                            };
                          });
                          setUploadProgressFileList([...uploadList]);
                        })
                    )
                  );
                  setFileIsUploading(false);
                }

                const annualNetIncome = values.annualIncome
                  ? Number(
                      parseFloat(
                        typeof values.annualIncome === 'string'
                          ? values.annualIncome
                              .replace('R$', '')
                              // .replaceAll('.', '')
                              .replace('.', '')
                              .replace('.', '')
                              .replace(',', '.')
                          : values.annualIncome
                      ).toFixed(2)
                    )
                  : 0;

                const annualNetWorth = values.annualPatrimony
                  ? Number(
                      parseFloat(
                        typeof values.annualPatrimony === 'string'
                          ? values.annualPatrimony
                              .replace('R$', '')
                              // .replaceAll('.', '')
                              .replace('.', '')
                              .replace('.', '')
                              .replace(',', '.')
                          : values.annualPatrimony
                      ).toFixed(2)
                    )
                  : 0;

                api
                  .put(`/register/users/enterprise-finantial`, {
                    annualPatrimony: annualNetWorth,
                    annualIncome: annualNetIncome,
                  })
                  .then((item) => {
                    dispatch(
                      updateUser({
                        user: item.data,
                      })
                    );

                    setLoading(false);
                    toast.success('Alterações realizadas com sucesso.');
                    window.amplitude
                      .getInstance()
                      .logEvent('Updated personal data', item);
                    history.replace('/update-data');
                  })
                  .catch((item) => {
                    setLoading(false);
                    setShowModal(true);
                    setErrorData({
                      title: 'Erro ao processar dados',
                      description:
                        'Tivemos um problema no envio das informações, revise o preenchimento dos campos obrigatórios ou tente novamente.',
                    });

                    // toast.error('Houve um problema ao enviar as infromações.');
                    action.setSubmitting(false);
                    window.amplitude
                      .getInstance()
                      .logEvent('Error in update client', item);
                  });
              } catch (err) {
                setLoading(false);
                setShowModal(true);
                setErrorData({
                  title: 'Erro ao processar dados',
                  description:
                    'Tivemos um problema no envio das informações, revise o preenchimento dos campos obrigatórios ou tente novamente.',
                });

                // toast.error('Houve um problema ao enviar as infromações.');

                action.setSubmitting(false);

                window.amplitude
                  .getInstance()
                  .logEvent('Error in update client', err);
              }
            }}
          >
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldValue,
              isValid,
              isSubmitting,
            }) => {
              // const FileChange = (value, event) => {
              //   setFieldValue('file', [...values.file, ...value]);
              // }

              // const FileRemove = (value, event) => {
              //   const filter = values.file.filter((item) => {
              //     return item.path !== value;
              //   });

              //   setFieldValue('file', filter);
              // }
              return (
                <Form onSubmit={handleSubmit}>
                  <All>
                    <Breadcrumb className='breadcrumb'>
                      <Link className='step' to='/home'>
                        Home
                      </Link>
                      <Link className='step' to='/update-data'>
                        Meus dados
                      </Link>
                      <Link className='active'>Financeiro</Link>
                    </Breadcrumb>
                    <BackButton className='back-button'>
                      <ArrowLeft />
                      <Link to='/update-data'>
                        <p>Voltar</p>
                      </Link>
                    </BackButton>
                    <Row>
                      <StyledTitle>Sobre a empresa:</StyledTitle>
                      <Row>
                        <Col xs={24} lg={8}>
                          <FormGroup>
                            <ControlLabel>
                              Fat. líquido anual (R$) *
                            </ControlLabel>
                            <MaskedInput
                              mask={currencyMask}
                              onChange={(value, event) => handleChange(value)}
                              onBlur={handleBlur}
                              name='annualIncome'
                              id='annualIncome'
                              value={values.annualIncome}
                              render={(ref, props) => (
                                <StyledMaskedInput
                                  type='text'
                                  className='rs-input'
                                  ref={ref}
                                  {...props}
                                />
                              )}
                            />

                            {errors.annualIncome && touched.annualIncome && (
                              <span className='input-error'>
                                {errors.annualIncome}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xs={24} lg={8}>
                          <FormGroup>
                            <ControlLabel>
                              Patr. líquido anual (R$) *
                            </ControlLabel>
                            <MaskedInput
                              mask={currencyMask}
                              onChange={(value, event) => handleChange(value)}
                              onBlur={handleBlur}
                              name='annualPatrimony'
                              id='annualPatrimony'
                              value={values.annualPatrimony}
                              render={(ref, props) => (
                                <StyledMaskedInput
                                  type='text'
                                  className='rs-input'
                                  ref={ref}
                                  {...props}
                                />
                              )}
                            />

                            {errors.annualPatrimony &&
                              touched.annualPatrimony && (
                                <span className='input-error'>
                                  {errors.annualPatrimony}
                                </span>
                              )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Col xs={24} lg={24}>
                        <UploadFiles
                          className={documentClassName}
                          type={documentType}
                          titleText='Envie cópia do último balanço patrimonial encerrado'
                          required={isRelationship !== true}
                          files={filesToUpload}
                          onChange={(files) => {
                            setFilesToUpload([...files]);
                            setFieldValue('files', files);
                          }}
                          listOldFiles
                          setFilesList={setFilesList}
                        />
                      </Col>
                    </Row>

                    <StyledButton
                      type='submit'
                      disabled={
                        isRelationship === true
                          ? !isValid || loading || isSubmitting
                          : !isValid ||
                            loading ||
                            isSubmitting ||
                            !filesToUpload.length
                      }
                    >
                      {loading ? 'Carregando...' : 'Salvar'}
                    </StyledButton>

                    <FooterLabel>
                      * Campos obrigatórios para salvar formulário e seguir com
                      o cadastro.
                    </FooterLabel>
                  </All>
                </Form>
              );
            }}
          </Formik>
        </LayoutContent>
      </Container>
    </LoggedLayout>
  );
};

export default Requests;
