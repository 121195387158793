import styled from 'styled-components';
import { Button, Tooltip } from 'rsuite';

export const ButtonList = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  width: 100%;
  max-width: 95%;
  margin: 0 auto;

  &:hover {
    background: #e7faff !important;
  }
`;

export const BodyList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  img {
    width: 48px;
    height: 48px;
  }
`;

export const ListInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const InfoText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoTitle = styled.span`
  text-align: left;
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #34404d;
`;

export const InfoStatusComplete = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #14cc70;
`;

export const InfoStatusIncomplete = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #ef3131;
`;

export const InfoStatusOptional = styled.p`
  font-family: IBM Plex Sans;
  font-style: italic;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #9ba8b7;
`;

export const BankLength = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: #0fc7ff;
  font-weight: bold;
  display: flex;
`;

export const TooltipLimit = styled(Tooltip)`
  .rs-tooltip-inner {
    background-color: #132552;
    border-radius: 5px;
    font-family: IBM Plex Sans;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    padding: 8px 8px;
  }

  .rs-tooltip-arrow {
    border-bottom-color: #132552;
  }
`;
