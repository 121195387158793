import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    padding-top: 50px;
    padding-left: 150px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const DesktopComponentHeader = styled.div`
  display: none;
  @media only screen and (min-width: 768px) {
    padding: 50px 50px 16px 150px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    a {
      margin: 0 5px;
    }

    button {
      background: transparent;
      color: #09b2e6;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 60px;
    }
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (min-width: 768px) {
    margin-left: 40px;
  }
`;

export const InvalidBanner = styled.span`
  color: #fff !important;
  background-color: red;
  text-align: center;
`;

export const HeaderBar = styled.div`
  padding: 20px 30px;

  display: flex;
  flex-direction: row;

  .headerbar-title {
    font-weight: 500;
    font-size: 25px;
    line-height: 34px;
    color: #20315b;
  }

  img {
    margin-right: 8px;
  }
`;

export const CardDataWrapper = styled.div`
  background-image: ${(props) => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  background-attachment: cover;
  min-height: 167px;
  min-width: 293px;
  border-radius: 16px;
  padding: 10px 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  h3 {
    font-size: 20px;
    color: #ccc;
  }

  > div {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: flex-start;
  }

  span {
    color: #ccc;
    font-weight: 700;
  }

  .client {
    font-size: 12px !important;
    max-width: 160px;
  }
`;

export const CardRechargeInfo = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    div {
      width: 295px !important;
      margin-top: 10px;
    }
  }
`;

export const CardTaxInfo = styled.div`
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    strong {
      color: #132552;
    }
  }
`;

export const ConfirmButton = styled.button`
  background-image: linear-gradient(
    98.1deg,
    #48d3fe 28.81%,
    #0fc7ff 52.98%,
    #09b2e6 84.96%
  );
  width: 100%;
  max-width: 320px;
  border-radius: 500px;
  color: #fff;
  padding: 10px 16px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin: 15px auto;
`;
