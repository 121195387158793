import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import LoggedLayout from '~/components/LoggedLayout';
// import { useHistory } from 'react-router-dom';
import CheckoutConfirmation from '~/components/CheckoutConfirmation';
import CheckoutLayout from '~/components/CheckoutLayout';
import CheckoutSimulation from '~/components/CheckoutSimulation';
import CheckoutPayment from '../CheckoutPayment';

import ModalExpiredTime from '~/components/ModalsCheckout/ModalExpiredTime';

import {
  resetTicketOperation,
  setCheckoutStep,
  updateTicket,
  updateTicketNewStatus,
} from '~/store/modules/exchange/actions';

import { BoxTimer } from './styles';
import BoxPayment from '~/components/BoxPayment';
import TimerPayment from '../../components/TimerPayment';

const Checkout = () => {
  const [percent, setPercent] = useState(20);
  const history = useHistory();
  const dispatch = useDispatch();

  const { exchange, user } = useSelector((state) => state);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const changeStep = ({ back = false } = { back: false }) => {
    scrollToTop();

    if (back === true) {
      dispatch(setCheckoutStep(back));
    } else {
      dispatch(setCheckoutStep(exchange.checkoutStep + 1));
    }
  };

  const backToInit = () => {
    window.location.reload(true);
  };

  const checkClientIsAllowedToCheckout = () => {
    const isAllowed = user.profile?.isRelationship;
    if (!isAllowed) {
      history.push('/login');
      toast.info(
        'Você ainda não pode realizar uma operação. Por favor, continue completando o seu cadastro.'
      );
    }
  };

  useEffect(() => {
    if (user) {
      checkClientIsAllowedToCheckout();
    }
  }, [user]);

  useEffect(() => {
    let newPercent;

    if (exchange.checkoutStep === 1) {
      newPercent = 20;

      return setPercent(newPercent);
    }

    if (exchange.checkoutStep === 2) {
      newPercent = 40;

      return setPercent(newPercent);
    }

    if (exchange.checkoutStep === 3) {
      newPercent = 80;

      return setPercent(newPercent);
    }

    newPercent = 100;
    return setPercent(newPercent);
  }, [exchange.checkoutStep]);

  useEffect(() => {
    if (!exchange.ticket.ticket_id && exchange.new) {
      return;
    }

    if (
      (!exchange.ticket.ticket_id || exchange.ticket.ticket_id === undefined) &&
      !exchange.new
    ) {
      dispatch(resetTicketOperation());
    }
  }, []);

  return (
    <LoggedLayout>
      <CheckoutLayout>
        {exchange.checkoutStep > 1 && exchange.timerInitialized === true && (
          <BoxTimer>
            <BoxPayment>
              <TimerPayment
                time={{ min: 0, sec: 30 }}
                text='O valor da sua cotação vence em: '
              />
            </BoxPayment>
          </BoxTimer>
        )}
        {exchange.checkoutStep === 1 && (
          <CheckoutSimulation
            percent={percent}
            remakeSimulation={() => backToInit()}
            changeStep={() => changeStep()}
          />
        )}
        {exchange.checkoutStep === 2 && (
          <CheckoutConfirmation
            percent={percent}
            remakeSimulation={() => backToInit()}
            changeStep={(param) => changeStep(param)}
          />
        )}
        {exchange.checkoutStep === 3 && (
          <CheckoutPayment
            percent={percent}
            remakeSimulation={() => backToInit()}
            changeStep={(param) => changeStep(param)}
          />
        )}
      </CheckoutLayout>

      <ModalExpiredTime isModalVisible={exchange.expiredTime} />
    </LoggedLayout>
  );
};

export default Checkout;
