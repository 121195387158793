import styled from 'styled-components';

export const CustomTitle = styled.h2`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #20315b;
  padding: 24px 0;
`;

export const CustomDescription = styled.span`
  color: #34404d;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 0 41px;
`;

export const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;
export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
`;
export const ListPrincipal = styled.span`
  color: #0fc7ff;
  font-weight: 500;
  font-size: 50px;
  line-height: 62px;
`;
export const ListData = styled.ul`
  padding-left: 16px;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
`;
export const Neighborhood = styled.li``;
