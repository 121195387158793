import styled from 'styled-components';
import SelectOptionCard from '~/pages/SelectOption/SelectOptionCard';

export const Container = styled.div`
  display: flex;
`;

export const CardWrapper = styled.div`
  background: #fff;
  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 10px;
  width: ${(props) =>
    (props.hg && `488px`) ||
    (props.lg && `450px`) ||
    (props.md && `400px`) ||
    (props.lw && `365px`)};
  height: ${(props) =>
    (props.alt && `802px`) ||
    // (props.alt && `757px`) ||
    (props.med && `490px`) ||
    (props.auto && `490px`) ||
    (props.automatic && `auto`) ||
    (props.low && `316px`)};
  margin: 30px 0 0 30px;
  /* padding: 24px 24px; */
  position: relative;

  @media (max-width: 768px) {
    ${({ full }) =>
      full &&
      `
    padding: 24px;
  `}
  }

  @media (width: 1024px) {
    /* width: ${(props) => props.hg && `460px`}; */
    /* height: auto; */
  }

  @media screen and (max-width: 968px) and (min-width: 651px) {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: ${(props) => props.md && `488px`};
    height: ${(props) => props.alt && `280px`};
  }

  @media (max-width: 375px) {
    width: ${(props) => props.hg && `100px`};
    height: ${(props) => props.alt && ``};
  }

  @media (max-width: 650px) {
    width: auto;
    padding-bottom: 0;
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    margin: 0 auto 25px;
    width: 80%;
  }

  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

export const Wrapper = styled.div`
  margin: 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Header = styled.div`
  font-family: 'IBM Plex Sans';

  p {
    font-size: 24px;
    font-weight: 500;
    color: #20315b;
    line-height: 34px;
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    margin-top: 16px;
    gap: 8px;
  }
`;

export const Conditions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  h1 {
    font-size: 24px;
    text-align: center;
    color: #20315b;
  }
`;

export const Cotation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  border: 2px solid rgb(15, 199, 255);
  border-radius: 10px;
  padding: 24px;

  strong {
    color: rgb(32, 49, 91);
  }

  > div {
    display: flex;
    justify-content: space-between;
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: column;
  }

  button {
    text-decoration: underline;
    background: none;
    color: #203133;
  }

  hr {
    margin: 0;
  }

  p {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const Message = styled.div`
  padding: 8px;
  background: #f1f1f1;

  p {
    text-align: center;
  }
`;

export const Footer = styled.div``;

export const UpTriangle = styled.div`
  width: 0;
  height: 0;

  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid green;
`;

export const DownTriangle = styled.div`
  width: 0;
  height: 0;

  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid red;
`;
