import React, { useCallback, useEffect, useState } from 'react';
import { ControlLabel, FormGroup } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Formik } from 'formik';

import {
  getProducts,
  selectProduct,
  updateProductValue,
  addCurrencyTax,
  generateTicketOrderForCard,
  selectProductType,
} from '~/store/modules/exchange/actions';

import history from '~/services/history';
import api from '~/services/api';

import IconDoc from '~/pages/MyData/icons/card.svg';

import { StyledInput } from '~/components/styles/form.js';

import {
  Container,
  HeaderBar,
  CardDataWrapper,
  CardRechargeInfo,
  CardTaxInfo,
  ConfirmButton,
  PageWrapper,
  InvalidBanner,
  TaxInfo,
  Select,
  SelectHolder,
} from '../../styles';
import CheckoutStepper from '~/components/CheckoutStepper';
import { transformNumberInMoney } from '~/utils/transformNumberInMoney';
import { calculateIof } from '~/utils/calculateIOF';
import cpfMask from '~/mask/cpf';
import { Flags } from '~/components/Flags';

export default function Create({ handleStep, cardNumber }) {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);

  const cardFormatted =
    cardNumber.match(/\d+/g).join(' ') || '0000 0000 0000 0000';

  const { productList } = useSelector((state) => state.exchange);

  const [card, setCard] = useState();
  const [moneyExchange, _setMoneyExchange] = useState();
  const [taxExchange, setTaxExchange] = useState();
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [productExchange, setProductExchange] = useState();
  const [moneyFlag, setMoneyFlag] = useState('');

  const compareDate = useCallback((expires) => {
    if (!expires) return false;

    const today = new Date();
    const expiresSplit = expires.split('/');

    const expireDate = new Date(
      expiresSplit[1],
      expiresSplit[0] - 1,
      expiresSplit[0] === 2 ? '28' : '30'
    );

    return today.getTime() > expireDate.getTime();
  }, []);

  const getCard = useCallback(async () => {
    try {
      const response = await api.get(
        // `/exchange/cards/card?cardNumber=${cardNumber}`
        `/exchange/cards/card/{cardNumber}?cardNumber=${cardNumber || 0}`
      );

      setCard(response.data);
    } catch (error) {
      toast.error('Não foi possível encontrar o cartão');
      history.goBack();
    }
  }, []);

  const getTax = useCallback(async (moneyExchange) => {
    try {
      const response = await api.get(
        `/exchange/taxes?codeAgent=DIGITAL&codeProduct=${moneyExchange}`
      );

      setTaxExchange(response.data);
    } catch (error) {
      return toast.error(
        'Não é possível calcular a taxa da moeda escolhida no momento, tente de novo mais tarde'
      );
    }
  }, []);

  function handleNextStep(valueTo) {
    const valueBy = valueTo * taxExchange.sugest_price;

    dispatch(
      selectProduct(
        productExchange.productId,
        productExchange.groupDescription,
        productExchange.iof
      )
    );

    dispatch(
      addCurrencyTax(productExchange.productId, taxExchange, taxExchange)
    );

    dispatch(updateProductValue(valueBy, valueTo));

    handleStep(3);
  }

  useEffect(() => {
    if (!productList.length) dispatch(getProducts());

    if (productList.length && !currencyOptions.length) {
      const products = productList.filter(
        (item) =>
          item.productTypeDescription === 'CARTAO' &&
          item.currencyDescription.includes('ADVANCED')
      );

      const currencies = products.map((item) => {
        return {
          label: item.groupDescription,
          value: item.productId,
        };
      });

      setCurrencyOptions(currencies);
    }
  }, [dispatch, productList]);

  useEffect(() => {
    getCard();
  }, [getCard]);

  useEffect(() => {
    if (moneyExchange) getTax(moneyExchange);
  }, [moneyExchange, getTax]);

  function handleIofTax(value = 0) {
    const iof =
      value * +taxExchange.sugest_price * (+productExchange.iof / 100);

    return iof;
  }

  function handleValueProduct(value = 0) {
    const valueProduct = value * +taxExchange.sugest_price;

    return valueProduct;
  }

  function handleTotalValue(value = 0) {
    const total =
      value * +taxExchange.sugest_price +
      value * +taxExchange.sugest_price * (+productExchange.iof / 100);

    return total;
  }

  const requestRecharge = async (valueExchange) => {
    const addressResponse = await api.get(`/delivery_address/${profile._id}`);

    const nacional_currency_value = +valueExchange * +taxExchange.sugest_price;

    dispatch(
      generateTicketOrderForCard({
        client_address_delivery_id: addressResponse.data[0].address_id,
        code_product: productExchange.productId,
        delivery_local: 'DELIVERY',
        foreign_currency_value: valueExchange,
        iof: calculateIof(nacional_currency_value, productExchange.iof).toFixed(
          2
        ),
        nacional_currency_value: nacional_currency_value.toFixed(2),
        tax_id: taxExchange.id,
        tax_value: taxExchange.sugest_price,
        exchange_card: {
          birth_date: card.birthDate,
          card_number: card.cardNumber,
          code_client: profile.gnCambioId,
          code_currency: moneyExchange,
          document: cpfMask(profile.document),
          email: card.email,
          genre_traveler: card.travelerGender || 'M',
          mother_name: card.motherName,
          rg: profile?.identificationDocument,
          travel_date: card.travelDate,
          traveler: card.travelerName,
          titular_name: profile.name,
          digit_card: card.cardDigit,
          is_charge: 'N',
        },
      })
    );

    handleNextStep(valueExchange);
  };

  const currencyMap = {
    USD: 'Dólar americano',
    BPUSD: 'Dólar americano',
    MCUSD: 'Dólar americano',
    GBP: 'Libra esterlina',
    BPGBP: 'Libra esterlina',
    MCGBP: 'Libra esterlina',
    EUR: 'Euro',
    BPEUR: 'Euro',
    MCEUR: 'Euro',
    VSEUR: 'Euro',
    DKK: 'Coroa dinamarquesa',
    CAD: 'Dólar canadense',
    BPCAD: 'Dólar canadense',
    NZD: 'Dólar neozelandês',
    BPNZD: 'Dólar neozelandês',
    AUD: 'Dólar australiano',
    BPAUD: 'Dólar australiano',
    CHF: 'Franco suíço',
    CLP: 'Peso chileno',
    JPY: 'Iene',
    MXN: 'Peso mexicano',
    SEK: 'Coroa sueca',
    ARS: 'Peso argentino',
    ZAR: 'Rand sul-africano',
    CNY: 'Yuan chinês',
    UYU: 'Peso uruguaio',
    NOK: 'Coroa norueguesa',
    ILS: 'Shekel israelense',
  };

  return (
    <Formik
      initialValues={{
        moneyExchange: '',
        valueExchange: '',
      }}
    >
      {({ values, setFieldValue }) => {
        const changeMoneyCurrency = (value) => {
          setMoneyFlag(value);
          setFieldValue('moneyExchange', value);
          const product = productList.find((item) => item.productId === value);
          setProductExchange(product);
          getTax(value);
        };

        return (
          <Container>
            <HeaderBar>
              <div>
                <img src={IconDoc} alt='iconDoc' />
                <h2 className='headerbar-title'>Informações do cartão</h2>
              </div>

              <span>Selecione as informações do seu cartão pré-pago</span>

              <CheckoutStepper percent={20} />
            </HeaderBar>

            <PageWrapper>
              <CardDataWrapper image='/icons/card-background.png'>
                {compareDate(card?.expiress) && (
                  <InvalidBanner>CARTÃO INVÁLIDO</InvalidBanner>
                )}
                <h3>
                  {card
                    ? `**** **** **** *${card?.cardNumber?.slice(-3)}`
                    : '0000 0000 0000 0000'}
                </h3>

                <div>
                  <span>{card ? card.expiress : '00/0000'}</span>
                  <span className='client'>
                    {card ? card.travelerName : 'Cliente'}
                  </span>
                </div>
              </CardDataWrapper>

              <CardRechargeInfo>
                <h4>Advanced Multi Moeda</h4>

                <div>
                  <FormGroup>
                    <ControlLabel>Moeda do cartão</ControlLabel>
                    <SelectHolder>
                      {!moneyFlag ? (
                        <img
                          src='/icons/advanced.png'
                          title=''
                          style={{ height: '27px', margin: '3px 5px' }}
                        />
                      ) : (
                        <Flags code={moneyFlag} />
                      )}
                      <Select
                        onChange={(value) =>
                          changeMoneyCurrency(value.target.value)
                        }
                        // disabled={!card?.currency}
                      >
                        <option>
                          {/* {' '}
                          {!card?.currency
                            ? 'Carregando...' : */}
                          Escolha a moeda
                        </option>
                        {card?.currency ? (
                          <option key={card?.currency} value={card?.currency}>
                            {card?.currency}
                          </option>
                        ) : (
                          currencyOptions.map(({ label, value }) => (
                            <option key={value} value={value}>
                              {currencyMap[label]}
                            </option>
                          ))
                        )}
                      </Select>
                    </SelectHolder>
                  </FormGroup>

                  <FormGroup>
                    {!currencyOptions.length && !taxExchange ? (
                      <ControlLabel>Carregando...</ControlLabel>
                    ) : (
                      ''
                    )}
                    {currencyOptions.length && !taxExchange ? (
                      <ControlLabel>Aguardando escolha...</ControlLabel>
                    ) : (
                      ''
                    )}
                    {taxExchange && (
                      <ControlLabel>Valor solicitado</ControlLabel>
                    )}
                    <StyledInput
                      onChange={(value, _event) =>
                        setFieldValue('valueExchange', value)
                      }
                      name='valueExchange'
                      id='valueExchange'
                      value={values.valueExchange}
                      maxLength='11'
                      type='number'
                      step='0.01'
                      disabled={!taxExchange}
                    />
                  </FormGroup>
                </div>
              </CardRechargeInfo>

              <CardTaxInfo>
                <>
                  <TaxInfo>
                    <span>
                      Taxa de câmbio{' '}
                      {productExchange &&
                        `(${productExchange.groupDescription}$)`}
                    </span>
                    <span>
                      <strong>
                        {taxExchange &&
                          transformNumberInMoney({
                            number: taxExchange.sugest_price,
                            currency: 'BRL',
                          })}
                      </strong>
                    </span>
                  </TaxInfo>

                  <TaxInfo>
                    <span>Valor do pedido</span>
                    <span>
                      <strong>
                        {taxExchange &&
                          transformNumberInMoney({
                            number: handleValueProduct(
                              +values.valueExchange || 0
                            ),
                            currency: 'BRL',
                          })}
                      </strong>
                    </span>
                  </TaxInfo>

                  <TaxInfo>
                    <span>
                      + IOF ({productExchange ? productExchange.iof : 0.0}%)
                    </span>
                    <span>
                      <strong>
                        {taxExchange &&
                          transformNumberInMoney({
                            number: handleIofTax(+values.valueExchange || 0),
                            currency: 'BRL',
                          })}
                      </strong>
                    </span>
                  </TaxInfo>

                  <TaxInfo>
                    <span>VET</span>
                    <span>
                      <strong>
                        {taxExchange &&
                          !!values.valueExchange &&
                          transformNumberInMoney({
                            number: handleTotalValue(
                              +values.valueExchange || 0
                            ),
                            currency: 'BRL',
                          })}
                      </strong>
                    </span>
                  </TaxInfo>
                </>

                <ConfirmButton
                  onClick={() => requestRecharge(values.valueExchange)}
                  disabled={!values.valueExchange}
                >
                  Salvar
                </ConfirmButton>
              </CardTaxInfo>
            </PageWrapper>
          </Container>
        );
      }}
    </Formik>
  );
}
