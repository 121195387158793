const crypto = require('crypto-js');

export function encrypt(str, key) {
  const sha256 = crypto.createHash('sha256');
  sha256.update(key);
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv('aes-256-ctr', sha256.digest(), iv);
  const ciphertext = cipher.update(Buffer.from(str));
  const encrypted = Buffer.concat([iv, ciphertext, cipher.final()]).toString(
    'base64'
  );
  return encrypted;
}
