import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from '~/services/api';
import { FileBox } from './FileBox';
import { Title } from './styles';

export default function FilesList(props) {
  const [mFiles, setMFiles] = useState([]);
  const [text, setText] = useState('Não há documentos para serem exibidos');
  const { user } = useSelector((state) => state);
  const auth = useSelector((state) => state.auth);
  const { nameClass } = props;

  const getFiles = useCallback(async () => {
    setText('Carregando documentos...');

    api.defaults.headers.Authorization = `Bearer ${auth.token}`;

    let textTitle = '';
    try {
      const response = await api.get(`/register/fileManager`, {
        params: {
          nameClass,
        },
      });

      if (response.status === 200 && response?.data instanceof Array) {
        setMFiles(response?.data);
        props.setFilesList(response?.data);
      } else {
        console.error('Há algum problema com o serviço do MFiles');
        return;
      }

      textTitle = response?.data.length
        ? 'Documentos já enviados'
        : 'Ainda não possui documentos registrados, clique em salvar para um novo envio.';
    } catch (err) {
      console.log(err);
    }

    return setText(textTitle);
  }, [user]);

  useEffect(() => {
    getFiles();
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <Title>{text}</Title>

      {mFiles.map((item) => {
        return (
          <FileBox key={item.DisplayID} mFileObj={item} canDelete={false} />
        );
      })}
    </div>
  );
}
