import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  ChatWrapper,
  ChatAgent,
  ChatWidget,
  HistoryView,
  ChatAfterTime,
  StyledUnselectedHistory,
  UnselectedTitle,
} from './styles';
import ChatIcon from './icon.svg';
import Summary from '~/components/Summary';

const UnselectedHistory = () => (
  <StyledUnselectedHistory>
    <img src={ChatIcon} alt='Ícone de Chat' />
    <UnselectedTitle>Selecione uma das mensagens para abrir</UnselectedTitle>
  </StyledUnselectedHistory>
);

const HistoryChat = ({ selected, closeChat }) => {
  const { currentChat } = useSelector((state) => state.chat);

  const ChatView = () => {
    const chatItems = [];

    return (
      <ChatWrapper id='ChatWrapper'>
        <Summary data={currentChat[0]} closeChat={closeChat} />

        {currentChat &&
          currentChat.map((item) => {
            chatItems.push(item);

            const hasLongTimeDifferenceBeforeLastChat = (
              initialDate = chatItems[chatItems.length - 2].sendAt,
              lastDate = chatItems[chatItems.length - 1].sendAt
            ) => {
              if (chatItems.length >= 2) {
                const fromTime = dayjs(initialDate);
                const actualTime = dayjs(lastDate);

                return actualTime.diff(fromTime, 'h');
              }

              return false;
            };

            const eligibleToChatTimeAlert = chatItems.length >= 2;

            if (chatItems.length === 1) {
              return <ChatAfterTime data={item} />;
            }

            // Defini que a cada 4 horas de diferença nas respostas, o ChatAfterTime é renderizado.

            if (
              eligibleToChatTimeAlert &&
              hasLongTimeDifferenceBeforeLastChat() >= 4
            ) {
              return <ChatAfterTime data={item} />;
            }

            if (
              eligibleToChatTimeAlert &&
              hasLongTimeDifferenceBeforeLastChat(
                chatItems[chatItems.length - 2],
                dayjs()
              ) === 0
            ) {
              return <ChatAfterTime lessTime data={item} />;
            }

            return item.senderType === 'agent' ? (
              <ChatAgent
                photo={item.sender.photo}
                message={item.text}
                key={item.id}
              />
            ) : (
              <ChatWidget message={item.text} key={item.id} />
            );
          })}
      </ChatWrapper>
    );
  };

  return (
    <HistoryView>{selected ? <ChatView /> : <UnselectedHistory />}</HistoryView>
  );
};

export default HistoryChat;
