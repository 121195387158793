import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 0 0 0;
  margin: 0;

  .logout-mobile__title {
    font-size: 14px;
    vertical-align: middle;
    margin-right: 5px;
    color: #20315b;
  }

  @media (min-width: 768px) {
    .logout-mobile {
      display: none;
    }
  }
`;

export const WizardBar = styled.nav`
  background: transparent;
  padding: 16px;
  width: 100%;
  z-index: 50;
  display: flex;

  @media (min-width: 768px) {
    padding: 16px 40px 16px 100px;
    background: #fff;
    box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
      0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  }

  ${(props) =>
    !props.show &&
    `
    @media (max-width: 768px) {
      display: none;
    }
  `}

  @media (max-width: 768px) {
    .container-header {
      flex-direction: row;
      align-items: center;
      display: flex;
      justify-content: space-between;
      flex: 1;
    }
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
`;
