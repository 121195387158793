import React from 'react';

import { ReactSVG } from 'react-svg';
import { BsBriefcase } from 'react-icons/bs';
import { FiFileText, FiHome, FiLogOut, FiBookOpen } from 'react-icons/fi';
import { RiExchangeDollarLine, RiDraftLine } from 'react-icons/ri';
import { FaRegQuestionCircle } from 'react-icons/fa';

export const services = [
  {
    id: 'HomeItem',
    name: 'Home',
    link: '/home',
    component: () => <FiHome size={24} color='#C0CCDA' />,
  },
  {
    id: 'DataItem',
    name: 'Dados',
    link: '/update-data',
    component: () => <FiFileText size={24} color='#C0CCDA' />,
  },
  // {
  //     id: 'ServiceItem',
  //     name: 'Serviços',
  //     link: '/update-data',
  //     component: () => <BsBriefcase size={24} color="#C0CCDA" />
  // },
  {
    id: 'OrdersItem',
    name: 'Pedidos',
    link: '/exchange-tickets',
    component: () => <RiExchangeDollarLine size={24} color='#C0CCDA' />,
  },
  {
    id: 'DocsItem',
    name: 'Contratos',
    link: '/contract',
    component: () => <RiDraftLine size={24} color='#C0CCDA' />,
  },
  // {
  //     id: 'DocsItem',
  //     name: 'Meus docs',
  //     link: '/contract',
  //     component: () => <FiFileText size={24} color="#C0CCDA" />
  // },
];

export const options = [
  {
    id: 'MyCards',
    name: 'Meus cartões',
    link: '/my-cards',
    component: () => <ReactSVG src='/icons/menu/credit-card.svg' />,
    // component: () => <ReactSVG
    // src='/icons/my-cards.svg'
    // style={{ color: '#C0CCDA !important' }}
    //   />
  },
  {
    id: 'Chat',
    name: 'Chat',
    link: '/chat',
    component: () => <ReactSVG src='/icons/chat.svg' />,
  },
  {
    id: 'Tutorial',
    name: 'Tutorial',
    link: '',
    url: 'https://mkt.advancedcorretora.com.br/manual-painel-digital',
    component: () => <FiBookOpen size={42} color='#C0CCDA' />,
  },
  // {
  //     id: 'MoneyForTripItem',
  //     name: 'Dinheiro para viagem',
  //     link: '/checkout',
  //     component: () => <ReactSVG src="/icons/menu/money.svg" />
  // },
  // {
  //     id: 'CommitmentsItem',
  //     name: 'Pequenos compromissos',
  //     link: '/home',
  //     component: () => <ReactSVG src="/icons/menu/exchange.svg" />
  // },
  // {
  //     id: 'SendMoneyToResidentItem',
  //     name: 'Enviar dinheiro para residente',
  //     link: '/home',
  //     component: () => <ReactSVG src="/icons/menu/globe.svg" />
  // },
  // {
  //     id: 'CreditCardMoneyItem',
  //     name: 'Dinheiro no cartão',
  //     link: '/home',
  //     component: () => <ReactSVG src="/icons/menu/credit-card.svg" />
  // },
  // {
  //     id: 'MoneyAbroadItem',
  //     name: 'Dinheiro para o exterior',
  //     link: '/home',
  //     component: () => <ReactSVG src="/icons/menu/bill-money.svg" />
  // },
  // {
  //     id: 'DirectInvestimentItem',
  //     name: 'Investimento Direto',
  //     link: '/home',
  //     component: () => <ReactSVG src="/icons/menu/investiment.svg" />
  // },
  {
    id: 'HelpCenter',
    name: 'Central de ajuda',
    link: '',
    url: 'https://advancedcorretora.com.br/contact',
    component: () => <FaRegQuestionCircle size={42} color='#C0CCDA' />,
  },
  {
    id: 'LogoutItem',
    name: 'Sair do aplicativo',
    link: '/#',
    component: () => <FiLogOut size={42} color='#C0CCDA' />,
    // component: () => <FiLogOut size={42} color="#2E4378" />
  },
];
