import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';
import getXNextBusinessDays from '../../utils/getXNextBusinessDays';
import CustomizedMessage from './CustomizedMessage';
import NextBusinessDaysRow from './NextBusinessDaysRow';

const BodyCard = styled.div`
  width: 100%;
  min-width: 380px;
  display: flex;
  flex-direction: column;

  @media (min-width: 526px) {
    width: 100%;
    margin-left: 64px;
    margin-top: 16px;
  }
`;

const ContentDays = styled.div`
  background-color: #e7faff;
  border-radius: 4;
  width: 100%;
  min-width: 200px;
  padding: 24px;
  font-family: IBM Plex Sans;

  @media screen and (min-width: 769px) {
    width: 90%;
  }

  @media screen and (max-width: 768px) and (min-width: 526px) {
    width: 85%;
  }
`;

const SelectNextBusinessDay = ({
  onChange,
  orderDate,
  productType,
  currencyTo,
}) => {
  const [showDates, setShowDates] = React.useState(false);
  const [nextBusinessDays, setNextBusinessDays] = React.useState([]);
  const validCurrenciesCash = ['USD', 'EUR', 'GBP', 'CAD'];

  React.useEffect(() => {
    if (productType === 'ESPECIE' && validCurrenciesCash.includes(currencyTo)) {
      const next5BusinessDays = getXNextBusinessDays(
        dayjs(orderDate).add(2, 'day').format('YYYY-MM-DD'),
        5
      );
      setNextBusinessDays([...next5BusinessDays]);
      setShowDates(true);
    } else if (showDates) setShowDates(false);
  }, [productType, currencyTo]);

  return (
    <BodyCard>
      <ContentDays>
        <p
          style={{
            fontWeight: 500,
            fontSize: 16,
            color: '#20315B',
          }}
        >
          Observação - Data do pedido: {dayjs(orderDate).format('DD/MM/YYYY')}
        </p>
        <CustomizedMessage productType={productType} currencyTo={currencyTo} />
        {showDates && (
          <NextBusinessDaysRow dates={nextBusinessDays} onChange={onChange} />
        )}
      </ContentDays>
    </BodyCard>
  );
};

export default SelectNextBusinessDay;
