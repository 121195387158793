import {
  mapAddressToAutoComplete,
  publicPlaceOptions,
} from '~/components/AutoCompleteAddress/mapAddressToAutoComplete';
import { api } from '~/services/api';

export async function getPublicPlacesOptions(token) {
  try {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    const { data } = await api.get('/lists/address-types');

    return mapAddressToAutoComplete(data);
  } catch (err) {
    return mapAddressToAutoComplete(publicPlaceOptions);
  }
}
