import { Form, Input, Radio, Checkbox } from 'rsuite';
import styled from 'styled-components';
import Button from '../../components/Button';

// AddNewBank
export const DisplayCards = styled.div`
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: 80px;
    height: calc(100vh - 75px);
  }
`;

export const GridCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
  }
`;

export const NewBankCard = styled.div`
  background: #fff;
  width: 296px;
  height: 372px;
  margin: 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  flex-direction: column;
  cursor: pointer;

  h1 {
    color: #09b2e6;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-top: 20px;
    width: 100px;
    text-align: center;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  border: 1.5px dashed #0fc7ff;
  box-sizing: border-box;
  border-radius: 4px;
`;

// NewBank
export const MyForm = styled(Form)`
  display: flex;

  a {
    color: #09b2e6;
    font-size: 12px;
  }

  .active {
    color: #455566;
    text-decoration: none;
  }
`;

export const StyledInput = styled(Input)`
  margin: 5px 0 15px 0;
  height: 50px;
  width: 384px;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #d3dce6;
  padding-left: 10px;
`;

export const StyledTitle = styled.h2`
  color: #20315b;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin: 20px 0;
`;

export const StyledButton = styled(Button)`
  width: 141px;
  height: 48px;
  margin: 35px 0;
  background: linear-gradient(
    98.1deg,
    #48d3fe 28.81%,
    #0fc7ff 52.98%,
    #09b2e6 84.96%
  );
`;

export const Fields = styled.div`
  font-family: IBM Plex Sans;
  font-size: 14px;
  line-height: 20px;
  color: #455566;
`;

export const DocumentFields = styled(Fields)`
  @media screen and (max-width: 600px) {
  }

  @media (min-width: 768px) {
    padding-left: 57px;
  }

  ${(props) => props.withoutPadding && ` padding: 0 !important; `}
`;

export const Title = styled.div`
  margin-bottom: 20px;
  color: #20315b;
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
`;

export const FileUploadBox = styled.div`
  max-width: 400px;
  height: 225px;
  background: #ffffff;
  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  div {
    margin: 33px;
    border: 1.5px dashed #0fc7ff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h1 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      width: 205px;
      color: #09b2e6;
      text-align: center;
      margin-top: 15px;
    }
  }
  input {
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    position: absolute;
  }

  ${(props) =>
    props.small &&
    `
    height: 178px;

    div { 
      margin: 0;
      padding: 22px;
    }

  `}
`;

export const DisplayGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;

  @media (min-width: 768px) {
    padding-top: 50px;
  }

  @media (max-width: 768px) {
    margin-bottom: 70px;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const BackButton = styled.div`
  display: flex;
  color: #0fc7ff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  p {
    margin-left: 3px;
  }

  a {
    text-decoration: none;
    color: #0fc7ff;
  }
`;

export const RadioItem = styled(Radio)`
  margin-top: 10px;
  color: #455566;
  font-size: 16px;
  background: #f9fafc;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 167px;
  height: 50px;
  padding: 7px 8px;
  border: 1px solid #d3dce6;
`;

export const CheckItem = styled(Checkbox)`
  margin-top: 10px;
  color: #455566;
  font-size: 16px;
  background: #f9fafc;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 250px;
  height: 50px;
  padding: 7px 8px;
  border: 1px solid #d3dce6;
`;

export const FormGrid = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    /* max-width: 80%; */
    margin: 30px auto;
  }

  a {
    color: #09b2e6;
    font-size: 12px;
  }

  .active {
    color: #455566;
    text-decoration: none;
  }
`;

export const BankListHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    padding: 40px 0 0 50px;
    max-height: calc(100vh - 80px);
  }

  @media (max-width: 768px) {
    .rs-breadcrumb {
      display: none;
    }
  }

  .step {
    color: #09b2e6;
    font-size: 12px;
  }

  .active {
    color: #455566;
    text-decoration: none;
    font-size: 12px;
  }

  .back-button {
    margin-bottom: 20px;
  }
`;

export const FooterLabel = styled.p`
  margin: 16px 0;
  color: #455566;
  font-size: 14px;
  line-height: 20px;
  font-family: IBM Plex Sans;
`;
