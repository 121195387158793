import { checkQuantityItemsFilled } from '../services/calculate';

export const calculatePercentPFGeneralData = ({
  civilState,
  gender,
  cityBirth,
  stateBirth,
  motherName,
  countryBirth,
}) => {
  return checkQuantityItemsFilled([
    !!civilState,
    !!gender,
    !!cityBirth,
    !!stateBirth,
    !!motherName,
    !!countryBirth,
  ]);
};

export const calculatePercentPFIdentity = ({
  document,
  identificationDocument,
  identificationDocumentType,
  issuingAgency,
  issuingState,
  issuingExpeditionDate,
}) => {
  return checkQuantityItemsFilled([
    !!document,
    !!identificationDocument,
    !!identificationDocumentType,
    !!issuingAgency,
    !!issuingState,
    !!issuingExpeditionDate,
  ]);
};

export const calculatePercentPFProfission = ({
  idProfission,
  companyWhereWorkName,
  monthlyIncome,
  patrimony,
}) => {
  return checkQuantityItemsFilled([
    !!idProfission,
    !!companyWhereWorkName,
    !!monthlyIncome,
    !!patrimony,
  ]);
};

export const calculatePercentPFProfissionProspect = ({
  idProfission,
  monthlyIncome,
  patrimony,
}) => {
  return checkQuantityItemsFilled([
    !!idProfission,
    !!monthlyIncome,
    !!patrimony,
  ]);
};

export const calculatePercentPJGeneralData = ({
  companyFantasyName,
  companyName,
  companyOpenedDate,
  companyEconomicActivity,
  email,
  phoneNumber,
  document,
}) => {
  return checkQuantityItemsFilled([
    !!companyFantasyName,
    !!companyName,
    !!email,
    !!phoneNumber,
    !!document,
    !!companyOpenedDate,
    !!companyEconomicActivity,
  ]);
};

export const calculatePercentPJRequest = ({
  annualIncome,
  annualPatrimony,
}) => {
  return checkQuantityItemsFilled([!!annualIncome, !!annualPatrimony]);
};

export const calculatePercentAddress = ({
  addressZipcode,
  addressStreet,
  addressNumber,
  addressState,
  addressCity,
  addressCountry,
  addressNeighborhood,
}) => {
  return checkQuantityItemsFilled([
    !!addressZipcode,
    !!addressStreet,
    !!addressNumber,
    !!addressState,
    !!addressCity,
    !!addressCountry,
    !!addressNeighborhood,
  ]);
};
