import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 400px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button + button {
    margin-left: 24px;
  }

  button {
    width: 100%;
  }
`;
