/**
 *
 * @param {number} currencyValue - the value you want to convert
 * @param {number} currencyUsdBaseToTax - the currency base tax to transform
 * @returns {number}
 */
export function getUsdValueBase(currencyValue, currencyUsdBaseToTax) {
  return +(+currencyValue / +currencyUsdBaseToTax).toFixed(2);
}

/**
 *
 * @param {number} minUsdValue - the min value allowed
 * @param {number} maxUsdValue - the max value allowed
 * @param {number} currencyValue - the value you want to convert
 * @param {number} currencyUsdBaseToTax - the currency base tax to transform
 * @returns {number};
 */
export function isExchangeValueMinimalToAllowTransaction(
  minUsdValue,
  maxUsdValue,
  currencyValue,
  currencyUsdBaseToTax
) {
  const usdBasedValue = getUsdValueBase(+currencyValue, +currencyUsdBaseToTax);

  return (
    /* usdBasedValue >= +minUsdValue && */ currencyValue >= 1000 &&
    usdBasedValue <= +maxUsdValue
  );
}
