import styled from 'styled-components';
import { Tooltip } from 'rsuite';

export const ContentStatus = styled.div`
  width: 100%;
  min-height: 97px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  @media (width: 1024px) {
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    height: 100%;
    padding-top: 24px;
  }

  @media (max-width: 650px) {
    /* min-height: 72px; */
    padding-bottom: 64px;
  }
`;

export const DisplayStatus = styled.div`
  width: 253px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${(props) =>
    (props.incomplete && '#ffecec') ||
    (props.wait && '#FFFAE2') ||
    (props.complete && '#DFFCE7') ||
    (props.process && '#CAEEFF')};
  border-radius: 50px;

  p {
    font-family: IBM Plex Sans;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #455566;
    margin: 0px 0 0 16px;
  }

  @media (max-width: 650px) {
    /* margin-bottom: 32px; */
  }

  @media (max-width: 375px) {
    /* margin-bottom: 64px; */
  }
`;

export const StatusTitle = styled.span`
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: ${(props) =>
    (props.incomplete && '#ef3131') ||
    (props.wait && '#FFB82E') ||
    (props.complete && '#14CC70') ||
    (props.process && '#20315b')};
`;

export const BodyTooltip = styled(Tooltip)`
  width: 346px;
  height: 96px;
  background-color: #009fd9;
  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 4px;

  font-family: IBM Plex Sans;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #ffffff;

  .rs-tooltip-arrow {
    border-right-color: #009fd9 !important;
  }

  .rs-tooltip-inner {
    max-width: 346px !important;
    padding: 16px 21px;
    color: #fff;
    text-align: start;
    background-color: #009fd9;
    border-radius: 4px;
    overflow-wrap: break-word;
  }
`;
