import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ReactSVG } from 'react-svg';
import { Grid, Row, Col, Breadcrumb } from 'rsuite';
import { ArrowLeft } from 'react-feather';

import isBefore from 'date-fns/isBefore';
import startOfToday from 'date-fns/startOfToday';
import IconDoc from '~/pages/MyData/icons/iconDoc.svg';
import api from '~/services/api';

import Button from '~/components/Button';
import LoggedLayout from '~/components/LoggedLayout';

import { TabsContent } from '../Contracts/style';
import {
  TAvailable,
  //  TApproval,
  TCanceled,
} from '~/pages/ExchangeCardsList/components';

import {
  NoCards,
  StyledLink,
  StyledNav,
  CustomNavItem,
  TabsBody,
  Title,
  TitleHeader,
  SubTitle,
  CustomContent,
  BackButton,
  Spacer,
  ReactSVGCustomDiv,
} from './styles';
import Loading from '~/components/Loading';

const HandleTabs = ({
  tab,
  isLoading,
  cardsAvailable,
  cardsCanceled,
  handleSelectedCard,
}) => {
  return (
    <div>
      {tab === 'available' && (
        <TAvailable
          isLoading={isLoading}
          cards={cardsAvailable}
          handleSelectedCard={handleSelectedCard}
        />
      )}
      {tab === 'canceled' && (
        <TCanceled isLoading={isLoading} cards={cardsCanceled} />
      )}
    </div>
  );
};

function ExchangeCardsList() {
  const { profile } = useSelector((state) => state.user);

  const [cards, setCards] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [_selectedCard, setSelectedCard] = useState({});
  const [selectedTab, setSelectedTab] = useState('available');
  const [waitingCards, setWaitingCards] = useState([]);

  function handleSelectedCard(rowData) {
    setSelectedCard(rowData);
  }

  const getCards = useCallback(async () => {
    try {
      const availableResponse = await api.get(
        `exchange/cards/${profile.gnCambioId}`
      );
      const waitingResponse = await api.get(
        `/exchange/exchange_tickets/status/${profile.gnCambioId}/1?page_number=1&page_size=10&sort=DESC`
      );

      setCards(availableResponse.data);
      setWaitingCards(waitingResponse.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // return toast.error(
      //   'Não é possível exibir os cartões agora. Tente novamente mais tarde.'
      // );
    }
  }, []);

  const checkCardExpirationDate = (currentDate) => {
    if (currentDate === '') return true;

    const month = currentDate.slice(0, 2);
    const year = currentDate.slice(-4);
    const parsedDate = new Date(`${year}/${month}/30`);

    return isBefore(parsedDate, startOfToday());
  };

  useEffect(() => {
    getCards();
  }, []);

  const onSelect = (key) => setSelectedTab(key);

  return (
    <>
      <LoggedLayout>
        {isLoading && <Loading text='Carregando seus cartões...' />}
        <CustomContent>
          <Grid fluid>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col>
                <Breadcrumb style={{ paddingLeft: 0, width: '300px' }}>
                  <Link to='/home'>Home</Link>
                  <Link className='active'>Meus cartões</Link>
                </Breadcrumb>
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col>
                <BackButton className='back-button'>
                  <ArrowLeft />
                  <Link to='/update-data'>
                    <p>Voltar</p>
                  </Link>
                </BackButton>
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col>
                <ReactSVGCustomDiv src='/icons/my-cards.svg' />
              </Col>
              <Col>
                <TitleHeader>Meus cartões</TitleHeader>
              </Col>
            </Row>
            <Row>
              <Col>
                <SubTitle>
                  Tenha acesso a todos os seus cartões pré-pagos adquiridos na
                  Advanced
                </SubTitle>
              </Col>
            </Row>
            <hr />
            <Spacer />
            {!isLoading && !cards.length && !waitingCards.length ? (
              <>
                <Row>
                  <Col>
                    <NoCards>
                      <ReactSVG src='/icons/card.svg' />
                      <Title>Você ainda não possui cartões pré-pagos</Title>

                      <Button
                        style={{ width: '226px', height: '40px' }}
                        href='/checkout'
                      >
                        Solicitar novo cartão pré-pago
                      </Button>
                    </NoCards>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col>
                    <StyledNav
                      appearance='subtle'
                      justified
                      activeKey={selectedTab}
                      onSelect={onSelect}
                    >
                      <StyledNav.Item eventKey='available'>
                        Cartões disponíveis (
                        {
                          cards.filter(
                            (card) => !checkCardExpirationDate(card.expiress)
                          ).length
                        }
                        )
                      </StyledNav.Item>
                      <CustomNavItem eventKey='canceled'>
                        Cartões cancelados/vencidos (
                        {
                          cards.filter((card) =>
                            checkCardExpirationDate(card.expiress)
                          ).length
                        }
                        )
                      </CustomNavItem>

                      <StyledNav.Item eventKey='new'>
                        <StyledLink to='/my-cards/new'>
                          Novo cartão pré-pago
                        </StyledLink>
                      </StyledNav.Item>
                    </StyledNav>
                  </Col>
                </Row>
                <Spacer />
                <Row>
                  <Col>
                    <TabsBody>
                      <HandleTabs
                        tab={selectedTab}
                        isLoading={isLoading}
                        cardsAvailable={cards.filter(
                          (card) => !checkCardExpirationDate(card.expiress)
                        )}
                        cardsCanceled={cards.filter((card) =>
                          checkCardExpirationDate(card.expiress)
                        )}
                        handleSelectedCard={handleSelectedCard}
                      />
                    </TabsBody>
                  </Col>
                </Row>
              </>
            )}
          </Grid>
        </CustomContent>
      </LoggedLayout>
    </>
  );
}

export default ExchangeCardsList;
