import React, { useEffect, useLayoutEffect, useState } from 'react';
import { LogOut, User } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { Button, Dropdown } from 'rsuite';
import styled from 'styled-components';
import { FiBookOpen } from 'react-icons/fi';
import { signOut } from '~/store/modules/auth/actions';
import { isXs } from '~/utils/getViewport';
import MobileMenu from '../MobileMenu';
import { FileUploadingModal } from './FileUploadingModal';
import {
  Container,
  Content,
  Navbar,
  NavbarContentGroup,
  NavbarContentHolder,
  NavbarItemName,
  NavbarList,
  NavbarListItem,
  UserBar,
  UserImageHolder,
  UserName,
  UserNavigation,
} from './styles';

function LoggedLayout({ children, isUploading, uploadProgressFileList }) {
  const dispatch = useDispatch();
  const [personalInfo, setPersonalInfo] = useState('');
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const entrance = useSelector((state) => state.user.profile);
  const user = useSelector((state) => state.user);

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const goToHelp = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    setPersonalInfo(entrance);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', () => setWindowSize(window));

    return () =>
      window.removeEventListener('resize', () => setWindowSize(window));
  }, [windowSize]);

  return (
    <>
      {isUploading && (
        <FileUploadingModal
          windowSize={windowSize}
          uploadProgressFileList={uploadProgressFileList}
        />
      )}

      <div
        style={{
          opacity: isUploading ? 0.3 : 1,
        }}
      >
        <UserBar show={user.showUserbar}>
          <Container className='container-header'>
            <UserNavigation>
              <Dropdown
                id='Dropdown'
                placement='bottomEnd'
                title={
                  <>
                    <UserImageHolder>
                      <User color='#fff' size={22} />
                    </UserImageHolder>
                    <UserName>
                      {user.profile.name} {user.profile.lastName}
                    </UserName>
                  </>
                }
              >
                <Link to='/account'>
                  <Dropdown.Item id='MyAccount' componentClass='div'>
                    Minha conta
                  </Dropdown.Item>
                </Link>
                <Dropdown.Item id='Logout' onClick={handleSignOut}>
                  Sair
                </Dropdown.Item>
              </Dropdown>
            </UserNavigation>

            <Button className='logout-mobile' onClick={handleSignOut}>
              <span className='logout-mobile__title'>Sair</span>
              <LogOut
                size='18'
                color='#20315B'
                style={{ verticalAlign: 'middle' }}
              />
            </Button>
          </Container>
        </UserBar>

        {isXs() ? (
          <MobileMenu />
        ) : (
          <Navbar id='AllNavBar'>
            <NavbarContentGroup>
              <NavbarContentHolder>
                <NavbarList>
                  <NavbarListItem logo>
                    <ReactSVG src='/icons/logo-white.svg' />
                  </NavbarListItem>

                  <NavbarListItem>
                    <Link to='/home'>
                      <ReactSVG src='/icons/home.svg' />
                      <NavbarItemName id='HomeItem'>Home</NavbarItemName>
                    </Link>
                  </NavbarListItem>

                  <NavbarListItem>
                    <Link to='/update-data'>
                      <ReactSVG src='/icons/data.svg' />
                      <NavbarItemName id='DataItem'>Meus dados</NavbarItemName>
                    </Link>
                  </NavbarListItem>

                  {user?.profile?.isRelationship &&
                    user?.profile?.document.length === 11 && (
                      <>
                        <NavbarListItem>
                          <Link to='/exchange-tickets'>
                            <ReactSVG src='/icons/meus-pedidos.svg' />
                            <NavbarItemName id='ExchangeItem'>
                              Meus pedidos
                            </NavbarItemName>
                          </Link>
                        </NavbarListItem>

                        <NavbarListItem svgColor>
                          <Link to='/my-cards'>
                            <ReactSVG
                              src='/icons/my-cards.svg'
                              style={{ fill: '#C0CCDA !important' }}
                            />
                            <NavbarItemName id='DataItem'>
                              Meus cartões
                            </NavbarItemName>
                          </Link>
                        </NavbarListItem>
                      </>
                    )}

                  <NavbarListItem>
                    <a
                      href='https://assinatura.advancedcorretora.com.br/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <ReactSVG src='/icons/contrato.svg' />
                      <NavbarItemName id='ContractItem'>
                        Meus contratos
                      </NavbarItemName>
                    </a>
                  </NavbarListItem>

                  {/* <NavbarListItem>
                    <Link to='/chat'>
                      <ReactSVG src='/icons/chat.svg' />
                      <NavbarItemName id='ChatItem'>Chat</NavbarItemName>
                    </Link>
                  </NavbarListItem> */}
                </NavbarList>
              </NavbarContentHolder>
            </NavbarContentGroup>

            <NavbarContentGroup highlight>
              <NavbarContentHolder>
                <NavbarList>
                  <NavbarListItem>
                    <FiBookOpen size={26} color='#C0CCDA' />
                    <NavbarItemName
                      onClick={() =>
                        goToHelp(
                          'https://mkt.advancedcorretora.com.br/manual-painel-digital'
                        )
                      }
                    >
                      Tutorial
                    </NavbarItemName>
                  </NavbarListItem>

                  <NavbarListItem>
                    <ReactSVG src='/icons/help.svg' />
                    <NavbarItemName
                      onClick={() =>
                        goToHelp('https://advancedcorretora.com.br/contact')
                      }
                    >
                      Central de Ajuda
                    </NavbarItemName>
                  </NavbarListItem>

                  <NavbarListItem>
                    <ReactSVG src='/icons/logout.svg' />
                    <NavbarItemName
                      id='Logout'
                      onClick={handleSignOut}
                      style={{ cursor: 'pointer' }}
                    >
                      Sair
                    </NavbarItemName>
                  </NavbarListItem>
                </NavbarList>
              </NavbarContentHolder>
            </NavbarContentGroup>
          </Navbar>
        )}

        <Content show={user.showUserbar}>{children}</Content>
      </div>
    </>
  );
}

export default LoggedLayout;

export const LayoutContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  margin: 0 15px;

  @media (min-width: 801px) {
    margin: 0 auto;
    padding: 40px 0;
    padding: 0px 0px 0px 16px;
  }

  .input-error {
    display: block;
    color: #ff0000;
    padding-bottom: 4px;
  }

  @media (max-width: 768px) {
    .rs-breadcrumb {
      display: none;
    }

    .back-button {
      display: none;
    }
  }
`;
