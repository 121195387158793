import styled from 'styled-components';
import { StyledInput } from '~/components/styles/form';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    padding-top: 36px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100%;
  min-height: 93vh;

  margin-left: 100px;
  padding: 0 64px;

  @media (max-width: 768px) {
    margin-left: 0px;
    padding: 0 8px;
  }
`;

export const DesktopComponentHeader = styled.div`
  display: none;
  @media only screen and (min-width: 768px) {
    padding: 50px 50px 16px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    a {
      margin: 0 5px;
    }

    button {
      background: transparent;
      color: #09b2e6;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 60px;
    }
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (min-width: 768px) {
    margin-left: 40px;
  }
`;

export const InvalidBanner = styled.span`
  color: #fff !important;
  background-color: red;
  text-align: center;
`;

export const HeaderBar = styled.div`
  padding: 0;

  display: flex;
  flex-direction: column;

  .headerbar-title {
    font-weight: 500;
    font-size: 25px;
    line-height: 34px;
    color: #20315b;
  }

  img {
    margin-right: 8px;
  }

  > div {
    display: flex;
    align-items: center;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    margin-top: 8px;
  }
`;

export const CardDataWrapper = styled.div`
  background-image: ${(props) => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  background-attachment: contain;
  min-height: 167px;
  min-width: 293px;
  border-radius: 16px;
  padding: 10px 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  h3 {
    font-size: 20px;
    color: #fff;
  }

  > div {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: flex-start;
  }

  span {
    color: #fff;
    font-weight: 700;
  }

  .client {
    font-size: 12px !important;
    max-width: 160px;
  }
`;

export const CardRechargeInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 24px;

  padding: 16px;

  div {
    div {
      width: 200px !important;

      @media (max-width: 768px) {
        width: 180px !important;
      }
    }
  }

  h4 {
    color: #20315b;
    font-weight: 500;
    font-size: 20px;

    margin-bottom: 24px;
  }

  > div {
    display: flex;
  }

  ${StyledInput} {
    margin: 0 !important;
  }

  .rs-form-group + .rs-form-group {
    margin-left: 32px;
  }
`;

export const CardTaxInfo = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ConfirmButton = styled.button`
  background-image: linear-gradient(
    98.1deg,
    #48d3fe 28.81%,
    #0fc7ff 52.98%,
    #09b2e6 84.96%
  );
  width: 100%;
  border-radius: 500px;
  color: #fff;
  padding: 10px 16px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin: 32px auto;
`;

export const TaxInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 0 16px;
  border-bottom: 1px solid #eff2f7;

  strong {
    color: #20315b;
    font-weight: bold;
    font-size: 14px;
  }

  span {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #20315b;
  }
`;

// SELECT COM FLAG

export const SelectHolder = styled.div`
  min-width: 190px;
  margin-right: 16px;
  border: 1px solid #d3dce6;
  border-radius: 5px;
  background: #ffffff;
  position: relative;
  display: flex;
  align-items: center;

  &::after {
    content: '>';
    position: absolute;
    top: 6px;
    left: 170px;
    font-size: 20px;
    color: #9ba8b7;
    transform: rotate(90deg);
    pointer-events: none;
  }
`;

export const Select = styled.select`
  width: 180px;
  height: 40px;
  border: none;
  border-radius: 5px;
  appearance: none;
  -webkit-appearance: none;
  font-size: 15px;
  color: #20315b;
  padding-left: 8px;

  &::after {
    content: '>';
  }
`;

export const BoxTimer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 0 32px 0;

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;

    padding: 32px 0;
  }
`;
