export const checkQuantityItemsFilled = (items) => {
  const checkQuantity = items.filter((item) => item === true);

  const totalQuantity = items.length;
  const currentQuantity = checkQuantity.length;

  return getPercentage(totalQuantity, currentQuantity);
};

export const getPercentage = (totalValue, currentValue) => {
  return Math.round((currentValue * 100) / totalValue);
};
