import React from 'react';
import { CircleFlag } from 'react-circle-flags';

const flagCodes = {
  BRL: ['br', 'Real'],
  USD: ['us', 'Dólar americano'],
  BPUSD: ['us', 'Dólar americano'],
  MCUSD: ['us', 'Dólar americano'],
  CAD: ['ca', 'Dólar canadense'],
  BPCAD: ['ca', 'Dólar canadense'],
  NZD: ['nz', 'Dólar neozelandês'],
  BPNZD: ['nz', 'Dólar neozelandês'],
  AUD: ['au', 'Dólar australiano'],
  BPAUD: ['au', 'Dólar australiano'],
  ILS: ['il', 'Shekel israelense'],
  GBP: ['gb', 'Libra esterlina'],
  BPGBP: ['gb', 'Libra esterlina'],
  MCGBP: ['gb', 'Libra esterlina'],
  EUR: ['euro', 'Euro', '/icons/uniao-europeia.png'],
  BPEUR: ['euro', 'Euro', '/icons/uniao-europeia.png'],
  MCEUR: ['euro', 'Euro', '/icons/uniao-europeia.png'],
  VSEUR: ['euro', 'Euro', '/icons/uniao-europeia.png'],
  DKK: ['dk', 'Coroa dinamarquesa'],
  SEK: ['se', 'Coroa sueca'],
  NOK: ['no', 'Coroa norueguesa'],
  CHF: ['ch', 'Franco suíço'],
  CLP: ['cl', 'Peso chileno'],
  JPY: ['jp', 'Iene'],
  MXN: ['mx', 'Peso mexicano'],
  ARS: ['ar', 'Peso argentino'],
  UYU: ['uy', 'Peso uruguaio'],
  ZAR: ['za', 'Rand sul-africano'],
  CNY: ['cn', 'Yuan chinês'],
};

export const Flags = ({ code }) => {
  const currentFlag = Object.keys(flagCodes).find((el) => el === code);

  return currentFlag && flagCodes[currentFlag].length >= 3 ? (
    <img
      title={flagCodes[code] && flagCodes[code][1]}
      alt={flagCodes[code] && flagCodes[code][1]}
      src={flagCodes[code] && flagCodes[code][2]}
      height={25}
    />
  ) : (
    <CircleFlag
      countryCode={(flagCodes[code] && flagCodes[code][0]) || ''}
      title={(flagCodes[code] && flagCodes[code][1]) || 'Moeda não encontrada'}
      height={25}
    />
  );
};
