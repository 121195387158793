import React from 'react';
import { api } from '~/services/api';

export async function postProfession(
  userId,
  professionId,
  company,
  salary,
  patrimony
) {
  const data = {
    idProfission: professionId,
    companyWhereWorkName: company,
    monthlyIncome: salary,
    patrimony,
  };

  return await api.put(`/register/users/profission`, data);
}
