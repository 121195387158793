import React from 'react';
import { Header, Footer, Content, CardWrapper } from './styles';

export default (
  {
    titleLink,
    title,
    actions,
    children,
    md,
    lg,
    lw,
    hg,
    alt,
    med,
    low,
    auto,
    full = true,
    style,
  },
  props
) => (
  <CardWrapper
    {...props}
    full={full}
    md={md}
    hg={hg}
    lg={lg}
    lw={lw}
    alt={alt}
    automatic={auto}
    med={med}
    low={low}
    style={style}
  >
    {(title || titleLink) && (
      <Header>
        <h1>{title}</h1>
      </Header>
    )}
    <Content>{children}</Content>
    <Footer>{actions}</Footer>
  </CardWrapper>
);
