import Axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const BEARER_TOKEN =
  'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI2aUZsWUZtTmlSN3lwWlBaNEZ0SVZ4a0RtbUp1OUZmTUN3LURHQlhhOXRzIn0.eyJleHAiOjE2NzQ1MzkwODYsImlhdCI6MTY3NDUyNDY4NiwianRpIjoiOGU0YjcyZjktNGFlYi00ZDVmLTg4MmQtMDllNDgzYTExZWNiIiwiaXNzIjoiaHR0cDovL2FwcC1wLXB1YmxpLW4ybzVuZDc3djA2ZC0yMDc5MzMwODQ3LnVzLXdlc3QtMi5lbGIuYW1hem9uYXdzLmNvbS9hdXRoL3JlYWxtcy9hZHZhbmNlZC1yZWFsbSIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiJmODUxMTdkZS1hMTZkLTQ5YjQtOGU0NC00YTYyMmQyYzcxY2EiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJyZWdpc3Rlci1zZXJ2aWNlIiwic2Vzc2lvbl9zdGF0ZSI6IjVkZTdlYWFhLTMzMDMtNDBlNC05NDZlLWMxMDIzMWFmMjY3MCIsImFjciI6IjEiLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiIsImRlZmF1bHQtcm9sZXMtYWR2YW5jZWQtcmVhbG0iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicHJlZmVycmVkX3VzZXJuYW1lIjoiMDM0MTI0NzU1MzAifQ.iqvCulogSwvfor8GPWR2KTJznkExlD6w_62BuFHWqxcjL-4ob5oHxfG1lzbstIBfJw64WBE4I_0mo1JadY3zBrEroak50VDCa5X-GPR0W5AAS0Z6dtIDtyqcAR21ZgwETmthSk0Y-XswKJN_y1eYLyJOdG5ipAtJNBG0Q-POxsx5nVML3M4QChD9E1ELf__bX_uA3gWW7nP8sPTUYn_13yyiEijBZIIEhEixTN4NStmd9ngPw2-SIGoQMmMC_syy6e5aylXFUdULInbdfQ9yDNY2H4CVN7YLJ65h8aVGTsVkXns1kxxYnXT-JWWKCKbWm-nj09Wq45cE84-fcW4MPA';

export async function getLastUSDQuotation() {
  //   return axios.get('https://api.exchangerate-api.com/v4/latest/USD');
  return (await Axios.get('https://economia.awesomeapi.com.br/last/USD-BRL'))
    .data;
}

export async function getLastUSDTourismQuotation() {
  // get the last quotation of tourism USD
  return (await Axios.get('https://economia.awesomeapi.com.br/last/USDT')).data;
}

export async function getCommercialQuotations(params) {
  Object.assign(params, {
    productId: '654a66bd0bea04d2a74836ff',
    fromIsoCode: 'USD',
    toIsoCode: 'BRL',
    value: 1,
    lockTax: false,
    ...params,
  });

  const response = await Axios.post(
    `${BASE_URL}/exchange/coins/quotations`,
    params,
    {
      headers: {
        Authorization: `Bearer ${BEARER_TOKEN}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return await response.data;
}

export async function getTourismQuotations(params) {
  Object.assign(params, {
    productId: '656e2221fc6788b5a0ce6124',
    fromIsoCode: 'USD',
    toIsoCode: 'BRL',
    value: 1,
    lockTax: false,
    ...params,
  });

  const response = await Axios.post(
    `${BASE_URL}/exchange/coins/quotations`,
    params,
    {
      headers: {
        Authorization: `Bearer ${BEARER_TOKEN}`,
        'Content-Type': 'application/json',
      },
    }
  );

  return await response.data;
}

export const transformNumberInMoney = ({ number = 0, currency = 'BRL' }) => {
  if (!number) {
    return;
  }

  const numberString = number.toString();

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency,
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  }).format(numberString);
};
