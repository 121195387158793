import React from 'react';
import { Table } from 'rsuite';

import { ProductFlags } from '../../../utils/flags';

class TableCellValue extends React.Component {
  rowData;

  dataKey;

  props;

  productCode;

  constructor(props) {
    super(props);
    this.rowData = props.rowData;
    this.dataKey = props.dataKey;
    this.props = props;
  }

  getValue() {
    this.productCode = this.rowData.code_product;
    const foreignValue = this.rowData.foreign_currency_value;
    const nationalValue =
      this.rowData.nacional_currency_value + this.rowData.iof;
    const formattedNationalValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(nationalValue);
    let formattedForeignValue;
    try {
      const index = this.productCode.indexOf('USD');
      if (index > -1) this.productCode = 'USD';
      formattedForeignValue = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: this.productCode.replace('BP', ''),
        unitDisplay: 'long',
      }).format(foreignValue);
    } catch (error) {
      formattedForeignValue = foreignValue ? foreignValue?.toFixed(2) : 0;
    }

    return `${formattedForeignValue} > ${formattedNationalValue}`;
  }

  render() {
    return (
      <Table.Cell {...this.props}>
        <div style={{ display: 'inline-flex' }}>
          <ProductFlags code={this.productCode} />
          <div style={{ marginLeft: '10px' }} />
          <span
            style={{
              color: '#132552',
              fontWeight: 'bolder',
              fontSize: '16px',
              lineHeight: '24px',
              fontStyle: 'normal',
              fontFamily: 'IBM Plex Sans',
            }}
          >
            {this.getValue()}
          </span>
        </div>
      </Table.Cell>
    );
  }
}

export default TableCellValue;
