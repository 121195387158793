import { api } from '~/services/api';

export async function validatePermanent(userId) {
  await api.put(`/register/users/validate-permanent`);
}

export async function registerUser(data) {
  await api.post('/users', {
    name: data.name,
    lastName: data.lastname,
    company: data.company,
    document: data.document.match(/\d+/g).join([]),
    email: data.email,
    referral: data.referralParam,
    dateBirth: data.date,
    phoneCountryCode: '55',
    phoneAreaCode: data.ddd,
    phoneNumber: data.phone,
  });
}
