import { format, parseISO } from 'date-fns';
import {
  //  Download,
  AlertCircle,
  //  CreditCard,
  ArrowRight,
} from 'react-feather';
import React from 'react';
import { Flags } from '~/components/Flags';
import SelectNextBusinessDay from '../../../components/SelectNextBusinessDay';
import Range from './range';
import {
  //  DetailsTrackingCode,
  DetailsCard,
  DetailsCardContainer,
  DetailsCardSmallContainer,
  DetailsContainer,
  //  DetailsPDF,
  DetailsTracking,
  DetailsTrackingContentItem,
  Title,
} from './styles';

export const TableOfExchangeTicketsDetails = ({ data }) => {
  const {
    ticket_id,
    sale_date,
    foreign_currency_value,
    nacional_currency_value,
    client_delivery_address,
    code_count,
    code_product,
    tax_value,
    iof,
    status_id,
    product_type,
  } = data;

  const { zipcode, address, complement, city, state, number, neighborhood } =
    client_delivery_address;

  return (
    <>
      <DetailsContainer>
        <div>
          <Title>
            Cartão de viagem{' '}
            {status_id === 4 && (
              <small style={{ color: 'red', fontSize: 22 }}>Cancelado</small>
            )}
          </Title>
          <span style={{ color: '#9BA8B7' }}>Operação #{ticket_id}</span>
        </div>
        <div>
          <p style={{ color: '#455566', fontSize: 14 }}>Pedido realizado em:</p>
          <p style={{ color: '#000', fontWeight: 500 }}>
            {format(new Date(parseISO(sale_date)), `dd/MM/yyyy 'às' HH:mm`)}
          </p>
        </div>
        {/* <div>
          <p style={{ color: '#455566', fontSize: 14 }}>Recibo do pedido:</p>
          <p
            style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
            onClick={() => alert('Baixando PDF...')}
          >
            <Download size={24} color='#09B2E6' />{' '}
            <DetailsPDF>Baixar PDF</DetailsPDF>
          </p>
        </div> */}
      </DetailsContainer>
      {status_id !== 4 && (
        <DetailsTracking>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: 24,
              paddingBottom: 24,
            }}
          >
            <DetailsTrackingContentItem>
              Acompanhar pedido
            </DetailsTrackingContentItem>
            {/* <DetailsTrackingCode>
            <DetailsTrackingContentItem
              style={{ fontSize: 12, fontWeight: 'bold' }}
            >
              Rastreio correios: QE993250100BR
            </DetailsTrackingContentItem>
          </DetailsTrackingCode> */}
          </div>

          <Range data={data} />
        </DetailsTracking>
      )}

      <DetailsCardContainer>
        <DetailsCard width={407} height={318}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 15,
              paddingLeft: 30,
              paddingRight: 40,
            }}
          >
            <span style={{ color: '#9BA8B7', fontSize: 12, fontWeight: 700 }}>
              VALOR SOLICITADO
            </span>
            <span style={{ color: '#9BA8B7', fontSize: 12, fontWeight: 700 }}>
              VALOR CONVERTIDO
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: 25,
              paddingRight: 25,
            }}
          >
            <span>
              <Flags code={code_product} />{' '}
              <span style={{ fontWeight: 500, fontSize: 18 }}>
                ${' '}
                {foreign_currency_value.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
              </span>
            </span>
            <span>
              <ArrowRight color='#D3DCE6' size={24} />
            </span>
            <span>
              <Flags code='BRL' />{' '}
              <span style={{ fontWeight: 500, fontSize: 18 }}>
                {nacional_currency_value.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                })}
              </span>
            </span>
          </div>
          <div
            style={{
              border: '0.5px solid #E5E9F2',
              marginTop: 25,
              marginLeft: 5,
              marginRight: 15,
              marginBottom: 21,
            }}
          />
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: 25,
                paddingRight: 25,
                marginBottom: 18,
              }}
            >
              <div>
                <AlertCircle color='#0FC7FF' size={15} /> Valor da taxa
              </div>
              <div>
                {tax_value.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                })}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: 25,
                paddingRight: 25,
                marginBottom: 18,
                marginBottom: 18,
              }}
            >
              <div>
                <AlertCircle color='#0FC7FF' size={15} /> +IOF (1.10%)
              </div>
              <div>
                {iof.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                })}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: 25,
                paddingRight: 25,
                marginBottom: 18,
              }}
            >
              <div>
                <AlertCircle color='#0FC7FF' size={15} />{' '}
                <span>Valor da entrega</span>
              </div>
              <div style={{ color: '#14CC70' }}>Gratuito</div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: 25,
                paddingRight: 25,
                marginBottom: 18,
              }}
            >
              <div>
                <AlertCircle color='#0FC7FF' size={15} /> VET
              </div>
              <div>
                {(nacional_currency_value + iof).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                })}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: 25,
                paddingRight: 25,
              }}
            >
              <div>
                <AlertCircle color='#0FC7FF' size={15} />{' '}
                <span style={{ fontWeight: 'bold' }}>Valor a pagar=</span>
              </div>
              <div>
                {(nacional_currency_value + iof).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                })}
              </div>
            </div>
          </div>
        </DetailsCard>
        <DetailsCardSmallContainer>
          <DetailsCard width={269} height={160}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 10,
              }}
            >
              <p style={{ marginBottom: 8, color: '#000' }}>
                Endereço de entrega
              </p>
              <span>CEP {zipcode}</span>
              <span>
                {address}, {number} - {neighborhood}
              </span>
              <span>{complement}</span>
              <span>
                {city}, {state} - Brasil
              </span>
            </div>
          </DetailsCard>
          <DetailsCard width={269} height={134}>
            <div>
              <div
                style={{
                  display: 'flex',
                  padding: 10,
                  justifyContent: 'space-between',
                }}
              >
                <p style={{ color: '#000' }}>Pagamento</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: 10,
                  justifyContent: 'space-between',
                }}
              >
                {code_count ? (
                  <span style={{ fontWeight: 'bold' }}>
                    {code_count.substring(3)}
                  </span>
                ) : (
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    Não efetuado
                  </span>
                )}

                {status_id !== 4 && status_id > 2 ? (
                  <span
                    style={{
                      color: 'green',
                      fontWeight: 'bold',
                    }}
                  >
                    Total:{' '}
                    {(nacional_currency_value + iof).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                    })}
                  </span>
                ) : (
                  <span
                    style={{
                      color: 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    Total:{' '}
                    {(nacional_currency_value + iof).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                    })}
                  </span>
                )}
              </div>

              <div
                style={{
                  display: 'flex',
                  padding: 10,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {/* Limitação da Vuori? */}
                {/* <span>
                  ****9245 <CreditCard size={15} />
                </span>
                <span>4x R$ 2.166,80</span> */}
              </div>
            </div>
          </DetailsCard>
        </DetailsCardSmallContainer>
      </DetailsCardContainer>
      <SelectNextBusinessDay
        style={{ marginTop: 20 }}
        orderDate={sale_date}
        productType={product_type}
        currencyTo={code_product}
      />
    </>
  );
};
