import styled from 'styled-components';

export const StyledBox = styled.div`
  .rs-progress-line {
    font-size: 0;
    padding-right: 0;
  }

  .rs-progress-info {
    display: none;
  }
`;

export const TimerHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Timer = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #09b2e6;
  margin-left: 4px;
`;
