import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  .brasil {
    margin-right: -11px;
  }

  .euro {
    height: 27px;
    margin-left: -7px;
  }

  .advanced {
    height: 30px;
    margin-left: 2px;
    margin-top: -3px;
  }
`;
