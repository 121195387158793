import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { GlobalStyle, Theme } from '~/components/styles';

import Routes from './routes';
import history from './services/history';

import { persistor, store } from './store';

import 'react-toastify/dist/ReactToastify.css';
import 'rsuite/dist/styles/rsuite-default.css';

import ChangedDataModal from './components/ChangedDataModal';
import ScrollToTop from './utils/scrollToTop';

import ModalBlock from './components/ModalBlock';
import Terms from './components/Terms';

import termsApi from './services/termsApi';
import cpfMask from './mask/cpf';
import cnpjMask from './mask/cnpj';

const GTMControl = () => {
  const tagManagerArgs = {
    gtmId: 'GTM-KVVVG9N', // TODO passar para env
  };
  TagManager.initialize(tagManagerArgs);
  ReactGA.initialize('UA-158625470-2');
  ReactGA.pageview(window.location.pathname + window.location.search);
};

GTMControl();

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <ThemeProvider theme={Theme}>
            <ScrollToTop />
            <GlobalStyle />
            <Routes />
            <ToastContainer autoClose={4000} />
            <ChangedDataModal />
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
