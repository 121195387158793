import produce from 'immer';

const INITIAL_STATE = {
  showModal: true,
};

export default function partnersList(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@showRegisterPJModal/ChangeVisibility': {
        draft.showModal = action.payload;
        break;
      }

      default:
        break;
    }
  });
}
