import styled from 'styled-components';
import { Table as RTable, Button, Input, Loader } from 'rsuite';

const { HeaderCell: RHeaderCell } = RTable;

export const HeaderCell = styled(RHeaderCell)`
  color: #455566;
`;

export const Table = styled(RTable)`
  .spanColor {
    color: #20315b;
  }

  .rs-table-cell {
    border-color: #ff0000 !important;
  }

  .rs-table-cell-content {
    color: #20315b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .rs-table-cell-header {
    .rs-table-cell-content {
      font-family: IBM Plex Sans;
      font-weight: normal;
      padding-top: 32px;
      font-size: 14px;
      line-height: 16px;
      color: #455566;
    }
  }

  .rs-table-row {
    border-bottom-color: #d3dce6;
  }
`;

export const Status = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4.5px 16px;
  width: 160px;
  height: 24px;
  background: #dffce7;
  border-radius: 5px;

  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #14cc70;
`;

export const BtnContent = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 16px;
  padding: 0 8px 0 0;
`;

export const BtnContentModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 24px;
`;

export const BtnCancel = styled(Button)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #09b2e6;
  padding-top: 24px;
`;

export const ContentModal = styled.div`
  width: 557px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TitleModal = styled.h3`
  padding-top: 20px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #20315b;
`;

export const SubTitleModal = styled.p`
  width: 338px;
  padding-top: 16px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #232b33;
`;

export const SubTitleResultModal = styled.h5`
  margin-top: 8px;
  margin-bottom: 56px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #20315b;
`;

export const InputSign = styled(Input)`
  width: 373px;
  height: 64px;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  color: #34404d;
`;

export const TitleLoader = styled.h4`
  margin-top: 32px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #20315b;
`;

export const LoaderSign = styled(Loader)`
  margin-bottom: 24px;

  .rs-loader-spin::after {
    border-width: 4px;
    border-style: solid;
    -webkit-animation: loaderSpin 0.6s infinite linear;
    animation: loaderSpin 0.6s infinite linear;
    border-color: #0fc7ff transparent transparent;
  }
`;

export const PaginationHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
`;

export const PaginationButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
`;

export const PaginationLabel = styled.span`
  font-size: 16px;
  padding: 0 8px;
  color: #09b2e6;
`;
