import React, { useEffect, useState } from 'react';
import { Copy } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import IconSuccess from '~/assets/icon-success.svg';
import { transformNumberInMoney } from '~/utils/transformNumberInMoney';
import Button from '../Button';
import {
  CodeArea,
  Content,
  Flag,
  Info,
  InfoTitle,
  ListButtons,
  StepContent,
  StepNumber,
  Steps,
  StepsItem,
  StyledBox,
  StyledButton,
  Title,
} from './styles';

import history from '~/services/history';
import {
  endTime,
  updateTicket,
  updateTicketNewStatus,
} from '~/store/modules/exchange/actions';
import { calcutateCheckoutValues } from '../../utils/calculateCheckoutValues';

const PixPayment = () => {
  const buttonTitle = {
    COPY_CODE: 'Copiar código Pix',
    COPIED_CODE: 'Código copiado',
  };

  const [title, setTitle] = useState('');
  const [flag, setFlag] = useState(
    <>
      <img src={IconSuccess} /> Pedido realizado
    </>
  );
  const [titleButton, setTitleButton] = useState(buttonTitle.COPY_CODE);
  const stepsPayment = [
    'Abra o app do seu banco',
    'Selecione pagar com Pix',
    'Ler/escanear QR code',
    'Aponte a câmera do celular',
    'Em caso de dúvidas, entre em contato conosco via WhatsApp <a target="__blank" href="https://api.whatsapp.com/send?phone=5511998656710&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20com%20meu%20pedido">(11) 99865-6710</a>',
  ];
  const dispatch = useDispatch();
  const { exchange } = useSelector((state) => state);

  const { totalValue } = calcutateCheckoutValues(exchange);

  const copyCode = () => {
    setTitleButton(buttonTitle.COPIED_CODE);
    navigator.clipboard.writeText(exchange.ticket?.CopiaECola);

    setTimeout(() => {
      setTitleButton(buttonTitle.COPY_CODE);
    }, 5000);
  };

  useEffect(() => {
    setTitle(
      `Pronto, aqui está o seu Pix no valor de <strong>${transformNumberInMoney(
        {
          currency: 'BRL',
          number: totalValue,
        }
      )}</strong>`
    );
  }, []);

  useEffect(() => {
    dispatch(updateTicket({ data: { ticket_id: null } }));
    dispatch(updateTicketNewStatus(false));
    dispatch(endTime());
  }, []);

  return (
    <StyledBox>
      <Flag>{flag}</Flag>
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <Content>
        <CodeArea>
          <div>
            <img
              style={{ width: 214, maxWidth: '100%' }}
              src={`data:image/jpeg;charset=utf-8;base64,${exchange.ticket.QRCodeEmBase64}`}
            />
          </div>
          <ListButtons>
            <StyledButton
              onClick={copyCode}
              style={{ background: '#0FC7FF', color: '#fff' }}
            >
              <Copy size={22} color='#fff' /> {titleButton}
            </StyledButton>
          </ListButtons>
        </CodeArea>
        <Info>
          <InfoTitle>Para pagar com Pix, siga o passo-a-passo</InfoTitle>
          <Steps>
            {stepsPayment.map((item, index) => (
              <StepsItem>
                <StepNumber>{index + 1}</StepNumber>
                <StepContent dangerouslySetInnerHTML={{ __html: item }} />
              </StepsItem>
            ))}
          </Steps>
          <Button onClick={() => history.push('/exchange-tickets')}>
            Ir para meus pedidos
          </Button>
        </Info>
      </Content>
    </StyledBox>
  );
};

export default PixPayment;
