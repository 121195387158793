import PropType from 'prop-types';
import React from 'react';
import CheckIcon from '~/assets/simple-check.svg';
import { DoneStep, Step } from './styles';

export default class WizardStep extends React.Component {
  render() {
    return (
      <>
        <div style={{ marginRight: 20 }}>
          {this.props.active === true ? (
            <Step active>{this.props.stepLabel}</Step>
          ) : (
            <DoneStep>
              <img src={CheckIcon} alt='Check' />
            </DoneStep>
          )}
          <span className='step-title'>{this.props.label}</span>
        </div>
      </>
    );
  }
}

WizardStep.propTypes = {
  label: PropType.string.isRequired,
  stepLabel: PropType.string,
  active: PropType.bool,
};
