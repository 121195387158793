import React, { useEffect, useState } from 'react';

import { Col, Grid, Row } from 'rsuite';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import MessagePayment from '../MessagePayment';
import {
  getProducts,
  getTax,
  resetProductSelected,
  selectProduct,
  updateProductValue,
} from '../../store/modules/exchange/actions';
import { transformNumberInMoney } from '../../utils/transformNumberInMoney';
import {
  ArrowContainer,
  Container,
  ErrorMessage,
  LoaderCustom,
  Loader,
} from './styles';
import ExchangeMoneyCalculatorTaxInformation from './TaxInformation';
import { isExchangeValueMinimalToAllowTransaction } from '~/utils/getUsdValueBase';
import { ReactComponent as ArrowIcon } from './icons/arrowIcon.svg';
import CurrencyBy from './CurrencyBy';
import CurrencyTo from './CurrencyTo';

const ExchangeMoneyCalculator = ({ inline, onCalculate }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { productList, selectedProduct, customTaxes } = useSelector(
    (state) => state.exchange
  );

  const [errors, setErrors] = useState([]);
  const [selectedTax, setSelectedTax] = useState({});

  const selectedProductId = selectedProduct?.productId;
  const selectedProductType = selectedProduct?.productType;

  const filteredProductList = productList.filter((e) => {
    if (selectedProduct.productType === 'CARTAO') {
      return (
        e.productTypeDescription === 'CARTAO' &&
        e.currencyDescription.includes('ADVANCED')
      );
    }
    if (selectedProduct.productType === 'ESPECIE') {
      return (
        e.productTypeDescription === 'ESPECIE' &&
        e.currencyDescription.includes('ESPECIE')
      );
    }
    return false;
  });

  const [maskedValueBy, setValBy] = useState('0,00');
  const [maskedValueTo, setValTo] = useState('0,00');

  const [numberValueBy, setNumberValBy] = useState(0);
  const [numberValueTo, setNumberValTo] = useState(0);

  let finalValue;
  const maskedValueFormatted = maskedValueTo.replace('.', '').replace(',', '.');
  const multiple =
    Math.ceil(maskedValueFormatted / selectedProduct.virtualStoreMinValue) *
    selectedProduct.virtualStoreMinValue;

  if (multiple > maskedValueFormatted) {
    finalValue = multiple - selectedProduct.virtualStoreMinValue;
  }

  const currencyBy = [
    {
      label: 'BRL',
      value: 'BRL',
      tax: 1,
    },
  ];

  useEffect(() => {
    dispatch(getProducts());
    dispatch(resetProductSelected());
  }, [selectedProductType]);

  useEffect(() => {
    selectDefaultCurrencyTo();
  }, [filteredProductList]);

  useEffect(() => {
    if (selectedProductId) dispatch(getTax(selectedProductId));
  }, [selectedProductId]);

  useEffect(() => {
    // setLoading(true)
    if (selectedProductId && customTaxes.length > 0) {
      const currentTax = customTaxes.find(
        (item) => item.currency === selectedProductId
      );
      setSelectedTax(currentTax);
      setLoading(false);
    }
  }, [customTaxes]);

  useEffect(() => {
    dispatch(updateProductValue(numberValueBy, numberValueTo));
  }, [numberValueBy, numberValueTo]);

  useEffect(() => {
    validate();
  }, [numberValueBy, numberValueTo]);

  useEffect(() => {
    if (selectedProductType === 'ESPECIE') {
      resetValues();
    } else if (selectedProductType === 'CARTAO') {
      resetValues();
    }
  }, [selectedProductType]);

  const maxValueBy = 1000000000;
  const minUsdValueToValidationCard = 500;
  const minUsdValueToValidation = 1000;
  const maxUsdValueToValidation = 3000;
  const { currencyUsdBaseToTax } = selectedProduct;

  const allowedTransaction = isExchangeValueMinimalToAllowTransaction(
    minUsdValueToValidation,
    maxUsdValueToValidation,
    numberValueBy,
    currencyUsdBaseToTax
  );

  const baseValue = +(+numberValueBy / +currencyUsdBaseToTax).toFixed(2);

  const validationSchema = Yup.object().shape({
    numberValueTo: Yup.string()
      .required('Obrigatório')
      .test(
        'min_value',
        `Sua solicitação de ${
          numberValueTo > 0 &&
          numberValueTo !== null &&
          numberValueTo !== undefined
            ? transformNumberInMoney({
                number: numberValueTo,
                currency:
                  selectedProduct?.currencyTo === ''
                    ? 'EUA'
                    : selectedProduct?.currencyTo,
              })
            : transformNumberInMoney({
                number: '0.00',
                currency: 'BRL',
              })
        } é menor que o limite mínimo de ${transformNumberInMoney({
          number: minUsdValueToValidation,
          currency: 'BRL',
        })} para esse tipo de operação.`,
        () => {
          if (numberValueBy && selectedProduct.productType === 'ESPECIE')
            return numberValueBy >= minUsdValueToValidation;
          return true;
        }
      )
      .test(
        'multiple_value',
        `Moeda vendida em valores múltiplos de ${transformNumberInMoney({
          number: selectedProduct?.virtualStoreMinValue,
          currency:
            selectedProduct?.currencyTo === ''
              ? 'EUA'
              : selectedProduct?.currencyTo,
        })}. Por favor altere o valor para ${transformNumberInMoney({
          number: finalValue,
          currency:
            selectedProduct?.currencyTo === ''
              ? 'EUA'
              : selectedProduct?.currencyTo,
        })}.`,
        () => {
          if (finalValue) return numberValueTo === finalValue;
          return true;
        }
      )
      .test(
        'max_value',
        `Sua solicitação de ${transformNumberInMoney({
          number: numberValueTo,
          currency:
            selectedProduct?.currencyTo === ''
              ? 'EUA'
              : selectedProduct?.currencyTo,
        })} excedeu o limite estabelecido de ${transformNumberInMoney({
          number: maxUsdValueToValidation,
          currency: 'USD',
        })}. Para operações de maior valor, entre com contato com o suporte.`,
        () => {
          if (numberValueTo && selectedProduct.productType === 'ESPECIE')
            return allowedTransaction;
          return true;
        }
      )
      .test(
        'min_value',
        `Sua solicitação de ${
          numberValueTo > 0 &&
          numberValueTo !== null &&
          numberValueTo !== undefined
            ? transformNumberInMoney({
                number: numberValueTo,
                currency:
                  selectedProduct?.currencyTo === ''
                    ? 'EUA'
                    : selectedProduct?.currencyTo,
              })
            : transformNumberInMoney({
                number: '0.00',
                currency: 'BRL',
              })
        } é menor que o limite mínimo de ${transformNumberInMoney({
          number: minUsdValueToValidationCard,
          currency: 'BRL',
        })} para esse tipo de operação.`,
        () => {
          if (numberValueBy && selectedProduct.productType === 'CARTAO')
            return numberValueBy >= minUsdValueToValidationCard;
          return true;
        }
      )
      .test(
        'max_value',
        `Sua solicitação de ${transformNumberInMoney({
          number: numberValueTo,
          currency:
            selectedProduct?.currencyTo === ''
              ? 'EUA'
              : selectedProduct?.currencyTo,
        })} excedeu o limite estabelecido de ${transformNumberInMoney({
          number: maxUsdValueToValidation,
          currency: 'USD',
        })}. Para operações de maior valor, entre com contato com o suporte.`,
        () => {
          if (numberValueTo && selectedProduct.productType === 'CARTAO')
            return baseValue <= maxUsdValueToValidation;
          return true;
        }
      ),
  });

  const resetValues = () => {
    setValBy('0,00');
    setValTo('0,00');
    setNumberValBy(0);
    setNumberValTo(0);
  };

  const onCurrencyToSelected = async (value) => {
    setLoading(true);
    const selectedCurrencyCode = value.target.value;
    const selectedCurrency = filteredProductList.filter(
      (item) => item.productId === selectedCurrencyCode
    )[0];
    resetValues();
    dispatch(
      selectProduct(
        selectedCurrency?.productId,
        selectedCurrency?.groupDescription,
        selectedCurrency?.iof,
        selectedCurrency?.virtualStoreMinValue
      )
    );
  };

  const validate = () => {
    validationSchema
      .validate({ numberValueTo, numberValueBy })
      .then(() => {
        // onCalculate([]);
        return setErrors([]);
      })
      .catch((error) => {
        // onCalculate(error.errors);
        return setErrors(error.errors);
      });
  };

  const handleChangeValueBy = (value) => {
    let valueBy = value;
    let valueByNumber =
      typeof valueBy === 'string'
        ? Number(value.replaceAll('.', '').replaceAll(',', '.'))
        : valueBy;

    if (valueByNumber <= 0) {
      valueBy = '';
      valueByNumber = 0;
    }

    if (valueByNumber > maxValueBy) {
      valueByNumber = maxValueBy;
      valueBy = maxValueBy;
    }

    const valueTo = valueBy
      ? (valueByNumber / Number(selectedTax.tax?.sugest_price)).toFixed(6)
      : '';

    const valueToNumber =
      typeof valueTo === 'string' ? Number(valueTo) : valueTo;

    const maskedValueTo = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valueToNumber);
    const maskedValueBy = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valueByNumber);

    setValTo(maskedValueTo.slice(3));
    setValBy(maskedValueBy.slice(3));

    setNumberValBy(valueByNumber);
    setNumberValTo(valueToNumber);
  };

  const selectDefaultCurrencyTo = () => {
    if (selectedProductType && !selectedProductId) {
      setLoading(true);
      const defaultProduct = filteredProductList.find(
        (p) => p.groupDescription === 'USD'
      );
      if (defaultProduct) {
        dispatch(
          selectProduct(
            defaultProduct?.productId,
            defaultProduct?.groupDescription,
            defaultProduct?.iof,
            defaultProduct?.virtualStoreMinValue
          )
        );
      }
    }
  };

  const handleChangeValueTo = (value) => {
    const valueTo = value;

    const valueToNumber =
      typeof valueTo === 'string'
        ? Number(value.replaceAll('.', '').replaceAll(',', '.'))
        : valueBy;

    const valueBy = (
      valueToNumber * Number(selectedTax.tax?.sugest_price)
    ).toFixed(2);

    const valueByNumber =
      typeof valueBy === 'string' ? Number(valueBy) : valueBy;

    if (valueToNumber <= 0) {
      return handleChangeValueBy(0);
    }

    if (valueBy > maxValueBy) {
      return handleChangeValueBy(maxValueBy);
    }

    const maskedValueTo = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valueToNumber);
    const maskedValueBy = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valueByNumber);

    setValTo(maskedValueTo.slice(3));
    setValBy(maskedValueBy.slice(3));

    setNumberValBy(valueByNumber);
    setNumberValTo(valueToNumber);

    if (onCalculate) {
      onCalculate(errors, selectedProduct);
    }
  };

  if (inline === true) {
    return (
      <Grid fluid>
        <Row>
          <Col sm={24} md={11}>
            <CurrencyBy
              data={currencyBy}
              onValueChange={handleChangeValueBy}
              maskedValue={maskedValueBy}
              errors={errors}
            />
          </Col>
          <Col xsHidden sm={4} md={2}>
            <ArrowContainer>
              <ArrowIcon />
            </ArrowContainer>
          </Col>
          <Col sm={24} md={11}>
            <CurrencyTo
              onValueChange={handleChangeValueTo}
              onSelected={onCurrencyToSelected}
              data={filteredProductList}
              maskedValue={maskedValueTo}
            />
          </Col>
        </Row>
        {errors && (
          <Row>
            <Col>
              <ErrorMessage>{errors}</ErrorMessage>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={24} mdHidden lgHidden>
            <ExchangeMoneyCalculatorTaxInformation />
          </Col>
        </Row>
      </Grid>
    );
  }
  return (
    <>
      <Container>
        {loading && (
          <LoaderCustom>
            <Loader />
          </LoaderCustom>
        )}
        <CurrencyBy
          data={currencyBy}
          onValueChange={handleChangeValueBy}
          maskedValue={maskedValueBy}
        />

        <ExchangeMoneyCalculatorTaxInformation />

        <CurrencyTo
          onValueChange={handleChangeValueTo}
          onSelected={onCurrencyToSelected}
          data={filteredProductList}
          maskedValue={maskedValueTo}
        />

        {errors.length > 0 && (
          <MessagePayment
            style={{ marginTop: 16 }}
            type='alert'
            text={errors[0]}
          />
        )}
      </Container>
    </>
  );
};

export default ExchangeMoneyCalculator;
