import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ArrowLeft, Info } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { toast } from 'react-toastify';
import {
  Breadcrumb,
  Checkbox,
  Col,
  ControlLabel,
  Form,
  FormGroup,
  Modal,
  Progress,
  Row,
  SelectPicker,
  Whisper,
} from 'rsuite';
import * as Yup from 'yup';
import InfoCircleSvg from './icons/infoCircle.svg';
import { MobileHeaderData } from '~/components/HeaderData';
import LoggedLayout, { LayoutContent } from '~/components/LoggedLayout';
import {
  Sidebar,
  SidebarContent,
  SidebarSubtitle,
  SidebarTitle,
} from '~/components/Sidebar';
import cpfMask from '~/mask/cpf';
import { Password } from '~/pages/Register/styles';
import { api } from '~/services/api';
import { updateUser } from '~/store/modules/user/actions';
import { calculatePercentPFGeneralData } from '~/utils/calculate-percent';
import testCpf from '~/utils/cpfTest';
import {
  All,
  BackButton,
  Container,
  SquareButton,
  StyledButton,
  StyledInput,
  StyledSelect,
  StyledTitle,
} from '../../components/styles/form.js';
import history from '../../services/history';
import {
  BodyTooltip,
  FooterLabel,
  InfoStatusComplete,
  InfoStatusIncomplete,
} from './style.js';
import ErrorModal from '~/components/ErrorModal';

const PF = () => {
  const [countries, setCountries] = useState([]);
  const { user, auth } = useSelector((state) => state);
  const [show, setShow] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [errorData, setErrorData] = useState({});

  const {
    cityBirth, // Prospect
    // cityBirth, //Grupo de relacionamento
    stateBirth, // Prospect
    // stateBirth, //Grupo de relacionamento
    motherName, // Prospect
    // motherName, // Grupo de relacionamento
    fatherName, // Prospect
    // fatherName, // Grupo de relacionamento
    countryBirth, // Prospect
    // countryBirth, // Grupo de relacionamento
    civilState,
    conjugalDocument,
    conjugalName,
    gender,
    dateBirth,
    // dateBirth,
    pep,
  } = user.profile;

  const dispatch = useDispatch();

  useEffect(() => {
    window.amplitude.getInstance().logEvent('access Personal Data page');
  }, []);

  useEffect(() => {
    const actualData = [];
    api.defaults.headers.Authorization = `Bearer ${auth.token}`;
    api.get('/lists/countries').then(({ data }) => {
      data.map((item) => {
        actualData.push({ label: item.country, value: item.id });
      });

      setCountries(actualData.reverse());
    });
  }, [auth.token]);

  const stateOptions = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Distrito Federal', value: 'DF' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
  ];

  const prospectFilled = [
    !!cityBirth, // Prospect
    !!stateBirth, // Prospect
    !!motherName, // Prospect
    // !!fatherName, // Prospect
    !!countryBirth, // Prospect
  ];

  const civil_state = {
    notMarried: 'single', // 419500001,
    married: 'married', // 419500000,
    stableUnion: 'stable_union', // 419500002,
    separated: 'separeted', // 419500005,
    divorced: 'divorced', // 419500003,
    widower: 'widower', // 419500004,
  };

  if (
    (civilState && civilState === civil_state.married) ||
    civilState === civil_state.stableUnion
  ) {
    prospectFilled.push(!!conjugalDocument, !!conjugalName);
  }

  const sex = {
    female: 'female',
    male: 'male',
  };

  const tooltipConjugal = <BodyTooltip>Preferencialmente CPF</BodyTooltip>;

  const FieldsSchema = Yup.object().shape({
    civilState: user.profile.relationship
      ? undefined
      : Yup.string().required('Obrigatório').nullable(),
    sex: Yup.string().required('Obrigatório').nullable(),
    city: Yup.string().required('Obrigatório').nullable(),
    state: Yup.string()
      .max(2, 'Estado deve ter até 2 caractéres')
      .required('Obrigatório')
      .nullable(),
    country: Yup.string().required('Obrigatório').nullable(),
    motherName: Yup.string().required('Obrigatório').nullable(),
    fatherName: Yup.string().notRequired().nullable(),
    conjugalName: Yup.string().optional().nullable(),
    conjugalDocument: Yup.string().optional().nullable(),
    birthDate: Yup.string().required('Obrigatótio'),
  });

  useEffect(() => {
    setPercentage(calculatePercentPFGeneralData(user.profile));
  }, [
    civilState,
    gender,
    cityBirth,
    stateBirth,
    motherName,
    countryBirth,
    user.profile,
  ]);

  return (
    <LoggedLayout>
      <Container className='responsive-container'>
        <Sidebar>
          <SidebarContent>
            <ReactSVG src='/icons/pf.svg' />
            <SidebarTitle internal>Geral</SidebarTitle>
            <SidebarSubtitle internal>
              Estado civil, cidade e estado de nascimento e etc.
            </SidebarSubtitle>

            {percentage === 100 ? (
              <InfoStatusComplete>Concluído</InfoStatusComplete>
            ) : (
              <InfoStatusIncomplete>Incompleto</InfoStatusIncomplete>
            )}
          </SidebarContent>
        </Sidebar>

        <MobileHeaderData
          title='Geral'
          percentage={percentage}
          icon='/icons/pf.svg'
        />

        <LayoutContent>
          <ErrorModal
            showModal={showModal}
            onChange={() => setShowModal(false)}
            {...errorData}
          />

          <Formik
            initialValues={{
              civilState,
              city: cityBirth,
              state: stateBirth,
              country: countryBirth,
              motherName,
              fatherName,
              conjugalName,
              conjugalDocument: conjugalDocument || '',
              sex: gender,
              birthDate: dateBirth && dateBirth.slice(0, 10),
              pep,
            }}
            validationSchema={FieldsSchema}
            onSubmit={(values, action) => {
              const {
                civilState,
                city,
                state,
                country,
                motherName,
                fatherName,
                conjugalName,
                conjugalDocument,
                sex,
                birthDate,
                pep,
              } = values;
              const id = user.profile._id;
              const { token } = auth;

              try {
                action.setSubmitting(true);
                setLoading(true);
                api.defaults.headers.Authorization = `Bearer ${token}`;

                api
                  .put(`/register/users/person-general`, {
                    civilState: civilState.toString(),
                    gender: sex,
                    dateBirth: birthDate,
                    cityBirth: city,
                    stateBirth: state,
                    countryBirth: country,
                    motherName,
                    fatherName:
                      fatherName === '' ? 'pai não identificado' : fatherName,
                    conjugalName,
                    conjugalDocument: conjugalDocument || null,
                    pep,
                  })
                  .then((item) => {
                    dispatch(
                      updateUser({
                        user: item.data,
                      })
                    );

                    setLoading(false);
                    toast.success('Alterações realizadas com sucesso.');
                    window.amplitude
                      .getInstance()
                      .logEvent('Updated personal data', item);
                    history.replace('/update-data');
                  })
                  .catch((item) => {
                    console.log(item);
                    setLoading(false);
                    setShowModal(true);
                    setErrorData({
                      title: 'Erro ao processar dados',
                      description:
                        'Tivemos um problema no envio das informações, revise o preenchimento dos campos obrigatórios ou tente novamente.',
                    });
                    // toast.error('Houve um problema ao enviar as infromações.');
                    action.setSubmitting(false);
                    window.amplitude
                      .getInstance()
                      .logEvent('Error in update client', item);
                  });
              } catch (err) {
                setLoading(false);
                setShowModal(true);
                setErrorData({
                  title: 'Erro ao processar dados',
                  description:
                    'Tivemos um problema no envio das informações, revise o preenchimento dos campos obrigatórios ou tente novamente.',
                });
                // toast.error('Houve um problema ao enviar as infromações.');

                action.setSubmitting(false);

                window.amplitude
                  .getInstance()
                  .logEvent('Error in update client', err);
              }
            }}
          >
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldValue,
              setSubmitting,
            }) => {
              const handleSelectChange = (value) => {
                setFieldValue('country', value);
              };

              const isMarried = (civilStateId) => {
                if (
                  civilStateId === civil_state.married ||
                  civilStateId === civil_state.stableUnion
                ) {
                  return true;
                }
              };

              const handleSelectStateChange = (value) => {
                setFieldValue('state', value);
              };

              return (
                <Form onSubmit={handleSubmit}>
                  <All>
                    <Breadcrumb className='breadcrumb'>
                      <Link className='step' to='/home'>
                        Home
                      </Link>
                      <Link className='step' to='/update-data'>
                        Meus dados
                      </Link>
                      <Link className='active'>Geral</Link>
                    </Breadcrumb>
                    <BackButton className='back-button'>
                      <ArrowLeft />
                      <Link to='/update-data'>
                        <p>Voltar</p>
                      </Link>
                    </BackButton>
                    {!user.profile.relationship && (
                      <>
                        <StyledTitle>Seu estado civil *</StyledTitle>
                        <FormGroup>
                          <SquareButton
                            onClick={() =>
                              setFieldValue(
                                'civilState',
                                civil_state.notMarried
                              )
                            }
                            value={civil_state.notMarried}
                            label='Solteiro (a)'
                            name='civilState'
                            checked={
                              values.civilState === civil_state.notMarried
                            }
                          />
                          <SquareButton
                            onClick={() =>
                              setFieldValue('civilState', civil_state.married)
                            }
                            value={civil_state.married}
                            label='Casado (a)'
                            name='civilState'
                            checked={values.civilState === civil_state.married}
                          />
                          <SquareButton
                            onClick={() =>
                              setFieldValue('civilState', civil_state.separated)
                            }
                            value={civil_state.separated}
                            label='Separado (a)'
                            name='civilState'
                            checked={
                              values.civilState === civil_state.separated
                            }
                          />
                          <SquareButton
                            onClick={() =>
                              setFieldValue('civilState', civil_state.divorced)
                            }
                            value={civil_state.divorced}
                            label='Divorciado (a)'
                            name='civilState'
                            checked={values.civilState === civil_state.divorced}
                          />
                          <SquareButton
                            onClick={() =>
                              setFieldValue(
                                'civilState',
                                civil_state.stableUnion
                              )
                            }
                            value={civil_state.stableUnion}
                            label='União Estável'
                            name='civilState'
                            checked={
                              values.civilState === civil_state.stableUnion
                            }
                          />
                          <SquareButton
                            onClick={() =>
                              setFieldValue('civilState', civil_state.widower)
                            }
                            value={civil_state.widower}
                            label='Viúvo (a)'
                            name='civilState'
                            checked={values.civilState === civil_state.widower}
                          />
                          {errors.civilState && touched.civilState && (
                            <span className='input-error'>
                              {errors.civilState}
                            </span>
                          )}
                        </FormGroup>
                      </>
                    )}
                    <StyledTitle>Informações Pessoais</StyledTitle>
                    <Row>
                      <Col xs={24}>
                        <FormGroup>
                          <ControlLabel>Sexo *</ControlLabel>
                          <SquareButton
                            onClick={() => setFieldValue('sex', sex.male)}
                            value={sex.male}
                            label='Masculino'
                            name='sex'
                            checked={values.sex === sex.male}
                          />
                          <SquareButton
                            onClick={() => setFieldValue('sex', sex.female)}
                            value={sex.female}
                            label='Feminino'
                            name='sex'
                            checked={values.sex === sex.female}
                          />
                          {errors.sex && touched.sex && (
                            <span className='input-error'>{errors.sex}</span>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <ControlLabel>Data de Nascimento *</ControlLabel>
                          <StyledInput
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='birthDate'
                            id='birthDate'
                            value={values.birthDate}
                            type='date'
                            max={new Date().toISOString().split('T')[0]}
                          />

                          {errors.birthDate && touched.birthDate && (
                            <span className='input-error'>
                              {errors.birthDate}
                            </span>
                          )}
                          <ControlLabel>Cidade de Nascimento *</ControlLabel>
                          <StyledInput
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='city'
                            id='city'
                            value={values.city}
                            type='city'
                          />

                          {errors.city && touched.city && (
                            <span className='input-error'>{errors.city}</span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} lg={5}>
                        <FormGroup>
                          <ControlLabel>Estado de Nasc. *</ControlLabel>
                          <StyledSelect
                            size='lg'
                            locale={{ searchPlaceholder: 'Pesquisar' }}
                            placeholder='Escolha o estado'
                            onChange={(value) => handleSelectStateChange(value)}
                            onBlur={handleBlur}
                            data={stateOptions}
                            value={values.state}
                            name='state'
                            id='state'
                            style={{ width: '380px', marginTop: '5px' }}
                            maxHeight={150}
                          />
                          {errors.state && touched.state && (
                            <span className='input-error'>{errors.state}</span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs={24} lg={12}>
                        <FormGroup>
                          <ControlLabel>País de Origem *</ControlLabel>
                          <StyledSelect
                            size='lg'
                            locale={{ searchPlaceholder: 'Pesquisar' }}
                            placeholder='Escolha seu país...'
                            onChange={(value) => handleSelectChange(value)}
                            onBlur={handleBlur}
                            data={countries}
                            value={values.country}
                            name='country'
                            id='country'
                            maxHeight={150}
                          />
                          {errors.country && touched.country && (
                            <span className='input-error'>
                              {errors.country}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <FormGroup>
                          <ControlLabel>Nome completo da mãe *</ControlLabel>
                          <StyledInput
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='motherName'
                            id='motherName'
                            value={values.motherName}
                            type='motherName'
                          />

                          {errors.motherName && touched.motherName && (
                            <span className='input-error'>
                              {errors.motherName}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <FormGroup>
                          <ControlLabel>
                            Nome completo do pai (opcional)
                          </ControlLabel>
                          <StyledInput
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='fatherName'
                            id='fatherName'
                            value={values.fatherName}
                            type='fatherName'
                          />

                          {errors.fatherName && touched.fatherName && (
                            <span className='input-error'>
                              {errors.fatherName}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Password className='pep'>
                      <Checkbox
                        value={values.pep}
                        checked={values.pep}
                        onChange={() => setFieldValue('pep', !values.pep)}
                      >
                        Sou PEP{' '}
                      </Checkbox>
                      <Info
                        size={20}
                        color='#09B2E6'
                        style={{ marginLeft: '5px' }}
                        onClick={() => setShow(true)}
                      />
                    </Password>
                    {isMarried(values.civilState) && (
                      <>
                        <StyledTitle>Dados do Cônjuge</StyledTitle>
                        <FormGroup>
                          <ControlLabel>Nome completo do Cônjuge</ControlLabel>
                          <StyledInput
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='conjugalName'
                            id='conjugalName'
                            value={values.conjugalName}
                            type='conjugalName'
                          />

                          {errors.conjugalName && touched.conjugalName && (
                            <span className='input-error'>
                              {errors.conjugalName}
                            </span>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>
                            Documento de Identificação do Cônjuge
                            <Whisper
                              placement='rightStart'
                              trigger='hover'
                              speaker={tooltipConjugal}
                            >
                              <img
                                src={InfoCircleSvg}
                                style={{ marginLeft: '8px' }}
                              />
                            </Whisper>
                          </ControlLabel>
                          <StyledInput
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='conjugalDocument'
                            id='conjugalDocument'
                            value={values.conjugalDocument}
                            type='conjugalDocument'
                          />

                          {errors.conjugalDocument &&
                            touched.conjugalDocument && (
                              <span className='input-error'>
                                {errors.conjugalDocument}
                              </span>
                            )}
                        </FormGroup>
                      </>
                    )}

                    <StyledButton type='submit' disabled={loading}>
                      Salvar
                    </StyledButton>
                    <FooterLabel>
                      * Campos obrigatórios para salvar formulário e seguir com
                      o cadastro.
                    </FooterLabel>
                    <Modal
                      show={show}
                      onHide={() => setShow(false)}
                      size='md'
                      style={{ padding: '16px 0 0 0' }}
                    >
                      <Modal.Title>Sou PEP</Modal.Title>
                      <Modal.Body>
                        <p>
                          Pessoa exposta politicamente é aquela que desempenha
                          ou tenha desempenhado, nos últimos cinco (5) anos,
                          cargos, empregos ou funções públicas relevantes, no
                          Brasil ou em outros países, territórios e dependências
                          estrangeiros, assim como seus representantes,
                          familiares e outras pessoas de seu relacionamento
                          próximo. São considerados familiares e parentes, na
                          linha direta, até o primeiro grau e ainda o cônjuge, o
                          companheiro (a), o enteado (a). São considerados
                          pessoas de relacionamento próximo, sócios,
                          procuradores e pessoas com quem mantenha
                          relacionamento de negócios em caráter contínuo.
                        </p>
                        <p>Conforme denominações acima, declaro que:</p>
                      </Modal.Body>
                    </Modal>
                  </All>
                </Form>
              );
            }}
          </Formik>
        </LayoutContent>
      </Container>
    </LoggedLayout>
  );
};

export default PF;
