import React from 'react';
import { StyledMessage } from './styles';

const MessagePayment = ({
  text,
  aditionalComponent,
  type,
  Icon,
  style,
  onClick,
}) => {
  return (
    <StyledMessage type={type} style={style} onClick={onClick}>
      {Icon ? <Icon /> : ''}
      <span dangerouslySetInnerHTML={{ __html: text }} />
      {aditionalComponent}
    </StyledMessage>
  );
};

export default MessagePayment;
