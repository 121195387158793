import React from 'react';
import { ReactSVG } from 'react-svg';
import { Table, Tooltip, Whisper } from 'rsuite';
import {
  Container,
  DangerButton,
  PaidButton,
  SuccessButton,
  WarningButton,
} from './styles';

class TableCellStatus extends React.Component {
  rowData;

  props;

  tooltip;

  svgRef;

  constructor(props) {
    super(props);
    this.rowData = props.rowData;
    this.props = props;
    this.svgRef = React.createRef();
    this.tooltip = <Tooltip>{this.rowData.status_description}</Tooltip>;
  }

  getButton() {
    if (this.rowData.status_id == 1)
      return <WarningButton size='md'>AGUARDANDO</WarningButton>;
    if (this.rowData.status_id == 2)
      return <PaidButton size='md'>PEDIDO PAGO</PaidButton>;
    if (this.rowData.status_id == 3 || this.rowData.status_id == 4)
      return <DangerButton size='md'>CANCELADO</DangerButton>;
    if (this.rowData.status_id == 5)
      return <SuccessButton size='md'>FINALIZADO</SuccessButton>;
    return <WarningButton size='md'>AGUARDANDO</WarningButton>;
  }

  render() {
    return (
      <Table.Cell {...this.props}>
        <Container>
          {this.getButton()}
          <Whisper speaker={this.tooltip}>
            <ReactSVG ref={this.svgRef} src='/icons/tooltip.svg' />
          </Whisper>
        </Container>
      </Table.Cell>
    );
  }
}

export default TableCellStatus;
