export function getProducts() {
  return {
    type: '@exchange/GET_PRODUCTS',
  };
}

export function addProducts(products) {
  return {
    type: '@exchange/ADD_PRODUCTS',
    payload: { products },
  };
}

export function getTax(currency) {
  return {
    type: '@exchange/GET_TAX',
    payload: { currency },
  };
}

export function addCurrencyTax(currency, tax, taxUsdBase) {
  return {
    type: '@exchange/UPDATE_TAX',
    payload: { currency, tax, taxUsdBase },
  };
}

export function addUsdBaseCurrencyTax(currency, tax) {
  return {
    type: '@exchange/UPDATE_USD_BASE_TAX',
    payload: { currency, tax },
  };
}

export function selectProduct(productId, currency, iof, virtualStoreMinValue) {
  return {
    type: '@exchange/SELECT_PRODUCT',
    payload: { productId, currency, iof, virtualStoreMinValue },
  };
}

export function updateProductValue(valueBy, valueTo) {
  return {
    type: '@exchange/UPDATE_PRODUCT_VALUE',
    payload: { valueBy, valueTo },
  };
}

export function getDeliveryAddressList() {
  return {
    type: '@exchange/GET_DELIVERY_ADDRESS_LIST',
  };
}

export function setDeliveryAddressList(payload) {
  return {
    type: '@exchange/SET_DELIVERY_ADDRESS_LIST',
    payload,
  };
}

export function setDeliveryAddress(payload) {
  return {
    type: '@exchange/SET_DELIVERY_ADDRESS',
    payload,
  };
}

export function addDeliveryAddress(payload) {
  return {
    type: '@exchange/ADD_ADDRESS',
    payload,
  };
}

export function selectProductType(product) {
  return {
    type: '@exchange/SELECT_PRODUCT_TYPE',
    payload: { product },
  };
}

export function executePaymentOrder(payload) {
  return {
    type: '@exchange/PAYMENT_ORDER',
    payload: { payload },
  };
}

export function generateTicketOrder(payload) {
  return {
    type: '@exchange/GENERATE_TICKET',
    payload: { payload },
  };
}

export function generateTicketOrderForCard(payload) {
  return {
    type: '@exchange/GENERATE_TICKET_FOR_CARD',
    payload: { payload },
  };
}

export function createTicket(payload) {
  return {
    type: '@exchange/CREATE_TICKET',
    payload: { ...payload },
  };
}

export function updateTicket(payload) {
  return {
    type: '@exchange/UPDATE_TICKET',
    payload: { ...payload },
  };
}

export function updateTicketNewStatus(payload) {
  return {
    type: '@exchange/UPDATE_TICKET_NEW',
    payload,
  };
}

export function resetTicketOperation() {
  return {
    type: '@exchange/RESET_TICKET_OPERATION',
  };
}

export function resetProductSelected() {
  return {
    type: '@exchange/RESET_PRODUCT_SELECTED',
  };
}

export function updateRemainingTime(payload) {
  return {
    type: '@exchange/REMAINING_TIME',
    payload,
  };
}

export function endTime() {
  return {
    type: '@exchange/END_TIME',
  };
}

export function setExpiredTime() {
  return {
    type: '@exchange/EXPIRED_TIME',
  };
}

export function enableLoadingOnExchange() {
  return {
    type: '@exchange/SET_LOADING_TRUE',
  };
}

export function disableLoadingOnExchange() {
  return {
    type: '@exchange/SET_LOADING_FALSE',
  };
}

export function updateCardInfo(payload) {
  return {
    type: '@exchange/UPDATE_CARD_INFO',
    payload,
  };
}

export function setCheckoutStep(payload) {
  return {
    type: '@exchange/SET_CHECKOUT_STEP',
    payload,
  };
}

export function getUserLimits() {
  return {
    type: '@exchange/GET_USER_LIMITS',
  };
}

export function updateUserLimits(payload) {
  return {
    type: '@exchange/UPDATE_USER_LIMITS',
    payload,
  };
}

export function updateUser(payload) {
  return {
    type: '@exchange/UPDATE_USER',
    payload: {
      fullName: payload.fullName,
      document: payload.document,
      email: payload.email,
      gender: payload.gender,
      birthDate: payload.birthDate,
    },
  };
}

export function updateReceiverUser(payload) {
  return {
    type: '@exchange/UPDATE_RECEIVER',
    payload: {
      fullName: payload.fullName,
      document: payload.document,
      email: payload.email,
      phone: payload.phone,
    },
  };
}

export function changeMoneyDeliveryDate(newDeliveryDate) {
  return {
    type: '@exchange/CHANGE_MONEY_DELIVERY_DATE',
    payload: {
      deliveryDate: newDeliveryDate,
    },
  };
}

export function updateOperationType(operationType) {
  return {
    type: '@exchange/UPDATE_OPERATION_TYPE',
    payload: {
      operationType,
    },
  };
}
