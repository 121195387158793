import React from 'react';
import { ReactSVG } from 'react-svg';
import { Table, Tooltip, Whisper } from 'rsuite';
import {
  Container,
  DangerButton,
  SuccessButton,
  WarningButton,
} from './styles';

class TableCellPaymentForm extends React.Component {
  rowData;

  props;

  tooltip;

  svgRef;

  constructor(props) {
    super(props);
    this.rowData = props.rowData;
    this.props = props;
  }

  getButton() {
    if (this.rowData.code_count == 'BRADESCO') return 'TED';
    if (this.rowData.code_count == 'PV BOLETO') return 'BOLETO';
    if (this.rowData.code_count == 'PV PIX') return 'PIX';
    if (this.rowData.code_count == 'PV CARTAO') return 'CARTÃO';
    return 'TED';
  }

  render() {
    return (
      <Table.Cell {...this.props}>
        <Container>{this.getButton()}</Container>
      </Table.Cell>
    );
  }
}

export default TableCellPaymentForm;
