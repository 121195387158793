import React from 'react';
import { Info } from 'react-feather';
import { useSelector } from 'react-redux';
import { calcutateCheckoutValues } from '../../../utils/calculateCheckoutValues';
import { transformNumberInMoney } from '../../../utils/transformNumberInMoney';
import { Spacer } from '../../Spacer';
import { InlineBox, TaxInfo, TaxList } from './styles';

const ExchangeMoneyCalculatorTaxInformation = ({ inline }) => {
  const { selectedProduct, defaultIof } = useSelector(
    (state) => state.exchange
  );

  const { iofPercent, iofValue, totalValue } = calcutateCheckoutValues({
    selectedProduct,
    defaultIof,
  });

  if (inline === true)
    return (
      <InlineBox id='taxInformation'>
        <Tax inline show>
          Taxa de câmbio {selectedProduct?.currencyTo}$ 1,00 =
          {selectedProduct?.currencyToTax > 0 &&
          selectedProduct?.currencyToTax !== null &&
          selectedProduct?.currencyToTax !== undefined
            ? transformNumberInMoney({
                number: selectedProduct?.currencyToTax,
                currency: 'BRL',
              })
            : transformNumberInMoney({
                number: '0.00',
                currency: 'BRL',
              })}
        </Tax>
        <Spacer right='30px' />
        <Tax inline show>
          + IOF ({iofPercent.toFixed(2)}%) ={' '}
          {iofValue > 0 && iofValue !== null && iofValue !== undefined
            ? transformNumberInMoney({
                number: iofValue,
                currency: 'BRL',
              })
            : transformNumberInMoney({
                number: '0.00',
                currency: 'BRL',
              })}
        </Tax>
        <Spacer right='30px' />
        <Tax inline show>
          VET ={' '}
          {totalValue > 0 && totalValue !== null && totalValue !== undefined
            ? transformNumberInMoney({
                number: totalValue,
                currency: 'BRL',
              })
            : transformNumberInMoney({
                number: '0.00',
                currency: 'BRL',
              })}
        </Tax>
      </InlineBox>
    );

  return (
    <TaxList>
      <Tax show>
        Taxa de câmbio {selectedProduct?.currencyTo}$ 1,00 ={' '}
        {selectedProduct?.currencyToTax > 0 &&
        selectedProduct?.currencyToTax !== null &&
        selectedProduct?.currencyToTax !== undefined
          ? transformNumberInMoney({
              number: selectedProduct?.currencyToTax,
              currency: 'BRL',
            })
          : transformNumberInMoney({
              number: '0.00',
              currency: 'BRL',
            })}
      </Tax>
      <Tax show>
        + IOF ({iofPercent.toFixed(2)}%) ={' '}
        {iofValue > 0 && iofValue !== null && iofValue !== undefined
          ? transformNumberInMoney({
              number: iofValue,
              currency: 'BRL',
            })
          : transformNumberInMoney({
              number: '0.00',
              currency: 'BRL',
            })}
      </Tax>
      <Tax show>
        VET ={' '}
        {totalValue > 0 && totalValue !== null && totalValue !== undefined
          ? transformNumberInMoney({
              number: totalValue,
              currency: 'BRL',
            })
          : transformNumberInMoney({
              number: '0.00',
              currency: 'BRL',
            })}
      </Tax>
    </TaxList>
  );
};

const Tax = (props) => {
  const { show, children, inline } = props;
  if (show === true) {
    if (inline === true)
      return (
        <>
          <Info size={20} color='#0FC7FF' />
          <Spacer right='10px' />
          <span>{children}</span>
        </>
      );
    return (
      <TaxInfo>
        <Info size={20} color='#0FC7FF' /> <span>{children}</span>
      </TaxInfo>
    );
  }
  return <></>;
};

export default ExchangeMoneyCalculatorTaxInformation;
