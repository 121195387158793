import produce from 'immer';

const INITIAL_STATE = {
  productList: [],
  selectedProduct: {
    productId: '',
    currencyFromValue: 0,
    currencyToValue: 0,
    currencyTo: '',
    currencyToTax: 0,
    currencyUsdBaseToTax: 0,
    virtualStoreMinValue: 0,
    productType: '',
    deliveryValue: 0,
    taxId: '',
    iof: 0,
    ticketId: '',
    deliveryDate: '',
  },
  user: {
    name: '',
    document: '',
    email: '',
    gender: '',
    birthDate: '',
  },
  receiver: {
    name: '',
    document: '',
    email: '',
    phone: '',
  },
  address: {
    zipcode: '',
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    complement: '',
    address_id: '',
  },
  deliveryAddressList: [],
  ticket: {
    error: false,
  },
  paymentInfo: {},
  customTaxes: [],
  isLoading: false,
  timeToValidate: 600,
  defaultIof: 1.1,
  timerInitialized: true,
  expiredTime: false,
  checkoutStep: 1,
  limits: {
    periodLimit: 0,
    avaiableLimit: 0,
    remainPeriodDays: 0,
    lastAlteration: false,
  },
  new: false,
  operationType: '',
};

export default function exchange(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@exchange/ADD_PRODUCTS': {
        draft.productList = action.payload.products;
        break;
      }
      case '@exchange/UPDATE_TAX': {
        draft.customTaxes = [...state.customTaxes, action.payload];
        draft.selectedProduct.currencyToTax = action.payload.tax.sugest_price;
        draft.selectedProduct.currencyUsdBaseToTax =
          action.payload.taxUsdBase.sugest_price;
        draft.selectedProduct.taxId = action.payload.tax.id;
        draft.isLoading = false;
        break;
      }
      case '@exchange/SELECT_PRODUCT': {
        draft.selectedProduct.productId = action.payload.productId;
        draft.selectedProduct.currencyTo = action.payload.currency;
        draft.selectedProduct.iof = action.payload.iof;
        draft.selectedProduct.currencyToTax =
          INITIAL_STATE.selectedProduct.currencyToTax;
        draft.selectedProduct.currencyUsdBaseToTax =
          INITIAL_STATE.selectedProduct.currencyUsdBaseToTax;
        draft.selectedProduct.taxId = INITIAL_STATE.selectedProduct.taxId;
        draft.selectedProduct.virtualStoreMinValue =
          action.payload.virtualStoreMinValue;
        break;
      }
      case '@exchange/SELECT_PRODUCT_TYPE': {
        draft.selectedProduct = {
          productType: action.payload.product,
        };
        break;
      }
      case '@exchange/UPDATE_PRODUCT_VALUE': {
        draft.selectedProduct.currencyFromValue = action.payload.valueBy;
        draft.selectedProduct.currencyToValue = action.payload.valueTo;
        break;
      }
      case '@exchange/ADD_ADDRESS': {
        draft.address = action.payload;
        draft.isLoading = false;
        break;
      }
      case '@exchange/SET_DELIVERY_ADDRESS': {
        draft.address = action.payload;
        draft.isLoading = false;
        break;
      }
      case '@exchange/SET_DELIVERY_ADDRESS_LIST': {
        draft.deliveryAddressList = action.payload;
        draft.isLoading = false;
        break;
      }

      case '@exchange/UPDATE_OPERATION_TYPE': {
        draft.operationType = action.payload.operationType;
        break;
      }

      case '@exchange/UPDATE_TICKET_NEW': {
        draft.new = action.payload;
        break;
      }

      case '@exchange/CREATE_TICKET': {
        draft.ticket = { ...action.payload.data, confirmed: false };
        draft.isLoading = false;
        break;
      }
      case '@exchange/UPDATE_TICKET': {
        draft.isLoading = false;
        draft.ticket = {
          ...state.ticket,
          ...action.payload.data,
          confirmed: true,
        };
        break;
      }
      case '@exchange/REMAINING_TIME': {
        draft.timeToValidate = action.payload;
        if (!draft.timerInitialized) {
          draft.timerInitialized = true;
        }
        break;
      }
      case '@exchange/END_TIME': {
        draft.timeToValidate = state.timeToValidate;
        draft.timerInitialized = false;
        break;
      }
      case '@exchange/UPDATE_CARD_INFO': {
        const { type, value } = action.payload;
        draft.paymentInfo[type] = value;
        break;
      }
      case '@exchange/EXPIRED_TIME': {
        draft.expiredTime = true;
        break;
      }
      case '@exchange/RESET_PRODUCT_SELECTED': {
        draft.selectedProduct = {
          ...INITIAL_STATE.selectedProduct,
          productType: state.selectedProduct.productType,
          virtualStoreMinValue: state.selectedProduct.virtualStoreMinValue,
          productId: state.selectedProduct.productId,
          currencyFromValue: 0,
          currencyToValue: 0,
        };

        break;
      }
      case '@exchange/RESET_TICKET_OPERATION': {
        return { ...INITIAL_STATE };
      }
      case '@exchange/SET_LOADING_TRUE': {
        draft.isLoading = true;
        break;
      }
      case '@exchange/SET_LOADING_FALSE': {
        draft.isLoading = false;
        break;
      }
      case '@exchange/SET_CHECKOUT_STEP': {
        if (action.payload !== state.checkoutStep) {
          if (action.payload === 1) {
            // Lógica do botão refazer
            draft.selectedProduct = {
              ...state.selectedProduct,
              virtualStoreMinValue: state.selectedProduct.virtualStoreMinValue,
              currencyFromValue: 0,
              currencyToValue: 0,
              productType: '',
            };
            draft.checkoutStep = action.payload;
          } else if (action.payload === true) {
            // Lógica do botão voltar
            draft.checkoutStep = 1;
          } else {
            draft.checkoutStep = action.payload;
          }
        }
        break;
      }
      case '@exchange/UPDATE_USER_LIMITS': {
        draft.limits = {
          periodLimit: action.payload?.data?.period_limit,
          avaiableLimit: action.payload?.data?.avaiable_limit,
          remainPeriodDays: action.payload?.data?.remain_period_days,
          lastAlteration: action.payload?.data?.last_alteration,
        };
        break;
      }
      case '@exchange/UPDATE_USER': {
        draft.user = {
          name: action.payload?.fullName,
          document: action.payload?.document,
          email: action.payload?.email,
          gender: action.payload?.gender,
          birthDate: action.payload?.birthDate,
        };

        break;
      }

      case '@exchange/UPDATE_RECEIVER': {
        const { fullName, document, email, phone } = action.payload;

        draft.receiver = {
          name: fullName,
          document,
          email,
          phone,
        };

        break;
      }

      case '@exchange/CHANGE_MONEY_DELIVERY_DATE': {
        const { deliveryDate } = action.payload;
        draft.selectedProduct.deliveryDate = deliveryDate;

        break;
      }

      default:
    }
  });
}
