import styled from 'styled-components';
import { Nav, Table as RTable, Content } from 'rsuite';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

export const CustomContent = styled(Content)`
  @media (min-width: 769px) {
    margin: 40px 40px 40px 140px;
  }

  @media screen and (max-width: 768px) and (min-width: 426px) {
    margin: 96px 20px 0 20px;

    .rs-nav.rs-nav-horizontal.rs-nav-justified > ul {
      flex-wrap: wrap;
    }
  }

  @media (max-width: 425px) {
    margin: 20px;

    .rs-nav.rs-nav-horizontal.rs-nav-justified > ul {
      flex-wrap: wrap;
    }
  }
`;

export const BackButton = styled.div`
  display: flex;
  color: #0fc7ff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  p {
    margin-left: 8px;
  }

  a {
    text-decoration: none;
    color: #0fc7ff;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const TitleHeader = styled.h2`
  color: #20315b;
  font-size: 2rem;
`;

export const SubTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #455566;
`;

export const Spacer = styled.div`
  padding-top: 20px;
`;

export const ReactSVGCustomDiv = styled(ReactSVG)`
  margin-right: 8px;
  div {
    display: flex;
    justify-content: center;
  }
`;

export const HeaderBar = styled.div`
  background: #eff2f7;
  padding: 20px 30px;

  display: flex;
  flex-direction: row;

  .headerbar-title {
    font-weight: 500;
    font-size: 25px;
    line-height: 34px;
    color: #20315b;
  }

  img {
    margin-right: 8px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Title = styled.h1`
  color: #20315b;
  margin: 1rem;

  @media only screen and (min-width: 900px) {
    margin-top: 2.5rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 60px;
  margin-left: 100px;
  max-width: 1340px;
  width: 100%;
  height: 100vh;

  h2 {
    color: #20315b;
  }

  hr {
    margin: 80px 0;
  }

  .rs-nav {
    position: initial !important;
  }

  @media (max-width: 768px) {
    height: 100vh;
    align-items: center;
    /* justify-content: center; */

    margin-left: 0;
    padding: 8px 8px;
  }
`;

export const NoCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 60vh;
  width: 1340px;

  margin-bottom: 16px;

  svg {
    width: 88px;
    height: 88px;
  }

  h1 {
    font-size: 24px;
    line-height: 32px;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 40px;
      line-height: 48px;
      max-width: 50%;
      text-align: center;
      margin-top: 0;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin-top: 0;
  }
`;

export const StyledNav = styled(Nav)`
  grid-column: 1 / -1;
  padding-top: 15px;
  height: 30px;

  .rs-nav-item-content,
  a {
    font-family: IBM Plex Sans;
    font-size: 17px;
    padding: 8px 12px 18px 0;
    font-weight: 500;
    line-height: 28px;

    color: #9ba8b7;
  }

  @media (min-width: 769px) {
    .rs-nav-item-content {
      font-size: 20px;
    }
  }

  .rs-nav-item-active {
    & .rs-nav-item-content {
      font-weight: bold;
      color: #09b2e6 !important;

      &:hover {
        color: #09b2e6;
      }

      &::before {
        background-color: #09b2e6 !important;
        height: 3px !important;
      }
    }
  }

  @media screen and (max-width: 768px) and (min-width: 426px) {
    .rs-nav-item-content {
      font-family: IBM Plex Sans;
      font-size: 20px;
      padding: 8px 12px 18px 0;
      font-weight: 500;
      line-height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rs-nav-item-active {
      & .rs-nav-item-content {
        font-weight: bold;
        color: #09b2e6 !important;

        &:hover {
          color: #09b2e6;
        }

        &::before {
          background-color: #09b2e6 !important;
          height: 3px !important;
        }
      }
    }
  }

  @media (max-width: 425px) {
    /* width: 95vw; */

    .rs-nav-item-content {
      font-family: IBM Plex Sans;
      font-size: 15px;
      padding: 8px 12px 18px 0;
      font-weight: 500;
      line-height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rs-nav-item-active {
      & .rs-nav-item-content {
        font-weight: bold;
        color: #09b2e6 !important;

        &:hover {
          color: #09b2e6;
        }

        &::before {
          background-color: #09b2e6 !important;
          height: 3px !important;
        }
      }
    }
  }
`;

export const StyledNavContainer = styled.div`
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #d3dce6;
`;

export const TabsBody = styled.div`
  grid-column: 1 / -1;
  margin-top: 32px;

  @media (max-width: 768px) {
    margin-top: 140px;
  }
`;

export const ContractTitle = styled.span`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #09b2e6;

  margin-right: 12px;
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;

  a + a {
    margin-left: 32px;
  }

  margin-bottom: 32px;
`;

export const StyledLink = styled(Link)`
  color: #9ba8b7;
  float: right;

  @media (max-width: 762px) {
    /* margin: 80px 0 0 0; */
  }
`;

export const CustomNavItem = styled(Nav.Item)`
  @media (min-width: 768px) {
    margin-left: 16px;
  }
`;
