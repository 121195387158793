import React, { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import Axios from 'axios';
import { FiX } from 'react-icons/fi';
import { DetailsModal, ModalHeader, TitleModalWarning } from './styles';
import TermsPF from './components/TermsPF';
import TermsPJ from './components/TermsPJ';
import Button from '../Button';
import termsApi from '~/services/termsApi';

const ModalBlock = ({
  showModal,
  clientTerms,
  setShowTerms,
  readOnly = false,
}) => {
  const { profile } = useSelector((state) => state.user);
  const [ip, setIP] = useState('');

  const [accept, setAccept] = useState(false);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(async () => {
    const res = await Axios.get('https://api.ipify.org/?format=json');
    setIP(res.data.ip);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const acceptTerms = useCallback(async () => {
    setLoading(true);
    await termsApi
      .post('accept', {
        id: clientTerms?._id,
        accepted_terms: accept,
        accepted_at: new Date().toISOString(),
        ip,
      })
      .then(() => setShowTerms(false))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [accept, profile?._id, ip]);

  return (
    <DetailsModal size='lg' show={showModal}>
      <ModalHeader
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'right',
        }}
      >
        <FiX size={40} onClick={() => setShowTerms(false)} />
      </ModalHeader>
      <TitleModalWarning>Novo Marco Cambial</TitleModalWarning>
      <p>
        Não se assuste, sabemos que acompanhar as mudanças de legislações e
        regras de cambio é complicado, por isso estamos aqui!
      </p>

      <p>
        A partir de janeiro de 2023, entrou em vigor a nova lei cambial
        brasileira, também conhecida como Lei nº 14.286 ou Novo Marco Cambial
        Brasileiro. A nova regulamentação não exigirá mais a formalização e a
        assinatura de um contrato para cada transação. Em vez disso, basta dar o
        aceite no Termo de Adesão à Prestação de Serviços Cambiais abaixo. Para
        saber mais sobre o Novo Marco Cambial{' '}
        <a
          href='https://advancedcorretora.com.br/contact?topic=8&question=0'
          target='_blank'
          rel='noreferrer'
        >
          clique aqui!
        </a>{' '}
      </p>
      <h2>Termo de Adesão à Prestação de Serviços Cambiais</h2>
      {clientTerms?.document.length === 14 && <TermsPF client={clientTerms} />}
      {clientTerms?.document.length === 18 && <TermsPJ client={clientTerms} />}
      {!readOnly && (
        <div style={{ marginBottom: '1.5rem' }}>
          <input
            type='checkbox'
            onChange={(e) => setAccept(e.target.checked)}
            checked={accept}
          />
          <label onClick={() => setAccept(!accept)}>
            Declaro que li e aceito os termos de Adesão à Prestação de Serviços
            Cambiais da Advanced Corretora
          </label>
        </div>
      )}

      {!readOnly && (
        <Button disabled={!accept || loading} onClick={acceptTerms}>
          {loading ? 'Assinando...' : 'Assinar'}
        </Button>
      )}
    </DetailsModal>
  );
};

export default ModalBlock;
