export function setChat(data) {
  return {
    type: '@chat/SET_CHAT',
    payload: data,
  };
}

export function getChat({ id }) {
  return {
    type: '@chat/GET_CHAT',
    payload: id,
  };
}

export function setHistoryChat(chat) {
  return {
    type: '@chat/SET_HISTORY_CHAT',
    payload: chat,
  };
}

export function getHistoryChat(data) {
  return {
    type: '@chat/GET_HISTORY_CHAT',
    payload: data,
  };
}
