import styled from 'styled-components';

export const Bar = styled.div`
  text-align: center;
  background: #eff2f7;
  padding: 16px;
  width: 100%;
  span {
    font-weight: 500;
  }
`;
