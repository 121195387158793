import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowLeft } from 'react-feather';

import { useHistory, useParams, Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import LoggedLayout from '~/components/LoggedLayout';

import { setCheckoutStep } from '~/store/modules/exchange/actions';

import { DesktopComponentHeader, Wrapper, BoxTimer } from './styles';

import CheckoutSimulation from '~/components/CheckoutSimulation';
import CheckoutConfirmation from '~/components/CheckoutConfirmation';
import CheckoutPayment from '../CheckoutPayment';
import { Create } from './components';

import ModalExpiredTime from '~/components/ModalsCheckout/ModalExpiredTime';

import BoxPayment from '~/components/BoxPayment';
import TimerPayment from '../../components/TimerPayment';

export default function ExchangeCardsNew() {
  const { exchange, user } = useSelector((state) => state);
  const { exchangeType, cardNumber } = useParams();

  const [percent, setPercent] = useState(20);
  const history = useHistory();
  const dispatch = useDispatch();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const changeStep = ({ back = false } = { back: false }) => {
    scrollToTop();

    if (back) {
      dispatch(setCheckoutStep(exchange.checkoutStep - 1));
    } else {
      dispatch(setCheckoutStep(exchange.checkoutStep + 1));
    }
  };

  const backToInit = () => {
    window.location.reload(true);
  };

  const checkClientIsAllowedToCheckout = () => {
    const isAllowed = user.profile?.isRelationship;
    if (!isAllowed) {
      history.push('/login');
      toast.info(
        'Você ainda não pode realizar uma operação. Por favor, continue completando o seu cadastro.'
      );
    }
  };

  useEffect(() => {
    if (user) {
      checkClientIsAllowedToCheckout();
    }
  }, [user]);

  useEffect(() => {
    let newPercent;

    if (exchange.checkoutStep === 1) {
      newPercent = 20;

      return setPercent(newPercent);
    }

    if (exchange.checkoutStep === 2) {
      newPercent = 40;

      return setPercent(newPercent);
    }

    if (exchange.checkoutStep === 3) {
      newPercent = 80;

      return setPercent(newPercent);
    }

    newPercent = 100;
    setPercent(newPercent);

    return () => dispatch(setCheckoutStep(1));
  }, [exchange.checkoutStep]);

  useEffect(() => {
    return () => dispatch(setCheckoutStep(1));
  }, []);

  return (
    <LoggedLayout>
      <Wrapper>
        <DesktopComponentHeader>
          <button onClick={() => history.goBack()}>
            <ArrowLeft size={18} /> Voltar
          </button>

          <p>
            <Link to='/home'>Home</Link> /
            <Link to='/my-cards'>Meus cartões</Link> / Novo cartão pré-pago
          </p>
        </DesktopComponentHeader>

        <hr />

        {exchange.checkoutStep > 1 && exchange.timerInitialized === true && (
          <BoxTimer>
            <BoxPayment>
              <TimerPayment
                time={{ min: 0, sec: 30 }}
                text='O valor da sua cotação vence em: '
              />
            </BoxPayment>
          </BoxTimer>
        )}

        {exchangeType === 'recharge' && (
          <>
            {exchange.checkoutStep === 1 && (
              <Create
                handleStep={() => dispatch(setCheckoutStep(3))}
                cardNumber={cardNumber}
              />
            )}

            {exchange.checkoutStep === 3 && (
              <CheckoutPayment
                percent={percent}
                remakeSimulation={() => backToInit()}
                changeStep={(param) => changeStep(param)}
              />
            )}
          </>
        )}

        {exchangeType === 'new' && (
          <>
            {exchange.checkoutStep === 1 && (
              <CheckoutSimulation
                percent={percent}
                remakeSimulation={() => backToInit()}
                changeStep={() => changeStep()}
                isANewCard
              />
            )}
            {exchange.checkoutStep === 2 && (
              <CheckoutConfirmation
                percent={percent}
                remakeSimulation={() => backToInit()}
                changeStep={(param) => changeStep(param)}
              />
            )}
            {exchange.checkoutStep === 3 && (
              <CheckoutPayment
                percent={percent}
                remakeSimulation={() => backToInit()}
                changeStep={(param) => changeStep(param)}
              />
            )}
          </>
        )}
      </Wrapper>
      <ModalExpiredTime isModalVisible={exchange.expiredTime} />
    </LoggedLayout>
  );
}
