import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, ControlLabel, FormGroup, Row } from 'rsuite';
import * as Yup from 'yup';
import CheckIcon from '../../../../assets/simple-check.svg';
import FilesList from '../../../../components/FilesList';
import FormSelectField from '../../../../components/Form/form-select-field';
import FormTextField from '../../../../components/Form/form-text-field';
import InfoBar from '../../../../components/InfoBar';
import { InputUpload } from '../../../../components/InputUpload';
import LoggedLayout from '../../../../components/LoggedLayout';
import WizardHeader from '../../../../components/WizardHeader';
import { Fields } from '../../../../components/styles/form';
import { RouteLeavingGuard } from '../../../../routes/route-leaving-guard';
import { api } from '../../../../services/api';
import history from '../../../../services/history';
import { savePartnerFiles, saveSecondStep } from '../partner-service';
import {
  Container,
  DoneStep,
  FormContainer,
  Step,
  StyledButton,
  Title,
  FooterLabel,
} from './styles';

import {
  identificationDocumentTypes,
  convertDocumentTypeInString,
  convertDocumentTypeInLabel,
} from '../../../../utils/files/fileUtils';

const DocumentsDataStep = (props) => {
  const { state } = props.location;
  const { auth, user } = useSelector((s) => s);
  const [countries, setCountries] = useState([]);
  const [identificationDocumentType, setIdentificationDocumentType] = useState(
    convertDocumentTypeInLabel(state?.documentType) ?? ''
  );
  const formik = React.useRef();

  const FieldsSchema = Yup.object().shape({
    documentType: Yup.string().required('Obrigatório').nullable(),
    documentNumber: Yup.string().required('Obrigatório'),
    issuingExpeditionDate:
      Yup.date('Deve ser uma data').required('Obrigatório'),
    issuingCountry: Yup.string().required('Obrigatório').nullable(),
    issuingState: Yup.string()
      .nullable()
      .test('required', 'Obrigatório', (value) => {
        const { documentType } = formik.current?.values;
        return documentType ? !!value : true;
      }),
    issuingAgency: Yup.string()
      .test('required', 'Obrigatório', (value) => {
        const { documentType } = formik.current?.values;
        return documentType !== 'cnh' && documentType !== 'passport'
          ? !!value
          : true;
      })
      .max(7, 'Tamanho máximo de 7 caracteres'),
    file: Yup.string().required('Obrigatório'),
  });

  const saveStep = (data) => {
    if (data.documentType === 'cnh') {
      data.issuingAgency = 'DETRAN';
    }

    if (data.documentType === 'passport') {
      data.issuingAgency = 'DPF';
    }

    data.documentType = convertDocumentTypeInString(data.documentType);

    const escapedDocument = user.profile.document
      ? user.profile.document.replace(/\D/g, '')
      : '';

    return saveSecondStep(auth.token, data, state._id)
      .then(() =>
        savePartnerFiles(
          escapedDocument,
          data.file,
          `${data.name} ${data.lastName}`
        )
      )
      .then(() => {
        toast.success('Dados salvos com sucesso');
      })
      .catch((err) => {
        toast.error('Algo deu errado. Por favor, tente novamente.');
      });
  };

  const handleNextForm = (data) => {
    const newData = { ...state, ...data };
    saveStep(newData).then(() => {
      history.push('/update-data/partners/new/3', newData);
    });
  };

  const handleBackForm = () => {
    history.push('/update-data/partners/new/1', state);
  };

  const stateOptions = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Distrito Federal', value: 'DF' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
  ];

  useEffect(() => {
    const actualData = [];
    api.defaults.headers.Authorization = `Bearer ${auth.token}`;
    api.get('/lists/countries').then(({ data }) => {
      data.map((item) =>
        actualData.push({ label: item.country, value: item.id })
      );

      setCountries(actualData.reverse());
    });
  }, [auth.token]);

  return (
    <RouteLeavingGuard
      shouldBlockNavigation={() =>
        formik?.current?.dirty === true || formik?.current.touched === true
      }
      onSaveAndExit={() => saveStep(formik.current.values)}
      onCanSave={() => formik.current?.dirty && formik.current?.isValid}
    >
      <LoggedLayout>
        <Container>
          <WizardHeader showLogo={false}>
            <div style={{ marginRight: 20 }}>
              <DoneStep>
                <img src={CheckIcon} alt='Check' />
              </DoneStep>
              <span className='step-title'>Info. pessoais</span>
            </div>
            <div style={{ marginRight: 20 }}>
              <Step active>2</Step>
              <span>Documentação</span>
            </div>
            <div style={{ marginRight: 20 }}>
              <Step>3</Step>
              <span>Endereço</span>
            </div>
            <div style={{ marginRight: 20 }}>
              <Step>4</Step>
              <span>Relação com a empresa</span>
            </div>
          </WizardHeader>
          <InfoBar
            title={`Adicionando novo ${
              state.isAttorney ? 'procurador' : 'sócio'
            }`}
          />
          <FormContainer>
            <Title>
              <span>Etapa 2</span>
              <h3>Documentação</h3>
            </Title>
            <Formik
              innerRef={formik}
              validationSchema={FieldsSchema}
              validateOnChange
              validateOnBlur
              isInitialValid
              initialValues={{
                documentType:
                  convertDocumentTypeInLabel(state?.documentType) ?? '',
                documentNumber: state?.documentNumber ?? '',
                issuingExpeditionDate: state?.issuingExpeditionDate
                  ? state?.issuingExpeditionDate.split('T')[0]
                  : '',
                issuingState: state?.issuingState ?? '',
                issuingAgency: state?.issuingAgency ?? '',
                issuingCountry: state?.issuingCountry ?? '',
                file: state?.file ?? '',
              }}
              onSubmit={(values) => {
                handleNextForm(values);
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                validateField,
                isValid,
                dirty,
              }) => {
                const FileChange = (value) => {
                  setFieldValue('file', [...values.file, ...value]);
                };
                const FileRemove = (value) => {
                  const filter = values.file.filter((item) => {
                    return item.path !== value;
                  });

                  setFieldValue('file', filter);
                };

                const handleSelectStateChange = (value) => {
                  setFieldValue('issuingState', value);
                };

                const handleSelectIssuingCountryChange = (value) => {
                  setFieldValue('issuingCountry', value);
                };

                const handleSelectDocumentTypeChange = (value) => {
                  setFieldValue('documentType', value);
                  setIdentificationDocumentType(value);
                };

                return (
                  <Form>
                    <Fields className='fieldsName'>
                      <Row>
                        <Col xs={24} lg={24}>
                          <FormSelectField
                            size='lg'
                            placeholder='Escolha o tipo do documento...'
                            labelText='Tipo do documento'
                            fieldName='documentType'
                            error={errors.documentType}
                            touched={touched.documentType}
                            handleOnBlur={handleBlur}
                            handleOnChange={handleSelectDocumentTypeChange}
                            handleOnClean={() => {
                              validateField(`documentType`);
                            }}
                            data={identificationDocumentTypes}
                            value={values.documentType ?? ''}
                            searchable={false}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} lg={24}>
                          <FormTextField
                            labelText='Número do documento *'
                            fieldName='documentNumber'
                            error={errors.documentNumber}
                            touched={touched.documentNumber}
                            value={values.documentNumber}
                            handleOnBlur={handleBlur}
                            handleOnChange={(_, event) => handleChange(event)}
                            maxLength={14}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} lg={12}>
                          <FormTextField
                            labelText='Data de Emissão *'
                            fieldName='issuingExpeditionDate'
                            value={values.issuingExpeditionDate ?? ''}
                            error={errors.issuingExpeditionDate}
                            touched={touched.issuingExpeditionDate}
                            handleOnBlur={handleBlur}
                            handleOnChange={(_, event) => handleChange(event)}
                            inputType='date'
                            maxDate={new Date().toISOString().split('T')[0]}
                          />
                        </Col>
                        <Col xs={24} lg={12}>
                          <FormSelectField
                            size='lg'
                            locale={{ searchPlaceholder: 'Pesquisar' }}
                            placeholder={
                              countries?.length
                                ? 'Escolha seu país...'
                                : 'Carregando...'
                            }
                            labelText='País emissor *'
                            fieldName='issuingCountry'
                            error={errors.issuingCountry}
                            touched={touched.issuingCountry}
                            handleOnBlur={handleBlur}
                            handleOnChange={(value) => {
                              handleSelectIssuingCountryChange(value);
                            }}
                            handleOnClean={() => {
                              validateField(`issuingCountry`);
                            }}
                            data={countries}
                            value={values.issuingCountry ?? ''}
                          />
                        </Col>
                      </Row>
                      {identificationDocumentType !== 'cnh' &&
                      identificationDocumentType !== 'passport' ? (
                        <Row>
                          <Col xs={24} lg={12}>
                            <FormSelectField
                              size='lg'
                              locale={{ searchPlaceholder: 'Pesquisar' }}
                              placeholder='Escolha o estado'
                              labelText='Estado emissor RG *'
                              fieldName='issuingState'
                              error={errors.issuingState}
                              touched={touched.issuingState}
                              handleOnBlur={handleBlur}
                              handleOnChange={(value) =>
                                handleSelectStateChange(value)
                              }
                              handleOnClean={() => {
                                validateField(`issuingState`);
                              }}
                              data={stateOptions}
                              value={values.issuingState ?? ''}
                            />
                          </Col>
                          <Col xs={24} lg={12}>
                            <FormTextField
                              labelText='Org. emissor RG *'
                              fieldName='issuingAgency'
                              error={errors.issuingAgency}
                              touched={touched.issuingAgency}
                              value={values.issuingAgency}
                              handleOnBlur={handleBlur}
                              handleOnChange={(_, event) => handleChange(event)}
                              maxLength={7}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col xs={24}>
                            <FormSelectField
                              size='lg'
                              locale={{ searchPlaceholder: 'Pesquisar' }}
                              placeholder='Escolha o estado'
                              labelText='Estado emissor RG *'
                              fieldName='issuingState'
                              error={errors.issuingState}
                              touched={touched.issuingState}
                              handleOnBlur={handleBlur}
                              handleOnChange={(value) =>
                                handleSelectStateChange(value)
                              }
                              handleOnClean={() => {
                                validateField(`issuingState`);
                              }}
                              data={stateOptions}
                              value={values.issuingState ?? ''}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col xs={24} lg={24}>
                          <ControlLabel>
                            Documento de identificação do{' '}
                            {state.isAttorney ? 'procurador' : 'sócio'}, como
                            RG, CNH e Passaporte
                          </ControlLabel>
                          {errors.file && (
                            <div>
                              <span style={{ color: '#EF3131' }}>
                                {errors.file}
                              </span>
                            </div>
                          )}
                          <FormGroup>
                            <InputUpload
                              name='file'
                              data={values.file}
                              onChange={FileChange}
                              removeFile={FileRemove}
                              required={false}
                            />
                          </FormGroup>
                          <FilesList nameClass='Documentos Cadastrais' />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} lg={12}>
                          <StyledButton
                            ghost='true'
                            onClick={() => handleBackForm()}
                          >
                            Voltar
                          </StyledButton>
                        </Col>
                        <Col xs={24} lg={12}>
                          <StyledButton
                            type='submit'
                            disabled={dirty && !isValid}
                          >
                            Próxima Etapa
                          </StyledButton>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} lg={24}>
                          <FooterLabel>
                            * Campos obrigatórios para salvar formulário e
                            seguir com o cadastro.
                          </FooterLabel>
                        </Col>
                      </Row>
                    </Fields>
                  </Form>
                );
              }}
            </Formik>
          </FormContainer>
        </Container>
      </LoggedLayout>
    </RouteLeavingGuard>
  );
};

export default DocumentsDataStep;
