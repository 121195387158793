import React, { useEffect, useState } from 'react';
import Logo from '~/assets/Advanced-Simbolo.svg';
import history from '~/services/history';
import { Container, StepsContainer, WizardBar } from './styles';

const WizardHeader = (props) => {
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    if (props?.showLogo !== undefined) setShowLogo(props.showLogo);
  }, [props.showLogo]);

  return (
    <WizardBar>
      {showLogo === true && (
        <Container className='container-header'>
          <img
            onClick={() => history.push('/update-data/partners')}
            src={Logo}
            alt='Logo'
          />
        </Container>
      )}

      <StepsContainer>{props.children}</StepsContainer>
      <Container className='container-header'>{props.button}</Container>
    </WizardBar>
  );
};

export default WizardHeader;
