import React, { useEffect, useState } from 'react';
import {
  Modal,
  Radio,
  RadioGroup,
  Col,
  ControlLabel,
  FormGroup,
  Row,
} from 'rsuite';

import { Form, Formik } from 'formik';
import { CustomButtom, CustomModal } from '~/components/ValidationModal/style';
import { CustomTitle } from './styles';
import { Fields, StyledInput } from '~/components/styles/form';
import cpfMask from '~/mask/cpf';
import FormTextField from '../../Form/form-text-field';

const ModalPerson = ({ isModalVisible, onClose }) => {
  const [showModal, setShowModal] = useState(false);
  const formik = React.useRef();

  useEffect(() => {
    setShowModal(isModalVisible);
  }, [isModalVisible]);

  return (
    <CustomModal
      size='sm'
      onHide={() => setShowModal(!showModal)}
      show={showModal}
    >
      <Modal.Header>
        <CustomTitle>Quem vai utilizar o cartão de viagem?</CustomTitle>
      </Modal.Header>
      <Modal.Body>
        <Formik
          innerRef={formik}
          initialValues={{
            fullName: '',
            email: '',
            document: '',
            gender: 'M',
            birthDate: '',
          }}
          onSubmit={(values) => {
            onClose(values);
          }}
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            touched,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Fields className='fieldsName' style={{ maxWidth: '98%' }}>
                  <Row>
                    <Col xs={24} lg={23}>
                      <ControlLabel>Nome completo</ControlLabel>
                      <FormGroup controlId='fullName'>
                        <StyledInput
                          name='fullName'
                          id='fullName'
                          type='text'
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          value={values.fullName}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} lg={23}>
                      <ControlLabel>E-mail</ControlLabel>
                      <FormGroup controlId='email'>
                        <StyledInput
                          name='email'
                          id='email'
                          type='text'
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} lg={23}>
                      <ControlLabel>CPF</ControlLabel>
                      <FormGroup controlId='document'>
                        <StyledInput
                          name='document'
                          id='document'
                          type='text'
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          value={cpfMask(values.document)}
                          maxLength={14}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} lg={23}>
                      <ControlLabel>Data de Nascimento</ControlLabel>
                      <FormGroup controlId='birthDate'>
                        <StyledInput
                          name='birthDate'
                          id='birthDate'
                          type='date'
                          onChange={(value, event) => handleChange(event)}
                          onBlur={handleBlur}
                          value={values.birthDate}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} lg={23}>
                      <ControlLabel>Gênero</ControlLabel>
                      <RadioGroup
                        name='gender'
                        onChange={(val) => setFieldValue(val)}
                      >
                        <Radio value='M'>Masculino</Radio>
                        <Radio value='F'>Feminino</Radio>
                      </RadioGroup>
                    </Col>
                  </Row>
                </Fields>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <CustomButtom
          onClick={() => {
            formik.current.submitForm();
            setShowModal(false);
          }}
        >
          Salvar alterações
        </CustomButtom>
      </Modal.Footer>
    </CustomModal>
  );
};

export default ModalPerson;
