import styled from 'styled-components';

export const StyledMessage = styled.div`
  background: #eff2f7;
  border-radius: 16px;
  width: 100%;

  padding: 20px 24px;

  font-size: 14px;
  line-height: 20px;
  color: #34404d;

  @media (min-width: 768px) {
    // max-width: 50%;
  }

  ${({ type }) =>
    type &&
    type === 'alert' &&
    `
    background: #FFECEC;
  `}
`;
