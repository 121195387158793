import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio, DateRangePicker } from 'rsuite';
import dayjs from 'dayjs';
import {
  Filters,
  SolicitationList,
  StyledRadioGroup,
  StyledButton,
  FilterTitle,
  FilterBoxTitle,
  ListHolder,
  ListHeader,
  ListTitle,
} from './styles';
import SolicitationCard from '~/components/SolicitationCard';
// import mock from './mock.json';
import { getChat } from '~/store/modules/chat/actions';

const HistoryList = ({ handleChat, actualChat }) => {
  const [filteredDate, setFilterDate] = useState([]);

  const dispatch = useDispatch();

  const chat = useSelector((state) => state.chat);

  const changeSelectedHistory = (data) => {
    if (data !== actualChat) {
      handleChat(data);
      dispatch(getChat(data));
    }
  };

  const openNewChat = () => {
    return handleChat('NEW_CHAT');
  };

  return (
    <>
      <ListHolder selectedList={!!actualChat}>
        <ListHeader>
          <ListTitle>Mensagens</ListTitle>
          <StyledButton onClick={openNewChat}>Abrir novo chat</StyledButton>
        </ListHeader>
        <FilterBoxTitle>
          <FilterTitle>Filtrar mensagens por: </FilterTitle>
        </FilterBoxTitle>
        <Filters>
          <DateRangePicker
            placement='rightStart'
            onChange={(e, x) => {
              if (e === []) {
                return setFilterDate([
                  '2020-01-01',
                  dayjs().format('YYYY-MM-DD'),
                ]);
              }

              return setFilterDate([
                dayjs(e[0]).format('YYYY-MM-DD'),
                dayjs(e[1]).format('YYYY-MM-DD'),
              ]);
            }}
            placeholder='Selecione o dia'
            iso
          />

          <StyledRadioGroup name='solicitation'>
            <Radio style={{ color: '#9BA8B7' }} value='open'>
              Abertos
            </Radio>
            <Radio style={{ color: '#9BA8B7' }} value='closed'>
              Fechados
            </Radio>
          </StyledRadioGroup>
        </Filters>
        <SolicitationList>
          {chat &&
            chat.chats &&
            Array.isArray(chat.chats) &&
            chat.chats.length > 0 &&
            chat.chats.map((item) => {
              return (
                <SolicitationCard
                  data={item}
                  onClick={() => changeSelectedHistory(item)}
                  key={item.id}
                />
              );
            })}
        </SolicitationList>
      </ListHolder>
    </>
  );
};

export default HistoryList;
