import React, { useEffect, useState } from 'react';
import { CheckboxGroup } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import { RotateCw } from 'react-feather';
import { Sidebar, ButtonList } from '../ChoosePaymentType/styles';

import Button from '../Button';

import CheckoutStepper from '../CheckoutStepper';

import {
  Stepper,
  StepperColumn,
  StepperItem,
  StepperItemNumber,
  StepperTitle,
  StepperContent,
  CheckItem,
} from '../../pages/Checkout/styles';

import check from '../../pages/ConfirmData/check.svg';

import {
  Section,
  Content,
  AddAddress,
  ChangeButton,
  ReceiverContainer,
  ReceiverCheckboxWrapper,
  LoaderCustom,
  Loader,
} from './styles';
import ResumeOrder from '../ResumeOrder';
import MessagePayment from '../MessagePayment';

// Modals flow
import ModalAllowedAddress from '../ModalsCheckout/ModalAllowedAddress';
import ModalAddress from '../ModalsCheckout/ModalAddress';
import ModalPerson from '../ModalsCheckout/ModalPerson';
import {
  generateTicketOrder,
  addDeliveryAddress,
  getDeliveryAddressList,
  generateTicketOrderForCard,
  updateUser,
  updateReceiverUser,
  changeMoneyDeliveryDate,
} from '~/store/modules/exchange/actions';
import cpfMask from '../../mask/cpf';
import ModalReceiver from '../ModalsCheckout/ModalReceiver';
import cellphoneMask from '~/mask/cellphone';
import SelectNextBusinessDay from '../SelectNextBusinessDay';

const CheckoutConfirmation = ({ changeStep, remakeSimulation, percent }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [address, setAddress] = useState({});

  const { user, exchange } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [visibleAddressModal, setVisibleAddressModal] = useState(false);
  const [visibleDeliveryModal, setVisibleDeliveryModal] = useState(false);
  const [visiblePersonModal, setVisiblePersonModal] = useState(false);

  const [myData, setMyData] = useState(false);
  const [otherPersonData, setOtherPersonData] = useState(false);

  const [modalReceiver, setModalReceiver] = useState(false);
  const [whoWillReceiveValue, setWhoWillReceiveValue] = useState('');

  const [loading, setLoading] = useState(false);

  const changeAddressVisibility = () => {
    setVisibleAddressModal(!visibleAddressModal);
  };

  const changeDeliveryVisibility = () => {
    setVisibleDeliveryModal(!visibleDeliveryModal);
  };

  const changeVisiblePersonModal = () => {
    setVisiblePersonModal(!visiblePersonModal);
  };

  const generateTicket = () => {
    if (!whoWillReceiveValue) {
      return false;
    }

    if (exchange.selectedProduct.productType === 'CARTAO') {
      dispatch(
        generateTicketOrderForCard({
          exchange_type: 'NEW',
        })
      );
    } else {
      dispatch(
        generateTicketOrder({
          exchange_type: 'NEW',
        })
      );
    }
  };

  const checkedMyData = () => {
    setOtherPersonData(false);
    setMyData(true);
    dispatch(
      updateUser({
        fullName: `${user.profile.name} ${user.profile.lastName}`,
        document: user.profile.document,
        email: user.profile.email,
        gender: user.profile.gender === 'male' ? 'M' : 'F',
        birthDate: user.profile.dateBirth,
      })
    );
  };

  const checkedOtherPerson = () => {
    setOtherPersonData(true);
    setMyData(false);
    setVisiblePersonModal(!visiblePersonModal);

    dispatch(updateUser({}));
  };

  function handleWhoWillReceiveValue() {
    setWhoWillReceiveValue('other');
    setModalReceiver(true);
  }

  useEffect(() => {
    setLoading(true);
    dispatch(getDeliveryAddressList());
  }, []);

  useEffect(() => {
    if (exchange.deliveryAddressList.length) {
      const principalAddress =
        exchange.deliveryAddressList.find((address) => address.principal) ||
        exchange.deliveryAddressList[0];

      if (exchange.selectedProduct.productType === 'ESPECIE') {
        const conditionsToAddAddress =
          principalAddress &&
          principalAddress.city === 'SÃO PAULO' &&
          principalAddress.state === 'SP';

        if (conditionsToAddAddress) {
          setAddress({
            addressZipcode: principalAddress.zipcode,
            addressStreet: principalAddress.street,
            addressNumber: principalAddress.number,
            addressNeighborhood: principalAddress.neighborhood,
            addressComplement: principalAddress.complement,
            addressCity: principalAddress.city,
            addressState: principalAddress.state,
            addressId: principalAddress.address_id,
          });

          dispatch(
            addDeliveryAddress({
              zipcode: principalAddress.zipcode,
              street: principalAddress.street,
              number: principalAddress.number,
              neighborhood: principalAddress.neighborhood,
              city: principalAddress.city,
              state: principalAddress.state,
              complement: principalAddress.complement,
              id: principalAddress.address_id,
            })
          );
        }

        setLoading(false);
      } else {
        setAddress({
          addressZipcode: principalAddress.zipcode,
          addressStreet: principalAddress.street,
          addressNumber: principalAddress.number,
          addressNeighborhood: principalAddress.neighborhood,
          addressComplement: principalAddress.complement,
          addressCity: principalAddress.city,
          addressState: principalAddress.state,
          addressId: principalAddress.address_id,
        });

        dispatch(
          addDeliveryAddress({
            zipcode: principalAddress.zipcode,
            street: principalAddress.street,
            number: principalAddress.number,
            neighborhood: principalAddress.neighborhood,
            city: principalAddress.city,
            state: principalAddress.state,
            complement: principalAddress.complement,
            id: principalAddress.address_id,
          })
        );

        setLoading(false);
      }
    }
  }, [exchange.deliveryAddressList]);

  return (
    <Section>
      <Content>
        <Stepper>
          <StepperItem
            active={activeStep === 1}
            checked={activeStep > 1}
            id='receiverStep'
          >
            <StepperItemNumber style={{ width: 50 }}>
              {activeStep > 1 ? <img src={check} /> : '1'}
            </StepperItemNumber>

            <StepperColumn>
              <StepperTitle>
                Onde você quer receber o seu dinheiro em São Paulo?
              </StepperTitle>

              <StepperContent>
                {loading && (
                  <LoaderCustom>
                    <Loader />
                  </LoaderCustom>
                )}
                {Object.entries(address).length > 0 ? (
                  <>
                    <AddAddress completed onClick={changeAddressVisibility}>
                      CEP {address.addressZipcode} <br />
                      {address.addressStreet}, {address.addressNumber} -{' '}
                      {address.addressNeighborhood}
                      <br />
                      {address.addressComplement} - {address.addressCity},{' '}
                      {address.addressState}
                    </AddAddress>

                    <ChangeButton onClick={changeAddressVisibility}>
                      <RotateCw width='24px' /> Trocar endereço
                    </ChangeButton>
                  </>
                ) : (
                  <AddAddress onClick={changeAddressVisibility}>
                    Adicionar um endereço em São Paulo
                  </AddAddress>
                )}

                <ReceiverContainer>
                  <span style={{ fontWeight: 500 }}>
                    Quem vai receber o dinheiro?
                  </span>

                  {!!exchange.receiver.name && (
                    <AddAddress completed style={{ marginTop: '1rem' }}>
                      {`Nome: ${exchange.receiver.name}`} <br />
                      {`CPF: ${exchange.receiver.document}`} <br />
                      {exchange.receiver.email && (
                        <>
                          E-mail: {exchange.receiver.email}
                          <br />
                        </>
                      )}
                      {exchange.receiver.phone && (
                        <>
                          Telefone: {cellphoneMask(exchange.receiver.phone)}
                          <br />
                        </>
                      )}
                    </AddAddress>
                  )}

                  <ReceiverCheckboxWrapper>
                    <CheckboxGroup>
                      <CheckItem
                        checked={whoWillReceiveValue === 'me'}
                        onChange={(_, checked) => {
                          if (checked) {
                            setWhoWillReceiveValue('me');
                            dispatch(
                              updateReceiverUser({
                                fullName: `${user.profile.name} ${user.profile.lastName}`,
                                document: user.profile.document,
                                email: user.profile.email,
                                phone: user.profile?.phoneNumber
                                  ? `${
                                      user.profile.phoneAreaCode
                                    }${user.profile.phoneNumber?.replace(
                                      /-./g,
                                      ''
                                    )}`
                                  : '',
                              })
                            );
                          }
                        }}
                      >
                        Eu mesmo
                      </CheckItem>
                    </CheckboxGroup>

                    <CheckboxGroup>
                      <CheckItem
                        checked={whoWillReceiveValue === 'other'}
                        onChange={(_, checked) =>
                          checked && handleWhoWillReceiveValue()
                        }
                        onClick={() => handleWhoWillReceiveValue()}
                      >
                        Outra pessoa
                      </CheckItem>
                    </CheckboxGroup>
                  </ReceiverCheckboxWrapper>
                </ReceiverContainer>

                {/* <MessagePayment
                  onClick={changeDeliveryVisibility}
                  type='alert'
                  text='OBS: As entregas em dinheiro em espécie estão limitadas a um raio de 50km da região da grande São Paulo. Veja aqui a lista de bairros'
                /> */}
              </StepperContent>
            </StepperColumn>
          </StepperItem>

          {exchange.selectedProduct.productType === 'CARTAO' ? (
            <StepperItem
              active={activeStep === 2}
              checked={activeStep > 2}
              id='identificationStep'
            >
              <StepperItemNumber>
                {activeStep > 2 ? <img src={check} /> : '2'}
              </StepperItemNumber>

              <StepperColumn>
                <StepperTitle>Confirme sua identificação</StepperTitle>
                <StepperContent>
                  <MessagePayment
                    style={{ width: '100%' }}
                    text={`
                    ${user.profile.name} ${user.profile.lastName}<br />
                    CPF: ${cpfMask(user.profile.document)}<br />
                    RG: ${user.profile.identificationDocument}
                  `}
                  />
                  <br />

                  <span style={{ fontWeight: 500 }}>
                    Quem vai utilizar o dinheiro?
                  </span>

                  <br />

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: 330,
                      justifyContent: 'space-between',
                    }}
                  >
                    <CheckboxGroup>
                      <CheckItem
                        checked={myData}
                        onChange={(_, checked) =>
                          checked === true ? checkedMyData() : undefined
                        }
                      >
                        Eu mesmo
                      </CheckItem>
                    </CheckboxGroup>

                    <CheckboxGroup>
                      <CheckItem
                        checked={otherPersonData}
                        onChange={(_, checked) =>
                          checked === true ? checkedOtherPerson() : undefined
                        }
                      >
                        Outra pessoa
                      </CheckItem>
                    </CheckboxGroup>
                  </div>
                </StepperContent>
              </StepperColumn>
            </StepperItem>
          ) : (
            ''
          )}
        </Stepper>
        <SelectNextBusinessDay
          currencyTo={exchange.selectedProduct.productId}
          productType={exchange.selectedProduct.productType}
          orderDate={new Date()}
          onChange={(date) => {
            dispatch(changeMoneyDeliveryDate(date));
          }}
        />
      </Content>

      <Sidebar>
        <CheckoutStepper percent={percent} />

        <ResumeOrder remakeSimulation={remakeSimulation} />
        <ButtonList>
          <Button sm ghost onClick={() => changeStep({ back: true })}>
            {' '}
            Voltar{' '}
          </Button>
          <Button
            sm
            disabled={
              !Object.keys(address).length ||
              (exchange.selectedProduct.productType === 'CARTAO' &&
                !(myData || otherPersonData)) ||
              !whoWillReceiveValue ||
              exchange.isLoading
            }
            onClick={generateTicket}
          >
            {' '}
            {exchange.isLoading ? 'Gerando pedido...' : 'Próximo passo'}
          </Button>
        </ButtonList>
      </Sidebar>

      <ModalAllowedAddress isModalVisible={visibleDeliveryModal} />

      <ModalAddress
        isModalVisible={visibleAddressModal}
        setAddress={setAddress}
        onClose={() => setVisibleAddressModal(false)}
      />

      <ModalPerson
        isModalVisible={visiblePersonModal}
        onClose={(data) => dispatch(updateUser(data))}
      />

      <ModalReceiver
        isModalVisible={modalReceiver}
        onClose={(data) => {
          setWhoWillReceiveValue('');
          dispatch(updateReceiverUser(data));
          setModalReceiver(false);
        }}
        onHide={() => {
          setModalReceiver(false);
          setWhoWillReceiveValue('');
        }}
      />
    </Section>
  );
};

export default CheckoutConfirmation;
