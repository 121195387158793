import styled from 'styled-components';

export const CardWrapper = styled.div`
  background: #fff;
  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 10px;
  width: ${(props) =>
    (props.hg && `488px`) ||
    (props.lg && `450px`) ||
    (props.md && `400px`) ||
    (props.lw && `365px`)};
  height: ${(props) =>
    (props.alt && `802px`) ||
    // (props.alt && `757px`) ||
    (props.med && `490px`) ||
    (props.auto && `490px`) ||
    (props.automatic && `auto`) ||
    (props.low && `316px`)};
  margin: 30px 0 0 30px;
  /* padding: 24px 24px; */
  position: relative;

  @media (max-width: 768px) {
    ${({ full }) =>
      full &&
      `
    padding: 24px;
  `}
  }

  @media (width: 1024px) {
    /* width: ${(props) => props.hg && `460px`}; */
    /* height: auto; */
  }

  @media screen and (max-width: 968px) and (min-width: 651px) {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: ${(props) => props.md && `488px`};
    height: ${(props) => props.alt && `280px`};
  }

  @media (max-width: 375px) {
    width: ${(props) => props.hg && `100px`};
    height: ${(props) => props.alt && ``};
  }

  @media (max-width: 650px) {
    width: auto;
    padding-bottom: 0;
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    /* max-width: 90%; */
    margin: 0 auto 25px;
    width: 80%;
  }
`;

export const Content = styled.div`
  @media (max-width: 768px) {
    /* padding-top: 24px; */
    width: 100%;

    /* padding-bottom: 56px; */
  }
`;
export const Footer = styled.div`
  position: absolute;
  bottom: 15px;
  display: none;

  @media (max-width: 768px) {
    position: relative;
    bottom: 0;
    margin: 0 0 30px;
    display: none;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e0e6ed;

  h1 {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #20315b;

    @media (min-width: 768px) {
      font-size: 25px;
      line-height: 34px;
      padding: 16px 0;
    }
  }

  section {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #0fc7ff;
    @media (max-width: 700px) {
      font-size: 12px;
      margin-left: 10px;
      line-height: 20px;
    }
  }

  @media (max-width: 768px) {
    width: 300px;
  }

  @media (max-width: 375px) {
    width: 260px;
  }
`;
