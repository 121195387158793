import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import React from 'react';
import Alert from '~/components/Alert';
import Button from '~/components/Button';
import { api } from '~/services/api';
import history from '~/services/history';
import { setPartner } from '~/store/modules/partner/actions';
import cnpjMask from '../../../../mask/cnpj';
import cpfMask from '../../../../mask/cpf';
import {
  BodyCard,
  BtnExcluir,
  CardNumber,
  DisplayItems,
  Items,
  RegisterAlert,
  StyledCard,
  TitleCard,
} from './styles';

const PartnerCard = (props) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  const edit = async (partner, e) => {
    await dispatch(setPartner(partner));

    return history.push(`/update-data/partners/new/${1}`, {
      // return history.push(`/update-data/partners/new/${partner.lastStep ?? 1}`, {
      ...partner,
      isAttorney: props.isAttorney,
    });
  };

  const exclud = async () => {
    const idPartner = props.data._id;

    await api
      .delete(`/partners/${idPartner}`)
      .then((_) => {
        toast.success('Representante excluído com sucesso');
      })
      .catch((_) => {
        toast.error('Houve um problema ao excluir os dados, tente novamente.');
      })
      .finally(() => {
        dispatch({
          type: '@partnersList/GET_PARTNERS',
          payload: { id: props.userId, token: auth.token },
        });
      });
  };

  const RenderEditButton = () => {
    if (props.data.isIdWall) {
      return (
        <Button ghost md onClick={(e) => edit(props.data, e)}>
          Completar cadastro
        </Button>
      );
    }
    return (
      <Button ghost md onClick={(e) => edit(props.data, e)}>
        Editar
      </Button>
    );
  };

  const RenderRegisterStatus = () => {
    if (props.registerStatus) {
      return (
        <Alert level='error'>
          Foi enviado um link no email do sócio para ser criada uma senha de
          acesso no Painel Advanced
        </Alert>
      );
    }
    if (props.registerStatus === false) {
      return (
        <Alert level='success'>
          Esse usuário já possui cadastro como PF. Ele poderá acessar e assinar
          os contratos da empresa usando o mesmo usuário e senha da conta
          pessoal
        </Alert>
      );
    }
    return null;
  };

  return (
    <>
      <StyledCard>
        {/* <StyledCard style={{ height: '100%' }}> */}
        <BodyCard>
          <CardNumber>
            {props.isAttorney === true ? 'PROCURADOR' : 'SÓCIO'} #
            {props.idx + 1}
          </CardNumber>
          <TitleCard>
            {props.data.isPF === true
              ? `${props.data.name} ${props.data.lastName}`
              : props.data.companyName}
          </TitleCard>
          <Items key={props.data.name} name={props.data.name}>
            <DisplayItems>
              <span>Documento</span>
              <span>
                <b>
                  {props.data.isPF === true
                    ? cpfMask(props.data.document)
                    : cnpjMask(props.data.document)}
                </b>
              </span>
            </DisplayItems>
            {props.data.role && (
              <>
                <hr />
                <DisplayItems>
                  <span>Cargo </span>
                  <span>
                    <b>{props.data.role}</b>
                  </span>
                </DisplayItems>
              </>
            )}
            <hr />
            {props.isAttorney !== true && (
              <>
                <DisplayItems>
                  <span>Participação</span>
                  <span>
                    <b>{`${
                      props.isAttorney === true ? 0 : props.data.quota || 0
                    }%`}</b>
                  </span>
                </DisplayItems>
                <hr />
              </>
            )}
          </Items>
          {!props.data.dynamicsId && (
            <RegisterAlert>
              Revise dados do representante: incompletos
            </RegisterAlert>
          )}
        </BodyCard>
        <RenderRegisterStatus />
        <RenderEditButton />
        <BtnExcluir appearance='subtle' onClick={() => exclud()}>
          Excluir representante
        </BtnExcluir>
      </StyledCard>
    </>
  );
};

export default PartnerCard;
