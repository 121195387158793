import React from 'react';
import {
  StyledCard,
  Header,
  Infos,
  Title,
  Description,
  IconHolder,
} from './styles';

const PaymentTypeCard = ({ infos, onClick, selected = false, Icon }) => {
  return (
    <StyledCard onClick={onClick} selected={selected}>
      <Header>
        <IconHolder>
          <Icon color={selected ? '#fff' : '#0FC7FF'} size={30} />
        </IconHolder>
      </Header>
      <Infos>
        <Title>{infos.title}</Title>
        <Description>{infos.description}</Description>
      </Infos>
    </StyledCard>
  );
};

export default PaymentTypeCard;
