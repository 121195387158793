import React, { useState, useEffect } from 'react';
import PaymentTypeCard from 'components/PaymentTypeCard';
import TimerPayment from 'components/TimerPayment';
import { useSelector, useDispatch } from 'react-redux';
import { File, Repeat, CreditCard } from 'react-feather';
import { useParams } from 'react-router-dom';
import {
  StyledChoosePaymentType,
  Sidebar,
  OperationsBox,
  BoxTitle,
  OptionsTitle,
  PaymentTypeList,
  ButtonList,
  MessageList,
  LogoList,
} from './styles';
import { transformNumberInMoney } from '~/utils/transformNumberInMoney';

import AddressPayment from '../AddressPayment';
import SelectedServicePayment from '../SelectedServicePayment';
import OperationValuePayment from '../OperationValuePayment';

import Button from '../Button';
// import UpdateMessagePayment from '../UpdateMessagePayment';
import MessagePayment from '../MessagePayment';
import PaymentCard from '../PaymentCard';

import BacenLogo from '~/assets/icons/checkout/bacen.svg';
import AbracamLogo from '~/assets/icons/checkout/abracam.svg';
import GoogleLogo from '~/assets/icons/checkout/google.svg';
import BoxPayment from '../BoxPayment';
import CheckoutStepper from '../CheckoutStepper';

import {
  enableLoadingOnExchange,
  executePaymentOrder,
  updateTicketNewStatus,
  endTime,
} from '~/store/modules/exchange/actions';
import { encrypt } from '~/utils/payments';
import { calcutateCheckoutValues } from '../../utils/calculateCheckoutValues';
// import user from '~/store/modules/user/reducer';

const ChoosePaymentType = ({ onFinish, percent }) => {
  const [_operationValue, setOperationValue] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [selectedPaymentMethod, setPaymentMethod] = useState({
    title: null,
    value: null,
  });
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [submitedPaymentData, setSubmitedPaymentData] = useState({});

  const exchange = useSelector((state) => state.exchange);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const { exchangeType } = useParams();

  const { totalValue } = calcutateCheckoutValues(exchange);

  const doneButton = (paymentType) => {
    if (paymentType === 'BOLETO') {
      return 'Gerar boleto';
    }
    if (paymentType === 'PIX') {
      return 'Pagar com Pix';
    }
    if (paymentType === 'TED') {
      return 'Pagar com TED';
    }

    return 'Pagar operação';
  };

  const setPaymentFields = (paymentType) => {
    if (paymentType === 'BOLETO') {
      return (
        <MessageList>
          <MessagePayment
            type='alert'
            text='Ao clicar em “gerar boleto”, ele terá validade de 2 úteis para pagamento e pode levar até 3 dias úteis para ser compensado.'
          />
          {/* <UpdateMessagePayment /> */}
        </MessageList>
      );
    }

    if (paymentType === 'CARTAO') {
      return <PaymentCard />;
    }

    if (paymentType === 'PIX') {
      return (
        <MessageList>
          <MessagePayment
            type='alert'
            text='OBS: Ao clicar em “Pagar com pix”, você terá 5 minutos para realizar o pagamento via QR CODE através do app do seu banco'
          />
          {/* <UpdateMessagePayment /> */}
        </MessageList>
      );
    }

    if (paymentType === 'TED') {
      return (
        <MessageList>
          <MessagePayment
            type='alert'
            text='OBS: Ao clicar em “Pagar com TED”, você terá 1 dia útil para realizar o pagamento via TED através do app do seu banco'
          />
          {/* <UpdateMessagePayment /> */}
        </MessageList>
      );
    }
  };

  const submitStep = async () => {
    const data = {
      payment: selectedPaymentMethod,
      status: 'COMPLETED_DATA',
    };

    const paymentPayload = {
      ticket_id: exchange.ticket._id,
      paymentMethod: selectedPaymentMethod.value,
      user_id: user.profile._id,
    };

    // if (selectedPaymentMethod.value === 'BOLETO') {
    // }

    if (selectedPaymentMethod.value === 'CARTAO') {
      paymentPayload.cardInfo = {
        cardNumber: encrypt(
          exchange.paymentInfo.cardNumber.replaceAll(' ', ''),
          process.env.REACT_APP_PAYMENT_KEY
        ),
        secureCode: encrypt(
          exchange.paymentInfo.securityCode,
          process.env.REACT_APP_PAYMENT_KEY
        ),
        validateDate: encrypt(
          exchange.paymentInfo.validDate.replaceAll('/', ''),
          process.env.REACT_APP_PAYMENT_KEY
        ),
      };
    }

    // if (selectedPaymentMethod.value === 'PIX') {

    // }

    // if (selectedPaymentMethod.value === 'TED') {

    // }

    if (selectedPaymentMethod.value === 'CARTAO') {
      dispatch(endTime());
    }

    dispatch(enableLoadingOnExchange());

    setLoading(exchange.isLoading);
    setSubmitedPaymentData(data);
    dispatch(executePaymentOrder(paymentPayload));
  };

  const isAvailableFinish = () => {
    if (selectedPaymentMethod.value === 'CARTAO') {
      const {
        paymentInfo: {
          cardNumber,
          securityCode,
          validDate,
          name,
          installments,
        },
      } = exchange;

      return [
        cardNumber?.length === 19,
        [3, 4].includes(securityCode?.length || 0),
        validDate?.length === 5,
        !!name,
        !!installments,
      ].every((validationItem) => !!validationItem);
    }

    return true;
  };

  const changePaymentMethod = ({ title, value }) => {
    setPaymentMethod({ title, value });

    if (value === 'CARTAO') {
      setTimeout(() => {
        const cardData = document.querySelector('#card-data');

        window.scrollTo({
          top: cardData.getBoundingClientRect().top + window.scrollY - 100,
          behavior: 'smooth',
        });
      }, 500);
    }
  };

  useEffect(() => {
    setOperationValue(totalValue);
  }, [totalValue]);

  useEffect(() => {
    if (totalValue <= 10000) {
      setPaymentTypes([
        {
          title: 'Boleto bancário',
          value: 'BOLETO',
          description: `O tempo de confirmação de pagamento é de 3 dias úteis Valor: ${transformNumberInMoney(
            { currency: 'BRL', number: totalValue }
          )}`,
          icon: File,
        },
        // {
        //   title: 'Cartão de Crédito',
        //   value: 'CARTAO',
        //   icon: CreditCard,
        //   description: `Pague em até 4x de ${transformNumberInMoney({
        //     currency: 'BRL',
        //     number: totalValue / 4,
        //   })} sem juros. Aceitamos todas as bandeiras`,
        // },
        {
          title: 'Pix',
          value: 'PIX',
          icon: Repeat,
          description: `Realize agora o pagamento em até 5 minutos e tenha a confirmação imediata. Valor: ${transformNumberInMoney(
            { currency: 'BRL', number: totalValue }
          )}`,
        },
        {
          title: 'TED',
          value: 'TED',
          icon: Repeat,
          description: `O pagamento será confirmado em até 1 dia útil, selecionando essa opção.\n Valor: ${transformNumberInMoney(
            { currency: 'BRL', number: totalValue }
          )}`,
        },
      ]);
    } else {
      setPaymentTypes([
        {
          title: 'Boleto bancário',
          value: 'BOLETO',
          description: `O tempo de confirmação de pagamento é de 3 dias úteis Valor: ${transformNumberInMoney(
            { currency: 'BRL', number: totalValue }
          )}`,
          icon: File,
        },
        // {
        //   title: 'Cartão de Crédito',
        //   value: 'CARTAO',
        //   icon: CreditCard,
        //   description: `Pague em até 4x de ${transformNumberInMoney({
        //     currency: 'BRL',
        //     number: totalValue / 4,
        //   })} sem juros. Aceitamos todas as bandeiras`,
        // },
        {
          title: 'Pix',
          value: 'PIX',
          icon: Repeat,
          description: `Realize agora o pagamento em até 5 minutos e tenha a confirmação imediata. Valor: ${transformNumberInMoney(
            { currency: 'BRL', number: totalValue }
          )}`,
        },
        {
          title: 'TED',
          value: 'TED',
          icon: Repeat,
          description: `O pagamento será confirmado em até 1 dia útil, selecionando essa opção.\n Valor: ${transformNumberInMoney(
            { currency: 'BRL', number: totalValue }
          )}`,
        },
      ]);
    }
    dispatch(updateTicketNewStatus(false));
  }, []);

  useEffect(() => {
    if (Object.keys(exchange.ticket).length > 1) {
      // setLoading(false)
      if (exchange.ticket.confirmed) {
        onFinish(submitedPaymentData);
      }
    }
  }, [exchange.ticket]);

  useEffect(() => {
    setLoading(exchange.isLoading);
  }, [exchange.isLoading]);

  return (
    <StyledChoosePaymentType>
      <OperationsBox>
        <BoxTitle>
          Como você gostaria de pagar os{' '}
          <strong>
            {transformNumberInMoney({ number: totalValue, currency: 'BRL' })}
          </strong>{' '}
          do seu pedido?
        </BoxTitle>

        <OptionsTitle>Selecione um método de pagamento</OptionsTitle>
        {paymentTypes.length && (
          <PaymentTypeList>
            {paymentTypes.map(({ title, description, value, icon }) => {
              return (
                <PaymentTypeCard
                  Icon={icon}
                  infos={{ title, description }}
                  onClick={() => changePaymentMethod({ title, value })}
                  selected={value === selectedPaymentMethod.value}
                  key={value}
                />
              );
            })}
          </PaymentTypeList>
        )}
        {selectedPaymentMethod?.value &&
          setPaymentFields(selectedPaymentMethod.value)}
      </OperationsBox>

      <Sidebar>
        <CheckoutStepper percent={percent} />

        <SelectedServicePayment />

        <OperationValuePayment />

        {exchangeType === 'new' && <AddressPayment />}

        <ButtonList>
          <Button
            sm
            disabled={
              !selectedPaymentMethod?.value || isLoading || !isAvailableFinish()
            }
            onClick={submitStep}
          >
            {' '}
            {isLoading
              ? 'Finalizando operação...'
              : doneButton(selectedPaymentMethod?.value)}{' '}
          </Button>
        </ButtonList>

        <LogoList>
          <img src={BacenLogo} alt='Bacen' />
          <img src={AbracamLogo} alt='Abracam' />
          <img src={GoogleLogo} alt='Google Safe Browsing' />
        </LogoList>
      </Sidebar>
    </StyledChoosePaymentType>
  );
};

export default ChoosePaymentType;
