import styled from 'styled-components';

export const StyledCard = styled.div``;

export const Title = styled.h4`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #34404d;
`;

export const Description = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: #34404d;
  margin: 12px 0 24px;
  display: block;
`;

export const Content = styled.div`
  display: flex;
  align-items: top;
  justify-content: space-between;
`;

export const TransactionData = styled.div`
  max-width: 283px;
`;

export const CardRepresentative = styled.div`
  @media (min-width: 768px) {
    padding-left: 24px;
  }

  & div:nth-child(2) {
    margin-left: 20px;
  }
`;

export const FieldName = styled.span`
  display: block;
`;

export const CardHolder = styled.div`
  position: relative;

  img {
    max-width: 100%;
  }
`;

export const CardNumber = styled.span`
  position: absolute;
  bottom: 74px;
  left: 44px;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 0.2px;
  text-shadow: rgba(51, 51, 51, 0.48) 1px 1px 2px;
  color: #fff;
`;

export const CardOwner = styled.span`
  position: absolute;
  bottom: 49px;
  left: 44px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-shadow: rgba(51, 51, 51, 0.48) 1px 1px 2px;
  color: #fff;

  width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const CardValidate = styled.span`
  position: absolute;
  bottom: 49px;
  left: 201px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-shadow: rgba(51, 51, 51, 0.48) 1px 1px 2px;
  color: #fff;
`;

export const ElementsHolder = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
