import React, { useState } from 'react';
import { Edit3, ChevronDown, ChevronUp, ArrowRight } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyledBox,
  Header,
  Category,
  EditButton,
  Content,
  InternalContainer,
  CollapseButton,
  List,
  ListItem,
  Order,
} from './styles';

import ValueBox from '../ValueBox';
import { transformNumberInMoney } from '~/utils/transformNumberInMoney';
import { setCheckoutStep } from '~/store/modules/exchange/actions';
import { calcutateCheckoutValues } from '../../utils/calculateCheckoutValues';

const OperationValuePayment = () => {
  const [isOpen, setIsOpen] = useState(false);

  const exchange = useSelector((state) => state.exchange);

  const { totalValue, iofPercent, iofValue } =
    calcutateCheckoutValues(exchange);

  const openText = () => {
    return (
      <>
        Abrir detalhes <ChevronDown />
      </>
    );
  };

  const closeText = () => {
    return (
      <>
        Fechar detalhes <ChevronUp />
      </>
    );
  };
  const dispatch = useDispatch();

  return (
    <StyledBox>
      <InternalContainer>
        <Header>
          <Category id='operationValue'>Valor da Operação</Category>
          <EditButton onClick={() => dispatch(setCheckoutStep(2))}>
            <Edit3 size={24} /> Refazer
          </EditButton>
        </Header>
        <Order>
          <ValueBox
            text='Valor solicitado'
            currency={exchange.selectedProduct?.currencyTo}
            value={exchange.selectedProduct?.currencyToValue}
          />
          <ArrowRight style={{ margin: '0 16px' }} />
          <ValueBox text='Total à pagar' currency='BRL' value={totalValue} />
        </Order>
        {isOpen && (
          <Content>
            <List>
              <ListItem>
                <span>Valor da taxa</span>
                <strong>
                  {transformNumberInMoney({
                    number: exchange.selectedProduct?.currencyFromValue,
                    currency: 'BRL',
                  })}
                </strong>
              </ListItem>

              <ListItem>
                <span>IOF ({iofPercent.toFixed(2)}%)</span>
                <strong>
                  {transformNumberInMoney({
                    number: iofValue,
                    currency: 'BRL',
                  })}
                </strong>
              </ListItem>

              <ListItem>
                <span>Valor da entrega</span>
                <strong>
                  {transformNumberInMoney({ number: 0, currency: 'BRL' })}
                </strong>
              </ListItem>

              <ListItem>
                <span>VET</span>
                <strong>
                  {transformNumberInMoney({
                    number: totalValue,
                    currency: 'BRL',
                  })}
                </strong>
              </ListItem>

              <ListItem>
                <span>Valor final</span>
                <strong>
                  {transformNumberInMoney({
                    number: totalValue,
                    currency: 'BRL',
                  })}
                </strong>
              </ListItem>
            </List>
          </Content>
        )}
      </InternalContainer>
      <CollapseButton onClick={() => setIsOpen(!isOpen)}>
        {!isOpen ? openText() : closeText()}
      </CollapseButton>
    </StyledBox>
  );
};

export default OperationValuePayment;
