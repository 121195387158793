import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Info } from 'react-feather';
import { Whisper } from 'rsuite';
import { toggleUserbar } from '~/store/modules/user/actions';
import {
  calculatePercentAddress,
  calculatePercentPFGeneralData,
  calculatePercentPFIdentity,
  calculatePercentPFProfission,
  calculatePercentPJGeneralData,
  calculatePercentPJRequest,
} from '~/utils/calculate-percent';

import ArrowRight from '../icons/right.svg';

import {
  BodyList,
  ButtonList,
  InfoStatusComplete,
  InfoStatusIncomplete,
  InfoStatusOptional,
  InfoText,
  InfoTitle,
  ListInfo,
  BankLength,
  TooltipLimit,
} from './styles';

const MyDataItem = ({ rote, image, title, isOptional = false }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { banks } = useSelector((state) => state.bankList);
  const { partners } = useSelector((state) => state.partnersList);

  // TODO alterar label e ícone Geral PJ
  const {
    addressCity,
    addressCountry,
    addressNeighborhood,
    addressNumber,
    addressState,
    addressStreet,
    addressZipcode,
    cityBirth,
    companyName,
    countryBirth,
    document,
    email,
    idProfission,
    issuingAgency,
    issuingExpeditionDate,
    issuingState,
    identificationDocument,
    identificationDocumentType,
    monthlyIncome,
    motherName,
    patrimony,
    phoneNumber,
    stateBirth,
    companyFantasyName,
    companyOpenedDate,
    companyEconomicActivity,
    companyWhereWorkName,
    annualPatrimony,
    annualIncome,
    importProducts,
    exportProducts,
    receiveAbroad,
    sendAbroad,
    travelMoney,
    amount,
  } = user.profile;

  const [idPercentage, setIdPercentage] = useState(0);
  const [profissionPercentage, setProfissionPercentage] = useState(0);
  const [generalPercentage, setGeneralPercentage] = useState(0);
  const [addressPercentage, setAddressPercentage] = useState(0);
  const [percentageAllPF, setPercentageAllPF] = useState(0);
  const [percentageAllPJ, setPercentageAllPJ] = useState(0);
  const [percentageFinantialPJ, setPercentageFinantialPJ] = useState(0);
  const [partnersPercentage, setPartnersPercentage] = useState(0);

  const percentage = () => {
    setIdPercentage(calculatePercentPFIdentity(user.profile), [
      document,
      identificationDocument,
      identificationDocumentType,
      issuingAgency,
      issuingState,
      issuingExpeditionDate,
    ]);
    setProfissionPercentage(calculatePercentPFProfission(user.profile), [
      idProfission,
      companyWhereWorkName,
      monthlyIncome,
      patrimony,
    ]);

    setGeneralPercentage(
      document.length === 11
        ? calculatePercentPFGeneralData(user.profile)
        : calculatePercentPJGeneralData(user.profile),
      [
        cityBirth,
        stateBirth,
        motherName,
        countryBirth,
        companyFantasyName,
        companyName,
        companyOpenedDate,
        companyEconomicActivity,
        email,
        phoneNumber,
        document,
      ]
    );
    setAddressPercentage(calculatePercentAddress(user.profile), [
      addressZipcode,
      addressStreet,
      addressNumber,
      addressState,
      addressCity,
      addressCountry,
      addressNeighborhood,
    ]);

    setPercentageAllPF(
      calculatePercentPFIdentity(user.profile) &&
        calculatePercentPFGeneralData(user.profile) &&
        // calculatePercentPFProfission(user.profile) &&
        calculatePercentAddress(user.profile),
      [
        cityBirth,
        stateBirth,
        motherName,
        countryBirth,
        document,
        identificationDocument,
        identificationDocumentType,
        issuingAgency,
        issuingState,
        issuingExpeditionDate,
        idProfission,
        companyWhereWorkName,
        monthlyIncome,
        patrimony,
        addressZipcode,
        addressStreet,
        addressNumber,
        addressState,
        addressCity,
        addressCountry,
        addressNeighborhood,
      ]
    );

    setPercentageFinantialPJ(calculatePercentPJRequest(user.profile), [
      annualPatrimony,
      annualIncome,
    ]);

    setPercentageAllPJ(
      calculatePercentPJGeneralData(user.profile) &&
      calculatePercentAddress(user.profile) && [
        // calculatePercentPJRequest(user.profile),
        companyFantasyName,
        companyName,
        companyOpenedDate,
        companyEconomicActivity,
        email,
        phoneNumber,
        document,
        addressZipcode,
        addressStreet,
        addressNumber,
        addressState,
        addressCity,
        addressCountry,
        addressNeighborhood,
        // annualPatrimony,
        // annualIncome,
      ]
    );

    let percentageAccumulate = 0;

    partners.map((item) => {
      if (item.quota) {
        percentageAccumulate += item.quota;
      }
    });

    setPartnersPercentage(percentageAccumulate);

    if (user?.showUserbar) {
      dispatch(toggleUserbar());
    }
  };

  useEffect(() => {
    percentage();
  }, [partners, partnersPercentage]);

  const getCurrentPercent = {
    Identificação: idPercentage,
    Profissão: profissionPercentage,
    Geral: generalPercentage,
    Endereço: addressPercentage,
    Financeiro: percentageFinantialPJ,
  };

  const tooltip = (
    <TooltipLimit>
      Exigido somente para limites operacionais acima de R$ 120.000
    </TooltipLimit>
  );

  return (
    <ButtonList href={rote}>
      <BodyList>
        <ListInfo>
          <img src={image} alt='' />

          <InfoText>
            <InfoTitle>{title}</InfoTitle>

            {title !== 'Dados bancários' &&
              title !== 'Representantes' &&
              isOptional === false &&
              (Math.round(getCurrentPercent[title]) === 100 ? (
                <InfoStatusComplete>Concluído</InfoStatusComplete>
              ) : (
                <InfoStatusIncomplete>Incompleto</InfoStatusIncomplete>
              ))}

            {title === 'Dados bancários' && (
              <BankLength>
                {banks.length}{' '}
                {banks.length <= 1 ? 'Conta cadastrada' : 'Contas cadastradas'}
              </BankLength>
            )}

            {title === 'Representantes' &&
              isOptional === false &&
              (partnersPercentage === 100 &&
              !partners.some((item) => item.isPartner && !item.dynamicsId) ? (
                <InfoStatusComplete>Concluído</InfoStatusComplete>
              ) : (
                <InfoStatusIncomplete>Incompleto</InfoStatusIncomplete>
              ))}

            {title === 'Financeiro' &&
              isOptional === true &&
              (Math.round(getCurrentPercent[title]) === 100 ? (
                <InfoStatusComplete>Concluído</InfoStatusComplete>
              ) : (
                <InfoStatusOptional>
                  Opcional
                  <Whisper
                    placement='bottom'
                    controlId='control-id-hover'
                    trigger='hover'
                    speaker={tooltip}
                  >
                    <Info
                      size={24}
                      color='#09B2E6'
                      style={{ marginLeft: '8px' }}
                    />
                  </Whisper>
                </InfoStatusOptional>
              ))}

            {title === 'Profissão' &&
              isOptional === true &&
              (Math.round(getCurrentPercent[title]) === 100 ? (
                <InfoStatusComplete>Concluído</InfoStatusComplete>
              ) : (
                <InfoStatusOptional>
                  Opcional
                  <Whisper
                    placement='bottom'
                    controlId='control-id-hover'
                    trigger='hover'
                    speaker={tooltip}
                  >
                    <Info
                      size={24}
                      color='#09B2E6'
                      style={{ marginLeft: '8px' }}
                    />
                  </Whisper>
                </InfoStatusOptional>
              ))}

            {title === 'Fechamento' &&
              isOptional === true &&
              ((importProducts === true ||
                exportProducts === true ||
                receiveAbroad === true ||
                sendAbroad === true ||
                travelMoney === true) &&
                amount ? (
                <InfoStatusComplete>Concluído</InfoStatusComplete>
              ) : (
                <InfoStatusOptional>
                  Opcional
                  <Whisper
                    placement='bottom'
                    controlId='control-id-hover'
                    trigger='hover'
                    speaker={tooltip}
                  >
                    <Info
                      size={24}
                      color='#09B2E6'
                      style={{ marginLeft: '8px' }}
                    />
                  </Whisper>
                </InfoStatusOptional>
              ))}
          </InfoText>
        </ListInfo>
        <img src={ArrowRight} alt='' />
      </BodyList>
    </ButtonList>
  );
};

export default MyDataItem;
