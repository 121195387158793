import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { ArrowLeft, Info } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { toast } from 'react-toastify';
import {
  Breadcrumb,
  Col,
  ControlLabel,
  Form,
  FormGroup,
  Row,
  Whisper,
} from 'rsuite';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { MobileHeaderData } from '~/components/HeaderData';
import LoggedLayout, { LayoutContent } from '~/components/LoggedLayout';
import {
  Sidebar,
  SidebarContent,
  SidebarSubtitle,
  SidebarTitle,
} from '~/components/Sidebar';
import { api } from '~/services/api';
import { updateUser } from '~/store/modules/user/actions';
import {
  All,
  BackButton,
  Container,
  StyledButton,
  StyledMaskedInput,
  StyledTitle,
  CheckItem,
} from '../../components/styles/form.js';
import {
  BodyForm,
  InfoStatusOptional,
  TooltipLimit,
  InfoStatusComplete,
} from './styles';
import history from '../../services/history';

const maskOptions = {
  prefix: 'R$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 12, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const ClosingEstimate = () => {
  const { user, auth } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const {
    importProducts,
    exportProducts,
    receiveAbroad,
    sendAbroad,
    travelMoney,
    amount,
  } = user.profile;

  const dispatch = useDispatch();

  const tooltip = (
    <TooltipLimit>
      Exigido somente para limites operacionais acima de R$ 120.000
    </TooltipLimit>
  );

  useEffect(() => {
    window.amplitude.getInstance().logEvent('access Personal Data page');
  }, []);

  const FieldsSchema = Yup.object().shape({
    importProducts: Yup.boolean(),
    exportProducts: Yup.boolean(),
    receiveAbroad: Yup.boolean(),
    sendAbroad: Yup.boolean(),
    travelMoney: Yup.boolean(),
    amount: Yup.string(),
  });

  const currencyMask = createNumberMask({ ...maskOptions });

  return (
    <LoggedLayout>
      <Container className='responsive-container'>
        <Sidebar>
          <SidebarContent>
            <ReactSVG src='/icons/finantial.svg' />
            <SidebarTitle internal>Estimativa de Fechamento</SidebarTitle>
            <SidebarSubtitle internal>
              Selecione o serviço e informe o valor desejado
            </SidebarSubtitle>

            {(importProducts === true ||
              exportProducts === true ||
              receiveAbroad === true ||
              sendAbroad === true ||
              travelMoney === true) &&
            amount ? (
              <InfoStatusComplete>Concluído</InfoStatusComplete>
            ) : (
              <InfoStatusOptional>
                Opcional
                <Whisper
                  placement='bottom'
                  controlId='control-id-hover'
                  trigger='hover'
                  speaker={tooltip}
                >
                  <Info
                    size={24}
                    color='#09B2E6'
                    style={{ marginLeft: '8px' }}
                  />
                </Whisper>
              </InfoStatusOptional>
            )}
          </SidebarContent>
        </Sidebar>

        <MobileHeaderData
          title='Fechamento'
          // percentage={percentage}
          icon='/icons/finantial.svg'
        />
        <BodyForm>
          <LayoutContent>
            <Formik
              initialValues={{
                importProducts,
                exportProducts: exportProducts || false,
                receiveAbroad: receiveAbroad || false,
                sendAbroad: sendAbroad || false,
                travelMoney: travelMoney || false,
                amount: amount || '',
              }}
              validationSchema={FieldsSchema}
              onSubmit={(values, action) => {
                const {
                  importProducts,
                  exportProducts,
                  receiveAbroad,
                  sendAbroad,
                  travelMoney,
                  amount,
                } = values;
                const id = user.profile._id;
                const { token } = auth;

                try {
                  action.setSubmitting(true);
                  setLoading(true);
                  api.defaults.headers.Authorization = `Bearer ${token}`;

                  const desiredAmount = values.amount
                    ? Number(
                        parseFloat(
                          typeof values.amount === 'string'
                            ? values.amount
                                .replace('R$', '')
                                // .replaceAll('.', '')
                                .replace('.', '')
                                .replace('.', '')
                                .replace(',', '.')
                            : values.amount
                        ).toFixed(2)
                      )
                    : 0;

                  api
                    .put(`/register/users/closing-estimate`, {
                      importProducts,
                      exportProducts,
                      receiveAbroad,
                      sendAbroad,
                      travelMoney,
                      amount: desiredAmount,
                    })
                    .then((item) => {
                      dispatch(
                        updateUser({
                          user: item.data,
                        })
                      );

                      setLoading(false);
                      toast.success('Alterações realizadas com sucesso.');
                      window.amplitude
                        .getInstance()
                        .logEvent('Updated personal data', item);
                      history.replace('/update-data');
                    })
                    .catch((item) => {
                      setLoading(false);
                      toast.error(
                        'Houve um problema ao enviar as infromações.'
                      );
                      action.setSubmitting(false);
                      window.amplitude
                        .getInstance()
                        .logEvent('Error in update client', item);
                    });
                } catch (err) {
                  setLoading(false);
                  toast.error('Houve um problema ao enviar as infromações.');

                  action.setSubmitting(false);

                  window.amplitude
                    .getInstance()
                    .logEvent('Error in update client', err);
                }
              }}
            >
              {({
                errors,
                values,
                handleChange,
                handleBlur,
                touched,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <All>
                      <Breadcrumb className='breadcrumb'>
                        <Link className='step' to='/home'>
                          Home
                        </Link>
                        <Link className='step' to='/update-data'>
                          Meus dados
                        </Link>
                        <Link className='active'>Estimativa de Fechamento</Link>
                      </Breadcrumb>
                      <BackButton className='back-button'>
                        <ArrowLeft />
                        <Link to='/update-data'>
                          <p>Voltar</p>
                        </Link>
                      </BackButton>
                      <Row>
                        <StyledTitle>Serviços que você precisa:</StyledTitle>
                        <Row>
                          <Col xs={24} lg={10}>
                            <FormGroup>
                              <CheckItem
                                onChange={() =>
                                  setFieldValue(
                                    'importProducts',
                                    !values.importProducts
                                  )
                                }
                                value={values.importProducts}
                                name='importProducts'
                                id='importProducts'
                                checked={values.importProducts}
                              >
                                Importação de Produtos
                              </CheckItem>
                            </FormGroup>

                            <FormGroup>
                              <CheckItem
                                onChange={() =>
                                  setFieldValue(
                                    'exportProducts',
                                    !values.exportProducts
                                  )
                                }
                                value={values.exportProducts}
                                name='exportProducts'
                                id='exportProducts'
                                checked={values.exportProducts}
                              >
                                Exportação de Produtos
                              </CheckItem>
                            </FormGroup>

                            <FormGroup>
                              <CheckItem
                                onChange={() =>
                                  setFieldValue(
                                    'sendAbroad',
                                    !values.sendAbroad
                                  )
                                }
                                value={values.sendAbroad}
                                name='sendAbroad'
                                id='sendAbroad'
                                checked={values.sendAbroad}
                              >
                                Envio de Dinheiro
                              </CheckItem>
                            </FormGroup>
                          </Col>

                          <Col xs={24} lg={7}>
                            <FormGroup>
                              <CheckItem
                                onChange={() =>
                                  setFieldValue(
                                    'receiveAbroad',
                                    !values.receiveAbroad
                                  )
                                }
                                value={values.receiveAbroad}
                                name='receiveAbroad'
                                id='receiveAbroad'
                                checked={values.receiveAbroad}
                              >
                                Recebimento de Dinheiro
                              </CheckItem>
                            </FormGroup>

                            <FormGroup>
                              <CheckItem
                                onChange={() =>
                                  setFieldValue(
                                    'travelMoney',
                                    !values.travelMoney
                                  )
                                }
                                value={values.travelMoney}
                                name='travelMoney'
                                id='travelMoney'
                                checked={values.travelMoney}
                              >
                                Dinheiro para Viagem
                              </CheckItem>
                            </FormGroup>
                          </Col>
                        </Row>

                        <StyledTitle>
                          Qual o valor que pretende operar conosco nos próximos
                          12 meses? <br /> (um número aproximado, não precisa
                          ser exato)
                        </StyledTitle>
                        <Row>
                          <Col xs={24} sm={12} lg={12}>
                            <FormGroup>
                              <ControlLabel>Valor desejado (R$)</ControlLabel>
                              <MaskedInput
                                mask={currencyMask}
                                onChange={(value, event) => handleChange(value)}
                                onBlur={handleBlur}
                                name='amount'
                                id='amount'
                                value={values.amount}
                                render={(ref, props) => (
                                  <StyledMaskedInput
                                    type='text'
                                    className='rs-input'
                                    ref={ref}
                                    {...props}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Row>

                      <StyledButton type='submit' disabled={loading}>
                        Salvar
                      </StyledButton>
                    </All>
                  </Form>
                );
              }}
            </Formik>
          </LayoutContent>
        </BodyForm>
      </Container>
    </LoggedLayout>
  );
};

export default ClosingEstimate;
