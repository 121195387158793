import React, { useState } from 'react';

import { Edit3, ChevronDown, ChevronUp } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyledBox,
  Header,
  Category,
  EditButton,
  Title,
  Content,
  InternalContainer,
  CollapseButton,
} from './styles';
import { setCheckoutStep } from '~/store/modules/exchange/actions';

const SelectedServicePayment = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { exchange, user } = useSelector((state) => state);

  const PRODUCT_TYPE = {
    ESPECIE: 'Dinheiro em Espécie',
    CARTAO: 'Cartão para Viagem',
  };
  const dispatch = useDispatch();
  const openText = () => {
    return (
      <>
        Abrir detalhes <ChevronDown />
      </>
    );
  };

  const closeText = () => {
    return (
      <>
        Fechar detalhes <ChevronUp />
      </>
    );
  };

  return (
    <StyledBox>
      <InternalContainer>
        <Header>
          <Category id='selectedService'>Serviço Selecionado</Category>
          <EditButton onClick={() => dispatch(setCheckoutStep(1))}>
            <Edit3 size={24} /> Refazer
          </EditButton>
        </Header>
        <Title>
          {PRODUCT_TYPE[exchange.selectedProduct?.productType || 'CARTAO']}
        </Title>
        {isOpen && (
          <Content>
            <span>Quem vai utilizar</span>
            <strong>
              {user.profile?.name} {user.profile?.lastName}
            </strong>
          </Content>
        )}
      </InternalContainer>
      <CollapseButton onClick={() => setIsOpen(!isOpen)}>
        {!isOpen ? openText() : closeText()}
      </CollapseButton>
    </StyledBox>
  );
};

export default SelectedServicePayment;
