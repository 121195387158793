import { Button } from 'rsuite';
import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
`;

export const WarningButton = styled(Button)(({ theme }) => ({
  background: '#FFFAE2',
  color: '#FFB82E',
}));

export const DangerButton = styled(Button)(({ theme }) => ({
  background: '#FFECEC',
  color: '#EF3131',
}));

export const SuccessButton = styled(Button)(({ theme }) => ({
  background: '#DFFCE7',
  color: '#14CC70',
}));

export const PaidButton = styled(Button)(({ theme }) => ({
  background: '#bbf1ff',
  color: '#009fd9',
}));
