import styled from 'styled-components';

export const StyledBox = styled.div`
  display: inline-block;
`;

export const ValueBoxText = styled.strong`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #9ba8b7;
  text-transform: uppercase;
  display: block;
  margin-bottom: 6px;
`;

export const Value = styled.strong`
  font-size: 17px;
  line-height: 26px;
  color: #20315b;
  /* margin-left: 8px; */
  vertical-align: middle;
`;

export const Image = styled.img`
  width: 18px;
  height: 18px;
`;
