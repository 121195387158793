import { Checkbox, Radio } from 'rsuite';
import styled from 'styled-components';
import Button from '~/components/Button';

export const Step = styled.div`
  margin: 0 16px 0 40px;
  line-height: 2.2em;
  height: 2.3em;
  width: 2.3em;
  border: 1px;
  text-align: center;

  ${({ active }) =>
    active
      ? `
      background: #20315B;
      color: #fff;
    `
      : `
      border: 1px solid black;
      border-color: #9BA8B7;
      color: #9BA8B7;
  `}

  border-radius: 50%;
  display: inline-block;

  span {
    margin: auto;
    ${({ active }) =>
      active
        ? `
        color: #20315B;
      `
        : `
        opacity: 1;
        border: 1px solid black;
        border-color: #9BA8B7;
        color: #9BA8B7;
  `}
  }
`;

export const DoneStep = styled.div`
  margin: 0 16px 0 40px;
  line-height: 2.2em;
  height: 2.3em;
  width: 2.3em;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #20315b;
  color: #fff;

  border-radius: 50%;
  display: inline-block;

  span {
    margin: auto;
    color: #20315b;
  }
`;

export const RadioItem = styled(Radio)`
  margin-top: 10px;
  color: #455566;
  font-size: 16px;
  background: #f9fafc;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100px;
  height: 50px;
  padding: 7px 15px;
  border: 1px solid #d3dce6;
`;

export const FormContainer = styled.div`
  display: block;
  margin: 30px auto 0 auto;
  width: 30%;

  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 48px;
  margin-top: 35px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

export const CheckItem = styled(Checkbox)`
  margin-top: 10px;
  color: #455566;
  font-size: 16px;
  background: #f9fafc;
  border-radius: 5px;
  margin-bottom: 20px;
  height: 50px;
  padding: 7px 8px;
  border: 1px solid #d3dce6;
`;

export const CheckBody = styled.div`
  margin-top: 10px;
  color: #455566;
  font-size: 16px;
  background: #f9fafc;
  border-radius: 5px;
  margin-bottom: 20px;
  height: 50px;
  padding: 7px 8px;
  border: 1px solid #d3dce6;

  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  margin-bottom: 30px;
`;

export const Container = styled.div`
  display: grid;
  width: 100%;

  @media (min-width: 768px) {
    height: calc(100vh - 75px);
  }
`;

export const FooterLabel = styled.p`
  margin: 16px 0;
  color: #455566;
  font-size: 14px;
  line-height: 20px;
  font-family: IBM Plex Sans;
`;
