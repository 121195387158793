import React, { useState, useEffect } from 'react';
import { Modal } from 'rsuite';
import IconError from './error.icon.svg';
import { CustomButtom, CustomModal } from '~/components/ValidationModal/style';
import { CustomDescription, CustomTitle } from './styles';

const ModalExpiredTime = ({ isModalVisible }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(isModalVisible);
  }, [isModalVisible]);

  return (
    <CustomModal onHide={() => window.location.reload()} show={showModal}>
      <Modal.Header>
        <img src={IconError} />
        <CustomTitle>O seu tempo de sessão expirou</CustomTitle>
        <CustomDescription>
          Pela sua segurança, limitamos o tempo de sessão para cotação. Se o seu
          tempo expirou, por favor, refaça a cotação para dar continuidade ao
          pedido.
        </CustomDescription>
      </Modal.Header>
      <Modal.Footer>
        <CustomButtom
          style={{ margin: '27px auto 0' }}
          onClick={() => window.location.reload()}
        >
          Refazer cotação
        </CustomButtom>
      </Modal.Footer>
    </CustomModal>
  );
};

export default ModalExpiredTime;
