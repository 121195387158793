import styled from 'styled-components';
import { Link as ReactLink } from 'react-router-dom';

export const Section = styled.section`
  width: 100%;
  height: 100%;
`;

export const Bar = styled.div`
  padding: 16px 0;
  left: 0px;
  top: 0px;
  width: 100%;
  min-height: 72px;

  background: #ffffff;

  border: 1px solid #e0e6ed;
`;

export const Container = styled.div`
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

export const Content = styled.div``;

export const Link = styled(ReactLink)`
  font-family: IBM Plex Sans;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #0fc7ff;
  text-decoration: none;
  transition: 0.5s all;
`;

export const Title = styled.h2`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 40px;
  color: #20315b;
  padding: 28px 0;

  @media (min-width: 768px) {
    font-size: 32px;
    padding: 56px 28px;
  }
`;

export const Stepper = styled.ul``;

export const StepperItemNumber = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: transparent;
  border: 1px solid #9ba8b7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9ba8b7;
  position: relative;
  transition: 0.5s all;

  &::after {
    content: '${(props) => props.quantityItems}';
    width: 21px;
    height: 21px;

    font-family: IBM Plex Sans;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #f9fafc;
    background: #20315b;
    border: 1px solid #ffffff;
    border-radius: 50%;
    position: absolute;

    line-height: 1.7;

    right: -10px;
    bottom: -3px;
  }
`;

export const StepperColumn = styled.div`
  margin-left: 32px;
  margin-top: 3px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 80%;
  }
`;

export const StepperContent = styled.div`
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: 0.5s all;

  .rs-control-label {
    margin: 8px 0;
    display: inline-block;
  }
`;

export const StepperItem = styled.li`
  display: flex;
  margin-bottom: 24px;

  ${(props) =>
    props.active &&
    `
    margin-bottom: 0;
    ${StepperItemNumber} {
      background: #0FC7FF;
      border-color: #0FC7FF;
      color: #fff;
      font-weight: 600;
    }
    ${StepperContent} {
      opacity: 1;
      height: max-content;
      visibility: visible;
      padding: 10px 0;
      margin: 10px 0;
      position: relative;

      &::after {
        content: '';
        background: #0fc7ff;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -50px;
        z-index: -1;
      }
    }
  `}

  ${(props) =>
    props.checked &&
    `
    ${StepperItemNumber} {
      background: transparent;
      border: 1px solid #0FC7FF;
      color: #0FC7FF;
      cursor: pointer;
    }
  `}
`;

export const ConfirmButton = styled.span`
  color: #0fc7ff;
  font-family: IBM Plex Sans;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0;
  margin-top: 10px;
  display: inline-block;
  transition: 0.5s all;
  padding: 10px 15px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  background: rgb(15 199 255 / 5%);

  &:hover {
    background: rgb(15 199 255 / 15%);
  }

  ${(props) =>
    props.disabled &&
    `
    color: #a8a8a8;
    background: rgba(64,64,64, 0.05);
    cursor: initial;
    pointer-events: none;
  `}

  ${(props) =>
    props.error &&
    `
    color: #ff5f0f;
    background: rgba(255,15,15, 0.05);
    cursor: initial;
    pointer-events: none;
  `}
`;

export const StepperTitle = styled.span`
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #34404d;
`;

export const StyledLogo = styled.img`
  height: 70px;
`;
