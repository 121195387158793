import { Col, Grid, Row, Tooltip, Whisper } from 'rsuite';
import React from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as TooltipIcon } from '../icons/tooltipIcon.svg';
import {
  Card,
  CardTop,
  ContentContainer,
  LabelText,
  SoonContainer,
} from './styles';
import { Spacer } from '../../../components/Spacer';

const SelectOptionCard = ({
  id,
  isComingSoon,
  labelText,
  tooltipText,
  selected,
  onClick,
  disabled,
  children,
}) => {
  const onCardClicked = () => {
    if (!disabled) onClick(id);
    else toast.warn(`Funcionalidade desabilitada`);
  };

  return (
    <Card selected={id && selected == id} onClick={onCardClicked}>
      <CardTop>
        <Whisper followCursor speaker={<Tooltip>{tooltipText}</Tooltip>}>
          <TooltipIcon />
        </Whisper>

        {isComingSoon && <SoonContainer>Em Breve</SoonContainer>}
      </CardTop>

      <ContentContainer>{children}</ContentContainer>

      <LabelText>{labelText}</LabelText>
    </Card>
  );
};

export default SelectOptionCard;
