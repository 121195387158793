import { Input, Checkbox } from 'rsuite';
import styled from 'styled-components';
import Button from '../../components/Button';

export const StyledInput = styled(Input)`
  margin: 5px 0 15px 0;
  height: 50px;
  width: 384px;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #d3dce6;
  padding-left: 10px;
`;

export const DocsList = styled.ul`
  list-style: outside;

  li {
    margin: 10px 0 10px 20px;
  }
`;

export const StyledTitle = styled.h2`
  color: #20315b;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin: 20px 0;
`;

export const StyledButton = styled(Button)`
  width: 141px;
  height: 48px;
  background: linear-gradient(
    98.1deg,
    #48d3fe 28.81%,
    #0fc7ff 52.98%,
    #09b2e6 84.96%
  );
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 768px) {
    margin-top: 35px;
  }
`;

export const Fields = styled.div`
  font-family: IBM Plex Sans;
  font-size: 14px;
  line-height: 20px;
  color: #455566;
`;

export const DocumentFields = styled(Fields)`
  max-width: 320px;
  margin: 30px 0;

  @media (min-width: 768px) {
    margin: 90px 0;
  }

  @media screen and (max-width: 600px) {
    margin-top: 30px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }

  ${(props) => props.withoutPadding && ` padding: 0 !important; `}
`;

export const Title = styled.div`
  margin-bottom: 20px;
  color: #20315b;
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;

  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;

export const FileUploadBox = styled.div`
  max-width: 400px;
  height: 225px;
  background: #ffffff;
  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 600px) {
    max-width: 300px;
    margin: 0 auto;
  }

  div {
    margin: 33px;
    border: 1.5px dashed #0fc7ff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h1 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      width: 205px;
      color: #09b2e6;
      text-align: center;
      margin-top: 15px;
    }
  }
  input {
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    position: absolute;
  }

  ${(props) =>
    props.small &&
    `
    height: 178px;

    div { 
      margin: 0;
      padding: 22px;
    }

  `}
`;

export const DisplayGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 35px;
  width: 100%;
  padding: 0 16px;

  @media (min-width: 768px) {
    margin-left: 50px;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
`;

export const FormGrid = styled.div`
  width: 100%;

  a {
    color: #09b2e6;
    font-size: 12px;
  }

  .active {
    color: #455566;
    text-decoration: none;
  }
`;

export const BackButton = styled.div`
  display: flex;
  color: #0fc7ff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  p {
    margin-left: 3px;
  }

  a {
    text-decoration: none;
    color: #0fc7ff;
  }
`;

export const ErrorBox = styled.div`
  width: 100%;
  max-width: 700px;
  height: 64px;
  background: #ffecec;
  color: #ef3131;
  border-radius: 5px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  font-family: IBM Plex Sans;
`;

export const SuccessBox = styled.div`
  width: 100%;
  max-width: 700px;
  height: 64px;
  background: #dcffe6;
  color: #14cc70;
  border-radius: 5px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  font-family: IBM Plex Sans;
`;

export const CheckItem = styled(Checkbox)`
  margin-top: 10px;
  color: #455566;
  font-size: 16px;
  /* background: #f9fafc; */
  border-radius: 5px;
  margin-bottom: 20px;
  height: 50px;
  padding: 7px 8px;
  /* border: 1px solid #d3dce6; */
`;

export const FooterLabel = styled.p`
  margin: 16px 0;
  color: #455566;
  font-size: 14px;
  line-height: 20px;
  font-family: IBM Plex Sans;
`;

export const InfoStatusComplete = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  display: flex;
  align-items: center;
  text-align: right;

  margin-top: 16px;

  color: #14cc70;
`;

export const InfoStatusIncomplete = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  display: flex;
  align-items: center;
  text-align: right;

  margin-top: 16px;

  color: #ef3131;
`;
