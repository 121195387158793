import { ReactSVG } from 'react-svg';
import { Content, Nav } from 'rsuite';
import styled from 'styled-components';

export const ListHolder = styled.div`
  overflow: auto;
  width: 100%;
  padding: 16px;
  background-color: #f9fafc;

  @media (min-width: 768px) {
    padding: 32px 16px 0 0;
    max-height: calc(100vh - 80px);
    width: 50%;
  }

  @media (max-width: 768px) {
    padding-bottom: 120px;
  }

  ${(props) =>
    props.selectedList &&
    `
  @media (max-width: 767px) {
    display: none;
  }
  `}
`;

export const LayoutContent = styled.div`
  display: inline-flex;

  overflow-y: auto;
  overflow-x: hidden;

  margin: 0 15px;

  @media (min-width: 768px) {
    padding: 40px 0px;
    padding: 0px 200px 0px 100px;
  }

  @media (max-width: 768px) {
    .rs-breadcrumb {
      display: none;
    }

    .back-button {
      display: none;
    }
  }
`;

export const CustomNav = styled(Nav)`
  li.rs-nav-item.rs-nav-item-active > a {
    color: #09b2e6;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  li.rs-nav-item.rs-nav-item-active > a:hover {
    color: #09b2e6;
  }

  .rs-nav-item-content::before {
    background-color: #09b2e6 !important;
  }
`;

export const tableCellTextStyle = {
  color: '#132552',
  fontWeight: 'bolder',
  fontSize: '16px',
  lineHeight: '24px',
  fontStyle: 'normal',
  fontFamily: 'IBM Plex Sans',
};

export const tableCellTextBlueStyle = {
  width: '64px',
  height: '24px',
  color: '#09B2E6',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  fontStyle: 'normal',
  fontFamily: 'IBM Plex Sans',
  cursor: 'pointer',
};

export const JustifyCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const CustomContent = styled(Content)`
  @media (min-width: 769px) {
    margin: 40px 40px 40px 140px;
  }

  @media screen and (max-width: 768px) and (min-width: 426px) {
    margin: 96px 20px 0 20px;

    .rs-nav.rs-nav-horizontal.rs-nav-justified > ul {
      flex-wrap: wrap;
    }
  }

  @media (max-width: 425px) {
    margin: 20px;

    .rs-nav.rs-nav-horizontal.rs-nav-justified > ul {
      flex-wrap: wrap;
    }
  }
`;

export const EmBreveLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;

  background: #9ba8b7;
  border-radius: 16px;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 12px;
  color: #f9fafc;
`;
export const BreveContainer = styled.div`
  @media (min-width: 767px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 768px) {
  }

  padding: 0px 0px 12px;
`;

export const Spacer = styled.div`
  padding-top: 20px;
`;

export const Title = styled.h2`
  color: #20315b;
  font-size: 32px;
`;

export const ReactSVGCustomDiv = styled(ReactSVG)`
  div {
    display: flex;
    justify-content: center;
  }
`;
