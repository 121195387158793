import produce from 'immer';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  error: false,
  errorData: {},
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        if (action.payload?.token){
          draft.token = action.payload.token;
        }
        
        draft.signed = true;
        draft.loading = false;
        break;
      }

      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@auth/SIGN_IN_ERROR': {
        draft.loading = false;
        draft.error = false;
        draft.errorData = {};
        break;
      }

      case '@auth/SIGN_FAILURE': {
        draft.loading = false;

        if (action.payload?.title) {
          draft.error = true;
          draft.errorData = {
            title: action.payload.title,
            description: action.payload.description,
          };
        }

        break;
      }

      case '@auth/LOADING': {
        draft.loading = true;
        break;
      }

      case '@auth/LOADING_COMPLETED': {
        draft.loading = false;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.token = null;
        draft.signed = false;
        break;
      }

      default:
    }
  });
}
