import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 0 0 0;
  margin: 0 auto;

  .logout-mobile__title {
    font-size: 14px;
    vertical-align: middle;
    margin-right: 5px;
    color: #20315b;
  }

  @media (min-width: 768px) {
    width: 100vmax;
    max-width: calc(100% - 32px);

    .logout-mobile {
      display: none;
    }
  }
`;
export const UserBar = styled.div`
  background: transparent;
  padding: 16px;
  width: 100%;
  z-index: 50;

  @media (min-width: 768px) {
    padding: 16px 40px 16px 0;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
      0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  }

  ${(props) =>
    !props.show &&
    `
    @media (max-width: 768px) {
      display: none;
    }
  `}

  @media (max-width: 768px) {
    .container-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const UserNavigation = styled.div`
  @media (min-width: 768px) {
    text-align: end;
  }

  .rs-dropdown-toggle {
    display: flex;
    align-items: center;

    .rs-dropdown-toggle-caret {
      position: initial;
    }
  }
`;

export const UserImageHolder = styled.figure`
  width: 32px;
  height: 32px;
  background: #132552;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  line-height: 3;
`;

export const UserImage = styled.img``;

export const UserName = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #455566;
  padding-left: 8px;
`;

export const NavbarItemName = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #c0ccda;
  vertical-align: middle;
  transition: 0.5s all;

  @media (min-width: 768px) {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(30px);
    min-width: 180px;
    padding: 0 0 0 16px;
  }
`;

export const Navbar = styled.nav`
  background: #132552;
  border-radius: 30px 30px 0 0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.5s all;

  /* overflow-y: scroll; */
  /* overflow-x: hidden; */

  @media (max-width: 800px) {
    /* min-width: 100vw;
    max-height: 72px; */
    display: none;
  }

  @media (min-width: 801px) {
    top: 0;
    bottom: initial;
    max-width: 96px;
    min-height: 100vh;
    border-radius: 0px 30px 30px 0px;
  }

  .svg-logo {
    transform: translateX(-9px);
  }

  .svg-logo__text {
    opacity: 0;
    transition: 0.5s all;
  }

  &:hover {
    max-width: 240px;
    ${NavbarItemName} {
      opacity: 1;
      visibility: visible;
    }

    .svg-logo__text {
      opacity: 1;
    }
  }
  /* Works on Firefox */
  /* scrollbar-width: none; */
  /* scrollbar-color: transparent; */

  /* Works on Chrome, Edge, and Safari */
  /* ::-webkit-scrollbar {
    display: none;
  } */
`;

export const NavbarList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (min-width: 801px) {
    a {
      /* margin: 15px; */
      /* padding: 15px; */
    }
  }

  @media (min-width: 801px) {
    flex-direction: column;
  }
`;

export const NavbarListItem = styled.li`
  position: relative;
  cursor: pointer;

  & > div {
    display: inline-block;
    vertical-align: middle;
  }

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;

    & a {
      text-align: center;
    }
  }

  ${(props) =>
    props.logo &&
    `
  
    @media (max-width: 800px) {
      display: none !important;
    }

    @media (min-width: 801px) {
      display: block;
      & > div {
        display: inline-block;
      }
    }
  `}

  ${({ svgColor }) =>
    svgColor &&
    css`
      path {
        fill: #c0ccda;
      }
    `}
`;

export const NavbarContentGroup = styled.div`
  ${(props) =>
    props.highlight &&
    `
    display: none;

    @media (min-width: 801px) {
      border-top: 1px solid #2D447F;
      display: inline-block;
    }

    ${NavbarItemName} {
      @media (min-width: 801px) {
        transform: translateY(-50%) translateX(0);
      }
    }
  `}
`;

export const NavbarContentHolder = styled.div`
  padding: 13px 36px;

  @media (min-width: 801px) {
    padding: 32px 36px;
  }
`;

export const Content = styled.div`
  position: relative;
  width: auto;
  min-width: 100%;
  margin: 74px 0 0 0;
  display: flex;
  flex-direction: row;
  background-color: #f9fafc;

  @media (min-width: 992px) {
    // height: calc(100vh - 80px);
  }

  .responsive-container {
    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    margin: 0;
    padding-bottom: 74px;

    overflow: auto;
  }

  @media (max-width: 800px) {
    .rs-breadcrumb {
      display: none;
    }

    .back-button {
      display: none;
    }
  }

  ${(props) =>
    !props.show &&
    `
    @media (max-width: 768px)   {
      margin: 0;
    }
  `}
`;
