export function setBank(bank) {
  return {
    type: '@bank/SET_BANK',
    payload: bank,
  };
}

export function removeBank() {
  return {
    type: '@bank/REMOVE_BANK',
  };
}
