import dayjs from 'dayjs';
import React from 'react';
import { Table } from 'rsuite';

class TableCellDate extends React.Component {
  rowData;

  dataKey;

  props;

  constructor(props) {
    super(props);
    this.rowData = props.rowData;
    this.dataKey = props.dataKey;
    this.props = props;
  }

  render() {
    return (
      <Table.Cell {...this.props}>
        {dayjs(this.rowData[this.dataKey]).format('DD/MM/YYYY')}
      </Table.Cell>
    );
  }
}

export default TableCellDate;
