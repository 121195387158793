import React from 'react';

import { Edit3 } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyledBox,
  Header,
  Category,
  EditButton,
  Content,
  InternalContainer,
} from './styles';
import { setCheckoutStep } from '~/store/modules/exchange/actions';

const AddressPayment = () => {
  const { exchange } = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <StyledBox>
      <InternalContainer>
        <Header>
          <Category>Endereço de Entrega</Category>
          <EditButton onClick={() => dispatch(setCheckoutStep(2))}>
            <Edit3 size={24} /> Editar
          </EditButton>
        </Header>
        <Content>
          <span>CEP {exchange.address.zipcode}</span>
          <span>
            {exchange.address.street}, {exchange.address.number} -{' '}
            {exchange.address.neighborhood}
          </span>
          <span>
            {exchange.address.complement} - {exchange.address.city},{' '}
            {exchange.address.state}
          </span>
        </Content>
      </InternalContainer>
    </StyledBox>
  );
};

export default AddressPayment;
