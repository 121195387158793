import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { Modal, Row } from 'rsuite';
import { CircleFlag as Flag } from 'react-circle-flags';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { toast } from 'react-toastify';
import {
  HeaderCell,
  Table,
  PaginationHolder,
  PaginationButton,
  PaginationLabel,
} from './styles';

import Button from '../Button';
import EmptyContainer from '../EmptyContainer';

import contractApi from '~/services/contractApi';
import { api } from '~/services/api';

const { Column, Cell } = Table;
const SignedContractTable = ({
  cpf,
  codClient,
  documentClient,
  setContractLoading,
  isLoading,
  setLoading,
  setSignedContracts,
  data,
}) => {
  const [show, _setShow] = useState(false);
  const [page, setPage] = useState(1);

  async function getSignedContracts() {
    try {
      const { data } = await api.get(
        `/contracts/signeds?code_client=${documentClient}&cpf=${cpf
          .replaceAll('.', '')
          .replace('-', '')}&page=${page}`
      );

      setSignedContracts(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (documentClient && page) {
      getSignedContracts();
    }
  }, [documentClient, page]);

  return (
    <>
      {!isLoading && !data.length ? (
        <EmptyContainer hasData={codClient} />
      ) : (
        <div>
          <Row>
            <Table
              virtualized
              headerHeight={70}
              rowHeight={56}
              height={610}
              // autoHeight
              // id='table'
              // sortColumn='id'
              // sortType='desc'
              data={data}
              loading={isLoading}
            >
              <Column width={70} align='center' verticalAlign='middle'>
                <HeaderCell>#</HeaderCell>
                <Cell dataKey='id'>
                  {(_rowData, order) => (
                    <span className='spanColor'>{order + 1}</span>
                  )}
                </Cell>
              </Column>

              <Column width={200} verticalAlign='middle'>
                <HeaderCell>Tipo de operação</HeaderCell>
                <Cell dataKey='op'>
                  {(rowData) => rowData.type_contract_name}
                </Cell>
              </Column>

              <Column width={240} verticalAlign='middle'>
                <HeaderCell>Moeda e valor</HeaderCell>
                <Cell dataKey='valor'>
                  {(rowData) => {
                    return (
                      <span>
                        {rowData.currency === 'EUR' ? (
                          <img
                            src='/icons/uniao-europeia.png'
                            className='euro'
                            style={{ height: '27px', marginLeft: '-7px' }}
                          />
                        ) : (
                          <Flag
                            countryCode={rowData.currency
                              .substr(0, 2)
                              .toLowerCase()}
                            height={25}
                          />
                        )}{' '}
                        {rowData.currency}{' '}
                        {rowData.value_foreign_currency.toLocaleString(
                          'pt-BR',
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </span>
                    );
                  }}
                </Cell>
              </Column>

              <Column width={200} verticalAlign='middle'>
                <HeaderCell>Data do contrato</HeaderCell>
                <Cell dataKey='data'>
                  {(rowData) => {
                    const data = format(
                      new Date(parseISO(rowData.contract_date)),
                      'dd/MM/yyyy'
                    );
                    return data;
                  }}
                </Cell>
              </Column>

              <Column width={200} verticalAlign='middle'>
                <HeaderCell>Nº do contrato</HeaderCell>
                <Cell dataKey='contrato'>
                  {(rowData) => rowData.id_contract_in_bacen}
                </Cell>
              </Column>

              <Column width={240} verticalAlign='middle'>
                <HeaderCell />
                <Cell>
                  {(rowData) => {
                    async function handlePdf() {
                      setContractLoading(true);
                      const a = document.createElement('a');
                      document.body.appendChild(a);
                      a.style = 'display: none';
                      const token = rowData.token_download;

                      await contractApi
                        .get(`/contracts/download?token_download=${token}`, {
                          responseType: 'blob',
                        })
                        .then((res) => {
                          const blob = new Blob([res.data], {
                            type: 'octet/stream',
                          });
                          const url = window.URL.createObjectURL(blob);

                          a.href = url;
                          a.download = 'contrato.pdf';
                          a.click();
                          setContractLoading(false);
                        })
                        .catch((_err) => {
                          setContractLoading(false);
                          toast.error(
                            'Não foi possível realizar o download agora. Por favor, tente novamente mais tarde.'
                          );
                        });
                    }
                    return (
                      <Button
                        style={{ bottom: '4px', width: '150px' }}
                        ghost
                        onClick={handlePdf}
                      >
                        Download PDF
                      </Button>
                    );
                  }}
                </Cell>
              </Column>
            </Table>
            <PaginationHolder>
              <PaginationButton
                disabled={page === 1}
                onClick={() => {
                  setPage(page - 1);
                  setLoading(true);
                }}
              >
                <FiChevronLeft size={25} color={page !== 1 && '#0fc7ff'} />
              </PaginationButton>
              <PaginationLabel>{page}</PaginationLabel>
              <PaginationButton
                disabled={data.length < 50}
                onClick={() => {
                  setPage(page + 1);
                  setLoading(true);
                }}
              >
                <FiChevronRight
                  size={25}
                  color={data.length === 50 && '#0fc7ff'}
                />
              </PaginationButton>
            </PaginationHolder>
          </Row>
        </div>
      )}

      <Modal show={show} size='sm' style={{ padding: '16px 0 0 0' }}>
        <Modal.Body />
        <Modal.Footer />
      </Modal>
    </>
  );
};

export default SignedContractTable;
