import React from 'react';
import { useHistory } from 'react-router-dom';
import EmptyIcon from '~/assets/empty-icon.svg';
import Button from '~/components/Button';
import { Container, ButtonContainer, Icon, Text } from './styles';

const EmptyContainer = ({ hasData }) => {
  const history = useHistory();

  const handleBackHome = () => {
    history.push('/home');
  };

  const handleMessage = hasData
    ? 'Você ainda não tem nenhum contrato a ser assinado'
    : 'Selecione o documento para listar seus contratos';

  return (
    <Container>
      <Icon>
        <img src={EmptyIcon} alt='Ícone de listagem vazio' />
      </Icon>
      <Text>
        <h2>{handleMessage}</h2>
      </Text>
      <ButtonContainer>
        <Button md onClick={handleBackHome}>
          Ir pra Home
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default EmptyContainer;
