import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Checkbox,
  CheckboxGroup,
  Col,
  ControlLabel,
  FormGroup,
  Row,
  Modal,
} from 'rsuite';
import { Info } from 'react-feather';
import * as Yup from 'yup';
import InfoBar from '~/components/InfoBar';
import { Fields } from '~/components/styles/form';
import history from '~/services/history';
import { loadingCompleted } from '~/store/modules/auth/actions';
import { getPartners } from '~/store/modules/partnersList/actions';
import FormSelectField from '../../../../components/Form/form-select-field';
import FormTextField from '../../../../components/Form/form-text-field';
import LoggedLayout from '../../../../components/LoggedLayout';
import RouteLeavingGuard from '../../../../routes/route-leaving-guard';
import { saveFourthStep } from '../partner-service';
import PartnersWizard from './PartnersWizard';
import {
  CheckItem,
  CheckBody,
  Container,
  FormContainer,
  StyledButton,
  Title,
  FooterLabel,
} from './styles';

const CompanyRelationStep = (props) => {
  const { state } = props.location;
  const { user, auth } = useSelector((state) => state);
  const { leadid } = user.profile;
  const partnersList = useSelector(
    (state) => state.partnersList?.partners ?? []
  );
  const [loading, setLoading] = useState(false);
  const formik = React.useRef();
  const [remainingPercentage, setRemainingPercentage] = useState(0);
  const dispatch = useDispatch();
  const partnerName = state?.isAttorney ? 'procurador' : 'sócio';
  const { token } = auth;
  const [show, setShow] = useState(false);

  const saveStep = async (data) => {
    try {
      await saveFourthStep(token, data, state._id);
      toast.success('Dados salvos com sucesso');
    } catch (ex) {
      toast.error('Algo deu errado. Por favor, tente novamente.');
      throw ex;
    }
  };

  const handleSubmit = async (data) => {
    const newData = { ...state, ...data, user, leadid };

    try {
      await saveStep(newData);
      history.push('/update-data/partners', {
        partnerDocument: newData.document,
      });
      dispatch(getPartners(user.profile._id, token));
    } catch (err) {
      toast.error('Houve um problema ao enviar as infromações.');
      dispatch(loadingCompleted());
    } finally {
      setLoading(false);
    }
  };

  const handleBackForm = () => {
    history.push('/update-data/partners/new/3', state);
  };

  useEffect(() => {
    let percentageAccumulate = 0;

    partnersList.map((item) => {
      if (item.quota) {
        percentageAccumulate += item.quota;
      }
    });
    percentageAccumulate = 100 - percentageAccumulate;
    if (state.quota) {
      percentageAccumulate += state.quota;
    }

    setRemainingPercentage(percentageAccumulate);
  }, [partnersList, state.quota]);

  const incomeOptions = [
    { label: 'Até 5.000,00', value: 5000 },
    { label: '5.000,00 a 25.000,00', value: 25000 },
    { label: '25.000,00 a 50.000,00', value: 30000 },
    { label: 'Acima de 50.000,00', value: 50000 },
  ];

  const patrimonyOptions = [
    { label: 'Até 50.000,00', value: 50000 },
    { label: '50.000,00 a 250.000,00', value: 100000 },
    { label: '250.000,00 a 500.000,00', value: 250000 },
    { label: 'Acima de 500.000,00', value: 500000 },
  ];

  return (
    <RouteLeavingGuard
      shouldBlockNavigation={() =>
        formik?.current?.dirty == true || formik?.current.touched === true
      }
      onSaveAndExit={() => saveStep(formik.current.values)}
      onCanSave={() => formik.current?.dirty && formik.current?.isValid}
    >
      <LoggedLayout>
        <Container>
          <PartnersWizard isPF={state.isPF} />
          <InfoBar title={`Adicionando novo ${partnerName}`} />
          <FormContainer>
            <Title>
              <span>Etapa 4</span>
              <h3>Relação com a empresa</h3>
            </Title>
            <Formik
              innerRef={formik}
              initialValues={{
                role: state?.role ?? '',
                quota: state?.quota ?? '',
                income: state?.incomePartner ?? 5000,
                patrimony: state?.patrimonyPartner ?? 50000,
                buy_financial: state?.financeBuy ?? false,
                sell_financial: state?.financeSell ?? false,
                import: state?.mayImport ?? false,
                export: state?.mayExport ?? false,
                pep: state?.pep ?? false,
                reside_abroad: state?.resideAbroad ?? false,
              }}
              validateOnChange
              validationSchema={Yup.object().shape({
                role:
                  state.isPF === true || state.isAttorney === true
                    ? Yup.string().required('Obrigatório')
                    : Yup.string().optional(),
                quota:
                  state?.isAttorney === false
                    ? Yup.number()
                        .required('Obrigatório')
                        .max(
                          remainingPercentage,
                          `Valor máximo de ${remainingPercentage}%`
                        )
                        .min(0.000000001, 'Valor mínimo de 0.000000001%')
                    : Yup.number().optional(),
                income: Yup.string().optional().nullable(),
                patrimony: Yup.string().optional().nullable(),
                buy_financial: Yup.boolean().optional(),
                sell_financial: Yup.boolean().optional(),
                import: Yup.boolean().optional(),
                export: Yup.boolean().optional(),
                pep: Yup.boolean().optional(),
                reside_abroad: Yup.boolean().optional(),
              })}
              onSubmit={async (values) => {
                setLoading(true);
                await handleSubmit(values);
              }}
            >
              {({
                errors,
                values,
                handleChange,
                handleBlur,
                touched,
                setFieldValue,
                isSubmitting,
                isValid,
              }) => {
                const handleSelectIncomeChange = (value) => {
                  setFieldValue('income', value);
                };

                const handleSelectPatrimonyChange = (value) => {
                  setFieldValue('patrimony', value);
                };

                return (
                  <Form>
                    <Fields className='fieldsName'>
                      <Row>
                        {state?.isPF === true && (
                          <Col xs={24} lg={12}>
                            <FormTextField
                              labelText='Cargo *'
                              fieldName='role'
                              error={errors.role}
                              touched={touched.role}
                              handleOnChange={(_, ev) => handleChange(ev)}
                              handleOnBlur={handleBlur}
                              value={values.role}
                            />
                          </Col>
                        )}

                        {state.isAttorney === false && (
                          <Col xs={24} lg={state?.isPF ? 12 : 24}>
                            <FormTextField
                              labelText={`Porcentagem (${remainingPercentage}% restante) *`}
                              fieldName='quota'
                              error={errors.quota}
                              touched={touched.quota}
                              handleOnChange={(value, event) => {
                                if (
                                  value === '' ||
                                  value <= remainingPercentage
                                ) {
                                  handleChange(event);
                                }
                              }}
                              handleOnBlur={handleBlur}
                              value={values.quota.toString()}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col xs={24} lg={12}>
                          <FormSelectField
                            size='lg'
                            locale={{ searchPlaceholder: 'Pesquisar' }}
                            placeholder='Escolha sua renda'
                            labelText='Renda (R$)'
                            fieldName='income'
                            error={errors.income}
                            touched={touched.income}
                            handleOnBlur={handleBlur}
                            handleOnChange={(value) =>
                              handleSelectIncomeChange(value)
                            }
                            data={incomeOptions}
                            value={values.income ?? ''}
                          />
                        </Col>

                        <Col xs={24} lg={12}>
                          <FormSelectField
                            size='lg'
                            locale={{ searchPlaceholder: 'Pesquisar' }}
                            placeholder='Escolha seu patrimônio'
                            labelText='Patrimônio (R$)'
                            fieldName='patrimony'
                            error={errors.patrimony}
                            touched={touched.patrimony}
                            handleOnBlur={handleBlur}
                            handleOnChange={(value) =>
                              handleSelectPatrimonyChange(value)
                            }
                            data={patrimonyOptions}
                            value={values.patrimony ?? ''}
                          />
                        </Col>
                      </Row>
                      {state?.isPF === true && (
                        <>
                          <Row>
                            <Col xs={24}>
                              <ControlLabel>
                                Selecione os tipos de contrato que o{' '}
                                {partnerName} assina
                              </ControlLabel>
                              <FormGroup>
                                <CheckboxGroup>
                                  {errors.buy_financial &&
                                    touched.buy_financial && (
                                      <div>
                                        <span style={{ color: '#EF3131' }}>
                                          {errors.buy_financial}
                                        </span>
                                      </div>
                                    )}
                                  <CheckItem
                                    checked={values.buy_financial}
                                    onChange={() =>
                                      setFieldValue(
                                        'buy_financial',
                                        !values.buy_financial
                                      )
                                    }
                                  >
                                    Envio de Recursos
                                  </CheckItem>
                                </CheckboxGroup>
                                <CheckItem
                                  checked={values.sell_financial}
                                  onChange={() =>
                                    setFieldValue(
                                      'sell_financial',
                                      !values.sell_financial
                                    )
                                  }
                                >
                                  Recebimento de Recursos
                                </CheckItem>
                                <CheckItem
                                  checked={values.import}
                                  onChange={() =>
                                    setFieldValue('import', !values.import)
                                  }
                                >
                                  Importação
                                </CheckItem>
                                <CheckItem
                                  checked={values.export}
                                  onChange={() =>
                                    setFieldValue('export', !values.export)
                                  }
                                >
                                  Exportação
                                </CheckItem>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={24}>
                              <ControlLabel>
                                Marque os campos correspondente ao {partnerName}
                              </ControlLabel>
                              <FormGroup controlId='pep'>
                                <CheckboxGroup name='pep'>
                                  <CheckBody>
                                    <Checkbox
                                      checked={values.pep}
                                      onChange={() =>
                                        setFieldValue('pep', !values.pep)
                                      }
                                    >
                                      PEP
                                    </Checkbox>
                                    <Info
                                      size={20}
                                      color='#09B2E6'
                                      style={{ marginLeft: '8px' }}
                                      onClick={() => setShow(true)}
                                    />
                                  </CheckBody>
                                  <CheckItem
                                    checked={values.reside_abroad}
                                    onChange={() =>
                                      setFieldValue(
                                        'reside_abroad',
                                        !values.reside_abroad
                                      )
                                    }
                                  >
                                    Declara IR no exterior
                                  </CheckItem>
                                </CheckboxGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row>
                        <Col xs={24} lg={12}>
                          <StyledButton
                            ghost='true'
                            onClick={() => handleBackForm()}
                          >
                            Voltar
                          </StyledButton>
                        </Col>
                        <Col xs={24} lg={12}>
                          <StyledButton
                            type='submit'
                            disabled={isSubmitting || !isValid || loading}
                          >
                            {loading ? 'Carregando' : 'Salvar dados'}
                          </StyledButton>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} lg={24}>
                          <FooterLabel>
                            * Campos obrigatórios para salvar formulário e
                            seguir com o cadastro.
                          </FooterLabel>
                        </Col>
                      </Row>
                    </Fields>
                  </Form>
                );
              }}
            </Formik>
          </FormContainer>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            size='md'
            style={{ padding: '16px 0 0 0' }}
          >
            <Modal.Title>Sou PEP</Modal.Title>
            <Modal.Body>
              <p>
                Pessoa exposta politicamente é aquela que desempenha ou tenha
                desempenhado, nos últimos cinco (5) anos, cargos, empregos ou
                funções públicas relevantes, no Brasil ou em outros países,
                territórios e dependências estrangeiros, assim como seus
                representantes, familiares e outras pessoas de seu
                relacionamento próximo. São considerados familiares e parentes,
                na linha direta, até o primeiro grau e ainda o cônjuge, o
                companheiro (a), o enteado (a). São considerados pessoas de
                relacionamento próximo, sócios, procuradores e pessoas com quem
                mantenha relacionamento de negócios em caráter contínuo.
              </p>
            </Modal.Body>
          </Modal>
        </Container>
      </LoggedLayout>
    </RouteLeavingGuard>
  );
};

export default CompanyRelationStep;
