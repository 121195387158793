import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  Container,
  Content,
  All,
  Banner,
  Title,
  Description,
  Phone,
  SuccessIcon,
  LogoImage,
} from './styles';
import Logo from '~/assets/logo.svg';
import IconSuccess from './icon-success.svg';
import Button from '~/components/Button';
import BgSuccess from './bg-success.jpg';
import {
  resetTicketOperation,
  setCheckoutStep,
  updateTicket,
  updateTicketNewStatus,
} from '~/store/modules/exchange/actions';

const CheckoutSuccess = ({ location }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(updateTicket({ data: { ticket_id: null } }));
      dispatch(updateTicketNewStatus(false));
      dispatch(resetTicketOperation());
      dispatch(setCheckoutStep(1));
    };
  }, []);

  return (
    <>
      <Container>
        <All>
          <Content>
            <LogoImage src={Logo} alt='Logo' />
            <SuccessIcon src={IconSuccess} alt='IconSuccess' />
            <Title>Pedido realizado com sucesso!</Title>
            <Description>
              Prazo estimado de confirmação de pagamento:{' '}
              <strong>01 dias úteis</strong>
            </Description>
            <Description>
              Em caso de dúvidas, entre em contato conosco via WhatsApp:{' '}
              <a
                target='__blank'
                href='https://api.whatsapp.com/send?phone=5511998656710&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20com%20meu%20pedido'
              >
                (11) 99865-6710
              </a>
            </Description>
            <Button sm onClick={() => history.push('/exchange-tickets')}>
              Ir para meus pedidos
            </Button>
          </Content>
        </All>
        <Banner>
          <img src={BgSuccess} alt='Imagem lateral' />
        </Banner>
      </Container>
    </>
  );
};

export default CheckoutSuccess;
