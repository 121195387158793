import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { FlexboxGrid, Pagination, Row, Table } from 'rsuite';
import { PlusCircle } from 'react-feather';
import history from '~/services/history';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import { api } from '../../../services/api';
import {
  JustifyCenter,
  Spacer,
  tableCellTextStyle,
  tableCellTextBlueStyle,
} from '../styles';
import TableCellDate from '../TableCellDate';
import TableCellOperacao from '../TableCellOperacao';
import TableCellStatus from '../TableCellStatus';
import TableCellValue from '../TableCellValue';
import { Spacing, Text, PaginationHolder, DetailsModal } from './styles';

import { TableOfExchangeTicketsDetails } from './details';
import TableCellPaymentForm from '../TableCellPaymentForm';

const TableOfExchangeTickets = () => {
  const auth = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.user);
  const [exchangeTickets, setExchangeTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [showModal, setShowModal] = useState(false);
  const [currentTicket, setCurrentTicket] = useState({});

  const showDetails = (ticket) => {
    setCurrentTicket(ticket);
    setShowModal(true);
  };

  const TicketDetails = ({ rowData, dataKey, ...props }) => (
    <Table.Cell style={tableCellTextBlueStyle} {...props}>
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => showDetails(rowData)}
      >
        Ver mais{' '}
        <PlusCircle size={24} color='#09B2E6' style={{ marginLeft: 5 }} />
      </span>
    </Table.Cell>
  );

  useEffect(() => {
    function fetchExchangeTickets() {
      setLoading(true);
      api.defaults.headers.Authorization = `Bearer ${auth.token}`;
      api
        .get(
          `/exchange/exchange_tickets/client/${encodeURI(profile.gnCambioId)}`,
          {
            params: {
              page_size: 10,
              page_number: activePage,
            },
          }
        )
        .then(({ data }) => {
          setExchangeTickets(data.items);
          setTotalPages(
            data.total % 10 === 0
              ? data.total / 10
              : Math.floor(data.total / 10) + 1
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }

    fetchExchangeTickets();
  }, [activePage]);

  if (loading === true) return <Loading />;
  if (exchangeTickets.length > 0)
    return (
      <>
        <DetailsModal
          onHide={() => setShowModal(false)}
          size='lg'
          show={showModal}
        >
          <TableOfExchangeTicketsDetails data={currentTicket} />
        </DetailsModal>
        <Row>
          <Table data={exchangeTickets} autoHeight>
            <Table.Column flexGrow={1} minWidth={90}>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.Cell style={tableCellTextStyle} dataKey='ticket_id' />
            </Table.Column>
            <Table.Column flexGrow={2} minWidth={190}>
              <Table.HeaderCell>Operação/Serviço</Table.HeaderCell>
              <TableCellOperacao
                style={tableCellTextStyle}
                dataKey='product_type'
              />
            </Table.Column>
            <Table.Column flexGrow={4} minWidth={270}>
              <Table.HeaderCell>Valor da operação</Table.HeaderCell>
              <TableCellValue style={tableCellTextStyle} dataKey='value' />
            </Table.Column>
            <Table.Column flexGrow={1} minWidth={120}>
              <Table.HeaderCell>Data do pedido</Table.HeaderCell>
              <TableCellDate style={tableCellTextStyle} dataKey='sale_date' />
            </Table.Column>
            <Table.Column flexGrow={2} minWidth={150}>
              <Table.HeaderCell>Meio de pagamento</Table.HeaderCell>
              <TableCellPaymentForm
                style={tableCellTextStyle}
                dataKey='code_count'
              />
            </Table.Column>
            <Table.Column flexGrow={2} minWidth={200} verticalAlign='middle'>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <TableCellStatus dataKey='status' />
            </Table.Column>
            <Table.Column flexGrow={2} minWidth={200} verticalAlign='middle'>
              <Table.HeaderCell />
              <TicketDetails dataKey='details' />
            </Table.Column>
          </Table>
          <PaginationHolder>
            <Pagination
              prev
              last
              next
              first
              pages={totalPages}
              activePage={activePage}
              onSelect={(e) => setActivePage(e)}
            />
          </PaginationHolder>
        </Row>
        <Spacer />
        <Spacer />
        <Row>
          <Button
            onClick={() => history.push('/checkout')}
            style={{ float: 'right' }}
          >
            Solicitar câmbio para viagem
          </Button>
        </Row>
        <Spacer />
        <Spacer />
      </>
    );
  return (
    <>
      <Spacing />
      <FlexboxGrid justify='center' style={{ textAlign: 'center' }}>
        <FlexboxGrid.Item colspan={10}>
          <ReactSVG src='/icons/calculadora.svg' />
          <Spacing />
          <Text>Você ainda não realizou nenhum pedido</Text>
          <Spacing />
          <JustifyCenter>
            <Button onClick={() => history.push('/checkout')}>
              Solicitar câmbio para viagem
            </Button>
          </JustifyCenter>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
};

export default TableOfExchangeTickets;
