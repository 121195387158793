const viewportWidthSizes = {
  xs: 480,
  sm: 768,
  md: 900,
  lg: 1200,
  xl: 1536,
};

export function getActualViewPort() {
  const viewportWidth = window.innerWidth;
}

export function isLg() {
  const viewportWidth = window.innerWidth;

  return !!(
    viewportWidth >= viewportWidthSizes.lg &&
    viewportWidth <= viewportWidthSizes.xl
  );
}

export function isMd() {
  const viewportWidth = window.innerWidth;

  return !!(
    viewportWidth >= viewportWidthSizes.md &&
    viewportWidth <= viewportWidthSizes.lg
  );
}

export function isXs() {
  const viewportWidth = window.innerWidth;

  return !!(viewportWidth < viewportWidthSizes.sm);
}
