import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '~/components/Button';
import { api } from '~/services/api';
import history from '~/services/history';
import { setBank } from '~/store/modules/bank/actions';
import { getBanks } from '~/store/modules/banklist/actions';
import {
  BodyCard,
  BtnExcluir,
  CardNumber,
  DisplayItems,
  Items,
  StyledCard,
  TitleCard,
} from './styles';

const BankCard = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const auth = useSelector((state) => state.auth);

  const getBankName = (id) => {
    const bank = props.banks.find((item) => id === item.id);
    return bank.name;
  };

  const edit = async (bank, e) => {
    await dispatch(setBank(bank));

    return history.push('/update-bank/new');
  };

  const deleteBank = async () => {
    const idBank = props.data._id;
    setLoading(true);

    await api
      .delete(`/bank_data/${idBank}`)
      .then((_) => {
        toast.success('Conta bancária foi removida com sucesso.');
      })
      .catch((_) => {
        toast.error(
          'Houve um problema ao excluir os dados, tente novamente dentro de alguns minutos.'
        );
      })
      .finally(() => {
        dispatch(getBanks(props.userId, auth.token));
        setLoading(false);
      });
  };

  return (
    <>
      <StyledCard>
        <BodyCard>
          <CardNumber>CONTA #{props.idx + 1}</CardNumber>
          <TitleCard>{getBankName(props.data.bankId)}</TitleCard>
          <Items key={props.data.accountNumber} name={props.data.name}>
            <DisplayItems>
              <span>Agência: </span>
              <span>
                <b>{props.data.agencyNumber}</b>
              </span>
            </DisplayItems>
            <hr />
            <DisplayItems>
              <span>Conta: </span>
              <span>
                <b>
                  {props.data.accountNumber} - {props.data.accountDigit}
                </b>
              </span>
            </DisplayItems>
            <hr />{' '}
            <DisplayItems>
              <span>Tipo de conta:</span>
              <span>
                <b>
                  {props.data.accountType === 'conta-corrente'
                    ? 'Corrente'
                    : 'Poupança'}
                </b>
              </span>
            </DisplayItems>
            <hr />
          </Items>
          <Button ghost='true' onClick={(e) => edit(props.data, e)}>
            Editar
          </Button>
          <BtnExcluir
            appearance='subtle'
            disabled={loading}
            onClick={() => deleteBank()}
          >
            Excluir conta
          </BtnExcluir>
        </BodyCard>
      </StyledCard>
    </>
  );
};

export default BankCard;
