export function skipIntrodution(value) {
  return {
    type: '@user/SKIP_INTRODUTION',
    payload: value,
  };
}

export function addIntrodution(document, password) {
  return {
    type: '@user/ADD_INTRODUTION',
  };
}

export function toggleUserbar() {
  return {
    type: '@user/TOGGLE_USERBAR',
  };
}

export function toggleMenu() {
  return {
    type: '@user/TOGGLE_MENU',
  };
}

export function updateUser(user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: user,
  };
}

export function getAddress(cep) {
  return {
    type: '@user/GET_ADDRESS',
    payload: cep,
  };
}

export function updateAddress(cep) {
  return {
    type: '@user/UPDATE_ADDRESS',
    payload: cep,
  };
}

export function setNewHuggyId(id) {
  return {
    type: '@user/SET_NEW_HUGGY_ID',
    payload: id,
  };
}

export function setHuggyId(id) {
  return {
    type: '@user/SET_HUGGY_ID',
    payload: id,
  };
}
