import produce from 'immer';

const INITIAL_STATE = {
  banks: [],
};

export default function bankList(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@bankList/SAVE_BANKS': {
        draft.banks = action.payload.banks;
        break;
      }

      default:
    }
  });
}
