export function setPartner(partner) {
  return {
    type: '@partner/SET_PARTNER',
    payload: partner,
  };
}

export function removePartner() {
  return {
    type: '@partner/REMOVE_PARTNER',
  };
}
