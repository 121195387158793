import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import {
  resetTicketOperation,
  setCheckoutStep,
  updateTicketNewStatus,
} from '~/store/modules/exchange/actions';
import Button from '../Button';
import { Spacer } from '../Spacer';
import Bottom from './Bottom';
import StepperCalculator from './StepperCalculator';
import Header from './Header';
import OperationLimit from './OperationLimit';
import {
  Container,
  IncompleteMessageContainer,
  RegisterLinkContainer,
  Wrapper,
} from './styles';
import { isExchangeValueMinimalToAllowTransaction } from '~/utils/getUsdValueBase';
import CardContent from '~/pages/Home/CardContent';
import SideModal from '../SideModalCalculator';
import OperationCalculator from './OperationCalculator';

const SimulateMoneyForTravel = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [canGoToNextPage, setCanGoToNextPage] = useState(false);
  const { isRelationship, idWallUserRestrict } = useSelector(
    (state) => state.user?.profile
  );

  const exchange = useSelector((state) => state.exchange);
  const minUsdValueToValidation = 1000;
  const minUsdValueToValidationCard = 500;
  const maxUsdValueToValidation = 3000;

  const style = {
    margin: '24px 32px',
    display: 'flex',
    flexDirection: 'column',
    filter: 'blur(10px)',
  };

  if (isRelationship === true && idWallUserRestrict === false)
    style.filter = '';

  useEffect(() => {
    dispatch(resetTicketOperation());
  }, []);

  const goToCheckoutPage = async () => {
    if (exchange.selectedProduct.currencyToValue) {
      dispatch(updateTicketNewStatus(true));
      dispatch(setCheckoutStep(2));
    }

    history.push('/checkout');
  };

  useEffect(() => {
    const {
      selectedProduct: {
        productId,
        productType,
        currencyFromValue,
        currencyToValue,
        currencyUsdBaseToTax,
        virtualStoreMinValue,
      },
    } = exchange;
    let isValid = false;

    const maskedValue = Math.ceil(currencyToValue);
    const multiple = maskedValue % virtualStoreMinValue;

    const allowedTransaction = isExchangeValueMinimalToAllowTransaction(
      minUsdValueToValidation,
      maxUsdValueToValidation,
      currencyFromValue,
      currencyUsdBaseToTax
    );

    if (productType === 'ESPECIE' && allowedTransaction && multiple === 0) {
      isValid = true;
    } else if (
      productType === 'CARTAO' &&
      productId &&
      currencyFromValue > 0 &&
      currencyToValue > 0 &&
      currencyFromValue >= minUsdValueToValidationCard
    ) {
      isValid = true;
    }

    if (canGoToNextPage != isValid) setCanGoToNextPage(isValid);
  }, [exchange.selectedProduct]);

  const [openCalc, setOpenCalcModel] = useState(false);

  return (
    <Wrapper>
      <OperationLimit />

      <SideModal open={openCalc} setOpen={setOpenCalcModel} />

      <OperationCalculator
        full={false}
        hg
        alt
        style={{ height: '100%', margin: 0 }}
        setOpen={() => setOpenCalcModel(true)}
      />

      {/*  
      <CardContent full={false} hg alt style={{ height: '100%', margin: 0 }}>
        <Container>
          {(isRelationship === false || idWallUserRestrict === true) && (
            <IncompleteMessageContainer>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: '32px',
                  color: '#20315B',
                  width: '330px',
                  lineHeight: '40px',
                  fontFamily: 'IBM Plex Sans',
                }}
              >
                Complete <span style={{ color: '#EF3131' }}>seus dados</span>{' '}
                solicitar serviços na Advanced
              </p>

              <Spacer top='20px' />

              <RegisterLinkContainer>
                <a onClick={() => history.push('/update-data')}>
                  Abrir meu cadastro
                  <FiArrowRight size={24} color='#09B2E6' />
                </a>
              </RegisterLinkContainer>
            </IncompleteMessageContainer>
          )}

          <div style={style}>
            <Header />

            <Spacer top='24px' />

            <StepperCalculator />

            <Spacer top='24px' />

            <Button
              ghost='true'
              onClick={() => props.setOpen(true)}
              disabled={!canGoToNextPage}
            >
              Solicitar dinheiro para viagem
            </Button>

            <Spacer top='24px' />

            <Bottom />
          </div>
        </Container>
      </CardContent>
      */}
    </Wrapper>
  );
};

export default SimulateMoneyForTravel;
