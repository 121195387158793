import * as dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, ControlLabel, FormGroup, Row } from 'rsuite';
import * as Yup from 'yup';
import FilesList from '../../../../components/FilesList';
import FormSelectField from '../../../../components/Form/form-select-field';
import FormTextField from '../../../../components/Form/form-text-field';
import InfoBar from '../../../../components/InfoBar';
import { InputUpload } from '../../../../components/InputUpload';
import LoggedLayout, {
  LayoutContent,
} from '../../../../components/LoggedLayout';
import { Fields } from '../../../../components/styles/form';
import WizardHeader from '../../../../components/WizardHeader';
import cnpjMask from '../../../../mask/cnpj';
import cpfMask from '../../../../mask/cpf';
import { RouteLeavingGuard } from '../../../../routes/route-leaving-guard';
import { api } from '../../../../services/api';
import history from '../../../../services/history';
import { testCNPJ } from '../../../../utils/cnpjTest';
import testCpf from '../../../../utils/cpfTest';
import { SquareButton } from '../../../UpdatePersonalData/style';
import { saveFirstStep } from '../partner-service';
import {
  Container,
  FormContainer,
  Step,
  StyledButton,
  Title,
  FooterLabel,
} from './styles';

const PersonalDataStep = (props) => {
  const { state } = props.location;
  const formik = React.useRef();
  const partnerName = state?.isAttorney ? 'procurador' : 'sócio';
  const { auth, user } = useSelector((s) => s);
  const [countries, setCountries] = useState([]);
  const [activities, setActivities] = useState([]);
  const [isPF, setPF] = useState(true);

  const PFValidationSchema = Yup.object().shape({
    document: Yup.string()
      .required('Obrigatório')
      .test('cpf', 'CPF Inválido', (value) => testCpf(value)),
    dateBirth: Yup.date()
      .required('Obrigatório')
      .test('dateBirth', 'Deve ser maior que 18 anos', (val) => {
        const selectedDate = dayjs(val);
        const eighteenYearsAgo = dayjs().subtract(18, 'year');
        return selectedDate.isBefore(eighteenYearsAgo);
      }),
    name: Yup.string().required('Obrigatório'),
    lastName: Yup.string().required('Obrigatório'),
    stateBirth: Yup.string().required('Obrigatório').nullable(),
    cityBirth: Yup.string().required('Obrigatório'),
    motherName: Yup.string().required('Obrigatório'),
    fatherName: Yup.string(),
    countryBirth: Yup.string().required('Obrigatório').nullable(),
    email: Yup.string().required('Obrigatório').email('E-mail inválido'),
  });

  const PJValidationSchema = Yup.object().shape({
    document: Yup.string()
      .required('Obrigatório')
      .test('CNPJ', 'CNPJ Inválido', (value) => testCNPJ(value)),
    companyName: Yup.string().required('Obrigatório'),
    issuingExpeditionDate: Yup.date()
      .required('Obrigatório')
      .test('dateBirth', 'Data não pode ser maior que hoje', (val) => {
        const selectedDate = dayjs(val);
        const today = dayjs();
        return selectedDate.isBefore(today);
      }),
    issuingCountry: Yup.string().required('Obrigatório').nullable(),
    economicActivity: Yup.string().required('Obrigatório').nullable(),
    email: Yup.string().required('Obrigatório').email('E-mail inválido'),
    file: Yup.string().required('Obrigatório'),
  });

  const stateOptions = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Distrito Federal', value: 'DF' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
  ];

  const saveStep = async (data) => {
    try {
      const id = await saveFirstStep(
        auth.token,
        {
          ...data,
          isAttorney: state.isAttorney,
        },
        user.profile._id,
        state._id
      );
      toast.success('Dados salvos com sucesso!');
      return id;
    } catch (ex) {
      toast.error('Ocorreu um erro');
      throw ex;
    }
  };

  const handleNextStep = async (data) => {
    const newData = { ...state, ...data };
    const id = await saveStep(newData);
    if (data.isPF)
      history.push('/update-data/partners/new/2', { _id: id, ...newData });
    else history.push('/update-data/partners/new/3', { _id: id, ...newData });
  };

  async function getActivities() {
    api.defaults.headers.Authorization = `Bearer ${auth.token}`;
    const response = await api.get('/lists/activities');
    setActivities(response.data);
    return response.data;
  }

  useEffect(() => {
    const actualData = [];
    api.defaults.headers.Authorization = `Bearer ${auth.token}`;
    api.get('/lists/countries').then(({ data }) => {
      data.map((item) =>
        actualData.push({ label: item.country, value: item.id })
      );

      setCountries(actualData.reverse());
    });
    getActivities();
  }, [auth.token]);

  return (
    <RouteLeavingGuard
      shouldBlockNavigation={() =>
        formik?.current?.dirty === true || formik?.current.touched === true
      }
      onSaveAndExit={() => saveStep(formik.current?.values)}
      onCanSave={() => formik.current?.dirty && formik.current?.isValid}
    >
      <LoggedLayout>
        <Container>
          <WizardHeader showLogo={false}>
            <div style={{ marginRight: 20 }}>
              <Step active>1</Step>
              <span className='step-title'>Info. pessoais</span>
            </div>
            {isPF ? (
              <>
                <div style={{ marginRight: 20 }}>
                  <Step>2</Step>
                  <span>Documentação</span>
                </div>
                <div style={{ marginRight: 20 }}>
                  <Step>3</Step>
                  <span>Endereço</span>
                </div>
                <div style={{ marginRight: 20 }}>
                  <Step>4</Step>
                  <span>Relação com a empresa</span>
                </div>
              </>
            ) : (
              <>
                <div style={{ marginRight: 20 }}>
                  <Step>2</Step>
                  <span>Endereço</span>
                </div>
                <div style={{ marginRight: 20 }}>
                  <Step>3</Step>
                  <span>Relação com a empresa</span>
                </div>
              </>
            )}
          </WizardHeader>
          <LayoutContent style={{ width: 'inherit' }}>
            <InfoBar title={`Adicionando novo ${partnerName}`} />
            <FormContainer>
              <Title>
                <span>Etapa 1</span>
                {isPF ? (
                  <h3>Informações pessoais</h3>
                ) : (
                  <h3>Informações da empresa</h3>
                )}
              </Title>
              <Formik
                innerRef={formik}
                validationSchema={
                  isPF === false ? PJValidationSchema : PFValidationSchema
                }
                initialValues={{
                  isPF: state?.isPF ?? true,
                  document: state?.document ?? '',
                  name: state?.name ?? '',
                  lastName: state?.lastName ?? '',
                  companyName: state?.companyName ?? '',
                  dateBirth: state?.dateBirth
                    ? state?.dateBirth.split('T')[0]
                    : '',
                  stateBirth: state?.stateBirth ?? '',
                  economicActivity: state?.economicActivity ?? '',
                  cityBirth: state?.cityBirth ?? '',
                  issuingExpeditionDate: state?.issuingExpeditionDate
                    ? state?.issuingExpeditionDate.split('T')[0]
                    : '',
                  countryBirth:
                    state?.countryBirth ??
                    '010cac00-4a4a-e911-a966-000d3ac1b38b',
                  issuingCountry:
                    state?.issuingCountry ??
                    '010cac00-4a4a-e911-a966-000d3ac1b38b',
                  motherName: state?.motherName ?? '',
                  fatherName: state?.fatherName ?? '',
                  email: state?.email ?? '',
                  file: state?.file ?? [],
                }}
                onSubmit={async (values) => {
                  await handleNextStep(values);
                }}
              >
                {({
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  touched,
                  setFieldValue,
                  isSubmitting,
                  isValid,
                }) => {
                  const handleSelectCountryBirthChange = (value) => {
                    setFieldValue('countryBirth', value);
                  };

                  const handleSelectIssuingCountryChange = (value) => {
                    setFieldValue('issuingCountry', value);
                  };

                  const handleSelectChange = (value) => {
                    setFieldValue('economicActivity', value);
                  };

                  const FileChange = (value) => {
                    setFieldValue('file', [...values.file, ...value]);
                  };
                  const FileRemove = (value) => {
                    const filter = values.file.filter((item) => {
                      return item.path !== value;
                    });

                    setFieldValue('file', filter);
                  };

                  const handleSelectStateChange = (value) => {
                    setFieldValue('stateBirth', value);
                  };

                  return (
                    <Form>
                      <Fields className='fieldsName'>
                        {!state.isAttorney ? (
                          <Row>
                            <Col xs={24}>
                              <ControlLabel>
                                Selecione o perfil do {partnerName}:
                              </ControlLabel>
                              <FormGroup controlId='personType'>
                                <SquareButton
                                  onClick={() => {
                                    setFieldValue('isPF', true);
                                    setPF(true);
                                  }}
                                  value={values.isPF}
                                  label='Pessoa Física'
                                  name='isPF'
                                  checked={values.isPF === true}
                                />
                                <SquareButton
                                  onClick={() => {
                                    setFieldValue('isPF', false);
                                    setPF(false);
                                  }}
                                  value={values.isPF}
                                  label='Pessoa Jurídica'
                                  name='isPF'
                                  checked={values.isPF === false}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        ) : (
                          <></>
                        )}
                        <Row>
                          <Col xs={24} lg={24}>
                            {isPF ? (
                              <FormTextField
                                labelText='CPF *'
                                fieldName='document'
                                error={errors.document}
                                touched={touched.document}
                                value={cpfMask(values.document)}
                                handleOnBlur={handleBlur}
                                handleOnChange={(_, event) =>
                                  handleChange(event)
                                }
                                disabled={state._id !== undefined}
                              />
                            ) : (
                              <FormTextField
                                labelText='CNPJ *'
                                fieldName='document'
                                error={errors.document}
                                touched={touched.document}
                                value={cnpjMask(values.document)}
                                handleOnBlur={handleBlur}
                                handleOnChange={(_, event) =>
                                  handleChange(event)
                                }
                                disabled={state._id !== undefined}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          {isPF ? (
                            <>
                              <Col xs={24} lg={12}>
                                <FormTextField
                                  labelText='Nome *'
                                  fieldName='name'
                                  value={values.name}
                                  error={errors.name}
                                  touched={touched.name}
                                  handleOnBlur={handleBlur}
                                  handleOnChange={(_, event) =>
                                    handleChange(event)
                                  }
                                />
                              </Col>
                              <Col xs={24} lg={12}>
                                <FormTextField
                                  labelText='Sobrenome *'
                                  fieldName='lastName'
                                  value={values.lastName}
                                  error={errors.lastName}
                                  touched={touched.lastName}
                                  handleOnBlur={handleBlur}
                                  handleOnChange={(_, event) =>
                                    handleChange(event)
                                  }
                                />
                              </Col>
                            </>
                          ) : (
                            <Col xs={24} lg={24}>
                              <FormTextField
                                labelText='Razão Social *'
                                fieldName='companyName'
                                value={values.companyName}
                                error={errors.companyName}
                                touched={touched.companyName}
                                handleOnBlur={handleBlur}
                                handleOnChange={(_, event) =>
                                  handleChange(event)
                                }
                              />
                            </Col>
                          )}
                        </Row>
                        <Row>
                          {isPF ? (
                            <>
                              <Col xs={24} lg={12}>
                                <FormTextField
                                  labelText='Data de nascimento *'
                                  fieldName='dateBirth'
                                  value={values.dateBirth ?? ''}
                                  error={errors.dateBirth}
                                  touched={touched.dateBirth}
                                  handleOnBlur={handleBlur}
                                  handleOnChange={(_, event) =>
                                    handleChange(event)
                                  }
                                  inputType='date'
                                  maxDate={
                                    new Date().toISOString().split('T')[0]
                                  }
                                />
                              </Col>
                              <Col xs={24} lg={12}>
                                <FormSelectField
                                  size='lg'
                                  locale={{ searchPlaceholder: 'Pesquisar' }}
                                  placeholder={
                                    countries?.length
                                      ? 'Escolha seu país...'
                                      : 'Carregando...'
                                  }
                                  labelText='País de Nascimento *'
                                  fieldName='countryBirth'
                                  error={errors.countryBirth}
                                  touched={touched.countryBirth}
                                  handleOnBlur={handleBlur}
                                  handleOnChange={(value) =>
                                    handleSelectCountryBirthChange(value)
                                  }
                                  data={countries}
                                  value={values.countryBirth ?? ''}
                                />
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col xs={24} lg={12}>
                                <FormTextField
                                  labelText='Data de Abertura *'
                                  fieldName='issuingExpeditionDate'
                                  error={errors.issuingExpeditionDate}
                                  touched={touched.issuingExpeditionDate}
                                  value={values.issuingExpeditionDate}
                                  handleOnBlur={handleBlur}
                                  handleOnChange={(_, event) =>
                                    handleChange(event)
                                  }
                                  inputType='date'
                                  maxDate={
                                    new Date().toISOString().split('T')[0]
                                  }
                                />
                              </Col>
                              <Col xs={24} lg={12}>
                                <FormSelectField
                                  size='lg'
                                  locale={{ searchPlaceholder: 'Pesquisar' }}
                                  placeholder={
                                    countries?.length
                                      ? 'Escolha seu país...'
                                      : 'Carregando...'
                                  }
                                  labelText='País de emissão *'
                                  fieldName='issuingCountry'
                                  error={errors.issuingCountry}
                                  touched={touched.issuingCountry}
                                  handleOnBlur={handleBlur}
                                  handleOnChange={(value) =>
                                    handleSelectIssuingCountryChange(value)
                                  }
                                  data={countries}
                                  value={values.issuingCountry ?? ''}
                                />
                              </Col>
                            </>
                          )}
                        </Row>

                        <Row>
                          {isPF ? (
                            <>
                              <Col xs={24} lg={12}>
                                <FormSelectField
                                  labelText='Estado de nascimento *'
                                  fieldName='stateBirth'
                                  error={errors.stateBirth}
                                  touched={touched.stateBirth}
                                  size='lg'
                                  placeholder='Escolha o estado'
                                  locale={{ searchPlaceholder: 'Pesquisar' }}
                                  handleOnChange={(value) =>
                                    handleSelectStateChange(value)
                                  }
                                  handleOnBlur={handleBlur}
                                  data={stateOptions}
                                  value={values.stateBirth ?? ''}
                                />
                              </Col>
                              <Col xs={24} lg={12}>
                                <FormTextField
                                  labelText='Cidade de nascimento *'
                                  fieldName='cityBirth'
                                  value={values.cityBirth}
                                  error={errors.cityBirth}
                                  touched={touched.cityBirth}
                                  handleOnBlur={handleBlur}
                                  handleOnChange={(_, event) =>
                                    handleChange(event)
                                  }
                                />
                              </Col>
                            </>
                          ) : (
                            <Col xs={24} lg={24}>
                              <FormSelectField
                                size='lg'
                                locale={{ searchPlaceholder: 'Pesquisar' }}
                                labelText='Atividade Econômica *'
                                placeholder='Escolha a atividade econômica'
                                fieldName='economicActivity'
                                error={errors.economicActivity}
                                touched={touched.economicActivity}
                                handleOnBlur={handleBlur}
                                handleOnChange={(value) =>
                                  handleSelectChange(value)
                                }
                                data={activities.map((activity) => ({
                                  label: activity.occupation,
                                  value: activity.id,
                                }))}
                                value={values.economicActivity ?? ''}
                              />
                            </Col>
                          )}
                        </Row>
                        {isPF && (
                          <>
                            <Row>
                              <Col xs={24} lg={24}>
                                <FormTextField
                                  labelText='Nome completo da mãe *'
                                  fieldName='motherName'
                                  value={values.motherName}
                                  error={errors.motherName}
                                  touched={touched.motherName}
                                  handleOnBlur={handleBlur}
                                  handleOnChange={(_, event) =>
                                    handleChange(event)
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={24} lg={24}>
                                <FormTextField
                                  labelText='Nome completo do pai (Opcional)'
                                  fieldName='fatherName'
                                  value={values.fatherName}
                                  error={errors.fatherName}
                                  touched={touched.fatherName}
                                  handleOnBlur={handleBlur}
                                  handleOnChange={(_, event) =>
                                    handleChange(event)
                                  }
                                />
                              </Col>
                            </Row>
                          </>
                        )}

                        <Row>
                          <Col xs={24} lg={24}>
                            <FormTextField
                              type='email'
                              labelText='Email *'
                              fieldName='email'
                              value={values.email}
                              error={errors.email}
                              touched={touched.email}
                              handleOnBlur={handleBlur}
                              handleOnChange={(_, event) => handleChange(event)}
                            />
                          </Col>
                        </Row>
                        {!isPF && (
                          <Row>
                            <Col xs={24} lg={24}>
                              <ControlLabel>
                                Documento de identificação do {partnerName},
                                como o contrato social
                              </ControlLabel>
                              <FormGroup>
                                <InputUpload
                                  data={values.file}
                                  onChange={FileChange}
                                  removeFile={FileRemove}
                                />
                              </FormGroup>
                              <FilesList nameClass='Comprovante de Endereço' />
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col xs={24} lg={24}>
                            {isSubmitting ? (
                              <StyledButton disabled>Carregando</StyledButton>
                            ) : (
                              <StyledButton
                                type='submit'
                                disabled={isSubmitting || !isValid || !values}
                              >
                                Próxima Etapa
                              </StyledButton>
                            )}

                            <FooterLabel>
                              * Campos obrigatórios para salvar formulário e
                              seguir com o cadastro.
                            </FooterLabel>
                          </Col>
                        </Row>
                      </Fields>
                    </Form>
                  );
                }}
              </Formik>
            </FormContainer>
          </LayoutContent>
        </Container>
      </LoggedLayout>
    </RouteLeavingGuard>
  );
};

export default PersonalDataStep;
