import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ArrowLeft, Info } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';
import {
  Breadcrumb,
  Col,
  ControlLabel,
  Form,
  FormGroup,
  Row,
  Whisper,
} from 'rsuite';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as Yup from 'yup';
import ProfessionMobileIcon from '~/assets/profissao_mobile.svg';
import { MobileHeaderData } from '~/components/HeaderData';
import UploadFiles from '~/components/UploadFiles';
import * as fileUploaderService from '~/services/files';
import LoggedLayout, { LayoutContent } from '~/components/LoggedLayout';
import {
  BackButton,
  Container,
  DisplayGrid,
  Fields,
  FormGrid,
  StyledInput,
  StyledMaskedInput,
  StyledSelect,
  Title,
} from '~/components/styles/form.js';
import cpfMask from '~/mask/cpf';
import { StyledButton } from '~/pages/UpdatePersonalData/style';
import { api } from '~/services/api';
import {
  DEFAULT_ALLOWED_FILE_TYPES,
  DEFAULT_SIZE_LIMIT,
} from '~/services/files';
import { postProfession } from '~/services/profession';
import { updateUser } from '~/store/modules/user/actions';
import {
  calculatePercentPFProfission,
  calculatePercentPFProfissionProspect,
} from '~/utils/calculate-percent';
import {
  Sidebar,
  SidebarContent,
  SidebarIcon,
  SidebarSubtitle,
  SidebarTitle,
} from '../../../components/Sidebar';
import history from '../../../services/history';
import ErrorModal from '~/components/ErrorModal';
import { InfoStatusComplete, InfoStatusOptional, TooltipLimit } from './styles';

const maskOptions = {
  prefix: 'R$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 10, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const Profession = () => {
  const dispatch = useDispatch();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [uploadProgressFileList, setUploadProgressFileList] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [errorData, setErrorData] = useState({});

  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const {
    idProfission,
    companyWhereWorkName,
    monthlyIncome,
    patrimony,
    dynamicsId,
    isRelationship,
  } = user.profile;

  const documentId = cpfMask(user.profile.document);
  const documentClassName = 'Documento Financeiro';
  const documentType = 'Tipo Documento Financeiro';
  const documentProperty = 'Outros';

  const { _id } = useSelector((state) => state.user.profile);
  const [percentage, setPercentage] = useState(0);

  const ProfessionSchema = Yup.object().shape({
    files: Yup.array(),
    // files: Yup.array().required('É obrigatório o envio do arquivo'),
  });

  const currencyMask = createNumberMask({ ...maskOptions });

  const occupationsOrder = () => {
    const data = occupations.map((occupation) => ({
      label: occupation.occupation,
      value: occupation.id,
    }));

    return data.sort((a, b) => a.label.localeCompare(b.label));
  };

  useEffect(() => {
    window.amplitude.getInstance().logEvent('access Profession page');
  }, []);

  useEffect(() => {
    setPercentage(
      user.profile.relationship
        ? calculatePercentPFProfissionProspect(user.profile)
        : calculatePercentPFProfission(user.profile)
    );
  }, [
    idProfission,
    companyWhereWorkName,
    monthlyIncome,
    patrimony,
    monthlyIncome,
    patrimony,
  ]);

  useEffect(() => {
    try {
      api.defaults.headers.Authorization = `Bearer ${auth.token}`;
      api
        .get(`/lists/occupations`)
        .then((item) => {
          const data = item.data.map((occupation) => ({
            label: occupation.occupation,
            value: occupation.id,
          }));

          setOccupations(data.sort((a, b) => a.label.localeCompare(b.label)));
        })
        .catch((item) => {
          setShowModal(true);
          setErrorData({
            title: 'Erro ao processar dados',
            description: 'Houve um problema ao buscar lista de profissões.',
          });

          // toast.error('Houve um problema ao buscar lista de profissões.');
        });
    } catch (err) {
      setShowModal(true);
      setErrorData({
        title: 'Erro ao processar dados',
        description: 'Houve um problema ao buscar lista de profissões.',
      });

      // toast.error('Houve um problema ao buscar lista de profissões.');
    }
  }, []);

  const tooltip = (
    <TooltipLimit>
      Exigido somente para limites operacionais acima de R$ 120.000
    </TooltipLimit>
  );

  return (
    <LoggedLayout
      isUploading={fileIsUploading}
      uploadProgressFileList={uploadProgressFileList}
    >
      <Container className='responsive-container'>
        <Sidebar>
          <SidebarContent>
            <SidebarIcon />
            <SidebarTitle internal>Profissão</SidebarTitle>
            <SidebarSubtitle internal>
              Informações gerais sobre sua profissão, renda e etc
            </SidebarSubtitle>

            {percentage === 100 ? (
              <InfoStatusComplete>Concluído</InfoStatusComplete>
            ) : (
              <InfoStatusOptional>
                Opcional
                <Whisper
                  placement='bottom'
                  controlId='control-id-hover'
                  trigger='hover'
                  speaker={tooltip}
                >
                  <Info
                    size={24}
                    color='#09B2E6'
                    style={{ marginLeft: '8px' }}
                  />
                </Whisper>
              </InfoStatusOptional>
            )}
          </SidebarContent>
        </Sidebar>

        <MobileHeaderData
          title='Profissão'
          percent={percentage}
          icon={ProfessionMobileIcon}
        />

        <LayoutContent>
          <ErrorModal
            showModal={showModal}
            onChange={() => setShowModal(false)}
            {...errorData}
          />

          <Formik
            initialValues={{
              profission: idProfission,
              companyWhereWorkName,
              monthlyIncome: monthlyIncome
                ? monthlyIncome.toString().replace('.', ',')
                : '',
              patrimony: patrimony
                ? patrimony.toString().replace('.', ',')
                : '',
              file: '',
            }}
            validationSchema={ProfessionSchema}
            onSubmit={async (values, action) => {
              try {
                action.setSubmitting(true);

                if (filesToUpload.length > 0) {
                  setFileIsUploading(true);
                  let uploadList = filesToUpload.map((f) => {
                    return {
                      id: f.name,
                      progress: 0,
                    };
                  });
                  await Promise.all(
                    filesToUpload.map((item) =>
                      fileUploaderService
                        .UploadFiles(
                          item,
                          documentId,
                          documentClassName,
                          documentType,
                          documentProperty,
                          (progress) => {
                            uploadList = uploadList.map((up) => {
                              return {
                                id: up.id,
                                progress:
                                  up.id == item.name ? progress : up.progress,
                              };
                            });
                            setUploadProgressFileList([...uploadList]);
                          }
                        )
                        .then(() => {
                          uploadList = uploadList.map((up) => {
                            return {
                              id: up.id,
                              progress: up.id == item.name ? 100 : up.progress,
                            };
                          });
                          setUploadProgressFileList([...uploadList]);
                        })
                    )
                  );
                  setFileIsUploading(false);
                }

                const monthlyIncomeParsed = values.monthlyIncome
                  ? Number(
                      parseFloat(
                        typeof values.monthlyIncome === 'string'
                          ? values.monthlyIncome
                              .replaceAll('.', '')
                              .replace('R$', '')
                              .replace(',', '.')
                          : values.monthlyIncome
                      ).toFixed(2)
                    )
                  : 0;

                const patrimonyParsed = values.patrimony
                  ? Number(
                      parseFloat(
                        typeof values.patrimony === 'string'
                          ? values.patrimony
                              .replaceAll('.', '')
                              .replace('R$', '')
                              .replace(',', '.')
                          : values.patrimony
                      ).toFixed(2)
                    )
                  : 0;

                postProfession(
                  _id,
                  values.profission,
                  values.companyWhereWorkName,
                  monthlyIncomeParsed,
                  patrimonyParsed
                )
                  .then((item) => {
                    dispatch(
                      updateUser({
                        user: item.data,
                      })
                    );
                    toast.success('Alterações realizadas com sucesso.');
                    window.amplitude
                      .getInstance()
                      .logEvent('Updated profession data', item);
                    history.replace('/update-data');
                  })
                  .catch((error) => {
                    setShowModal(true);
                    setErrorData({
                      title: 'Erro ao processar dados',
                      description:
                        'Tivemos um problema no envio das informações, revise o preenchimento dos campos obrigatórios ou tente novamente.',
                    });

                    // toast.error('Houve um problema ao enviar as informações.');
                    action.setSubmitting(false);
                  });
              } catch (err) {
                setShowModal(true);
                setErrorData({
                  title: 'Erro ao processar dados',
                  description:
                    'Tivemos um problema no envio das informações, revise o preenchimento dos campos obrigatórios ou tente novamente.',
                });

                // toast.error('Houve um problema ao enviar as infromações.');
                action.setSubmitting(false);
                window.amplitude
                  .getInstance()
                  .logEvent('Error to update client', err);
              }
            }}
          >
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              touched,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }) => {
              const handleSelectChange = (value, event) => {
                setFieldValue('profission', value);
              };

              const onFileChanged = (files) => {
                setFieldValue('files', files);
              };

              return (
                <Form onSubmit={handleSubmit}>
                  <DisplayGrid>
                    <FormGrid>
                      <Breadcrumb className='breadcrumb'>
                        <Link to='/home'>Home</Link>
                        <Link to='/update-data'>Meus dados</Link>
                        <Link className='active' to='/update-data/profession'>
                          Profissão
                        </Link>
                      </Breadcrumb>

                      <BackButton className='back-button'>
                        <ArrowLeft />
                        <Link to='/update-data'>
                          <p>Voltar</p>
                        </Link>
                      </BackButton>
                      <Fields className='fieldsName'>
                        <Title>Profissão e renda</Title>
                        <Row>
                          <Col xs={24}>
                            <FormGroup>
                              <ControlLabel>Sua profissão</ControlLabel>
                              <StyledSelect
                                size='lg'
                                placeholder='Profissão'
                                locale={{
                                  searchPlaceholder: 'Pesquisar',
                                }}
                                onChange={(value, event) =>
                                  handleSelectChange(value)
                                }
                                value={values.profission}
                                data={occupations}
                              />
                              {errors.profission && touched.profission && (
                                <span>{errors.profission}</span>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          {!user.profile.relationship && (
                            <Col xs={24}>
                              <FormGroup>
                                <ControlLabel>
                                  Empresa onde trabalha
                                </ControlLabel>
                                <StyledInput
                                  onChange={(value, event) =>
                                    handleChange(event)
                                  }
                                  onBlur={handleBlur}
                                  name='companyWhereWorkName'
                                  id='companyWhereWorkName'
                                  value={values.companyWhereWorkName}
                                  type='text'
                                />
                                {errors.companyWhereWorkName &&
                                  touched.companyWhereWorkName && (
                                    <span>{errors.companyWhereWorkName}</span>
                                  )}
                              </FormGroup>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col xs={24} lg={12}>
                            <FormGroup>
                              <ControlLabel>Renda mensal (R$)</ControlLabel>
                              <MaskedInput
                                mask={currencyMask}
                                onChange={(value, event) => handleChange(value)}
                                onBlur={handleBlur}
                                name='monthlyIncome'
                                id='monthlyIncome'
                                value={values.monthlyIncome}
                                render={(ref, props) => (
                                  <StyledMaskedInput
                                    type='text'
                                    className='rs-input'
                                    ref={ref}
                                    {...props}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={24} lg={12}>
                            <FormGroup>
                              <ControlLabel>Patrimônio (R$)</ControlLabel>
                              <MaskedInput
                                mask={currencyMask}
                                onChange={(value) => handleChange(value)}
                                onBlur={handleBlur}
                                name='patrimony'
                                id='patrimony'
                                value={values.patrimony}
                                render={(ref, props) => (
                                  <StyledMaskedInput
                                    type='text'
                                    className='rs-input'
                                    ref={ref}
                                    {...props}
                                  />
                                )}
                              />
                              {errors.patrimony && touched.patrimony && (
                                <span>{errors.patrimony}</span>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Fields>
                      <StyledButton
                        type='submit'
                        disabled={
                          isRelationship === true
                            ? isSubmitting
                            : isSubmitting || !filesToUpload.length
                        }
                      >
                        Salvar
                      </StyledButton>
                    </FormGrid>

                    <UploadFiles
                      className={documentClassName}
                      type={documentType}
                      labelText='Holerite ou Imposto de Renda do ano corrente (ou do ano anterior, caso
                        ainda não tenha declarado)'
                      titleText='Anexar comprovante de renda e/ou faturamento'
                      required={isRelationship !== true}
                      files={filesToUpload}
                      onChange={(files) => {
                        setFilesToUpload([...files]);
                        onFileChanged([...files]);
                      }}
                      listOldFiles
                      setFilesList={setFilesList}
                    />
                  </DisplayGrid>
                </Form>
              );
            }}
          </Formik>
        </LayoutContent>
      </Container>
    </LoggedLayout>
  );
};

export default Profession;
