import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Form, FormGroup, ControlLabel, Input, InputGroup } from 'rsuite';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ArrowRight, Eye, EyeOff } from 'react-feather';
import {
  Container,
  Content,
  All,
  Banner,
  Title,
  SubTitle,
  Rodape,
  Info,
} from './styles';
import Logo from '~/assets/logo.svg';
import ImagemLateral from '~/assets/reset.svg';
import Button from '~/components/Button';
import { authAPI as api } from '~/services/api';
import ModalComplete from '~/components/ModalComplete';

const ResetPassword = () => {
  const location = useLocation();
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const user = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);

  const handleReset = async (data) => {
    const query = location.search.split('&');
    try {
      await api.post('/auth/reset_pass', {
        password: data.password,
        token: query[0].split('=')[1],
        newUser: false,
      });

      setShowModal(true);
      toast.success('Senha criada com sucesso!');
    } catch (e) {
      setShowModal(false);
      toast.error('Falha ao criar senha!');
    }
  };

  const RegisterSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Senha muito curta.')
      .required('Obrigatório')
      .test(
        'password_rules',
        'Senha não segue as condições de segurança',
        function (value) {
          const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/;
          return regex.test(value);
        }
      ),
    confirmPassword: Yup.string()
      .min(6, 'Senha muito curta.')
      .required('Obrigatório')
      .test(
        'password_rules',
        'Senha não segue as condições de segurança',
        function (value) {
          const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/;
          return regex.test(value);
        }
      ),
  });

  function handleShowPass(e) {
    e.preventDefault();
    setShowPass(!showPass);
  }

  function handleShowConfirmPass(e) {
    e.preventDefault();
    setShowConfirmPass(!showConfirmPass);
  }

  return (
    <Container>
      <ModalComplete value={showModal} />
      <All>
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validateOnMount
          validationSchema={RegisterSchema}
          onSubmit={(values, action) => {}}
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            isValid,
            touched,
            handleSubmit,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Content>
                  <img src={Logo} alt='Logo' />
                  {user.profile ? (
                    <Title>Olá {user?.profile?.firstname},</Title>
                  ) : (
                    ''
                  )}
                  <SubTitle>
                    Cadastre sua senha para acessar o ambiente de painel de
                    clientes.
                  </SubTitle>
                  <FormGroup>
                    <ControlLabel>Sua senha</ControlLabel>
                    <InputGroup style={{ margin: 0, width: '100%' }}>
                      <Input
                        className='input'
                        onChange={(value, event) => handleChange(event)}
                        onBlur={handleBlur}
                        name='password'
                        id='password'
                        value={values.password}
                        type={showPass ? 'text' : 'password'}
                        style={{ margin: 0, padding: 0 }}
                      />
                      <InputGroup.Button type='button' onClick={handleShowPass}>
                        {showPass ? <Eye /> : <EyeOff />}
                      </InputGroup.Button>
                    </InputGroup>
                    {errors.password && touched.password && (
                      <span>{errors.password}</span>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Repetir senha</ControlLabel>
                    <InputGroup style={{ margin: 0, width: '100%' }}>
                      <Input
                        className='input'
                        onChange={(value, event) => handleChange(event)}
                        onBlur={handleBlur}
                        name='confirmPassword'
                        id='confirmPassword'
                        value={values.confirmPassword}
                        type={showConfirmPass ? 'text' : 'password'}
                        style={{ margin: 0, padding: 0 }}
                      />
                      <InputGroup.Button
                        type='button'
                        onClick={handleShowConfirmPass}
                      >
                        {showConfirmPass ? <Eye /> : <EyeOff />}
                      </InputGroup.Button>
                    </InputGroup>

                    {errors.confirmPassword && touched.confirmPassword && (
                      <span>{errors.confirmPassword}</span>
                    )}
                  </FormGroup>

                  <Info>
                    <p>Siga os seguintes critérios:</p>
                    <ul>
                      <li>No mínimo 6 caracteres</li>
                      <li>Letras maiúscula e minúscula</li>
                      <li>Pelo menos um número</li>
                      <li>Não usar caracteres especiais, como: !@#$%&*</li>
                    </ul>
                  </Info>

                  <FormGroup>
                    <Button
                      type='submit'
                      disabled={
                        (values.password &&
                          values.password !== values.confirmPassword) ||
                        !isValid
                      }
                      onClick={() => handleReset(values)}
                    >
                      Registrar senha
                    </Button>
                  </FormGroup>
                  <Rodape>
                    <p>Já tem senha cadastrada?</p>
                    <a href='/login'>
                      Fazer login na plataforma
                      <ArrowRight />
                    </a>
                  </Rodape>
                </Content>
              </Form>
            );
          }}
        </Formik>
      </All>
      <Banner>
        <img src={ImagemLateral} alt='Imagem lateral' />
      </Banner>
    </Container>
  );
};

export default ResetPassword;
