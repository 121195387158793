import React, { useEffect, useState } from 'react';

const CustomizedMessage = ({ productType, currencyTo }) => {
  const validCurrenciesCash = ['USD', 'EUR', 'GBP', 'CAD'];

  const [message, setMessage] = useState('');

  useEffect(() => {
    if (productType === 'ESPECIE' && validCurrenciesCash.includes(currencyTo)) {
      setMessage(
        'Nossas entregas são realizadas até em 48h úteis após a confirmação do pagamento. Caso essa data não lhe atenda, sinalize qual é a melhor para o recebimento abaixo:'
      );
    } else if (
      productType === 'ESPECIE' &&
      !validCurrenciesCash.includes(currencyTo)
    ) {
      setMessage(
        'Nossas entregas de dólar, euro, libra e dólar canadense, são realizadas até em 48h úteis após a confirmação do pagamento. Caso tenha solicitado outra moeda, vamos te enviar por e-mail uma data sobre a entrega e o dia em que receberá o seu pedido.'
      );
    } else if (productType === 'CARTAO') {
      setMessage(
        'Para a moeda selecionada, os prazos de disponibilização e entrega podem variar. Nossa equipe entrará em contato para sinalizar a data para entrega em até 48 horas.'
      );
    } else {
      setMessage(
        'Os prazos de disponibilização e entrega podem variar, nossa equipe de logística entrará em contato para sinalizar a data para entrega em até 48 horas.'
      );
    }
  }, [productType, currencyTo]);

  return (
    <p
      style={{
        fontWeight: 400,
        fontSize: 14,
        color: '#232B33',
      }}
    >
      {message}
    </p>
  );
};

export default CustomizedMessage;
