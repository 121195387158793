import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ArrowRight, Info as InfoIcon } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Checkbox, ControlLabel, Form, FormGroup, Input, Modal } from 'rsuite';
import * as Yup from 'yup';
import AttentionIcon from '~/assets/attention.svg';
import ImagemLateral from '~/assets/banner-register.svg';
import CloseIcon from '~/assets/close.svg';
import IconError from '~/assets/iconsContract/erro.svg';
import IconSuccess from '~/assets/iconsContract/iconSuccess.svg';
import Logo from '~/assets/logo.svg';
import Button from '~/components/Button';
import Loading from '~/components/Loading';
import cnpjMask from '~/mask/cnpj';
import cpfMask from '~/mask/cpf';
import telMask from '~/mask/telphone';
import { api } from '~/services/api';
import cpfTest from '~/utils/cpfTest';
import { StyledSelect } from '../../components/styles/form.js';
import { CountryDdi } from '~/mocks/ddi';
import {
  All,
  Banner,
  BoxRecovery,
  ButtonBase,
  Buttons,
  Container,
  Content,
  ContentFormModal,
  CustomModal,
  Fields,
  Flex,
  FlexButton,
  Icons,
  IconsStatus,
  Info,
  LinkContact,
  ModalBody,
  ModalBodyStatus,
  ModalTitle,
  Rodape,
  Terms,
  Title,
  InfoEmail,
} from './styles';
import ErrorModal from '~/components/ErrorModal';
import PepModal from './components/PepModal';
import FATCAModal from './components/FatcaModal';
import LGPDModal from './components/LGPDModal';

function Register({ location }) {
  const history = useHistory();
  const [referralParam, setReferralParam] = useState('');
  const [loading, setLoading] = useState(false);
  const [isPF, setIsPF] = useState(true);
  const [show, setShow] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorData, setErrorData] = useState({});

  const [showPepModal, setShowPepModal] = useState(false);
  const [showFATCAModal, setShowFATCAModal] = useState(false);
  const [showLGPDModal, setShowLGPDModal] = useState(false);

  dayjs.extend(customParseFormat);

  useEffect(() => {
    window.amplitude.getInstance().logEvent('access Register page');
  }, []);

  useEffect(() => {
    if (location.search) {
      const ref = location.search.split('=');
      setReferralParam(ref[1].slice('', 8));
    }
  }, [referralParam]);

  const handleRegister = async (data) => {
    setLoading(true);
    try {
      if (!data.legalAuth) {
        throw new Error(
          'É necessário aceitar o termo de responsabilidade sobre Autorizações Legais e LGPD!'
        );
      }

      const dateBirth = data.date.split('/').reverse().join('-');
      const { name, companyName, email, pep, fatca, legalAuth, emailAuth } =
        data;

      await api.post('/users', {
        name,
        lastName: data.lastname,
        companyName,
        document: data.document.match(/\d+/g).join([]),
        email,
        referral: referralParam,
        dateBirth,
        phoneCountryCode: data.ddi,
        phoneAreaCode: data.ddd,
        phoneNumber: data.phone,
        pep,
        fatca,
        legalAuth,
        emailAuth,
      });

      toast.success(
        'Pronto, em instantes você receberá um email para criar sua senha'
      );
      window.amplitude.getInstance().logEvent('Register success');

      history.push('/home');
    } catch (err) {
      setLoading(false);
      setShowErrorModal(true);

      if (err?.response?.data?.message === 'User Already Exists') {
        const errorMessage = isPF
          ? 'Este CPF já está cadastrado, efetue login ou recupere sua senha para acessar'
          : 'Este CNPJ já está cadastrado, efetue login ou recupere sua senha para acessar';

        setErrorData({
          title: 'Falha ao cadastrar usuário',
          description: errorMessage,
        });

        return;
      }

      window.amplitude.getInstance().logEvent('Register failed');

      setErrorData({
        title: 'Erro ao enviar dados',
        description: err.message,
      });
    }
  };

  const handleRecoverAccess = async (email) => {
    setLoading(true);
    handleOpenModalError();
  };

  const handleOpenModal = () => {
    setShow(true);
  };

  const handleOpenModalSuccess = () => {
    setShowModalSuccess(true);
  };

  const handleOpenModalError = () => {
    setShowModalError(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  const handleCloseModalSuccess = () => {
    setShowModalSuccess(false);
  };

  const handleCloseModalError = () => {
    setShowModalError(false);
  };

  const setPF = () => setIsPF(true);

  const setPJ = () => setIsPF(false);

  let isPRD = false;
  if (process.env.NODE_ENV === 'production') {
    isPRD = true;
  }

  const RegisterSchema = Yup.object().shape({
    companyName: isPF ? Yup.string() : Yup.string().required('Obrigatório'),
    name: Yup.string().required('Obrigatório'),
    lastname: Yup.string().required('Obrigatório'),
    document: Yup.string()
      .required('Obrigatório')
      .length(isPF ? 14 : 18, 'Tamanho inválido.')
      .test('value', 'CPF inválido', function (value) {
        if (isPF && value?.length === 14) {
          return cpfTest(value);
        }

        return true;
      }),
    date: Yup.date()
      .transform((value) => {
        return value ? dayjs(value).toDate() : dayjs().toDate();
      })
      .required('Obrigatório')
      .test('data_criação', 'A data não pode ser futura', function (value) {
        if (dayjs().diff(dayjs(value), 'day') < 0) {
          return false;
        }
        return true;
      })
      .test('maioridade', 'Você precisa ter 18 anos', function (value) {
        if (isPF) {
          if (dayjs().diff(dayjs(value), 'year') < 18) {
            return false;
          }
        }

        return true;
      }),
    email: Yup.string().email('Digite um email válido').required('Obrigatório'),
    confEmail: Yup.string()
      .email('Digite um email válido')
      .required('Obrigatório'),
    ddi: Yup.string().required('Obrigatório').nullable(),
    ddd: Yup.string().required('Obrigatório'),
    phone: Yup.string().required('Obrigatório'),
    // emailAuth: Yup.boolean().required('Obrigatório'),
    legalAuth: Yup.boolean().required('Obrigatório'),
  });

  const RecoverSchema = Yup.object().shape({
    email: Yup.string().email('Digite um email válido'),
  });

  return (
    <>
      <Container>
        {loading && <Loading text='Aguarde...' />}

        <ErrorModal
          showModal={showErrorModal}
          onChange={() => setShowErrorModal(false)}
          {...errorData}
        />

        <All>
          <Formik
            initialValues={{
              companyName: '',
              name: '',
              lastname: '',
              document: '',
              ddi: '',
              ddd: '',
              phone: '',
              date: '',
              email: '',
              confEmail: '',
              pep: false,
              fatca: false,
              legalAuth: false,
              emailAuth: true,
            }}
            validationSchema={RegisterSchema}
            validateOnMount
            onSubmit={(values, action) => {}}
          >
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              isValid,
              touched,
              setFieldValue,
              handleSubmit,
            }) => {
              const handleSelectStateChange = (value) => {
                setFieldValue('ddi', value);
              };

              return (
                <Form onSubmit={handleSubmit}>
                  <Content>
                    <img src={Logo} alt='Logo' />
                    <Title>
                      {isPF
                        ? 'Criar um login para você'
                        : 'Criar um login para sua empresa'}
                    </Title>

                    <ButtonBase>
                      <FlexButton>
                        <Buttons
                          id='PfButton'
                          notToggle={!isPF}
                          onClick={setPF}
                        >
                          Pessoa Física
                        </Buttons>
                        <Buttons id='PjButton' notToggle={isPF} onClick={setPJ}>
                          Pessoa Jurídica
                        </Buttons>
                      </FlexButton>
                    </ButtonBase>

                    <Fields className='fieldsName'>
                      <Flex>
                        <FormGroup>
                          <ControlLabel>Nome</ControlLabel>
                          <Input
                            className='input'
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='name'
                            id='name'
                            value={values.name}
                            type='text'
                          />

                          {errors.name && touched.name && (
                            <span>{errors.name}</span>
                          )}
                        </FormGroup>
                      </Flex>

                      <Flex>
                        <FormGroup>
                          <ControlLabel>Sobrenome</ControlLabel>
                          <Input
                            className='input'
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='lastname'
                            id='lastname'
                            value={values.lastname}
                            type='text'
                          />

                          {errors.lastname && touched.lastname && (
                            <span>{errors.lastname}</span>
                          )}
                        </FormGroup>
                      </Flex>

                      {!isPF && (
                        <Flex>
                          <FormGroup>
                            <ControlLabel>Razão Social</ControlLabel>
                            <Input
                              className='input'
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='companyName'
                              id='companyName'
                              value={values.companyName}
                              type='text'
                            />

                            {errors.companyName && touched.companyName && (
                              <span>{errors.companyName}</span>
                            )}
                          </FormGroup>
                        </Flex>
                      )}

                      <Flex>
                        <FormGroup>
                          <ControlLabel>
                            {isPF
                              ? 'Data de nascimento'
                              : 'Data de abertura da empresa'}
                          </ControlLabel>
                          <Input
                            className='input'
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='date'
                            id='date'
                            maxlength={10}
                            value={values.date}
                            type='date'
                          />
                          {errors.date && touched.date && (
                            <span>{errors.date}</span>
                          )}
                        </FormGroup>
                      </Flex>

                      <Flex>
                        <FormGroup>
                          <ControlLabel>
                            {isPF ? 'Seu CPF' : 'Seu CNPJ'}
                          </ControlLabel>
                          <Input
                            className='input'
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='document'
                            id='document'
                            maxLength={isPF ? 14 : 18}
                            value={
                              isPF
                                ? cpfMask(values.document)
                                : cnpjMask(values.document)
                            }
                            type='text'
                          />

                          {errors.document && touched.document && (
                            <span>{errors.document}</span>
                          )}
                        </FormGroup>
                      </Flex>

                      <Flex className='number-phone'>
                        <ControlLabel>DDI</ControlLabel>

                        <FormGroup>
                          <StyledSelect
                            size='lg'
                            locale={{ searchPlaceholder: 'Pesquisar' }}
                            placeholder='Escolha o DDI'
                            onChange={(value) => handleSelectStateChange(value)}
                            onBlur={handleBlur}
                            data={CountryDdi}
                            value={values.ddi}
                            name='ddi'
                            id='ddi'
                            style={{ width: '100%', marginTop: '5px' }}
                            maxHeight={150}
                          />

                          {errors.ddi && touched.ddi && (
                            <span>{errors.ddi}</span>
                          )}
                        </FormGroup>
                      </Flex>

                      <Flex className='number-phone'>
                        <ControlLabel>Número de celular</ControlLabel>

                        <div style={{ display: 'flex' }}>
                          <FormGroup>
                            <Input
                              className='input'
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='ddd'
                              id='ddd'
                              value={values.ddd}
                              type='text'
                              style={{ width: 50 }}
                              maxlength={values.ddi === '55' ? 2 : ''}
                            />

                            {errors.ddd && touched.ddd && (
                              <span>{errors.ddd}</span>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Input
                              className='input input-phone'
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='phone'
                              id='phone'
                              value={
                                values.ddi === '55'
                                  ? telMask(values.phone)
                                  : values.phone
                              }
                              maxlength={values.ddi === '55' ? 10 : ''}
                              type='text'
                            />

                            {errors.phone && touched.phone && (
                              <span>{errors.phone}</span>
                            )}
                          </FormGroup>
                        </div>
                      </Flex>

                      <Flex>
                        <FormGroup>
                          <ControlLabel>Seu email</ControlLabel>
                          <Input
                            className='input'
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='email'
                            id='email'
                            value={values.email}
                            type='text'
                          />

                          {errors.email && touched.email && (
                            <span>{errors.email}</span>
                          )}
                        </FormGroup>
                      </Flex>

                      <Flex>
                        <FormGroup>
                          <ControlLabel>Confirmar email</ControlLabel>
                          <Input
                            className='input'
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='confEmail'
                            id='confEmail'
                            value={values.confEmail}
                            type='text'
                          />

                          {errors.confEmail && touched.confEmail && (
                            <span>{errors.confEmail}</span>
                          )}
                        </FormGroup>
                      </Flex>

                      {values.email !== values.confEmail && (
                        <InfoEmail>
                          E-mail informado não confere, por favor verifique o
                          e-mail informado.
                        </InfoEmail>
                      )}

                      {/* <Flex style={{ marginTop: 4 }}>
                        <FormGroup>
                          <Checkbox
                            value={values.emailAuth}
                            onChange={() => setFieldValue('emailAuth', !values.emailAuth)}
                            onBlur={handleBlur}
                            id="emailAuth"
                            name="emailAuth"
                          >
                            Autorizo receber informações financeiras e de contratos de câmbio nesse mesmo e-mail. (Marcação obrigatória)
                          </Checkbox>
                        </FormGroup>
                      </Flex> */}

                      <Flex>
                        <h5>Termos de adesão</h5>
                      </Flex>

                      {isPF && (
                        <>
                          <Flex style={{ marginTop: 0 }}>
                            <Checkbox
                              value={values.pep}
                              onChange={() => setFieldValue('pep', !values.pep)}
                            >
                              Sou PEP
                              <InfoIcon
                                size={20}
                                color='#09B2E6'
                                style={{ marginLeft: '8px' }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowPepModal(true);
                                }}
                              />
                            </Checkbox>
                          </Flex>

                          <Flex style={{ marginTop: 0 }}>
                            <Checkbox
                              value={values.fatca}
                              onChange={() =>
                                setFieldValue('fatca', !values.fatca)
                              }
                            >
                              Sou/declaro ser FATCA
                              <InfoIcon
                                size={20}
                                color='#09B2E6'
                                style={{ marginLeft: '8px' }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowFATCAModal(true);
                                }}
                              />
                            </Checkbox>
                          </Flex>
                        </>
                      )}

                      <Terms>
                        <span>
                          Termos de responsabilidade (Marcação obrigatória):
                        </span>

                        <Flex>
                          <Checkbox
                            value={values.legalAuth}
                            onChange={() =>
                              setFieldValue('legalAuth', !values.legalAuth)
                            }
                            onBlur={handleBlur}
                            id='legalAuth'
                            name='legalAuth'
                          >
                            Autorizações Legais e LGPD
                            <InfoIcon
                              size={20}
                              color='#09B2E6'
                              style={{ marginLeft: '8px' }}
                              onClick={(e) => {
                                e.preventDefault();
                                setShowLGPDModal(true);
                              }}
                            />
                          </Checkbox>
                        </Flex>
                      </Terms>

                      <Info>
                        <p>
                          <b>
                            Você receberá um link no e-mail cadastrado para
                            criar uma senha.
                          </b>
                        </p>
                        <p>
                          Ao clicar em cadastrar você está autorizando o
                          recebimento de informações financeiras e de contratos
                          de câmbio por e-mail.
                        </p>
                      </Info>

                      <FormGroup>
                        <Button
                          // type='submit'
                          onClick={() => handleRegister(values)}
                          disabled={
                            loading ||
                            (!touched.email && !isValid) ||
                            !isValid ||
                            // values.emailAuth === false ||
                            values.legalAuth === false
                          }
                        >
                          {!loading ? 'Cadastrar' : 'Aguarde...'}
                        </Button>
                      </FormGroup>
                      <Rodape>
                        <div>
                          <p>Já possui um login?</p>
                          <Link to='/login'>
                            Entrar agora
                            <ArrowRight />
                          </Link>
                        </div>

                        <div>
                          <p>Não recebeu o email?</p>
                          <h1 onClick={handleOpenModal}>
                            Veja o que fazer
                            <ArrowRight />
                          </h1>
                        </div>
                      </Rodape>
                    </Fields>
                  </Content>
                </Form>
              );
            }}
          </Formik>
        </All>
        <Banner>
          <img src={ImagemLateral} alt='Imagem lateral' />
        </Banner>
      </Container>

      <PepModal show={showPepModal} onHide={() => setShowPepModal(false)} />
      <FATCAModal
        show={showFATCAModal}
        onHide={() => setShowFATCAModal(false)}
      />
      <LGPDModal show={showLGPDModal} onHide={() => setShowLGPDModal(false)} />

      <Modal show={show} onHide={handleCloseModal}>
        <Icons>
          <img
            src={CloseIcon}
            onClick={handleCloseModal}
            style={{ cursor: 'pointer' }}
          />
          <img
            src={AttentionIcon}
            alt='Ícone de atenção'
            className='attention'
          />
        </Icons>
        <CustomModal>
          <ModalTitle>Não recebi o email de confirmação de senha</ModalTitle>
          <ModalBody>
            <p>
              Pode ser que o email não tenha ido diretamente para sua caixa de
              entrada. Para termos certeza de que houve algum problema, pedimos
              que você verifique alguns pontos:
            </p>
            <li>
              Acesse sua caixa de SPAM e verifique se o nosso e-mail não foi
              identificado como "SPAM";
            </li>
            <li>
              No seu aplicativo de e-mail, verifique em outras janelas como
              "principal", "atualizações" e semelhantes;
            </li>
            <li>
              Utilizando o campo de busca, pesquise pelo título "Bem-vindo(a) a
              Advanced" ou a palavra chave "Advanced".
            </li>

            <p>
              Caso ainda não tenha identificado o recebimento, coloque seu email
              e clique em "Resgatar acesso".
            </p>
            <BoxRecovery>
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={RecoverSchema}
                onSubmit={(values, action) => {}}
              >
                {({
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  isValid,
                  touched,
                  setFieldValue,
                  handleSubmit,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <ContentFormModal>
                        <Fields className='fieldsName'>
                          <Flex>
                            <FormGroup>
                              <ControlLabel>Seu email</ControlLabel>
                              <Input
                                className='input'
                                onChange={(value, event) => handleChange(event)}
                                onBlur={handleBlur}
                                name='email'
                                id='email'
                                value={values.email}
                                type='text'
                              />

                              {errors.email && touched.email && (
                                <span>{errors.email}</span>
                              )}
                            </FormGroup>
                          </Flex>
                        </Fields>

                        <FormGroup>
                          <Button
                            type='submit'
                            onClick={() => handleRecoverAccess(values)}
                            disabled={!isValid}
                            // disabled={loading || !isValid}
                            lg
                          >
                            Resgatar acesso
                          </Button>
                        </FormGroup>
                      </ContentFormModal>
                    </Form>
                  );
                }}
              </Formik>
            </BoxRecovery>
          </ModalBody>
        </CustomModal>
      </Modal>

      <Modal show={showModalSuccess} onHide={handleCloseModalSuccess}>
        <IconsStatus>
          <img src={IconSuccess} alt='Ícone de sucesso' className='attention' />
        </IconsStatus>
        <CustomModal>
          <ModalTitle>Tudo certo! Hora de criar sua senha</ModalTitle>
          <ModalBodyStatus>
            <Button
              type='submit'
              // onClick={() => handleRecoverAccess(values)}
              // disabled={loading || !isValid}
              sm
            >
              Entendi
            </Button>
          </ModalBodyStatus>
        </CustomModal>
      </Modal>

      <Modal show={showModalError} onHide={handleCloseModalError}>
        <IconsStatus>
          <img src={IconError} alt='Ícone de erro' className='attention' />
        </IconsStatus>
        <CustomModal>
          <ModalTitle>Parece que seus dados estão incorretos.</ModalTitle>
          <br />
          <ModalTitle> Fale com a Advanced:</ModalTitle>
          <ModalBodyStatus>
            <p>
              Por email:{' '}
              <LinkContact href='mailto:contato@advancedcorretora.com.br'>
                contato@advancedcorretora.com.br
              </LinkContact>
            </p>
            <p>
              Por telefone:{' '}
              <LinkContact href='tel:1130652300'>(11) 3065-2300</LinkContact>
            </p>

            <Button
              type='submit'
              // onClick={() => handleRecoverAccess(values)}
              // disabled={loading || !isValid}
              sm
            >
              Entendi
            </Button>
          </ModalBodyStatus>
        </CustomModal>
      </Modal>
    </>
  );
}

export default Register;
