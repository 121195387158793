import { Modal } from 'rsuite';

const { default: styled } = require('styled-components');

export const SModal = styled(Modal)`
  width: 680px;

  .rs-modal-header-close {
    display: none;
  }

  .rs-modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .rs-modal-body {
    margin-top: 0;
  }

  h2 {
    color: rgb(19, 37, 82);
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 34px;

    margin: 20px 0;
  }
`;

export const ModalClose = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 4px;

  border: 2px solid #0fc7ff;
  border-radius: 50%;
  cursor: pointer;

  svg {
    fill: #0fc7ff;
  }
`;

export const Description = styled.p`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgb(52, 64, 77);
  text-align: justify;
`;

export const Contrast = styled.div`
  background-color: rgb(239, 242, 247);
  padding: 10px 20px;
`;
