import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Progress } from 'rsuite';
import LoggedLayout from '~/components/LoggedLayout';
import { validatePermanent } from '~/services/user';
import { getBanks } from '~/store/modules/banklist/actions';
import { getPartners } from '~/store/modules/partnersList/actions';
import { toggleUserbar } from '~/store/modules/user/actions';
import { updateUser } from '~/store/modules/user/actions.js';
import {
  calculatePercentAddress,
  calculatePercentPFGeneralData,
  calculatePercentPFIdentity,
  calculatePercentPFProfission,
  calculatePercentPJGeneralData,
  calculatePercentPJRequest,
} from '~/utils/calculate-percent';
import SimulateMoneyForTravel from '../../components/SimulateMoneyForTravel';
import CardContent from './CardContent';
import MyDataItem from './MyDataItem';
import Arrow from './icons/arrowRight.svg';
import Contract from './icons/contratos.svg';
import Banco from './icons/iconsList/Banco.svg';
import Endereco from './icons/iconsList/Endereco.svg';
import Geral from './icons/iconsList/Geral.svg';
import GeralPJ from './icons/iconsList/Geral_PJ.svg';
import IconContract from './icons/iconsList/contratos.svg';
import Finantial from './icons/iconsList/finantial.svg';
// Icons List
import RegisterStatus from '~/components/RegisterStatus';
import SideModal from '~/components/SideModalCalculator';
import { getProducts, getUserLimits } from '~/store/modules/exchange/actions';
import TermsCard from './TermsCard';
import Id from './icons/iconsList/Id.svg';
import Profissao from './icons/iconsList/Profissao.svg';
import chatSvg from './icons/img-contato.svg';
import MobileChat from './icons/mobileChat.svg';
import MobileContract from './icons/mobileContract.svg';
import {
  Btn,
  BtnContract,
  CustomList,
  CustomLoader,
  DisplayCards,
  Footer,
  GridCards,
  GridCardsInitial,
  ResponsiveIcon,
  ServicesContent,
  SupportContent,
  SupportFooter,
  WebIcon,
} from './styles';

import termsApi from '~/services/termsApi';
import cpfMask from '~/mask/cpf';
import cnpjMask from '~/mask/cnpj';

// import { CustomModal } from '~/components/ValidationModal/style';
// import NewPjPainel from '~/components/NewPjPainel';

const { Line } = Progress;

const Home = () => {
  const dispatch = useDispatch();
  const [calcModal, setOpenCalcModel] = useState(false);

  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const { banks } = useSelector((state) => state.bankList);
  const { partners } = useSelector((state) => state.partnersList);
  const [isLoading, setIsLoading] = useState(false);
  // TODO alterar label e ícone Geral PJ
  const {
    addressCity,
    addressCountry,
    addressNeighborhood,
    addressNumber,
    addressState,
    addressStreet,
    addressZipcode,
    cityBirth,
    companyName,
    countryBirth,
    document,
    email,
    idProfission,
    issuingAgency,
    issuingExpeditionDate,
    issuingState,
    identificationDocument,
    identificationDocumentType,
    monthlyIncome,
    motherName,
    patrimony,
    phoneNumber,
    stateBirth,
    companyFantasyName,
    companyOpenedDate,
    companyEconomicActivity,
    companyWhereWorkName,
    annualPatrimony,
    annualIncome,
    isRelationship,
  } = user.profile;

  const [idPercentage, setIdPercentage] = useState(0);
  const [profissionPercentage, setProfissionPercentage] = useState(0);
  const [generalPercentage, setGeneralPercentage] = useState(0);
  const [addressPercentage, setAddressPercentage] = useState(0);
  const [percentageAllPF, setPercentageAllPF] = useState(0);
  const [percentageAllPJ, setPercentageAllPJ] = useState(0);
  const [percentageFinantialPJ, setPercentageFinantialPJ] = useState(0);

  const [terms, setTerms] = useState();

  const isPF = document.length === 11;

  const getUserTerms = useCallback(async () => {
    const response = await termsApi.post('/search-client', {
      document: isPF ? cpfMask(document) : cnpjMask(document),
      email,
    });

    if (response.data?.message === 'Terms already accepted')
      return setTerms(response.data);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getProducts());

    // dispatch(getUserLimits());
    getUserTerms();

    window.amplitude.getInstance().logEvent('user logged');
    if (!user.showUserbar) {
      dispatch(toggleUserbar());
    }

    dispatch(getBanks(user.profile._id, auth.token));

    if (document.length > 11)
      dispatch(getPartners(user.profile._id, auth.token));

    percentage();

    const canBePermanent = () => {
      return (
        !user.profile.isPermanent &&
        !user.profile.isRelationship &&
        (percentageAllPF === 100 || percentageAllPJ === 100) &&
        banks.length > 0
      );
    };

    if (canBePermanent()) {
      validatePermanent(user.profile._id);
      dispatch(
        updateUser({
          user: {
            ...user.profile,
          },
        })
      );
    }

    setIsLoading(false);
  }, []);

  const percentage = () => {
    setIdPercentage(calculatePercentPFIdentity(user.profile), [
      document,
      identificationDocument,
      identificationDocumentType,
      issuingAgency,
      issuingState,
      issuingExpeditionDate,
    ]);
    setProfissionPercentage(calculatePercentPFProfission(user.profile), [
      idProfission,
      companyWhereWorkName,
      monthlyIncome,
      patrimony,
    ]);

    setGeneralPercentage(
      isPF
        ? calculatePercentPFGeneralData(user.profile)
        : calculatePercentPJGeneralData(user.profile),
      [
        cityBirth,
        stateBirth,
        motherName,
        countryBirth,
        companyFantasyName,
        companyName,
        companyOpenedDate,
        companyEconomicActivity,
        email,
        phoneNumber,
        document,
      ]
    );
    setAddressPercentage(calculatePercentAddress(user.profile), [
      addressZipcode,
      addressStreet,
      addressNumber,
      addressState,
      addressCity,
      addressCountry,
      addressNeighborhood,
    ]);

    setPercentageAllPF(
      calculatePercentPFIdentity(user.profile) &&
        calculatePercentPFGeneralData(user.profile) &&
        // calculatePercentPFProfission(user.profile) &&
        calculatePercentAddress(user.profile),
      [
        cityBirth,
        stateBirth,
        motherName,
        countryBirth,
        document,
        identificationDocument,
        identificationDocumentType,
        issuingAgency,
        issuingState,
        issuingExpeditionDate,
        idProfission,
        companyWhereWorkName,
        monthlyIncome,
        patrimony,
        addressZipcode,
        addressStreet,
        addressNumber,
        addressState,
        addressCity,
        addressCountry,
        addressNeighborhood,
      ]
    );

    setPercentageFinantialPJ(calculatePercentPJRequest(user.profile), [
      annualPatrimony,
      annualIncome,
    ]);

    setPercentageAllPJ(
      calculatePercentPJGeneralData(user.profile) &&
        calculatePercentAddress(user.profile) && [
          // calculatePercentPJRequest(user.profile),
          companyFantasyName,
          companyName,
          companyOpenedDate,
          companyEconomicActivity,
          email,
          phoneNumber,
          document,
          addressZipcode,
          addressStreet,
          addressNumber,
          addressState,
          addressCity,
          addressCountry,
          addressNeighborhood,
          // annualPatrimony,
          // annualIncome,
        ]
    );

    if (user?.showUserbar) {
      dispatch(toggleUserbar());
    }
  };

  return (
    <LoggedLayout>
      <DisplayCards>
        {/* {isPF && <SimulateMoneyForTravel setOpen={setOpenCalcModel} />}
        <SideModal open={calcModal} setOpen={setOpenCalcModel} /> */}

        <GridCardsInitial width={isPF ? '1390px' : '969px'}>
          <CardContent full={false} title='Meu cadastro' lw alt>
            {isLoading && <CustomLoader size='lg' backdrop vertical />}
            <RegisterStatus />

            <CustomList>
              {isPF && (
                <List.Item>
                  <MyDataItem
                    image={Id}
                    rote='/update-data/identification'
                    title='Identificação'
                  />
                </List.Item>
              )}
              <List.Item>
                <MyDataItem
                  image={document.length > 11 ? GeralPJ : Geral}
                  rote={`/update-data/${isPF ? 'pf' : 'pj'}`}
                  title='Geral'
                />
              </List.Item>
              <List.Item>
                <MyDataItem
                  image={Endereco}
                  rote='/update-address'
                  title='Endereço'
                />
              </List.Item>
              <List.Item>
                <MyDataItem
                  image={Banco}
                  rote='/update-bank'
                  title='Dados bancários'
                />
              </List.Item>
              {document.length > 11 && (
                <List.Item>
                  <MyDataItem
                    image={Geral}
                    rote='/update-data/partners'
                    title='Representantes'
                  />
                </List.Item>
              )}
              {document.length > 11 && (
                <List.Item>
                  <MyDataItem
                    image={Finantial}
                    rote='/update-data/finantial'
                    title='Financeiro'
                    isOptional
                  />
                </List.Item>
              )}
              {isPF && (
                <List.Item>
                  <MyDataItem
                    image={Profissao}
                    rote='/update-data/profession'
                    title='Profissão'
                    isOptional
                  />
                </List.Item>
              )}
              <List.Item>
                <MyDataItem
                  image={IconContract}
                  rote='/closing-estimate'
                  title='Fechamento'
                  isOptional
                />
              </List.Item>
            </CustomList>
            <Footer>
              <a href='/update-data'>
                Abrir meu cadastro
                <img src={Arrow} alt='Imagem abrir meu cadastro' />{' '}
              </a>
            </Footer>
          </CardContent>

          <GridCards>
            {/* {isRelationship &&
              !!terms &&
              !terms?.message === 'Terms already accepted' && (
                <TermsCard setIsAcceptedTerm={setIsAcceptedTerm} />
              )} */}

            <CardContent lw low auto>
              <SupportContent>
                <div>
                  <ResponsiveIcon src={chatSvg} />
                  <WebIcon src={MobileChat} />
                </div>
                <section>
                  <h1>Têm dúvidas sobre serviços ou documentação?</h1>
                  <p>Navegue por nossa central de ajuda.</p>
                </section>
              </SupportContent>
              <SupportFooter>
                <Btn
                  ghost
                  style={{ width: '226px', height: '40px' }}
                  href='https://www.advancedcorretora.com.br/contact'
                  target='_blank'
                >
                  Central de ajuda
                </Btn>
                {/* <BtnChat
                    ghost
                    style={{ width: '164px', height: '40px' }}
                    href='/chat'
                  >
                    Chat
                  </BtnChat> */}
              </SupportFooter>
            </CardContent>

            <CardContent lw low auto>
              <ServicesContent>
                <div>
                  <ResponsiveIcon src={Contract} />
                  <WebIcon src={MobileContract} />
                </div>
                <section>
                  <h1>Meus contratos de câmbio</h1>
                  {/* <p>Você não tem nenhum novo contrato a ser assinado</p> */}
                  <p>
                    Aqui você pode visualizar e assinar seus contratos de câmbio
                  </p>
                </section>
              </ServicesContent>
              <SupportFooter>
                <BtnContract
                  ghost
                  href='https://assinatura.advancedcorretora.com.br/'
                  target='_blank'
                >
                  Ir para contratos
                </BtnContract>
              </SupportFooter>
            </CardContent>

            {/* {isRelationship && isAcceptedTerm && (
              <TermsCard setIsAcceptedTerm={setIsAcceptedTerm} />
            )} */}
          </GridCards>
        </GridCardsInitial>
      </DisplayCards>
      {/* </Content> */}
      {/* </Container> */}
    </LoggedLayout>
  );
};

export default Home;
