import React, { useEffect } from 'react';
import Barcode from 'react-barcode';
import { Camera, Copy, Download } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import history from '~/services/history';
import {
  endTime,
  updateTicket,
  updateTicketNewStatus,
} from '~/store/modules/exchange/actions';
import { transformNumberInMoney } from '~/utils/transformNumberInMoney';
import { calcutateCheckoutValues } from '../../utils/calculateCheckoutValues';
import Button from '../Button';
import {
  BoletoNumber,
  ListButtons,
  Observation,
  StyledBox,
  StyledButton,
  Title,
  DisplayBarCode,
  CustomButton,
} from './styles';

const BoletoPayment = () => {
  const { exchange } = useSelector((state) => state);
  const dispatch = useDispatch();

  const copyCode = () => {
    return navigator.clipboard.writeText(exchange.ticket?.linhaDigitavel);
  };

  const downloadDocument = () => {
    return window.open(exchange.ticket.urlExterna);
  };

  useEffect(() => {
    dispatch(updateTicket({ data: { ticket_id: null } }));
    dispatch(updateTicketNewStatus(false));
    dispatch(endTime());
  }, []);

  const { totalValue } = calcutateCheckoutValues(exchange);

  return (
    <StyledBox>
      <Title>
        Pronto, aqui está o seu boleto no valor de{' '}
        <strong>
          {transformNumberInMoney({
            currency: 'BRL',
            number: totalValue,
          })}
        </strong>
      </Title>
      <Observation>
        <Camera size={16} /> Utilize o app do seu banco para pagar
        <br />
        O prazo máximo de pagamento deste boleto é de 2 dias.
        <br />
        O prazo de efetivação do pedido com boleto bancário é contado a partir
        da compensação do pagamento.
        <br />
        Em caso de dúvidas, entre em contato conosco via WhatsApp:{' '}
        <a
          target='__blank'
          href='https://api.whatsapp.com/send?phone=5511998656710&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20com%20meu%20pedido'
        >
          (11) 99865-6710
        </a>
      </Observation>
      <DisplayBarCode>
        <Barcode value={exchange.ticket?.codigoBarras} displayValue={false} />
      </DisplayBarCode>
      <BoletoNumber>{exchange.ticket?.linhaDigitavel}</BoletoNumber>
      <ListButtons>
        <StyledButton onClick={copyCode}>
          <Copy size={21} /> Copiar código de barras
        </StyledButton>
        <StyledButton onClick={downloadDocument}>
          <Download size={21} /> Baixar boleto em PDF
        </StyledButton>
      </ListButtons>
      <CustomButton
        md
        style={{ margin: '0 auto' }}
        onClick={() => history.push('/exchange-tickets')}
      >
        Ir para meus pedidos
      </CustomButton>
    </StyledBox>
  );
};

export default BoletoPayment;
