import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: calc(100vh - 90px);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }

  .backgroundBanner {
    background-color: #eff2f7;
    width: 30%;
    height: 95%;
    position: fixed;
  }

  @media (max-width: 1200px) {
    .backgroundBanner {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const Banner = styled.div`
  position: relative;

  .welcome {
    box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
      0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
    border-radius: 8px;
    width: 456px;
    height: 639px;
    margin: 135px 0 0 350px;
  }

  @media (max-width: 1000px) {
    .welcome {
      display: none;
    }
  }
`;

export const Title = styled.div`
  color: #20315b;
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 32px;
    width: 481px;
    margin: 0 120px;
  }
`;

export const TextArea = styled.div`
  margin: 10px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 10px 30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
    max-width: 400px;
  }

  .LogoImage {
    margin: 20px 109px;
  }

  @media (max-width: 800px) {
    .LogoImage {
      display: block;
      margin: 20px auto;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 0 120px;
    flex-direction: row;
  }

  span {
    margin-right: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #455566;
    opacity: 0.8;
  }
`;
