import styled from 'styled-components';

import { Modal } from 'rsuite';
import Button from '../Button';

export const ModalWrapper = styled(Modal)`
  .rs-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.h1`
  color: #132552;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  text-align: center;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const Description = styled.h4`
  color: #455566;
  font-family: IBM Plex Sans;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 30px;
  text-align: center;

  padding-top: 16px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const CustomButtom = styled(Button)`
  width: 154px;
  height: 40px;
  min-width: min-content;
`;
