import React from 'react';

export const Spacer = ({ top, left, right, bottom, children }) => {
  const style = {};
  if (top) style.paddingTop = top;
  if (left) style.paddingLeft = left;
  if (right) style.paddingRight = right;
  if (bottom) style.paddingBottom = bottom;
  return <div style={style}>{children}</div>;
};
