export const CountryDdi = [
  {
    codigo: '076',
    value: '55',
    iso: 'BR',
    label: 'Brasil - +55',
    nome: 'Brasil',
    nomeFormal: 'República Federativa do Brasil',
  },
  {
    codigo: '004',
    value: '93',
    iso: 'AF',
    label: 'Afeganistão - +93',
    nome: 'Afeganistão',
    nomeFormal: 'República Islâmica do Afeganistão',
  },
  {
    codigo: '710',
    value: '27',
    iso: 'ZA',
    label: 'África do Sul - +27',
    nome: 'África do Sul',
    nomeFormal: 'República da África do Sul',
  },
  {
    codigo: '008',
    value: '355',
    iso: 'AL',
    label: 'Albânia - +355',
    nome: 'Albânia',
    nomeFormal: 'República da Albânia',
  },
  {
    codigo: '276',
    value: '49',
    iso: 'DE',
    label: 'Alemanha - +49',
    nome: 'Alemanha',
    nomeFormal: 'República Federal da Alemanha',
  },
  {
    codigo: '020',
    value: '376',
    iso: 'AD',
    label: 'Andorra - +376',
    nome: 'Andorra',
    nomeFormal: 'Principado Andorra',
  },
  {
    codigo: '024',
    value: '244',
    iso: 'AO',
    label: 'Angola - +244',
    nome: 'Angola',
    nomeFormal: 'República de Angola',
  },
  {
    codigo: '660',
    value: '1264',
    iso: 'AI',
    label: 'Anguilla - +1264',
    nome: 'Anguilla',
    nomeFormal: 'Anguilla',
  },
  {
    codigo: '010',
    value: '672',
    iso: 'AQ',
    label: 'Antártida - +672',
    nome: 'Antártida',
    nomeFormal: 'Antártida',
  },
  {
    codigo: '028',
    value: '1268',
    iso: 'AG',
    label: 'Antígua e Barbuda - +1268',
    nome: 'Antígua e Barbuda',
    nomeFormal: 'Antigua e Barbuda',
  },
  {
    codigo: '530',
    value: '599',
    iso: 'AN',
    label: 'Antilhas Holandesas - +599',
    nome: 'Antilhas Holandesas',
    nomeFormal: 'Antilhas Holandesas',
  },
  {
    codigo: '682',
    value: '966',
    iso: 'SA',
    label: 'SArábia SauditaAU - +966',
    nome: 'Arábia Saudita',
    nomeFormal: 'Reino da Arábia Saudita',
  },
  {
    codigo: '012',
    value: '213',
    iso: 'DZ',
    label: 'Algéria - +213',
    nome: 'Algéria',
    nomeFormal: 'República Democrática Popular da Algéria',
  },
  {
    codigo: '032',
    value: '54',
    iso: 'AR',
    label: 'Argentina - +54',
    nome: 'Argentina',
    nomeFormal: 'República Argentina',
  },
  {
    codigo: '051',
    value: '374',
    iso: 'AM',
    label: 'Armênia - +374',
    nome: 'Armênia',
    nomeFormal: 'República da Armênia',
  },
  {
    codigo: '533',
    value: '297',
    iso: 'AW',
    label: 'Aruba - +297',
    nome: 'Aruba',
    nomeFormal: 'Aruba',
  },
  {
    codigo: '036',
    value: '61',
    iso: 'AU',
    label: 'Austrália - +61',
    nome: 'Austrália',
    nomeFormal: 'Comunidade da Austrália',
  },
  {
    codigo: '040',
    value: '43',
    iso: 'AT',
    label: 'AUÁustriaT - +43',
    nome: 'Áustria',
    nomeFormal: 'República da Áustria',
  },
  {
    codigo: '031',
    value: '994',
    iso: 'AZ',
    label: 'Azerbaijão - +994',
    nome: 'Azerbaijão',
    nomeFormal: 'República do Azerbaijão',
  },
  {
    codigo: '044',
    value: '1242',
    iso: 'BS',
    label: 'Bahamas - +1242',
    nome: 'Bahamas',
    nomeFormal: 'Comunidade de Bahamas',
  },
  {
    codigo: '050',
    value: '880',
    iso: 'BD',
    label: 'Bangladesh - +880',
    nome: 'Bangladesh',
    nomeFormal: 'República Popular de Bangladesh',
  },
  {
    codigo: '052',
    value: '246',
    iso: 'BB',
    label: 'Barbados - +246',
    nome: 'Barbados',
    nomeFormal: 'Barbados',
  },
  {
    codigo: '048',
    value: '973',
    iso: 'BH',
    label: 'Bahrein - +973',
    nome: 'Bahrein',
    nomeFormal: 'Reino do Bahrein',
  },
  {
    codigo: '056',
    value: '32',
    iso: 'BE',
    label: 'Bélgica - +32',
    nome: 'Bélgica',
    nomeFormal: 'Reino da Bélgica',
  },
  {
    codigo: '084',
    value: '501',
    iso: 'BZ',
    label: 'Belize - +501',
    nome: 'Belize',
    nomeFormal: 'Belize',
  },
  {
    codigo: '204',
    value: '229',
    iso: 'BJ',
    label: 'Benin - +229',
    nome: 'Benin',
    nomeFormal: 'República do Benin',
  },
  {
    codigo: '060',
    value: '1441',
    iso: 'BM',
    label: 'Bermudas - +1441',
    nome: 'Bermudas',
    nomeFormal: 'Bermudas',
  },
  {
    codigo: '112',
    value: '375',
    iso: 'BY',
    label: 'Bielorrússia - +375',
    nome: 'Bielorrússia',
    nomeFormal: 'República da Bielorrússia',
  },
  {
    codigo: '068',
    value: '591',
    iso: 'BO',
    label: 'Bolívia - +591',
    nome: 'Bolívia',
    nomeFormal: 'Estado Plurinacional da Bolívia',
  },
  {
    codigo: '070',
    value: '387',
    iso: 'BA',
    label: 'Bósnia e Herzegovina - +387',
    nome: 'Bósnia e Herzegovina',
    nomeFormal: 'Bósnia e Herzegovina',
  },
  {
    codigo: '072',
    value: '267',
    iso: 'BW',
    label: 'Botswana - +267',
    nome: 'Botswana',
    nomeFormal: 'República da Botswana',
  },
  {
    codigo: '096',
    value: '673',
    iso: 'BN',
    label: 'Brunei - +673',
    nome: 'Brunei',
    nomeFormal: 'Estado do Brunei Darussalam',
  },
  {
    codigo: '100',
    value: '359',
    iso: 'BG',
    label: 'Bulgária - +359',
    nome: 'Bulgária',
    nomeFormal: 'República da Bulgária',
  },
  {
    codigo: '854',
    value: '226',
    iso: 'BF',
    label: 'Burkina Faso - +226',
    nome: 'Burkina Faso',
    nomeFormal: 'Burkina Faso',
  },
  {
    codigo: '108',
    value: '257',
    iso: 'BI',
    label: 'Burundi - +257',
    nome: 'Burundi',
    nomeFormal: 'República do Burundi',
  },
  {
    codigo: '064',
    value: '975',
    iso: 'BT',
    label: 'Butão - +975',
    nome: 'Butão',
    nomeFormal: 'Reino do Butão',
  },
  {
    codigo: '132',
    value: '238',
    iso: 'CV',
    label: 'Cabo Verde - +238',
    nome: 'Cabo Verde',
    nomeFormal: 'República do Cabo Verde',
  },
  {
    codigo: '120',
    value: '237',
    iso: 'CM',
    label: 'Camarões - +237',
    nome: 'Camarões',
    nomeFormal: 'República de Camarões',
  },
  {
    codigo: '116',
    value: '855',
    iso: 'KH',
    label: 'Camboja - +855',
    nome: 'Camboja',
    nomeFormal: 'Reino do Camboja',
  },
  {
    codigo: '124',
    value: '0001',
    iso: 'CA',
    label: 'Canadá - +1',
    nome: 'Canadá',
    nomeFormal: 'Canadá',
  },
  {
    codigo: '398',
    value: '7',
    iso: 'KZ',
    label: 'Cazaquistão - +7',
    nome: 'Cazaquistão',
    nomeFormal: 'República do Cazaquistão',
  },
  {
    codigo: '148',
    value: '235',
    iso: 'TD',
    label: 'Chade - +235',
    nome: 'Chade',
    nomeFormal: 'República do Chade',
  },
  {
    codigo: '152',
    value: '56',
    iso: 'CL',
    label: 'Chile - +56',
    nome: 'Chile',
    nomeFormal: 'República do Chile',
  },
  {
    codigo: '156',
    value: '86',
    iso: 'CN',
    label: 'China - +86',
    nome: 'China',
    nomeFormal: 'República Popular da China',
  },
  {
    codigo: '196',
    value: '357',
    iso: 'CY',
    label: 'Chipre - +357',
    nome: 'Chipre',
    nomeFormal: 'República do Chipre',
  },
  {
    codigo: '170',
    value: '57',
    iso: 'CO',
    label: 'Colômbia - +57',
    nome: 'Colômbia',
    nomeFormal: 'República da Colômbia',
  },
  {
    codigo: '174',
    value: '269',
    iso: 'KM',
    label: 'Comores - +269',
    nome: 'Comores',
    nomeFormal: 'União das Comores',
  },
  {
    codigo: '178',
    value: '242',
    iso: 'CG',
    label: 'Congo - +242',
    nome: 'Congo',
    nomeFormal: 'República do Congo',
  },
  {
    codigo: '180',
    value: '243',
    iso: 'CD',
    label: 'Congo (DR) - +243',
    nome: 'Congo (DR)',
    nomeFormal: 'República Democrática do Congo',
  },
  {
    codigo: '408',
    value: '850',
    iso: 'KP',
    label: 'Coreia do Norte - +850',
    nome: 'Coreia do Norte',
    nomeFormal: 'República Democrática Popular da Coreia',
  },
  {
    codigo: '410',
    value: '82',
    iso: 'KR',
    label: 'Coreia do Sul - +82',
    nome: 'Coreia do Sul',
    nomeFormal: 'República da Coreia',
  },
  {
    codigo: '384',
    value: '225',
    iso: 'CI',
    label: 'Costa do Marfim - +225',
    nome: 'Costa do Marfim',
    nomeFormal: 'República da Costa do Marfim',
  },
  {
    codigo: '188',
    value: '506',
    iso: 'CR',
    label: 'Costa Rica - +506',
    nome: 'Costa Rica',
    nomeFormal: 'República da Costa Rica',
  },
  {
    codigo: '191',
    value: '385',
    iso: 'HR',
    label: 'Croácia - +385',
    nome: 'Croácia',
    nomeFormal: 'República da Croácia',
  },
  {
    codigo: '192',
    value: '53',
    iso: 'CU',
    label: 'Cuba - +53',
    nome: 'Cuba',
    nomeFormal: 'República de Cuba',
  },
  {
    codigo: '208',
    value: '45',
    iso: 'DK',
    label: 'Dinamarca - +45',
    nome: 'Dinamarca',
    nomeFormal: 'Reino da Dinamarca',
  },
  {
    codigo: '262',
    value: '253',
    iso: 'DJ',
    label: 'Djibuti - +253',
    nome: 'Djibuti',
    nomeFormal: 'República do Djibuti',
  },
  {
    codigo: '212',
    value: '1767',
    iso: 'DM',
    label: 'Dominica - +1767',
    nome: 'Dominica',
    nomeFormal: 'Comunidade da Dominica',
  },
  {
    codigo: '818',
    value: '20',
    iso: 'EG',
    label: 'Egito - +20',
    nome: 'Egito',
    nomeFormal: 'República Árabe do Egito',
  },
  {
    codigo: '222',
    value: '503',
    iso: 'SV',
    label: 'El Salvador - +503',
    nome: 'El Salvador',
    nomeFormal: 'República El Salvador',
  },
  {
    codigo: '784',
    value: '971',
    iso: 'AE',
    label: 'Emirados Árabes - +971',
    nome: 'Emirados Árabes',
    nomeFormal: 'Emirados Árabes Unidos',
  },
  {
    codigo: '218',
    value: '593',
    iso: 'EC',
    label: 'Equador - +593',
    nome: 'Equador',
    nomeFormal: 'República do Equador',
  },
  {
    codigo: '232',
    value: '291',
    iso: 'ER',
    label: 'Eritreia - +291',
    nome: 'Eritreia',
    nomeFormal: 'Estado da Eritreia',
  },
  {
    codigo: '703',
    value: '421',
    iso: 'SK',
    label: 'Eslováquia - +421',
    nome: 'Eslováquia',
    nomeFormal: 'República Eslovaca',
  },
  {
    codigo: '705',
    value: '386',
    iso: 'SI',
    label: 'Eslovênia - +386',
    nome: 'Eslovênia',
    nomeFormal: 'República da Eslovênia',
  },
  {
    codigo: '724',
    value: '34',
    iso: 'ES',
    label: 'Espanha - +34',
    nome: 'Espanha',
    nomeFormal: 'Reino da Espanha',
  },
  {
    codigo: '840',
    value: '1',
    iso: 'US',
    label: 'Estados Unidos - +1',
    nome: 'Estados Unidos',
    nomeFormal: 'Estados Unidos da América',
  },
  {
    codigo: '233',
    value: '372',
    iso: 'EE',
    label: 'Estônia - +372',
    nome: 'Estônia',
    nomeFormal: 'República da Estônia',
  },
  {
    codigo: '231',
    value: '251',
    iso: 'ET',
    label: 'Etiópia - +251',
    nome: 'Etiópia',
    nomeFormal: 'República Democrática Federal da Etiópia',
  },
  {
    codigo: '242',
    value: '679',
    iso: 'FJ',
    label: 'Fiji - +679',
    nome: 'Fiji',
    nomeFormal: 'República do Fiji',
  },
  {
    codigo: '608',
    value: '63',
    iso: 'PH',
    label: 'Filipinas - +63',
    nome: 'Filipinas',
    nomeFormal: 'República das Filipinas',
  },
  {
    codigo: '246',
    value: '358',
    iso: 'FI',
    label: 'Finlândia - +358',
    nome: 'Finlândia',
    nomeFormal: 'República da Finlândia',
  },
  {
    codigo: '250',
    value: '33',
    iso: 'FR',
    label: 'França - +33',
    nome: 'França',
    nomeFormal: 'República Francesa',
  },
  {
    codigo: '266',
    value: '241',
    iso: 'GA',
    label: 'Gabão - +241',
    nome: 'Gabão',
    nomeFormal: 'República Gabonesa',
  },
  {
    codigo: '270',
    value: '220',
    iso: 'GM',
    label: 'Gâmbia - +220',
    nome: 'Gâmbia',
    nomeFormal: 'República da Gâmbia',
  },
  {
    codigo: '288',
    value: '233',
    iso: 'GH',
    label: 'Gana - +233',
    nome: 'Gana',
    nomeFormal: 'Repúblia de Gana',
  },
  {
    codigo: '268',
    value: '995',
    iso: 'GE',
    label: 'Geórgia - +995',
    nome: 'Geórgia',
    nomeFormal: 'Geórgia',
  },
  {
    codigo: '292',
    value: '350',
    iso: 'GI',
    label: 'Gibraltar - +350',
    nome: 'Gibraltar',
    nomeFormal: 'Gibraltar',
  },
  {
    codigo: '308',
    value: '1473',
    iso: 'GD',
    label: 'Granada - +1473',
    nome: 'Granada',
    nomeFormal: 'Granada',
  },
  {
    codigo: '300',
    value: '30',
    iso: 'GR',
    label: 'Grécia - +30',
    nome: 'Grécia',
    nomeFormal: 'República Helênica',
  },
  {
    codigo: '304',
    value: '299',
    iso: 'GL',
    label: 'Groelândia - +299',
    nome: 'Groelândia',
    nomeFormal: 'Groelândia',
  },
  {
    codigo: '312',
    value: '590',
    iso: 'GP',
    label: 'Guadalupe - +590',
    nome: 'Guadalupe',
    nomeFormal: 'Guadalupe',
  },
  {
    codigo: '316',
    value: '1671',
    iso: 'GU',
    label: 'Guão - +1671',
    nome: 'Guão',
    nomeFormal: 'Território do Guão',
  },
  {
    codigo: '320',
    value: '502',
    iso: 'GT',
    label: 'Guatemala - +502',
    nome: 'Guatemala',
    nomeFormal: 'República da Guatemala',
  },
  {
    codigo: '328',
    value: '592',
    iso: 'GY',
    label: 'Guiana - +592',
    nome: 'Guiana',
    nomeFormal: 'República Cooperativa da Guiana',
  },
  {
    codigo: '254',
    value: '594',
    iso: 'GF',
    label: 'Guiana Francesa - +594',
    nome: 'Guiana Francesa',
    nomeFormal: 'Guiana Francesa',
  },
  {
    codigo: '324',
    value: '224',
    iso: 'GN',
    label: 'Guiné - +224',
    nome: 'Guiné',
    nomeFormal: 'República do Guiné',
  },
  {
    codigo: '624',
    value: '245',
    iso: 'GW',
    label: 'Guiné-Bissau - +245',
    nome: 'Guiné-Bissau',
    nomeFormal: 'República da Guiné-Bissau',
  },
  {
    codigo: '226',
    value: '240',
    iso: 'GQ',
    label: 'Guiné Equatorial - +240',
    nome: 'Guiné Equatorial',
    nomeFormal: 'República do Guiné Equatorial',
  },
  {
    codigo: '332',
    value: '509',
    iso: 'HT',
    label: 'Haiti - +509',
    nome: 'Haiti',
    nomeFormal: 'República do Haiti',
  },
  {
    codigo: '528',
    value: '31',
    iso: 'NL',
    label: 'Holanda - +31',
    nome: 'Holanda',
    nomeFormal: 'Holanda',
  },
  {
    codigo: '340',
    value: '504',
    iso: 'HN',
    label: 'Honduras - +504',
    nome: 'Honduras',
    nomeFormal: 'República de Honduras',
  },
  {
    codigo: '344',
    value: '852',
    iso: 'HK',
    label: 'Hong Kong - +852',
    nome: 'Hong Kong',
    nomeFormal:
      'Região Administrativa Especial de Hong Kong da República Popular da China',
  },
  {
    codigo: '348',
    value: '36',
    iso: 'HU',
    label: 'Hungria - +36',
    nome: 'Hungria',
    nomeFormal: 'Hungria',
  },
  {
    codigo: '887',
    value: '967',
    iso: 'YE',
    label: 'Iêmen - +967',
    nome: 'Iêmen',
    nomeFormal: 'República do Iêmen',
  },
  {
    codigo: '136',
    value: '1345',
    iso: 'KY',
    label: 'Ilhas Cayman - +1345',
    nome: 'Ilhas Cayman',
    nomeFormal: 'Ilhas Cayman',
  },
  {
    codigo: '162',
    value: '61',
    iso: 'CX',
    label: 'Ilha Christmas - +61',
    nome: 'Ilha Christmas',
    nomeFormal: 'Território da Ilha Christmas',
  },
  {
    codigo: '166',
    value: '000672',
    iso: 'CC',
    label: 'Ilhas Cocos (Keeling) - +672',
    nome: 'Ilhas Cocos (Keeling)',
    nomeFormal: 'Território das Ilhas Cocos (Keeling)',
  },
  {
    codigo: '184',
    value: '682',
    iso: 'CK',
    label: 'Ilhas Cook - +682',
    nome: 'Ilhas Cook',
    nomeFormal: 'Ilhas Cook',
  },
  {
    codigo: '234',
    value: '298',
    iso: 'FO',
    label: 'Ilhas Faroe - +298',
    nome: 'Ilhas Faroe',
    nomeFormal: 'Ilhas Faroe',
  },
  {
    codigo: '334',
    value: '00672',
    iso: 'HM',
    label: 'Ilhas Heard e McDonald - +672',
    nome: 'Ilhas Heard e McDonald',
    nomeFormal: 'Território das Ilhas Heard e McDonald',
  },
  {
    codigo: '238',
    value: '500',
    iso: 'FK',
    label: 'Ilhas Malvinas - +500',
    nome: 'Ilhas Malvinas',
    nomeFormal: 'Ilhas Malvinas',
  },
  {
    codigo: '580',
    value: '1670',
    iso: 'MP',
    label: 'Ilhas Marianas do Norte - +1670',
    nome: 'Ilhas Marianas do Norte',
    nomeFormal: 'Comunidade das Ilhas Marianas do Norte',
  },
  {
    codigo: '584',
    value: '692',
    iso: 'MH',
    label: 'Ilhas Marshall - +692',
    nome: 'Ilhas Marshall',
    nomeFormal: 'República das Ilhas Marshall',
  },
  {
    codigo: '574',
    value: '0672',
    iso: 'NF',
    label: 'Ilha Norfolk - +672',
    nome: 'Ilha Norfolk',
    nomeFormal: 'Território da Ilha Norfolk',
  },
  {
    codigo: '090',
    value: '677',
    iso: 'SB',
    label: 'Ilhas Salomão - +677',
    nome: 'Ilhas Salomão',
    nomeFormal: 'Ilhas Salomão',
  },
  {
    codigo: '850',
    value: '1340',
    iso: 'VI',
    label: 'Ilhas Virgens (USA) - +1340',
    nome: 'Ilhas Virgens (USA)',
    nomeFormal: 'Ilhas Virgens dos Estados Unidos',
  },
  {
    codigo: '092',
    value: '1284',
    iso: 'VG',
    label: 'Ilhas Virgens Inglesas - +1284',
    nome: 'Ilhas Virgens Inglesas',
    nomeFormal: 'Ilhas Virgens',
  },
  {
    codigo: '356',
    value: '91',
    iso: 'IN',
    label: 'Índia - +91',
    nome: 'Índia',
    nomeFormal: 'República da Índia',
  },
  {
    codigo: '360',
    value: '62',
    iso: 'ID',
    label: 'Indonésia - +62',
    nome: 'Indonésia',
    nomeFormal: 'República da Indonésia',
  },
  {
    codigo: '364',
    value: '98',
    iso: 'IR',
    label: 'Iran - +98',
    nome: 'Iran',
    nomeFormal: 'República Islâmica do Iran',
  },
  {
    codigo: '368',
    value: '964',
    iso: 'IQ',
    label: 'Iraque - +964',
    nome: 'Iraque',
    nomeFormal: 'República do Iraque',
  },
  {
    codigo: '372',
    value: '353',
    iso: 'IE',
    label: 'IIrlandaRL - +353',
    nome: 'Irlanda',
    nomeFormal: 'Irlanda',
  },
  {
    codigo: '352',
    value: '354',
    iso: 'IS',
    label: 'Islândia - +354',
    nome: 'Islândia',
    nomeFormal: 'Islândia',
  },
  {
    codigo: '376',
    value: '972',
    iso: 'IL',
    label: 'Israel - +972',
    nome: 'Israel',
    nomeFormal: 'Estado de Israel',
  },
  {
    codigo: '380',
    value: '039',
    iso: 'IT',
    label: 'Itália - +39',
    nome: 'Itália',
    nomeFormal: 'República Italiana',
  },
  {
    codigo: '388',
    value: '1876',
    iso: 'JM',
    label: 'Jamaica - +1876',
    nome: 'Jamaica',
    nomeFormal: 'Jamaica',
  },
  {
    codigo: '392',
    value: '81',
    iso: 'JP',
    label: 'Japão - +81',
    nome: 'Japão',
    nomeFormal: 'Japão',
  },
  {
    codigo: '400',
    value: '962',
    iso: 'JO',
    label: 'Jordânia - +962',
    nome: 'Jordânia',
    nomeFormal: 'Reino Hachemita da Jordânia',
  },
  {
    codigo: '296',
    value: '686',
    iso: 'KI',
    label: 'Kiribati - +686',
    nome: 'Kiribati',
    nomeFormal: 'República do Kiribati',
  },
  {
    codigo: '414',
    value: '965',
    iso: 'KW',
    label: 'Kuwait - +965',
    nome: 'Kuwait',
    nomeFormal: 'Estado do Kuwait',
  },
  {
    codigo: '418',
    value: '856',
    iso: 'LA',
    label: 'Laos - +856',
    nome: 'Laos',
    nomeFormal: 'República Democrática Popular Lau',
  },
  {
    codigo: '426',
    value: '266',
    iso: 'LS',
    label: 'Lesoto - +266',
    nome: 'Lesoto',
    nomeFormal: 'Reino do Lesoto',
  },
  {
    codigo: '428',
    value: '371',
    iso: 'LV',
    label: 'Letônia - +371',
    nome: 'Letônia',
    nomeFormal: 'República da Letônia',
  },
  {
    codigo: '422',
    value: '961',
    iso: 'LB',
    label: 'Líbano - +961',
    nome: 'Líbano',
    nomeFormal: 'República Libanesa',
  },
  {
    codigo: '430',
    value: '231',
    iso: 'LR',
    label: 'Libéria - +231',
    nome: 'Libéria',
    nomeFormal: 'República da Libéria',
  },
  {
    codigo: '434',
    value: '218',
    iso: 'LY',
    label: 'Líbia - +218',
    nome: 'Líbia',
    nomeFormal: 'Líbia',
  },
  {
    codigo: '438',
    value: '423',
    iso: 'LI',
    label: 'Liechtenstein - +423',
    nome: 'Liechtenstein',
    nomeFormal: 'Principado de Liechtenstein',
  },
  {
    codigo: '440',
    value: '370',
    iso: 'LT',
    label: 'Lituânia - +370',
    nome: 'Lituânia',
    nomeFormal: 'República da Lituânia',
  },
  {
    codigo: '442',
    value: '352',
    iso: 'LU',
    label: 'Luxemburgo - +352',
    nome: 'Luxemburgo',
    nomeFormal: 'Grão-Ducado do Luxemburgo',
  },
  {
    codigo: '446',
    value: '853',
    iso: 'MO',
    label: 'Macao - +853',
    nome: 'Macao',
    nomeFormal: 'Macao',
  },
  {
    codigo: '807',
    value: '389',
    iso: 'MK',
    label: 'Macedônia - +389',
    nome: 'Macedônia',
    nomeFormal: 'República da Macedônia',
  },
  {
    codigo: '450',
    value: '261',
    iso: 'MG',
    label: 'Madagascar - +261',
    nome: 'Madagascar',
    nomeFormal: 'República de Madagascar',
  },
  {
    codigo: '458',
    value: '60',
    iso: 'MY',
    label: 'Malásia - +60',
    nome: 'Malásia',
    nomeFormal: 'Malásia',
  },
  {
    codigo: '454',
    value: '265',
    iso: 'MW',
    label: 'Malawi - +265',
    nome: 'Malawi',
    nomeFormal: 'República de Malawi',
  },
  {
    codigo: '466',
    value: '223',
    iso: 'ML',
    label: 'Mali - +223',
    nome: 'Mali',
    nomeFormal: 'República do Mali',
  },
  {
    codigo: '462',
    value: '960',
    iso: 'MV',
    label: 'Maldivas - +960',
    nome: 'Maldivas',
    nomeFormal: 'Reública de Maldivas',
  },
  {
    codigo: '470',
    value: '356',
    iso: 'MT',
    label: 'Malta - +356',
    nome: 'Malta',
    nomeFormal: 'República de Malta',
  },
  {
    codigo: '504',
    value: '212',
    iso: 'MA',
    label: 'Marrocos - +212',
    nome: 'Marrocos',
    nomeFormal: 'Reino de Marrocos',
  },
  {
    codigo: '474',
    value: '596',
    iso: 'MQ',
    label: 'Martinica - +596',
    nome: 'Martinica',
    nomeFormal: 'Martinica',
  },
  {
    codigo: '480',
    value: '230',
    iso: 'MU',
    label: 'Maurícia - +230',
    nome: 'Maurícia',
    nomeFormal: 'República de Maurício',
  },
  {
    codigo: '478',
    value: '222',
    iso: 'MR',
    label: 'Mauritânia - +222',
    nome: 'Mauritânia',
    nomeFormal: 'República Islâmica da Mauritânia',
  },
  {
    codigo: '175',
    value: '269',
    iso: 'YT',
    label: 'Mayotte - +269',
    nome: 'Mayotte',
    nomeFormal: 'Departamento de Mayotte',
  },
  {
    codigo: '484',
    value: '52',
    iso: 'MX',
    label: 'México - +52',
    nome: 'México',
    nomeFormal: 'Estados Unidos Mexicanos',
  },
  {
    codigo: '583',
    value: '691',
    iso: 'FM',
    label: 'Micronésia - +691',
    nome: 'Micronésia',
    nomeFormal: 'Estados Federados da Micronesia',
  },
  {
    codigo: '508',
    value: '258',
    iso: 'MZ',
    label: 'Moçambique - +258',
    nome: 'Moçambique',
    nomeFormal: 'República de Moçambique',
  },
  {
    codigo: '498',
    value: '373',
    iso: 'MD',
    label: 'Moldova - +373',
    nome: 'Moldova',
    nomeFormal: 'República de Moldova',
  },
  {
    codigo: '492',
    value: '377',
    iso: 'MC',
    label: 'Mônaco - +377',
    nome: 'Mônaco',
    nomeFormal: 'Principado de Mônaco',
  },
  {
    codigo: '496',
    value: '976',
    iso: 'MN',
    label: 'Mongólia - +976',
    nome: 'Mongólia',
    nomeFormal: 'Mongólia',
  },
  {
    codigo: '688',
    value: '382',
    iso: 'CS',
    label: 'Montenegro - +382',
    nome: 'Montenegro',
    nomeFormal: 'União Estatal de Sérvia e Montenegro',
  },
  {
    codigo: '500',
    value: '1664',
    iso: 'MS',
    label: 'Montserrat - +1664',
    nome: 'Montserrat',
    nomeFormal: 'Montserrat',
  },
  {
    codigo: '104',
    value: '95',
    iso: 'MM',
    label: 'Myanmar - +95',
    nome: 'Myanmar',
    nomeFormal: 'República da  de Myanmar',
  },
  {
    codigo: '516',
    value: '264',
    iso: 'NA',
    label: 'Namíbia - +264',
    nome: 'Namíbia',
    nomeFormal: 'República da Namíbia',
  },
  {
    codigo: '520',
    value: '674',
    iso: 'NR',
    label: 'Nauru - +674',
    nome: 'Nauru',
    nomeFormal: 'República de Nauru',
  },
  {
    codigo: '524',
    value: '977',
    iso: 'NP',
    label: 'Nepal - +977',
    nome: 'Nepal',
    nomeFormal: 'República Democrática Federativa do Nepal',
  },
  {
    codigo: '558',
    value: '505',
    iso: 'NI',
    label: 'Nicarágua - +505',
    nome: 'Nicarágua',
    nomeFormal: 'República da Nicarágua',
  },
  {
    codigo: '562',
    value: '227',
    iso: 'NE',
    label: 'Niger - +227',
    nome: 'Niger',
    nomeFormal: 'República do Niger',
  },
  {
    codigo: '566',
    value: '234',
    iso: 'NG',
    label: 'Nigéria - +234',
    nome: 'Nigéria',
    nomeFormal: 'República Federativa da Nigéria',
  },
  {
    codigo: '570',
    value: '683',
    iso: 'NU',
    label: 'Niue - +683',
    nome: 'Niue',
    nomeFormal: 'Niue',
  },
  {
    codigo: '578',
    value: '0047',
    iso: 'NO',
    label: 'Noruega - +47',
    nome: 'Noruega',
    nomeFormal: 'Reino da Noruega',
  },
  {
    codigo: '540',
    value: '687',
    iso: 'NC',
    label: 'Nova Caledônia - +687',
    nome: 'Nova Caledônia',
    nomeFormal: 'Nova Caledônia',
  },
  {
    codigo: '554',
    value: '64',
    iso: 'NZ',
    label: 'Nova Zelândia - +64',
    nome: 'Nova Zelândia',
    nomeFormal: 'Nova Zelândia',
  },
  {
    codigo: '512',
    value: '968',
    iso: 'OM',
    label: 'Omã - +968',
    nome: 'Omã',
    nomeFormal: 'Sultanato de Omã',
  },
  {
    codigo: '585',
    value: '680',
    iso: 'PW',
    label: 'Palau - +680',
    nome: 'Palau',
    nomeFormal: 'República de Palau',
  },
  {
    codigo: '275',
    value: '970',
    iso: 'PS',
    label: 'Palestina - +970',
    nome: 'Palestina',
    nomeFormal: 'Estado da Palestina',
  },
  {
    codigo: '591',
    value: '507',
    iso: 'PA',
    label: 'Panamá - +507',
    nome: 'Panamá',
    nomeFormal: 'República do Panamá',
  },
  {
    codigo: '598',
    value: '675',
    iso: 'PG',
    label: 'Papua-Nova Guiné - +675',
    nome: 'Papua-Nova Guiné',
    nomeFormal: 'Estado Independente da Papua-Nova Guiné',
  },
  {
    codigo: '586',
    value: '92',
    iso: 'PK',
    label: 'Paquistão - +92',
    nome: 'Paquistão',
    nomeFormal: 'República Islâmica do Paquistão',
  },
  {
    codigo: '600',
    value: '595',
    iso: 'PY',
    label: 'Paraguai - +595',
    nome: 'Paraguai',
    nomeFormal: 'República do Paraguai',
  },
  {
    codigo: '604',
    value: '51',
    iso: 'PE',
    label: 'Peru - +51',
    nome: 'Peru',
    nomeFormal: 'República do Peru',
  },
  {
    codigo: '258',
    value: '689',
    iso: 'PF',
    label: 'Polinésia Francesa - +689',
    nome: 'Polinésia Francesa',
    nomeFormal: 'Polinésia Francesa',
  },
  {
    codigo: '616',
    value: '48',
    iso: 'PL',
    label: 'Polônia - +48',
    nome: 'Polônia',
    nomeFormal: 'República da Polônia',
  },
  {
    codigo: '630',
    value: '1787',
    iso: 'PR',
    label: 'Porto Rico - +1787',
    nome: 'Porto Rico',
    nomeFormal: 'Comunidade do Porto Rico',
  },
  {
    codigo: '620',
    value: '351',
    iso: 'PT',
    label: 'Portugal - +351',
    nome: 'Portugal',
    nomeFormal: 'República Portuguesa',
  },
  {
    codigo: '634',
    value: '974',
    iso: 'QA',
    label: 'Qatar - +974',
    nome: 'Qatar',
    nomeFormal: 'Estado do Catar',
  },
  {
    codigo: '404',
    value: '254',
    iso: 'KE',
    label: 'Quênia - +254',
    nome: 'Quênia',
    nomeFormal: 'República do Quênia',
  },
  {
    codigo: '417',
    value: '996',
    iso: 'KG',
    label: 'Quirguistão - +996',
    nome: 'Quirguistão',
    nomeFormal: 'República Quirguiz',
  },
  {
    codigo: '826',
    value: '44',
    iso: 'GB',
    label: 'Reino Unido - +44',
    nome: 'Reino Unido',
    nomeFormal: 'Reino Unido da Grã-Bretanha e Irlanda do Norte',
  },
  {
    codigo: '140',
    value: '236',
    iso: 'CF',
    label: 'República Centro-Africana - +236',
    nome: 'República Centro-Africana',
    nomeFormal: 'República Centro-Africana',
  },
  {
    codigo: '214',
    value: '1809',
    iso: 'DO',
    label: 'República Dominicana - +1809',
    nome: 'República Dominicana',
    nomeFormal: 'República Dominicana',
  },
  {
    codigo: '203',
    value: '420',
    iso: 'CZ',
    label: 'República Tcheca - +420',
    nome: 'República Tcheca',
    nomeFormal: 'República Tcheca',
  },
  {
    codigo: '638',
    value: '262',
    iso: 'RE',
    label: 'Reunião - +262',
    nome: 'Reunião',
    nomeFormal: 'Polônia',
  },
  {
    codigo: '642',
    value: '40',
    iso: 'RO',
    label: 'Romênia - +40',
    nome: 'Romênia',
    nomeFormal: 'Romênia',
  },
  {
    codigo: '646',
    value: '250',
    iso: 'RW',
    label: 'Ruanda - +250',
    nome: 'Ruanda',
    nomeFormal: 'República da Ruanda',
  },
  {
    codigo: '643',
    value: '70',
    iso: 'RU',
    label: 'Rússia - +70',
    nome: 'Rússia',
    nomeFormal: 'Federação Russa',
  },
  {
    codigo: '732',
    value: '212',
    iso: 'EH',
    label: 'Saara Ocidental - +212',
    nome: 'Saara Ocidental',
    nomeFormal: 'Saara Ocidental',
  },
  {
    codigo: '882',
    value: '684',
    iso: 'WS',
    label: 'Samoa - +684',
    nome: 'Samoa',
    nomeFormal: 'Estado Independente de Samoa',
  },
  {
    codigo: '016',
    value: '1684',
    iso: 'AS',
    label: 'Samoa Americana - +1684',
    nome: 'Samoa Americana',
    nomeFormal: 'Território de Samoa Americana',
  },
  {
    codigo: '654',
    value: '290',
    iso: 'SH',
    label: 'Santa Helena - +290',
    nome: 'Santa Helena',
    nomeFormal: 'Saint Helena',
  },
  {
    codigo: '662',
    value: '1758',
    iso: 'LC',
    label: 'Santa Lúcia - +1758',
    nome: 'Santa Lúcia',
    nomeFormal: 'Santa Lúcia',
  },
  {
    codigo: '659',
    value: '1869',
    iso: 'KN',
    label: 'São Cristóvão - +1869',
    nome: 'São Cristóvão',
    nomeFormal: 'São Cristóvão',
  },
  {
    codigo: '674',
    value: '378',
    iso: 'SM',
    label: 'São Marinho - +378',
    nome: 'São Marinho',
    nomeFormal: 'República de São Marino',
  },
  {
    codigo: '666',
    value: '508',
    iso: 'PM',
    label: 'São Pedro e Miquelon - +508',
    nome: 'São Pedro e Miquelon',
    nomeFormal: 'Coletividade Territorial de São Pedro e Miquelon',
  },
  {
    codigo: '678',
    value: '239',
    iso: 'ST',
    label: 'São Tomé e Príncipe - +239',
    nome: 'Sao Tomé e Príncipe',
    nomeFormal: 'República Democrática de Sao Tomé e Príncipe',
  },
  {
    codigo: '670',
    value: '1784',
    iso: 'VC',
    label: 'São Vicente e Granadinas - +1784',
    nome: 'São Vicente e Granadinas',
    nomeFormal: 'São Vicente e Granadinas',
  },
  {
    codigo: '690',
    value: '248',
    iso: 'SC',
    label: 'Seicheles - +248',
    nome: 'Seicheles',
    nomeFormal: 'República das Seicheles',
  },
  {
    codigo: '686',
    value: '221',
    iso: 'SN',
    label: 'Senegal - +221',
    nome: 'Senegal',
    nomeFormal: 'República do Senegal',
  },
  {
    codigo: '694',
    value: '232',
    iso: 'SL',
    label: 'Serra Leoa - +232',
    nome: 'Serra Leoa',
    nomeFormal: 'República da Serra Leoa',
  },
  {
    codigo: '688',
    value: '381',
    iso: 'CS',
    label: 'Sérvia - +381',
    nome: 'Sérvia',
    nomeFormal: 'União Estatal de Sérvia e Montenegro',
  },
  {
    codigo: '702',
    value: '65',
    iso: 'SG',
    label: 'Singapura - +65',
    nome: 'Singapura',
    nomeFormal: 'República da Singapura',
  },
  {
    codigo: '760',
    value: '963',
    iso: 'SY',
    label: 'Síria - +963',
    nome: 'Síria',
    nomeFormal: 'República Árabe Síria',
  },
  {
    codigo: '706',
    value: '252',
    iso: 'SO',
    label: 'Somália - +252',
    nome: 'Somália',
    nomeFormal: 'República da Somália',
  },
  {
    codigo: '144',
    value: '94',
    iso: 'LK',
    label: 'Sri Lanka - +94',
    nome: 'Sri Lanka',
    nomeFormal: 'República Democrática Socialista do Sri Lanka',
  },
  {
    codigo: '748',
    value: '268',
    iso: 'SZ',
    label: 'Suazilândia - +268',
    nome: 'Suazilândia',
    nomeFormal: 'Reino da Suazilândia',
  },
  {
    codigo: '736',
    value: '249',
    iso: 'SD',
    label: 'Sudão - +249',
    nome: 'Sudão',
    nomeFormal: 'República do Sudão',
  },
  {
    codigo: '752',
    value: '46',
    iso: 'SE',
    label: 'Suécia - +46',
    nome: 'Suécia',
    nomeFormal: 'Reino da Suécia',
  },
  {
    codigo: '756',
    value: '41',
    iso: 'CH',
    label: 'Suiça - +41',
    nome: 'Suiça',
    nomeFormal: 'Confederação Suiça',
  },
  {
    codigo: '740',
    value: '597',
    iso: 'SR',
    label: 'Suriname - +597',
    nome: 'Suriname',
    nomeFormal: 'República do Suriname',
  },
  {
    codigo: '762',
    value: '992',
    iso: 'TJ',
    label: 'Tajiquistão - +992',
    nome: 'Tajiquistão',
    nomeFormal: 'República do Tajiquistão',
  },
  {
    codigo: '764',
    value: '66',
    iso: 'TH',
    label: 'Tailândia - +66',
    nome: 'Tailândia',
    nomeFormal: 'Reino da Tailândia',
  },
  {
    codigo: '834',
    value: '255',
    iso: 'TZ',
    label: 'Tanzânia - +255',
    nome: 'Tanzânia',
    nomeFormal: 'República Unida da Tanzânia',
  },
  {
    codigo: '158',
    value: '886',
    iso: 'TW',
    label: 'Taiwan - +886',
    nome: 'Taiwan',
    nomeFormal: 'Taiwan',
  },
  {
    codigo: '086',
    value: '246',
    iso: 'IO',
    label: 'Território Britânico do Oceano Índico - +246',
    nome: 'Território Britânico do Oceano Índico',
    nomeFormal: 'Território Britânico do Oceano Índico',
  },
  {
    codigo: '626',
    value: '670',
    iso: 'TL',
    label: 'Timor-Leste - +670',
    nome: 'Timor-Leste',
    nomeFormal: 'República Democrática de Timor-Leste',
  },
  {
    codigo: '768',
    value: '228',
    iso: 'TG',
    label: 'Togo - +228',
    nome: 'Togo',
    nomeFormal: 'República Togolesa',
  },
  {
    codigo: '772',
    value: '690',
    iso: 'TK',
    label: 'Toquelau - +690',
    nome: 'Toquelau',
    nomeFormal: 'Toquelau',
  },
  {
    codigo: '776',
    value: '676',
    iso: 'TO',
    label: 'Tonga - +676',
    nome: 'Tonga',
    nomeFormal: 'Reino de Tonga',
  },
  {
    codigo: '780',
    value: '1868',
    iso: 'TT',
    label: 'Trinidad e Tobago - +1868',
    nome: 'Trinidad e Tobago',
    nomeFormal: 'República da Trinidad e Tobago',
  },
  {
    codigo: '788',
    value: '216',
    iso: 'TN',
    label: 'Tunísia - +216',
    nome: 'Tunísia',
    nomeFormal: 'República da Tunísia',
  },
  {
    codigo: '796',
    value: '1649',
    iso: 'TC',
    label: 'Turcas e Caicos - +1649',
    nome: 'Turcas e Caicos',
    nomeFormal: 'Ilhas Turks e Caicos',
  },
  {
    codigo: '795',
    value: '7370',
    iso: 'TM',
    label: 'Turcomenistão - +7370',
    nome: 'Turcomenistão',
    nomeFormal: 'Turcomenistão',
  },
  {
    codigo: '792',
    value: '90',
    iso: 'TR',
    label: 'Turquia - +90',
    nome: 'Turquia',
    nomeFormal: 'República da Turquia',
  },
  {
    codigo: '798',
    value: '688',
    iso: 'TV',
    label: 'Tuvalu - +688',
    nome: 'Tuvalu',
    nomeFormal: 'Tuvalu',
  },
  {
    codigo: '804',
    value: '380',
    iso: 'UA',
    label: 'Ucrânia - +380',
    nome: 'Ucrânia',
    nomeFormal: 'Ucrânia',
  },
  {
    codigo: '800',
    value: '256',
    iso: 'UG',
    label: 'Uganda - +256',
    nome: 'Uganda',
    nomeFormal: 'República de Uganda',
  },
  {
    codigo: '858',
    value: '598',
    iso: 'UY',
    label: 'Uruguai - +598',
    nome: 'Uruguai',
    nomeFormal: 'República Oriental do Uruguai',
  },
  {
    codigo: '860',
    value: '998',
    iso: 'UZ',
    label: 'Uzbequistão - +998',
    nome: 'Uzbequistão',
    nomeFormal: 'República do Uzbequistão',
  },
  {
    codigo: '548',
    value: '678',
    iso: 'VU',
    label: 'Vanuatu - +678',
    nome: 'Vanuatu',
    nomeFormal: 'República de Vanuatu',
  },
  {
    codigo: '336',
    value: '39',
    iso: 'VA',
    label: 'Vaticano - +39',
    nome: 'Vaticano',
    nomeFormal: 'Estado da Cidade do Vaticano',
  },
  {
    codigo: '862',
    value: '58',
    iso: 'VE',
    label: 'Venezuela - +58',
    nome: 'Venezuela',
    nomeFormal: 'República Bolivariana da Venezuela',
  },
  {
    codigo: '704',
    value: '84',
    iso: 'VN',
    label: 'Vietnam - +84',
    nome: 'Vietnam',
    nomeFormal: 'República Socialista do Vietnam',
  },
  {
    codigo: '876',
    value: '681',
    iso: 'WF',
    label: 'Wallis e Futuna - +681',
    nome: 'Wallis e Futuna',
    nomeFormal: 'Wallis e Futuna',
  },
  {
    codigo: '894',
    value: '260',
    iso: 'ZM',
    label: 'Zâmbia - +260',
    nome: 'Zâmbia',
    nomeFormal: 'República do Zâmbia',
  },
  {
    codigo: '716',
    value: '263',
    iso: 'ZW',
    label: 'Zimbábue - +263',
    nome: 'Zimbábue',
    nomeFormal: 'República do Zimbábue',
  },
  {
    codigo: '074',
    value: '047',
    iso: 'BV',
    label: 'Ilha Bouvet - +47',
    nome: 'Ilha Bouvet',
    nomeFormal: 'Ilha Bouvet',
  },
  {
    codigo: '260',
    value: '33',
    iso: 'TF',
    label: 'Terras Austrais e Antárticas Francesas - +33',
    nome: 'Terras Austrais e Antárticas Francesas',
    nomeFormal: 'Território das Terras Austrais e Antárticas Francesas',
  },
  {
    codigo: '612',
    value: '0000672',
    iso: 'PN',
    label: 'Ilhas Picárnia - +672',
    nome: 'Ilhas Picárnia',
    nomeFormal: 'Ilhas Picárnia',
  },
  {
    codigo: '239',
    value: '0500',
    iso: 'GS',
    label: 'Ilhas Geórgia do Sul e Sandwich do Sul - +500',
    nome: 'Ilhas Geórgia do Sul e Sandwich do Sul',
    nomeFormal: 'Ilhas Geórgia do Sul e Sandwich do Sul',
  },
  {
    codigo: '744',
    value: '47',
    iso: 'SJ',
    label: 'Esvalbarde - +47',
    nome: 'Esvalbarde',
    nomeFormal: 'Esvalbarde',
  },
  {
    codigo: '581',
    value: '001',
    iso: 'UM',
    label: 'Ilhas Menores Distantes dos Estados Unidos - +1',
    nome: 'Ilhas Menores Distantes dos Estados Unidos',
    nomeFormal: 'Ilhas Menores Distantes dos Estados Unidos',
  },
];
