import dayjs from 'dayjs';

const getXNextBusinessDays = (fromDate, numberOfDates) => {
  if (!fromDate || !dayjs(fromDate).isValid()) throw new Error('Invalid date');
  if (!numberOfDates || numberOfDates < 1)
    throw new Error('Invalid numberOfDates');
  let fromDateTransformed = dayjs(fromDate);
  const nextBusinessDays = [];
  while (nextBusinessDays.length < numberOfDates) {
    fromDateTransformed = fromDateTransformed.add(1, 'day');
    if (fromDateTransformed.day() !== 0 && fromDateTransformed.day() !== 6) {
      nextBusinessDays.push(fromDateTransformed.format('YYYY-MM-DD'));
    }
  }
  return nextBusinessDays;
};

export default getXNextBusinessDays;
