import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Breadcrumb,
  Col,
  ControlLabel,
  Form,
  FormGroup,
  Progress,
  Row,
  SelectPicker,
} from 'rsuite';
import * as Yup from 'yup';
import IdentificationIcon from '~/assets/identificacao_mobile.svg';
import FilesList from '~/components/FilesList';
import { MobileHeaderData } from '~/components/HeaderData';
import { InputUpload } from '~/components/InputUpload';
import LoggedLayout, { LayoutContent } from '~/components/LoggedLayout';
import cpfMask from '~/mask/cpf';
import { StyledButton } from '~/pages/UpdatePersonalData/style';
import { api } from '~/services/api';
import { loadingCompleted, setLoading } from '~/store/modules/auth/actions';
import { updateUser } from '~/store/modules/user/actions.js';
import { calculatePercentPFIdentity } from '~/utils/calculate-percent';
import * as fileUploaderService from '~/services/files';
import {
  Sidebar,
  SidebarContent,
  SidebarIcon,
  SidebarSubtitle,
  SidebarTitle,
} from '../../../components/Sidebar';
import {
  BackButton,
  Container,
  DisplayGrid,
  DocumentFields,
  ErrorBox,
  Fields,
  FormGrid,
  StyledInput,
  SuccessBox,
  Title,
} from '../../../components/styles/form.js';
import UploadFiles from '../../../components/UploadFiles';
import history from '../../../services/history';
import ErrorModal from '~/components/ErrorModal';

import {
  identificationDocumentTypes,
  convertDocumentTypeNumberInLabel,
  convertDocumentTypeInNumber,
} from '../../../utils/files/fileUtils';

import {
  FooterLabel,
  InfoStatusComplete,
  InfoStatusIncomplete,
} from './styles';

const { Line } = Progress;

const Identification = () => {
  const dispatch = useDispatch();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [uploadProgressFileList, setUploadProgressFileList] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [errorData, setErrorData] = useState({});

  const { loading } = useSelector((state) => state.auth);
  const {
    document,
    issuingAgency,
    issuingExpeditionDate,
    identificationDocument,
    identificationDocumentType,
    issuingState,
    dynamicsId,
  } = useSelector((state) => state.user.profile);

  const user = useSelector((state) => state.user);
  const { _id, isRelationship } = user.profile;
  const [documentTypeIdentification, setDocumentTypeIdentification] = useState(
    convertDocumentTypeNumberInLabel(identificationDocumentType) ?? ''
  );

  const documentId = cpfMask(user.profile.document);
  const documentClassName = 'Documento de Identificação';
  const documentType = 'Tipo Documento Identificação';
  const documentProperty = 'Registro Geral - RG';

  const stateOptions = [
    { label: 'Acre', value: 'AC' },
    { label: 'Alagoas', value: 'AL' },
    { label: 'Amapá', value: 'AP' },
    { label: 'Amazonas', value: 'AM' },
    { label: 'Bahia', value: 'BA' },
    { label: 'Ceará', value: 'CE' },
    { label: 'Distrito Federal', value: 'DF' },
    { label: 'Espírito Santo', value: 'ES' },
    { label: 'Goiás', value: 'GO' },
    { label: 'Maranhão', value: 'MA' },
    { label: 'Mato Grosso', value: 'MT' },
    { label: 'Mato Grosso do Sul', value: 'MS' },
    { label: 'Minas Gerais', value: 'MG' },
    { label: 'Pará', value: 'PA' },
    { label: 'Paraíba', value: 'PB' },
    { label: 'Paraná', value: 'PR' },
    { label: 'Pernambuco', value: 'PE' },
    { label: 'Piauí', value: 'PI' },
    { label: 'Rio de Janeiro', value: 'RJ' },
    { label: 'Rio Grande do Norte', value: 'RN' },
    { label: 'Rio Grande do Sul', value: 'RS' },
    { label: 'Rondônia', value: 'RO' },
    { label: 'Roraima', value: 'RR' },
    { label: 'Santa Catarina', value: 'SC' },
    { label: 'São Paulo', value: 'SP' },
    { label: 'Sergipe', value: 'SE' },
    { label: 'Tocantins', value: 'TO' },
  ];

  const IdentificationSchema = Yup.object().shape({
    issuingState: Yup.string().required('Obrigatório').nullable(),
  });
  const [percentage, setPercentage] = useState(0);
  const formik = React.useRef();

  const today = new Date();

  useEffect(() => {
    window.amplitude.getInstance().logEvent('access Identification page');
  }, []);

  useEffect(() => {
    setPercentage(calculatePercentPFIdentity(user.profile), [
      document,
      identificationDocument,
      identificationDocumentType,
      issuingAgency,
      issuingState,
      issuingExpeditionDate,
    ]);
  }, [
    document,
    issuingAgency,
    issuingExpeditionDate,
    identificationDocument,
    identificationDocumentType,
    issuingState,
    dynamicsId,
  ]);

  return (
    <LoggedLayout
      isUploading={fileIsUploading}
      uploadProgressFileList={uploadProgressFileList}
    >
      <Container className='responsive-container'>
        <Sidebar>
          <SidebarContent>
            <SidebarIcon />
            <SidebarTitle>Identificação</SidebarTitle>
            <SidebarSubtitle>
              Seus dados e documentos como RG, CPF e etc.
            </SidebarSubtitle>

            {percentage === 100 ? (
              <InfoStatusComplete>Concluído</InfoStatusComplete>
            ) : (
              <InfoStatusIncomplete>Incompleto</InfoStatusIncomplete>
            )}
          </SidebarContent>
        </Sidebar>
        <MobileHeaderData
          title='Identificação'
          percentage={Math.round(percentage)}
          icon={IdentificationIcon}
        />
        <LayoutContent>
          <ErrorModal
            showModal={showModal}
            onChange={() => setShowModal(false)}
            {...errorData}
          />

          <Formik
            innerRef={formik}
            validateOnChange
            validateOnBlur
            isInitialValid
            initialValues={{
              identificationDocument,
              identificationDocumentType:
                convertDocumentTypeNumberInLabel(identificationDocumentType) ??
                '',
              issuingState,
              issuingAgency,
              issuingExpeditionDate: issuingExpeditionDate
                ? issuingExpeditionDate.split('T')[0]
                : today,
              document,
            }}
            validationSchema={IdentificationSchema}
            onSubmit={async (values, action) => {
              const id = _id;

              if (values.identificationDocumentType === 'cnh') {
                values.issuingAgency = 'DETRAN';
              }

              if (values.identificationDocumentType === 'passport') {
                values.issuingAgency = 'DPF';
              }

              values.identificationDocumentType = convertDocumentTypeInNumber(
                values.identificationDocumentType
              );

              const data = {
                document: values.document,
                identificationDocument: values.identificationDocument,
                identificationDocumentType: values.identificationDocumentType,
                issuingExpeditionDate: new Date(values.issuingExpeditionDate),
                issuingState: values.issuingState,
                issuingAgency: values.issuingAgency,
              };

              try {
                dispatch(setLoading());
                action.setSubmitting(true);

                if (filesToUpload.length > 0) {
                  setFileIsUploading(true);
                  let uploadList = filesToUpload.map((f) => {
                    return {
                      id: f.name,
                      progress: 0,
                    };
                  });
                  setUploadProgressFileList([...uploadList]);
                  await Promise.all(
                    filesToUpload.map((item) =>
                      fileUploaderService
                        .UploadFiles(
                          item,
                          documentId,
                          documentClassName,
                          documentType,
                          documentProperty,
                          (progress) => {
                            uploadList = uploadList.map((up) => {
                              return {
                                id: up.id,
                                progress:
                                  up.id == item.name ? progress : up.progress,
                              };
                            });
                            setUploadProgressFileList([...uploadList]);
                          }
                        )
                        .then(() => {
                          uploadList = uploadList.map((up) => {
                            return {
                              id: up.id,
                              progress: up.id == item.name ? 100 : up.progress,
                            };
                          });
                          setUploadProgressFileList([...uploadList]);
                        })
                    )
                  );
                  setFileIsUploading(false);
                }

                api
                  .put(`/register/users/identifier`, data)
                  .then((item) => {
                    dispatch(
                      updateUser({
                        user: item.data,
                      })
                    );
                    dispatch(loadingCompleted());
                    toast.success('Alterações realizadas com sucesso.');
                    window.amplitude
                      .getInstance()
                      .logEvent('Updated identification data', item);
                    history.replace('/update-data');
                  })
                  .catch((item) => {
                    setShowModal(true);
                    setErrorData({
                      title: 'Erro ao processar dados',
                      description:
                        'Tivemos um problema no envio das informações, revise o preenchimento dos campos obrigatórios ou tente novamente.',
                    });
                    // toast.error('Houve um problema ao enviar as informações.');
                    dispatch(loadingCompleted());
                    action.setSubmitting(false);
                    window.amplitude
                      .getInstance()
                      .logEvent('Error in update client', item);
                  });
              } catch (err) {
                setShowModal(true);
                setErrorData({
                  title: 'Erro ao processar dados',
                  description:
                    'Tivemos um problema no envio do seu arquivo, tente efetuar o upload novamente.',
                });
                // toast.error('Houve um problema ao enviar as infromações.');
                dispatch(loadingCompleted());
                action.setSubmitting(false);
                window.amplitude
                  .getInstance()
                  .logEvent('Error in update client', err);
              }
            }}
          >
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              isValid,
              touched,
              handleSubmit,
              setFieldValue,
            }) => {
              const handleSelectChange = (value, event) => {
                setFieldValue('identificationDocumentType', value);
                setDocumentTypeIdentification(value);
              };

              const handleSelectStateChange = (value, event) => {
                setFieldValue('issuingState', value);
              };

              return (
                <Form onSubmit={handleSubmit}>
                  <DisplayGrid>
                    <FormGrid>
                      <Breadcrumb
                        className='breadcrumb'
                        style={{ paddingLeft: 0 }}
                      >
                        <Link to='/home'>Home</Link>
                        <Link to='/update-data'>Meus dados</Link>
                        <Link className='active'>Identificação</Link>
                      </Breadcrumb>

                      <BackButton className='back-button'>
                        <ArrowLeft />
                        <Link to='/update-data'>
                          <p>Voltar</p>
                        </Link>
                      </BackButton>
                      <Fields className='fieldsName'>
                        <Title>Dados do documento</Title>
                        <FormGroup>
                          <ControlLabel>Selecione o documento *</ControlLabel>
                          <SelectPicker
                            size='lg'
                            locale={{ searchPlaceholder: 'Pesquisar' }}
                            placeholder='Escolha seu documento'
                            onChange={(value, event) =>
                              handleSelectChange(value)
                            }
                            data={identificationDocumentTypes}
                            value={values.identificationDocumentType}
                            style={{ width: '380px' }}
                          />
                          {errors.identificationDocumentType &&
                            touched.identificationDocumentType && (
                              <span className='input-error'>
                                {errors.identificationDocumentType}
                              </span>
                            )}
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>Nº do documento *</ControlLabel>
                          <StyledInput
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='identificationDocument'
                            id='identificationDocument'
                            value={values.identificationDocument}
                            maxLength='11'
                            type='text'
                            style={{ width: '380px' }}
                          />
                          {errors.identifyDocument &&
                            touched.identifyDocument && (
                              <span>{errors.identifyDocument}</span>
                            )}

                          {documentTypeIdentification !== 'cnh' &&
                          documentTypeIdentification !== 'passport' ? (
                            <Row>
                              <Col xs={24} md={10}>
                                {/* <section> */}
                                <ControlLabel>Org. Exped. *</ControlLabel>
                                <StyledInput
                                  onChange={(value, event) =>
                                    handleChange(event)
                                  }
                                  onBlur={handleBlur}
                                  name='issuingAgency'
                                  id='issuingAgency'
                                  minLength={3}
                                  maxLength={5}
                                  value={values.issuingAgency}
                                  type='text'
                                  style={{ width: '380px' }}
                                />
                                {errors.issuingAgency &&
                                  touched.issuingAgency && (
                                    <span className='input-error'>
                                      {errors.issuingAgency}
                                    </span>
                                  )}
                                {/* </section> */}
                              </Col>
                              <Col xs={24} md={14}>
                                <section>
                                  <ControlLabel>UF emissor *</ControlLabel>
                                  <SelectPicker
                                    size='lg'
                                    locale={{ searchPlaceholder: 'Pesquisar' }}
                                    placeholder='Escolha o estado'
                                    onChange={(value, event) =>
                                      handleSelectStateChange(value)
                                    }
                                    onBlur={handleBlur}
                                    data={stateOptions}
                                    value={values.issuingState}
                                    style={{ width: '380px', marginTop: '5px' }}
                                    name='issuingState'
                                    id='issuingState'
                                  />
                                  {errors.issuingState &&
                                    touched.issuingState && (
                                      <span className='input-error'>
                                        {errors.issuingState}
                                      </span>
                                    )}
                                </section>
                              </Col>
                            </Row>
                          ) : (
                            <Row>
                              <Col xs={24}>
                                {/* <section> */}
                                <ControlLabel>UF emissor *</ControlLabel>
                                <SelectPicker
                                  size='lg'
                                  locale={{ searchPlaceholder: 'Pesquisar' }}
                                  placeholder='Escolha o estado'
                                  onChange={(value, event) =>
                                    handleSelectStateChange(value)
                                  }
                                  onBlur={handleBlur}
                                  data={stateOptions}
                                  value={values.issuingState}
                                  style={{
                                    width: '380px',
                                    marginTop: '5px',
                                    marginBottom: '8px',
                                  }}
                                  name='issuingState'
                                  id='issuingState'
                                />
                                {errors.issuingState &&
                                  touched.issuingState && (
                                    <span className='input-error'>
                                      {errors.issuingState}
                                    </span>
                                  )}
                                {/* </section> */}
                              </Col>
                            </Row>
                          )}
                          <ControlLabel>Expedição do documento *</ControlLabel>
                          <StyledInput
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='issuingExpeditionDate'
                            id='issuingExpeditionDate'
                            value={
                              new Date(values.issuingExpeditionDate) > today
                                ? ''
                                : values.issuingExpeditionDate
                            }
                            type='date'
                            required
                            pattern='[0-9]{4}-[0-9]{2}-[0-9]{2}'
                            style={{ width: '380px' }}
                          />
                          {errors.issuingExpeditionDate &&
                            touched.issuingExpeditionDate && (
                              <span>{errors.issuingExpeditionDate}</span>
                            )}
                          <ControlLabel>CPF</ControlLabel>
                          <StyledInput
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='document'
                            id='document'
                            disabled
                            maxLength={14}
                            value={cpfMask(values.document)}
                            type='text'
                            style={{ width: '380px' }}
                          />
                        </FormGroup>
                      </Fields>
                    </FormGrid>
                    <FormGrid>
                      <UploadFiles
                        className={documentClassName}
                        type={documentType}
                        titleText='Envie documentos de identidade, como RG, CNH e Passaporte'
                        required={isRelationship !== true}
                        files={filesToUpload}
                        onChange={(files) => {
                          setFilesToUpload([...files]);
                          setFieldValue('files', files);
                        }}
                        listOldFiles
                        setFilesList={setFilesList}
                      />
                    </FormGrid>
                    <FormGrid>
                      <FormGroup>
                        <StyledButton
                          type='submit'
                          disabled={
                            isRelationship === true
                              ? loading
                              : loading || !filesToUpload.length
                          }
                        >
                          Salvar
                        </StyledButton>
                      </FormGroup>
                      <FormGroup>
                        <FooterLabel>
                          * Campos obrigatórios para salvar formulário e seguir
                          com o cadastro.
                        </FooterLabel>
                      </FormGroup>
                    </FormGrid>
                  </DisplayGrid>
                </Form>
              );
            }}
          </Formik>
        </LayoutContent>
      </Container>
    </LoggedLayout>
  );
};

export default Identification;
