import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1rem;
  }
`;

export const All = styled.div`
  margin-left: 10%;
  /* overflow: auto; */

  @media (max-width: 1000px) {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const Title = styled.h2`
  color: #20315b;
  font-family: IBM Plex Sans;
  font-weight: 500;
  width: 100%;
  font-size: 24px;
  line-height: 40px;

  @media (max-width: 1000px) {
    display: inline-block;
    max-width: 100%;
  }

  @media (min-width: 1000px) {
    margin-bottom: 20px;
    width: 298px;
    font-size: 32px;
  }
`;

export const LogoImage = styled.img`
  width: 250px;
  margin-left: -20px;
  display: block;
`;

export const SuccessIcon = styled.img`
  width: 74px;
  height: 74px;
  text-align: left;
  margin-top: 100px;
  margin-bottom: 47px;
`;

export const Description = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #455566;
  max-width: 350px;

  display: block;

  & strong {
    display: block;
  }
`;

export const Phone = styled(Description)`
  font-weight: 700;
  background: #eff2f7;
  border: 1px solid #d3dce6;
  border-radius: 5px;
  display: inline-block;
  margin: 16px 0;
  padding: 8px 21px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 384px;

  @media (min-width: 1000px) {
    width: auto;
    max-width: 30vw;
    margin-top: 60px;
  }

  .input {
    margin: 5px 0 5px 0;
    height: 50px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #d3dce6;
    padding-left: 10px;

    @media (min-width: 1000px) {
      width: 384px;
      max-width: 100%;
    }
  }

  button {
    margin-top: 20px;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    width: 100%;
    height: 48px;
    color: #fff;
    background: linear-gradient(
      138.72deg,
      #48d3fe 28.81%,
      #0fc7ff 52.98%,
      #09b2e6 84.96%
    );
    box-shadow: 0px 3px 20px rgba(114, 222, 255, 0.25),
      0px 2px 4px rgba(196, 241, 255, 0.25), 0px 5px 20px rgba(0, 159, 217, 0.2);
    border-radius: 30px;
    border: none;

    @media (min-width: 1000px) {
      position: absolute;
      width: 384px;
    }
  }
`;

export const Banner = styled.div`
  top: 0;
  right: 0;
  position: fixed;

  img {
    width: 100%;
    max-width: 60vw;
    height: 100vh;
    object-fit: cover;
  }

  @media (max-width: 1000px) {
    display: none;
  }

  @media (min-width: 1000px) {
    width: 60%;
  }
`;
