import React from 'react';
import { Modal } from 'rsuite';
import { XCircle } from 'react-feather';

import * as S from './styles';

export default function ErrorModal({
  showModal = false,
  title,
  description,
  onChange,
}) {
  return (
    <S.ModalWrapper show={showModal}>
      <Modal.Header>
        <S.Title>
          <XCircle
            color='#0FC7FF'
            size={33}
            style={{ cursor: 'pointer' }}
            onClick={onChange}
          />

          {title}
        </S.Title>
      </Modal.Header>

      <Modal.Body>
        <S.Description>{description}</S.Description>
      </Modal.Body>

      <Modal.Footer>
        <S.CustomButtom onClick={onChange}>Ok</S.CustomButtom>
      </Modal.Footer>
    </S.ModalWrapper>
  );
}
