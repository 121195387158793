import React, { useCallback, useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { ControlLabel, FormGroup, SelectPicker } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import LoggedLayout from '~/components/LoggedLayout';
import IconDoc from '~/pages/MyData/icons/card.svg';
import { StyledInput } from '~/components/styles/form.js';

import {
  setCheckoutStep,
  getProducts,
  generateTicketOrderForCard,
} from '~/store/modules/exchange/actions';

import {
  Container,
  HeaderBar,
  CardDataWrapper,
  CardRechargeInfo,
  CardTaxInfo,
  ConfirmButton,
  PageWrapper,
  DesktopComponentHeader,
  InvalidBanner,
} from './styles';
import history from '~/services/history';
import api from '~/services/api';

function ExchangeSolicitationRecharge(props) {
  const [card, setCard] = useState();
  const [moneyExchange, setMoneyExchange] = useState();
  const [taxExchange, setTaxExchange] = useState();
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [productExchange, setProductExchange] = useState();

  const { productList } = useSelector((state) => state.exchange);
  const { profile } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const compareDate = useCallback((expires) => {
    if (!expires) return false;

    const today = new Date();
    const expiresSplit = expires.split('/');

    const expireDate = new Date(
      expiresSplit[1],
      expiresSplit[0] - 1,
      expiresSplit[0] === 2 ? '28' : '30'
    );

    return today.getTime() > expireDate.getTime();
  }, []);

  const getCard = useCallback(async () => {
    try {
      const response = await api.get(
        `/exchange/cards/card/{cardNumber}?cardNumber=${props.match.params.cardNumber}`
      );

      setCard(response.data);
    } catch (error) {
      toast.error('Não foi possível encontrar o cartão');
      history.goBack();
    }
  }, []);

  const getTax = useCallback(async (moneyExchange) => {
    try {
      const response = await api.get(
        `/exchange/taxes?codeAgent=DIGITAL&codeProduct=${moneyExchange}`
      );

      setTaxExchange(response.data);
    } catch (error) {
      return toast.error(
        'Não é possível calcular a taxa da moeda escolhida no momento, tente de novo mais tarde'
      );
    }
  }, []);

  useEffect(() => {
    if (!productList.length) dispatch(getProducts());

    if (productList.length && !currencyOptions.length) {
      const products = productList.filter(
        (item) =>
          item.productTypeDescription === 'CARTAO' &&
          item.currencyDescription.includes('ADVANCED')
      );

      const currencies = products.map((item) => {
        return {
          label: item.currencyDescription,
          value: item.productId,
        };
      });
      setCurrencyOptions(currencies);
    }
  }, [dispatch, productList]);

  useEffect(() => {
    getCard();
  }, [getCard]);

  useEffect(() => {
    if (moneyExchange) getTax(moneyExchange);
  }, [moneyExchange, getTax]);

  const requestRecharge = async (moneyExchange, valueExchange) => {
    const product = productList.find(
      (item) => item.productId === moneyExchange
    );

    const addressResponse = await api.get(`/delivery_address/${profile._id}`);

    dispatch(
      generateTicketOrderForCard({
        client_address_delivery_id: addressResponse.data[0].address_id,
        code_product: moneyExchange,
        delivery_local: 'DELIVERY',
        foreign_currency_value: (
          Number(valueExchange) * Number(taxExchange.sugest_price)
        ).toFixed(2),
        iof: valueExchange * product.iof,
        nacional_currency_value: valueExchange,
        tax_id: taxExchange.id,
        tax_value: taxExchange.sugest_price,
        exchange_card: {
          code_client: profile.gnCambioId,
          document: card.document,
          email: card.email,
          code_currency: moneyExchange,
          card_type: 'TITULAR',
          titular_name: card.mainUser,
          rg: card.rg,
          card_number: card.cardNumber,
          mother_name: card.motherName,
          secret_question: card.secretQuestion,
          secret_answer: card.secretAnswer,
          traveler: card.travelerName,
          document_traveler: card.travelerCPF,
          genre_traveler: card.travelerGender,
          paying: card.paying,
          status: card.status,
          expiration: card.expiress,
          digit_card: card.cardDigit,
          is_charge: 'S',
          provider_Name: '',
          inclusion_date: card.inclusionDate,
          emission_date: card.emissionDate,
          travel_date: card.travelDate,
          birth_date: card.birthDate,
        },
      })
    );
  };

  return (
    <LoggedLayout>
      <Formik
        initialValues={{
          moneyExchange: '',
          valueExchange: '',
        }}
      >
        {({
          errors,
          values,
          handleChange,
          isValid,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setErrors,
          setTouched,
          isSubmitting,
        }) => {
          const changeMoneyCurrency = (value) => {
            setFieldValue('moneyExchange', value);
            const product = productList.find(
              (item) => item.productId === value
            );
            setProductExchange(product);
            getTax(value);
          };

          return (
            <div style={{ width: '100vw' }}>
              <DesktopComponentHeader>
                <button onClick={() => history.goBack()}>
                  <ArrowLeft size={18} /> Voltar
                </button>
                <p>
                  <Link to='/home'>Home</Link> /
                  <Link to='/my-cards'>Meus cartões</Link> / Recarregar meu
                  pré-pago
                </p>
              </DesktopComponentHeader>
              <Container>
                <HeaderBar>
                  <img src={IconDoc} alt='iconDoc' />
                  <h2 className='headerbar-title'>Informações do cartão</h2>
                </HeaderBar>
                <PageWrapper>
                  <CardDataWrapper image='/icons/card-background.png'>
                    {compareDate(card?.expiress) && (
                      <InvalidBanner>CARTÃO INVÁLIDO</InvalidBanner>
                    )}
                    <h3>
                      {card
                        ? card.cardNumber
                            .replace('.', ' ')
                            .replace('.', ' ')
                            .replace('.', ' ') + card.cardDigit
                        : '0000 0000 0000 0000'}
                    </h3>
                    <div>
                      <span>{card ? card.expiress : '00/0000'}</span>
                      <span className='client'>
                        {card ? card.travelerName : 'Cliente'}
                      </span>
                    </div>
                  </CardDataWrapper>
                  <CardRechargeInfo>
                    <h4>Advanced Multi Moeda</h4>

                    <div>
                      <FormGroup>
                        <ControlLabel>Moeda do cartão</ControlLabel>
                        <SelectPicker
                          size='lg'
                          locale={{ searchPlaceholder: 'Pesquisar' }}
                          placeholder='Escolha a moeda'
                          onChange={(value) => changeMoneyCurrency(value)}
                          name='moneyExchange'
                          id='moneyExchange'
                          value={values.moneyExchange}
                          data={currencyOptions}
                          style={{ width: '380px' }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>Valor solicitado</ControlLabel>
                        <StyledInput
                          onChange={(value, event) =>
                            setFieldValue('valueExchange', value)
                          }
                          name='valueExchange'
                          id='valueExchange'
                          value={values.valueExchange}
                          maxLength='11'
                          type='number'
                          step='0.01'
                          disabled={!taxExchange}
                        />
                      </FormGroup>
                    </div>
                  </CardRechargeInfo>
                  <CardTaxInfo>
                    <>
                      <div>
                        <span>Taxa de câmbio</span>
                        <span>
                          <strong>
                            {taxExchange ? taxExchange.sugest_price : '0,00'}
                          </strong>
                        </span>
                      </div>
                      <div>
                        <span>Valor em reais </span>
                        <span>
                          <strong>
                            {taxExchange
                              ? `R$ ${(
                                  Number(values.valueExchange) *
                                  Number(taxExchange.sugest_price)
                                ).toFixed(2)}`
                              : 'R$ 0,00'}
                          </strong>
                        </span>
                      </div>
                      <div>
                        <span>IOF</span>
                        <span>
                          <strong>
                            R${' '}
                            {productExchange && taxExchange
                              ? (
                                  Number(values.valueExchange) *
                                  Number(taxExchange.sugest_price) *
                                  (Number(productExchange.iof) / 100)
                                ).toFixed(2)
                              : '0,00'}
                          </strong>
                        </span>
                      </div>
                      <div>
                        <span>Total (VET)</span>
                        <span>
                          <strong>
                            R${' '}
                            {taxExchange
                              ? (
                                  Number(values.valueExchange) *
                                    Number(taxExchange.sugest_price) +
                                  Number(values.valueExchange) *
                                    Number(taxExchange.sugest_price) *
                                    (Number(productExchange.iof) / 100)
                                ).toFixed(2)
                              : '0,00'}
                          </strong>
                        </span>
                      </div>
                    </>

                    <ConfirmButton
                      onClick={() =>
                        requestRecharge(
                          values.moneyExchange,
                          values.valueExchange
                        )
                      }
                    >
                      Solicitar recarga
                    </ConfirmButton>
                  </CardTaxInfo>
                </PageWrapper>
              </Container>
            </div>
          );
        }}
      </Formik>
    </LoggedLayout>
  );
}

export default ExchangeSolicitationRecharge;
