import React from 'react';
import { Modal } from 'rsuite';
import CheckIcon from './icon/check-icon.svg';
import {
  BodyContent,
  Box,
  ContentModal,
  CustomButtomGhost,
  CustomModal,
  TextBody,
  Title,
  LineTitle,
  CustomButtom,
} from './styles';

const ModalComplete = ({ value }) => {
  return (
    <>
      <CustomModal show={value}>
        <Modal.Body>
          <ContentModal>
            <BodyContent>
              <LineTitle>
                <img src={CheckIcon} />
                <Title>Primeira etapa concluída!</Title>
              </LineTitle>

              <TextBody>
                Ótimo! Agora que você criou seu acesso a plataforma,
                <b> realize seu login e conclua seu cadastro</b> para poder
                operar conosco.
              </TextBody>

              <Box>
                <CustomButtomGhost
                  target='_blank'
                  href='https://mkt.advancedcorretora.com.br/manual-painel-digital'
                >
                  Ver tutorial da plataforma
                </CustomButtomGhost>

                <CustomButtom href='/'>Entrar para cadastrar</CustomButtom>
              </Box>
            </BodyContent>
          </ContentModal>
        </Modal.Body>
      </CustomModal>
    </>
  );
};

export default ModalComplete;
