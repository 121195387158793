import styled from 'styled-components';

export const StyledBox = styled.div`
  width: 100%;
  max-width: 720px;
  background: #ffffff;
  border: 1px solid #c0ccda;
  border-radius: 10px;
  padding: 40px 64px;
  margin: 0 auto 32px;

  counter-reset: list;
`;

export const Observation = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #34404d;
  padding: 24px 0;
  display: block;
`;

export const BoletoNumber = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  display: block;
  text-align: center;
  padding-bottom: 16px;
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #20315b;
  padding-bottom: 24px;
`;

export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 8px 30px;
  margin-top: 8px;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  & svg {
    margin-right: 8px;
  }
`;

export const ListButtons = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BoletoHolder = styled.img`
  margin: 0 auto 8px;
  text-align: center;
  width: 100%;
  object-fit: none;
`;

export const Flag = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: bold;
  color: #14cc70;
  padding-bottom: 16px;

  & img {
    width: 22px;
    height: 22px;
  }
`;
export const CodeArea = styled.div`
  padding: 16px;
  border: 1px solid #e5e9f2;
  border-radius: 4px;
  display: inline-block;

  @media (max-width: 768px) {
    max-width: 246px;
    margin: 24px auto 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: top;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Info = styled.div`
  width: 100%;
  max-width: 279px;

  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

export const InfoTitle = styled.h4`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #34404d;
  margin-bottom: 16px;
`;

export const Steps = styled.ul``;

export const StepsItem = styled.li`
  margin-bottom: 16px;
  display: flex;
`;

export const StepNumber = styled.div`
  background: #2d447f;
  width: 24px;
  height: 24px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  line-height: 1.8;
  border-radius: 50%;
  display: inline-block;
`;

export const StepContent = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  max-width: 240px;
  line-height: 24px;
  color: #34404d;
  margin-left: 12px;
`;
