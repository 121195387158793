import React from 'react';
import { Input, SelectPicker, Tooltip } from 'rsuite';
import styled from 'styled-components';
import Button from '../../components/Button';

export const BodyForm = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    margin: 0 50px;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: 768px) {
    height: calc(100vh - 70px);
  }
`;

export const StyledInput = styled(Input)`
  margin: 5px 0 24px 0;
  height: 42px;
  width: 466px;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #d3dce6;
  padding-left: 10px;
`;

export const StyledSelect = styled(SelectPicker)`
  margin: 5px 0 24px 0;
  height: 42px;
  width: 247px;
  max-width: 100%;
`;

export const StyledTitle = styled.h2`
  color: #20315b;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin: 20px 0;
`;

export const All = styled.div`
  padding: 30px;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 0 30px 30px;
  }

  @media (min-width: 768px) {
    width: calc(100vw - 520px);
    height: calc(100vh - 115px);
  }

  .step {
    color: #09b2e6;
    font-size: 12px;
  }

  .active {
    color: #455566;
    text-decoration: none;
    font-size: 12px;
  }
`;

export const SquareRadioButton = styled.label`
  color: #455566;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 13px;
  border: 1px solid #455566;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.5;
  margin: 0 16px 16px 0;
  transition: 0.5s all;
  display: block;

  @media (min-width: 768px) {
    padding: 13px 24px;
  }
`;

export const SquareButtonContainer = styled.div`
  display: inline-block;
`;

const StyledRadio = styled.input`
  display: none;

  &:checked ~ label {
    background: #009fd9;
    color: #fff;
    border-color: #009fd9;
    opacity: 1;
  }
`;

export const SquareButton = ({ value, label, name, onClick, checked }) => {
  return (
    <SquareButtonContainer onClick={onClick}>
      <StyledRadio
        type='radio'
        id={value.toString()}
        checked={checked}
        name={name}
        value={value}
        onChange={() => {}}
      />
      <SquareRadioButton htmlFor={name}>{label}</SquareRadioButton>
    </SquareButtonContainer>
  );
};

export const BackButton = styled.div`
  display: flex;
  color: #0fc7ff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  p {
    margin-left: 3px;
  }

  a {
    text-decoration: none;
    color: #0fc7ff;
  }
`;

export const StyledButton = styled(Button)`
  width: 141px;
  padding: 16px 0 16px 0;
  margin-top: 15px;
  background: linear-gradient(
    98.1deg,
    #48d3fe 28.81%,
    #0fc7ff 52.98%,
    #09b2e6 84.96%
  );
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;

  @media screen and (max-width: 600px) {
    width: 95%;
  }
`;

export const DisplayGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 35px;
  width: 100%;

  @media (min-width: 768px) {
    margin-left: 50px;
    margin-bottom: 35px;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 90px;
  }
`;

export const FormGrid = styled.div`
  width: 100%;

  a {
    color: #09b2e6;
    font-size: 12px;
  }

  .active {
    color: #455566;
    text-decoration: none;
  }
`;

export const BodyUpload = styled.div`
  margin-top: 8px;

  @media (min-width: 1100px) {
    width: 500px;
  }
`;

export const InfoStatusComplete = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  display: flex;
  align-items: center;
  text-align: right;

  margin-top: 16px;

  color: #14cc70;
`;

export const InfoStatusOptional = styled.p`
  font-family: IBM Plex Sans;
  font-style: italic;
  font-size: 24px;
  line-height: 32px;

  display: flex;
  align-items: center;
  text-align: right;

  margin-top: 16px;

  color: #9ba8b7;
`;

export const TooltipLimit = styled(Tooltip)`
  .rs-tooltip-inner {
    background-color: #132552;
    border-radius: 5px;
    font-family: IBM Plex Sans;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    padding: 8px 8px;
  }

  .rs-tooltip-arrow {
    border-bottom-color: #132552;
  }
`;
