import PropTypes from 'prop-types';
import React from 'react';
import { Prompt } from 'react-router-dom';
import { Modal } from 'rsuite';
import styled from 'styled-components';
import AttentionIcon from '~/assets/attention.svg';
import CloseIcon from '~/assets/close.svg';
import Button from '../components/Button';
import history from '../services/history';

export class RouteLeavingGuard extends React.Component {
  nextLocation = '';

  ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  `;

  ModalTitle = styled.div`
    color: #20315b;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
  `;

  ModalBody = styled.div`
    @media (min-width: 768px) {
      margin: 40px 64px;
    }
  `;

  ModalText = styled.div`
    padding-top: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  `;

  ModalAction = styled.div`
    padding-top: 24px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  `;

  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false,
  };

  showModal = (location) =>
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });

  closeModal = (callback) =>
    this.setState(
      {
        modalVisible: false,
      },
      callback
    );

  shouldNavigateToNextLocation = (nextLocation) => {
    if (nextLocation.pathname.indexOf('/update-data/partners') > -1)
      return true;
    return false;
  };

  handleBlockedNavigation = (nextLocation) => {
    this.nextLocation = nextLocation;
    const { confirmedNavigation } = this.state;
    const { shouldBlockNavigation } = this.props;

    if (
      !confirmedNavigation &&
      shouldBlockNavigation(nextLocation) &&
      !this.shouldNavigateToNextLocation(nextLocation)
    ) {
      this.showModal(nextLocation);
      return false;
    }

    return true;
  };

  handleOnCloseClick = () => this.closeModal();

  handleSaveAndExit = async () => {
    const { onSaveAndExit } = this.props;

    if (onSaveAndExit) {
      await onSaveAndExit();
      this.handleDismissAndNavigate();
    }
  };

  handleDismissAndNavigate = () => {
    this.setState(
      {
        confirmedNavigation: true,
        modalVisible: false,
      },
      () => {
        history.push(this.nextLocation.pathname);
      }
    );
  };

  render() {
    const { when, onSaveAndExit, onCanSave } = this.props;
    const { modalVisible } = this.state;
    return (
      <>
        <Prompt when={when} message={this.handleBlockedNavigation} />

        <Modal
          show={modalVisible}
          style={{ display: 'flex', alignItems: 'center' }}
          onHide={this.handleOnCloseClick}
        >
          <this.ModalBody>
            <this.ModalHeader>
              <img src={AttentionIcon} alt='Ícone de atenção' />
              <button
                onClick={this.handleOnCloseClick}
                style={{ backgroundColor: '#fff' }}
              >
                <img src={CloseIcon} alt='Ícone de fechar' />
              </button>
            </this.ModalHeader>
            <this.ModalTitle>Você não concluiu todas as etapas</this.ModalTitle>
            <this.ModalText>
              <li>
                É necessário concluir todas as etapas para acessar nossos
                serviços!
              </li>
              <br />
              Se ainda sim quiser sair, clique no botão abaixo.
            </this.ModalText>
            <this.ModalAction>
              <Button
                ghost='true'
                type='submit'
                onClick={this.handleDismissAndNavigate}
                sm='true'
              >
                SAIR SEM SALVAR
              </Button>
              {onCanSave() === true && onSaveAndExit && (
                <Button
                  type='submit'
                  onClick={this.handleSaveAndExit}
                  sm='true'
                >
                  SALVAR E SAIR
                </Button>
              )}
            </this.ModalAction>
          </this.ModalBody>
        </Modal>
        {this.props.children}
      </>
    );
  }
}

RouteLeavingGuard.propTypes = {
  when: PropTypes.func,
  shouldBlockNavigation: PropTypes.func.isRequired,
  onSaveAndExit: PropTypes.func,
  onCanSave: PropTypes.func,
};
export default RouteLeavingGuard;
