export function signInRequest(document, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { document, password },
  };
}

export function setLoading() {
  return {
    type: '@auth/LOADING',
  };
}

export function setResetError() {
  return {
    type: '@auth/SIGN_IN_ERROR',
  };
}

export function loadingCompleted() {
  return {
    type: '@auth/LOADING_COMPLETED',
  };
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function signFailure(title, description) {
  return {
    type: '@auth/SIGN_FAILURE',
    payload: { title, description },
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}
