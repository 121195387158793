import React from 'react';
import { ArrowLeft, Calendar, Clock, Hash } from 'react-feather';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Logo from '../../assets/close.svg';
import {
  CardHeaderGroup,
  CardUserImage,
  CardUserName,
  CardFooter,
  CardFooterItem,
  CardFooterContent,
} from '~/components/SolicitationCard/styles';
import Tag from '../Tag';

const StyledSummary = styled.div`
  top: 0;
  width: 100%;
  background: #f9fafc;
  box-shadow: 0px 1.72094px 2.53613px rgba(154, 154, 167, 0.0243888),
    0px 4.75819px 7.01207px rgba(154, 154, 167, 0.035),
    0px 11.4559px 16.8824px rgba(154, 154, 167, 0.0456112),
    0px 38px 56px rgba(154, 154, 167, 0.07);
  border-radius: 6px 6px 0px 0px;
  padding: 32px 40px 23px;
  z-index: 10;
`;

const CardFooterItemOpen = styled(CardFooterItem)`
  @media (max-width: 768px) {
    display: none;
  }
`;
export const Back = styled.span`
  color: #09b2e6;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  svg {
    vertical-align: middle;
    margin-right: 5px;
  }
`;

const HeaderWrapper = styled.div`
  display: inline-block;
`;

const HeaderButtonWrapper = styled.div`
  display: inline-block;
  @media (max-width: 768px) {
    display: none;
  }
`;

const BackWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  background: #f9fafc;

  @media (min-width: 768px) {
    padding-top: 0;
    display: none;
  }
`;

const Close = styled.button`
  color: ${({ theme }) => theme.palette.brand.lightblue.light};
  background: transparent;
  font-size: 1rem;
  font-weight: 600;
  vertical-align: middle;
  margin-left: 5px;
`;

const Icon = styled.img`
  cursor: pointer;
`;

const Summary = ({ data, closeChat }) => {
  const isClosedChat = !!data.closedAt;
  const chatStatus = (isClosed) => (isClosed ? 'Fechado' : 'Aberto');
  return (
    <>
      <BackWrapper>
        <Back onClick={closeChat}>
          <ArrowLeft />
          Voltar
        </Back>
        <Tag success={isClosedChat} danger={!isClosedChat}>
          {chatStatus(isClosedChat)}
        </Tag>
      </BackWrapper>
      <StyledSummary>
        <CardHeaderGroup>
          <HeaderWrapper>
            <CardUserImage circle src={data.chat.customer.photo} />
            <CardUserName>{data.chat.customer.name}</CardUserName>
          </HeaderWrapper>
          <HeaderButtonWrapper onClick={closeChat}>
            <Icon src={Logo} alt='botão fechar' />
            <Close> Fechar </Close>
          </HeaderButtonWrapper>
        </CardHeaderGroup>
        <CardFooter withoutDash>
          <CardFooterItem>
            <Calendar fill='none' />
            <CardFooterContent>
              {dayjs(data.sendAt).format('DD/MM/YYYY')}
            </CardFooterContent>
          </CardFooterItem>

          <CardFooterItem>
            <Clock />
            <CardFooterContent>
              {dayjs(data.sendAt).format('HH:mm')}
            </CardFooterContent>
          </CardFooterItem>

          <CardFooterItem>
            <Hash />
            <CardFooterContent>{data.id}</CardFooterContent>
          </CardFooterItem>

          <CardFooterItemOpen>
            <Tag success={isClosedChat} danger={!isClosedChat}>
              {chatStatus(isClosedChat)}
            </Tag>
          </CardFooterItemOpen>
        </CardFooter>
      </StyledSummary>
    </>
  );
};

export default Summary;
