import React, { useState, useEffect, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import LoggedLayout, { LayoutContent } from '~/components/LoggedLayout';

import HistoryChat from '~/components/HistoryChat';
import HistoryList from '~/components/HistoryList';

import { getHistoryChat } from '~/store/modules/chat/actions';
import { setNewHuggyId } from '~/store/modules/user/actions';

import { HeaderBar } from '../MyData/styles';

import { Iframe } from '~/components/HistoryChat/styles';

import { generateUserHash } from '~/services/hash';

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;

  @media (min-width: 768px) {
    padding-left: 112px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

const Chat = () => {
  const [selectedChat, handleSetChat] = useState('');
  const [isDataSendedToIframe, setDataSended] = useState(false);
  const { user } = useSelector((state) => state);
  const iframeElement = createRef();
  const handleCloseChat = () => handleSetChat('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHistoryChat(user.profile?.huggyContactId));
  }, []);

  useEffect(() => {
    window.addEventListener(
      'message',
      function (event) {
        if (typeof event.data === 'string') {
          if (event.data == 'goBack') {
            handleCloseChat();
          }

          if (event.data.includes('getDataFromId:')) {
            const newHuggyId = event.data.replace('getDataFromId:', '');

            attributeNewHuggyId(newHuggyId);
          }
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    if (iframeElement.current && !isDataSendedToIframe) {
      openHuggy();
    }
  }, [iframeElement]);

  const openHuggy = () => {
    if (!isDataSendedToIframe) {
      const message = {
        name: user.profile.name,
        lastname: user.profile.lastName,
        userIdentifier: user.profile.email,
        userHash: generateUserHash(user.profile.email),
        type: 'ADVANCED_COMMUNICATION',
        phone: `${user.profile.phoneAreaCode}${user.profile.phoneNumber.replace(
          '-',
          ''
        )}`,
      };
      setTimeout(() => {
        document
          .getElementById('huggy-iframe-chat')
          .contentWindow.postMessage(message, '*'); // enviar os dados do contato para o iframe ao clicar para abrir o chat
      }, 1000);
      setDataSended(true);
    }
  };

  const setChat = (data) => {
    handleSetChat(data);
  };

  const attributeNewHuggyId = (id) => {
    if (user.profile?.huggyContactId !== id) {
      dispatch(setNewHuggyId(id));
    }
  };

  return (
    <LoggedLayout>
      <HeaderBar>
        <h2 className='headerbar-title'>Chat</h2>
      </HeaderBar>
      <LayoutContent style={{ paddingTop: '6px' }}>
        <Holder isChatSelected={!!selectedChat}>
          <HistoryList handleChat={setChat} actualChat={selectedChat} />
          {selectedChat === 'NEW_CHAT' ? (
            <div ref={iframeElement}>
              <Iframe
                id='huggy-iframe-chat'
                sandbox='allow-scripts allow-same-origin allow-forms'
                src='/huggy.html'
              />
            </div>
          ) : (
            <HistoryChat
              isChatSelected={!!selectedChat}
              selected={selectedChat}
              closeChat={handleCloseChat}
            />
          )}
        </Holder>
      </LayoutContent>
    </LoggedLayout>
  );
};

export default Chat;
