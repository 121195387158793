import React, { useState } from 'react';
import { Modal } from 'rsuite';

import { CustomButtom, CustomModal } from '~/components/ValidationModal/style';

const ChangedDataModal = ({ onChangeData }) => {
  const [showModal, setShowModal] = useState(false);

  // onChangeData();

  return (
    <CustomModal show={showModal}>
      <Modal.Header>
        <Modal.Title>Alteração de dados</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Nenhum dado foi alterado. Você deseja sair mesmo assim?
      </Modal.Body>
      <Modal.Footer>
        <CustomButtom onClick={() => setShowModal(false)}>Ok</CustomButtom>
      </Modal.Footer>
    </CustomModal>
  );
};

export default ChangedDataModal;
