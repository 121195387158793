import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  place-items: center;

  button {
    margin-top: 20px;
  }
`;

export const Text = styled.div`
  margin-top: 32px;
  grid-column: 1/-1;

  h2 {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    color: #20315b;
  }
`;

export const Icon = styled.div`
  margin-top: 30px;
  grid-column: 1/-1;

  @media (max-width: 425px) {
    margin-top: 60px;
  }
`;

export const ButtonContainer = styled.div`
  grid-column: 1/-1;
  margin-top: 20px;
`;
