import styled from 'styled-components';

export const LoaderCustom = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  top: 0;
`;

export const LoaderText = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #20315b;
`;

export const Loader = styled.div`
  border: 6px solid #eff2f7;
  border-radius: 50%;
  border-top-color: #09b2e6;
  height: 50px;
  width: 50px;
  animation: loaderSpin 0.6s infinite linear;
`;
