import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import chat from './chat/sagas';
import user from './user/sagas';
import exchange from './exchange/sagas';
import bankList from './banklist/sagas';
import partnersList from './partnersList/sagas';

export default function* rootSaga() {
  return yield all([auth, user, bankList, partnersList, chat, exchange]);
}
