import styled from 'styled-components';

export const Main = styled.main`
  min-height: 100vh;
  height: 100%;
  background-color: #f9fafc;
  position: relative;
  width: 100%;
`;

export const Header = styled.div`
  background: #fff;
  border: 1px solid #e0e6ed;
  padding: 16px 0;
`;

export const Container = styled.div`
  width: 100%;
  max-width: calc(1216px - 32px);
  margin: 0 auto;
  position: relative;
  padding: 0 16px;
`;

export const Content = styled.div`
  @media (min-width: 768px) {
    margin-top: 56px;
  }
`;

export const LogoImage = styled.img`
  width: 142px;
`;
export const HeaderHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .rs-progress-info {
    padding-left: 0;
    padding-right: 24px;
  }
`;

export const LineHolder = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;

  .rs-progress-line {
    flex-direction: row-reverse;
  }
`;
