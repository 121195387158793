import React, { useState, useEffect } from 'react';
import { Modal } from 'rsuite';

import { CustomButtom, CustomModal } from '~/components/ValidationModal/style';
import {
  CustomDescription,
  CustomTitle,
  List,
  ListItem,
  ListPrincipal,
  ListData,
  Neighborhood,
} from './styles';

const ModalAllowedAddress = ({ isModalVisible }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(isModalVisible);
  }, [isModalVisible]);

  return (
    <CustomModal onHide={() => setShowModal(false)} show={showModal}>
      <Modal.Header>
        <CustomTitle>Regiões de São Paulo que atendemos</CustomTitle>
        <CustomDescription>
          Todos as regiões a partir de R$ 1.000
        </CustomDescription>
      </Modal.Header>
      <Modal.Body>
        <List>
          <ListItem>
            <ListPrincipal>A</ListPrincipal>
            <ListData>
              <Neighborhood>Aruja</Neighborhood>
              <Neighborhood>Alumínio</Neighborhood>
              <Neighborhood>Americana</Neighborhood>
              <Neighborhood>Araçoiaba da Serra</Neighborhood>
              <Neighborhood>Araçoiabinha</Neighborhood>
              <Neighborhood>Atibaia</Neighborhood>
            </ListData>
          </ListItem>
          <ListItem>
            <ListPrincipal>A</ListPrincipal>
            <ListData>
              <Neighborhood>Aruja</Neighborhood>
              <Neighborhood>Alumínio</Neighborhood>
              <Neighborhood>Americana</Neighborhood>
              <Neighborhood>Araçoiaba da Serra</Neighborhood>
              <Neighborhood>Araçoiabinha</Neighborhood>
              <Neighborhood>Atibaia</Neighborhood>
            </ListData>
          </ListItem>
          <ListItem>
            <ListPrincipal>A</ListPrincipal>
            <ListData>
              <Neighborhood>Aruja</Neighborhood>
              <Neighborhood>Alumínio</Neighborhood>
              <Neighborhood>Americana</Neighborhood>
              <Neighborhood>Araçoiaba da Serra</Neighborhood>
              <Neighborhood>Araçoiabinha</Neighborhood>
              <Neighborhood>Atibaia</Neighborhood>
            </ListData>
          </ListItem>
          <ListItem>
            <ListPrincipal>A</ListPrincipal>
            <ListData>
              <Neighborhood>Aruja</Neighborhood>
              <Neighborhood>Alumínio</Neighborhood>
              <Neighborhood>Americana</Neighborhood>
              <Neighborhood>Araçoiaba da Serra</Neighborhood>
              <Neighborhood>Araçoiabinha</Neighborhood>
              <Neighborhood>Atibaia</Neighborhood>
            </ListData>
          </ListItem>
          <ListItem>
            <ListPrincipal>A</ListPrincipal>
            <ListData>
              <Neighborhood>Aruja</Neighborhood>
              <Neighborhood>Alumínio</Neighborhood>
              <Neighborhood>Americana</Neighborhood>
              <Neighborhood>Araçoiaba da Serra</Neighborhood>
              <Neighborhood>Araçoiabinha</Neighborhood>
              <Neighborhood>Atibaia</Neighborhood>
            </ListData>
          </ListItem>
          <ListItem>
            <ListPrincipal>A</ListPrincipal>
            <ListData>
              <Neighborhood>Aruja</Neighborhood>
              <Neighborhood>Alumínio</Neighborhood>
              <Neighborhood>Americana</Neighborhood>
              <Neighborhood>Araçoiaba da Serra</Neighborhood>
              <Neighborhood>Araçoiabinha</Neighborhood>
              <Neighborhood>Atibaia</Neighborhood>
            </ListData>
          </ListItem>
          <ListItem>
            <ListPrincipal>A</ListPrincipal>
            <ListData>
              <Neighborhood>Aruja</Neighborhood>
              <Neighborhood>Alumínio</Neighborhood>
              <Neighborhood>Americana</Neighborhood>
              <Neighborhood>Araçoiaba da Serra</Neighborhood>
              <Neighborhood>Araçoiabinha</Neighborhood>
              <Neighborhood>Atibaia</Neighborhood>
            </ListData>
          </ListItem>
        </List>
      </Modal.Body>
      <Modal.Footer>
        <CustomButtom onClick={() => setShowModal(false)}>Entendi</CustomButtom>
      </Modal.Footer>
    </CustomModal>
  );
};

export default ModalAllowedAddress;
