import styled from 'styled-components';
import { RadioGroup } from 'rsuite';
import Button from '../Button';

export const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  position: relative;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  .rs-picker-toggle-wrapper {
    width: 258px;

    @media (max-width: 767px) {
      display: block;
      width: 100% !important;
    }
  }
`;

export const SolicitationList = styled.div`
  display: block;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  width: 100%;
  display: inline-flex;
`;

export const QuotationInfo = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: middle;
`;

export const QuotationTitle = styled.span`
  color: #20315b;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
`;
export const FilterBoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 0 0;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const FilterTitle = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: #9ba8b7;
  font-weight: 500;
`;

export const QuotationContent = styled.div`
  display: block;
  padding-top: 16px;
`;
export const StyledButton = styled(Button)`
  width: 193px;
  padding: 10px 0px 10px 0px;
  background: linear-gradient(
    98.1deg,
    #48d3fe 28.81%,
    #0fc7ff 52.98%,
    #09b2e6 84.96%
  );
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;

  @media screen and (max-width: 768px) {
    position: fixed;
    bottom: 84px;
    width: 85%;
  }
`;

export const ListHolder = styled.div`
  overflow: auto;
  width: 100%;
  padding: 16px;
  background-color: #f9fafc;

  @media (min-width: 768px) {
    padding: 32px 16px 0 0;
    max-height: calc(100vh - 80px);
    width: 50%;
  }

  @media (max-width: 768px) {
    padding-bottom: 120px;
  }

  ${(props) =>
    props.selectedList &&
    `
  @media (max-width: 767px) {
    display: none;
  }
  `}
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid #d3dce6;
  padding: 24px 0 24px 0;

  @media (max-width: 767px) {
    height: 0;
    padding: 0;
    border-bottom: 0;
  }
`;

export const ListTitle = styled.h2`
  color: #20315b;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  @media (max-width: 767px) {
    display: none;
  }
`;
