import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FlexboxGrid, Table } from 'rsuite';
import { ReactSVG } from 'react-svg';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import Loading from '~/components/Loading';
import { exchangeAPI } from '../../../services/api';
import { Spacing, Text } from './styles';
import { tableCellTextStyle } from '../styles';
import { transformNumberInMoney } from '~/utils/transformNumberInMoney';

const { Column, HeaderCell, Cell } = Table;

const parseStatus = (status) => {
  const statusEnum = {
    A_FORMALIZAR: 'A formalizar',
    EM_REGISTRO: 'Em registro',
    REGISTRADO: 'Registrado',
    LIQUIDADO: 'Liquidado',
  };

  return statusEnum[status];
};

export default function Shippings() {
  const auth = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [yourShipments, setShipments] = useState([]);

  const getTicket = useCallback(async () => {
    try {
      setLoading(true);

      exchangeAPI.defaults.headers.Authorization = `Bearer ${auth.token}`;
      const { data } = await exchangeAPI.get(
        `/tickets/list/user/${profile._id}`
      );

      const parsedData = data.map((el) => ({
        ...el,
        tax_value: transformNumberInMoney({
          currency: 'BRL',
          number: el.tax_value,
        }),
        iof:
          transformNumberInMoney({
            currency: 'BRL',
            number: el.iof,
          }) || 'R$ 0,00',
        foreign_currency_value: transformNumberInMoney({
          currency: el.code_product,
          number: el.foreign_currency_value,
        }),
        nacional_currency_value: transformNumberInMoney({
          currency: 'BRL',
          number: el.nacional_currency_value,
        }),
        sale_date: dayjs(el.sale_date).format('DD/MM/YYYY'),
        status_id: parseStatus(el.status_id),
        // payment_form: parsePaymentForm(el.payment_form) // TODO: Ignorar por enquanto, vai vir de um novo fornecedor
      }));

      setShipments(parsedData);
    } catch (err) {
      toast.error('Não foi possível listar suas remessas no momento');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getTicket();
  }, [getTicket]);

  if (!loading && yourShipments.length === 0) {
    return (
      <>
        <Spacing />
        <FlexboxGrid justify='center' style={{ textAlign: 'center' }}>
          <FlexboxGrid.Item colspan={10}>
            <ReactSVG src='/icons/calculadora.svg' />
            <Spacing />
            <Text>Você ainda não tem remessas</Text>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </>
    );
  }

  return (
    <div>
      {loading && <Loading />}
      <Table
        data={yourShipments}
        onRowClick={(rowData) => {
          console.log(rowData);
        }}
        autoHeight
      >
        <Column width={50}>
          <HeaderCell>#</HeaderCell>
          <Cell style={tableCellTextStyle} dataKey='ticket_id' />
        </Column>

        <Column width={80}>
          <HeaderCell>Moeda</HeaderCell>
          <Cell style={tableCellTextStyle} dataKey='code_product' />
        </Column>

        <Column width={200}>
          <HeaderCell>Valor moeda estrangeira</HeaderCell>
          <Cell style={tableCellTextStyle} dataKey='foreign_currency_value' />
        </Column>

        <Column width={200}>
          <HeaderCell>Valor moeda nacional</HeaderCell>
          <Cell style={tableCellTextStyle} dataKey='nacional_currency_value' />
        </Column>

        <Column width={200}>
          <HeaderCell>Status</HeaderCell>
          <Cell style={tableCellTextStyle} dataKey='status_id' />
        </Column>

        <Column width={200}>
          <HeaderCell>Descrição</HeaderCell>
          <Cell style={tableCellTextStyle} dataKey='status_description' />
        </Column>

        <Column width={150}>
          <HeaderCell>Data da venda</HeaderCell>
          <Cell style={tableCellTextStyle} dataKey='sale_date' />
        </Column>

        {/* // TODO: Ignorar por enquanto, vai vir de um novo fornecedor */}
        {/* <Column width={150}>
                    <HeaderCell>Forma da pagamento</HeaderCell>
                    <Cell style={tableCellTextStyle} dataKey="payment_form" />
                </Column> */}

        <Column width={150}>
          <HeaderCell>Valor da taxa</HeaderCell>
          <Cell style={tableCellTextStyle} dataKey='tax_value' />
        </Column>

        <Column width={150}>
          <HeaderCell>IOF</HeaderCell>
          <Cell style={tableCellTextStyle} dataKey='iof' />
        </Column>
      </Table>
    </div>
  );
}
