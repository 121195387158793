export function convertDocumentTypeToLabel(identificationDocumentType) {
  switch (identificationDocumentType) {
    case 'rg':
      return 'RG';
    case 'cnh':
      return 'CNH';
    case 'rne':
      return 'RNE';
    case 'passport':
      return 'Passaporte';
    case 'classDocument':
      return 'Documento de Entidade de Classe';
    default:
      return 'RG';
  }
}

export function convertDocumentTypeStateToLabel(identificationDocumentType) {
  switch (identificationDocumentType) {
    case '2':
      return 'RG';
    case '1':
      return 'CNH';
    case '3':
      return 'RNE';
    case '4':
      return 'Passaporte';
    case '5':
      return 'Documento de Entidade de Classe';
    default:
      return 'RG';
  }
}

export function convertDocumentTypeInNumber(documentType) {
  switch (documentType) {
    case 'cnh':
      return 1;
    case 'rg':
      return 2;
    case 'rne':
      return 3;
    case 'passport':
      return 4;
    case 'classDocument':
      return 4;
    default:
      return 4;
  }
}

export function convertDocumentTypeInString(documentType) {
  switch (documentType) {
    case 'cnh':
      return '1';
    case 'rg':
      return '2';
    case 'rne':
      return '3';
    case 'passport':
      return '4';
    case 'classDocument':
      return '4';
    default:
      return '4';
  }
}

export function convertDocumentTypeInLabel(documentType) {
  switch (documentType) {
    case '1':
      return 'cnh';
    case '2':
      return 'rg';
    case '3':
      return 'rne';
    case '4':
      return 'passport';
    case '5':
      return 'classDocument';
    default:
      return 'rg';
  }
}

export function convertDocumentTypeNumberInLabel(documentType) {
  switch (documentType) {
    case 1:
      return 'cnh';
    case 2:
      return 'rg';
    case 3:
      return 'rne';
    case 4:
      return 'passport';
    case 5:
      return 'classDocument';
    default:
      return 'rg';
  }
}

export function getMfilesDocumentType(documentType) {
  const types = identificationDocumentTypes.filter(
    (a) => a.value === documentType
  );
  console.log(`getMfilesDocumentType`, documentType, types);
  if (types.length > 0) {
    return types[0].fileUpload?.documentType;
  }
}

export function getMfilesDocumentClassName(documentType) {
  const types = identificationDocumentTypes.filter(
    (a) => a.value === documentType
  );
  if (types.length > 0) {
    return types[0].fileUpload?.documentClassName;
  }
}

export function getMFilesDocumentProperty(documentType) {
  const types = identificationDocumentTypes.filter(
    (a) => a.value === documentType
  );
  if (types.length > 0) {
    return types[0].fileUpload?.documentProperty;
  }
}

export const identificationDocumentTypes = [
  {
    label: 'RG',
    value: 'rg',
    fileUpload: {
      documentClassName: 'Documento de Identificação',
      documentType: 'Tipo Documento Identificação',
      documentProperty: 'Registro Geral - RG',
    },
  },
  {
    label: 'CNH',
    value: 'cnh',
    fileUpload: {
      documentClassName: 'Documento de Identificação',
      documentType: 'Tipo Documento Identificação',
      documentProperty: 'Registro Geral - CNH',
    },
  },
  {
    label: 'RNE',
    value: 'rne',
    fileUpload: {
      documentClassName: 'Documento de Identificação',
      documentType: 'Tipo Documento Identificação',
      documentProperty: 'Registro Geral - RNE',
    },
  },
  {
    label: 'Passaporte',
    value: 'passport',
    fileUpload: {
      documentClassName: 'Documento de Identificação',
      documentType: 'Tipo Documento Identificação',
      documentProperty: 'Outros',
    },
  },
  {
    label: 'Entidade de Classe',
    value: 'classDocument',
    fileUpload: {
      documentClassName: 'Documento de Identificação',
      documentType: 'Tipo Documento Identificação',
      documentProperty: 'Outros',
    },
  },
];
