import { calculateIof, calculateValueWithIof } from './calculateIOF';

export function calcutateCheckoutValues({ selectedProduct, defaultIof }) {
  const deliveryValue = selectedProduct?.deliveryValue;
  const taxValue = Number(selectedProduct?.currencyFromValue);
  const iofPercent = Number(selectedProduct?.iof) || Number(defaultIof);
  const iofValue = calculateIof(selectedProduct?.currencyFromValue, iofPercent);
  const valueWithIof = calculateValueWithIof(
    selectedProduct?.currencyFromValue,
    iofPercent
  );
  const totalValue = valueWithIof + deliveryValue;

  return {
    deliveryValue,
    taxValue,
    iofPercent,
    iofValue,
    valueWithIof,
    totalValue,
  };
}
