import React from 'react';
import { Modal } from 'rsuite';
import { CloseOutlined } from '@mui/icons-material';

import * as S from './styles';

export default function PepModal({ show, onHide }) {
  return (
    <S.SModal
      show={show}
      onHide={onHide}
      size='md'
      style={{ padding: '16px 0 0 0' }}
    >
      <Modal.Header>
        <S.ModalClose onClick={onHide}>
          <CloseOutlined color='#0fc7ff' />
        </S.ModalClose>
      </Modal.Header>

      <Modal.Body>
        <h2>Pessoa Exposta Politicamente (PEP)</h2>

        <S.Description style={{ marginBottom: 16 }}>
          Pessoa exposta politicamente é aquela que desempenha ou tenha
          desempenhado, nos últimos cinco (5) anos, cargos, empregos ou funções
          públicas relevantes, no Brasil ou em outros países, territórios e
          dependências estrangeiros, assim como seus representantes, familiares
          e outras pessoas de seu relacionamento próximo. São considerados
          familiares e parentes, na linha direta, até o primeiro grau e ainda o
          cônjuge, o companheiro (a), o enteado (a). São considerados pessoas de
          relacionamento próximo, sócios, procuradores e pessoas com quem
          mantenha relacionamento de negócios em caráter contínuo.
        </S.Description>

        <S.Contrast>
          <S.Description>
            Em entendimento a Circular 3.461 do BACEN que dispõe sobre Pessoas
            Expostas Politicamente, ou seja, candidatos eleitos a cargos
            públicos nos últimos 5 anos ou conforme denominações acima, declaro
            que:
          </S.Description>
        </S.Contrast>
      </Modal.Body>
    </S.SModal>
  );
}
