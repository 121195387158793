import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { saveBanks } from './actions';

import { api } from '~/services/api';

export function* getBanks({ payload }) {
  try {
    api.defaults.headers.Authorization = `Bearer ${payload.auth}`;
    const response = yield call(api.get, `/register/bank_data`);

    yield put(saveBanks(response.data));
  } catch (err) {
    toast.error('Houve um problema com o login, verifique suas credenciais.');
  }
}

export default all([takeLatest('@bankList/GET_BANKS', getBanks)]);
