import React from 'react';
import { Calendar, Clock, Hash } from 'react-feather';
import dayjs from 'dayjs';
import Tag from '~/components/Tag';
import {
  StyledCard,
  CardHeader,
  CardHeaderGroup,
  CardUserImage,
  CardUserName,
  CardContent,
  CardFooter,
  CardFooterItem,
  CardFooterContent,
  CardFooterItemWrapper,
  Arrow,
} from './styles';

const SolicationCard = ({ data, onClick, isSelected }) => {
  const isClosedChat = !!data.closedAt;
  const chatStatus = (isClosed) => (isClosed ? 'Fechado' : 'Aberto');

  return (
    <StyledCard onClick={onClick} isSelected={isSelected}>
      <CardHeader>
        <CardHeaderGroup>
          <CardUserImage circle src={data.chatCustomer.photo} />
          <CardUserName>{data.agents[0]?.name}</CardUserName>
        </CardHeaderGroup>
        <CardHeaderGroup>
          <Arrow />
        </CardHeaderGroup>
      </CardHeader>

      <CardContent>{data.lastMessage.text}</CardContent>

      <CardFooter>
        <CardFooterItemWrapper>
          <CardFooterItem>
            <Calendar fill='none' />
            <CardFooterContent>
              {dayjs(data.lastMessage.sendAt).format('DD/MM/YYYY')}
            </CardFooterContent>
          </CardFooterItem>

          <CardFooterItem>
            <Clock />
            <CardFooterContent>
              {dayjs(data.lastMessage.sendAt).format('HH:mm')}
            </CardFooterContent>
          </CardFooterItem>
        </CardFooterItemWrapper>
        <CardFooterItemWrapper>
          <CardFooterItem>
            <Tag success={isClosedChat} danger={!isClosedChat}>
              {chatStatus(isClosedChat)}
            </Tag>
          </CardFooterItem>
          <CardFooterItem>
            <Hash />
            <CardFooterContent>{data.id}</CardFooterContent>
          </CardFooterItem>
        </CardFooterItemWrapper>
      </CardFooter>
    </StyledCard>
  );
};

export default SolicationCard;
