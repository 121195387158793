import React, { useState, useEffect } from 'react';

import { Form, FormGroup, ControlLabel, Input, SelectPicker } from 'rsuite';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import check from './check.svg';

import { updateUser } from '~/store/modules/user/actions.js';

import {
  Section,
  Bar,
  Container,
  Link,
  Title,
  Content,
  Stepper,
  StepperItem,
  StepperItemNumber,
  StepperContent,
  ConfirmButton,
  StepperTitle,
  StyledLogo,
  StepperColumn,
} from './styles';
import Logo from '~/assets/logo.svg';
import Button from '~/components/Button';
import { api } from '~/services/api';

const ConfirmData = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const actualData = [];
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api.get('/lists/countries').then(({ data }) => {
      data.map((item) => {
        actualData.push({ label: item.country, value: item.id });
      });

      setCountries(actualData);
    });
  }, []);

  const history = useHistory();

  const goToNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const goToStep = (step) => {
    setActiveStep(step);
  };

  const updateDataAndGoToPanel = () => {
    history.push('/home');
  };

  return (
    <Section>
      <Bar>
        <Container>
          <StyledLogo src={Logo} />
          <Link to='/home'>Sair e confirmar depois</Link>
        </Container>
      </Bar>
      <Content>
        <Container
          style={{ flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <Title>
            Esses são os seus dados que encontramos.
            <br />
            Antes de continuar, confirme se estão corretos:
          </Title>
          <Formik
            initialValues={{
              rg: user.profile?.identificationDocument
                ? user.profile?.identificationDocument
                : '',
              uf: user.profile?.issuingState ? user.profile?.issuingState : '',
              orgExp: user.profile?.issuingAgency
                ? user.profile?.issuingAgency
                : '',
              dateExp: user.profile?.issuingExpeditionDate
                ? dayjs(user.profile?.issuingExpeditionDate).format(
                    'DD/MM/YYYY'
                  )
                : '',
              name:
                user.profile?.name && user.profile?.lastName
                  ? `${user.profile.name} ${user.profile.lastName}`
                  : '',
              companyWhereWorkName: user.profile?.companyWhereWorkName
                ? user.profile?.companyWhereWorkName
                : '',
              birthCity: user.profile?.cityBirth ? user.profile?.cityBirth : '',
              state: user.profile?.stateBirth ? user.profile?.stateBirth : '',
              country: user.profile?.countryBirth
                ? user.profile?.countryBirth
                : '',
            }}
          >
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              isValid,
              touched,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              const confirmPersonalData = () => {
                setLoading(true);
                api
                  .put(`/register/users/identifier`, {
                    identificationDocument: values.rg,
                    identificationDocumentType:
                      user.profile?.identificationDocumentType,
                    issuingExpeditionDate: new Date(values.dateExp),
                    issuingState: values.uf,
                    issuingAgency: values.orgExp,
                  })
                  .then(({ data }) => {
                    dispatch(
                      updateUser({
                        user: data,
                      })
                    );
                    goToNextStep();
                    setLoading(false);
                  })
                  .catch((err) => {
                    setLoading(false);
                    toast.error('Houve um problema ao enviar as informações.');
                    throw err;
                  });
              };

              const confirmAddressData = () => {
                setLoading(true);
                api
                  .put(`/register/users/person-general`, {
                    cityBirth: values.birthCity,
                    stateBirth: values.state,
                    countryBirth: values.country,
                  })
                  .then(({ data }) => {
                    dispatch(
                      updateUser({
                        user: data,
                      })
                    );
                    goToNextStep();
                    setLoading(false);
                  })
                  .catch((err) => {
                    setLoading(false);
                    toast.error('Houve um problema ao enviar as informações.');
                    throw err;
                  });
              };

              const confirmWorkData = () => {
                setLoading(true);
                api
                  .put(`/users/${user.profile?._id}/profission`, {
                    companyWhereWorkName: values.companyWhereWorkName,
                  })
                  .then(({ data }) => {
                    dispatch(
                      updateUser({
                        user: data,
                      })
                    );
                    goToNextStep();
                    setLoading(false);
                  })
                  .catch((err) => {
                    setLoading(false);
                    setError(true);
                    toast.error('Houve um problema ao enviar as informações.');

                    setTimeout(() => {
                      setError(false);
                      goToNextStep();
                    }, 3000);
                    throw err;
                  });
              };

              const handleSelectChange = (value, event) => {
                setFieldValue('country', value);
              };

              return (
                <>
                  <Stepper>
                    <StepperItem
                      active={activeStep === 1}
                      checked={activeStep > 1}
                    >
                      <StepperItemNumber
                        onClick={activeStep > 1 ? () => goToStep(1) : () => {}}
                        quantityItems='4'
                      >
                        {activeStep > 1 ? <img src={check} /> : '1'}
                      </StepperItemNumber>
                      <StepperColumn>
                        <StepperTitle>Dados de Identificação</StepperTitle>
                        <StepperContent>
                          <FormGroup>
                            <ControlLabel>
                              Esse continua sendo seu Nº de RG?
                            </ControlLabel>
                            <Input
                              className='input'
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              id='rg'
                              name='rg'
                              value={values.rg}
                              type='text'
                            />

                            {errors.rg && touched.rg && (
                              <span>{errors.rg}</span>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <FormGroup>
                              <ControlLabel>UF Emissor</ControlLabel>
                              <Input
                                className='input'
                                onChange={(value, event) => handleChange(event)}
                                onBlur={handleBlur}
                                value={values.uf}
                                id='uf'
                                name='uf'
                                type='text'
                              />

                              {errors.uf && touched.uf && (
                                <span>{errors.uf}</span>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <ControlLabel>Org. Expedidor</ControlLabel>
                              <Input
                                className='input'
                                onChange={(value, event) => handleChange(event)}
                                onBlur={handleBlur}
                                name='orgExp'
                                id='orgExp'
                                value={values.orgExp}
                                type='text'
                              />

                              {errors.orgExp && touched.orgExp && (
                                <span>{errors.orgExp}</span>
                              )}
                            </FormGroup>
                          </FormGroup>
                          <FormGroup>
                            <ControlLabel>Expedição do RG</ControlLabel>
                            <Input
                              className='input'
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='dateExp'
                              id='dateExp'
                              value={values.dateExp}
                              type='date'
                            />

                            {errors.dateExp && touched.dateExp && (
                              <span>{errors.dateExp}</span>
                            )}
                          </FormGroup>
                          <ConfirmButton
                            disabled={isLoading}
                            error={isError}
                            onClick={confirmPersonalData}
                          >
                            {!isError && isLoading
                              ? 'Enviando...'
                              : 'Confirmar dados'}
                            {isError &&
                              `Erro ao salvar dados. Você será redirecionado para o próximo passo.`}
                          </ConfirmButton>
                        </StepperContent>
                      </StepperColumn>
                    </StepperItem>
                    <StepperItem
                      active={activeStep === 2}
                      checked={activeStep > 2}
                    >
                      <StepperItemNumber
                        onClick={activeStep > 2 ? () => goToStep(2) : () => {}}
                        quantityItems='1'
                      >
                        {activeStep > 2 ? <img src={check} /> : '2'}
                      </StepperItemNumber>
                      <StepperColumn>
                        <StepperTitle>Dados de Profissão</StepperTitle>
                        <StepperContent>
                          <FormGroup>
                            <ControlLabel>
                              Você continua trabalhando nessa empresa?
                            </ControlLabel>
                            <Input
                              className='input'
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='companyWhereWorkName'
                              id='companyWhereWorkName'
                              value={values.companyWhereWorkName}
                              type='text'
                            />

                            {errors.companyWhereWorkName &&
                              touched.companyWhereWorkName && (
                                <span>{errors.companyWhereWorkName}</span>
                              )}
                          </FormGroup>
                          <ConfirmButton
                            disabled={isLoading}
                            error={isError}
                            onClick={confirmWorkData}
                          >
                            {isLoading
                              ? 'Enviando'
                              : isError
                              ? 'Erro ao salvar dados. Você será redirecionado para o próximo passo.'
                              : 'Confirmar dados'}
                          </ConfirmButton>
                        </StepperContent>
                      </StepperColumn>
                    </StepperItem>
                    <StepperItem
                      active={activeStep === 3}
                      checked={activeStep > 3}
                    >
                      <StepperItemNumber
                        onClick={activeStep > 3 ? () => goToStep(3) : () => {}}
                        quantityItems='3'
                      >
                        {activeStep > 3 ? <img src={check} /> : '3'}
                      </StepperItemNumber>
                      <StepperColumn>
                        <StepperTitle>Dados Gerais</StepperTitle>
                        <StepperContent>
                          <FormGroup>
                            <ControlLabel>
                              Você nasceu nessa cidade?
                            </ControlLabel>
                            <Input
                              className='input'
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='birthCity'
                              id='birthCity'
                              value={values.birthCity}
                              type='text'
                            />

                            {errors.birthCity && touched.birthCity && (
                              <span>{errors.birthCity}</span>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <ControlLabel>Em qual estado?</ControlLabel>
                            <Input
                              className='input'
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              value={values.state}
                              type='text'
                            />

                            {errors.state && touched.state && (
                              <span>{errors.state}</span>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <ControlLabel>Em qual país?</ControlLabel>
                            <br />
                            <SelectPicker
                              size='lg'
                              locale={{ searchPlaceholder: 'Pesquisar' }}
                              placeholder={
                                countries.length
                                  ? 'Escolha seu país...'
                                  : 'Carregando...'
                              }
                              onChange={(value, event) =>
                                handleSelectChange(value)
                              }
                              data={countries}
                              value={values.country}
                            />

                            {errors.country && touched.country && (
                              <span>{errors.country}</span>
                            )}
                          </FormGroup>
                          <ConfirmButton
                            disabled={isLoading}
                            error={isError}
                            onClick={confirmAddressData}
                          >
                            {!isError && isLoading
                              ? 'Enviando...'
                              : 'Confirmar dados'}
                            {isError &&
                              `Erro ao salvar dados. Você será redirecionado para o próximo passo.`}
                          </ConfirmButton>
                        </StepperContent>
                      </StepperColumn>
                    </StepperItem>
                  </Stepper>
                  <Button
                    disabled={activeStep < 4}
                    onClick={updateDataAndGoToPanel}
                    md
                  >
                    Entrar no painel
                  </Button>
                </>
              );
            }}
          </Formik>
        </Container>
      </Content>
    </Section>
  );
};

export default ConfirmData;
