import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Breadcrumb, Grid, Row, Col } from 'rsuite';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { ArrowLeft } from 'react-feather';
import { Link } from 'react-router-dom';
import LoggedLayout from '~/components/LoggedLayout';
import ContractImg from '../../assets/iconsContract/contract.svg';
import {
  BackButton,
  StyledNav,
  TabsBody,
  SelectDocument,
  CustomContent,
  ReactSVGCustomDiv,
  Title,
  SubTitle,
  Spacer,
  SelectPickerCustom,
} from './style';
import Loading from '../../components/Loading';
import ContractTable from '~/components/ContractTable';
import SignedContractTable from '~/components/SignedContractTable';

import { api } from '~/services/api';

import { MobileHeaderData } from '~/components/HeaderData';

const HandleTabs = ({
  tab,
  isLoading,
  setLoading,
  cpf,
  codClient,
  documentClient,
  contracts,
  signedContracts,
  setSignedContracts,
  setContractLoading,
  setContracts,
}) => {
  return (
    <div>
      {tab === 'contracts' && (
        <ContractTable
          isLoading={isLoading}
          dados={contracts}
          codClient={codClient}
          cpf={cpf}
          setContractLoading={setContractLoading.bind()}
          documentClient={documentClient}
          setContractsList={setContracts.bind()}
        />
      )}
      {tab === 'signed' && (
        <SignedContractTable
          isLoading={isLoading}
          setLoading={setLoading.bind()}
          cpf={cpf}
          codClient={codClient}
          documentClient={documentClient}
          setContractLoading={setContractLoading.bind()}
          setSignedContracts={setSignedContracts.bind()}
          data={signedContracts}
        />
      )}
    </div>
  );
};

const Contracts = () => {
  const cpf = useSelector((state) => state?.user?.profile?.document);

  const [contracts, setContracts] = useState([]);
  const [signedContracts, setSignedContracts] = useState([]);
  const [codClient, setCodClient] = useState([]);
  const [selectedTab, changeTab] = useState('contracts');
  const [isLoading, setLoading] = useState(false);
  const [loadingContract, setContractLoading] = useState(false);
  const [documentClient, setDocumentClient] = useState();

  async function getCodClients() {
    try {
      setLoading(true);
      const { data } = await api.get(
        `/contracts/authorized_clients?cpf=${cpf
          .replaceAll('.', '')
          .replace('-', '')}`
      );

      data.map(
        (item, idx) => idx === 0 && setDocumentClient(item?.code_client)
      );

      const codes = data.map((item) => ({
        label: item?.document_formated,
        value: item?.code_client,
        noDocumento: item?.document_not_formated,
      }));

      setCodClient(codes);

      getContracts(codes[0].value || []);
      getSignedContracts(codes[0].value || []);
      changeTab('contracts');
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }

  async function getContracts(client) {
    try {
      setLoading(true);
      const { data } = await api.get(
        `/contracts/to_sign?code_client=${client || documentClient}&cpf=${cpf
          .replaceAll('.', '')
          .replace('-', '')}`
      );

      setContracts(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  async function getSignedContracts(client) {
    try {
      setLoading(true);
      const { data } = await api.get(
        `/contracts/signeds?code_client=${client || documentClient}&cpf=${cpf
          .replaceAll('.', '')
          .replace('-', '')}`
      );

      setSignedContracts(data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCodClients();
  }, []);

  useEffect(() => {
    if (documentClient) {
      getContracts();
      getSignedContracts();
      changeTab('contracts');
    }
  }, [documentClient]);

  const onSelect = (key) => changeTab(key);

  const renderSelectLoadingMenu = (menu) => {
    if (codClient.length === 0) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <SpinnerIcon spin /> Carregando documentos...
        </p>
      );
    }
    return menu;
  };

  return (
    <>
      <LoggedLayout>
        <CustomContent>
          <Grid fluid>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col>
                <Breadcrumb style={{ paddingLeft: 0, width: '300px' }}>
                  <Link to='/home'>Home</Link>
                  <Link className='active'>Meus contratos</Link>
                </Breadcrumb>
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col>
                <BackButton className='back-button'>
                  <ArrowLeft />
                  <Link to='/update-data'>
                    <p>Voltar</p>
                  </Link>
                </BackButton>
              </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col>
                <ReactSVGCustomDiv src='/icons/contract-list-icon.svg' />
              </Col>
              <Col>
                <Title>Meus contratos</Title>
              </Col>
            </Row>
            <Row>
              <Col>
                <SubTitle>
                  Assine ou acompanhe seus contratos de câmbio
                </SubTitle>
              </Col>
            </Row>
            <Spacer />
            <Row>
              <Col>
                <StyledNav
                  appearance='subtle'
                  justified
                  activeKey={selectedTab}
                  onSelect={onSelect}
                >
                  <StyledNav.Item eventKey='contracts'>
                    Contratos à assinar ({contracts.length})
                  </StyledNav.Item>
                  <StyledNav.Item
                    eventKey='signed'
                    style={{ marginLeft: '15px' }}
                  >
                    Operações realizadas ({signedContracts.length})
                  </StyledNav.Item>

                  <StyledNav.Item>
                    <SelectDocument>
                      <span style={{ marginTop: 10 }}>Encontrar: </span>
                      <SelectPickerCustom
                        placeholder='Escolha o documento'
                        size='lg'
                        locale={{ searchPlaceholder: 'Pesquisar' }}
                        value={documentClient}
                        onChange={(value, _event) => setDocumentClient(value)}
                        data={codClient}
                        renderMenu={renderSelectLoadingMenu}
                      />
                    </SelectDocument>
                  </StyledNav.Item>
                </StyledNav>
              </Col>
            </Row>
            <Spacer />
            <Row>
              <Col>
                <TabsBody>
                  <HandleTabs
                    tab={selectedTab}
                    isLoading={isLoading}
                    setLoading={setLoading.bind()}
                    cpf={cpf}
                    codClient={documentClient}
                    documentClient={documentClient}
                    contracts={contracts}
                    signedContracts={signedContracts}
                    setContractLoading={setContractLoading.bind()}
                    setContracts={setContracts.bind()}
                    setSignedContracts={setSignedContracts.bind()}
                  />
                </TabsBody>
              </Col>
            </Row>
          </Grid>
        </CustomContent>
        {loadingContract && <Loading text='Aguarde...' />}
      </LoggedLayout>
    </>
  );
};

export default Contracts;
