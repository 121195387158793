import PropType from 'prop-types';
import React from 'react';
import WizardHeader from '../../../../components/WizardHeader';
import WizardStep from './WizardStep';

export default class PartnersWizard extends React.Component {
  render() {
    return (
      <>
        <WizardHeader showLogo={false}>
          <WizardStep label='Info. pessoais' />
          {this.props.isPF === false ? (
            <>
              <WizardStep label='Endereço' />
              <WizardStep label='Relação com a empresa' active stepLabel='3' />
            </>
          ) : (
            <>
              <WizardStep label='Documentação' />
              <WizardStep label='Endereço' />
              <WizardStep label='Relação com a empresa' active stepLabel='4' />
            </>
          )}
        </WizardHeader>
      </>
    );
  }
}

PartnersWizard.propTypes = {
  isPF: PropType.bool.isRequired,
};
