import styled from 'styled-components';

export const StyledBox = styled.div`
  background: #ffffff;
  border: 1px solid #eff2f7;
  box-shadow: 0px 38px 56px rgba(154, 154, 167, 0.07),
    0px 11.4559px 16.8824px rgba(154, 154, 167, 0.0456112),
    0px 4.75819px 7.01207px rgba(154, 154, 167, 0.035),
    0px 1.72094px 2.53613px rgba(154, 154, 167, 0.0243888);
  border-radius: 10px;
  padding: 24px 32px;
`;
