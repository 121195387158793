import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #eff2f7;
  display: flex;
  height: 64px;
  align-items: center;
  font-family: 'IBM Plex Sans';
  border-radius: 10px 10px 0 0;

  padding: 20px 32px;

  p {
    display: flex;
    align-items: center;

    color: #20315b;
    font-size: 16px;
    font-weight: 500;

    @media (max-width: 43rem) {
      font-size: 14px;
      margin-bottom: 12px;
    }
  }

  strong {
    color: #20315b;
    font-size: 25px;

    @media (max-width: 43rem) {
      font-size: 16px;
    }
  }

  @media (max-width: 43rem) {
    flex-direction: column;

    margin: 20px 24px;
    border-radius: 10px;
    padding: 20px 24px;

    justify-content: center;

    hr {
      width: 100%;
      margin: 0;
    }
  }
`;
