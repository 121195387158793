import { format, parseISO } from 'date-fns';
import React from 'react';
import { XCircle } from 'react-feather';
// import SignatureLogo from '../../../assets/iconsContract/signature.svg';
import {
  Container,
  ContentModal,
  ContractContent,
  ContractDesc,
  ContractTitle,
  HeaderModal,
  Title,
} from './styles';

const ModalSignature = ({ dados, cancelHandler }) => {
  return (
    <ContentModal>
      <HeaderModal>
        <Title>
          Confirmar a assinatura{' '}
          {dados.length > 1 ? 'dos contratos' : 'do contrato'}
        </Title>
        <XCircle
          color='#0FC7FF'
          size={33}
          style={{ cursor: 'pointer' }}
          onClick={cancelHandler}
        />
      </HeaderModal>
      <Container>
        {dados.map((data, idx) => (
          <React.Fragment key={idx}>
            <ContractContent>
              <ContractTitle index={idx + 1}>
                Contrato {`#${data?.contract_id}`}
              </ContractTitle>
              <ContractDesc>
                Tipo de operação: {data?.type_contract_name} <br />
                Valor do contrato: {data?.currency}{' '}
                {data?.value_foreign_currency} <br />
                Data do contrato:{' '}
                {format(
                  new Date(parseISO(data?.contract_date)),
                  'dd/MM/yyyy'
                )}{' '}
                <br />
              </ContractDesc>
              <div
                style={{
                  border: '1px solid #E5E9F2',
                  marginTop: 14,
                }}
              />
            </ContractContent>
          </React.Fragment>
        ))}
      </Container>
    </ContentModal>
  );
};

export default ModalSignature;
