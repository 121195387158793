import React from 'react';
import { Bar } from './styles';

const InfoBar = (props) => {
  return (
    <Bar>
      <span>{props.title}</span>
    </Bar>
  );
};

export default InfoBar;
