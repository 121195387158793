import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { setHistoryChat, setChat } from './actions';

import { api } from '~/services/api';

export function* chatService(data) {
  try {
    const getToken = (state) => state.auth;
    const token = yield select(getToken);

    if (token && data.payload) {
      api.defaults.headers.Authorization = `Bearer ${token.token}`;
      const response = yield call(
        api.get,
        `/huggy/chats?page=0&huggyContactId=${data.payload}`
      );
      yield put(setHistoryChat(response.data));
    }
  } catch (err) {
    toast.error('Houve um problema para carregar listagem de chats.');
  }
}

export function* getIndividualChat({ payload }) {
  try {
    const getToken = (state) => state.auth;
    const token = yield select(getToken);

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token.token}`;
      const response = yield call(
        api.get,
        `/huggy/chats/messages?page=0&chat_id=${payload}`
      );
      yield put(setChat(response.data));
    }
  } catch (err) {
    toast.error('Houve um problema para trazer o chat.');
  }
}

export default all([
  takeLatest('@chat/GET_HISTORY_CHAT', chatService),
  takeLatest('@chat/GET_CHAT', getIndividualChat),
]);
