import React, { useEffect, useState } from 'react';
import { FiAlertTriangle, FiCheckSquare } from 'react-icons/fi';
import { ArrowLeft, PlusCircle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb, Col, FormGroup, Modal, Row, Progress } from 'rsuite';
import GroupIcon from '~/assets/group.svg';
import PartnersIcon from '~/assets/partners.svg';
import WarningIcon from '~/assets/warning.svg';
import { MobileHeaderData } from '~/components/HeaderData';
import LoggedLayout, { LayoutContent } from '~/components/LoggedLayout';
import {
  Sidebar,
  SidebarContent,
  SidebarSubtitle,
  SidebarTitle,
} from '~/components/Sidebar';
import { Container } from '~/components/styles/form';
import { CheckItem, StyledButton } from '~/pages/MyData/Partners/Steps/styles';
import { retrievePartners } from '~/services/partner';
import { getPartners } from '~/store/modules/partnersList/actions';
import { updateUser } from '~/store/modules/user/actions';
import PartnerCard from './PartnerCard';
import {
  BackButton,
  DisabledPartnerText,
  DisplayCards,
  GridCards,
  NewPartnerCard,
  NewPartnerCardDisabled,
  PartnersModal,
  PartnersToast,
  SidebarIcon,
  InfoStatusComplete,
  InfoStatusIncomplete,
} from './styles';

const Partners = (props) => {
  const { state } = props.location;
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const partnersList = useSelector(
    (state) => state?.partnersList?.partners ?? []
  );
  const partnerData = useSelector(
    (state) => state.user?.profile?.partnerData ?? {}
  );
  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(!partnerData?.processed);
  const [percentage, setPercentage] = useState(0);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getPartners(user.profile._id, auth.token));
  }, []);

  useEffect(() => {
    window.amplitude.getInstance().logEvent('access Partners page');
  }, []);

  useEffect(() => {
    let percentageAccumulate = 0;

    partnersList.map((item) => {
      if (item.quota) {
        percentageAccumulate += item.quota;
      }
    });

    setPercentage(percentageAccumulate);
  }, [partnersList]);

  const renderCountPartners = () => {
    if (partnerData?.data?.length > 0) {
      return `os (${partnerData?.data?.length}) sócios`;
    }
    return `(${partnerData?.data?.length}) sócio`;
  };

  const partnerToConfirm = partnerData?.data;

  const handleSetPartnerToConfirm = (data) => {
    partnerToConfirm.forEach((partner) => {
      if (partner.protocol === data.protocol) {
        partner.useData = !partner.useData;
      }
    });
  };

  const confirmPartners = async () => {
    const body = {
      retriveUsers: partnerToConfirm,
      companyName:
        user.profile.companyName ??
        `${user.profile.name} ${user.profile.lastName}`,
    };

    await retrievePartners(user.profile._id, body);

    setShowToast(false);
    setShow(false);

    dispatch(getPartners(user.profile._id));
    dispatch(
      updateUser({
        user: {
          ...user.profile,
        },
      })
    );
  };

  console.log(partnersList.some((item) => item.isPartner && !item.dynamicsId));

  return (
    <LoggedLayout>
      <Container className='responsive-container'>
        <Sidebar>
          <SidebarContent>
            <SidebarIcon />
            <SidebarTitle>Sócios e Procuradores</SidebarTitle>
            <SidebarSubtitle>
              Quantidade e dados dos sócios com % de participação
            </SidebarSubtitle>

            {percentage === 100 &&
            !partnersList.some((item) => item.isPartner && !item.dynamicsId) ? (
              <InfoStatusComplete>Concluído</InfoStatusComplete>
            ) : (
              <InfoStatusIncomplete>Incompleto</InfoStatusIncomplete>
            )}
          </SidebarContent>
        </Sidebar>
        <MobileHeaderData
          title='Sócios'
          percentage={Math.round(percentage)}
          icon={PartnersIcon}
        />
        <LayoutContent>
          <DisplayCards>
            <div>
              <Breadcrumb className='breadcrumb' style={{ paddingLeft: 0 }}>
                <Link className='step' to='/home'>
                  Home
                </Link>
                <Link className='step' to='/update-data'>
                  Meus dados
                </Link>
                <Link className='active' to='/login'>
                  Sócios
                </Link>
              </Breadcrumb>

              <BackButton className='back-button'>
                <ArrowLeft />
                <Link to='/update-data'>
                  <p>Voltar</p>
                </Link>
              </BackButton>
            </div>

            <GridCards>
              <Row>
                {percentage >= 100 ? (
                  <DisabledPartnerText>
                    <FiCheckSquare size={24} color='#14cc70' />
                    <h4 style={{ marginLeft: '8px' }}>
                      Os sócios adicionados alcançaram os 100% de participação
                    </h4>
                  </DisabledPartnerText>
                ) : (
                  <DisabledPartnerText>
                    <FiAlertTriangle size={24} color='red' />
                    <h4 style={{ marginLeft: '8px' }}>
                      Os sócios adicionados ainda não alcançaram os 100% de
                      participação. O percentual cadastrado foi de {percentage}%
                    </h4>
                  </DisabledPartnerText>
                )}

                <Col xs={24} md={12} lg={12}>
                  {percentage >= 100 ? (
                    <NewPartnerCardDisabled>
                      <PlusCircle color='#9BA8B7' size={33} />
                      <h1>Adicionar novo sócio</h1>
                    </NewPartnerCardDisabled>
                  ) : (
                    <NewPartnerCard
                      onClick={() =>
                        history.push('/update-data/partners/new/1', {
                          isAttorney: false,
                        })
                      }
                    >
                      <PlusCircle color='#0FC7FF' size={33} />
                      <h1>Adicionar novo sócio</h1>
                    </NewPartnerCard>
                  )}
                  {partnersList.length > 0 &&
                    partnersList
                      .filter((partner) => partner.isAttorney === false)
                      .map((item, idx) => {
                        if (item.isIdWall || item.quota > 0) {
                          if (
                            state &&
                            state.partnerDocument === item.document
                          ) {
                            return (
                              <div
                                style={{ marginTop: 20, marginBotton: 20 }}
                                key={item._id}
                              >
                                <PartnerCard
                                  data={item}
                                  idx={idx}
                                  partners={partnersList}
                                  userId={user.profile._id}
                                  registerStatus={state.registerStatus}
                                  isAttorney={false}
                                />
                              </div>
                            );
                          }
                        }
                        return (
                          <div
                            style={{ marginTop: 20, marginBotton: 20 }}
                            key={item._id}
                          >
                            <PartnerCard
                              data={item}
                              idx={idx}
                              partners={partnersList}
                              userId={user.profile._id}
                              isAttorney={false}
                            />
                          </div>
                        );
                      })}
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <NewPartnerCard
                    onClick={() =>
                      history.push('/update-data/partners/new/1', {
                        isAttorney: true,
                      })
                    }
                  >
                    <PlusCircle color='#0FC7FF' size={33} />
                    <h1>Adicionar Procurador</h1>
                  </NewPartnerCard>
                  {partnersList.length > 0 &&
                    partnersList
                      .filter((partner) => partner.isAttorney === true)
                      .map((item, idx) => (
                        <div style={{ marginTop: 20, marginBotton: 20 }}>
                          <PartnerCard
                            data={item}
                            idx={idx}
                            partners={partnersList}
                            userId={user.profile._id}
                            isAttorney
                          />
                        </div>
                      ))}
                </Col>
              </Row>
            </GridCards>
          </DisplayCards>
        </LayoutContent>
        {partnerData?.data?.length > 0 && (
          <PartnersToast
            show={showToast}
            onClick={() => {
              setShow(true);
              setShowToast(false);
            }}
          >
            <img src={WarningIcon} alt='Ícone' />
            <h4>
              Confirme <b>{renderCountPartners()}</b> que encontramos
            </h4>
          </PartnersToast>
        )}
        <Modal
          show={show}
          onHide={() => setShow(false)}
          size='sm'
          style={{ padding: '16px 0 0 0' }}
        >
          <Modal.Body>
            <PartnersModal>
              <img src={GroupIcon} alt='Ícone' />
              <h1>
                Encontramos <b>{renderCountPartners()}</b> vinculado(s) a
                empresa
              </h1>
              <p>
                Selecione quem são as pessoas que ainda fazem parte do quadro
                societário. Você vai precisar completar os dados de cada um em
                seguida
              </p>
              <Row>
                <Col xs={24}>
                  <FormGroup>
                    {partnerData?.data?.map((partner) => (
                      <CheckItem
                        onChange={() => handleSetPartnerToConfirm(partner)}
                      >
                        {partner.name}
                      </CheckItem>
                    ))}
                  </FormGroup>
                </Col>
              </Row>
              <StyledButton onClick={() => confirmPartners()}>
                Confirmar sócios
              </StyledButton>
            </PartnersModal>
          </Modal.Body>
        </Modal>
      </Container>
    </LoggedLayout>
  );
};

export default Partners;
