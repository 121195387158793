import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Breadcrumb,
  Col,
  ControlLabel,
  Form,
  FormGroup,
  Modal,
  Row,
} from 'rsuite';
import * as Yup from 'yup';
import { MobileHeaderData } from '~/components/HeaderData';
import LoggedLayout, { LayoutContent } from '~/components/LoggedLayout';
import { Sidebar, SidebarContent, SidebarTitle } from '~/components/Sidebar';
import telMask from '~/mask/telphone';
import { api } from '~/services/api';
import { loadingCompleted, setLoading } from '~/store/modules/auth/actions';
import { updateUser } from '~/store/modules/user/actions';
import {
  AllForm,
  Avatar,
  AvatarColumn,
  AvatarInitials,
  BackButton,
  CustomModal,
  DesktopDelete,
  DisplayGrid,
  FlexPhone,
  FormGrid,
  GeralColumn,
  MobileDelete,
  PasswordColumn,
  StyledButton,
  StyledInput,
  StyledTitle,
} from './styles';
import { StyledSelect } from '../../components/styles/form.js';
import { CountryDdi } from '~/mocks/ddi';
import history from '../../services/history';

const MyAccount = () => {
  const { user, auth } = useSelector((state) => state);
  const {
    name,
    lastName,
    email,
    phoneAreaCode,
    phoneNumber,
    phoneCountryCode,
  } = user.profile;

  const [openModal, setOpenModal] = useState(false);

  const { loading } = auth;

  const FieldsSchema = Yup.object().shape({
    name: Yup.string().required('Obrigatório'),
    lastName: Yup.string().required('Obrigatório'),
    email: Yup.string().email('Digite um email válido').required('Obrigatório'),
    ddi: Yup.string().required('Obrigatório').nullable(),
    ddd: Yup.string().required('Obrigatório'),
    phone: Yup.string().required('Obrigatório'),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    window.amplitude.getInstance().logEvent('access My Account page');
  }, [user]);

  return (
    <LoggedLayout>
      <Sidebar>
        <SidebarContent>
          <SidebarTitle account>Minha conta</SidebarTitle>
        </SidebarContent>
      </Sidebar>

      <MobileHeaderData
        title='Minha conta'
        usePercentage={false}
        icon='/icons/pf.svg'
      />

      <LayoutContent>
        <Formik
          initialValues={{
            name: name || user.profile.name || '',
            lastName: lastName || user.profile.lastName || '',
            email: email || user.profile.email || '',
            phone: phoneNumber || user.profile.phone || '',
            ddi: phoneCountryCode || '',
            ddd: phoneAreaCode || '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={FieldsSchema}
          onSubmit={async (values, action) => {
            const { name, lastName, email, phone, ddd, password, ddi } = values;

            const id = user.profile._id;
            const { token } = auth;

            try {
              await dispatch(setLoading());
              action.setSubmitting(true);
              let data = {
                name,
                lastName,
                email,
                phoneCountryCode: ddi,
                phoneAreaCode: ddd,
                phoneNumber: phone,
                password,
              };

              if (user.relationship) data = { ...data, password };

              api
                .put(`/register/users/person-data`, data)
                .then((item) => {
                  dispatch(
                    updateUser({
                      ...user,
                      user: item.data,
                    })
                  );
                  dispatch(loadingCompleted());
                  toast.success('Alterações realizadas com sucesso.');
                  window.amplitude
                    .getInstance()
                    .logEvent('Updated account data', item);
                  history.replace('/login');
                })
                .catch((item) => {
                  // setShowModal(true);
                  // setErrorData({
                  //   title: 'Erro ao processar dados',
                  //   description: 'Tivemos um problema no envio das informações, revise o preenchimento dos campos obrigatórios ou tente novamente.',
                  // });
                  // toast.error('Houve um problema ao enviar as informações.');
                  dispatch(loadingCompleted());
                  action.setSubmitting(false);
                  window.amplitude
                    .getInstance()
                    .logEvent('Error in update client', item);
                });
              // let response = await api.put(`/users/${id}/person-data`, data);

              // console.log('DADOS: ', response);

              // const dados = response.data;

              // await dispatch(
              //   updateUser({
              //     user: {
              //       ...user.profile,
              //       dados
              //       // ...user.profile,
              //     },
              //   })
              // );

              // await dispatch(loadingCompleted());

              // toast.success('Alterações realizadas com sucesso.');
              // window.amplitude
              //   .getInstance()
              //   .logEvent('Updated account data', data);
            } catch (err) {
              toast.error('Houve um problema ao enviar as informações.');
              dispatch(loadingCompleted());
              action.setSubmitting(false);
              window.amplitude
                .getInstance()
                .logEvent('Error in update account data', err);
            }
          }}
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            const initialsAvatarLogic = () => {
              if (!values.name && !values.lastName) return;
              if (values.name && !values.lastName)
                return `${values.name[0]}${values.name[1]}`;
              if (!values.name && values.lastName)
                return `${values.lastName[0]}${values.lastName[1]}`;
              if (values.name && values.lastName)
                return `${values.name[0]}${values.lastName[0]}`;
            };

            const handleSelectStateChange = (value) => {
              setFieldValue('ddi', value);
            };

            return (
              <Form onSubmit={handleSubmit}>
                <FormGrid>
                  <Breadcrumb className='breadcrumb'>
                    <Link to='/home' id='HomeBread'>
                      Home
                    </Link>
                    <Link className='active'>Minha conta</Link>
                  </Breadcrumb>

                  <BackButton className='back-button'>
                    <ArrowLeft />
                    <Link to='/' id='BackButton'>
                      <p>Voltar</p>
                    </Link>
                  </BackButton>

                  <AllForm>
                    <AvatarColumn>
                      <Avatar>
                        <AvatarInitials>{initialsAvatarLogic()}</AvatarInitials>
                      </Avatar>
                    </AvatarColumn>

                    <GeralColumn>
                      <StyledTitle>Geral</StyledTitle>
                      <Row>
                        <Col xs={20}>
                          <FormGroup>
                            <ControlLabel>Nome</ControlLabel>
                            <StyledInput
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='name'
                              id='name'
                              value={values.name}
                              type='text'
                            />
                            {errors.name && touched.name && (
                              <span className='input-error'>{errors.name}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={20}>
                          <FormGroup>
                            <ControlLabel>Sobrenome</ControlLabel>
                            <StyledInput
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='lastName'
                              id='lastName'
                              value={values.lastName}
                              type='text'
                            />

                            {errors.lastName && touched.lastName && (
                              <span className='input-error'>
                                {errors.lastName}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={20}>
                          <FormGroup>
                            <ControlLabel>Email</ControlLabel>
                            <StyledInput
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='email'
                              id='email'
                              value={values.email}
                              type='email'
                            />

                            {errors.email && touched.email && (
                              <span className='input-error'>
                                {errors.email}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <ControlLabel>DDI</ControlLabel>
                      <FlexPhone>
                        <FormGroup>
                          <StyledSelect
                            size='lg'
                            locale={{ searchPlaceholder: 'Pesquisar' }}
                            placeholder='Escolha o DDI'
                            onChange={(value) => handleSelectStateChange(value)}
                            onBlur={handleBlur}
                            data={CountryDdi}
                            value={values.ddi}
                            name='ddi'
                            id='ddi'
                            style={{ width: 320, marginTop: '5px' }}
                            maxHeight={150}
                          />

                          {errors.ddi && touched.ddi && (
                            <span>{errors.ddi}</span>
                          )}
                        </FormGroup>
                      </FlexPhone>

                      <ControlLabel>Número de celular</ControlLabel>
                      <FlexPhone>
                        <FormGroup>
                          <StyledInput
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='ddd'
                            maxlength={values.ddi === '55' ? 2 : ''}
                            id='ddd'
                            value={values.ddd}
                            type='text'
                            style={{ width: 50 }}
                          />

                          {errors.ddd && touched.ddd && (
                            <span>{errors.ddd}</span>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <StyledInput
                            onChange={(value, event) => handleChange(event)}
                            onBlur={handleBlur}
                            name='phone'
                            id='phone'
                            value={
                              values.ddi === '55'
                                ? telMask(values.phone)
                                : values.phone
                            }
                            maxlength={values.ddi === '55' ? 10 : ''}
                            type='text'
                            style={{ width: 260, marginLeft: 10 }}
                          />

                          {errors.phone && touched.phone && (
                            <span>{errors.phone}</span>
                          )}
                        </FormGroup>
                      </FlexPhone>
                      <DesktopDelete onClick={() => setOpenModal(true)}>
                        Excluir minha conta
                      </DesktopDelete>
                    </GeralColumn>

                    <PasswordColumn>
                      <StyledTitle>Senha</StyledTitle>
                      <span className='passwordText'>
                        Esta é a senha utilizada para fazer o login no seu
                        painel Advanced. Evite compartilhar sua senha com outras
                        pessoas.
                      </span>

                      <Row>
                        <Col xs={20}>
                          <FormGroup>
                            <ControlLabel>Nova senha</ControlLabel>
                            <StyledInput
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='password'
                              id='password'
                              value={values.password}
                              type='password'
                            />

                            {errors.password && touched.password && (
                              <span className='input-error'>
                                {errors.password}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={20}>
                          <FormGroup>
                            <ControlLabel>Repetir senha </ControlLabel>
                            <StyledInput
                              onChange={(value, event) => handleChange(event)}
                              onBlur={handleBlur}
                              name='confirmPassword'
                              id='confirmPassword'
                              value={values.confirmPassword}
                              type='password'
                            />

                            {errors.confirmPassword &&
                              touched.confirmPassword && (
                                <span className='input-error'>
                                  {errors.confirmPassword}
                                </span>
                              )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </PasswordColumn>
                  </AllForm>
                  <MobileDelete onClick={() => setOpenModal(true)}>
                    Excluir minha conta
                  </MobileDelete>
                  <hr />
                  <DisplayGrid>
                    <StyledButton id='Save' type='submit' disabled={loading}>
                      {loading ? 'Carregando...' : 'Salvar'}
                    </StyledButton>
                  </DisplayGrid>
                </FormGrid>
              </Form>
            );
          }}
        </Formik>
        <CustomModal
          show={openModal}
          onClose={() => setOpenModal(!openModal)}
          onHide={() => setOpenModal(!openModal)}
          backdrop='static'
        >
          <Modal.Header>
            <Modal.Title>Formulário de Solicitação</Modal.Title>
          </Modal.Header>
          <iframe src='https://advancedcorretora-privacy.my.onetrust.com/webform/cdf432c4-64bd-4700-8128-c1b5a60201f1/fd9b07ec-de1b-446c-b4ea-2ab151584994' />
        </CustomModal>
      </LayoutContent>
    </LoggedLayout>
  );
};

export default MyAccount;
