import React from 'react';
import { Edit3 } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { setCheckoutStep } from '~/store/modules/exchange/actions';
import { transformNumberInMoney } from '~/utils/transformNumberInMoney';
import { calcutateCheckoutValues } from '../../utils/calculateCheckoutValues';
import {
  Category,
  Content,
  EditButton,
  Header,
  InternalContainer,
  List,
  ListItem,
  Order,
  StyledBox,
  TotalOperation,
} from './styles';

const ResumeOrder = ({}) => {
  const dispatch = useDispatch();
  const { selectedProduct, checkoutStep, defaultIof } = useSelector(
    (state) => state.exchange
  );
  const PRODUCT_TYPE = {
    ESPECIE: 'Dinheiro em Espécie',
    CARTAO: 'Cartão para Viagem',
  };

  const { deliveryValue, taxValue, iofPercent, iofValue, totalValue } =
    calcutateCheckoutValues({ selectedProduct, defaultIof });

  return (
    <StyledBox>
      <InternalContainer>
        <Header>
          <Category id='resumeOrder'>Resumo da solicitação</Category>
          {checkoutStep > 1 ? (
            <EditButton onClick={() => dispatch(setCheckoutStep(1))}>
              <Edit3 size={24} /> Refazer
            </EditButton>
          ) : (
            ''
          )}
        </Header>
        <Order>
          <List>
            <ListItem big>
              <span>Operação</span>
              <strong>
                {selectedProduct?.productType
                  ? PRODUCT_TYPE[selectedProduct?.productType]
                  : ''}
              </strong>
            </ListItem>
            <ListItem big>
              <span>Moeda + Valor</span>
              <strong>
                {transformNumberInMoney({
                  number: selectedProduct?.currencyToValue,
                  currency: selectedProduct?.currencyTo,
                })}
              </strong>
            </ListItem>
          </List>
        </Order>
        <Content>
          <List>
            <ListItem>
              <span>Valor da taxa</span>
              <strong>
                {transformNumberInMoney({
                  number: selectedProduct.currencyToTax,
                  currency: 'BRL',
                })}
              </strong>
            </ListItem>
            <ListItem>
              <span>Valor do montante</span>
              <strong>
                {transformNumberInMoney({ number: taxValue, currency: 'BRL' })}
              </strong>
            </ListItem>
            <ListItem>
              <span>IOF ({iofPercent.toFixed(2)}%)</span>
              <strong>
                {transformNumberInMoney({ number: iofValue, currency: 'BRL' })}
              </strong>
            </ListItem>
            {deliveryValue > 0 && (
              <ListItem>
                <span>Valor da entrega</span>
                <strong>
                  {transformNumberInMoney({
                    number: deliveryValue,
                    currency: 'BRL',
                  })}
                </strong>
              </ListItem>
            )}
            <ListItem>
              <span>VET</span>
              <strong>
                {transformNumberInMoney({
                  number: totalValue,
                  currency: 'BRL',
                })}
              </strong>
            </ListItem>
            <ListItem>
              <span>Valor final</span>
              <strong>
                {transformNumberInMoney({
                  number: totalValue,
                  currency: 'BRL',
                })}
              </strong>
            </ListItem>
          </List>
        </Content>
        <TotalOperation>
          <List>
            <ListItem big>
              <span>Total à pagar</span>
              <strong>
                {transformNumberInMoney({
                  number: totalValue,
                  currency: 'BRL',
                })}
              </strong>
            </ListItem>
          </List>
        </TotalOperation>
      </InternalContainer>
    </StyledBox>
  );
};

export default ResumeOrder;
