import React from 'react';
import styled from 'styled-components';
import PartnersIcon from '~/assets/partners.svg';

export const SidebarLinks = styled.div`
  margin-top: 77px;
`;

export const SidebarLinksTitle = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #eff2f7;
`;

export const DisplayCards = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    padding: 50px 0 0 50px;
  }
`;

export const PartnerCard = styled.div`
  background: #fff;
  width: 300px;
  height: 361px;

  @media (max-width: 700px) {
    width: 95%;
    max-height: 170px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1.5px dashed #0fc7ff;
    border-radius: 4px;
  }

  @media (min-width: 768px) {
    margin: 30px 0 0 30px;
  }
`;

export const NewPartnerCardDisabled = styled.div`
  width: 100%;
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eff2f7;
  border: 1.5px dashed #9ba8b7;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 10px;

  @media (min-width: 801px) {
    min-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
  }

  h1 {
    color: #9ba8b7;
    font-weight: 500;
    font-size: 18px;
    margin-left: 10px;
  }
`;

export const DisabledPartnerText = styled.div`
  background-color: #eff2f7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  h4 {
    color: #455566;
    font-size: 14px;
  }

  @media (max-width: 467px) {
    display: flex;
    flex-direction: column;
  }
`;

export const NewPartnerCard = styled.div`
  width: 100%;
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1.5px dashed #0fc7ff;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 10px;

  @media (min-width: 801px) {
    min-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
  }

  h1 {
    color: #09b2e6;
    font-weight: 500;
    font-size: 18px;
    margin-left: 10px;
  }
`;

export const GridCards = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 10px;
  padding: 8px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    margin-top: 0;
    padding: 10px;
  }
`;

export const PartnersIconImg = () => (
  <img src={PartnersIcon} alt='Ícone parceiro' />
);

export const All = styled.div`
  display: grid;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const Title = styled.div`
  margin-bottom: 20px;
  color: #20315b;
  font-family: IBM Plex Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
`;

export const SidebarIcon = styled(PartnersIconImg)`
  margin-bottom: 30px;
`;

export const SidebarContent = styled.div`
  padding-top: 44px;
  padding-left: 44px;
  padding-right: 44px;
`;

export const Content = styled.div`
  @media (min-width: 768px) {
    margin-top: 60px;
    padding-left: 97px;

    max-height: calc(100vh - 150px);
  }

  img {
    width: 250px;
    margin-left: -20px;
  }

  .input {
    margin: 5px 0 15px 0;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #d3dce6;
    padding-left: 10px;
    width: 100%;

    @media (min-width: 768px) {
      width: 384px;
    }
  }

  .rs-form-group {
    display: flex;
    flex-direction: column;

    width: 100%;
  }
`;

export const Fields = styled.div`
  font-family: IBM Plex Sans;
  font-size: 14px;
  line-height: 20px;
  color: #455566;
`;

export const InternalTwoColumns = styled.div`
  display: grid;
  margin-right: 30px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 0px;

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }

  @media (min-width: 768px) {
    input {
      max-width: 200px;
    }
  }

  .rs-form-group:not(:last-child) {
    margin-bottom: initial !important;
  }
`;

export const SelectProfile = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 14px;

  p {
    font-size: 14px;
    margin-top: 8px;
  }
`;

export const FileUploadBox = styled.div`
  max-width: 400px;
  height: 225px;
  background: #ffffff;
  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  div {
    margin: 33px;
    border: 1.5px dashed #0fc7ff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h1 {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      width: 205px;
      color: #09b2e6;
      text-align: center;
      margin-top: 15px;
    }
  }
  input {
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    position: absolute;
  }

  ${(props) =>
    props.small &&
    `
    height: 178px;

    div { 
      margin: 0;
      padding: 22px;
    }

  `}
`;

export const BackButton = styled.div`
  display: flex;
  color: #0fc7ff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  p {
    margin-left: 3px;
  }

  a {
    text-decoration: none;
    color: #0fc7ff;
  }
`;

export const SelectTypeProfile = styled.div`
  width: 60px;
  height: 48px;

  font-family: 'IBM Plex Sans';
  display: inline-block;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  border-radius: 5px;

  line-height: 2.8;

  border: 1px solid #455566;
  color: #455566;

  cursor: pointer;
  opacity: 0.5;

  transition: 0.5s all;

  ${({ active }) =>
    active
      ? `
    opacity: 1;
    background: #0FC7FF;
    border-color: #0FC7FF;
    color: #F9FAFC;
  `
      : `
    &:hover {
      background: #0FC7FF;
      border-color: #0FC7FF;
      color: #F9FAFC;
    }
  `}

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const SelectProfileHolder = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const IdentityButtonHolder = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const DataFormGroup = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const InfoBar = styled.div`
  text-align: center;
  background: #eff2f7;
  padding: 16px;
  width: 100%;
  span {
    font-weight: 500;
  }
`;

export const PartnersModal = styled.div`
  padding: 0 64px;
  img {
    margin-bottom: 20px;
  }
  h1 {
    line-height: 40px;
    color: #20315b;
    font-size: 32px;
  }
  p {
    margin: 20px 0;
  }
`;

export const PartnersToast = styled.button`
  position: absolute;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  top: 0;
  right: 0;
  margin: 50px;
  padding: 5px 10px;
  background-color: #ffffff;
  border: 1px #eff2f7 solid;
  border-radius: 10px;
  img {
    margin-top: 5px;
    margin-right: 10px;
  }
  h4 {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const InfoStatusComplete = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  display: flex;
  align-items: center;
  text-align: right;

  margin-top: 16px;

  color: #14cc70;
`;

export const InfoStatusIncomplete = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  display: flex;
  align-items: center;
  text-align: right;

  margin-top: 16px;

  color: #ef3131;
`;
