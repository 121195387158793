import styled from 'styled-components';
import { Table as RTable } from 'rsuite';

export const Table = styled(RTable)`
  .spanColor {
    color: #20315b;
  }

  .rs-table-cell {
    border-color: #ff0000 !important;
  }

  .rs-table-cell-content {
    color: #20315b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .rs-table-cell-header {
    .rs-table-cell-content {
      font-family: IBM Plex Sans;
      font-weight: normal;
      padding-top: 32px;
      font-size: 14px;
      line-height: 16px;
      color: #455566;
    }
  }

  .rs-table-row {
    border-bottom-color: #d3dce6;
  }
`;

export const HeaderCell = styled(RTable.HeaderCell)`
  color: #455566;
`;

export const ContractTitle = styled.span`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #09b2e6;

  margin-right: 12px;
  cursor: pointer;
`;

export const ContractWrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    div {
      display: flex;
    }
  }
`;

export const CurrencyWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const CurrencyValue = styled.span`
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #132552;

  margin-left: 20px;
`;

export const FlagWrapper = styled.div`
  display: flex;

  img:last-child {
    position: absolute;
    z-index: 10;
    left: 12px;
    border: 2px solid #fff;
    background-color: #fff;
    border-radius: 50%;
  }
`;

export const Status = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: 24px;

  padding: 4.5px 16px;
  background: #fffae2;
  border-radius: 5px;

  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #ffb82e;
`;
