const Theme = {
  breakpoints: {
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1920px',
  },
  fontFamily: 'IBM Plex Sans',
  palette: {
    brand: {
      blue: {
        light: '#2E4378',
        regular: '#20315B',
        dark: '#132552',
      },
      lightblue: {
        light: '#0FC7FF',
        regular: '#09B2E6',
        dark: '#009FD9',
      },
    },
    greyscale: {
      light: {
        lighter: '#C5CED9',
        regular: '#AFBAC7',
        darker: '#9BA8B7',
      },
      regular: {
        lighter: '#F9FAFC',
        regular: '#EFF2F7',
        darker: '#EFF2F7',
      },
      dark: {
        lighter: '#455566',
        regular: '#34404D',
        darker: '#232B33',
      },
    },
    action: {
      standard: {
        success: '#14CC70',
        danger: '#FF4D4D',
        warning: '#FFC926',
      },
      light: {
        success: '#E9FFEF',
        danger: '#FFECEC',
        warning: '#FFFAE2',
      },
    },
    highlight: {
      warning: {
        light: '#FDE186',
        regular: '#FBDC75',
        dark: '#F7D263',
      },
      danger: {
        light: '#EB1B0E',
        regular: '#CC160B',
        dark: '#BF0A00',
      },
    },
  },
};

export default Theme;
