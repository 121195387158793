import React from 'react';

import { LoaderCustom, Loader, LoaderText } from './styles';

const Loading = (props) => {
  return (
    <LoaderCustom>
      <Loader />
      <LoaderText>{props?.text}</LoaderText>
    </LoaderCustom>
  );
};
export default Loading;
