import styled from 'styled-components';
import { Modal } from 'rsuite';

// Modal de Bloqueio

export const DetailsModal = styled(Modal)`
  width: 560px;
  max-width: 100%;
  z-index: 50;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 801px) {
    width: 560px;

    ${(props) =>
      props.size &&
      props.size === 'sm' &&
      `
      width: 503px;
    `}
  }

  .error-message {
    color: #ff0000;
  }

  @media (min-width: 768px) {
    .rs-modal-content {
      padding: 32px 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  @media (max-width: 768px) {
    .rs-modal-content {
      padding: 32px 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
`;

export const TitleModalWarning = styled.h1`
  color: #132552;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;

  display: flex;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const DescriptionModalWarning = styled.h4`
  color: #455566;
  font-family: IBM Plex Sans;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 30px;
  text-align: center;

  padding-top: 16px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const LinkEmail = styled.a`
  font-family: IBM Plex Sans;
  color: #009fd9;
  text-decoration: none;
`;

export const ImgWarning = styled.img`
  width: 150px;
`;

// FiM Modal de Bloqueio
