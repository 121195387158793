import React, { useState } from 'react';
import ProgressLine from 'rsuite/lib/Progress/ProgressLine';
import styled from 'styled-components';
import { Spacer } from '../../Spacer';

export const FileUploadingModal = ({ windowSize, uploadProgressFileList }) => {
  const ref = React.createRef();

  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    setHeight(ref.current.clientHeight);
    setWidth(ref.current.clientWidth);
  }, []);

  return (
    <ProgressPopupContainer
      ref={ref}
      left={windowSize.innerWidth / 2 - width / 2}
      top={windowSize.innerHeight / 2 - height - 100}
    >
      <p>
        <strong>Subindo arquivos, aguarde um momento...</strong>
      </p>

      <Spacer bottom={10} />

      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {uploadProgressFileList.map((up, idx) => (
          <div style={{ display: 'flex', alignItems: 'center' }} key={idx}>
            <p style={{ flex: 1, textAlign: 'left' }}>{up.id}</p>
            <ProgressLine
              style={{ flex: 1, width: '100%' }}
              percent={Math.ceil(up.progress)}
            />
          </div>
        ))}
      </div>
    </ProgressPopupContainer>
  );
};

export const ProgressPopupContainer = styled.div`
  background: #fff;
  box-shadow: 0px 30px 30px rgba(52, 64, 77, 0.03),
    0px 5px 7px rgba(52, 64, 77, 0.05), 0px 15px 25px rgba(32, 49, 91, 0.05);
  border-radius: 10px;
  padding: 20px;
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  text-align: center;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
`;
