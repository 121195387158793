import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '~/components/Card';
import LoggedLayout from '~/components/LoggedLayout';
import {
  Sidebar,
  SidebarContent,
  SidebarSubtitle,
  SidebarTitle,
} from '~/components/Sidebar';
import { toggleUserbar } from '~/store/modules/user/actions';
import {
  calculatePercentAddress,
  calculatePercentPFGeneralData,
  calculatePercentPFIdentity,
  calculatePercentPFProfission,
  calculatePercentPJGeneralData,
  calculatePercentPJRequest,
} from '~/utils/calculate-percent';
import Endereco from './icons/address.svg';
import EnderecoMobile from './icons/addressMobile.svg';
import Bank from './icons/bank.svg';
import BankMobile from './icons/bankMobile.svg';
import Contract from './icons/contract.svg';
import ContractMobile from './icons/contractMobile.svg';
import Geral from './icons/geral.svg';
import GeralMobile from './icons/geralMobile.svg';
import IconDoc from './icons/iconDoc.svg';
import Id from './icons/id.svg';
import IdMobile from './icons/idMobile.svg';
import Partners from './icons/partners.svg';
import PartnersMobile from './icons/partnersMobile.svg';
import Profession from './icons/profession.svg';
import ProfessionMobile from './icons/professionMobile.svg';
import Finantial from './icons/finantial.svg';
import FinantialMobile from './icons/finantialMobile.svg';
import { DisplayCards, GridCards, HeaderBar } from './styles';

import RegisterStatus from '~/components/RegisterStatus';

const PfCards = [
  {
    icon: Id,
    iconMobile: IdMobile,
    title: 'Identificação',
    subtitle: 'Seus dados e documentos como RG, CPF e etc...',
    navigateTo: '/update-data/identification',
  },
  {
    icon: Geral,
    iconMobile: GeralMobile,
    title: 'Geral',
    subtitle: 'Estado civil, cidade, estado de nascimento e etc',
    navigateTo: '/update-data/pf',
  },
  {
    icon: Endereco,
    iconMobile: EnderecoMobile,
    title: 'Endereço',
    subtitle: 'Informação completa de endereço com comprovante',
    navigateTo: '/update-address',
  },
  {
    icon: Bank,
    iconMobile: BankMobile,
    title: 'Banco',
    subtitle: 'Tipo de conta, banco, conta corrente e etc',
    navigateTo: '/update-bank',
  },
  {
    icon: Profession,
    iconMobile: ProfessionMobile,
    title: 'Profissão',
    subtitle: 'Informações gerais sobre sua profissão, renda e etc',
    navigateTo: '/update-data/profession',
  },
  {
    icon: Contract,
    iconMobile: ContractMobile,
    title: 'Fechamento',
    subtitle: 'Selecione o serviço e informe o valor desejado',
    navigateTo: '/closing-estimate',
  },
];

const PjCards = [
  {
    icon: Geral,
    iconMobile: GeralMobile,
    title: 'Geral',
    subtitle: 'Razão social, nome fantasia, CNPJ, atividade econômica e etc',
    navigateTo: '/update-data/pj',
  },
  {
    icon: Endereco,
    iconMobile: EnderecoMobile,
    title: 'Endereço',
    subtitle: 'Informação completa de endereço com comprovante',
    navigateTo: '/update-address',
  },
  {
    icon: Bank,
    iconMobile: BankMobile,
    title: 'Banco',
    subtitle: 'Tipo de conta, banco, conta corrente e etc',
    navigateTo: '/update-bank',
  },
  {
    icon: Partners,
    iconMobile: PartnersMobile,
    title: 'Representantes',
    subtitle: 'Dados dos sócios e/ou procuradores da empresa',
    navigateTo: '/update-data/partners',
  },
  {
    icon: Finantial,
    iconMobile: FinantialMobile,
    title: 'Financeiro',
    subtitle: 'Servicos, faturamento, património e etc',
    navigateTo: '/update-data/finantial',
  },
  {
    icon: Contract,
    iconMobile: ContractMobile,
    title: 'Fechamento',
    subtitle: 'Selecione o serviço e informe o valor desejado',
    navigateTo: '/closing-estimate',
  },
];

const MyData = () => {
  const user = useSelector((state) => state.user);
  const bankList = useSelector((state) => state.bankList);
  const partnersList = useSelector(
    (state) => state?.partnersList?.partners ?? []
  );
  const {
    addressCity,
    addressCountry,
    addressNeighborhood,
    addressNumber,
    addressState,
    addressStreet,
    addressZipcode,
    cityBirth,
    companyName,
    companyWhereWorkName,
    countryBirth,
    document,
    email,
    idProfission,
    issuingAgency,
    issuingExpeditionDate,
    identificationDocumentType,
    identificationDocument,
    issuingState,
    monthlyIncome,
    motherName,
    patrimony,
    phoneNumber,
    stateBirth,
    companyFantasyName,
    companyOpenedDate,
    companyEconomicActivity,
    annualPatrimony,
    annualIncome,
  } = user.profile;

  const [idPercentage, setIdPercentage] = useState(0);
  const [profissionPercentage, setProfissionPercentage] = useState(0);
  const [generalPercentage, setGeneralPercentage] = useState(0);
  const [addressPercentage, setAddressPercentage] = useState(0);
  const [finantialPercentage, setFinantialPercentage] = useState(0);
  const [partnersPercentage, setPartnersPercentage] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    let percentageAccumulate = 0;

    partnersList.map((item) => {
      if (item.quota) {
        percentageAccumulate += item.quota;
      }
    });

    setPartnersPercentage(percentageAccumulate);

    setIdPercentage(calculatePercentPFIdentity(user.profile), [
      document,
      identificationDocument,
      identificationDocumentType,
      issuingAgency,
      issuingState,
      issuingExpeditionDate,
    ]);
    setProfissionPercentage(calculatePercentPFProfission(user.profile), [
      idProfission,
      companyWhereWorkName,
      monthlyIncome,
      patrimony,
    ]);
    setGeneralPercentage(
      document.length === 11
        ? calculatePercentPFGeneralData(user.profile)
        : calculatePercentPJGeneralData(user.profile),
      [
        companyFantasyName,
        companyName,
        companyOpenedDate,
        companyEconomicActivity,
        email,
        phoneNumber,
        document,
        cityBirth,
        stateBirth,
        motherName,
        countryBirth,
      ]
    );
    setAddressPercentage(calculatePercentAddress(user.profile), [
      addressZipcode,
      addressStreet,
      addressNumber,
      addressState,
      addressCity,
      addressCountry,
      addressNeighborhood,
    ]);

    setFinantialPercentage(calculatePercentPJRequest(user.profile), [
      annualIncome,
      annualPatrimony,
    ]);

    if (user.showUserbar) {
      dispatch(toggleUserbar());
    }
  }, []);

  const getCurrentPercent = {
    Identificação: idPercentage,
    Profissão: profissionPercentage,
    Geral: generalPercentage,
    Endereço: addressPercentage,
    Financeiro: finantialPercentage,
  };

  useEffect(() => {
    window.amplitude.getInstance().logEvent('access My Data page');
  }, []);

  return (
    <LoggedLayout>
      <Sidebar>
        <SidebarContent>
          <SidebarTitle>Meus Dados</SidebarTitle>
          <SidebarSubtitle>
            Gerencie e tenha total controle sobre os seus dados e documentos
          </SidebarSubtitle>

          <RegisterStatus />
        </SidebarContent>
      </Sidebar>
      <HeaderBar>
        <div id='label'>
          <img src={IconDoc} alt='iconDoc' />
          <h2 className='headerbar-title'>Dados e documentos</h2>
        </div>
        <RegisterStatus />
      </HeaderBar>
      <DisplayCards>
        <GridCards>
          {document.length === 11 &&
            PfCards.map((card) => (
              <Card
                key={card.title}
                icon={card.icon}
                iconMobile={card.iconMobile}
                title={card.title}
                subtitle={card.subtitle}
                percent={getCurrentPercent[card.title]}
                navigateTo={card.navigateTo}
                bankList={bankList}
              />
            ))}
          {document.length > 11 &&
            PjCards.map((card) => (
              <Card
                key={card.title}
                icon={card.icon}
                iconMobile={card.iconMobile}
                title={card.title}
                subtitle={card.subtitle}
                percent={getCurrentPercent[card.title]}
                partnersPercentage={partnersPercentage}
                partners={partnersList}
                navigateTo={card.navigateTo}
                bankList={bankList}
              />
            ))}
        </GridCards>
      </DisplayCards>
    </LoggedLayout>
  );
};

export default MyData;
