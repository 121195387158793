import React, { useEffect, useState } from 'react';
import { Copy } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import IconSuccess from '~/assets/icon-success.svg';
import { transformNumberInMoney } from '~/utils/transformNumberInMoney';
import {
  CodeArea,
  Content,
  Flag,
  Info,
  ListButtons,
  StepContent,
  StepNumber,
  Steps,
  StepsItem,
  StepTransfer,
  StyledBox,
  StyledButton,
  Title,
  TransferArea,
  TransferTitle,
} from './styles';

import history from '~/services/history';
import {
  updateTicket,
  updateTicketNewStatus,
  endTime,
} from '~/store/modules/exchange/actions';
import { calcutateCheckoutValues } from '../../utils/calculateCheckoutValues';

const TedPayment = () => {
  const [title, setTitle] = useState('');
  const [flag, setFlag] = useState(
    <>
      <img src={IconSuccess} /> Pedido realizado
    </>
  );
  const [isOverdue, setOverdue] = useState(false);
  const { exchange } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { totalValue } = calcutateCheckoutValues(exchange);

  const copyCode = () => {
    return navigator.clipboard.writeText(exchange.ticket?.bankCode);
  };

  const copyAgencyNumber = () => {
    return navigator.clipboard.writeText(exchange.ticket?.agencyNumber);
  };

  const copyAccountNumber = () => {
    return navigator.clipboard.writeText(exchange.ticket?.accountNumber);
  };

  const copyCnpj = () => {
    return navigator.clipboard.writeText(exchange.ticket?.cnpj);
  };

  const stepsPayment = [
    'Abra o app do seu banco',
    'Selecione a opção Transferência',
    'Adicione o valor e dados',
    'Efetue o pagamento e envie o comprovante via WhatsApp para <a target="__blank" href="https://api.whatsapp.com/send?phone=5511998656710&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20com%20meu%20pedido">(11) 99865-6710</a>',
  ];

  useEffect(() => {
    setTitle(
      `Pronto, agora é a hora de fazer a TED no valor de <strong>${transformNumberInMoney(
        {
          currency: 'BRL',
          number: totalValue,
        }
      )}</strong>`
    );
  }, []);

  useEffect(() => {
    dispatch(updateTicket({ data: { ticket_id: null } }));
    dispatch(updateTicketNewStatus(false));
    dispatch(endTime());
  }, []);

  return (
    <StyledBox>
      <Flag>{flag}</Flag>
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <Content>
        <CodeArea>
          <TransferArea>
            <TransferTitle>Dados para transferência</TransferTitle>
            <br />
            <StepTransfer>
              Banco: {exchange.ticket.bankName} ({exchange.ticket.bankCode}){' '}
            </StepTransfer>
            <Copy size={14} onClick={copyCode} cursor='pointer' />
            <br />
            <StepTransfer>
              Agencia: {exchange.ticket.agencyNumber}{' '}
            </StepTransfer>
            <Copy size={14} onClick={copyAgencyNumber} cursor='pointer' />
            <br />
            <StepTransfer>
              Conta Corrente: {exchange.ticket.accountNumber}{' '}
            </StepTransfer>
            <Copy size={14} onClick={copyAccountNumber} cursor='pointer' />
            <br />
            <StepTransfer>CNPJ: {exchange.ticket.cnpj} </StepTransfer>
            <Copy size={14} onClick={copyCnpj} cursor='pointer' />
            <br />
          </TransferArea>
          <ListButtons>
            <StyledButton
              style={{ background: '#0FC7FF', color: '#fff' }}
              onClick={() => history.push('/exchange-tickets')}
            >
              Voltar para os pedidos
            </StyledButton>
          </ListButtons>
        </CodeArea>
        <Info>
          <Steps>
            {stepsPayment.map((item, index) => (
              <StepsItem>
                <StepNumber>{index + 1}</StepNumber>
                <StepContent dangerouslySetInnerHTML={{ __html: item }} />
              </StepsItem>
            ))}
          </Steps>
        </Info>
      </Content>
    </StyledBox>
  );
};

export default TedPayment;
