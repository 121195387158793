import styled from 'styled-components';
import Button from '../Button';

export const StyledBox = styled.div`
  width: 100%;
  max-width: 696px;
  background: #ffffff;
  border: 1px solid #c0ccda;
  border-radius: 10px;
  padding: 40px 64px;
  margin: 0 auto;

  @media (max-width: 375px) {
    padding: 40px 32px;
  }
`;

export const Observation = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #34404d;
  padding: 24px 0;
  display: block;
`;

export const BoletoNumber = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  display: block;
  text-align: center;
  padding-bottom: 16px;
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #20315b;
`;

export const StyledButton = styled.button`
  color: #0fc7ff;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  background: #fff;

  & svg {
    margin-right: 8px;
  }
`;

export const ListButtons = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-bottom: 36px;

  &::after {
    content: '|';
    color: #d3dce6;
    position: absolute;
    left: 50%;
    transform: translateY(-8px) translateX(-50%);
    font-size: 24px;
  }
`;

export const BoletoHolder = styled.img`
  margin: 0 auto 8px;
  text-align: center;
  width: 100%;
  object-fit: none;
  filter: blur(6px);
`;

export const DisplayBarCode = styled.div`
  @media (max-width: 730px) {
    display: none;
  }
`;

export const CustomButton = styled(Button)`
  @media (max-width: 355px) {
  }
`;
